import { useState } from 'react'
import { DistributiveOmit } from 'react-redux'
import styled from 'styled-components'

import { AddButton } from 'components/AddButton'
import Select from 'components/forms/Select'

import {
  MetaAnalysisChart,
  MetaAnalysisFrequencyChartType,
  MetaAnalysisDimensionalityReductionChartType,
} from 'shared/api/meta-analysis.api'
import { useAppDispatch, useAppSelector } from 'shared/store'

import { MetaAnalysisBoxPlotOptions } from './MetaAnalysisBoxPlotOptions'
import { MetaAnalysisDimensionalityReductionChartOptions } from './MetaAnalysisDimensionalityReductionChartOptions'
import { MetaAnalysisFrequencyChartOptions } from './MetaAnalysisFrequencyChartOptions'
import { MetaAnalysisGlobalHeatMapChartOptions } from './MetaAnalysisGlobalHeatMapChartOptions'
import { MetaAnalysisHistogramOptions } from './MetaAnalysisHistogramOptions'
import { MetaAnalysisScatterPlotOptions } from './MetaAnalysisScatterPlotOptions'
import { MetaAnalysisSpiderwebPlotOptions } from './MetaAnalysisSpiderwebPlotOptions'
import { MetaAnalysisVolcanoPlotOptions } from './MetaAnalysisVolcanoPlotOptions'
import { addMetaAnalysisChart } from './store/meta-analysis.history.slice'
import {
  selectMetaAnalysis,
  selectMetaAnalysisChannels,
  selectMetaAnalysisClusters,
  selectMetaAnalysisFcsFileIds,
  selectMetaAnalysisFcsFileNameById,
  selectMetaAnalysisGroupNames,
} from './store/selectors'

export const AddMetaAnalysisChart = (): JSX.Element => {
  const dispatch = useAppDispatch()
  const fileIds = useAppSelector(selectMetaAnalysisFcsFileIds)
  const clusters = useAppSelector(selectMetaAnalysisClusters)
  const channels = useAppSelector(selectMetaAnalysisChannels)
  const groupNames = useAppSelector(selectMetaAnalysisGroupNames)
  const globalVizSetting = useAppSelector(
    state => selectMetaAnalysis(state).compute_global_viz,
  )
  const fileNameById = useAppSelector(selectMetaAnalysisFcsFileNameById)

  const [shouldDisplayForm, setShouldDisplayForm] = useState(false)
  const [chartType, setChartType] =
    useState<MetaAnalysisChart['type']>('global-heat-map')

  const options: {
    value: MetaAnalysisChart['type']
    label: string
    disabled?: boolean
    disabledText?: string
  }[] = [
    {
      value: 'global-heat-map',
      label: 'Clusters frequency by samples',
    },
    {
      value: 'dimensionality-reduction',
      label: 'Dimensionality reduction',
      disabled: globalVizSetting === null,
      disabledText: 'Current Meta-analysis has no dimensionality reduction',
    },
    {
      value: 'box-plot',
      label: 'Box plot',
    },
    {
      value: 'frequency-chart',
      label: 'Frequency chart',
    },
    {
      value: 'spiderweb-plot',
      label: 'Spiderweb plot',
    },
    {
      value: 'volcano-plot',
      label: 'Volcano plot',
      disabled: groupNames.length < 2,
      disabledText: groupNames.length < 2 ? 'Requires 2 or more groups' : '',
    },
    {
      value: 'scatter-plot',
      label: 'Scatter plot',
      disabled: globalVizSetting === null,
      disabledText: 'Current Meta-analysis has no dimensionality reduction',
    },
    {
      value: 'histogram',
      label: 'Histogram',
      disabled: globalVizSetting === null,
      disabledText: 'Current Meta-analysis has no dimensionality reduction',
    },
  ]

  const handleCreateGlobalHeatMapChart = (
    fileIds: string[],
    clusters: string[],
  ) => {
    setShouldDisplayForm(false)
    dispatch(
      addMetaAnalysisChart({
        type: 'global-heat-map',
        name: 'Clusters frequency by samples',
        selectedFileIds: fileIds,
        selectedClusters: clusters,
      }),
    )
  }

  const handleCreateBoxChart = (
    fileIds: string[],
    cluster: string,
    channel: string,
  ) => {
    setShouldDisplayForm(false)
    dispatch(
      addMetaAnalysisChart({
        type: 'box-plot',
        name: cluster,
        selectedFileIds: fileIds,
        selectedCluster: cluster,
        selectedChannel: channel,
      }),
    )
  }

  const handleCreateDimensionalityReductionChart = (
    values: DistributiveOmit<
      MetaAnalysisDimensionalityReductionChartType,
      'id' | 'name' | 'type'
    >,
  ) => {
    setShouldDisplayForm(false)
    dispatch(
      addMetaAnalysisChart({
        type: 'dimensionality-reduction',
        name:
          values.mode === 'reference-file'
            ? [values.selectedFileId, values.selectedReferenceFileId]
                .map(id => fileNameById[id])
                .join(' vs ')
            : fileNameById[values.selectedFileId],
        ...values,
      }),
    )
  }

  const handleCreateFrequencyChart = (
    fileIds: string[],
    cluster: string,
    channel: string,
    mode: MetaAnalysisFrequencyChartType['selectedIntensityMode'],
  ) => {
    setShouldDisplayForm(false)
    dispatch(
      addMetaAnalysisChart({
        type: 'frequency-chart',
        name: cluster,
        selectedFileIds: fileIds,
        selectedCluster: cluster,
        selectedChannel: channel,
        selectedIntensityMode: mode,
      }),
    )
  }

  const handleCreateSpiderwebPlot = (
    fileIds: string[],
    cluster: string,
    channels: string[],
  ) => {
    setShouldDisplayForm(false)
    dispatch(
      addMetaAnalysisChart({
        type: 'spiderweb-plot',
        name: cluster,
        selectedFileIds: fileIds,
        selectedCluster: cluster,
        selectedChannels: channels,
      }),
    )
  }

  const handleCreateVolcanoPlot = (
    groups: [string, string],
    xThreshold: number,
    yThreshold: number,
  ) => {
    setShouldDisplayForm(false)
    dispatch(
      addMetaAnalysisChart({
        type: 'volcano-plot',
        name: groups.join(' vs '),
        selectedGroups: groups,
        xThreshold,
        yThreshold,
      }),
    )
  }

  const handleAddScatterPlot = (values: {
    fileIds: string[]
    clusters: string[]
    xAxis: string
    yAxis: string
  }) => {
    setShouldDisplayForm(false)
    dispatch(
      addMetaAnalysisChart({
        type: 'scatter-plot',
        name: fileIds.map(id => fileNameById[id]).join(', '),
        ...values,
      }),
    )
  }

  function handleAddHistogram(values: {
    fileIds: string[]
    clusters: string[]
    channel: string
  }): void {
    setShouldDisplayForm(false)
    dispatch(
      addMetaAnalysisChart({
        type: 'histogram',
        name: 'Histogram',
        ...values,
      }),
    )
  }

  const handleCancel = () => {
    setShouldDisplayForm(false)
  }

  return (
    <AddMetaAnalysisChartRoot>
      {!shouldDisplayForm && (
        <AddButton onClick={() => setShouldDisplayForm(true)} />
      )}
      {shouldDisplayForm && (
        <FormContainer>
          <StyledSelect
            label={<Header>Chart type</Header>}
            value={chartType}
            onChange={event =>
              setChartType(event.target.value as MetaAnalysisChart['type'])
            }
            options={options}
            shouldReserveSpaceForError={false}
          />
          {chartType === 'global-heat-map' && (
            <MetaAnalysisGlobalHeatMapChartOptions
              mode="create"
              onCancel={handleCancel}
              onFinish={handleCreateGlobalHeatMapChart}
              initialSelectedFileIds={fileIds}
              initialSelectedClusters={clusters}
            />
          )}
          {chartType === 'box-plot' && (
            <MetaAnalysisBoxPlotOptions
              mode="create"
              onCancel={handleCancel}
              onFinish={handleCreateBoxChart}
              initialSelectedFileIds={fileIds}
              initialSelectedCluster={clusters[0]}
              initialSelectedChannel={channels[0]}
            />
          )}
          {chartType === 'dimensionality-reduction' && (
            <MetaAnalysisDimensionalityReductionChartOptions
              mode="create"
              onCancel={handleCancel}
              onFinish={handleCreateDimensionalityReductionChart}
            />
          )}
          {chartType === 'frequency-chart' && (
            <MetaAnalysisFrequencyChartOptions
              mode="create"
              onCancel={handleCancel}
              onFinish={handleCreateFrequencyChart}
              initialSelectedFileIds={fileIds}
              initialSelectedCluster={clusters[0]}
              initialSelectedChannel={channels[0]}
              initialSelectedIntensityMode="median"
            />
          )}
          {chartType === 'spiderweb-plot' && (
            <MetaAnalysisSpiderwebPlotOptions
              mode="create"
              onCancel={handleCancel}
              onFinish={handleCreateSpiderwebPlot}
              initialSelectedFileIds={fileIds}
              initialSelectedCluster={clusters[0]}
              initialSelectedChannels={channels}
            />
          )}
          {chartType === 'volcano-plot' && (
            <MetaAnalysisVolcanoPlotOptions
              mode="create"
              onCancel={handleCancel}
              onFinish={handleCreateVolcanoPlot}
              initialSelectedGroups={[groupNames[0], groupNames[1]]}
              initialXThreshold={0}
              initialYThreshold={0}
            />
          )}
          {chartType === 'scatter-plot' && (
            <MetaAnalysisScatterPlotOptions
              mode="create"
              initialValues={{
                fileIds,
                clusters,
                xAxis: channels[0],
                yAxis: channels[1],
              }}
              onCancel={handleCancel}
              onFinish={handleAddScatterPlot}
            />
          )}
          {chartType === 'histogram' && (
            <MetaAnalysisHistogramOptions
              mode="create"
              initialValues={{
                fileIds,
                clusters,
                channel: channels[0],
              }}
              onCancel={handleCancel}
              onFinish={handleAddHistogram}
            />
          )}
        </FormContainer>
      )}
    </AddMetaAnalysisChartRoot>
  )
}

const AddMetaAnalysisChartRoot = styled.div`
  width: 100%;
  height: 100%;
  display: grid;
  border-radius: ${props => props.theme.radius[2]}px;
  border: 1px solid ${props => props.theme.colors.primaryDark[20]};
  background-color: ${props => props.theme.colors.white};
  box-shadow: ${props => props.theme.shadow[1]};
  overflow: hidden;
`

const FormContainer = styled.div`
  height: 100%;
  display: grid;
  grid-template-rows: auto 1fr;
  overflow: hidden;
`

const Header = styled.p`
  font-family: ${props => props.theme.font.style.bold};
`

const StyledSelect = styled(Select)`
  padding: 8px;
  padding-bottom: 0;
  border-bottom: 1px solid ${props => props.theme.colors.primaryDark[20]};
`
