import { useState } from 'react'
import styled from 'styled-components'

import { Button } from 'components/button/Button'
import { Modal } from 'components/modal/Modal'
import { ModalContainer } from 'components/modal/ModalContainer'
import { TextField } from 'components/text-field/TextField'

import {
  SpilloverFile,
  useUpdateSpilloverFileMutation,
} from 'shared/api/files.api'
import { useEventCallback } from 'shared/hooks/useEventCallback'

type CompensationMatrixRenameProps = {
  file: SpilloverFile
  onClose: () => void
}

export const CompensationMatrixRenameModal: React.FC<
  CompensationMatrixRenameProps
> = ({ file, onClose }) => {
  const [triggerUpdateSpilloverFileMutation, updateSpilloverFileMutationState] =
    useUpdateSpilloverFileMutation()

  const [fileName, setFileName] = useState(file.file_name)
  const [batchName, setBatchName] = useState(file.batch_name ?? '')

  const handleFileNameChange = useEventCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      setFileName(e.target.value)
    },
  )

  const handleBatchNameChange = useEventCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      setBatchName(e.target.value)
    },
  )

  const handleConfirmRename = useEventCallback(() => {
    const mutationPayload = {
      id: file.id,
      file_name: fileName,
    }

    if (batchName !== '' && batchName !== file.batch_name) {
      mutationPayload['batch_name'] = batchName
    }

    triggerUpdateSpilloverFileMutation(mutationPayload).unwrap().then(onClose)
  })

  return (
    <Modal
      open
      title="Rename file or batch"
      onClose={onClose}
      Container={StyledModalContainer}
    >
      <StyledTextField
        value={fileName}
        onChange={handleFileNameChange}
        label="File name"
      />
      <StyledTextField
        value={batchName}
        onChange={handleBatchNameChange}
        label="Rename batch (will apply to all files in this batch)"
      />

      <Actions>
        <Button colorOverride="greyscale" onClick={onClose}>
          Cancel
        </Button>
        <Button
          onClick={handleConfirmRename}
          disabled={fileName.trim().length === 0}
          loading={updateSpilloverFileMutationState.isLoading}
        >
          Apply
        </Button>
      </Actions>
    </Modal>
  )
}

const StyledModalContainer = styled(ModalContainer)`
  width: 700px;
  font-size: 13px;
  gap: ${props => props.theme.spacing(2)}px;
`

const StyledTextField = styled(TextField)`
  width: 50%;
`

const Actions = styled.div`
  display: flex;
  gap: 12px;
  margin-top: 50px;
`
