import {
  CircularProgress,
  Fade,
  Table,
  TableBody,
  TableCell,
  TableRow,
} from '@material-ui/core'
import React from 'react'
import styled from 'styled-components'

import { EmptyList } from 'components/EmptyList'
import { Modal } from 'components/Modal'

import { useAppSelector } from 'shared/store'

import { selectAnalysisFcsFile } from './store/selectors'

type FcsFileHeadersModalProps = Readonly<{
  open: boolean
  onClose: () => void
}>

export const FcsFileHeadersModal: React.FC<FcsFileHeadersModalProps> = ({
  open,
  onClose,
}) => {
  const fcsFile = useAppSelector(selectAnalysisFcsFile)

  type Header = Readonly<{
    key: string
    value: string
  }>
  const headers: undefined | Array<Header> = Object.entries(
    fcsFile.headers || {},
  ).flatMap(([k, v]) => {
    if ((v as unknown) instanceof Object) {
      return Object.entries(v).map(([v_k, v_v]) => ({
        key: v_k,
        value: String(v_v),
      }))
    } else {
      return { key: k, value: String(v) }
    }
  })

  return (
    <Modal open={open} onClose={onClose}>
      <Fade in={open}>
        <Container>
          {headers === undefined ? (
            <LoaderContainer>
              <CircularProgress />
            </LoaderContainer>
          ) : headers.length === 0 ? (
            <EmptyList text="No headers in this file" />
          ) : (
            <StyledTable>
              <TableBody>
                {headers.map(h => (
                  <TableRow key={h.key}>
                    <StyledTableCell>
                      <Code>{h.key}</Code> : <Code>{h.value}</Code>
                    </StyledTableCell>
                  </TableRow>
                ))}
              </TableBody>
            </StyledTable>
          )}
        </Container>
      </Fade>
    </Modal>
  )
}

const Container = styled.div`
  background-color: ${props => props.theme.colors.white};
  border-radius: ${props => props.theme.radius[2]}px;
  width: ${props => props.theme.layout.modalWidth}px;
  height: 80%;
  overflow: auto;
`

const LoaderContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
`

const StyledTable = styled(Table)`
  table-layout: fixed;
`

const StyledTableCell = styled(TableCell)`
  overflow: auto;
  white-space: nowrap;
  padding: 8px;
`

const Code = styled.code`
  padding: 2px;
  background-color: ${props => props.theme.colors.warningLight};
`
