import { useField } from 'formik'
import { isEqual } from 'lodash'
import styled from 'styled-components'

import { AddButton } from 'components/AddButton'
import { AutocompleteInput } from 'components/AutocompleteInput'
import { EditableLabel } from 'components/EditableLabel'
import Select from 'components/forms/Select'

import { useEventCallback } from 'shared/hooks/useEventCallback'

import { AnalysisStatisticsWizardReportableStatistic } from './AnalysisStatisticsWizardReportableStatistic'

export type ReportableOption = {
  value: string
  label: string
}

export type ClusterReportableOption = Omit<ReportableOption, 'value'> & {
  value: string[]
}

type AnalysisStatisticsWizardReportableProps = {
  path: string
  availableOptions: {
    cluster: ClusterReportableOption[]
    lasso: ReportableOption[]
    gate: ReportableOption[]
    channel: ReportableOption[]
  }
  onDelete: () => void
}

export const AnalysisStatisticsWizardReportable: React.FC<
  AnalysisStatisticsWizardReportableProps
> = ({ path, availableOptions, onDelete }) => {
  const [typeField, , { setValue: setTypeValue }] = useField<
    Analysis.Reportable['type']
  >(`${path}.type`)
  const [idField, , { setValue: setIdValue }] = useField<string | undefined>(
    `${path}.id`,
  )
  const [idsField, , { setValue: setIdsValue }] = useField<
    string[] | undefined
  >(`${path}.ids`)
  const [labelField, , { setValue: setLabelValue }] = useField(`${path}.label`)
  const [statisticsField, , { setValue: setStatisticsValue }] = useField<
    Analysis.Reportable['statistics']
  >(`${path}.statistics`)

  const handleTypeChange = (event: { target: { value: unknown } }) => {
    const type = event.target.value as Analysis.Reportable['type']
    setTypeValue(type)
    if (type === 'cluster') {
      setIdsValue(availableOptions.cluster[0].value)
      setIdValue(undefined)
    } else {
      setIdValue(availableOptions[type][0].value)
      setIdsValue(undefined)
    }
  }

  const handleIdChange = useEventCallback(
    (_event, option: ReportableOption) => {
      setIdValue(option.value)
      setIdsValue(undefined)
    },
  )

  const handleIdsChange = useEventCallback(
    (_event, option: ClusterReportableOption) => {
      setIdsValue(option.value)
      setIdValue(undefined)
    },
  )

  const handleValidateLabel = useEventCallback((value: string) => {
    if (value.trim().length === 0) {
      return 'Label is required'
    }
    return ''
  })

  return (
    <AnalysisStatisticsWizardReportableRoot>
      <ReportableLabel
        value={labelField.value}
        onChange={setLabelValue}
        onDelete={onDelete}
        onValidate={handleValidateLabel}
      />
      <ReportableTypeAndId>
        <StyledSelect
          name={typeField.name}
          value={typeField.value}
          label="Type"
          options={[
            { value: 'cluster', label: 'Cluster' },
            {
              value: 'lasso',
              label: 'Lasso',
              disabled: availableOptions.lasso.length === 0,
            },
            {
              value: 'gate',
              label: 'Gate',
              disabled: availableOptions.gate.length === 0,
            },
          ]}
          shouldReserveSpaceForError={false}
          onChange={handleTypeChange}
          onBlur={typeField.onBlur}
        />
        {typeField.value === 'cluster' ? (
          <AutocompleteInput
            name={idsField.name}
            value={availableOptions.cluster.find(optionClusterIds =>
              isEqual(optionClusterIds.value, idsField.value),
            )}
            label="ID"
            options={availableOptions.cluster}
            getOptionLabel={option => option.label}
            disableClearable={true}
            onChange={handleIdsChange}
            onBlur={idsField.onBlur}
          />
        ) : (
          <AutocompleteInput
            name={idField.name}
            value={availableOptions[typeField.value].find(
              o => o.value === idField.value,
            )}
            label="ID"
            options={availableOptions[typeField.value]}
            getOptionLabel={option => option.label}
            disableClearable={true}
            onChange={handleIdChange}
            onBlur={idField.onBlur}
          />
        )}
      </ReportableTypeAndId>
      <StatisticsLabel>Statistics</StatisticsLabel>
      <Statistics>
        {statisticsField.value.map((_, index) => {
          return (
            <AnalysisStatisticsWizardReportableStatistic
              key={`${path}.statistics.${index}`}
              path={`${path}.statistics.${index}`}
              availableOptions={availableOptions}
              onDelete={() => {
                setStatisticsValue(
                  statisticsField.value.filter((_, i) => i !== index),
                )
              }}
            />
          )
        })}
        <AddStatisticsPlaceholder>
          <AddButton
            scale={0.5}
            onClick={() => {
              setStatisticsValue([
                ...statisticsField.value,
                {
                  type: 'count',
                  parameter: undefined,
                  value: undefined,
                },
              ])
            }}
          />
        </AddStatisticsPlaceholder>
      </Statistics>
    </AnalysisStatisticsWizardReportableRoot>
  )
}

const AnalysisStatisticsWizardReportableRoot = styled.div`
  border: 1px solid ${props => props.theme.colors.greyscale[10]};
  background: ${props => props.theme.colors.white};
  box-shadow: rgba(0, 0, 0, 0.1) 0 1px 4px;
  border-radius: ${props => props.theme.radius[2]}px;
  padding: 12px;

  label {
    font-weight: bold;
  }
`

const ReportableLabel = styled(EditableLabel)`
  .label,
  input {
    font-weight: bold;
    font-size: 14px;
    color: ${props => props.theme.colors.primaryDark['100']};
  }
`

const ReportableTypeAndId = styled.div`
  display: grid;
  gap: 8px;
  grid-template-columns: 100px 1fr;
  margin-bottom: 8px;
`

const Statistics = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`

const StatisticsLabel = styled.p`
  font-weight: bold;
  font-size: 15px;
  margin-bottom: 8px;
`

const AddStatisticsPlaceholder = styled.div`
  border: 1px solid ${props => props.theme.colors.greyscale[10]};
  background: ${props => props.theme.colors.white};
  box-shadow: rgba(0, 0, 0, 0.1) 0 1px 4px;
  border-radius: ${props => props.theme.radius[2]}px;
  display: flex;
  place-items: center;
  height: 56px;
`

const StyledSelect = styled(Select)`
  padding: 0;

  label {
    font-size: 12px;
  }

  .MuiInputBase-root {
    padding: 0 10px;
    font-size: 13px;
    min-height: 26px;
    height: min-content;
  }
`
