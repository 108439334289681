import { Point } from 'geometric'
import HighchartsReact from 'highcharts-react-official'
import { throttle } from 'lodash'
import { MutableRefObject, useMemo } from 'react'
import { useEvent } from 'react-use'

import { useEventCallback } from 'shared/hooks/useEventCallback'

export const useChartEvent = (
  scatterPlotRef: MutableRefObject<HighchartsReact.RefObject | undefined>,
  container: HTMLDivElement | null | undefined,
  eventType: 'mousedown' | 'mouseup' | 'mousemove' | 'dblclick',
  onEvent: (point: Point, button?: number) => void,
  isActive: boolean,
): void => {
  const handler = useEventCallback((event: Event) => {
    if (!isActive || !scatterPlotRef.current?.chart.pointer) {
      return
    }
    const { chartX, chartY } = scatterPlotRef.current.chart.pointer.normalize(
      event as MouseEvent,
    )
    const x = scatterPlotRef.current.chart?.xAxis[0]?.toValue(chartX)
    const y = scatterPlotRef.current.chart?.yAxis[0]?.toValue(chartY)
    onEvent([x, y], (event as MouseEvent).button)
  })

  const isContainerUndefined = !container

  const finalHandler = useMemo(() => {
    if (isContainerUndefined) {
      return () => {
        console.warn(
          'Lasso event handler is not attached because container is undefined',
        )
      }
    }
    return eventType === 'mousemove' ? throttle(handler, 16) : handler
  }, [eventType, handler, isContainerUndefined])

  useEvent(eventType, finalHandler, container)
}
