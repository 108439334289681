import { groupBy, mapValues } from 'lodash'

export function groupByUnique<T>(
  xs: T[],
  propertySelector: string | ((x: T) => T[keyof T]),
): Record<string, T> {
  const grouped = groupBy(xs, propertySelector)
  return mapValues(grouped, (value, id) => {
    if (value.length > 1) {
      throw new Error(`Duplicate key ${id}`)
    }
    return value[0]
  })
}
