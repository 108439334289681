import { ReactNode, useRef } from 'react'
import { Handle, Position } from 'reactflow'
import styled from 'styled-components'

import { WORKFLOW_NODE_HEIGHT, WORKFLOW_NODE_WIDTH } from './constants'

type WorkflowNodeProps = {
  isRoot?: boolean
  isLeaf?: boolean
  children: ReactNode
  className?: string
}

export const WorkflowNode: React.FC<WorkflowNodeProps> = ({
  isRoot,
  isLeaf,
  children,
  className,
}) => {
  const rootElementRef = useRef<HTMLDivElement>(null)

  return (
    <WorkflowNodeRoot className={className} ref={rootElementRef}>
      {!isRoot && <StyledHandle type="target" position={Position.Left} />}
      {children}
      {!isLeaf && <StyledHandle type="source" position={Position.Right} />}
    </WorkflowNodeRoot>
  )
}

const WorkflowNodeRoot = styled.div`
  box-shadow: 0px 4px 10px 0px #e8e9f399;
  border-radius: 5px;
  padding: 6px 10px;
  font-size: 12px;
  font-weight: bold;
  width: ${WORKFLOW_NODE_WIDTH}px;
  height: ${WORKFLOW_NODE_HEIGHT}px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 8px;
  cursor: pointer;
  background: white;
`

const StyledHandle = styled(Handle)`
  background-color: ${props => props.theme.colors.primary[70]};
`
