import { Point, pointInPolygon, Polygon } from 'geometric'
import HighchartsReact from 'highcharts-react-official'
import { cloneDeep, isEqual, mapValues, omit } from 'lodash'
import { MutableRefObject } from 'react'
import { v4 as uuidv4 } from 'uuid'

import { Analysis, Lasso } from 'shared/models/AnalysisModels'
import { GraphZoom } from 'shared/models/Graphs'
import { AppDispatch } from 'shared/store'
import { checkIfClusterIsInsideLasso } from 'shared/utils/clusters.utils'
import { handleError } from 'shared/utils/errorHandler'

import { AppTheme } from 'Theme'

import { deleteLasso, duplicateChart, saveLasso } from '../store/analysis.slice'
import { AnalysisAccessMode, Cluster } from '../store/selectors'
import { UseChartScales } from '../useChartScales'
import { LassoCursor } from './LassoCursor'
import { LassoRenderer } from './LassoRenderer'
import { LassoValidator } from './LassoValidator'

enum LassoTypes {
  Rectangle = 'rectangle',
  Freeshape = 'freeshape',
}

export type LassoToolProps = {
  chartId: string
  chartScales: UseChartScales
  xAxis: string
  yAxis: string | undefined
  theme: AppTheme
  shownLassos: Analysis['lassos']
  zoom: GraphZoom
  activeLeaves: Cluster[]
  analysisAccessMode: AnalysisAccessMode
  triggerReactRender: () => void
  dispatch: AppDispatch
  onFinishLasso: () => void
}

export type LassoToolLasso = {
  id: string
  name: string
  type: `${LassoTypes}`
  polygon: Polygon
  isFinished: boolean
}

export type LassoToolState = {
  isActive: boolean
  lassoCreationMode: `${LassoTypes}`
  isDrawingLasso: boolean
  isMovingLasso: boolean
  mousePoint: Point | undefined
  movedPolygonPointIndex: number | undefined
  lassos: { [id: string]: LassoToolLasso }
  currentLassoId: string | undefined
  svgGroup: Highcharts.SVGElement | undefined
  scatterPlotRef: MutableRefObject<HighchartsReact.RefObject | undefined>

  __computed__hoveredLassoId: string | undefined
  __computed__hoveredPolygonPointIndex: number | undefined
  __computed__hoveredPolygonPoint: Point | undefined
  __computed__canAddPoint: boolean
  __computed__canFinishLasso: boolean
  __computed__isModifiedLassoCorrect: boolean
  __computed__isMouseInsideCurrentLasso: boolean
  __computed__canDropLasso: boolean
  __computed__horizontalConnectedVertexIndex: number | undefined
  __computed__verticalConnectedVertexIndex: number | undefined
}

export class LassoTool {
  props: LassoToolProps
  state: LassoToolState

  private lassoRenderer: LassoRenderer
  private lassoValidator: LassoValidator
  private lassoCursor: LassoCursor

  constructor(props: LassoToolProps) {
    this.props = props
    this.state = {
      isActive: Object.keys(this.props.shownLassos).length > 0,
      lassoCreationMode: LassoTypes.Freeshape,
      isDrawingLasso: this.isDrawingEnabled(),
      isMovingLasso: false,
      mousePoint: undefined,
      movedPolygonPointIndex: undefined,
      lassos: {},
      currentLassoId: undefined,
      svgGroup: undefined,
      scatterPlotRef: { current: undefined },

      __computed__hoveredLassoId: undefined,
      __computed__hoveredPolygonPointIndex: undefined,
      __computed__hoveredPolygonPoint: undefined,
      __computed__canAddPoint: false,
      __computed__canFinishLasso: false,
      __computed__isModifiedLassoCorrect: false,
      __computed__isMouseInsideCurrentLasso: false,
      __computed__canDropLasso: true,
      __computed__horizontalConnectedVertexIndex: undefined,
      __computed__verticalConnectedVertexIndex: undefined,
    }
    this.lassoRenderer = new LassoRenderer(this.props, this.state)
    this.lassoValidator = new LassoValidator(this.state)
    this.lassoCursor = new LassoCursor(this.state)

    this.setLassos(this.props.shownLassos)
  }

  changeIsActive(isActive: boolean): void {
    this.state.isActive = isActive
    if (!isActive) {
      this.lassoRenderer.clear()
      this.lassoCursor.updateCursor()
      this.state.lassos = {}
      this.state.currentLassoId = undefined
      this.previousLassos = undefined
    }
    this.props.triggerReactRender()
  }

  saveLasso(name?: string): void {
    const currentLasso = this.getCurrentLasso()
    this.props.dispatch(
      saveLasso({
        chartId: this.props.chartId,
        lasso: {
          id: currentLasso.id,
          name: name ?? currentLasso.name,
          type: currentLasso.type,
          polygon: [...currentLasso.polygon],
        },
      }),
    )
  }

  deleteLasso(): void {
    const currentLasso = this.getCurrentLasso()
    this.props.dispatch(deleteLasso({ lassoId: currentLasso.id }))
  }

  clearCurrentLasso(): void {
    this.previousLassos = undefined
    this.state.isDrawingLasso = this.isDrawingEnabled()
  }

  convertCurrentLassoToFreeshape(): void {
    const currentLasso = this.getCurrentLasso()
    if (currentLasso.type === LassoTypes.Freeshape) {
      return
    }
    currentLasso.type = LassoTypes.Freeshape
    this.saveLasso()
    this.props.triggerReactRender()
  }

  createChartFromLasso(lasso: Lasso): void {
    this.props.dispatch(
      duplicateChart({
        chartId: this.props.chartId,
        shownClusterIds: this.getSelectedClusters().map(cluster => cluster.id),
        parentLasso: lasso,
      }),
    )
  }

  changeLassoCreationMode(mode: `${LassoTypes}`): void {
    const currentLasso = this.getCurrentLasso()
    currentLasso.type = mode
    this.state.lassoCreationMode = mode
    this.state.isDrawingLasso = this.isDrawingEnabled()
    this.props.triggerReactRender()
  }

  getSelectedClusters(): Cluster[] {
    const currentLasso = this.getCurrentLasso()
    return currentLasso ? this.getLassoClusters(currentLasso) : []
  }

  getHoveredLassoClusters(): Cluster[] {
    const hoveredLasso = this.getHoveredLasso()
    return hoveredLasso ? this.getLassoClusters(hoveredLasso) : []
  }

  getLassoClusters(lasso: LassoToolLasso): Cluster[] {
    const yAxis = this.props.yAxis
    if (!lasso || !lasso.isFinished || !yAxis) {
      return []
    }
    return this.props.activeLeaves.filter(leaf =>
      checkIfClusterIsInsideLasso(leaf, lasso.polygon, this.props.xAxis, yAxis),
    )
  }

  setScatterPlotInstance(value: HighchartsReact.RefObject): void {
    this.state.scatterPlotRef.current = value
    this.renderLasso()
  }

  onReactRender(props: LassoToolProps): void {
    this.props = props
    this.lassoRenderer.onReactRender(props)

    this.setLassos(this.props.shownLassos)

    if (this.state.isActive) {
      this.renderLasso()
    }
  }

  onMouseMove(point: Point): void {
    if (!this.state.isActive) {
      return
    }
    const currentLasso = this.getCurrentLasso()

    const previousState = this.cloneState()
    this.state.mousePoint = point

    const { chartScales, zoom } = this.props

    if (this.state.isMovingLasso && currentLasso.isFinished) {
      this.moveLasso(point, previousState.mousePoint)
      this.state.__computed__canDropLasso =
        this.lassoValidator.checkIfLassoIsInChartArea(
          currentLasso.polygon,
          chartScales,
          zoom,
        )
    } else {
      const hoveredLasso = this.getHoveredLasso()
      this.state.__computed__hoveredLassoId = hoveredLasso?.id
      const hoveredPolygonPointIndex = this.getHoveredPolygonPointIndex()
      this.state.__computed__hoveredPolygonPointIndex = hoveredPolygonPointIndex
      this.state.__computed__hoveredPolygonPoint =
        hoveredPolygonPointIndex !== undefined
          ? hoveredLasso?.polygon[hoveredPolygonPointIndex]
          : undefined
      this.state.__computed__canAddPoint =
        this.lassoValidator.checkIfCanAddPoint()
      this.state.__computed__canFinishLasso =
        this.lassoValidator.checkIfLassoCanBeFinished(
          [...currentLasso.polygon, point],
          chartScales,
          zoom,
        )
      if (this.state.movedPolygonPointIndex === undefined) {
        this.state.__computed__isMouseInsideCurrentLasso =
          currentLasso.isFinished && pointInPolygon(point, currentLasso.polygon)
      } else {
        this.state.__computed__isModifiedLassoCorrect =
          this.lassoValidator.checkIfModifiedLassoIsCorrect(
            currentLasso.polygon.toSpliced(
              this.state.movedPolygonPointIndex,
              1,
              point,
            ),
            chartScales,
            zoom,
          )
      }
    }

    this.renderLasso()

    if (
      previousState.__computed__isMouseInsideCurrentLasso !==
        this.state.__computed__isMouseInsideCurrentLasso ||
      previousState.__computed__hoveredPolygonPointIndex !==
        this.state.__computed__hoveredPolygonPointIndex ||
      previousState.__computed__hoveredLassoId !==
        this.state.__computed__hoveredLassoId
    ) {
      this.props.triggerReactRender()
    }
  }

  onMouseDown(_: Point, button: number | undefined): void {
    if (!this.state.isActive || button !== 0) {
      return
    }

    let previousLasso = this.getCurrentLasso()

    const hoveredLasso = this.getHoveredLasso()
    if (hoveredLasso?.isFinished) {
      this.state.currentLassoId = hoveredLasso.id
      this.state.isDrawingLasso = false
      if (this.state.__computed__hoveredPolygonPointIndex === undefined) {
        this.state.isMovingLasso = true
        this.props.triggerReactRender()
      }
    } else if (!this.state.isDrawingLasso) {
      const newLasso = this.createNewLasso()
      this.state.currentLassoId = newLasso.id
      this.state.lassos[newLasso.id] = newLasso
      previousLasso = newLasso
      this.props.triggerReactRender()
    }

    if (
      this.state.__computed__hoveredPolygonPointIndex !== undefined &&
      this.state.movedPolygonPointIndex === undefined &&
      previousLasso.id === this.state.__computed__hoveredLassoId
    ) {
      this.state.movedPolygonPointIndex =
        this.state.__computed__hoveredPolygonPointIndex
      this.state.__computed__hoveredPolygonPointIndex = undefined
      this.state.__computed__hoveredPolygonPoint = undefined
      this.props.triggerReactRender()
      if (previousLasso.type === LassoTypes.Rectangle) {
        const [x, y] = previousLasso.polygon[this.state.movedPolygonPointIndex]
        this.state.__computed__verticalConnectedVertexIndex =
          previousLasso.polygon.findIndex(
            point => point[0] === x && point[1] !== y,
          )
        this.state.__computed__horizontalConnectedVertexIndex =
          previousLasso.polygon.findIndex(
            point => point[0] !== x && point[1] === y,
          )
      }
    } else if (previousLasso.id !== this.state.currentLassoId) {
      this.renderLasso()
    }
  }

  onMouseUp(point: Point): void {
    if (!this.state.isActive || !this.state.mousePoint) {
      return
    }
    const previousState = this.cloneState()
    const currentLasso = this.getCurrentLasso()
    const previousLasso = cloneDeep(currentLasso)

    if (this.state.isMovingLasso && this.state.__computed__canDropLasso) {
      this.saveLasso()
      this.state.isMovingLasso = false
      this.props.triggerReactRender()
      return
    }

    if (this.state.movedPolygonPointIndex !== undefined) {
      if (
        this.state.mousePoint &&
        this.state.__computed__isModifiedLassoCorrect
      ) {
        if (currentLasso.type === LassoTypes.Rectangle) {
          currentLasso.polygon = this.lassoRenderer.resizeRectangle(
            point,
            currentLasso.polygon,
          )
          this.saveLasso()
        } else {
          const newPolygon = [...currentLasso.polygon]
          newPolygon[this.state.movedPolygonPointIndex] = this.state.mousePoint

          currentLasso.polygon = newPolygon
          this.saveLasso()
        }
      }
      this.state.movedPolygonPointIndex = undefined
    }

    if (!currentLasso.isFinished) {
      if (!this.state.isDrawingLasso) {
        this.state.isDrawingLasso = this.isDrawingEnabled()
      } else {
        if (this.state.lassoCreationMode === LassoTypes.Freeshape) {
          if (this.state.__computed__canFinishLasso) {
            currentLasso.isFinished = true
            this.state.isDrawingLasso = false
            this.props.onFinishLasso()
          } else if (this.state.__computed__canAddPoint) {
            currentLasso.polygon.push(this.state.mousePoint)
            this.props.triggerReactRender()
          }
        } else {
          if (this.state.isDrawingLasso) {
            const polygon = this.drawRectangle(point)
            if (
              this.lassoValidator.checkIfLassoIsInChartArea(
                polygon,
                this.props.chartScales,
                this.props.zoom,
              )
            ) {
              currentLasso.polygon.push(...polygon)
              currentLasso.isFinished = true
              this.state.isDrawingLasso = false
              this.props.onFinishLasso()
            }
          }
        }
      }
    }

    if (
      previousLasso.isFinished !== currentLasso.isFinished ||
      (currentLasso.isFinished &&
        !isEqual(previousLasso.polygon, currentLasso.polygon)) ||
      previousState.movedPolygonPointIndex !== this.state.movedPolygonPointIndex
    ) {
      this.props.triggerReactRender()
    }
  }

  onDoubleClick(point: Point): void {
    const currentLasso = this.getCurrentLasso()
    if (
      this.state.isActive &&
      currentLasso.isFinished &&
      pointInPolygon(point, currentLasso.polygon)
    ) {
      this.createChartFromLasso(currentLasso)
    }
  }

  onKeydown(event: KeyboardEvent): void {
    if (
      !this.state.isActive ||
      !(event.key === 'Escape' || event.key === 'Esc')
    ) {
      return
    }
    if (this.state.isDrawingLasso) {
      const lassoToCancel = this.getCurrentLasso()
      const newLasso = this.createNewLasso()
      this.state.lassos[newLasso.id] = newLasso
      this.state.currentLassoId = newLasso.id
      delete this.state.lassos[lassoToCancel.id]
      this.renderLasso()
      this.props.triggerReactRender()
    }
  }

  private getCurrentLasso(): LassoToolLasso {
    const currentLasso =
      this.state.currentLassoId && this.state.lassos[this.state.currentLassoId]
    if (!currentLasso) {
      throw handleError(new Error('Could not find current lasso'))
    }
    return currentLasso
  }

  private renderLasso() {
    this.lassoRenderer.render(this.state.__computed__hoveredPolygonPoint)
    this.lassoCursor.updateCursor()
  }

  private getHoveredLasso() {
    const { lassos, mousePoint } = this.state

    if (!Object.keys(lassos).length || !mousePoint) {
      return undefined
    }

    const lassoWithHoveredPoint = Object.values(lassos).find(lasso =>
      lasso.polygon.some(point =>
        this.lassoValidator.checkIfChartDistanceIsLessThan(
          point,
          mousePoint,
          10,
        ),
      ),
    )

    const hoveredLasso = Object.values(lassos).find(lasso =>
      pointInPolygon(mousePoint, lasso.polygon),
    )

    return lassoWithHoveredPoint ?? hoveredLasso
  }

  private getHoveredPolygonPointIndex() {
    const { isDrawingLasso, mousePoint, movedPolygonPointIndex } = this.state
    const hoveredLasso = this.getHoveredLasso()

    if (
      !hoveredLasso ||
      !mousePoint ||
      isDrawingLasso ||
      movedPolygonPointIndex !== undefined
    ) {
      return undefined
    }

    const index = hoveredLasso.polygon.findIndex(point =>
      this.lassoValidator.checkIfChartDistanceIsLessThan(point, mousePoint, 10),
    )

    return index !== -1 ? index : undefined
  }

  private cloneState() {
    return cloneDeep(omit(this.state, 'scatterPlotRef'))
  }

  private createNewLasso() {
    return {
      id: uuidv4(),
      name: '',
      type: this.state?.lassoCreationMode,
      polygon: [],
      isFinished: false,
    }
  }

  private moveLasso(point: Point, previousPoint: Point | undefined): void {
    if (point[0] !== previousPoint?.[0] || point[1] !== previousPoint?.[1]) {
      const currentLasso = this.getCurrentLasso()
      const xDiff = point[0] - (previousPoint?.[0] ?? 0)
      const yDiff = point[1] - (previousPoint?.[1] ?? 0)
      const newPolygon = currentLasso.polygon.map(
        point => [point[0] + xDiff, point[1] + yDiff] as Point,
      )
      currentLasso.polygon = newPolygon
    }
  }

  private drawRectangle(point: Point): Polygon {
    const { zoom } = this.props
    const { xMin, xMax, yMin, yMax } = this.props.chartScales
    if (!yMax || !yMin) {
      throw handleError(new Error('Y scales are undefined'))
    }
    const width = ((zoom?.x_max ?? xMax) - (zoom?.x_min ?? xMin)) / 10
    const height = ((zoom?.y_max ?? yMax) - (zoom?.y_min ?? yMin)) / 10
    const polygon: Point[] = [
      [point[0] - width, point[1] + height],
      [point[0] + width, point[1] + height],
      [point[0] + width, point[1] - height],
      [point[0] - width, point[1] - height],
    ]
    return polygon
  }

  private previousLassos: Analysis['lassos'] | undefined

  private setLassos(lassos: Analysis['lassos']): void {
    if (isEqual(this.previousLassos, lassos)) {
      return
    }
    this.previousLassos = lassos
    const updatedLassos = mapValues(lassos, lasso => ({
      id: lasso.id,
      name: lasso.name,
      type: lasso.type,
      polygon: [...lasso.polygon],
      isFinished: true,
    }))
    if (
      !(
        this.state.currentLassoId &&
        Object.keys(updatedLassos).includes(this.state.currentLassoId)
      )
    ) {
      const newLasso = this.createNewLasso()
      updatedLassos[newLasso.id] = newLasso
      this.state.currentLassoId = newLasso.id
    }
    this.state.lassos = updatedLassos
  }

  private isDrawingEnabled(): boolean {
    return this.props.analysisAccessMode === 'read-and-write'
  }
}
