import {
  CUMULATIVE_FLUORESCENCE_CHANNEL,
  TIME_CHANNEL,
  UMAP_X_CHANNEL,
  UMAP_Y_CHANNEL,
} from 'shared/constants'

export const WORKFLOW_NODE_WIDTH = 130
export const WORKFLOW_NODE_HEIGHT = 50

export const DEFAULT_TRANSFORMATION_FACTOR_BY_CYTOMETER = {
  conventional: 150,
  spectral: 50,
  mass: 5,
}

export const CLUSTERING_EXCLUDED_CHANNELS = [
  TIME_CHANNEL,
  CUMULATIVE_FLUORESCENCE_CHANNEL,
  UMAP_X_CHANNEL,
  UMAP_Y_CHANNEL,
]

export const MIN_KD = 1
export const MAX_KD = 100
export const MIN_KT = 1
export const MAX_KT = 10000
export const MIN_TRANSFORMATION_FACTOR = 1
export const MAX_TRANSFORMATION_FACTOR = 10000
export const DEFAULT_KT = 999 // granularity
export const DEFAULT_KD = 20 // precision
