import Highcharts from 'highcharts'
import HighchartsReact from 'highcharts-react-official'
import React, { useMemo, useRef } from 'react'
import { ErrorBoundary } from 'react-error-boundary'
import styled from 'styled-components'

import { useEventCallback } from 'shared/hooks/useEventCallback'
import { useSize } from 'shared/utils/utils'

import { theme } from 'Theme'

import { ErrorFallback } from '../ErrorFallback'

Highcharts.setOptions({
  chart: {
    animation: false,
    style: {
      fontFamily: theme.font.style.regular,
      color: theme.colors.primaryDark[100],
    },
  },
  title: {
    style: {
      fontFamily: theme.font.style.bold,
      fontSize: theme.font.size.small + 'px',
      color: theme.colors.primaryDark[100],
    },
  },
  xAxis: {
    title: {
      style: {
        fontFamily: theme.font.style.bold,
        color: theme.colors.primaryDark[100],
      },
    },
    labels: {
      style: {
        fontFamily: theme.font.style.regular,
        color: theme.colors.primaryDark[100],
      },
    },
  },
  yAxis: {
    title: {
      style: {
        fontFamily: theme.font.style.bold,
        color: theme.colors.primaryDark[100],
      },
    },
    labels: {
      style: {
        fontFamily: theme.font.style.regular,
        color: theme.colors.primaryDark[100],
      },
    },
  },
  tooltip: {
    style: {
      color: theme.colors.primaryDark[100],
    },
    useHTML: true,
    backgroundColor: theme.colors.white,
    padding: 0,
    borderColor: theme.colors.primaryDark[20],
    borderWidth: 1,
    borderRadius: theme.radius[2],
    shadow: {
      offsetX: 0,
      offsetY: 2,
      opacity: 0.2,
      color: 'rgba(0, 0, 0, 0.2)',
    },
    hideDelay: 100,
    outside: true,
  },
  legend: {
    enabled: false,
    itemStyle: {
      color: theme.colors.primaryDark[100],
      fontFamily: theme.font.style.regular,
      fontSize: '12px',
    },
  },
  credits: {
    enabled: false,
  },
  exporting: {
    enabled: false,
  },
})

type ChartProps = HighchartsReact.Props & {
  shouldAutomaticallyAsignSize?: boolean
  onContextMenu?: (
    event: React.MouseEvent<HTMLDivElement, MouseEvent>,
    point: Highcharts.Point | undefined,
  ) => void
}

export const Chart = React.forwardRef<HighchartsReact.RefObject, ChartProps>(
  (
    { shouldAutomaticallyAsignSize = true, onContextMenu, options, ...props },
    ref,
  ): JSX.Element => {
    const chartRef = useRef<HighchartsReact.RefObject>()
    const [chartWrapperRef, { width, height }] = useSize<HTMLDivElement>({
      debounce: 250,
    })

    const handleContextMenu = useEventCallback(
      (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
        if (!onContextMenu) return

        event.preventDefault()
        const point = chartRef.current?.chart.hoverPoint ?? undefined
        onContextMenu(event, point)
      },
    )

    const handleSetRef = useEventCallback(
      (chart: HighchartsReact.RefObject) => {
        chartRef.current = chart
        if (ref) {
          if (typeof ref === 'function') {
            ref(chart)
          } else {
            ref.current = chart
          }
        }
      },
    )

    const optionsWithSize = useMemo(() => {
      return {
        ...options,
        chart: {
          ...options?.chart,
          width:
            shouldAutomaticallyAsignSize && !options?.chart?.width
              ? width
              : options?.chart?.width,
          height:
            shouldAutomaticallyAsignSize && !options?.chart?.height
              ? height
              : options?.chart?.height,
        },
      }
    }, [height, options, shouldAutomaticallyAsignSize, width])

    return (
      <ErrorBoundary FallbackComponent={ErrorFallback}>
        <ChartWrapper ref={chartWrapperRef} onContextMenu={handleContextMenu}>
          <HighchartsReact
            ref={handleSetRef}
            highcharts={Highcharts}
            options={optionsWithSize}
            {...props}
          />
        </ChartWrapper>
      </ErrorBoundary>
    )
  },
)

const ChartWrapper = styled.div`
  width: 100%;
  height: 100%;
  overflow: hidden;
`
