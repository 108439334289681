import { FC, useMemo, useRef, useState } from 'react'
import styled from 'styled-components'

import { Drawer } from 'components/Drawer'
import { Layout, LayoutElement } from 'components/chart-grid'
import { ChartGrid } from 'components/chart-grid/ChartGrid'
import { AnalysisHistogram } from 'components/graphs/AnalysisHistogram'
import { AnalysisLassosHierarchyGraph } from 'components/graphs/AnalysisLassosHierarchyGraph'
import { AnalysisMetaCleanChart } from 'components/graphs/AnalysisMetaCleanChart'
import { AnalysisScatterPlot } from 'components/graphs/AnalysisScatterPlot'

import {
  ADD_CHART_PLACEHOLDER_ID,
  ANALYSIS_HEATMAP_ID,
  ANALYSIS_HIERARCHY_ID,
  ANALYSIS_LASSOS_HIERARCHY_GRAPH_ID,
  CLUSTER_LIST_ID,
  CUMULATIVE_FLUORESCENCE_CHANNEL,
  MULTI_TAB_MENU_ID,
} from 'shared/constants'
import { useEventCallback } from 'shared/hooks/useEventCallback'
import { Graph } from 'shared/models/Graphs'
import { useAppDispatch, useAppSelector } from 'shared/store'
import { getCurrentLayoutKey } from 'shared/utils/multi-tab'
import { includeIf } from 'shared/utils/utils'

import { AddLayoutItem } from './AddLayoutItem'
import { AnalysisClustersList } from './AnalysisClusterList'
import { AnalysisHeatmap } from './AnalysisHeatmap'
import { AnalysisHierarchy } from './AnalysisHierarchy'
import { AnalysisMultiTabMenu } from './AnalysisMultiTabMenu'
import { AnalysisStatistics } from './AnalysisStatistics'
import { EditDisplayedChannels } from './EditDisplayChannels'
import { OrganizeHeatmapMenu } from './OrganizeHeatmapMenu'
import {
  addChart as addChartActionCreator,
  addStatistics,
  changeSpecificLayout,
  updateSpecificLayout,
} from './store/analysis.slice'
import {
  selectAnalysisAccessMode,
  selectAnalysisStatistics,
  selectCharts,
  selectLayout,
} from './store/selectors'

export const AnalysisLayout: FC = () => {
  const dispatch = useAppDispatch()
  const graphs = useAppSelector(selectCharts)
  const statistics = useAppSelector(selectAnalysisStatistics)
  const layout = useAppSelector(selectLayout)
  const analysisAccessMode = useAppSelector(selectAnalysisAccessMode)

  const graphsContainer = useRef<HTMLDivElement>(null)

  const currentLayoutKey = getCurrentLayoutKey()

  const handleAddChart = useEventCallback((chart: Graph) => {
    dispatch(addChartActionCreator({ chart }))
    setTimeout(() => {
      if (graphsContainer.current) {
        graphsContainer.current.scrollTo({
          top: graphsContainer.current.scrollHeight,
          behavior: 'smooth',
        })
      }
    }, 0)
  })

  const handleAddStatistics = useEventCallback(
    (statistics: Analysis.Statistics) => {
      dispatch(
        addStatistics({
          statistics,
        }),
      )
      setTimeout(() => {
        if (graphsContainer.current) {
          graphsContainer.current.scrollTo({
            top: graphsContainer.current.scrollHeight,
            behavior: 'smooth',
          })
        }
      }, 0)
    },
  )

  const handleChangeSpecificLayout = useEventCallback(
    (key: keyof Layout, layout: LayoutElement) => {
      dispatch(
        changeSpecificLayout({
          layoutKey: key,
          layout,
        }),
      )
    },
  )

  const handleUpdateSpecificLayout = useEventCallback(
    (key: keyof Layout, layout: LayoutElement) => {
      dispatch(
        updateSpecificLayout({
          layoutKey: key,
          layout,
        }),
      )
    },
  )

  const [editDisplayedChannelsDrawer, setEditDisplayedChannelsDrawer] =
    useState(false)
  const [editHeatmapLayoutDrawer, setEditHeatmapLayoutDrawer] = useState(false)

  const handleOpenEditChannelsDrawer = useEventCallback(() => {
    setEditDisplayedChannelsDrawer(true)
  })

  const handleOpenEditHeatmapLayoutDrawer = useEventCallback(() => {
    setEditHeatmapLayoutDrawer(true)
  })

  const items = useMemo(() => {
    const analysisHierarchy = <AnalysisHierarchy key={ANALYSIS_HIERARCHY_ID} />

    const analysisHeatmap = (
      <AnalysisHeatmap
        key={ANALYSIS_HEATMAP_ID}
        openEditDisplayedChannelsDrawer={handleOpenEditChannelsDrawer}
        openEditHeatmapLayoutDrawer={handleOpenEditHeatmapLayoutDrawer}
      />
    )

    const clusterList = <AnalysisClustersList key={CLUSTER_LIST_ID} />

    const lassosHierarchyChart = (
      <AnalysisLassosHierarchyGraph key={ANALYSIS_LASSOS_HIERARCHY_GRAPH_ID} />
    )

    const addLayoutItem = (
      <AddLayoutItem
        key={ADD_CHART_PLACEHOLDER_ID}
        onAddChart={handleAddChart}
        onAddStatistics={handleAddStatistics}
      />
    )
    const multiTabModeMenu = <AnalysisMultiTabMenu key={MULTI_TAB_MENU_ID} />

    const charts = graphs.map(graph => {
      if (graph.y_axis === CUMULATIVE_FLUORESCENCE_CHANNEL) {
        return <AnalysisMetaCleanChart key={graph.id} chart={graph} />
      }

      if (graph.chart_type === 'Dot plot') {
        return <AnalysisScatterPlot key={graph.id} chart={graph} />
      }

      if (graph.chart_type === 'Histogram') {
        return <AnalysisHistogram key={graph.id} chart={graph} />
      }

      throw new Error(`Unsupported chart type: ${graph.chart_type}`)
    })

    const statisticsElements = statistics.map(statistics => {
      return (
        <AnalysisStatistics key={statistics.id} statisticsItem={statistics} />
      )
    })

    const items = [
      analysisHierarchy,
      analysisHeatmap,
      clusterList,
      lassosHierarchyChart,
      ...includeIf(analysisAccessMode === 'read-and-write', [addLayoutItem]),
      multiTabModeMenu,
      ...charts,
      ...statisticsElements,
    ].filter(item => {
      return (item.key as string) in layout[currentLayoutKey]
    })

    return items
  }, [
    handleOpenEditChannelsDrawer,
    handleOpenEditHeatmapLayoutDrawer,
    handleAddChart,
    handleAddStatistics,
    graphs,
    statistics,
    analysisAccessMode,
    layout,
    currentLayoutKey,
  ])

  return (
    <AnalysisLayoutRoot>
      <ChartGrid
        layout={layout}
        onChangeSpecificLayout={handleChangeSpecificLayout}
        onUpdateSpecificLayout={handleUpdateSpecificLayout}
      >
        {items}
      </ChartGrid>
      <Drawer
        open={editDisplayedChannelsDrawer}
        onClose={() => setEditDisplayedChannelsDrawer(false)}
        onBackdropClick={() => setEditDisplayedChannelsDrawer(false)}
      >
        <EditDisplayedChannels
          onClose={() => setEditDisplayedChannelsDrawer(false)}
        />
      </Drawer>
      <Drawer
        open={editHeatmapLayoutDrawer}
        onClose={() => setEditHeatmapLayoutDrawer(false)}
        onBackdropClick={() => setEditHeatmapLayoutDrawer(false)}
        anchor="right"
      >
        <OrganizeHeatmapMenu
          closeDrawer={() => setEditHeatmapLayoutDrawer(false)}
        />
      </Drawer>
    </AnalysisLayoutRoot>
  )
}

const AnalysisLayoutRoot = styled.div``
