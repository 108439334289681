import { Typography } from '@material-ui/core'
import styled from 'styled-components'

import {
  RESOURCE_ICONS,
  RESOURCE_LABELS,
  ResourceType,
} from 'shared/models/LicenseModels'

type PackageResourceCardProps = {
  type: ResourceType
  value: number | string
}

export const PackageResourceCard = ({
  type,
  value,
}: PackageResourceCardProps): JSX.Element => {
  const Icon = RESOURCE_ICONS[type]
  const valueText = getValueText(type, value)

  return (
    <PackageResourceCardRoot>
      <Icon />
      <Description>
        <Typography variant="h5">{valueText}</Typography>
        <p>{RESOURCE_LABELS[type]}</p>
      </Description>
    </PackageResourceCardRoot>
  )
}

const getValueText = (type: ResourceType, value: number | string) => {
  if (type === 'CG') {
    return value === 0 ? 'Inactive' : value === 1 ? 'Active' : 'Available'
  }

  if (type === 'SP') {
    return `Level ${value}`
  }

  return value
}

const PackageResourceCardRoot = styled.div`
  display: flex;
  height: 64px;
  width: calc(100% - 16px);
  padding: 12px;
  margin: 4px 0;
  align-items: center;
  background-color: white;
  border: 1px solid ${props => props.theme.palette.grey[300]};
  border-radius: 4px;

  svg {
    height: 42px;
    width: 42px;
    color: ${props => props.theme.palette.primary.main};
    margin-right: 12px;
  }
`

const Description = styled.div`
  display: flex;
  flex-direction: column;
`
