import Highcharts from 'highcharts'

import { AnalysisSunburstBaseOptions } from 'components/graphs/AnalysisSunburstBaseOptions'

import {
  selectActiveClusters,
  selectGloballyHiddenClusters,
} from 'pages/analysis/store/selectors'

import { RootState } from 'shared/store'
import { computeSunburstSeries } from 'shared/worker/analysis-worker'

type RenderSunburstProps = {
  state: RootState
  width: number
  height: number
}

export const renderSunburst = async ({
  state,
  width,
  height,
}: RenderSunburstProps): Promise<string> => {
  const activeClusters = selectActiveClusters(state)
  const globallyHiddenClusters = selectGloballyHiddenClusters(state)

  const series = await computeSunburstSeries({
    activeClusters,
    globallyHiddenClusters,
  })

  return new Highcharts.Chart(document.createElement('div'), {
    ...AnalysisSunburstBaseOptions,
    chart: {
      ...AnalysisSunburstBaseOptions.chart,
      height: Math.min(width, height),
      width: Math.min(width, height),
    },
    series,
  }).getSVG()
}
