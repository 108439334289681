import { User } from 'shared/models/User'
import { toQueryString } from 'shared/utils/utils'

import { PaginatedResults } from '../models/Result'
import { privateApi } from './private.api'
import { encodeTagParameters } from './utils'

export const projectsApi = privateApi.injectEndpoints({
  endpoints: builder => ({
    getProjects: builder.query<PaginatedResults<Project[]>, GetProjectsPayload>(
      {
        query: payload =>
          `projects/?${toQueryString({
            page: payload.page,
            search: payload.searchQuery,
            ordering: payload.ordering,
          })}`,
        providesTags: [
          { type: 'Project', id: encodeTagParameters({ id: 'list' }) },
        ],
      },
    ),
    getProject: builder.query<Project, string>({
      query: payload => `projects/${payload}/`,
      providesTags: (_result, _error, args) => [
        { type: 'Project', id: encodeTagParameters({ id: args }) },
      ],
    }),
    updateProject: builder.mutation<unknown, UpdateProjectPayload>({
      query: ({ id, ...payload }) => ({
        url: `/projects/${id}/`,
        method: 'PUT',
        body: payload,
      }),
    }),
    deleteProject: builder.mutation<unknown, string>({
      query: payload => ({
        url: `/projects/${payload}/`,
        method: 'DELETE',
      }),
    }),
    createProject: builder.mutation<Project, CreateProjectPayload>({
      query: payload => ({
        url: `/projects/`,
        method: 'POST',
        body: { name: payload.name, description: payload.description },
      }),
    }),
  }),
})

export const {
  useGetProjectsQuery,
  useGetProjectQuery,
  useUpdateProjectMutation,
  useDeleteProjectMutation,
  useCreateProjectMutation,
} = projectsApi

type GetProjectsPayload = {
  page: number
  searchQuery?: string
  ordering?: string
}

type CreateProjectPayload = {
  name: string
  description: string
}

type UpdateProjectPayload = {
  id: string
  name: string
  description?: string
}

export type Project = {
  id: string
  name: string
  created_at: string
  description?: string
  author: User | null
  num_experiments: number
  num_files: number
}
