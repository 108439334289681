import { CircularProgress } from '@material-ui/core'
import { FC, useMemo, useState } from 'react'
import { Link } from 'react-router-dom'
import styled from 'styled-components'

import { Header } from 'components/Header'
import { OptionsContextMenu } from 'components/OptionsContextMenu'
import { Button } from 'components/button/Button'
import { SearchInput } from 'components/input/SearchInput'
import { EmptyTableInfo } from 'components/table/EmptyTableInfo'
import { TableColumn, TableRow } from 'components/table/Table'
import { TableWithPagination } from 'components/table/TableWithPagination'
import { useTableSorting } from 'components/table/useTableSorting'

import {
  Project,
  useDeleteProjectMutation,
  useGetProjectsQuery,
} from 'shared/api/projects.api'
import { useGetUserQuery } from 'shared/api/user.api'
import { useEventCallback } from 'shared/hooks/useEventCallback'
import { formatDateTime } from 'shared/utils/utils'

import { CreateProjectModal } from './CreateProjectModal'
import { EditProjectModal } from './EditProjectModal'

export const ProjectsPage: FC = () => {
  const { sortingColumn, sortingDirection, orderingString, setSorting } =
    useTableSorting('created_at', 'desc')

  const [page, setPage] = useState(1)
  const [shownModal, setShownModal] = useState<
    { type: 'create-project' } | { type: 'project-settings'; project: Project }
  >()
  const [searchValue, setSearchValue] = useState('')

  const projectsQueryState = useGetProjectsQuery({
    page,
    ordering: orderingString,
    searchQuery: searchValue,
  })

  const [triggerDeleteProject] = useDeleteProjectMutation()

  const userFirstName = useGetUserQuery().data?.first_name

  const handleDeleteProject = useEventCallback((projectId: string) => {
    triggerDeleteProject(projectId)
  })

  const rows: TableRow[] = useMemo(() => {
    if (!projectsQueryState.data) {
      return []
    }
    return projectsQueryState.data.results.map(project => ({
      key: project.id,
      cells: [
        {
          key: 'projectName',
          content: (
            <ProjectLink to={`/projects/${project.id}/raw-fcs-files`}>
              {project.name}
            </ProjectLink>
          ),
        },
        {
          key: 'creationDate',
          content: formatDateTime(project.created_at) ?? '',
        },
        {
          key: 'experiments',
          content: `${project.num_experiments} Experiment${
            project.num_experiments !== 1 ? 's' : ''
          }`,
        },
        {
          key: 'files',
          content: `${project.num_files} File${
            project.num_files !== 1 ? 's' : ''
          }`,
        },
        {
          key: 'author',
          content: (
            <ProjectAuthor>
              <p>
                {project.author
                  ? `${project.author.first_name} ${project.author.last_name}`
                  : ''}
              </p>
              <ProjectEmail>
                {project.author ? project.author.email : ''}
              </ProjectEmail>
            </ProjectAuthor>
          ),
        },
        {
          key: 'actions',
          content: (
            <ProjectActions>
              <OptionsContextMenu
                options={[
                  {
                    label: 'Edit',
                    onClick: () =>
                      setShownModal({ type: 'project-settings', project }),
                  },
                  {
                    label: 'Delete',
                    textColor: 'error',
                    onClick: () => handleDeleteProject(project.id),
                  },
                ]}
              />
            </ProjectActions>
          ),
        },
      ],
    }))
  }, [handleDeleteProject, projectsQueryState.data])

  const columns: TableColumn[] = [
    {
      key: 'name',
      label: 'Project Name',
      style: { width: '25%' },
      canSort: true,
    },
    {
      key: 'created_at',
      label: 'Creation Date',
      style: { width: '20%' },
      canSort: true,
    },
    {
      key: 'num_experiments',
      label: 'Experiments',
      style: { width: '15%' },
      canSort: true,
    },
    {
      key: 'num_files',
      label: 'Files',
      style: { width: '10%' },
      canSort: true,
    },
    {
      key: 'author__last_name',
      label: 'Author',
      style: { width: '25%' },
      canSort: true,
    },
    {
      key: 'actions',
      style: { width: '5%' },
      label: undefined,
      canSort: false,
    },
  ]

  const CreateProjectButton = (
    <CreateButton
      onClick={() => setShownModal({ type: 'create-project' })}
      colorOverride="success"
    >
      + Add a new project
    </CreateButton>
  )

  if (rows === undefined) {
    return null
  } else {
    return (
      <ProjectsPageRoot>
        <StyledHeader shouldShowMetaflowLogo />
        <Main $isLoading={projectsQueryState.isFetching}>
          <SubHeader>
            <Title>
              <TitleText>Welcome, {userFirstName}</TitleText>
              <Caption>Pick one of the projects or create one to start</Caption>
            </Title>
            {CreateProjectButton}
          </SubHeader>
          <ProjectSearchInput
            placeholder="Search projects"
            onChange={setSearchValue}
          />
          {rows.length !== 0 ? (
            <TableWithPagination
              rows={rows}
              columns={columns}
              sortingColumn={sortingColumn}
              sortingDirection={sortingDirection}
              onSortingChange={setSorting}
              page={page}
              pageCount={projectsQueryState.data?.page_count ?? 1}
              onPageChange={(_, page) => setPage(page)}
            />
          ) : (
            <StyledEmptyTableInfo text="No projects found" iconName="projects">
              {CreateProjectButton}
            </StyledEmptyTableInfo>
          )}
          {shownModal?.type === 'create-project' && (
            <CreateProjectModal onClose={() => setShownModal(undefined)} />
          )}
          {shownModal?.type === 'project-settings' && (
            <EditProjectModal
              project={shownModal.project}
              onClose={() => setShownModal(undefined)}
            />
          )}
          {projectsQueryState.isFetching && <StyledCircularProgress />}
        </Main>
      </ProjectsPageRoot>
    )
  }
}

const ProjectsPageRoot = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  flex: 1;
`

const Main = styled.div<{ $isLoading: boolean }>`
  margin: ${props => props.theme.spacing(4)}px 100px 50px;
  position: relative;
  display: flex;
  flex-direction: column;
  gap: ${props => props.theme.spacing(4)}px;
  pointer-events: ${props => (props.$isLoading ? 'none' : 'auto')};
`

const SubHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`

const Title = styled.div`
  display: flex;
  flex-direction: column;
`

const TitleText = styled.h1`
  margin: ${props => props.theme.spacing(1)}px 0;
  font-family: OpenSans-bold;
`

const Caption = styled.p`
  font-size: ${props => props.theme.font.size.smallest}px;
  font-family: ${props => props.theme.font.style.light};
  color: ${props => props.theme.colors.primaryDark[50]};
`

const CreateButton = styled(Button)`
  height: 48px;
  border-radius: ${props => props.theme.radius[3]}px;
`

const ProjectLink = styled(Link)`
  color: inherit;
  text-decoration: none;

  :hover {
    text-decoration: underline;
  }
`

const ProjectActions = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`

export const ProjectAuthor = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: ${props => props.theme.spacing(1)}px 0;
`

export const ProjectEmail = styled.p`
  color: ${props => props.theme.colors.primaryDark[50]};
  font-size: ${props => props.theme.font.size.smallest}px;
  font-family: ${props => props.theme.font.style.regular};
`

const StyledCircularProgress = styled(CircularProgress)`
  position: absolute;
  left: 50%;
  top: 50%;
`

const StyledHeader = styled(Header)`
  margin: 60px 100px 0;
`

const ProjectSearchInput = styled(SearchInput)`
  width: 40%;
  min-width: 200px;
  max-width: 400px;
`

const StyledEmptyTableInfo = styled(EmptyTableInfo)`
  margin-top: ${props => props.theme.spacing(4)}px;
`
