import {
  TableCell as MuiTableCell,
  TableRow as MuiTableRow,
  Radio,
  Tooltip,
} from '@material-ui/core'
import { FC } from 'react'
import styled from 'styled-components'

import { Checkbox } from 'components/Checkbox'

import { TableCell, TableColumn } from './Table'

export type TableRowProps = {
  id: string
  columns: TableColumn[]
  cells: TableCell[]
  selectedElements?: string[]
  shouldDisableCheckbox?: boolean
  compactMode?: boolean
  className?: string
  selectMultiple?: boolean
  tooltip?: string
  onToggleSelection?: (element: string) => void
}

export const TableRow: FC<TableRowProps> = ({
  id,
  columns,
  cells,
  selectedElements,
  shouldDisableCheckbox,
  compactMode,
  className,
  selectMultiple = true,
  tooltip = '',
  onToggleSelection,
}) => {
  return (
    <Tooltip title={tooltip} arrow>
      <StyledTableRow className={className}>
        {onToggleSelection !== undefined ? (
          <StyledTableCell $compactMode={compactMode}>
            {selectMultiple ? (
              <Checkbox
                checked={selectedElements?.includes(id)}
                disabled={shouldDisableCheckbox}
                onChange={() => onToggleSelection(id)}
              />
            ) : (
              <StyledRadio
                checked={selectedElements?.includes(id)}
                disabled={shouldDisableCheckbox}
                onChange={() => onToggleSelection(id)}
              />
            )}
          </StyledTableCell>
        ) : null}
        {cells.map((cell, cellIndex) => (
          <StyledTableCell
            style={columns[cellIndex].style}
            $compactMode={compactMode}
            key={id + cell.key}
            title={
              cell.title
                ? cell.title
                : typeof cell.content === 'string'
                ? cell.content
                : typeof cell.content === 'number'
                ? cell.content.toLocaleString()
                : ''
            }
          >
            {cell.content}
          </StyledTableCell>
        ))}
      </StyledTableRow>
    </Tooltip>
  )
}

export const StyledTableRow = styled(MuiTableRow)`
  background-color: ${props => props.theme.colors.white};
  box-shadow: 0px 4px 10px 0px #e8e9f399;
  border-radius: ${props => props.theme.radius[3]}px;
`

export const StyledTableCell = styled(MuiTableCell)<{
  $compactMode?: boolean
}>`
  padding: ${props => props.theme.spacing(props.$compactMode ? 0.5 : 2)}px 0;
  border: none;
  font-family: ${props => props.theme.font.style.bold};
  color: ${props => props.theme.colors.primaryDark[70]};
  padding-right: 16px;

  &:first-child {
    padding-left: ${props => props.theme.spacing(props.$compactMode ? 2 : 4)}px;
    border-top-left-radius: ${props => props.theme.radius[3]}px;
    border-bottom-left-radius: ${props => props.theme.radius[3]}px;
  }

  &:last-child {
    padding-right: ${props =>
      props.theme.spacing(props.$compactMode ? 2 : 4)}px;
    border-top-right-radius: ${props => props.theme.radius[3]}px;
    border-bottom-right-radius: ${props => props.theme.radius[3]}px;
  }
`

const StyledRadio = styled(Radio)`
  &.MuiRadio-root {
    color: ${props => props.theme.colors.primaryDark[70]};
  }
`
