import { Table as MuiTable, TableBody as MuiTableBody } from '@material-ui/core'
import { CSSProperties, FC, ReactNode } from 'react'
import styled from 'styled-components'

import { CollapsibleTableRow } from './CollapsibleTableRow'
import { TableHead } from './TableHead'
import { TableRow } from './TableRow'

export interface TableCell {
  key: string
  title?: string
  content: ReactNode
}

export interface TableRow {
  key: string
  cells: TableCell[]
  shouldDisableCheckbox?: boolean
  children?: ReactNode
  tooltip?: string
}

export interface TableColumn {
  key: string
  label?: ReactNode
  canSort?: boolean
  style?: CSSProperties
}

export interface TableProps {
  columns: TableColumn[]
  rows: Array<TableRow>
  compactMode?: boolean
  stickyHeaderEnabled?: boolean
  sortingColumn?: string
  sortingDirection?: 'asc' | 'desc'
  selectedElements?: string[]
  areAllSelected?: boolean
  isSelectionDisabled?: boolean
  className?: string
  showHeader?: boolean
  selectMultiple?: boolean
  selectAllProps?: Record<string, unknown>
  onSortingChange?: (column: string) => void
  onToggleSelection?: (element: string) => void
  onToggleSelectAll?: () => void
}

export const Table: FC<TableProps> = ({
  columns,
  rows,
  compactMode,
  stickyHeaderEnabled,
  sortingColumn,
  sortingDirection,
  selectedElements,
  areAllSelected,
  isSelectionDisabled,
  className,
  showHeader = true,
  selectMultiple = true,
  selectAllProps,
  onSortingChange,
  onToggleSelection,
  onToggleSelectAll,
}) => {
  const hasCollapsibleRows = rows.some(row => !!row.children)

  return (
    <StyledTable stickyHeader={stickyHeaderEnabled} className={className}>
      {showHeader && (
        <TableHead
          hasCollapsibleRows={hasCollapsibleRows}
          columns={columns}
          currentSortingColumn={sortingColumn}
          currentSortingDirection={sortingDirection}
          areAllSelected={areAllSelected}
          isSelectionDisabled={isSelectionDisabled}
          hasSelectableRows={!selectMultiple}
          selectAllProps={selectAllProps}
          onSortingChange={onSortingChange}
          onToggleSelectAll={onToggleSelectAll}
        />
      )}
      <MuiTableBody>
        {rows.map(row =>
          row.children ? (
            <CollapsibleTableRow
              id={row.key}
              columns={columns}
              compactMode={compactMode}
              selectedElements={selectedElements}
              selectMultiple={selectMultiple}
              onToggleSelection={onToggleSelection}
              {...row}
            >
              {row.children}
            </CollapsibleTableRow>
          ) : (
            <TableRow
              id={row.key}
              columns={columns}
              compactMode={compactMode}
              selectedElements={selectedElements}
              selectMultiple={selectMultiple}
              onToggleSelection={onToggleSelection}
              {...row}
            />
          ),
        )}
      </MuiTableBody>
    </StyledTable>
  )
}

const StyledTable = styled(MuiTable)`
  border-collapse: separate;
  border-spacing: 0 ${props => props.theme.spacing(1)}px;
`
