import { CSSProperties, ReactNode } from 'react'
import styled from 'styled-components'

type WizardSectionCardProps = {
  className?: string
  title: ReactNode
  children: ReactNode
  contentStyle?: CSSProperties
}

export const WizardSectionCard: React.FC<WizardSectionCardProps> = ({
  className,
  title,
  children,
  contentStyle,
}) => {
  return (
    <WizardSectionCardRoot className={className}>
      <WizardSectionCardHeader>{title}</WizardSectionCardHeader>
      <WizardSectionCardContent style={contentStyle}>
        {children}
      </WizardSectionCardContent>
    </WizardSectionCardRoot>
  )
}

const WizardSectionCardRoot = styled.div`
  width: 100%;
  margin-bottom: ${props => props.theme.spacing(3)}px;
  border-radius: ${props => props.theme.radius[4]}px;
  box-shadow: 0px 2px 10px 0px #e8e9f399;
`

const WizardSectionCardHeader = styled.div`
  width: 100%;
  padding: ${props => props.theme.spacing(2)}px
    ${props => props.theme.spacing(3)}px;
  background: #f5f5f5;
  border-radius: ${props => props.theme.radius[4]}px;
`

const WizardSectionCardContent = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${props => props.theme.spacing(2)}px;
  padding: ${props => props.theme.spacing(3)}px
    ${props => props.theme.spacing(4)}px;
`
