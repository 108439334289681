import { Typography } from '@material-ui/core'
import { filesize } from 'filesize'
import { useFormikContext } from 'formik'
import { FC, useEffect, useState } from 'react'
import styled from 'styled-components'

import { ReactComponent as FilesIcon } from 'assets/images/icons/fcs-file.svg'

import { CircularProgress } from 'components/CircularProgress'
import { SearchInput } from 'components/input/SearchInput'
import { EmptyTableInfo } from 'components/table/EmptyTableInfo'
import { TableColumn, TableRow } from 'components/table/Table'
import { TableWithPagination } from 'components/table/TableWithPagination'
import {
  DateFilter,
  DateFilterValue,
} from 'components/table/filters/DateFilter'
import { MultipleSelectFilterWithPagination } from 'components/table/filters/MultipleSelectFilterWithPagination'
import { NumberRangeFilter } from 'components/table/filters/NumberRangeFilter'
import { SelectFilter } from 'components/table/filters/SelectFilter'
import { useMultipleSelectFilter } from 'components/table/filters/useMultipleSelectFilter'
import { useTableSelection } from 'components/table/useTableSelection'
import { useTableSorting } from 'components/table/useTableSorting'

import {
  CompensatedFileStatus,
  FileStatus,
  useGetCompensatedFileAuthorsQuery,
  useGetCompensatedFileBatchNamesQuery,
  useGetCompensatedFileIdsQuery,
  useGetCompensatedFilesQuery,
} from 'shared/api/files.api'
import { formatDateTime } from 'shared/utils/utils'

import { CreateExperimentFormValues } from './CreateExperimentWizard'
import { StatusChip } from './StatusChip'

type CreateExperimentWizardSelectCompensatedFilesStepProps = {
  projectId: string
}

export const CreateExperimentWizardSelectCompensatedFilesStep: FC<
  CreateExperimentWizardSelectCompensatedFilesStepProps
> = ({ projectId }) => {
  const { values, setFieldValue } =
    useFormikContext<CreateExperimentFormValues>()

  const [page, setPage] = useState(1)
  const [searchValue, setSearchValue] = useState('')
  const [dateFilterValue, setDateFilterValue] =
    useState<DateFilterValue>(undefined)
  const [sizeFilterValue, setSizeFilterValue] = useState<
    [number | undefined, number | undefined]
  >([undefined, undefined])
  const [selectedStatus, setSelectedStatus] = useState<
    `${CompensatedFileStatus}` | undefined
  >()

  const { sortingColumn, sortingDirection, orderingString, setSorting } =
    useTableSorting('file_name', 'desc')

  const {
    selectedIds: selectedBatchNames,
    page: batchNamesFilterPage,
    filterSearchValue: batchNamesFilterSearchValue,
    handleSelectOption: handleSelectBatchName,
    handlePageChange: handleBatchNamesFilterPageChange,
    handleFilterSearchValueChange: handleBatchNamesFilterSearchValueChange,
  } = useMultipleSelectFilter()

  const {
    selectedIds: selectedAuthorIds,
    page: authorsFilterPage,
    filterSearchValue: authorsFilterSearchValue,
    handleSelectOption: handleSelectAuthor,
    handleFilterSearchValueChange: handleAuthorsFilterSearchValueChange,
    handlePageChange: handleAuthorsFilterPageChange,
  } = useMultipleSelectFilter()

  const compensatedFilesQueryFilters = {
    name: searchValue,
    batch_name: selectedBatchNames.join(','),
    author: selectedAuthorIds.join(','),
    created_at_after: dateFilterValue?.[0]?.toISOString(),
    created_at_before: dateFilterValue?.[1]?.toISOString(),
    size_min: sizeFilterValue[0] ? sizeFilterValue[0] * 1000 : undefined,
    size_max: sizeFilterValue[1] ? sizeFilterValue[1] * 1000 : undefined,
    status: selectedStatus,
  }

  const getCompensatedFilesQueryState = useGetCompensatedFilesQuery({
    projectId,
    page,
    ordering: orderingString,
    search: compensatedFilesQueryFilters,
  })

  const compensatedFileIdsQueryState = useGetCompensatedFileIdsQuery({
    search: {
      ...compensatedFilesQueryFilters,
      project: projectId,
      status: CompensatedFileStatus.Ready,
    },
  })

  const getCompensatedFileBatchNamesQueryState =
    useGetCompensatedFileBatchNamesQuery(
      {
        projectId,
        page: batchNamesFilterPage,
        search: {
          lookup_batch_name: batchNamesFilterSearchValue,
        },
      },
      { refetchOnMountOrArgChange: true },
    )

  const getCompensatedFileAuthorsQueryState = useGetCompensatedFileAuthorsQuery(
    {
      projectId,
      page: authorsFilterPage,
      search: {
        lookup_author: authorsFilterSearchValue,
      },
    },
  )

  const {
    selectedIds,
    areAllSelected,
    handleSelect,
    handleSelectAll,
    handleSelectCurrentPage,
  } = useTableSelection({
    allIds: compensatedFileIdsQueryState.data ?? [],
    currentPageIds:
      getCompensatedFilesQueryState.data?.results
        .filter(file => file.status === FileStatus.Ready)
        .map(file => file.id) ?? [],
    initialSelectedIds: values.compensatedFiles,
  })

  useEffect(() => {
    setFieldValue('compensatedFiles', selectedIds)
  }, [selectedIds, setFieldValue])

  const columns: TableColumn[] = [
    {
      key: 'batch_name',
      label: 'Batch Name',
      canSort: true,
      style: { width: 100 },
    },
    {
      key: 'file_name',
      label: 'File name',
      style: { width: '25%' },
      canSort: true,
    },
    {
      key: 'status',
      label: 'Status',
      canSort: false,
    },
    {
      key: 'total_cell_count',
      label: 'Total cell count',
      style: { width: '10%' },
      canSort: true,
    },
    {
      key: 'author',
      label: 'Author',
      style: { width: '20%' },
      canSort: true,
    },
    {
      key: 'size',
      label: 'Size',
      style: { width: '5%' },
      canSort: true,
    },
    {
      key: 'analyses',
      label: 'Analyses',
      style: { width: '10%' },
      canSort: true,
    },
    {
      key: 'sample_acquisition_date',
      label: 'Sample Acquisition',
      style: { width: '20%' },
      canSort: true,
    },
  ]

  const rows: TableRow[] =
    getCompensatedFilesQueryState.data?.results.map(file => ({
      key: file.id,
      shouldDisableCheckbox: file.status !== FileStatus.Ready,
      cells: [
        {
          key: 'batch_name',
          content: file.batch_name,
        },
        {
          key: 'fileName',
          content: file.name,
        },
        {
          key: 'status',
          title: file.status,
          content: <StatusChip status={file.status} />,
        },
        {
          key: 'cellCount',
          content: 0,
        },
        {
          key: 'author',
          content: file.author_name,
        },
        {
          key: 'size',
          content: filesize((file.fcs_file_size ?? 0) * 1000),
        },
        {
          key: 'analyses',
          content: 0,
        },
        {
          key: 'sampleAcquisition',
          content: formatDateTime(file.fcs_file_acquisition_date),
        },
      ],
    })) ?? []

  return (
    <>
      {getCompensatedFilesQueryState?.isLoading ? (
        <CircularProgress />
      ) : (
        <StepRoot>
          <StepTitle variant="h6" align="center">
            Select compensated files
          </StepTitle>
          <TableFilters>
            <SearchInput placeholder="Search files" onChange={setSearchValue} />
            <MultipleSelectFilterWithPagination
              title="Batch name"
              placeholder="Search batch names"
              options={
                getCompensatedFileBatchNamesQueryState.data?.results.map(
                  batchName => ({ id: batchName, label: batchName }),
                ) ?? []
              }
              selectedIds={selectedBatchNames}
              onSelect={handleSelectBatchName}
              page={batchNamesFilterPage}
              pageCount={
                getCompensatedFileBatchNamesQueryState.data?.page_count ?? 1
              }
              onPageChange={handleBatchNamesFilterPageChange}
              onSearchValueChange={handleBatchNamesFilterSearchValueChange}
            />
            <DateFilter value={dateFilterValue} onChange={setDateFilterValue} />
            <MultipleSelectFilterWithPagination
              title="Author"
              placeholder="Search authors"
              options={
                getCompensatedFileAuthorsQueryState.data?.results.map(user => ({
                  id: user.id,
                  label: user.username,
                })) ?? []
              }
              selectedIds={selectedAuthorIds}
              onSelect={handleSelectAuthor}
              page={authorsFilterPage}
              pageCount={
                getCompensatedFileAuthorsQueryState.data?.page_count ?? 1
              }
              onPageChange={handleAuthorsFilterPageChange}
              onSearchValueChange={handleAuthorsFilterSearchValueChange}
            />
            <NumberRangeFilter
              title="Size"
              value={sizeFilterValue}
              onChange={setSizeFilterValue}
            />
            <SelectFilter
              title="Status"
              options={Object.keys(CompensatedFileStatus).map(key => ({
                id: key,
                label: key,
              }))}
              selectedId={selectedStatus}
              onSelect={id =>
                setSelectedStatus(id as `${CompensatedFileStatus}` | undefined)
              }
            />
          </TableFilters>
          {getCompensatedFilesQueryState.data?.results.length ? (
            <StyledTableWithPagination
              rows={rows}
              columns={columns}
              selectedElements={selectedIds}
              areAllSelected={areAllSelected}
              isSelectionDisabled={getCompensatedFilesQueryState.data.results.every(
                file => file.status !== FileStatus.Ready,
              )}
              onToggleSelection={handleSelect}
              onToggleSelectAll={handleSelectAll}
              onToggleSelectAllEntriesFromCurrentPage={handleSelectCurrentPage}
              sortingColumn={sortingColumn}
              sortingDirection={sortingDirection}
              onSortingChange={setSorting}
              page={page}
              pageCount={getCompensatedFilesQueryState.data.page_count}
              onPageChange={(_event, page) => setPage(page)}
            />
          ) : (
            <StyledEmptyTableInfo
              text="No files found"
              Icon={<StyledFilesIcon />}
            />
          )}
        </StepRoot>
      )}
    </>
  )
}

const StepRoot = styled.div`
  min-width: 600px;
  display: flex;
  flex-direction: column;
  padding: ${props => props.theme.spacing(2)}px;
  background: ${props => props.theme.colors.primary[10]};
  border-radius: ${props => props.theme.radius[4]}px;
`

const StepTitle = styled(Typography)`
  margin-bottom: ${props => props.theme.spacing(2)}px;
`

const StyledEmptyTableInfo = styled(EmptyTableInfo)`
  margin-top: ${props => props.theme.spacing(8)}px;
`

const StyledFilesIcon = styled(FilesIcon)`
  width: 160px;
  height: 160px;
  stroke: ${props => props.theme.colors.greyscale[20]};
  stroke-width: 0.1;
  & > path {
    fill: ${props => props.theme.colors.greyscale[20]};
  }
`

const StyledTableWithPagination = styled(TableWithPagination)`
  display: block;
  overflow-y: auto;
`

const TableFilters = styled.div`
  display: flex;
  align-items: center;
  gap: ${props => props.theme.spacing(2)}px;
  margin: ${props => props.theme.spacing(4)}px 0;
`
