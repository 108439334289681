import { useCallback, useEffect, useRef } from 'react'
import { object, string } from 'yup'

import { analysisSlice } from 'pages/analysis/store/analysis.slice'
import { selectAnalysisOrUndefined } from 'pages/analysis/store/selectors'
import { metaAnalysisSlice } from 'pages/meta-analysis/store/meta-analysis.slice'
import { selectMetaAnalysisOrUndefined } from 'pages/meta-analysis/store/selectors'

import { DialogContextValue, useDialog } from 'shared/contexts/DialogContext'
import { useEventCallback } from 'shared/hooks/useEventCallback'
import { Analysis } from 'shared/models/AnalysisModels'
import { AppDispatch, useAppDispatch, useAppSelector } from 'shared/store'
import {
  selectApplicationInstanceId,
  selectIsAuthenticated,
} from 'shared/store/auth.slice'
import {
  FileDownloadNotificationActionType,
  NotificationType,
  showNotification,
} from 'shared/store/notification.slice'
import { handleError } from 'shared/utils/errorHandler'
import { getInjectedEnvironmentVariable } from 'shared/utils/getInjectedEnvironmentVariable'
import { getIsSecondaryTab } from 'shared/utils/multi-tab'

import { analysisApi } from './analysis.api'
import { MetaAnalysis, metaAnalysisApi } from './meta-analysis.api'
import { PRIVATE_API_TAGS, PrivateApiTag, privateApi } from './private.api'
import { PUBLIC_API_TAGS, PublicApiTag, publicApi } from './public.api'
import { encodeTagParameters } from './utils'

const SOCKET_URL =
  getInjectedEnvironmentVariable('REACT_APP_WS_BASE_URL') + '/notifications/'

type MessageFrame = {
  type: 'notification_message'
  msg_datetime: string
  organization: string
  user: string | undefined
  team: string | undefined
  message: string | undefined
} & (
  | {
      reffered_object_class: 'Analysis' | 'BatchAnalysis' | 'MetaAnalysis'
      referred_object_module: 'metaflow.analysis.models'
      referred_object_pk: string
    }
  | {
      reffered_object_class: 'Organization' | 'Team' | 'User' | 'Invitation'
      referred_object_module: 'metaflow.users.models'
      referred_object_pk: string
    }
  | {
      reffered_object_class: 'Project' | 'Experiment' | 'File' | 'Spillover'
      referred_object_module: 'metaflow.projects.models'
      referred_object_pk: string
    }
  | {
      reffered_object_class: 'ExportTask'
      referred_object_module: 'metaflow.export.models'
      referred_object_pk: string
    }
  | {
      reffered_object_class:
        | 'Invoice'
        | 'LicensePackage'
        | 'LicenseWallet'
        | 'PaymentTransaction'
      referred_object_module: 'metaflow.licenses.models'
      referred_object_pk: string
    }
  | {
      reffered_object_class: undefined
      referred_object_module: undefined
      referred_object_pk: undefined
    }
)

type InvalidateTagsMessage = MessageFrame & {
  action: 'invalidate_tags'
  extra: {
    tags: TagMap
    application_instance_id: string | undefined
  }
}

type TagMap = Record<string, Record<string, string>[]>

type Tag<T = string, S = string> = { type: T; id?: S }

type DictionaryTag<T = string> = Tag<T, Record<string, string>>

type ShowNotificationMessage = {
  action: 'popup'
  message: string
  extra: {
    message_type: NotificationType
    auto_hide_duration: number
    actions: FileDownloadNotificationActionType[]
  }
}

type Message = InvalidateTagsMessage | ShowNotificationMessage

export const WebSocketCommunication = (): null => {
  const dialog = useDialog()
  const dispatch = useAppDispatch()
  const analysis = useAppSelector(selectAnalysisOrUndefined)
  const metaAnalysis = useAppSelector(selectMetaAnalysisOrUndefined)
  const applicationInstanceId = useAppSelector(selectApplicationInstanceId)

  const handleMessage = useEventCallback((message: Message) => {
    switch (message.action) {
      case 'invalidate_tags':
        invalidateTags({
          message,
          dispatch,
          analysisId: analysis?.id,
          metaAnalysisId: metaAnalysis?.id,
          applicationInstanceId,
          dialog,
        })
        break
      case 'popup':
        dispatch(
          showNotification({
            type: message.extra.message_type,
            description: message.message,
            autoHideDuration: message.extra.auto_hide_duration,
            extraActions: message.extra.actions,
          }),
        )
        break
      default:
        handleError(
          new Error(
            JSON.stringify({
              error: 'Unknown web socket message type',
              message,
            }),
            {},
          ),
        )
    }
  })

  useListen(handleMessage, analysis, metaAnalysis)

  return null
}

const useListen = (
  onMessage: (message: Message) => void,
  analysis: Analysis | undefined,
  metaAnalysis: MetaAnalysis | undefined,
) => {
  const dispatch = useAppDispatch()
  const isAuthenticated = useAppSelector(selectIsAuthenticated)

  const socketRef = useRef<WebSocket | undefined>()
  const timeoutIdRef = useRef<number | undefined>()

  const disconnect = useCallback(() => {
    clearTimeout(timeoutIdRef.current)
    timeoutIdRef.current = undefined
    socketRef.current?.close()
    socketRef.current = undefined
  }, [])

  const handleSocketOpen = useEventCallback(async () => {
    const afterReconnecting = !!timeoutIdRef.current
    if (afterReconnecting) {
      const privateApiTags = PRIVATE_API_TAGS.filter(
        tag => tag !== 'Analysis' && tag !== 'MetaAnalysis',
      )
      if (analysis) {
        dispatch(analysisSlice.actions.preventStateUpdate())
        const data = await dispatch(
          analysisApi.endpoints.getAnalysis.initiate(analysis.id, {
            forceRefetch: true,
          }),
        ).unwrap()
        if (data.analysis.updated_at !== analysis.updated_at) {
          dispatch(
            privateApi.util.invalidateTags([...privateApiTags, 'Analysis']),
          )
        }
      } else if (metaAnalysis) {
        dispatch(metaAnalysisSlice.actions.preventStateUpdate())
        const data = await dispatch(
          metaAnalysisApi.endpoints.getMetaAnalysis.initiate(metaAnalysis.id, {
            forceRefetch: true,
          }),
        ).unwrap()
        if (data.metaAnalysis.updated_at !== metaAnalysis.updated_at) {
          dispatch(
            privateApi.util.invalidateTags([...privateApiTags, 'MetaAnalysis']),
          )
        }
      } else {
        dispatch(privateApi.util.invalidateTags([...privateApiTags]))
      }
      dispatch(publicApi.util.invalidateTags([...PUBLIC_API_TAGS]))
    }
  })

  const connect = useCallback(() => {
    socketRef.current = new WebSocket(SOCKET_URL)

    socketRef.current.onopen = handleSocketOpen

    socketRef.current.onmessage = event => {
      try {
        const parsedMessage = JSON.parse(event.data)
        onMessage(parsedMessage)
      } catch (error) {
        handleError(
          error,
          `Message was not in JSON format. Message: ${event.data}`,
        )
      }
    }

    socketRef.current.onclose = (event: CloseEvent) => {
      if (event.code !== 1000 || !event.wasClean) {
        disconnect()
        timeoutIdRef.current = window.setTimeout(connect, 2000)
      }
    }
  }, [disconnect, handleSocketOpen, onMessage])

  useEffect(() => {
    if (!isAuthenticated) {
      disconnect()
      return
    }

    if (!socketRef.current) {
      connect()
    }
  }, [connect, disconnect, isAuthenticated])
}

type CustomHandlingTag =
  | { type: 'Analysis'; id: { id: string } }
  | { type: 'MetaAnalysis'; id: { id: string } }

type InvalidateTagsProps = {
  message: InvalidateTagsMessage
  dispatch: AppDispatch
  analysisId: string | undefined
  metaAnalysisId: string | undefined
  applicationInstanceId: string | undefined
  dialog: DialogContextValue
}

const invalidateTags = ({
  message,
  dispatch,
  analysisId,
  metaAnalysisId,
  applicationInstanceId,
  dialog,
}: InvalidateTagsProps) => {
  const { privateTags, publicTags, customHandlingTags } = groupTags(
    unpackTagMap(message.extra.tags),
  )

  if (privateTags.length > 0) {
    dispatch(
      privateApi.util.invalidateTags(
        privateTags.map(transformDictionaryTagToRegularTag),
      ),
    )
  }

  if (publicTags.length > 0) {
    dispatch(
      publicApi.util.invalidateTags(
        publicTags.map(transformDictionaryTagToRegularTag),
      ),
    )
  }

  if (customHandlingTags.length > 0) {
    invalidateCustomHandlingTags({
      tags: customHandlingTags,
      message,
      dispatch,
      analysisId,
      metaAnalysisId,
      applicationInstanceId,
      dialog,
    })
  }
}

const unpackTagMap = (tags: TagMap) => {
  const unpackedTags: DictionaryTag[] = []

  for (const [type, ids] of Object.entries(tags)) {
    if (ids.length === 0) {
      unpackedTags.push({
        type,
      })
    } else {
      for (const id of ids) {
        unpackedTags.push({
          type,
          id,
        })
      }
    }
  }

  return unpackedTags
}

const groupTags = (tags: DictionaryTag[]) => {
  const privateTags: DictionaryTag<PrivateApiTag>[] = []
  const publicTags: DictionaryTag<PublicApiTag>[] = []
  const customHandlingTags: CustomHandlingTag[] = []

  for (const tag of tags) {
    if (
      object({
        type: string().oneOf(['Analysis', 'MetaAnalysis']).required(),
        id: object({ id: string().required() }).required(),
      }).isValidSync(tag) &&
      tag.id.id !== 'list'
    ) {
      customHandlingTags.push(tag as CustomHandlingTag)
    } else if (PRIVATE_API_TAGS.includes(tag.type as PrivateApiTag)) {
      privateTags.push(tag as DictionaryTag<PrivateApiTag>)
    } else if (PUBLIC_API_TAGS.includes(tag.type as PublicApiTag)) {
      publicTags.push(tag as DictionaryTag<PublicApiTag>)
    } else {
      alert(`Unknown tag: ${tag}. Please report it to the support.`) // for Grzegorz's request, so Kamila can see it
      throw handleError(new Error(`Unknown tag: ${JSON.stringify(tag)}`))
    }
  }

  return {
    privateTags,
    publicTags,
    customHandlingTags,
  }
}

const transformDictionaryTagToRegularTag = <T,>(tag: DictionaryTag<T>) => ({
  type: tag.type,
  id: tag.id ? encodeTagParameters(tag.id) : undefined,
})

type InvalidateCustomTagsProps = {
  tags: CustomHandlingTag[]
  message: InvalidateTagsMessage
  dispatch: AppDispatch
  analysisId: string | undefined
  metaAnalysisId: string | undefined
  applicationInstanceId: string | undefined
  dialog: DialogContextValue
}

const invalidateCustomHandlingTags = ({
  tags,
  message,
  dispatch,
  analysisId,
  metaAnalysisId,
  applicationInstanceId,
  dialog,
}: InvalidateCustomTagsProps) => {
  for (const tag of tags) {
    switch (tag.type) {
      case 'Analysis':
        if (
          analysisId &&
          analysisId === tag.id.id &&
          applicationInstanceId !== message.extra.application_instance_id &&
          !getIsSecondaryTab()
        ) {
          dialog.showConfirmationDialog({
            title: 'This analysis has been saved by another user or tab',
            message:
              'This analysis has been overwritten and it will not be possible to save it with the current changes. Do you want to reload the page (all current changes will be lost)?',
            onConfirm: () => {
              dispatch(
                privateApi.util.invalidateTags([
                  transformDictionaryTagToRegularTag(tag),
                ]),
              )
            },
          })
        }
        break

      case 'MetaAnalysis':
        if (
          metaAnalysisId &&
          metaAnalysisId === tag.id.id &&
          applicationInstanceId !== message.extra.application_instance_id
        ) {
          dialog.showConfirmationDialog({
            title: 'This meta-analysis has been saved by another user or tab',
            message:
              'This meta-analysis has been overwritten and it will not be possible to save it with the current changes. Do you want to reload the page (all current changes will be lost)?',
            onConfirm: () => {
              dispatch(
                privateApi.util.invalidateTags([
                  transformDictionaryTagToRegularTag(tag),
                ]),
              )
            },
          })
        }
        break
    }
  }
}
