import { useCallback } from 'react'

import {
  GetAnalysisStatusesResponse,
  useGetAnalysisStatusesQuery,
} from 'shared/api/analysis.api'
import { AnalysisStatus } from 'shared/models/AnalysisModels'

type UseAnalysisStatusesResult = {
  translations: Record<AnalysisStatus, string> | undefined

  validateActionBasedOnAnalysisStatus: (
    status: AnalysisStatus,
    action: ValidatedAction,
  ) => string | undefined
}

export type ValidatedAction =
  | 'select-analysis-for-meta-analysis'
  | 'select-analysis-for-transfer-fit'
  | 'select-analysis-for-transfer-predict'
  | 'view-analysis'
  | 'enable-protection-mode'
  | 'select-analysis-for-batch-global-statstics-export'
  | 'select-analysis-for-batch-custom-statstics-export'
  | 'select-analysis-for-sub-analysis'

export const useAnalysisStatuses = (): UseAnalysisStatusesResult => {
  const analysisStatusesQuery = useGetAnalysisStatusesQuery()

  const validateActionBasedOnAnalysisStatus: UseAnalysisStatusesResult['validateActionBasedOnAnalysisStatus'] =
    useCallback(
      (status, action) => {
        const response = analysisStatusesQuery.data
        if (!response) {
          return 'Loading...'
        }

        const allowedStatuses = getAllowedStatusesForAction(action, response)

        if (!allowedStatuses.includes(status)) {
          const actionName =
            action === 'view-analysis'
              ? 'view'
              : action === 'enable-protection-mode'
              ? 'enable protection mode for'
              : 'select'
          return (
            `You cannot ${actionName} this analysis, because it is not in one of the allowed statuses: ` +
            allowedStatuses.map(status => response.statuses[status]).join(', ')
          )
        }

        return undefined
      },
      [analysisStatusesQuery.data],
    )

  return {
    translations: analysisStatusesQuery.data?.statuses,
    validateActionBasedOnAnalysisStatus,
  }
}

const getAllowedStatusesForAction = (
  action: ValidatedAction,
  statusesResponse: GetAnalysisStatusesResponse,
): string[] => {
  switch (action) {
    case 'select-analysis-for-meta-analysis':
      return statusesResponse.statuses_acceptable_for_comparing

    case 'select-analysis-for-transfer-fit':
      return statusesResponse.statuses_acceptable_for_transfer_fit

    case 'select-analysis-for-transfer-predict':
      return statusesResponse.statuses_acceptable_for_transfer_predict

    case 'view-analysis':
      return statusesResponse.statuses_acceptable_for_view_analysis

    case 'enable-protection-mode':
      return statusesResponse.statuses_acceptable_for_protection_mode

    case 'select-analysis-for-batch-global-statstics-export':
      return statusesResponse.statuses_acceptable_for_batch_export_statistics

    case 'select-analysis-for-batch-custom-statstics-export':
      return statusesResponse.statuses_acceptable_for_batch_export_custom_statistics

    case 'select-analysis-for-sub-analysis':
      return statusesResponse.statuses_acceptable_for_sub_analysis
  }
}
