import { Menu } from '@material-ui/core'
import { useCallback, useMemo, useState } from 'react'
import { RGBColor } from 'react-color'
import styled from 'styled-components'

import { ConfirmModal } from 'components/ConfirmModal'
import { DotLabel } from 'components/DotLabel'
import { MenuItem } from 'components/menu/MenuItem'
import { NestedMenuItem } from 'components/menu/NestedMenuItem'

import { useDialog } from 'shared/contexts/DialogContext'
import { useEventCallback } from 'shared/hooks/useEventCallback'
import { Graph } from 'shared/models/Graphs'
import { RootState, useAppDispatch, useAppSelector } from 'shared/store'
import { rgbStringToValues } from 'shared/utils/colors'
import { handleError } from 'shared/utils/errorHandler'
import { positionColorMenu } from 'shared/utils/positionColorMenu'

import { ChangeDotSizeMenuItem } from './ChangeDotSizeMenuItem'
import { ClusterColorContextMenu } from './ClusterColorContextMenu'
import { DeleteLassoDialog } from './DeleteLassoDialog'
import { LassoTag } from './LassoTag'
import { RenameClusterDialog } from './RenameClusterDialog'
import { SaveLassoDialog } from './SaveLassoDialog'
import { LassoToolLasso, LassoToolState } from './lasso/LassoTool'
import {
  changeClusterDotSize,
  duplicateChart,
  expandClusters,
  hideClusters,
  hideClustersOnAllCharts,
  highlightCluster,
  mergeClusters,
  mergeLassoClustersRecursively,
  resetClusterHighlighting,
  showClusterOnAllCharts,
  toggleClusterActive,
  toggleClusterVisibility,
} from './store/analysis.slice'
import {
  selectActiveClusters,
  selectActiveLeaves,
  selectAnalysisAccessMode,
  selectAnalysisLassos,
  selectClusterById,
  selectClusterDotSizes,
  selectClusters,
  selectClustersHiddenOnAnyGraph,
  selectHighlightedCluster,
} from './store/selectors'

export type ClusterActionsContextMenuActions = {
  saveLasso?: boolean
  deleteLasso?: boolean
  changeLassoDotSize?: boolean
  changeLassoCreationMode?: boolean
  changeClusterColor?: boolean
  changeClusterName?: boolean
  expandCluster?: boolean
  expandVisibleClusters?: boolean
  mergeCluster?: boolean
  mergeVisibleClusters?: boolean
  hideClusters?: boolean
  highlightCluster?: boolean
  shouldChangeDotSize?: boolean
  showClustersOnNewChart?: boolean
  showLassoList?: boolean
  mergeSelectedClusters?: boolean
  expandSelectedClusters?: boolean
  toggleClusterVisibilityAll?: boolean
  toggleClusterVisibilitySelected?: boolean
}

type ClusterActionsContextMenuProps = {
  actions?: ClusterActionsContextMenuActions
  menuOrigin: { x: number; y: number }
  source: 'sunburst' | 'heatmap' | 'graph'
  graph?: Graph
  clusterId: string
  isLassoToolActive?: boolean
  selectedLasso?: LassoToolLasso
  lassoClusterIds?: string[]
  lassoCreationMode?: LassoToolState['lassoCreationMode']
  disablePortal?: boolean
  onClose: () => void
  onRenameLasso?: (lassoName: string) => void
  onDeleteLasso?: () => void
  onConvertCurrentLassoToFreeshape?: () => void
  onToggleSelectLasso?: (lassoId: string) => void
  onChangeLassoCreationMode?: (
    mode: LassoToolState['lassoCreationMode'],
  ) => void
}

export const ClusterActionsContextMenu = ({
  actions = {},
  menuOrigin,
  source,
  graph,
  clusterId,
  isLassoToolActive,
  selectedLasso,
  lassoClusterIds = [],
  lassoCreationMode,
  disablePortal = true,
  onClose,
  onRenameLasso,
  onDeleteLasso,
  onConvertCurrentLassoToFreeshape,
  onToggleSelectLasso,
  onChangeLassoCreationMode,
}: ClusterActionsContextMenuProps): JSX.Element => {
  const { showDialog } = useDialog()
  const dispatch = useAppDispatch()
  const activeClusters = useAppSelector(selectActiveClusters)
  const activeLeaves = useAppSelector(selectActiveLeaves)
  const clusterById = useAppSelector(selectClusterById)
  const highlightedCluster = useAppSelector(selectHighlightedCluster)
  const clusters = useAppSelector(selectClusters)
  const clustersHiddenOnAnyGraph = useAppSelector(
    selectClustersHiddenOnAnyGraph,
  )
  const lassos = useAppSelector(selectAnalysisLassos)
  const clusterDotSizes = useAppSelector((state: RootState) =>
    graph ? selectClusterDotSizes(state)[graph.id] : undefined,
  )
  const analysisAccessMode = useAppSelector(selectAnalysisAccessMode)

  const clusterDotSize = clusterDotSizes
    ? clusterDotSizes?.[clusterId] ?? 1
    : undefined
  const minLassoClusterDotSize = clusterDotSizes
    ? Math.min(
        ...lassoClusterIds.map(clusterId => clusterDotSizes[clusterId] ?? 1),
      )
    : undefined
  const isClusterHighlighted =
    highlightedCluster?.clusterId === clusterId &&
    !highlightedCluster?.highlightOnlyOnSunburst

  const parent = useMemo(() => {
    const parentId = clusterById[clusterId]?.parent
    return parentId ? clusterById[parentId] : undefined
  }, [clusterById, clusterId])

  const canExpandCluster = useMemo(() => {
    const nodeChildren = clusters.filter(d => d.parent === clusterId)
    return nodeChildren.length > 0
  }, [clusters, clusterId])

  const canMergeCluster = useMemo(
    () =>
      !!parent &&
      !clustersHiddenOnAnyGraph.has(clusterId) &&
      parent.children.every(cluster => !clustersHiddenOnAnyGraph.has(cluster)),
    [clusterId, clustersHiddenOnAnyGraph, parent],
  )
  const canMergeVisibleClusters = useMemo(
    () => !activeLeaves.some(leaf => clustersHiddenOnAnyGraph.has(leaf.id)),
    [activeLeaves, clustersHiddenOnAnyGraph],
  )

  const [shouldShowMenu, setShouldShowMenu] = useState(true)
  const [hideOrShowConfirmData, setHideOrShowConfirmData] = useState<{
    clusterId: string
    confirmModalText: string
    type: 'show-cluster' | 'hide-cluster'
  }>()
  const [showColorMenu, setShowColorMenu] = useState(false)
  const [initialColor, setInitialColor] = useState<RGBColor>()

  const onRenameClick = () => {
    const cluster = activeClusters.find(d => d.id === clusterId)
    const parent = cluster && activeClusters.find(d => d.id === cluster.parent)
    if (cluster && parent) {
      showDialog(closeDialog => (
        <RenameClusterDialog
          cluster={cluster}
          onClose={() => {
            closeDialog()
            onClose()
          }}
        />
      ))
      setShouldShowMenu(false)
    }
  }

  const openConfirmModal = (type: 'show-cluster' | 'hide-cluster') => {
    setShouldShowMenu(false)
    const confirmModalText =
      type === 'show-cluster'
        ? 'Are you sure to show this cluster for all graphs?'
        : 'Are you sure to hide this population for all graphs?'
    if (clusterId)
      setHideOrShowConfirmData({
        confirmModalText,
        clusterId,
        type,
      })
  }

  const handleOk = () => {
    if (!hideOrShowConfirmData) {
      return
    }
    if (hideOrShowConfirmData.type === 'show-cluster' && clusterId) {
      dispatch(
        showClusterOnAllCharts({ clusterId: hideOrShowConfirmData.clusterId }),
      )
    } else {
      dispatch(
        hideClustersOnAllCharts({
          clusterIds: [hideOrShowConfirmData.clusterId],
        }),
      )
    }

    onClose()
  }

  const onColorChangeClick = () => {
    setShowColorMenu(true)
    setShouldShowMenu(false)

    if (clusterId) {
      const [r, g, b] = rgbStringToValues(clusterById[clusterId]?.color)
      setInitialColor({
        r,
        g,
        b,
      })
    }
  }

  const clusterHighlightHandler = () => {
    if (clusterId) {
      if (highlightedCluster?.clusterId === clusterId) {
        dispatch(resetClusterHighlighting())
      } else {
        dispatch(highlightCluster({ clusterId }))
      }
    }
    onClose()
  }

  const handleClusterVisibility = () => {
    if (graph?.id && clusterId)
      dispatch(toggleClusterVisibility({ chartId: graph.id, clusterId }))
    onClose()
  }

  const handleHideLassoClusters = useCallback(() => {
    if (!graph) {
      throw handleError(new Error('Graph is undefined'))
    }

    dispatch(hideClusters({ chartId: graph.id, clusterIds: lassoClusterIds }))
    onClose()
  }, [lassoClusterIds, dispatch, graph, onClose])

  const handleShowClustersOnNewChart = useCallback(() => {
    if (!graph) {
      throw handleError(new Error('Graph is undefined'))
    }

    dispatch(
      duplicateChart({
        chartId: graph?.id,
        shownClusterIds: lassoClusterIds,
        parentLasso: selectedLasso,
      }),
    )
    onClose()
  }, [lassoClusterIds, dispatch, graph, onClose, selectedLasso])

  const handleExpandCluster = () => {
    if (clusterId && canExpandCluster) {
      dispatch(toggleClusterActive({ clusterId }))
    }
    onClose()
  }

  const handleExpandVisibleClusters = useCallback(() => {
    dispatch(expandClusters({ shouldExpandAllVisibleClusters: true }))
    onClose()
  }, [dispatch, onClose])

  const handleExpandSelectedClusters = useCallback(() => {
    dispatch(
      expandClusters({
        shouldExpandAllVisibleClusters: false,
        selectedLeafIds: lassoClusterIds,
      }),
    )
    onClose()
  }, [dispatch, lassoClusterIds, onClose])

  const handleMergeCluster = useCallback(() => {
    if (parent) {
      dispatch(toggleClusterActive({ clusterId: parent.id }))
    }
    onClose()
  }, [dispatch, onClose, parent])

  const handleMergeVisibleClusters = useCallback(() => {
    dispatch(mergeClusters())
    onClose()
  }, [dispatch, onClose])

  const handleMergeSelectedClusters = useCallback(
    (mode: 'inclusive' | 'exclusive' | 'lca') => {
      if (!graph || !graph.y_axis) {
        throw handleError(new Error('Incorrect or missing graph'))
      }
      if (mode === 'lca') {
        dispatch(
          mergeLassoClustersRecursively({
            chartId: graph.id,
            lassoId: selectedLasso!.id,
          }),
        )
      } else {
        dispatch(
          mergeClusters({
            selectedLeafIds: lassoClusterIds,
            isExclusive: mode === 'exclusive',
          }),
        )
      }

      onClose()
    },
    [dispatch, graph, lassoClusterIds, onClose, selectedLasso],
  )

  const handleRenameLasso = () => {
    if (!graph) {
      throw handleError(new Error('Graph is undefined'))
    }
    showDialog(closeDialog => (
      <SaveLassoDialog
        onSave={lassoName => {
          onRenameLasso?.(lassoName)
          closeDialog()
          onClose()
        }}
        onCancel={() => {
          closeDialog()
          onClose()
        }}
      />
    ))
  }

  const handleDeleteLasso = useEventCallback(() => {
    if (!selectedLasso || !onDeleteLasso) {
      return
    }
    showDialog(closeDialog => (
      <DeleteLassoDialog
        selectedLassoId={selectedLasso.id}
        onConfirm={() => {
          onDeleteLasso()
          closeDialog()
          onClose()
        }}
        onCancel={() => {
          closeDialog()
          onClose()
        }}
      />
    ))
  })

  const handleConvertLassoToFreeshape = useEventCallback(() => {
    if (!selectedLasso || !onConvertCurrentLassoToFreeshape) {
      return
    }
    onConvertCurrentLassoToFreeshape()
    onClose()
  })

  const handleChangeLassoCreationMode = useEventCallback(
    (mode: LassoToolState['lassoCreationMode']) => {
      onChangeLassoCreationMode?.(mode)
    },
  )

  const handleClusterDotSizeChange = useEventCallback(
    (
      size: number,
      shouldApplyToAllCharts: boolean,
      mode: 'cluster' | 'lasso',
    ) => {
      if (!graph) {
        throw new Error('Graph is undefined')
      }

      const clusterIds = mode === 'lasso' ? lassoClusterIds : [clusterId]

      if (shouldApplyToAllCharts) {
        dispatch(
          changeClusterDotSize({
            clusterIds,
            size,
          }),
        )
      } else {
        dispatch(
          changeClusterDotSize({
            chartIds: [graph.id],
            clusterIds,
            size,
          }),
        )
      }
    },
  )

  return (
    <>
      {shouldShowMenu && (
        <Menu
          disableAutoFocusItem
          anchorReference="anchorPosition"
          anchorPosition={{
            top: menuOrigin.y,
            left: menuOrigin.x,
          }}
          open={!!menuOrigin && !!clusterId}
          onClose={() => {
            setShowColorMenu(false)
            onClose()
          }}
          disablePortal={disablePortal}
        >
          {clusterId && clusterById[clusterId] && (
            <div>
              <HeaderMenuItem key="cluster-header" disabled>
                <HeaderMenuItemLabel>
                  <StyledDotLabel
                    $color={clusterById[clusterId].color}
                    $isHighlighted={false}
                  />
                  {clusterById[clusterId].label}
                </HeaderMenuItemLabel>
              </HeaderMenuItem>
            </div>
          )}

          {actions.changeClusterName && (
            <MenuItem key="changeClusterName" onClick={onRenameClick}>
              Edit cluster name
            </MenuItem>
          )}
          {actions.toggleClusterVisibilityAll &&
            clusterId &&
            activeLeaves.map(d => d.id).includes(clusterId) && (
              <div>
                <MenuItem
                  key="showCluster"
                  disabled={analysisAccessMode !== 'read-and-write'}
                  onClick={() => openConfirmModal('show-cluster')}
                >
                  Show cluster
                </MenuItem>
                <MenuItem
                  key="hideCluster"
                  disabled={analysisAccessMode !== 'read-and-write'}
                  onClick={() => openConfirmModal('hide-cluster')}
                >
                  Hide cluster
                </MenuItem>
              </div>
            )}
          {actions.toggleClusterVisibilitySelected && clusterId && (
            <MenuItem
              key="toggleClusterVisibilitySelected"
              disabled={analysisAccessMode !== 'read-and-write'}
              onClick={handleClusterVisibility}
            >
              {!graph?.hidden_cluster_ids.includes(clusterId)
                ? 'Hide cluster'
                : 'Show cluster'}
            </MenuItem>
          )}
          {actions.changeClusterColor && (
            <MenuItem
              key="changeClusterColor"
              disabled={analysisAccessMode !== 'read-and-write'}
              onClick={onColorChangeClick}
            >
              Edit cluster color
            </MenuItem>
          )}
          {actions.highlightCluster && (
            <MenuItem key="highlightCluster" onClick={clusterHighlightHandler}>
              {isClusterHighlighted ? 'Remove highlight' : 'Highlight cluster'}
            </MenuItem>
          )}
          {canExpandCluster && actions.expandCluster && (
            <MenuItem
              key="expandCluster"
              disabled={analysisAccessMode !== 'read-and-write'}
              onClick={handleExpandCluster}
            >
              Expand cluster
            </MenuItem>
          )}
          {canMergeCluster && actions.mergeCluster && (
            <MenuItem
              key="mergeCluster"
              disabled={analysisAccessMode !== 'read-and-write'}
              onClick={handleMergeCluster}
            >
              Merge cluster
            </MenuItem>
          )}
          {actions.shouldChangeDotSize &&
            clusterId &&
            activeLeaves.map(d => d.id).includes(clusterId) && (
              <ChangeDotSizeMenuItem
                shouldShowApplyToAllCharts
                value={clusterDotSize ?? 1}
                onChange={handleClusterDotSizeChange}
                mode="cluster"
              />
            )}
          {actions.expandVisibleClusters && (
            <MenuItem
              key="expandVisibleClusters"
              disabled={analysisAccessMode !== 'read-and-write'}
              onClick={handleExpandVisibleClusters}
            >
              Expand visible clusters
            </MenuItem>
          )}
          {canMergeVisibleClusters && actions.mergeVisibleClusters && (
            <MenuItem
              key="mergeVisibleClusters"
              disabled={analysisAccessMode !== 'read-and-write'}
              onClick={handleMergeVisibleClusters}
            >
              Merge visible clusters
            </MenuItem>
          )}
          {isLassoToolActive && (
            <div>
              {actions.showLassoList && onToggleSelectLasso && graph && (
                <NestedMenuItem
                  key="lasso-header-nested"
                  label={
                    <HeaderMenuItemLabel>
                      Display lasso
                      {selectedLasso?.name && (
                        <StyledLassoTag name={selectedLasso.name} />
                      )}
                    </HeaderMenuItemLabel>
                  }
                >
                  {Object.entries(graph.lasso_ids).map(([lassoId, isShown]) => {
                    const lasso = lassos[lassoId]
                    return (
                      <LassoSelectionMenuItem
                        key={lasso.name}
                        onClick={() => onToggleSelectLasso(lasso.id)}
                        selected={isShown}
                      >
                        <LassoTag name={lassos[lassoId].name} />
                        {isShown && '✓'}
                      </LassoSelectionMenuItem>
                    )
                  })}
                </NestedMenuItem>
              )}
              {selectedLasso?.isFinished && actions.saveLasso && (
                <MenuItem
                  key="saveLasso"
                  disabled={analysisAccessMode !== 'read-and-write'}
                  onClick={handleRenameLasso}
                >
                  Rename lasso
                </MenuItem>
              )}
              {selectedLasso?.isFinished && actions.deleteLasso && (
                <MenuItem
                  key="deleteLasso"
                  disabled={analysisAccessMode !== 'read-and-write'}
                  onClick={handleDeleteLasso}
                >
                  Delete lasso
                </MenuItem>
              )}
              {selectedLasso?.type === 'rectangle' &&
                analysisAccessMode !== 'read-only' && (
                  <MenuItem
                    key="convertLassoToFreeshape"
                    onClick={handleConvertLassoToFreeshape}
                  >
                    Convert lasso to freeshape
                  </MenuItem>
                )}
              {selectedLasso?.isFinished && lassoClusterIds.length > 0 && (
                <div>
                  {actions.hideClusters && (
                    <MenuItem
                      key="hideClusters"
                      disabled={analysisAccessMode !== 'read-and-write'}
                      onClick={handleHideLassoClusters}
                    >
                      Hide clusters
                    </MenuItem>
                  )}
                  {actions.changeLassoDotSize && (
                    <ChangeDotSizeMenuItem
                      shouldShowApplyToAllCharts
                      value={minLassoClusterDotSize ?? 1}
                      onChange={handleClusterDotSizeChange}
                      mode="lasso"
                    />
                  )}
                  {actions.showClustersOnNewChart && (
                    <MenuItem
                      key="showClustersOnNewChart"
                      disabled={analysisAccessMode !== 'read-and-write'}
                      onClick={handleShowClustersOnNewChart}
                    >
                      Show clusters on new graph
                    </MenuItem>
                  )}
                  {actions.expandSelectedClusters && (
                    <MenuItem
                      key="expandSelectedClusters"
                      disabled={analysisAccessMode !== 'read-and-write'}
                      onClick={handleExpandSelectedClusters}
                    >
                      Expand selected clusters
                    </MenuItem>
                  )}
                  {actions.mergeSelectedClusters && (
                    <MenuItem
                      key="mergeSelectedClustersToCommonParent"
                      disabled={analysisAccessMode !== 'read-and-write'}
                      onClick={() => handleMergeSelectedClusters('lca')}
                    >
                      Merge selected clusters to common ancestors
                    </MenuItem>
                  )}
                </div>
              )}
              {actions.changeLassoCreationMode &&
                onChangeLassoCreationMode &&
                selectedLasso &&
                !selectedLasso.isFinished &&
                analysisAccessMode === 'read-and-write' && (
                  <>
                    <LassoCreationModeHeaderItem disabled>
                      Lasso creation mode
                    </LassoCreationModeHeaderItem>
                    <MenuToggle>
                      <MenuToggleOption
                        $selected={lassoCreationMode === 'freeshape'}
                        onClick={() =>
                          handleChangeLassoCreationMode('freeshape')
                        }
                      >
                        <LassoCreationModeIcon>
                          <polygon
                            points="10,15 30,10 35,30 20,25 10,30"
                            fill="none"
                          />
                        </LassoCreationModeIcon>
                        <span>Freeshape</span>
                      </MenuToggleOption>
                      <MenuToggleOption
                        $selected={lassoCreationMode === 'rectangle'}
                        onClick={() =>
                          handleChangeLassoCreationMode('rectangle')
                        }
                      >
                        <LassoCreationModeIcon>
                          <polygon
                            points="10,10 30,10 30,30 10,30"
                            fill="none"
                          />
                        </LassoCreationModeIcon>
                        <span>Rectangle</span>
                      </MenuToggleOption>
                    </MenuToggle>
                  </>
                )}
            </div>
          )}
        </Menu>
      )}
      {hideOrShowConfirmData && (
        <ConfirmModal
          OKLabel="Proceed"
          KOLabel="Cancel"
          message={hideOrShowConfirmData.confirmModalText}
          onOK={handleOk}
          onKO={() => {
            setHideOrShowConfirmData(undefined)
            setShouldShowMenu(true)
          }}
          open
        />
      )}
      {showColorMenu && clusterId && (
        <ClusterColorContextMenu
          menuOrigin={positionColorMenu(menuOrigin)}
          clusterId={clusterId}
          initialColor={initialColor}
          closeColorMenu={() => {
            onClose()
          }}
          source={source}
        />
      )}
    </>
  )
}

const HeaderMenuItem = styled(MenuItem)`
  background-color: ${props => props.theme.colors.primary[20]};
  && {
    opacity: 1;
  }
`

const HeaderMenuItemLabel = styled.div`
  display: flex;
  align-items: center;
  font-family: ${props => props.theme.font.style.bold};
`

const StyledDotLabel = styled(DotLabel)`
  margin-left: 0;
  margin-right: 4px;
`

const StyledLassoTag = styled(LassoTag)`
  margin-left: 4px;
`

const LassoSelectionMenuItem = styled(MenuItem)`
  gap: ${props => props.theme.spacing(2)}px;
`

const MenuToggle = styled.div`
  display: flex;
  padding: 0 4px 4px;
`

const LassoCreationModeIcon = styled.svg`
  height: 40px;
  width: 45px;
  stroke-width: 2px;
`

const MenuToggleOption = styled(MenuItem)<{ $selected: boolean }>`
  display: flex;
  align-items: center;
  width: 50%;
  padding: 0 ${props => props.theme.spacing(1)}px;
  font-size: ${props => props.theme.font.size.smallest}px;
  color: ${props =>
    props.$selected
      ? props.theme.colors.white
      : props.theme.colors.primaryDark[100]};
  background: ${props =>
    props.$selected
      ? props.theme.colors.primaryDark[100]
      : props.theme.colors.white};
  border: 2px solid ${props => props.theme.colors.primaryDark[100]};
  border-right: none;
  border-radius: ${props => props.theme.radius[2]}px 0 0
    ${props => props.theme.radius[2]}px;
  cursor: pointer;

  &:last-child {
    border-radius: 0 ${props => props.theme.radius[2]}px
      ${props => props.theme.radius[2]}px 0;
    border-left: none;
    border-right: 2px solid ${props => props.theme.colors.primaryDark[100]};
  }

  &:hover {
    background: ${props =>
      props.$selected ? props.theme.colors.primaryDark[70] : 'default'};
  }

  ${LassoCreationModeIcon} {
    stroke: ${props =>
      props.$selected
        ? props.theme.colors.white
        : props.theme.colors.primaryDark[100]};
  }
`

const LassoCreationModeHeaderItem = styled(HeaderMenuItem)`
  display: flex;
  justify-content: center;
  background: ${props => props.theme.colors.white};
  font-family: ${props => props.theme.font.style.bold};
`
