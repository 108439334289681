import { Grid } from '@material-ui/core'
import { Pagination } from '@material-ui/lab'
import React from 'react'
import styled from 'styled-components'

import { LoadingMask } from 'components/LoadingMask'
import { TableColumn } from 'components/TableColumn'

import { OrganizationMember } from 'shared/models/OrganizationModels'
import { SortingOrder } from 'shared/utils/array'

import { MemberRow } from './MemberRow'

const columnHeadersWithSize = [
  { header: 'Username', size: 2 },
  { header: 'First name', size: 2 },
  { header: 'Last name', size: 2 },
  { header: 'Invite date', size: 1 },
  { header: 'Join date', size: 1 },
  { header: 'Last login', size: 1 },
  { header: 'Operator', size: 1 },
  { header: 'Active', size: 1 },
  { header: 'Actions', size: 1 },
] as const

export type MemberHeader = (typeof columnHeadersWithSize)[number]['header']

type MembersTableProps = {
  isFetching: boolean
  members: OrganizationMember[]
  page: number
  numberOfPages: number
  sortingCategory: MemberHeader
  sortingOrder: SortingOrder
  onPageChange: (page: number) => void
  onSortingCategoryChange: (sortingCategory: MemberHeader) => void
  onSortingOrderChange: (sortingOrder: SortingOrder) => void
}

export const MembersTable: React.FC<MembersTableProps> = ({
  isFetching,
  members,
  page,
  numberOfPages,
  sortingCategory,
  sortingOrder,
  onPageChange,
  onSortingCategoryChange,
  onSortingOrderChange,
}) => {
  if (isFetching) {
    return <LoadingMask />
  }

  return (
    <TableWrapper>
      <TableHeader container spacing={0}>
        {columnHeadersWithSize.map(({ header, size }) => (
          <TableColumn<MemberHeader>
            key={header}
            columnName={header}
            size={size}
            currentSortingCategory={sortingCategory}
            currentSortingOrder={sortingOrder}
            disabledSortingFields={['Invite date']}
            setSortingCategory={onSortingCategoryChange}
            setSortingOrder={onSortingOrderChange}
          />
        ))}
      </TableHeader>
      {members.length ? (
        <>
          {members.map(
            ({
              id,
              email,
              first_name,
              last_name,
              invitation,
              date_joined,
              last_login,
              is_operator,
              is_active,
            }) => (
              <MemberRow
                key={id}
                id={id}
                email={email}
                firstName={first_name}
                lastName={last_name}
                inviteDate={invitation && invitation.send_date}
                joinedDate={date_joined}
                lastLoginDate={last_login}
                isOperator={is_operator}
                isActive={is_active}
              />
            ),
          )}
          <PaginationContainer>
            <Pagination
              count={numberOfPages}
              onChange={(_event, page) => onPageChange(page)}
              page={page}
            />
          </PaginationContainer>
        </>
      ) : (
        <EmptyListMessageContainer>No members found</EmptyListMessageContainer>
      )}
    </TableWrapper>
  )
}

const TableWrapper = styled.div`
  width: 100%;
  margin: 20px 0;
`

const TableHeader = styled(Grid)`
  color: ${props => props.theme.colors.greyscale[50]};
  font-family: ${props => props.theme.font.style.bold};
  align-items: center;
  padding: 0 10px 10px;
`

const PaginationContainer = styled.div`
  display: flex;
  justify-content: center;
  margin-top: ${props => props.theme.spacing(3)}px;
`

const EmptyListMessageContainer = styled.div`
  display: flex;
  justify-content: center;
  margin-top: ${props => props.theme.spacing(4)}px;
`
