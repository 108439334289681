import { Menu } from '@material-ui/core'
import React, { useState } from 'react'
import { RGBColor, SketchPicker } from 'react-color'
import styled from 'styled-components'

import { Icon } from 'assets/images/icons/Icon'

import { theme } from 'Theme'

type ClusterColorContextMenuProps = {
  menuOrigin: {
    x: number
    y: number
  }
  initialColor?: RGBColor
  onColorValidate: (newColor: RGBColor | null) => void
  onCancel: () => void
  onClickOutside: () => void
}

export const SelectColorContextMenu: React.FC<
  ClusterColorContextMenuProps
> = props => {
  const {
    menuOrigin,
    initialColor,
    onColorValidate,
    onCancel,
    onClickOutside,
  } = props

  const [selectedColor, setSelectedColor] = useState<RGBColor | null>(
    initialColor ?? null,
  )

  return (
    <Menu
      anchorPosition={{ top: menuOrigin.y, left: menuOrigin.x }}
      anchorReference="anchorPosition"
      onClose={() => onClickOutside()}
      open={!!menuOrigin}
    >
      <PickerWrapper>
        <SketchPicker
          disableAlpha={true}
          presetColors={[]}
          color={selectedColor || 'rgba(0, 0, 0, 1)'}
          onChange={color => setSelectedColor(color.rgb)}
        />
        <ConfirmWrapper>
          <ConfirmIcon onClick={() => onColorValidate(selectedColor)}>
            <Icon name="check" color={theme.colors.primaryDark[100]} />
          </ConfirmIcon>
          <ConfirmIcon onClick={onCancel}>
            <Icon name="close" color={theme.colors.primaryDark[100]} />
          </ConfirmIcon>
        </ConfirmWrapper>
      </PickerWrapper>
    </Menu>
  )
}

const PickerWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  & .sketch-picker {
    background-color: inherit !important;
    border-radius: 0 !important;
    box-shadow: none !important;
    font-family: inherit !important;
  }
`

const ConfirmWrapper = styled.div`
  display: flex;
`

const ConfirmIcon = styled.div`
  cursor: pointer;
  margin: 2px 8px;
  & > svg {
    width: 16px;
    height: 16px;
  }
`
