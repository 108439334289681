import { FC, useMemo } from 'react'
import styled from 'styled-components'

import { CompensatedFile, FcsFile } from 'shared/api/files.api'
import { Analysis } from 'shared/models/AnalysisModels'
import { formatDateTime } from 'shared/utils/utils'

import { DataLine } from './DataLine'

type AnalysisHeaderDetailsProps = {
  analysis: Analysis
  compensatedFile: CompensatedFile
  fcsFile: FcsFile
  isAnalysisSaved: boolean
}

export const AnalysisHeaderDetails: FC<AnalysisHeaderDetailsProps> = ({
  analysis,
  compensatedFile,
  fcsFile,
  isAnalysisSaved,
}: AnalysisHeaderDetailsProps) => {
  const spilloverName = useMemo(() => {
    switch (compensatedFile?.spillover_origin) {
      case 'FCS':
        return 'Embedded'
      case 'SPILLOVER':
        return compensatedFile.spillover_file_name
      default:
        return '-'
    }
  }, [compensatedFile.spillover_file_name, compensatedFile?.spillover_origin])

  return (
    <AnalysisHeaderDetailsRoot>
      <TitleDataLine
        inverted={true}
        title={analysis.name + (isAnalysisSaved ? '' : '*')}
        content={analysis.breadcrumbs.join(' - ')}
      />
      <StyledDataLine
        title="Sample acquisition date"
        content={fcsFile.acquisition_date}
      />
      <StyledDataLine title="Spillover title" content={spilloverName} />
      <StyledDataLine
        title="Clustering Date"
        content={formatDateTime(analysis.created_at)}
        contentTooltip={`${formatDateTime(analysis.created_at)}${
          analysis.clustering_algo_version
            ? ` - ${analysis.clustering_algo_version}`
            : ''
        }`}
      />
    </AnalysisHeaderDetailsRoot>
  )
}

const AnalysisHeaderDetailsRoot = styled.div`
  display: flex;
  min-width: 0;
`

const TitleDataLine = styled(DataLine)`
  max-width: 450px;
  min-width: 110px;
  font-family: ${props => props.theme.font.style.bold};
  margin: 0 20px;
`

const StyledDataLine = styled(DataLine)`
  max-width: 250px;
  margin: 0 20px;
`
