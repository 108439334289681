import { filesize } from 'filesize'
import { useState } from 'react'
import { FileRejection } from 'react-dropzone'
import styled from 'styled-components'

import { Button } from 'components/button/Button'
import { Modal } from 'components/modal/Modal'
import { ModalContainer } from 'components/modal/ModalContainer'
import { Table, TableColumn, TableRow } from 'components/table/Table'
import { TextField } from 'components/text-field/TextField'

import { Dropzone } from 'pages/project/Dropzone'

import {
  useCreateFcsFilesMutation,
  useReportFcsFileUploadErrorMutation,
} from 'shared/api/files.api'
import { useEventCallback } from 'shared/hooks/useEventCallback'
import { useAppDispatch } from 'shared/store'
import { showNotification } from 'shared/store/notification.slice'
import { formatDateTimeFileName } from 'shared/utils/utils'

import {
  addFcsFileBeingUploaded,
  removeFcsFileBeingUploaded,
} from './store/ui.slice'

type FcsFileUploadModalProps = {
  projectId: string
  onClose: () => void
}

export const FcsFileUploadModal: React.FC<FcsFileUploadModalProps> = ({
  projectId,
  onClose,
}) => {
  const dispatch = useAppDispatch()
  const [triggerCreateFcsFilesMutation, createFcsFilesMutationResult] =
    useCreateFcsFilesMutation()
  const [triggerReportFcsFileUploadErrorMutation] =
    useReportFcsFileUploadErrorMutation()

  const [files, setFiles] = useState<File[]>([])
  const [batchName, setBatchName] = useState(
    `batch-${formatDateTimeFileName(new Date())}`,
  )

  const handleFileSelection = useEventCallback((selectedFiles: File[]) => {
    setFiles([...files, ...selectedFiles])
  })

  const handleFileSelectionError = (rejections: FileRejection[]) => {
    dispatch(
      showNotification({
        type: 'error',
        description: rejections[0].errors[0].message,
      }),
    )
  }

  const handleBatchNameChange = useEventCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      setBatchName(e.target.value)
    },
  )

  const handleConfirmUpload = useEventCallback(() => {
    onClose()
    triggerCreateFcsFilesMutation(
      files.map(file => ({
        file_name: file.name,
        project: projectId,
        batch_name: batchName,
        size: Math.round(file.size / 1000),
      })),
    )
      .unwrap()
      .then(createdFiles => {
        for (const createdFile of Object.values(createdFiles)) {
          dispatch(addFcsFileBeingUploaded(createdFile.id))
          const file = files[createdFile.index]
          fetch(createdFile.url, {
            method: 'PUT',
            body: file,
          })
            .catch(error => {
              triggerReportFcsFileUploadErrorMutation(createdFile.id)
              dispatch(
                showNotification({
                  type: 'error',
                  description: `Failed to upload ${file.name}: ${error}`,
                }),
              )
            })
            .finally(() => {
              dispatch(removeFcsFileBeingUploaded(createdFile.id))
            })
        }
      })
  })

  const columns: TableColumn[] = [
    {
      key: 'name',
      label: 'File Name',
      style: { width: '80%' },
      canSort: false,
    },
    {
      key: 'size',
      label: 'Size',
      style: { width: '20%' },
      canSort: false,
    },
  ]

  const rows: TableRow[] = files.map(file => ({
    key: file.name,
    cells: [
      { key: 'name', content: file.name },
      { key: 'size', content: filesize(file.size) },
    ],
  }))

  return (
    <Modal
      open
      title="Add new FCS files"
      onClose={onClose}
      Container={StyledModalContainer}
    >
      {files.length > 0 && (
        <>
          <TextField
            label="Batch Name"
            value={batchName}
            onChange={handleBatchNameChange}
          />
          <Table columns={columns} rows={rows} />
        </>
      )}
      <StyledDropzone
        fileType="fcs"
        accept={['.fcs', '.lmd']}
        onFileSelection={handleFileSelection}
        onDropError={handleFileSelectionError}
        multiple
      />
      <Actions>
        <Button colorOverride="greyscale" onClick={onClose}>
          Close
        </Button>
        <Button
          onClick={handleConfirmUpload}
          disabled={files.length === 0}
          loading={createFcsFilesMutationResult.isLoading}
        >
          Upload
        </Button>
      </Actions>
    </Modal>
  )
}

const StyledModalContainer = styled(ModalContainer)`
  width: 700px;
  font-size: 13px;
  max-height: 90vh;
`

const StyledDropzone = styled(Dropzone)`
  width: 580px;
  margin-top: 20px;
`

const Actions = styled.div`
  display: flex;
  gap: 12px;
  margin-top: 50px;
`
