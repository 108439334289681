import { DatePicker } from 'antd'
import { Dayjs } from 'dayjs'
import { RangePickerProps } from 'rc-picker'
import { useState } from 'react'

import { TableFilter } from './TableFilter'

const { RangePicker } = DatePicker

type DateFilterProps = Pick<RangePickerProps<Dayjs>, 'value' | 'onChange'> & {
  title?: string
}

export type DateFilterValue = DateFilterProps['value']

export const DateFilter: React.FC<DateFilterProps> = ({
  value,
  title,
  onChange,
}) => {
  const [isFilterExpanded, setIsFilterExpanded] = useState(false)

  const isSelected =
    value !== undefined &&
    value !== null &&
    value[0] !== null &&
    value[1] !== null

  const handleChange: typeof onChange = (...args) => {
    setIsFilterExpanded(false)
    onChange?.(...args)
  }

  return (
    <TableFilter
      isExpanded={isFilterExpanded}
      isSelected={isSelected}
      onButtonClick={() => setIsFilterExpanded(!isFilterExpanded)}
      onClickAway={() => setIsFilterExpanded(false)}
      title={title ?? 'Date'}
    >
      <RangePicker
        value={value}
        onChange={handleChange}
        popupStyle={{ zIndex: 1301 }}
      />
    </TableFilter>
  )
}
