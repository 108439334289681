import Highcharts from 'highcharts'
import { useEffect, useMemo, useState } from 'react'

import { ClusterActionsContextMenu } from 'pages/analysis/ClusterActionsContextMenu'
import { highlightCluster } from 'pages/analysis/store/analysis.slice'
import {
  Cluster,
  selectActiveLeavesWithoutRoot,
  selectClusterById,
  selectGloballyHiddenClusters,
  selectHighlightedCluster,
} from 'pages/analysis/store/selectors'

import { useClickPrevention } from 'shared/hooks/useClickPrevention'
import { useEventCallback } from 'shared/hooks/useEventCallback'
import { useAppDispatch, useAppSelector } from 'shared/store'

import { createAnalysisPieChartBaseOptions } from './AnalysisPieChartBaseOptions'
import { Chart } from './Chart'

type PieChartProps = {
  width: number
  height: number
  onClusterClick: (id: string) => void
  onHoveredClusterChange: (
    cluster: Cluster | undefined,
    point: [number, number],
  ) => void
}

export const PieChart = ({
  width,
  height,
  onClusterClick,
  onHoveredClusterChange,
}: PieChartProps): JSX.Element => {
  const dispatch = useAppDispatch()
  const highlightedCluster = useAppSelector(selectHighlightedCluster)
  const globallyHiddenClusters = useAppSelector(selectGloballyHiddenClusters)
  const activeLeavesWithoutRoot = useAppSelector(selectActiveLeavesWithoutRoot)
  const clusterById = useAppSelector(selectClusterById)

  // Data
  const [contextMenuId, setContextMenuId] = useState<string>()
  const [menuOrigin, setMenuOrigin] = useState<{ x: number; y: number }>()
  const [chart, setChart] = useState<Highcharts.Chart>()
  const [shouldAnimate, setShouldAnimate] = useState(true)

  // Callbacks
  const openContextMenu = useEventCallback(
    (event: MouseEvent, p: Highcharts.Point) => {
      event.preventDefault()
      const { clientX, clientY } = event
      setContextMenuId(p.id)
      setMenuOrigin({ x: clientX, y: clientY })
    },
  )
  const openTooltip = useEventCallback(
    (
      event: MouseEvent,
      p: Highcharts.Point & Partial<Pick<Cluster, 'parent_rate'>>,
    ) => {
      event.preventDefault()
      const { clientX: x, clientY: y } = event
      const cluster = clusterById[p.id]

      onHoveredClusterChange(cluster, [x, y])
    },
  )

  const closeTooltip = useEventCallback(() => {
    onHoveredClusterChange(undefined, [0, 0])
  })

  const onDoubleClick = useEventCallback((p?: Highcharts.Point) => {
    if (p?.id) {
      dispatch(highlightCluster({ clusterId: p.id }))
    }
  })

  const [handleClick, handleDoubleClick] = useClickPrevention({
    onClick: onClusterClick,
    onDoubleClick,
  })

  const options = useMemo((): Highcharts.Options => {
    const AnalysisPieChartBaseOptions = createAnalysisPieChartBaseOptions({
      leaves: activeLeavesWithoutRoot,
      globallyHiddenClusters,
      highlightedCluster,
      shouldAnimate,
    })

    return {
      ...AnalysisPieChartBaseOptions,
      chart: {
        ...AnalysisPieChartBaseOptions.chart,
        width,
        height,
      },
    }
  }, [
    activeLeavesWithoutRoot,
    globallyHiddenClusters,
    highlightedCluster,
    shouldAnimate,
    width,
    height,
  ])

  // Effects
  useEffect(() => {
    chart?.series?.forEach(s => {
      s.points.forEach((p: Highcharts.Point) => {
        const elm = p.graphic?.element
        if (elm) {
          elm.oncontextmenu = e => openContextMenu(e, p)
          elm.onmouseenter = e => openTooltip(e, p)
          elm.onmouseleave = () => closeTooltip()
          elm.onclick = () => handleClick(p.id)
          elm.ondblclick = () => handleDoubleClick(p)
        }
        // eslint-disable-next-line
        // @ts-ignore-next-line
        const label = p.dataLabel.element
        if (label) {
          label.oncontextmenu = e => openContextMenu(e, p)
          label.onmouseenter = e => openTooltip(e, p)
          label.onmouseleave = () => closeTooltip()
          label.onclick = () => handleClick(p.id)
          label.ondblclick = () => handleDoubleClick(p)
        }
      })
    })
  }, [
    chart,
    openContextMenu,
    openTooltip,
    closeTooltip,
    handleClick,
    handleDoubleClick,
  ])

  useEffect(() => {
    setShouldAnimate(false)
  }, [])

  return (
    <div>
      <Chart
        highcharts={Highcharts}
        options={options}
        callback={setChart}
        data-cy="pie-chart"
      />
      {contextMenuId && menuOrigin && (
        <ClusterActionsContextMenu
          clusterId={contextMenuId}
          menuOrigin={menuOrigin}
          onClose={() => {
            setContextMenuId(undefined)
            setMenuOrigin(undefined)
          }}
          source="graph"
          actions={{
            changeClusterColor: true,
            changeClusterName: true,
            toggleClusterVisibilityAll: true,
          }}
        />
      )}
    </div>
  )
}
