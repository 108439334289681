import { skipToken } from '@reduxjs/toolkit/query'
import { Formik, FormikErrors } from 'formik'
import { mapValues, pick } from 'lodash'
import { FC, useEffect, useMemo, useState } from 'react'
import styled from 'styled-components'
import { array, boolean, lazy, number, object, string } from 'yup'

import { CircularProgress } from 'components/CircularProgress'
import { Button } from 'components/button/Button'
import { Modal } from 'components/modal/Modal'
import { ModalContainer } from 'components/modal/ModalContainer'

import { useGetCompensatedFileByIdQuery } from 'shared/api/files.api'
import { useGetUserQuery } from 'shared/api/user.api'
import {
  Brick,
  BrickSettingsTransformationType,
  PrecisionAndGranularityMode,
  useCreateBrickMutation,
  useGetBrickQuery,
  usePreCreateAdjustBrickSettingsMutation,
  usePreCreateBuildBrickSettingsMutation,
} from 'shared/api/workflows.api'
import { calculateClusteringGranularity } from 'shared/utils/clustering.utils'

import { CreateBrickWizardBasicSettingsStep } from './CreateBrickWizardBasicSettingsStep'
import { CreateBrickWizardSelectChannelsStep } from './CreateBrickWizardSelectChannelsStep'
import { CreateBrickWizardSelectClustersAndLassosStep } from './CreateBrickWizardSelectClustersAndLassosStep'
import { CreateBrickWizardSummaryStep } from './CreateBrickWizardSummaryStep'
import { WizardStepper } from './WizardStepper'
import {
  DEFAULT_KD,
  DEFAULT_KT,
  DEFAULT_TRANSFORMATION_FACTOR_BY_CYTOMETER,
  MAX_KD,
  MAX_KT,
  MAX_TRANSFORMATION_FACTOR,
  MIN_KD,
  MIN_KT,
  MIN_TRANSFORMATION_FACTOR,
} from './constants'

type CreateBrickWizardProps = {
  brick: Brick
  onClose: () => void
}

export type CreateBrickFormValues = {
  alignClusters: boolean
  concatenateFiles: boolean
  brickType: 'clustering' | 'sub-analysis'
  doubletExclusion: boolean
  precisionAndGranularityMode: PrecisionAndGranularityMode
  kd: number
  kt: number
  metaClean: boolean
  name: string
  selectedChannels: string[]
  transformationMethod: BrickSettingsTransformationType | 'none'
  transformationFactor: number
  selectedClusterNames: string[]
  selectedLassoIds: string[]
  selectedChannelIds: string[]
}

const CreateBrickSchema = lazy((values: CreateBrickFormValues) =>
  object({
    alignClusters: boolean(),
    concatenateFiles: boolean(),
    doubletExclusion: string(),
    kd: number().min(MIN_KD).max(MAX_KD).required(),
    kt: number().min(MIN_KT).max(MAX_KT).required(),
    metaClean: boolean(),
    name: string().trim().max(80).required(),
    selectedChannels: array(string().required()).min(
      2,
      'You must select at least two channels',
    ),
    transformationMethod: string().required(),
    transformationFactor: number()
      .min(MIN_TRANSFORMATION_FACTOR)
      .max(MAX_TRANSFORMATION_FACTOR)
      .required(),
    selectedClusterNames:
      values.selectedLassoIds.length === 0
        ? array().min(1, 'Please select at least one cluster or lasso')
        : array(),
    selectedLassoIds:
      values.selectedClusterNames.length === 0
        ? array().min(1, 'Please select at least one cluster or lasso')
        : array(),
    selectedChannelIds:
      values.brickType === 'clustering'
        ? array(string().required()).min(
            2,
            'Please select at least two channels',
          )
        : array(),
  }),
)

export const CreateBrickWizard: FC<CreateBrickWizardProps> = ({
  brick,
  onClose,
}) => {
  const getBrickQueryState = useGetBrickQuery(brick.id)
  const compensatedFileByIdQueryState = useGetCompensatedFileByIdQuery(
    getBrickQueryState.data?.analyses.map(
      analysis => analysis.compensated_file,
    ) || skipToken,
  )

  const [
    triggerPreCreateBrickSettingsMutation,
    preCreateBrickSettingsMutationState,
  ] = usePreCreateBuildBrickSettingsMutation()

  const [
    triggerPreCreateAdjustBrickSettingsMutation,
    preCreateAdjustBrickSettingsMutationState,
  ] = usePreCreateAdjustBrickSettingsMutation()

  const [triggerCreateBrickMutation, createBrickMutationState] =
    useCreateBrickMutation()

  const isExpert = !!useGetUserQuery().data?.is_expert

  const [currentStepIndex, setCurrentStepIndex] = useState(0)

  const compensatedFiles = compensatedFileByIdQueryState.data
    ? Object.values(compensatedFileByIdQueryState.data)
    : undefined

  const initialValues: CreateBrickFormValues = useMemo(() => {
    const settings = preCreateBrickSettingsMutationState.data?.settings
    return {
      addGraphsForSelectedChannels:
        !!settings?.add_graphs_for_selected_channels,
      alignClusters: !!settings?.align_clusters,
      brickType: 'clustering',
      concatenateFiles: !!settings?.concatenate_files,
      doubletExclusion: !!settings?.doublet_exclusion,
      precisionAndGranularityMode:
        settings?.precision_and_granularity_mode ?? 'standard',
      kd: settings?.kd ?? DEFAULT_KD,
      kt:
        (compensatedFiles &&
          calculateClusteringGranularity({
            mode: 'standard',
            compensatedFiles,
            clusteringType: 'primary',
          })) ??
        settings?.kt ??
        DEFAULT_KT,
      metaClean: !!settings?.metaclean,
      name: preCreateBrickSettingsMutationState.data?.name ?? '',
      normalisation: !!settings?.normalisation,
      selectedChannels: [],
      transformationMethod: settings?.transformation_method ?? 'none',
      transformationFactor:
        settings?.transformation_args.factor ??
        DEFAULT_TRANSFORMATION_FACTOR_BY_CYTOMETER[
          getBrickQueryState.data?.settings.cytometer ?? 'conventional'
        ],
      selectedClusterNames: [],
      selectedLassoIds: [],
      selectedChannelIds: [],
    }
  }, [
    compensatedFiles,
    getBrickQueryState.data?.settings.cytometer,
    preCreateBrickSettingsMutationState.data?.name,
    preCreateBrickSettingsMutationState.data?.settings,
  ])

  const isMutationLoading =
    preCreateAdjustBrickSettingsMutationState.isLoading ||
    createBrickMutationState.isLoading

  useEffect(() => {
    triggerPreCreateBrickSettingsMutation({
      parent: brick.id,
    })
  }, [brick.id, triggerPreCreateBrickSettingsMutation])

  return (
    <Modal
      open
      title="Create new step"
      onClose={onClose}
      Container={StyledModalContainer}
    >
      <StyledWizardStepper
        steps={[
          'Set up new clustering step',
          'Select clusters',
          'Select channels',
          'Summary',
        ]}
        currentStepIndex={currentStepIndex}
      />
      <Formik<CreateBrickFormValues>
        initialValues={initialValues}
        enableReinitialize
        validationSchema={CreateBrickSchema}
        onSubmit={() => {}}
      >
        {({ values, errors, validateForm, setFieldError }) => {
          const getFirstApplicableError = (
            errors: FormikErrors<CreateBrickFormValues>,
          ) => {
            switch (currentStepIndex) {
              case 0:
                return errors.name
              case 1:
                return errors.selectedClusterNames || errors.selectedLassoIds
              case 2:
                return errors.selectedChannelIds
              case 3:
                return undefined
              default:
                throw new Error('Invalid step index')
            }
          }

          const finalPayload = preCreateAdjustBrickSettingsMutationState.data &&
            getBrickQueryState.data && {
              ...preCreateAdjustBrickSettingsMutationState.data!,
              settings: {
                ...preCreateAdjustBrickSettingsMutationState.data!.settings,
                channels: mapValues(
                  preCreateAdjustBrickSettingsMutationState.data!.settings
                    .channels,
                  (value, channelId) => ({
                    ...value,
                    is_selected:
                      values.brickType === 'clustering' &&
                      values.selectedChannelIds.includes(channelId),
                  }),
                ),
                cytometer: getBrickQueryState.data!.settings.cytometer,
                add_graphs_for_selected_channels: false,
                normalisation: true,
                transformation_method:
                  values.transformationMethod !== 'none'
                    ? values.transformationMethod
                    : null,
                precision_and_granularity_mode:
                  values.precisionAndGranularityMode,
                kd: values.kd,
                kt: values.kt,
              },
            }

          const handleNextStep = () => {
            validateForm().then(errors => {
              const firstApplicableError = getFirstApplicableError(errors)
              if (firstApplicableError) {
                return
              }

              if (currentStepIndex === 0) {
                setCurrentStepIndex(currentStepIndex + 1)
                setFieldError('selectedClusterNames', undefined)
                setFieldError('selectedLassoIds', undefined)
              }

              if (currentStepIndex === 1) {
                triggerPreCreateAdjustBrickSettingsMutation({
                  ...preCreateBrickSettingsMutationState.data!,
                  name: values.name,
                  settings: {
                    ...preCreateBrickSettingsMutationState.data!.settings,
                    normalisation: true,
                    transformation_method:
                      values.transformationMethod === 'none'
                        ? null
                        : values.transformationMethod,
                    cluster_names: values.selectedClusterNames,
                    lassos: pick(
                      preCreateBrickSettingsMutationState.data!.settings
                        .lassos!,
                      values.selectedLassoIds,
                    ),
                  },
                }).then(() => {
                  setCurrentStepIndex(currentStepIndex + 1)
                  setFieldError('selectedChannelIds', undefined)
                })
              }

              if (currentStepIndex === 2) {
                setCurrentStepIndex(currentStepIndex + 1)
              }

              if (currentStepIndex === 3) {
                triggerCreateBrickMutation(finalPayload!)
                  .unwrap()
                  .then(() => {
                    onClose()
                  })
              }
            })
          }

          const handlePreviousStep = () => {
            setCurrentStepIndex(currentStepIndex - 1)
          }

          return (
            <>
              <ScrollWrapper>
                <Fieldset disabled={isMutationLoading}>
                  {preCreateBrickSettingsMutationState.isLoading ||
                  getBrickQueryState.isLoading ||
                  compensatedFileByIdQueryState.isLoading ? (
                    <CircularProgress />
                  ) : (
                    <>
                      {currentStepIndex === 0 && (
                        <CreateBrickWizardBasicSettingsStep />
                      )}
                      {currentStepIndex === 1 && (
                        <CreateBrickWizardSelectClustersAndLassosStep
                          clusterNames={
                            preCreateBrickSettingsMutationState.data!.settings
                              .cluster_names
                          }
                          lassos={Object.values(
                            preCreateBrickSettingsMutationState.data!.settings
                              .lassos!,
                          )}
                        />
                      )}
                      {currentStepIndex === 2 && (
                        <CreateBrickWizardSelectChannelsStep
                          channels={
                            preCreateAdjustBrickSettingsMutationState.data!
                              .settings.channels
                          }
                          isExpert={isExpert}
                          compensatedFiles={compensatedFiles!}
                          parentPrecisionAndGranularityMode={
                            getBrickQueryState.data!.settings
                              .precision_and_granularity_mode
                          }
                        />
                      )}
                      {currentStepIndex === 3 && (
                        <CreateBrickWizardSummaryStep
                          finalPayload={finalPayload!}
                          isSubAnalysis={values.brickType === 'sub-analysis'}
                        />
                      )}
                    </>
                  )}
                </Fieldset>
              </ScrollWrapper>
              <Actions>
                {currentStepIndex === 0 && (
                  <Button colorOverride="greyscale" onClick={onClose}>
                    Cancel
                  </Button>
                )}
                {currentStepIndex > 0 && (
                  <Button
                    colorOverride="greyscale"
                    onClick={handlePreviousStep}
                  >
                    Previous
                  </Button>
                )}
                <Button
                  onClick={handleNextStep}
                  disabled={
                    !!(
                      getFirstApplicableError(errors) ||
                      preCreateBrickSettingsMutationState.error
                    )
                  }
                  loading={isMutationLoading}
                >
                  {currentStepIndex === 3 ? 'Create' : 'Next'}
                </Button>
              </Actions>
            </>
          )
        }}
      </Formik>
    </Modal>
  )
}

const StyledModalContainer = styled(ModalContainer)`
  min-width: 800px;
  min-height: 400px;
  max-height: 90vh;
  font-size: 13px;
  align-items: stretch;
  padding: 24px 32px;
`

const ScrollWrapper = styled.div`
  overflow-y: auto;
`

const StyledWizardStepper = styled(WizardStepper)`
  margin-bottom: ${props => props.theme.spacing(4)}px;
  align-self: flex-start;
`

const Actions = styled.div`
  display: flex;
  gap: 12px;
  margin-top: ${props => props.theme.spacing(2)}px;
  align-self: flex-end;
`

const Fieldset = styled.fieldset`
  border: none;
`
