import { filesize } from 'filesize'
import styled from 'styled-components'

import { ReactComponent as DeleteFileIcon } from 'assets/images/icons/deleteFile.svg'
import { ReactComponent as UploadFileIcon } from 'assets/images/icons/upload-file.svg'

import { CircularProgress } from 'components/CircularProgress'
import { Button } from 'components/button/Button'
import { Modal } from 'components/modal/Modal'
import { ModalContainer } from 'components/modal/ModalContainer'
import { Table, TableColumn, TableRow } from 'components/table/Table'

import { useGetExperimentQuery } from 'shared/api/experiments.api'
import { WorkflowStatus } from 'shared/api/workflows.api'
import { useModal } from 'shared/contexts/ModalContext'
import { useEventCallback } from 'shared/hooks/useEventCallback'
import { formatDateTime } from 'shared/utils/utils'

import { AddFileToExperiment } from './AddFileToExperiment'
import { DeleteFileFromExperiment } from './DeleteFileFromExperiment'

type ExperimentFilesModalProps = {
  projectId: string
  experimentId: string
  onClose: () => void
}

export const ExperimentFilesModal: React.FC<ExperimentFilesModalProps> = ({
  projectId,
  experimentId,
  onClose,
}) => {
  const experimentQuery = useGetExperimentQuery(experimentId)

  const disableActions =
    experimentQuery.data?.status === WorkflowStatus.Processing

  const columns: TableColumn[] = [
    {
      key: 'file_name',
      label: 'File name',
    },
    {
      key: 'total_cell_count',
      label: 'Total cell count',
    },
    {
      key: 'author',
      label: 'Author',
    },
    {
      key: 'size',
      label: 'Size',
    },
    {
      key: 'analyses',
      label: 'Analyses',
    },
    {
      key: 'sample_acquisition_date',
      label: 'Sample Acquisition',
    },
  ]

  const rows: TableRow[] =
    experimentQuery.data?.compensated_files?.map(file => ({
      key: file.id,
      cells: [
        {
          key: 'fileName',
          content: file.name,
        },
        {
          key: 'cellCount',
          content: file.fcs_file_total_cell_count,
        },
        {
          key: 'author',
          content: file.author_name,
        },
        {
          key: 'size',
          content: filesize((file.fcs_file_size ?? 0) * 1000),
        },
        {
          key: 'analyses',
          content: 0,
        },
        {
          key: 'sampleAcquisition',
          content: formatDateTime(file.fcs_file_acquisition_date),
        },
      ],
    })) ?? []

  const { showModal } = useModal()

  const handleShowUploadModal = useEventCallback(() => {
    showModal(AddFileToExperiment, { projectId, experimentId })
  })

  const handleShowDeleteModal = useEventCallback(() => {
    onClose()
    showModal(DeleteFileFromExperiment, {
      projectId,
      experimentId,
    })
  })

  return (
    <Modal
      open
      title="Workflow files"
      Container={StyledModalContainer}
      onClose={onClose}
    >
      <ScrollContainer>
        <ButtonsContainer>
          <SectionHeaderActionButton
            colorOverride="error"
            onClick={handleShowDeleteModal}
            disabled={disableActions}
          >
            <DeleteFileIcon />
            Delete files
          </SectionHeaderActionButton>
          <SectionHeaderActionButton
            colorOverride="success"
            onClick={handleShowUploadModal}
            disabled={disableActions}
          >
            <UploadFileIcon />
            Upload files
          </SectionHeaderActionButton>
        </ButtonsContainer>
        {experimentQuery.isLoading && <CircularProgress />}
        {experimentQuery.isSuccess && <Table columns={columns} rows={rows} />}
      </ScrollContainer>
      <Actions>
        <Button onClick={onClose}>Close</Button>
      </Actions>
    </Modal>
  )
}

const StyledModalContainer = styled(ModalContainer)`
  width: 90vw;
  max-width: 1200px;
  max-height: 90vh;
  overflow: hidden;
`

const ScrollContainer = styled.div`
  overflow-y: auto;
  align-self: stretch;
`

const Actions = styled.div`
  margin-top: 24px;
`

const SectionHeaderActionButton = styled(Button)`
  height: 48px;
  padding: 0 ${props => props.theme.spacing(2)}px;
  float: right;

  & > span > svg {
    margin-right: ${props => props.theme.spacing(1)}px;
  }
`

const ButtonsContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  gap: 1rem;
  margin: 0.5rem 0;
`
