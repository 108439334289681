import styled from 'styled-components'

import { CircularProgress } from 'components/CircularProgress'
import { Modal } from 'components/modal/Modal'
import { ModalContainer } from 'components/modal/ModalContainer'

import {
  useGetExperimentQuery,
  useUpdateExperimentMutation,
} from 'shared/api/experiments.api'

import { WorkflowView } from './WorkflowView'

type ExperimentWorkflowModalProps = {
  experimentId: string
  onClose: () => void
}

export const ExperimentWorkflowModal: React.FC<
  ExperimentWorkflowModalProps
> = ({ experimentId, onClose }) => {
  const experimentQueryState = useGetExperimentQuery(experimentId)
  const [triggerUpdateExperimentMutation] = useUpdateExperimentMutation()

  const handleUpdateExperiment = ({
    name,
    description,
  }: {
    name?: string
    description?: string
  }) => {
    triggerUpdateExperimentMutation({ id: experimentId, name, description })
  }

  return (
    <Modal
      open
      disableBackdropClick
      onClose={onClose}
      Container={StyledModalContainer}
      title={
        <Header>
          Experiment view
          <DebugId>{experimentId}</DebugId>
        </Header>
      }
    >
      {experimentQueryState.isLoading ? (
        <CircularProgress />
      ) : (
        <WorkflowView
          workflow={experimentQueryState.data}
          onUpdate={handleUpdateExperiment}
          onClose={onClose}
        />
      )}
    </Modal>
  )
}

const StyledModalContainer = styled(ModalContainer)`
  width: 60vw;
  min-width: 900px;
  gap: ${props => props.theme.spacing(2)}px;
`

const Header = styled.h2`
  width: 100%;
  text-align: center;
  font-family: ${({ theme }) => theme.font.style.bold};
  margin: 0;
  font-size: 20px;
`

const DebugId = styled.div`
  font-size: 10px;
  color: ${({ theme }) => theme.colors.greyscale[30]};
  font-family: ${({ theme }) => theme.font.style.light};
`
