import { RadioGroup } from '@material-ui/core'
import { ChangeEvent, FC, useState } from 'react'
import styled from 'styled-components'

import { ReactComponent as SpilloverIcon } from 'assets/images/icons/compensation-matrix.svg'
import { ReactComponent as WarningIcon } from 'assets/images/icons/warning.svg'

import { CircularProgress } from 'components/CircularProgress'
import RadioButton from 'components/RadioButton'
import { Button } from 'components/button/Button'
import { SearchInput } from 'components/input/SearchInput'
import { Modal } from 'components/modal/Modal'
import { ModalContainer } from 'components/modal/ModalContainer'
import { EmptyTableInfo } from 'components/table/EmptyTableInfo'
import { TableColumn, TableRow } from 'components/table/Table'
import { TableWithPagination } from 'components/table/TableWithPagination'

import {
  SpilloverOrigin,
  UnmixingMethod,
  useCreateCompensatedFilesMutation,
  useGetSpilloverFilesQuery,
} from 'shared/api/files.api'
import { useEventCallback } from 'shared/hooks/useEventCallback'

type ApplyCompensationMatrixModalProps = {
  selectedFcsFileIds: string[]
  projectId: string
  onClose: () => void
}

export const ApplyCompensationMatrixModal: FC<
  ApplyCompensationMatrixModalProps
> = ({ selectedFcsFileIds, projectId, onClose }) => {
  const [
    triggerCreateCompensatedFilesMutation,
    createCompensatedFilesMutationState,
  ] = useCreateCompensatedFilesMutation()

  const [page, setPage] = useState(1)
  const [searchValue, setSearchValue] = useState<string | undefined>(undefined)
  const [selectedCompensationOrUnmixMode, setSelectedCompensationOrUnmixMode] =
    useState<`${SpilloverOrigin}` | `${UnmixingMethod}`>(SpilloverOrigin.File)
  const [selectedSpilloverFileId, setSelectedSpilloverFileId] = useState('')

  const getSpilloverFilesQueryState = useGetSpilloverFilesQuery({
    page,
    projectId,
    search: {
      file_name: searchValue,
    },
  })

  const columns: TableColumn[] = [
    {
      key: 'select',
      style: { width: '10%' },
    },
    {
      key: 'file_name',
      style: { width: '90%' },
    },
  ]

  const rows: TableRow[] =
    getSpilloverFilesQueryState.data?.results.map(file => ({
      key: file.id,
      cells: [
        {
          key: 'select',
          content: <RadioButton value={file.id} label="" />,
        },
        {
          key: 'file_name',
          content: file.file_name,
        },
      ],
    })) ?? []

  const canApplySpillover =
    selectedCompensationOrUnmixMode === SpilloverOrigin.File
      ? selectedSpilloverFileId !== ''
      : true

  const handleSelectSpilloverFileId = useEventCallback(
    (event: ChangeEvent<HTMLInputElement>) =>
      setSelectedSpilloverFileId(event.target.value),
  )

  const handleCreateCompensatedFiles = useEventCallback(() => {
    triggerCreateCompensatedFilesMutation({
      fcs_file: selectedFcsFileIds,
      spillover_origin:
        selectedCompensationOrUnmixMode === UnmixingMethod.Embedded
          ? SpilloverOrigin.Embedded
          : selectedCompensationOrUnmixMode,
      spillover: selectedSpilloverFileId,
    })
      .unwrap()
      .then(onClose)
  })

  return (
    <Modal
      open
      title="Apply compensation matrix or unmix"
      onClose={onClose}
      Container={StyledModalContainer}
    >
      <InputField>
        <InputFieldLabel>Select compensation method</InputFieldLabel>
        <RadioGroup
          row
          value={selectedCompensationOrUnmixMode}
          onChange={event =>
            setSelectedCompensationOrUnmixMode(
              event.target.value as SpilloverOrigin,
            )
          }
        >
          <RadioButton
            value={SpilloverOrigin.File}
            label="Choose from library"
          />
          <RadioButton
            value={SpilloverOrigin.Embedded}
            label="Embedded compensation matrix"
          />
          <RadioButton value={SpilloverOrigin.None} label="No compensation" />
        </RadioGroup>
      </InputField>
      <InputField>
        <InputFieldLabel>Select unmixing method</InputFieldLabel>
        <RadioGroup
          row
          value={selectedCompensationOrUnmixMode}
          onChange={event =>
            setSelectedCompensationOrUnmixMode(
              event.target.value as UnmixingMethod,
            )
          }
        >
          <RadioButton
            value={UnmixingMethod.Embedded}
            label="Embedded unmixing"
          />
          <RadioButton
            value={UnmixingMethod.Unmix}
            label="Unmix (Coming soon)"
            disabled
          />
        </RadioGroup>
      </InputField>
      {selectedCompensationOrUnmixMode === SpilloverOrigin.File ? (
        <SpilloverFilesTableContainer>
          <SearchInput placeholder="Search files" onChange={setSearchValue} />
          {getSpilloverFilesQueryState.isLoading ? (
            <CircularProgress />
          ) : getSpilloverFilesQueryState.data?.results.length ? (
            <RadioGroup
              value={selectedSpilloverFileId}
              onChange={handleSelectSpilloverFileId}
            >
              <StyledTableWithPagination
                columns={columns}
                rows={rows}
                compactMode={true}
                page={page}
                onPageChange={(_, newPage) => setPage(newPage)}
                pageCount={getSpilloverFilesQueryState.data.page_count}
              />
            </RadioGroup>
          ) : (
            <StyledEmptyTableInfo
              text="No files found"
              Icon={<StyledSpilloverIcon />}
            />
          )}
        </SpilloverFilesTableContainer>
      ) : selectedCompensationOrUnmixMode === SpilloverOrigin.Embedded ? (
        <CompensationModeInfo>
          <WarningIcon />
          <span>
            Embedded compensation matrix will be applied for each file. <br />
            If the file does not contain an embedded matrix, it will be
            compensated nonetheless
          </span>
        </CompensationModeInfo>
      ) : selectedCompensationOrUnmixMode === SpilloverOrigin.None ? (
        <CompensationModeInfo>
          <WarningIcon />
          <span>No compensation selected</span>
        </CompensationModeInfo>
      ) : (
        <CompensationModeInfo>
          <WarningIcon />
          <span>Embedded unmixing will be applied for each file.</span>
        </CompensationModeInfo>
      )}
      <Actions>
        <Button colorOverride="greyscale" onClick={onClose}>
          Cancel
        </Button>
        <Button
          disabled={!canApplySpillover}
          loading={createCompensatedFilesMutationState.isLoading}
          onClick={handleCreateCompensatedFiles}
        >
          Confirm
        </Button>
      </Actions>
    </Modal>
  )
}

const StyledModalContainer = styled(ModalContainer)`
  min-width: 800px;
  font-size: 13px;
`

const InputField = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${props => props.theme.spacing(0.5)}px;
  margin: ${props => props.theme.spacing(2)}px 0;
  width: 90%;
`

const InputFieldLabel = styled.p`
  color: ${props => props.theme.colors.primaryDark[50]};
`

const SpilloverFilesTableContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${props => props.theme.spacing(2)}px;
  max-height: 90vh;
  width: 90%;
  padding: ${props => props.theme.spacing(2)}px;
  background-color: ${props => props.theme.colors.primary[10]};
  border-radius: ${props => props.theme.radius[4]}px;
`

const StyledTableWithPagination = styled(TableWithPagination)`
  display: block;
  max-height: calc(100vh - 630px);
  overflow-y: auto;
`

const StyledEmptyTableInfo = styled(EmptyTableInfo)`
  margin-top: ${props => props.theme.spacing(8)}px;
`

const StyledSpilloverIcon = styled(SpilloverIcon)`
  width: 160px;
  height: 160px;
  stroke: ${props => props.theme.colors.primaryDark[50]};
  stroke-width: 0.1;
  & > path {
    fill: ${props => props.theme.colors.primaryDark[50]};
  }
`

const CompensationModeInfo = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: ${props => props.theme.spacing(3)}px;
  width: 100%;
  margin: ${props => props.theme.spacing(6)}px 0;
  text-align: center;

  & > span {
    color: ${props => props.theme.colors.primaryDark[50]};
    font-size: ${props => props.theme.font.size.small}px;
  }
`

const Actions = styled.div`
  display: flex;
  gap: 12px;
  margin-top: ${props => props.theme.spacing(6)}px;
`
