import { createSelector } from '@reduxjs/toolkit'

import { CUMULATIVE_FLUORESCENCE_CHANNEL } from 'shared/constants'

import { selectScalesFromAnalysis } from './analysis.selectors'
import { selectCharts } from './charts.selectors'

export type ChartScale = {
  xAxis: Scale
  yAxis: Scale | undefined
}

export type ScatterPlotChartScale = {
  xAxis: Scale
  yAxis: Scale
}

export type ChartScales = {
  [chartId: string]: ChartScale | undefined
}

export const selectChartScales = createSelector(
  selectCharts,
  selectScalesFromAnalysis,
  (charts, scales): ChartScales => {
    return Object.fromEntries<ChartScale | undefined>(
      charts
        .filter(chart => chart.y_axis !== CUMULATIVE_FLUORESCENCE_CHANNEL)
        .map(chart => {
          if (
            !scales[chart.x_axis] ||
            (chart.y_axis && !scales[chart.y_axis])
          ) {
            return [chart.id, undefined]
          }

          return [
            chart.id,
            {
              xAxis: {
                min: scales[chart.x_axis]?.[0],
                max: scales[chart.x_axis]?.[1],
              },
              yAxis:
                chart.chart_type === 'Dot plot' && chart.y_axis
                  ? {
                      min: scales[chart.y_axis]?.[0],
                      max: scales[chart.y_axis]?.[1],
                    }
                  : undefined,
            },
          ]
        }),
    )
  },
)
