import {
  ColorLens as ColorLensIcon,
  Settings as SettingsIcon,
  SortByAlpha as SortByAlphaIcon,
} from '@material-ui/icons'
import React, { useState } from 'react'
import styled from 'styled-components'

import { DefaultErrorBoundary } from 'components/DefaultErrorBoundary'
import { Modal } from 'components/Modal'
import { HeatMap } from 'components/graphs/HeatMap'
import { LayoutItemCard } from 'components/graphs/LayoutItemCard'
import { LayoutItemCardHeader } from 'components/graphs/LayoutItemCardHeader'

import {
  useGetSelfOrganizingHeatMapResponseQuery,
  useStartSelfOrganizingHeatMapRequestMutation,
} from 'shared/api/analysis.api'
import { ANALYSIS_HEATMAP_ID } from 'shared/constants'
import { useObserve } from 'shared/hooks/useObserve'
import { useStatefulRequest } from 'shared/hooks/useStatefulRequest'
import { useAppDispatch, useAppSelector } from 'shared/store'

import { AnalysisHeatmapColorSelection } from './AnalysisHeatmapColorSelection'
import { selfOrganizingHeatMapDataFetched } from './store/analysis.slice'
import {
  selectAnalysisSelectedGraphicalElementIds,
  toggleAnalysisGraphicalElementSelection,
} from './store/selected-graphical-elements.slice'
import {
  selectActiveLeafIds,
  selectAnalysisId,
  selectSortedSelectedChannels,
} from './store/selectors'
import { selectIsSelfOrganizingHeatMapQueryStale } from './store/selectors/self-organizing-heat-map.selectors'

enum HeatmapMode {
  DISPLAY = 'DISPLAY',
  COLOR_SELECTION = 'COLOR_SELECTION',
}

type AnalysisHeatmapProps = {
  isExpanded?: boolean
  className?: string
  onCloseExpand?: () => void
  openEditDisplayedChannelsDrawer: () => void
  openEditHeatmapLayoutDrawer: () => void
}

export const AnalysisHeatmap: React.FC<AnalysisHeatmapProps> = ({
  isExpanded,
  className,
  onCloseExpand,
  openEditDisplayedChannelsDrawer,
  openEditHeatmapLayoutDrawer,
}) => {
  const dispatch = useAppDispatch()
  const selectedGraphicalElements = useAppSelector(
    selectAnalysisSelectedGraphicalElementIds,
  )

  const [mode, setMode] = useState(HeatmapMode.DISPLAY)
  const [shouldShowExpandedSelf, setShouldShowExpandedSelf] = useState(false)

  const toggleColorSelectionMode = () => {
    if (mode === HeatmapMode.COLOR_SELECTION) {
      setMode(HeatmapMode.DISPLAY)
    } else {
      setMode(HeatmapMode.COLOR_SELECTION)
    }
  }

  useSelfOrganizingHeatMap()

  return (
    <AnalysisHeatmapRoot $isExpanded={isExpanded} className={className}>
      <StyledLayoutItemCardHeader
        title="Heat map"
        actions={[
          {
            icon: <SortByAlphaIcon />,
            onClick: openEditHeatmapLayoutDrawer,
            tooltip: 'Organize heat map',
          },
          {
            icon: <SettingsIcon />,
            onClick: openEditDisplayedChannelsDrawer,
            tooltip: 'Edit displayed channels',
          },
          {
            icon: <ColorLensIcon />,
            onClick: toggleColorSelectionMode,
            tooltip: 'Modify color palette',
          },
        ]}
        isSelected={selectedGraphicalElements.some(
          s => s === ANALYSIS_HEATMAP_ID,
        )}
        isExpanded={isExpanded}
        onSelect={() => {
          dispatch(toggleAnalysisGraphicalElementSelection(ANALYSIS_HEATMAP_ID))
        }}
        onExpand={() => setShouldShowExpandedSelf(true)}
        onCloseExpand={onCloseExpand}
      />
      <DefaultErrorBoundary>
        <HeatMapWrapper>
          <HeatMap hideHeatmap={mode !== HeatmapMode.DISPLAY} />
          {mode === HeatmapMode.COLOR_SELECTION && (
            <AnalysisHeatmapColorSelection
              closeMenu={() => setMode(HeatmapMode.DISPLAY)}
            />
          )}
        </HeatMapWrapper>
      </DefaultErrorBoundary>
      {shouldShowExpandedSelf && (
        <Modal open onClose={() => setShouldShowExpandedSelf(false)}>
          <ExpandedAnalysisHeatmap
            openEditDisplayedChannelsDrawer={openEditDisplayedChannelsDrawer}
            openEditHeatmapLayoutDrawer={openEditHeatmapLayoutDrawer}
            onCloseExpand={() => setShouldShowExpandedSelf(false)}
          />
        </Modal>
      )}
    </AnalysisHeatmapRoot>
  )
}

const AnalysisHeatmapRoot = styled(LayoutItemCard)`
  grid-template-rows: auto 1fr;
  padding: 0;
`

const StyledLayoutItemCardHeader = styled(LayoutItemCardHeader)`
  padding: 12px;
`

const HeatMapWrapper = styled.div`
  overflow: auto;
`

const ExpandedAnalysisHeatmap = styled(AnalysisHeatmap).attrs({
  isExpanded: true,
})`
  width: 90vw;
  height: 90vh;
`

const useSelfOrganizingHeatMap = () => {
  const dispatch = useAppDispatch()
  const analysisId = useAppSelector(selectAnalysisId)
  const activeLeafIds = useAppSelector(selectActiveLeafIds)
  const selectedChannels = useAppSelector(selectSortedSelectedChannels)
  const isSelfOrganizingHeatMapQueryStale = useAppSelector(
    selectIsSelfOrganizingHeatMapQueryStale,
  )

  const { start: startSelfOrganizingHeatMapRequest } = useStatefulRequest({
    useStartRequestMutation: useStartSelfOrganizingHeatMapRequestMutation,
    useGetResponseQuery: useGetSelfOrganizingHeatMapResponseQuery,
  })

  useObserve(isSelfOrganizingHeatMapQueryStale, isStale => {
    if (isStale) {
      startSelfOrganizingHeatMapRequest(
        {
          analysisId,
          selectedChannels,
          selectedClusterIds: activeLeafIds,
        },
        response => {
          if (response.isReady) {
            dispatch(
              selfOrganizingHeatMapDataFetched({
                result: response.data,
                parameters: {
                  selectedChannels,
                  selectedClusterIds: activeLeafIds,
                },
              }),
            )
          }
          return { isFinished: response.isReady }
        },
      )
    }
  })
}
