import { Layout } from 'components/chart-grid'

import { getURLSearchParam } from './utils'

export const getCurrentLayoutKey = (): keyof Layout => {
  return (getURLSearchParam('layout') as keyof Layout) ?? 'single'
}

export const getMultiTabModeSessionId = (): string | undefined => {
  return (getURLSearchParam('multiTabModeSessionId') as string) ?? undefined
}

export const getIsMultiTabMode = (): boolean => {
  return (
    getCurrentLayoutKey() !== 'single' &&
    getMultiTabModeSessionId() !== undefined
  )
}

export const getIsSecondaryTab = (): boolean => {
  return getCurrentLayoutKey() === 'secondary'
}
