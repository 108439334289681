import { filesize } from 'filesize'
import styled from 'styled-components'

import { Button } from 'components/button/Button'
import { Modal } from 'components/modal/Modal'
import { ModalContainer } from 'components/modal/ModalContainer'
import { Table, TableColumn, TableRow } from 'components/table/Table'

import { CompensatedFile } from 'shared/api/files.api'
import { formatDateTime } from 'shared/utils/utils'

type WorkflowFilesModalProps = {
  compensatedFiles: CompensatedFile[]
  onClose: () => void
}

export const WorkflowFilesModal: React.FC<WorkflowFilesModalProps> = ({
  compensatedFiles,
  onClose,
}) => {
  const columns: TableColumn[] = [
    {
      key: 'file_name',
      label: 'File name',
    },
    {
      key: 'total_cell_count',
      label: 'Total cell count',
    },
    {
      key: 'author',
      label: 'Author',
    },
    {
      key: 'size',
      label: 'Size',
    },
    {
      key: 'analyses',
      label: 'Analyses',
    },
    {
      key: 'sample_acquisition_date',
      label: 'Sample Acquisition',
    },
  ]

  const rows: TableRow[] =
    compensatedFiles.map(file => ({
      key: file.id,
      cells: [
        {
          key: 'fileName',
          content: file.name,
        },
        {
          key: 'cellCount',
          content: file.fcs_file_total_cell_count,
        },
        {
          key: 'author',
          content: file.author_name,
        },
        {
          key: 'size',
          content: filesize((file.fcs_file_size ?? 0) * 1000),
        },
        {
          key: 'analyses',
          content: 0,
        },
        {
          key: 'sampleAcquisition',
          content: formatDateTime(file.fcs_file_acquisition_date),
        },
      ],
    })) ?? []

  return (
    <Modal
      open
      title="Workflow files"
      Container={StyledModalContainer}
      onClose={onClose}
    >
      <ScrollContainer>
        <Table columns={columns} rows={rows} />
      </ScrollContainer>
      <Actions>
        <Button onClick={onClose}>Close</Button>
      </Actions>
    </Modal>
  )
}

const StyledModalContainer = styled(ModalContainer)`
  width: 90vw;
  max-width: 1200px;
  max-height: 90vh;
  overflow: hidden;
`

const ScrollContainer = styled.div`
  overflow-y: auto;
  align-self: stretch;
`

const Actions = styled.div`
  margin-top: 24px;
`
