import { isRejectedWithValue, Middleware } from '@reduxjs/toolkit'

import { unpackError } from 'shared/api/utils'
import { browserHistory } from 'shared/browserHistory'
import { handleError } from 'shared/utils/errorHandler'

import { showNotification } from './notification.slice'

export const globalErrorHandlingMiddleware: Middleware =
  store => next => action => {
    if (isRejectedWithValue(action)) {
      handleError(action)
      const { data: description, status } = unpackError(
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        action.payload as any,
        data => {
          const checkIfDataHasKey = (key: string) =>
            Object.hasOwn(data ?? {}, key)
          if (checkIfDataHasKey('detail')) {
            return data.detail as string
          } else if (checkIfDataHasKey('message')) {
            return data.message as string
          } else if (checkIfDataHasKey('non_field_errors')) {
            return (data.non_field_errors as string[]).join('.')
          } else if (Array.isArray(data)) {
            return data.join(', ')
          } else {
            return `Something went wrong. Check your Internet connection and try again. (${JSON.stringify(
              data,
            )})`
          }
        },
      )

      store.dispatch(
        showNotification({
          type: 'error',
          description,
        }),
      )

      if (status === '404') {
        browserHistory.push('/404')
      }
    }

    return next(action)
  }
