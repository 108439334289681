import { ClickAwayListener, Popper, PopperProps } from '@material-ui/core'
import { ReactNode } from 'react'
import styled from 'styled-components'

import { useEventCallback } from 'shared/hooks/useEventCallback'

type ContextMenuProps = Omit<PopperProps, 'children'> & {
  children?: ReactNode
  onClose?: () => void
}

export const ContextMenu = ({
  children,
  anchorEl,
  onClose,
  ...props
}: ContextMenuProps): JSX.Element => {
  const handleClickAway = useEventCallback(() => {
    onClose?.()
  })
  return (
    <StyledPopper
      {...props}
      anchorEl={anchorEl}
      popperOptions={{
        modifiers: {
          computeStyle: {
            gpuAcceleration: false,
          },
        },
      }}
    >
      <ClickAwayListener onClickAway={handleClickAway}>
        <ContextMenuContent>{children}</ContextMenuContent>
      </ClickAwayListener>
    </StyledPopper>
  )
}

const StyledPopper = styled(Popper)`
  z-index: 2;
`

const ContextMenuContent = styled.div`
  background-color: ${props => props.theme.colors.white};
  box-shadow: 2px 2px 10px 2px rgba(0, 0, 0, 0.1);
  border-radius: ${props => props.theme.radius[2]}px;
  overflow: hidden;
`
