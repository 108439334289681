import { MuiThemeProvider } from '@material-ui/core'
import CssBaseline from '@material-ui/core/CssBaseline/CssBaseline'
import { ErrorBoundary } from '@sentry/react'
import { ConfigProvider } from 'antd'
import { SnackbarProvider } from 'notistack'
import { Provider } from 'react-redux'
import { Router } from 'react-router-dom'
import { ThemeProvider as StyledComponentsThemeProvider } from 'styled-components'

import { Notification } from 'components/Notification'

import { WebSocketCommunication } from 'shared/api/WebSocketCommunication'
import { browserHistory } from 'shared/browserHistory'
import { DialogContextProvider } from 'shared/contexts/DialogContextProvider'
import { ModalContextProvider } from 'shared/contexts/ModalContextProvider'
import { store } from 'shared/store'

import Routes from './Routes'
import { theme } from './Theme'
import './assets/styles/App.css'
import './yup.config'

export const App = (): JSX.Element => {
  return (
    <ErrorBoundary>
      <Router history={browserHistory}>
        <Provider store={store}>
          <MuiThemeProvider theme={theme}>
            <ConfigProvider
              theme={{ token: { colorPrimary: theme.colors.primaryDark[100] } }}
            >
              <StyledComponentsThemeProvider theme={theme}>
                <SnackbarProvider>
                  <DialogContextProvider>
                    <ModalContextProvider>
                      <WebSocketCommunication />
                      <CssBaseline />
                      <Notification />
                      <Routes />
                    </ModalContextProvider>
                  </DialogContextProvider>
                </SnackbarProvider>
              </StyledComponentsThemeProvider>
            </ConfigProvider>
          </MuiThemeProvider>
        </Provider>
      </Router>
    </ErrorBoundary>
  )
}
