import { flip, offset, shift, size, useFloating } from '@floating-ui/react'

export const DEFAULT_USE_FLOATING_PROPS = {
  middleware: [
    offset(30),
    shift(),
    flip(),
    size({
      apply({ availableWidth, availableHeight, elements }) {
        Object.assign(elements.floating.style, {
          maxWidth: `${availableWidth}px`,
          maxHeight: `${availableHeight}px`,
        })
      },
    }),
  ],
  placement: 'top' as const,
  strategy: 'fixed' as const,
}

export const setFloatingTooltipReferencePoint = (
  refs: ReturnType<typeof useFloating>['refs'],
  point: [number, number],
  isRelativeToContainer?: boolean,
): void => {
  if (!refs.domReference.current) {
    throw new Error('DOM Reference element is not set')
  }
  const containerBoundingRect =
    refs.domReference.current.getBoundingClientRect()
  const x = (isRelativeToContainer ? containerBoundingRect.x : 0) + point[0]
  const y = (isRelativeToContainer ? containerBoundingRect.y : 0) + point[1]
  refs.setPositionReference({
    getBoundingClientRect: () => ({
      x,
      y,
      top: y,
      left: x,
      bottom: y,
      right: x,
      width: 0,
      height: 0,
    }),
  })
}
