import { HighlightedCluster } from 'pages/analysis/store/analysis.history.slice'
import { Cluster } from 'pages/analysis/store/selectors'

import { computeClusterColor } from 'shared/utils/clusters.utils'

type CreateAnalysisNetworkGraphBaseOptionsProps = {
  activeClusters: Cluster[]
  globallyHiddenClusters: Set<string>
  highlightedCluster: HighlightedCluster | undefined
}

export const createAnalysisNetworkGraphBaseOptions = ({
  activeClusters,
  globallyHiddenClusters,
  highlightedCluster,
}: // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
CreateAnalysisNetworkGraphBaseOptionsProps) => {
  const getClusterColor = (cluster: Cluster) => {
    return computeClusterColor(
      cluster,
      globallyHiddenClusters,
      highlightedCluster,
    )
  }

  return {
    title: {
      text: undefined,
    },
    chart: {
      type: 'networkgraph' as const,
    },
    tooltip: {
      enabled: false,
    },
    plotOptions: {
      networkgraph: {
        keys: ['from', 'to'],
        layoutAlgorithm: {
          enableSimulation: true,
          integration: 'verlet' as const,
          approximation: 'barnes-hut' as const,
          initialPositionRadius: 200,
        },
      },
    },
    series: [
      {
        type: 'networkgraph' as const,
        nodes: activeClusters.map(cluster => {
          return {
            id: cluster.id,
            name: cluster.label,
            color: getClusterColor(cluster),
            marker: {
              radius: Math.pow(cluster.stats.count, 0.25),
              fillColor: getClusterColor(cluster),
            },
          }
        }) as Highcharts.SeriesNetworkgraphNodesOptions[],
        data: activeClusters
          .filter(cluster => cluster.parent !== '')
          .map(cluster => {
            return [cluster.parent, cluster.id]
          }),
        borderColor: 'white',
        borderWidth: 0.5,
        dataLabels: {
          enabled: true,
          linkTextPath: {
            enabled: false,
          },
          linkFormat: '',
        },
      },
    ],
  }
}
