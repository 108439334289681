import { AnalysisWorkerStore } from './AnalysisWorkerStore'
import { HeatMapSeries } from './HeatMapSeries'
import { HistogramSeries } from './HistogramSeries'
import { MetaCleanSeries } from './MetaCleanSeries'
import { ScatterPlotSeries } from './ScatterPlotSeries'
import { SeriesEvents } from './SeriesEvents'
import { SunburstSeries } from './SunburstSeries'

const store = new AnalysisWorkerStore()

const seriesEvents = new SeriesEvents(store)
const sunburstSeries = new SunburstSeries()
const heatMapSeries = new HeatMapSeries(store)
const scatterPlotSeries = new ScatterPlotSeries(seriesEvents)
const metaCleanSeries = new MetaCleanSeries(store)
const histogramSeries = new HistogramSeries(seriesEvents)

export const loadClusteringFiles = store.loadClusteringFiles.bind(store)

export const computeLogarithmicScaleWorkaround =
  seriesEvents.computeLogarithmicScaleWorkaround.bind(seriesEvents)

export const computeSunburstSeries =
  sunburstSeries.computeSeries.bind(sunburstSeries)

export const computeHeatMapSeries =
  heatMapSeries.computeSeries.bind(heatMapSeries)

export const computeScatterPlotSeriesIdsByPixelPosition =
  scatterPlotSeries.computeScatterPlotSeriesIdsByPixelPosition.bind(
    scatterPlotSeries,
  )
export const computeScatterPlotKDTree =
  scatterPlotSeries.computeKDTree.bind(scatterPlotSeries)
export const computeScatterPlotGatePercentages =
  scatterPlotSeries.computeGatePercentages.bind(scatterPlotSeries)

export const computeMetaCleanSeries =
  metaCleanSeries.computeSeries.bind(metaCleanSeries)

export const computeHighPerformanceHistogramSeries =
  histogramSeries.computeHighPerformanceSeries.bind(histogramSeries)
