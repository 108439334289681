import {
  FetchBaseQueryError,
  FetchBaseQueryMeta,
} from '@reduxjs/toolkit/dist/query'
import { QueryReturnValue } from '@reduxjs/toolkit/dist/query/baseQueryTypes'
import { MaybePromise } from '@reduxjs/toolkit/dist/query/tsHelpers'

import { Layout } from 'components/chart-grid'

import {
  getMetaAnalysisStoreName,
  selectMetaAnalysis,
} from 'pages/meta-analysis/store/selectors'

import {
  ADD_CHART_PLACEHOLDER_ID,
  LOCAL_FORAGE_META_ANALYSIS_GLOBAL_VIZ_KEY,
  LOCAL_FORAGE_META_ANALYSIS_KEY,
  LOCAL_FORAGE_META_ANALYSIS_VOLCANO_PLOT_KEY,
} from 'shared/constants'
import {
  Analysis,
  MessagesForUser,
  WorkflowMode,
} from 'shared/models/AnalysisModels'
import { PaginatedResults } from 'shared/models/Result'
import { convertWorkflowModeToWorkflowEntity } from 'shared/utils/api'
import { loadClusteringFile } from 'shared/utils/storage'

import { Experiment } from './experiments.api'
import { CompensatedFile, FcsFile, filesApi } from './files.api'
import { Pipeline } from './pipelines.api'
import { privateApi } from './private.api'
import { Project } from './projects.api'
import { encodeTagParameters } from './utils'
import { Workflow } from './workflows.api'

export const metaAnalysisApi = privateApi.injectEndpoints({
  endpoints: builder => ({
    getMetaAnalyses: builder.query<
      PaginatedResults<MetaAnalysis[]>,
      GetMetaAnalysesPayload
    >({
      query: ({ page, ordering = '-updated_at', search }) => ({
        url: `meta-analysis/`,
        params: { page, ordering, ...search },
      }),
      providesTags: [
        { type: 'MetaAnalysis', id: encodeTagParameters({ id: 'list' }) },
      ],
    }),
    getMetaAnalysis: builder.query<MetaAnalysisResult, string>({
      providesTags: (_result, _error, id) => [
        { type: 'MetaAnalysis', id: encodeTagParameters({ id }) },
      ],
      queryFn: async (metaAnalysisId, api, _extraOptions, baseQuery) => {
        const storeName = getMetaAnalysisStoreName(metaAnalysisId)

        const metaAnalysisResult = await baseQuery(
          `/meta-analysis/${metaAnalysisId}/`,
        )
        if (metaAnalysisResult.error) {
          return metaAnalysisResult
        }
        const metaAnalysis = metaAnalysisResult.data as MetaAnalysis

        let compensatedFileById: Record<string, CompensatedFile>
        try {
          compensatedFileById = await api
            .dispatch(
              filesApi.endpoints.getCompensatedFileById.initiate(
                metaAnalysis.analyses.map(
                  analysis => analysis.compensated_file,
                ),
              ),
            )
            .unwrap()
        } catch (error) {
          return { error: error as FetchBaseQueryError }
        }

        let fcsFileById: Record<string, FcsFile>
        try {
          fcsFileById = await api
            .dispatch(
              filesApi.endpoints.getFcsFileById.initiate(
                Object.values(compensatedFileById).map(
                  compensatedFile => compensatedFile.fcs_file,
                ),
              ),
            )
            .unwrap()
        } catch (error) {
          return { error: error as FetchBaseQueryError }
        }

        const workflowResult = await baseQuery(
          `/${convertWorkflowModeToWorkflowEntity(
            metaAnalysis.workflow_mode,
          )}s/${metaAnalysis.workflow}/`,
        )
        if (workflowResult.error) {
          return workflowResult
        }
        const workflow = workflowResult.data as Workflow | Pipeline | Experiment

        const projectResult = await baseQuery(`/projects/${workflow.project}/`)
        if (projectResult.error) {
          return projectResult
        }
        const project = projectResult.data as Project

        const metaAnalysisFileUrlsResult = await baseQuery(
          `/meta-analysis/${metaAnalysisId}/file_contents_urls/`,
        )
        if (metaAnalysisFileUrlsResult.error) {
          return metaAnalysisFileUrlsResult
        }
        const urls = metaAnalysisFileUrlsResult.data as MetaAnalysisFileUrls

        const metaAnalysisFileResult = await loadClusteringFile({
          storeName,
          key: LOCAL_FORAGE_META_ANALYSIS_KEY,
          url: urls['meta-analysis-urls'].meta_analysis_path,
          baseQuery,
        })
        if (metaAnalysisFileResult.error) {
          return metaAnalysisFileResult
        }
        const metaAnalysisFile = JSON.parse(
          metaAnalysisFileResult.data,
        ) as MetaAnalysisFile

        let metaAnalysisGlobalVizFile: MetaAnalysisGlobalVizFile | undefined
        const globalVizFilePath = urls['meta-analysis-urls'].global_viz_path
        if (globalVizFilePath) {
          const metaAnalysisGlobalVizFileResult = await loadClusteringFile({
            storeName,
            key: LOCAL_FORAGE_META_ANALYSIS_GLOBAL_VIZ_KEY,
            url: globalVizFilePath,
            baseQuery,
          })
          if (metaAnalysisGlobalVizFileResult.error) {
            return metaAnalysisGlobalVizFileResult
          }
          metaAnalysisGlobalVizFile = JSON.parse(
            metaAnalysisGlobalVizFileResult.data,
          ) as MetaAnalysisGlobalVizFile
        }

        const metaAnalysisVolcanoPlotFileResult = await loadClusteringFile({
          storeName,
          key: LOCAL_FORAGE_META_ANALYSIS_VOLCANO_PLOT_KEY,
          url: urls['meta-analysis-urls'].volcano_plot_path,
          baseQuery,
        })
        if (metaAnalysisVolcanoPlotFileResult.error) {
          return metaAnalysisVolcanoPlotFileResult
        }
        const metaAnalysisVolcanoPlotFile = JSON.parse(
          metaAnalysisVolcanoPlotFileResult.data,
        ) as MetaAnalysisVolcanoPlotFile

        return {
          data: {
            metaAnalysis: {
              ...metaAnalysis,
              layout: metaAnalysis.layout?.single
                ? metaAnalysis.layout
                : {
                    single: {
                      [ADD_CHART_PLACEHOLDER_ID]: {
                        x: 0,
                        y: 0,
                        w: 1,
                        h: 1,
                        noMove: true,
                        noResize: true,
                      },
                    },
                    primary: {},
                    secondary: {},
                  },
            },
            metaAnalysisFile,
            metaAnalysisGlobalVizFile,
            metaAnalysisVolcanoPlotFile,
            compensatedFileById,
            fcsFileById,
            workflow,
            project,
          },
        }
      },
    }),
    createMetaAnalysis: builder.mutation<Project, CreateMetaAnalysisPayload>({
      query: payload => ({
        url: `/meta-analysis/`,
        method: 'POST',
        body: payload,
      }),
    }),
    saveMetaAnalysis: builder.mutation<
      MetaAnalysis,
      Partial<MetaAnalysis> | void
    >({
      queryFn: (payload, { getState }, _extraOptions, baseQuery) => {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const state = getState() as any
        const metaAnalysis = selectMetaAnalysis(state)

        return baseQuery({
          url: `/meta-analysis/${metaAnalysis.id}/`,
          method: 'PATCH',
          body: {
            ...metaAnalysis,
            ...payload,
            origin_state_date: metaAnalysis.updated_at, // this is to prevent multiple users from overwriting each other's changes
          },
        }) as MaybePromise<
          QueryReturnValue<
            MetaAnalysis,
            FetchBaseQueryError,
            FetchBaseQueryMeta
          >
        >
      },
    }),
    getInconsistentChannelNames: builder.mutation<
      InconsistentChannelNamesResult,
      string[]
    >({
      query: payload => ({
        url: '/meta-analysis/inconsistent_channel_names/',
        method: 'POST',
        body: { analyses: payload },
      }),
    }),
    retriggerMetaAnalysis: builder.mutation<void, string>({
      query: id => ({
        url: `/meta-analysis/${id}/recreate/`,
        method: 'POST',
      }),
    }),
  }),
})

export const {
  useGetMetaAnalysesQuery,
  useGetMetaAnalysisQuery,
  useCreateMetaAnalysisMutation,
  useSaveMetaAnalysisMutation,
  useGetInconsistentChannelNamesMutation,
  useRetriggerMetaAnalysisMutation,
} = metaAnalysisApi

type CreateMetaAnalysisPayload = {
  name: string
  description: string
  workflow: string
  analyses: string[]
  groups: Record<string, string>
  apply_normalization: boolean
  search_groups: boolean
  compute_global_viz: 'tsne' | 'umap' | null
  channel_names: Record<string, string>
}

export type MetaAnalysisResult = {
  metaAnalysis: MetaAnalysis
  metaAnalysisFile: MetaAnalysisFile
  metaAnalysisGlobalVizFile: MetaAnalysisGlobalVizFile | undefined
  metaAnalysisVolcanoPlotFile: MetaAnalysisVolcanoPlotFile
  compensatedFileById: Record<string, CompensatedFile>
  fcsFileById: Record<string, FcsFile>
  workflow: Workflow | Pipeline | Experiment
  project: Project
}

export type MetaAnalysisStatus = 'New' | 'Processing' | 'Done' | 'Error'

type HexColor = string

export type MetaAnalysisColors = {
  files: Record<string, HexColor>
  clusters: Record<string, HexColor>
}

export type MetaAnalysis = {
  id: string
  created_at: string
  updated_at: string
  status: MetaAnalysisStatus
  name: string
  description: string
  author_name: string
  groups: Record<string, string>
  graphs: MetaAnalysisChart[]
  layout: Layout
  colors: MetaAnalysisColors
  apply_normalization: boolean
  search_groups: boolean
  compute_global_viz: string | null
  population_list: unknown
  algo_version: string
  analyses: Analysis[]
  channel_names: Record<string, string>
  workflow: string
  workflow_mode: WorkflowMode
  messages_for_user: MessagesForUser
  is_stale: boolean
}

export type MetaAnalysisChart =
  | MetaAnalysisGlobalHeatMapChartType
  | MetaAnalysisDimensionalityReductionChartType
  | MetaAnalysisBoxPlotType
  | MetaAnalysisFrequencyChartType
  | MetaAnalysisSpiderwebPlotType
  | MetaAnalysisVolcanoPlotType
  | MetaAnalysisScatterPlotType
  | MetaAnalysisHistogramType

export type MetaAnalysisGlobalHeatMapChartType = {
  type: 'global-heat-map'
  id: string
  name: string
  selectedFileIds: string[]
  selectedClusters: string[]
}

export type MetaAnalysisDimensionalityReductionChartType = {
  type: 'dimensionality-reduction'
  id: string
  name: string
  selectedFileId: string
} & (
  | {
      mode: 'cluster/intensity'
      selectedClusters: string[]
      colorBy: 'cluster' | 'event'
      selectedChannel: string | undefined
    }
  | { mode: 'reference-file'; selectedReferenceFileId: string }
)

export type MetaAnalysisBoxPlotType = {
  type: 'box-plot'
  id: string
  name: string
  selectedFileIds: string[]
  selectedCluster: string
  selectedChannel: string
}

export type MetaAnalysisFrequencyChartType = {
  type: 'frequency-chart'
  id: string
  name: string
  selectedFileIds: string[]
  selectedCluster: string
  selectedChannel: string
  selectedIntensityMode: 'arithmetic-mean' | 'geometric-mean' | 'median'
}

export type MetaAnalysisSpiderwebPlotType = {
  type: 'spiderweb-plot'
  id: string
  name: string
  selectedFileIds: string[]
  selectedCluster: string
  selectedChannels: string[]
}

export type MetaAnalysisVolcanoPlotType = {
  type: 'volcano-plot'
  id: string
  name: string
  selectedGroups: [string, string]
  xThreshold: number
  yThreshold: number
}

export type MetaAnalysisScatterPlotType = {
  type: 'scatter-plot'
  id: string
  name: string
  fileIds: string[]
  clusters: string[]
  xAxis: string
  yAxis: string
}

export type MetaAnalysisHistogramType = {
  type: 'histogram'
  id: string
  name: string
  fileIds: string[]
  clusters: string[]
  channel: string
}

export type MetaAnalysisFile = {
  stat_files: {
    [fileId: string]: {
      [clusterName: string]: {
        analysis_id: string
        sel: number[]
        sel_down: number[]
        global_umap_sel: number[]
        channels: string[]
        colors: [string, string, string] | [string, string, string, string]
        heatmap: number[]
        median: number[]
        mean: number[]
        min: number[]
        max: number[]
        q1: number[]
        q3: number[]
        count: number
        proportion: number
        outliers?: number[][]
      }
    }
  }
  channels: string[]
}

export type MetaAnalysisGlobalVizFile = {
  ['Umap-x-global']: number[]
  ['Umap-y-global']: number[]
  file_id: string[]
} & {
  [channel: string]: number[]
}

export interface MetaAnalysisVolcanoPlotFile {
  index: string[]
  [key: string]: number[] | string[]
}

type MetaAnalysisFileUrls = {
  'meta-analysis-urls': {
    global_viz_path?: string
    meta_analysis_path: string
    volcano_plot_path: string
  }
}

type InconsistentChannelNamesResult = {
  channels: Record<string, string[]>
}

type GetMetaAnalysesPayload = {
  page: number
  ordering?: string
  search: Record<string, string | number | string[] | undefined>
}
