import { createSelector } from '@reduxjs/toolkit'
import { mapValues, maxBy, uniq } from 'lodash'

import { Layout, LayoutElement } from 'components/chart-grid'

import {
  ADD_CHART_PLACEHOLDER_ID,
  ANALYSIS_LASSOS_HIERARCHY_GRAPH_ID,
  CUMULATIVE_FLUORESCENCE_CHANNEL,
} from 'shared/constants'
import { Graph } from 'shared/models/Graphs'
import { RootState } from 'shared/store'
import { groupByUnique } from 'shared/utils/collection.utils'
import { getIsMultiTabMode } from 'shared/utils/multi-tab'

import {
  selectAnalysis,
  selectAnalysisGraphs,
  selectAnalysisLassos,
  selectLayout,
} from './analysis.selectors'

export const selectCharts = createSelector(selectAnalysisGraphs, rawCharts => {
  return rawCharts.map(graph => {
    const zoom = graph.zoom
    const gates = graph.gates.map(gate => {
      if (gate.type) {
        return gate
      }
      return {
        ...gate,
        type: 'rectangle',
      }
    })

    return {
      ...graph,
      zoom,
      gates,
    } as Graph
  })
})

export const selectChartIds = createSelector(selectCharts, charts =>
  charts.map(chart => chart.id),
)

export const selectSortedChartIds = createSelector(
  selectCharts,
  selectLayout,
  (charts, layout) => {
    const chartIds = charts.map(chart => chart.id)
    chartIds.push(ANALYSIS_LASSOS_HIERARCHY_GRAPH_ID)

    if (!getIsMultiTabMode()) {
      return getSortedIdsFromLayout(layout.single, chartIds)
    }

    return [
      ...getSortedIdsFromLayout(layout.primary, chartIds),
      ...getSortedIdsFromLayout(layout.secondary, chartIds),
    ]
  },
)

const getSortedIdsFromLayout = (
  layout: LayoutElement,
  chartIds: string[],
): string[] => {
  const ids = Object.keys(layout).filter(id => chartIds.includes(id))
  return ids.sort((chartIdA, chartIdB) => {
    const layoutItemA = layout[chartIdA]
    const layoutItemB = layout[chartIdB]

    if (layoutItemA && layoutItemB) {
      if (layoutItemA.y === layoutItemB.y) {
        return layoutItemA.x - layoutItemB.x
      }
      return layoutItemA.y - layoutItemB.y
    }

    return 0
  })
}

export const selectChartNamesSet = createSelector(selectCharts, charts => {
  return new Set(charts.map(chart => chart.name))
})

export const selectChartById = createSelector(selectCharts, charts => {
  return groupByUnique(charts, chart => chart.id)
})

export const selectRootCharts = createSelector(selectCharts, charts => {
  return charts.filter(chart => !chart.parent_lasso_id)
})

const LETTERS = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ'.split('')

export const selectAvailableGateLetters = createSelector(
  selectCharts,
  charts => {
    const takenLetters = new Set(
      charts.flatMap(chart =>
        chart.gates
          .filter(gate => gate.name.length === 1)
          .map(gate => gate.name),
      ),
    )

    return LETTERS.filter(letter => !takenLetters.has(letter))
  },
)

export const selectGloballyHiddenClusters = createSelector(
  selectAnalysis,
  analysis => new Set(analysis.hidden_cluster_ids),
)

export const selectClustersHiddenOnAnyGraph = createSelector(
  selectCharts,
  charts => {
    if (charts.length === 0) {
      return new Set<string>()
    }
    return new Set(
      charts.reduce((sum: string[], cur) => {
        return [...sum, ...cur.hidden_cluster_ids]
      }, []),
    )
  },
)

export const selectClusterListLinkedChartId = (
  state: RootState,
): string | undefined => {
  return state.analysisPage.selectedGraphicalElements
    .clusterListConnectedChartId
}

export const selectNextLayoutPositions = createSelector(
  selectLayout,
  (layout: Layout) => {
    const layoutPositions = {
      single: { x: 0, y: 0 },
      primary: { x: 0, y: 0 },
      secondary: { x: 0, y: 0 },
    }
    for (const layoutKey in layoutPositions) {
      if (layout[layoutKey][ADD_CHART_PLACEHOLDER_ID]) {
        layoutPositions[layoutKey] = {
          x: layout[layoutKey][ADD_CHART_PLACEHOLDER_ID].x,
          y: layout[layoutKey][ADD_CHART_PLACEHOLDER_ID].y,
        }
      }
    }
    return layoutPositions
  },
)

export const selectChildChartsById = createSelector(selectCharts, charts => {
  const chartByLassoId = Object.fromEntries(
    charts.flatMap(chart => {
      return Object.keys(chart.lasso_ids).map(lassoId => {
        return [lassoId, chart]
      })
    }),
  )

  const childChartsByChartId: Record<string, Graph[]> = {}
  for (const chart of charts) {
    childChartsByChartId[chart.id] = []
  }

  for (const chart of charts) {
    if (chart.parent_lasso_id) {
      const parentChartId = chartByLassoId[chart.parent_lasso_id].id
      childChartsByChartId[parentChartId].push(chart)
    }
  }

  return childChartsByChartId
})

type ChartsHierarchyForest = ChartsHierarchyTree[]

type ChartsHierarchyTree = {
  id: string
  children: ChartsHierarchyTree[]
}

const selectChartsHierarchyForest = createSelector(
  selectCharts,
  selectChildChartsById,
  (charts, childChartIdsByChartId): ChartsHierarchyForest => {
    const childCharts = uniq(Object.values(childChartIdsByChartId).flat())
    const chartHierarchyForestRootIds = charts
      .map(chart => chart.id)
      .filter(
        chartId => !childCharts.some(childChart => childChart.id === chartId),
      )

    const createChartsHierarchyTree = (id: string): ChartsHierarchyTree => {
      return {
        id,
        children: (childChartIdsByChartId[id] || []).map(child =>
          createChartsHierarchyTree(child.id),
        ),
      }
    }

    return chartHierarchyForestRootIds.map(rootId =>
      createChartsHierarchyTree(rootId),
    )
  },
)

export const selectDescendentChartsById = createSelector(
  selectChartsHierarchyForest,
  selectChartById,
  (chartsHierarchyForest, chartById) => {
    const descendentChartsById: Record<string, string[]> = {}

    const findDescendentChartIds = (node: ChartsHierarchyTree) => {
      if (!descendentChartsById[node.id]) {
        descendentChartsById[node.id] = []
      }
      for (const childNode of node.children) {
        descendentChartsById[node.id].push(childNode.id)
        findDescendentChartIds(childNode)
        descendentChartsById[node.id].push(
          ...descendentChartsById[childNode.id],
        )
      }
    }

    for (const rootNode of chartsHierarchyForest) {
      findDescendentChartIds(rootNode)
    }
    return mapValues(descendentChartsById, chartIds =>
      chartIds.map(id => chartById[id]),
    )
  },
)

export const selectParentLassoIdByLassoId = createSelector(
  selectChartsHierarchyForest,
  selectAnalysisLassos,
  selectChartById,
  (chartsHierarchyForest, lassos, chartById) => {
    const parentLassoIdByLassoId: Record<string, string | undefined> = {}

    const traverseChildChartNode = (chartNode: ChartsHierarchyTree) => {
      const chart = chartById[chartNode.id]
      if (!chart.parent_lasso_id) {
        throw new Error(`Chart ${chart.id} has no parent lasso id`)
      }
      const parentLassoId = chart.parent_lasso_id
      for (const lassoId of Object.keys(chart.lasso_ids)) {
        parentLassoIdByLassoId[lassoId] = parentLassoId
      }

      for (const childChartNode of chartNode.children) {
        traverseChildChartNode(childChartNode)
      }
    }

    for (const chartsHierarchyTree of chartsHierarchyForest) {
      for (const childChartNode of chartsHierarchyTree.children) {
        traverseChildChartNode(childChartNode)
      }
    }

    for (const lassoId of Object.keys(lassos)) {
      if (!(lassoId in parentLassoIdByLassoId)) {
        parentLassoIdByLassoId[lassoId] = undefined
      }
    }

    return parentLassoIdByLassoId
  },
)

export const selectNewestScatterPlotId = createSelector(
  selectCharts,
  charts => {
    return maxBy(
      charts
        .filter(
          chart =>
            chart.chart_type === 'Dot plot' &&
            chart.y_axis !== CUMULATIVE_FLUORESCENCE_CHANNEL,
        )
        .map(chart => [chart.id, chart.created_at] as const),
      ([, date]) => date,
    )?.[0]
  },
)

export const selectChartDisplaySettingsByChartId = createSelector(
  selectChartById,
  charts => mapValues(charts, chart => chart.display_settings || {}),
)
