import { HTMLAttributes } from 'react'
import styled, { css } from 'styled-components'

type TextButtonProps = HTMLAttributes<HTMLAnchorElement> & {
  disabled?: boolean
}

export const TextButton = styled(
  ({ disabled, onClick, ...props }: TextButtonProps) => {
    return <a onClick={disabled ? undefined : onClick} {...props} />
  },
)`
  cursor: pointer;
  font-family: ${({ theme }) => theme.font.style.bold};

  ${({ disabled, theme }) => {
    if (disabled) {
      return css`
        color: ${theme.colors.greyscale[50]};
        cursor: not-allowed;
      `
    }
    return css`
      :hover {
        color: ${({ theme }) => theme.colors.primaryDark[100]};
        text-decoration: underline;
      }
    `
  }}
`
