import { skipToken } from '@reduxjs/toolkit/query'
import { Formik, FormikErrors } from 'formik'
import { useMemo, useState } from 'react'
import styled from 'styled-components'
import { array, boolean, object, string } from 'yup'

import { CircularProgress } from 'components/CircularProgress'
import { Button } from 'components/button/Button'
import { Modal } from 'components/modal/Modal'
import { ModalContainer } from 'components/modal/ModalContainer'

import { ExperimentDetails } from 'shared/api/experiments.api'
import {
  CompensatedFile,
  useGetCompensatedFileByIdQuery,
} from 'shared/api/files.api'
import {
  useCreateMetaAnalysisMutation,
  useGetInconsistentChannelNamesMutation,
} from 'shared/api/meta-analysis.api'
import { PipelineDetails } from 'shared/api/pipelines.api'
import {
  BrickAnalysis,
  WorkflowDetails,
  useGetBrickByIdQuery,
} from 'shared/api/workflows.api'
import { handleError } from 'shared/utils/errorHandler'

import { MetaAnalysisWizardSelectCreateStep } from './MetaAnalysisWizardCreateStep'
import { MetaAnalysisWizardSelectAnalysesStep } from './MetaAnalysisWizardSelectAnalysesStep'
import { MetaAnalysisWizardSelectChannelLabelsStep } from './MetaAnalysisWizardSelectChannelLabelsStep'
import { MetaAnalysisWizardSelectGroupsStep } from './MetaAnalysisWizardSelectGroupsStep'
import { WizardStepper } from './WizardStepper'

type MetaAnalysisWizardProps = {
  workflow: WorkflowDetails | PipelineDetails | ExperimentDetails
  brickIds: string[]
  onClose: () => void
}

const MetaAnalysisWizardFormSchema = object({
  selectedAnalysisIds: array()
    .required()
    .min(1, 'At least one analysis must be selected'),
  name: string().required('Name is required'),
  description: string(),
  alignment: boolean(),
  concatenation: boolean(),
})

export type MetaAnalysisWizardFormValues = {
  selectedAnalysisIds: string[]
  groups: string[]
  fileGroupMapping: Record<string, string>
  name: string
  description: string
  uMapMethod: 'tsne' | 'umap' | 'none'
  alignClusters: boolean
  searchGroups: boolean
  channelNames: Record<string, string>
}

export const MetaAnalysisWizard = ({
  workflow,
  brickIds,
  onClose,
}: MetaAnalysisWizardProps): JSX.Element => {
  const brickByIdQuery = useGetBrickByIdQuery(brickIds)
  const analyses = brickByIdQuery.data
    ? Object.values(brickByIdQuery.data).flatMap(brick => brick.analyses)
    : undefined
  const compensatedFileById = useGetCompensatedFileByIdQuery(
    analyses ? analyses.map(analysis => analysis.compensated_file) : skipToken,
  )
  const [triggerCreateMetaAnalysis, createMetaAnalysisState] =
    useCreateMetaAnalysisMutation()
  const [
    triggerGetInconsistentChannelNames,
    getInconsistentChannelNamesMutationState,
  ] = useGetInconsistentChannelNamesMutation()
  const [inconsistentChannelNames, setInconsistentChannelNames] = useState<
    Record<string, string[]>
  >({})

  const { fileIdByAnalysisId, fileNameById } = useFileByAnalysisId(
    analyses,
    compensatedFileById.data,
  )

  const isMutationLoading =
    getInconsistentChannelNamesMutationState.isLoading ||
    createMetaAnalysisState.isLoading

  const [step, setStep] = useState(STEPS[0])

  const isFirstStep = step === STEPS[0]
  const isLastStep = step === STEPS.at(-1)

  const goToNextStep = () => {
    setStep(STEPS.indexOf(step) + 1)
  }
  const goToPreviousStep = () => {
    if (isFirstStep) {
      onClose()
    } else {
      setStep(STEPS.indexOf(step) - 1)
    }
  }

  const handleCreateAnalysis = (values: MetaAnalysisWizardFormValues) => {
    triggerCreateMetaAnalysis({
      name: values.name,
      description: values.description,
      analyses: values.selectedAnalysisIds,
      groups: values.fileGroupMapping,
      workflow: workflow.id,
      apply_normalization: values.alignClusters,
      search_groups: values.searchGroups,
      compute_global_viz:
        values.uMapMethod === 'none' ? null : values.uMapMethod,
      channel_names: removeEmptyChannelLabels(values.channelNames),
    }).then(onClose)
  }

  return (
    <Modal
      open
      title="Create new meta-analysis"
      Container={StyledModalContainer}
      onClose={onClose}
    >
      <StyledWizardStepper
        currentStepIndex={step}
        steps={STEPS.map(step => STEP_LABELS[step] as string)}
      />
      <Formik<MetaAnalysisWizardFormValues>
        initialValues={INITIAL_VALUES}
        validationSchema={MetaAnalysisWizardFormSchema}
        onSubmit={handleCreateAnalysis}
      >
        {({ values, errors, submitForm, validateForm }) => {
          const getFirstApplicableError = (
            errors: FormikErrors<MetaAnalysisWizardFormValues>,
          ) => {
            if (step === STEP_SELECT_ANALYSES) {
              return errors.selectedAnalysisIds
            }
            if (step === STEP_SELECT_GROUPS) {
              return errors.groups
            }
            if (step === STEP_CREATE_META_ANALYSIS) {
              return errors.name || errors.description
            }
          }

          const handleNextStep = () => {
            validateForm().then(async errors => {
              if (getFirstApplicableError(errors)) {
                return
              }

              if (isLastStep) {
                submitForm()
              }

              if (step === STEP_SELECT_ANALYSES) {
                const inconsistentChannelNames =
                  await triggerGetInconsistentChannelNames(
                    values.selectedAnalysisIds,
                  ).unwrap()
                if (!inconsistentChannelNames) {
                  throw handleError(new Error('Could not fetch channel names'))
                }
                if (
                  Object.keys(inconsistentChannelNames.channels).length === 0
                ) {
                  setStep(STEPS[2])
                } else {
                  setInconsistentChannelNames(inconsistentChannelNames.channels)
                  goToNextStep()
                }
              } else {
                goToNextStep()
              }
            })
          }

          return (
            <>
              {brickByIdQuery.isLoading || compensatedFileById.isLoading ? (
                <CircularProgress />
              ) : (
                <Fieldset disabled={isMutationLoading}>
                  <ScrollWrapper>
                    {step === STEP_SELECT_ANALYSES && (
                      <MetaAnalysisWizardSelectAnalysesStep
                        brickById={brickByIdQuery.data!}
                        fileIdByAnalysisId={fileIdByAnalysisId}
                      />
                    )}
                    {step === STEP_SELECT_CHANNEL_LABELS && (
                      <MetaAnalysisWizardSelectChannelLabelsStep
                        channelNames={inconsistentChannelNames}
                      />
                    )}
                    {step === STEP_SELECT_GROUPS && (
                      <MetaAnalysisWizardSelectGroupsStep
                        fileNameById={fileNameById}
                      />
                    )}
                    {step === STEP_CREATE_META_ANALYSIS && (
                      <MetaAnalysisWizardSelectCreateStep />
                    )}
                  </ScrollWrapper>
                </Fieldset>
              )}
              <Actions>
                <Button colorOverride="greyscale" onClick={goToPreviousStep}>
                  {isFirstStep ? 'Close' : 'Previous'}
                </Button>
                <Button
                  disabled={!!getFirstApplicableError(errors)}
                  loading={isMutationLoading}
                  onClick={handleNextStep}
                >
                  {isLastStep ? 'Create' : 'Next'}
                </Button>
              </Actions>
            </>
          )
        }}
      </Formik>
    </Modal>
  )
}

const useFileByAnalysisId = (
  analyses: BrickAnalysis[] | undefined,
  compensatedFileById: Record<string, CompensatedFile> | undefined,
) => {
  return useMemo(() => {
    const fileIdByAnalysisId: Record<string, string> = {}
    const fileNameById: Record<string, string> = {}
    if (!analyses || !compensatedFileById) {
      return { fileIdByAnalysisId, fileNameById }
    }
    for (const analysis of analyses) {
      const compensatedFile = compensatedFileById[analysis.compensated_file]
      const fcsFileId = compensatedFile.fcs_file
      fileIdByAnalysisId[analysis.id] = fcsFileId
      fileNameById[fcsFileId] = compensatedFile.fcs_file_name
    }

    return { fileIdByAnalysisId, fileNameById }
  }, [analyses, compensatedFileById])
}

const removeEmptyChannelLabels = (
  channelLabels: Record<string, string>,
): Record<string, string> => {
  return Object.fromEntries(
    Object.entries(channelLabels).filter(([, value]) => !!value),
  )
}

const STEP_SELECT_ANALYSES = 0
const STEP_SELECT_CHANNEL_LABELS = 1
const STEP_SELECT_GROUPS = 2
const STEP_CREATE_META_ANALYSIS = 3

const STEPS = [
  STEP_SELECT_ANALYSES,
  STEP_SELECT_CHANNEL_LABELS,
  STEP_SELECT_GROUPS,
  STEP_CREATE_META_ANALYSIS,
]

const STEP_LABELS = {
  [STEP_SELECT_ANALYSES]: 'Select analyses',
  [STEP_SELECT_CHANNEL_LABELS]: 'Select channel labels',
  [STEP_SELECT_GROUPS]: 'Select groups',
  [STEP_CREATE_META_ANALYSIS]: 'Create meta-analysis',
}

const INITIAL_VALUES: MetaAnalysisWizardFormValues = {
  selectedAnalysisIds: [],
  groups: ['Group 1'],
  fileGroupMapping: {},
  name: '',
  description: '',
  uMapMethod: 'none',
  alignClusters: false,
  searchGroups: false,
  channelNames: {},
}

const StyledModalContainer = styled(ModalContainer)`
  min-width: 800px;
  max-height: 90vh;
  font-size: 13px;
  align-items: stretch;
  padding: 24px 32px;
`

const StyledWizardStepper = styled(WizardStepper)`
  margin-bottom: ${props => props.theme.spacing(4)}px;
  align-self: flex-start;
`

const Actions = styled.div`
  display: flex;
  gap: 12px;
  margin-top: ${props => props.theme.spacing(2)}px;
  align-self: flex-end;
`

const ScrollWrapper = styled.div`
  overflow-y: auto;
`

const Fieldset = styled.fieldset`
  border: none;
`
