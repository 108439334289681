import { FilterList as FilterIcon } from '@material-ui/icons'
import { formatISO } from 'date-fns'
import { Formik, FormikConfig } from 'formik'
import { mapValues } from 'lodash'
import React, { ReactNode } from 'react'
import styled from 'styled-components'

import { Button } from 'components/Button'
import Input from 'components/forms/Input'
import Select from 'components/forms/Select'

type ExportFiltersProps = {
  onFilterChange: (filters: ExportFilterFormValues) => void
  typeOptions: { value: string; label: ReactNode }[]
  statusOptions: { value: string; label: ReactNode }[]
}

export type ExportFilterFormValues = {
  export_class: string
  file_name: string
  format: string
  created_at_after: string
  created_at_before: string
  updated_at_after: string
  updated_at_before: string
  status: string
}

const defaultValues: ExportFilterFormValues = {
  export_class: '-',
  file_name: '',
  format: '',
  created_at_after: '',
  created_at_before: '',
  updated_at_after: '',
  updated_at_before: '',
  status: '-',
}

export const ExportFilters = ({
  onFilterChange,
  typeOptions,
  statusOptions,
}: ExportFiltersProps): JSX.Element => {
  const handleSubmit: FormikConfig<ExportFilterFormValues>['onSubmit'] =
    values => onFilterChange(convertDatesToUTCHours(values))

  const maxDate = formatISO(new Date()).replace(/\+.*/, '')

  return (
    <FiltersContainer>
      <Formik<ExportFilterFormValues>
        initialValues={defaultValues}
        onSubmit={handleSubmit}
      >
        {({ values, handleChange, handleBlur, handleSubmit, handleReset }) => (
          <Form onSubmit={handleSubmit}>
            <StyledSelect
              id="export_class"
              name="export_class"
              label="Export type"
              value={values.export_class}
              options={typeOptions}
              onChange={handleChange}
              onBlur={handleBlur}
            />
            <StyledInput
              name="file_name"
              label="File name"
              value={values.file_name}
              onChange={handleChange}
              onBlur={handleBlur}
            />
            <StyledInput
              type="datetime-local"
              name="created_at_after"
              label="Created after"
              value={values.created_at_after}
              max={values.created_at_before || maxDate}
              onChange={handleChange}
              onBlur={handleBlur}
            />
            <StyledInput
              type="datetime-local"
              name="created_at_before"
              label="Created before"
              value={values.created_at_before}
              min={values.created_at_after}
              max={maxDate}
              onChange={handleChange}
              onBlur={handleBlur}
            />
            <StyledInput
              type="datetime-local"
              name="updated_at_after"
              label="Updated after"
              value={values.updated_at_after}
              max={values.updated_at_before || maxDate}
              onChange={handleChange}
              onBlur={handleBlur}
            />
            <StyledInput
              type="datetime-local"
              name="updated_at_before"
              label="Updated before"
              value={values.updated_at_before}
              min={values.updated_at_after}
              max={maxDate}
              onChange={handleChange}
              onBlur={handleBlur}
            />
            <StyledSelect
              id="status"
              name="status"
              label="Status"
              value={values.status}
              options={statusOptions}
              onChange={handleChange}
              onBlur={handleBlur}
            />
            <StyledButton type="submit">
              <FilterIcon />
              Filter
            </StyledButton>
            <StyledButton
              grey
              onClick={() => {
                handleReset()
                onFilterChange(defaultValues)
              }}
            >
              Reset filters
            </StyledButton>
          </Form>
        )}
      </Formik>
    </FiltersContainer>
  )
}

const convertDatesToUTCHours = (values: ExportFilterFormValues) =>
  mapValues(values, (value, key) =>
    key.includes('_at_') && value ? formatISO(new Date(value)) : value,
  )

const FiltersContainer = styled.div`
  margin: auto;
`

const Form = styled.form`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  gap: ${props => props.theme.spacing(1)}px;
`

const StyledButton = styled(Button)`
  height: 40px;
  margin-bottom: 5px;
`

const StyledInput = styled(Input)`
  flex: 0 1 160px;
`

const StyledSelect = styled(Select)`
  min-width: 160px;
  width: 160px;
`
