import { IconButton } from '@material-ui/core'
import { FC } from 'react'
import styled, { css } from 'styled-components'

import { ReactComponent as ArrowLeftIcon } from 'assets/images/icons/analysis/arrowLeft.svg'

import { useAnalysisExitUrl, useSafeNavigate } from 'shared/hooks/router'
import { useEventCallback } from 'shared/hooks/useEventCallback'
import { useAppSelector } from 'shared/store'

import { AnalysisHeaderDetails } from './AnalysisHeaderDetails'
import { BrickNavigation } from './BrickNavigation'
import {
  selectAnalysis,
  selectAnalysisCompensatedFile,
  selectAnalysisFcsFile,
  selectIsAnalysisSaved,
} from './store/selectors'

type AnalysisHeaderProps = {
  isSecondaryTab: boolean
}

export const AnalysisHeader: FC<AnalysisHeaderProps> = ({ isSecondaryTab }) => {
  const analysis = useAppSelector(selectAnalysis)
  const compensatedFile = useAppSelector(selectAnalysisCompensatedFile)
  const fcsFile = useAppSelector(selectAnalysisFcsFile)
  const isAnalysisSaved = useAppSelector(selectIsAnalysisSaved)

  const safeNavigate = useSafeNavigate()
  const analysisExitUrl = useAnalysisExitUrl()

  const handleExitAnalysis = useEventCallback(() => {
    safeNavigate(analysisExitUrl)
  })

  return (
    <AnalysisHeaderRoot $isSecondaryTab={isSecondaryTab}>
      {!isSecondaryTab && (
        <BackButtonContainer>
          <IconButton size="medium" onClick={handleExitAnalysis}>
            <ArrowLeftIcon />
          </IconButton>
        </BackButtonContainer>
      )}
      <AnalysisHeaderDetails
        analysis={analysis}
        compensatedFile={compensatedFile}
        fcsFile={fcsFile}
        isAnalysisSaved={isAnalysisSaved}
      />
      {!isSecondaryTab && <StyledBrickNavigation />}
    </AnalysisHeaderRoot>
  )
}

const AnalysisHeaderRoot = styled.div<{ $isSecondaryTab: boolean }>`
  display: flex;
  background-color: ${props => props.theme.colors.white};

  ${({ $isSecondaryTab }) =>
    $isSecondaryTab &&
    css`
      border-bottom: 1px solid ${props => props.theme.colors.primaryDark[20]};
    `}
`

const BackButtonContainer = styled.div`
  display: flex;
  align-items: center;
  padding-left: 15px;
`

const StyledBrickNavigation = styled(BrickNavigation)`
  align-self: center;
  margin-left: auto;
  margin-right: 15px;
`
