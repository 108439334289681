import { GridStack, GridStackWidget } from 'gridstack'
import 'gridstack/dist/gridstack-extra.min.css'
import 'gridstack/dist/gridstack.min.css'
import 'gridstack/dist/h5/gridstack-dd-native'

export type LayoutKey = 'single' | 'primary' | 'secondary'

export type LayoutElement = {
  [id: string]: LayoutItem
}

export type Layout = {
  [layout in LayoutKey]: LayoutElement
}

export type LayoutItem = {
  x: number
  y: number
  w: number
  h: number
  minW?: number
  minH?: number
  noMove?: boolean
  noResize?: boolean
  locked?: boolean
}

export const NUMBER_OF_COLUMNS = 3

export const serializeLayout = (
  grid: Pick<GridStack, 'save'>,
): LayoutElement => {
  const gridStackWidgets = grid.save() as GridStackWidget[]
  return Object.fromEntries(
    gridStackWidgets.map(widget => [
      widget.id,
      {
        x: widget.x,
        y: widget.y,
        w: widget.w,
        h: widget.h,
        minW: widget.minW,
        minH: widget.minH,
        noMove: widget.noMove,
        noResize: widget.noResize,
        locked: widget.locked,
      },
    ]),
  )
}
