import { Typography } from '@material-ui/core'
import styled from 'styled-components'

import { Cluster } from 'pages/analysis/store/selectors'

import { PdfCheckbox } from '../PdfCheckbox'
import { CLUSTER_LIST_COLUMNS } from './groupAnalysisPrintItems'

type AnalysisPdfClusterListProps = {
  className?: string
  clusters: Cluster[]
  selectedClusterIds: string[]
}

export const AnalysisPdfClusterList: React.FC<AnalysisPdfClusterListProps> = ({
  className,
  clusters,
  selectedClusterIds,
}) => {
  return (
    <AnalysisPdfClusterListRoot className={className}>
      <Title>Clusters</Title>
      <List>
        {clusters.map(cluster => (
          <Item key={cluster.id}>
            <DotLabel $color={cluster.color}></DotLabel>
            <PdfCheckbox checked={selectedClusterIds.includes(cluster.id)} />
            <Typography variant="body2">{cluster.label}</Typography>
          </Item>
        ))}
      </List>
    </AnalysisPdfClusterListRoot>
  )
}

const AnalysisPdfClusterListRoot = styled.div`
  border: 1px solid ${props => props.theme.colors.primaryDark[20]};
  border-radius: ${props => props.theme.radius[2]}px;
`

const List = styled.div`
  display: grid;
  grid-template-columns: repeat(${CLUSTER_LIST_COLUMNS}, 1fr);
  row-gap: 26px;
  padding: 16px 32px;
`

const Item = styled.div`
  display: flex;
  align-items: center;
  column-gap: 8px;
`

const DotLabel = styled.div<{ $color: string }>`
  background-color: ${props => props.$color};
  width: 12px;
  height: 12px;
  border-radius: 50%;
`

const Title = styled.div`
  font-size: ${props => props.theme.font.size.h3}px;
  color: ${props => props.theme.colors.primaryDark[70]};
  font-weight: bold;
  margin-left: 16px;
  margin-top: 16px;
`
