import React, { useEffect, useState } from 'react'
import styled from 'styled-components'

type ChannelNameInputProps = {
  channel: string
  placeholder: string | undefined
  value: string | undefined
  onChange: (value: string) => void
}

export const ChannelNameInput = ({
  channel,
  value,
  placeholder,
  onChange,
}: ChannelNameInputProps): JSX.Element => {
  const [internalValue, setInternalValue] = useState(value ?? '')

  useEffect(() => {
    setInternalValue(value ?? '')
  }, [value])

  return (
    <ChannelNameInputRoot>
      <Name>{channel}: </Name>
      <Input
        placeholder={placeholder}
        value={internalValue}
        onChange={e => setInternalValue(e.target.value)}
        onBlur={() => onChange(internalValue)}
        data-cy="channel-name"
      />
    </ChannelNameInputRoot>
  )
}

const ChannelNameInputRoot = styled.div`
  display: flex;
  align-items: center;
`

const Name = styled.span`
  white-space: nowrap;
  margin-right: 4px;
  user-select: none;
  font-weight: bold;
`

const Input = styled.input`
  width: 100%;
  outline: none;
  border: 1px solid transparent;
  box-sizing: border-box;
  padding: 4px;
  font-size: inherit;
  color: inherit;
  font-family: inherit;
  border-radius: ${props => props.theme.radius[1]}px;
  transition: border ease 250ms;
  &:hover {
    border: 1px solid ${props => props.theme.colors.primaryDark[20]};
  }
  &:focus {
    border: 1px solid ${props => props.theme.colors.primaryDark[50]};
  }
`
