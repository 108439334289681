import { IconButton } from '@material-ui/core'
import { useRef, useState } from 'react'
import styled from 'styled-components'

import { Icon } from 'assets/images/icons/Icon'
import { ReactComponent as RetriggerIcon } from 'assets/images/icons/retrigger-workflow.svg'

import { ActionButton } from 'components/ActionButton'
import { Button } from 'components/Button'
import { ContextMenu } from 'components/ContextMenu'
import { ContextMenuItem } from 'components/ContextMenuItem'

import {
  metaAnalysisApi,
  useRetriggerMetaAnalysisMutation,
} from 'shared/api/meta-analysis.api'
import { useDialog } from 'shared/contexts/DialogContext'
import { generateMetaAnalysisPdf } from 'shared/export/meta-analysis/generateMetaAnalysisPdf'
import { useMetaAnalysisExitUrl, useSafeNavigate } from 'shared/hooks/router'
import { useAppDispatch, useAppSelector } from 'shared/store'

import { theme } from 'Theme'

import { GroupsPreviewModal } from './GroupsPreviewModal'
import { SaveMetaAnalysisButton } from './SaveMetaAnalysisButton'
import { metaAnalysisRedo, metaAnalysisUndo } from './store/meta-analysis.slice'
import {
  selectIsMetaAnalysisSaved,
  selectIsMetaAnalysisBeingSaved,
  selectMetaAnalysisCanRedo,
  selectMetaAnalysisCanUndo,
  selectMetaAnalysisId,
  selectIsMetaAnalysisStale,
} from './store/selectors'

export const MetaAnalysisPageToolbar = (): JSX.Element => {
  const safeNavigate = useSafeNavigate()
  const metaAnalysisExitUrl = useMetaAnalysisExitUrl()

  const [
    triggerRetriggerMetaAnalysisMutation,
    retriggerMetaAnalysisMutationState,
  ] = useRetriggerMetaAnalysisMutation()
  const { ensureConditionIsMetBeforeAction } = useDialog()

  const dispatch = useAppDispatch()
  const metaAnalysisId = useAppSelector(selectMetaAnalysisId)
  const isSaved = useAppSelector(selectIsMetaAnalysisSaved)
  const canUndo = useAppSelector(selectMetaAnalysisCanUndo)
  const canRedo = useAppSelector(selectMetaAnalysisCanRedo)
  const isMetaAnalysisBeingSaved = useAppSelector(
    selectIsMetaAnalysisBeingSaved,
  )
  const isMetaAnalysisStale = useAppSelector(selectIsMetaAnalysisStale)

  const [isExportMenuOpen, setIsExportMenuOpen] = useState(false)
  const [isExporting, setIsExporting] = useState(false)
  const [isGroupsPreviewModalOpen, setGroupsPreviewModalOpen] = useState(false)

  const exportButtonRef = useRef<HTMLButtonElement>(null)

  const handleUndo = () => {
    dispatch(metaAnalysisUndo())
  }

  const handleRedo = () => {
    dispatch(metaAnalysisRedo())
  }

  const handleExportToPdf = () => {
    setIsExportMenuOpen(false)
    if (!metaAnalysisId) return

    const generate = async () => {
      setIsExporting(true)
      try {
        await generateMetaAnalysisPdf([metaAnalysisId])
      } finally {
        setIsExporting(false)
      }
    }

    ensureConditionIsMetBeforeAction({
      condition: isSaved,
      title: 'PDF Export',
      message:
        'The meta-analysis must be saved before exporting to PDF. Are you sure you want to continue?',
      performAction: generate,
      performFix: async () => {
        try {
          await dispatch(
            metaAnalysisApi.endpoints.saveMetaAnalysis.initiate(),
          ).unwrap()
          return true
        } catch {
          return false
        }
      },
    })
  }

  const handleRetriggerMetaAnalysis = () => {
    if (!metaAnalysisId) {
      return
    }
    triggerRetriggerMetaAnalysisMutation(metaAnalysisId)
      .unwrap()
      .then(() => {
        safeNavigate(metaAnalysisExitUrl)
      })
  }

  return (
    <MetaAnalysisPageHeaderToolbarRoot>
      <StyledIconButton disabled={!canUndo} onClick={handleUndo}>
        <Icon name={canUndo ? 'undo' : 'undoInactive'} />
      </StyledIconButton>
      <StyledIconButton disabled={!canRedo} onClick={handleRedo}>
        <Icon name={canRedo ? 'redo' : 'redoInactive'} />
      </StyledIconButton>
      <VerticalDivider />
      <ActionButton
        onClick={() => setIsExportMenuOpen(true)}
        startIcon={<Icon name="export" />}
        ref={exportButtonRef}
        loading={isExporting}
      >
        Export
      </ActionButton>
      <ContextMenu
        anchorEl={exportButtonRef.current}
        open={isExportMenuOpen}
        onClose={() => setIsExportMenuOpen(false)}
      >
        <ContextMenuItem label="Export to PDF" onClick={handleExportToPdf} />
      </ContextMenu>
      {isMetaAnalysisStale && (
        <>
          <StaleWarning>
            Not current version, as some files have been modified
          </StaleWarning>
          <ActionButton
            disabled={retriggerMetaAnalysisMutationState.isLoading}
            loading={retriggerMetaAnalysisMutationState.isLoading}
            startIcon={<RetriggerIcon />}
            onClick={handleRetriggerMetaAnalysis}
          >
            Retrigger
          </ActionButton>
        </>
      )}
      <HorizontalDivider />
      <GroupsButton
        disabled={isMetaAnalysisBeingSaved}
        onClick={() => setGroupsPreviewModalOpen(true)}
        startIcon={
          <Icon name="channels" stroke={theme.colors.primaryDark[70]} />
        }
      >
        Review groups
      </GroupsButton>
      <VerticalDivider />
      <SaveMetaAnalysisButton />
      {isGroupsPreviewModalOpen && (
        <GroupsPreviewModal onClose={() => setGroupsPreviewModalOpen(false)} />
      )}
    </MetaAnalysisPageHeaderToolbarRoot>
  )
}

const MetaAnalysisPageHeaderToolbarRoot = styled.div`
  height: 48px;
  padding: 8px 12px;
  border-bottom: 1px solid ${props => props.theme.colors.primaryDark[20]};
  background-color: ${props => props.theme.colors.white};
  display: flex;
  position: sticky;
  top: 60px;
  z-index: 2;
  gap: 4px;
`

const StyledIconButton = styled(IconButton).attrs({ size: 'small' })``

const VerticalDivider = styled.div`
  background-color: ${props => props.theme.colors.primaryDark[20]};
  width: 1px;
  max-height: 100%;
  margin: 0 12px;
`

const HorizontalDivider = styled.div`
  flex-grow: 1;
`

const GroupsButton = styled(Button)`
  border: none;
  background-color: transparent;
  color: ${props => props.theme.colors.primaryDark[100]};
  font-size: ${props => props.theme.font.size.smallest}px;
  font-family: ${props => props.theme.font.style.bold};
  min-width: 165px;
  height: 32px;
  padding: 0 10px;
  &:hover {
    background-color: rgba(0, 0, 0, 0.04);
    color: ${props => props.theme.colors.primaryDark[100]};
  }
`

const StaleWarning = styled.div`
  background-color: ${props => props.theme.colors.error};
  color: white;
  display: flex;
  align-items: center;
  padding: 0 8px;
  border-radius: 4px;
`
