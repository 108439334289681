import { FC } from 'react'
import styled from 'styled-components'

import { Modal } from 'components/modal/Modal'
import { ModalContainer } from 'components/modal/ModalContainer'

import { Workflow } from './Workflow'

type WorkflowModalProps = {
  workflowId: string
  onClose: () => void
}

export const WorkflowModal: FC<WorkflowModalProps> = ({
  workflowId,
  onClose,
}) => {
  return (
    <Modal
      open
      disableBackdropClick
      onClose={onClose}
      Container={StyledModalContainer}
      title={
        <Header>
          Workflow view
          <DebugId>{workflowId}</DebugId>
        </Header>
      }
    >
      <Workflow workflowId={workflowId} />
    </Modal>
  )
}

const StyledModalContainer = styled(ModalContainer)`
  width: 60vw;
  min-width: 900px;
  gap: ${props => props.theme.spacing(2)}px;
`

const Header = styled.h2`
  width: 100%;
  text-align: center;
  font-family: ${({ theme }) => theme.font.style.bold};
  margin: 0;
  font-size: 20px;
`

const DebugId = styled.div`
  font-size: 10px;
  color: ${({ theme }) => theme.colors.greyscale[30]};
  font-family: ${({ theme }) => theme.font.style.light};
`
