import { Typography } from '@material-ui/core'
import { useMemo } from 'react'
import styled from 'styled-components'

import { Icon } from 'assets/images/icons/Icon'

import { LoadingMask } from 'components/LoadingMask'
import { UserAvatar } from 'components/UserAvatar'

import { useGetOrganizationQuery } from 'shared/api/organization.api'
import { useGetUserQuery } from 'shared/api/user.api'

import { UpdatePasswordForm } from './UpdatePasswordForm'
import { UpdateProfileForm } from './UpdateProfileForm'

export const ProfilePage = (): JSX.Element => {
  const getUser = useGetUserQuery()
  const user = useMemo(() => getUser.data, [getUser.data])

  const organizationResult = useGetOrganizationQuery()
  const organization = organizationResult.data

  if (getUser.isLoading) {
    return <LoadingMask />
  }

  return (
    <ProfilePageRoot>
      <Header variant="h3" align="center">
        Profile
      </Header>
      {user && (
        <>
          <StyledUpdateProfileForm
            user={user}
            onProfileUpdate={() => getUser.refetch()}
          />
          <StyledUpdatePasswordForm user={user} />
        </>
      )}
      <UserInfoContainer>
        <UserAvatar username={user?.username ?? ''} size={200} />
        <UserInfoSection>
          <Typography variant="h4" align="center">
            <b>
              {user?.first_name} {user?.last_name}
            </b>
          </Typography>
          <Typography variant="body1" align="center">
            <b>{organization?.name ?? ''}</b>
          </Typography>
          <Typography variant="body2" align="center">
            {user?.email}
          </Typography>
        </UserInfoSection>
        <UserInfoSection>
          <Typography variant="body1" align="center">
            Expert <LargeIcon name={user?.is_expert ? 'check' : 'close'} />
          </Typography>
          {user?.is_operator ? (
            <Typography variant="body1" align="center">
              Operator <LargeIcon name="check" />
            </Typography>
          ) : null}
        </UserInfoSection>
      </UserInfoContainer>
    </ProfilePageRoot>
  )
}

const ProfilePageRoot = styled.div`
  display: grid;
  grid-template-rows: repeat(3, auto);
  grid-template-columns: 1fr 300px;
  grid-template-areas: 'header header' 'profile info' 'password info';
  grid-column-gap: 24px;
  width: 800px;
  margin: auto;
  padding: ${props => props.theme.layout.headerHeight + 24}px 0 64px;
`

const Header = styled(Typography)`
  grid-area: header;
  margin-bottom: 24px;
`

const StyledUpdateProfileForm = styled(UpdateProfileForm)`
  grid-area: profile;
`

const StyledUpdatePasswordForm = styled(UpdatePasswordForm)`
  grid-area: password;
`

const UserInfoContainer = styled.div`
  grid-area: info;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  max-width: 300px;
  word-break: break-all;
  margin-top: 16px;
`

const UserInfoSection = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  margin-top: 16px;
`

const LargeIcon = styled(Icon)`
  width: 16px;
  height: 16px;
  margin-left: 4px;
  padding-top: 4px;
`
