import { useFormikContext } from 'formik'
import { ChangeEvent, FC } from 'react'
import styled from 'styled-components'

import { ValidationLabel } from 'components/ validation-label/ValidationLabel'
import Select from 'components/forms/Select'
import { TextField } from 'components/text-field/TextField'

import { CompensatedFile } from 'shared/api/files.api'
import { PrecisionAndGranularityMode } from 'shared/api/workflows.api'
import { useEventCallback } from 'shared/hooks/useEventCallback'
import { calculateClusteringGranularity } from 'shared/utils/clustering.utils'
import { includeIf } from 'shared/utils/utils'

import { WizardSectionCard } from './WizardSectionCard'

type ClusteringPrecisionAndGranularityWizardSectionProps = {
  compensatedFiles: CompensatedFile[] | undefined
  isExpert: boolean
  clusteringType: 'primary' | 'secondary'
  parentPrecisionAndGranularityMode?: PrecisionAndGranularityMode
}

export const ClusteringPrecisionAndGranularityWizardSection: FC<
  ClusteringPrecisionAndGranularityWizardSectionProps
> = ({
  compensatedFiles,
  isExpert,
  clusteringType,
  parentPrecisionAndGranularityMode,
}) => {
  const { values, errors, handleChange, handleBlur, setFieldValue } =
    useFormikContext<{
      precisionAndGranularityMode: PrecisionAndGranularityMode
      kd: number
      kt: number
    }>()

  const handleModeChange = useEventCallback(
    (event: ChangeEvent<{ value: unknown }>) => {
      const mode = event.target.value as PrecisionAndGranularityMode
      setFieldValue('precisionAndGranularityMode', mode)

      if (mode !== 'expert' && compensatedFiles) {
        setFieldValue(
          'kt',
          calculateClusteringGranularity({
            mode,
            compensatedFiles,
            clusteringType,
          }),
        )
      }
    },
  )

  return (
    <WizardSectionCard title="Granularity and precision">
      <Select
        name="precisionAndGranularityMode"
        label="Mode"
        value={values.precisionAndGranularityMode}
        onChange={handleModeChange}
        onBlur={handleBlur}
        options={[
          { value: 'rare-cell', label: 'Rare cell' },
          ...includeIf(parentPrecisionAndGranularityMode !== 'rare-cell', [
            {
              value: 'standard',
              label: 'Standard',
            },
          ]),
          ...includeIf(isExpert, [{ value: 'expert', label: 'Expert' }]),
        ]}
      />
      {values.precisionAndGranularityMode === 'expert' && (
        <>
          <div>
            <StyledTextField
              type="number"
              name="kt"
              label="Granularity"
              value={values.kt}
              placeholder="Granularity"
              onChange={handleChange}
              onBlur={handleBlur}
              autoComplete="off"
            />
            {errors.kt && <ValidationLabel>{errors.kt}</ValidationLabel>}
          </div>
          <div>
            <StyledTextField
              type="number"
              name="kd"
              label="Precision"
              value={values.kd}
              placeholder="Precision"
              onChange={handleChange}
              onBlur={handleBlur}
              autoComplete="off"
            />
            {errors.kd && <ValidationLabel>{errors.kd}</ValidationLabel>}
          </div>
        </>
      )}
    </WizardSectionCard>
  )
}

const StyledTextField = styled(TextField)`
  width: 50%;

  & > .MuiInputBase-root {
    margin-top: 0;
  }
`
