import styled from 'styled-components'

import { CircularProgress } from 'components/CircularProgress'
import { Button } from 'components/button/Button'
import { Modal } from 'components/modal/Modal'
import { ModalContainer } from 'components/modal/ModalContainer'

import { useGetBrickQuery } from 'shared/api/workflows.api'

import { WorkflowBrickSettings } from './WorkflowBrickSettings'

type WorkflowBrickSettingsModalProps = {
  brickId: string
  onClose: () => void
}

export const WorkflowBrickSettingsModal: React.FC<
  WorkflowBrickSettingsModalProps
> = ({ brickId, onClose }) => {
  const brickQuery = useGetBrickQuery(brickId)
  const brick = brickQuery.data

  return (
    <Modal
      open
      title={
        <>
          Step settings
          <DebugIds>
            <span>Workflow ID: {brick?.workflow}</span>
            <span>Step ID: {brick?.id}</span>
          </DebugIds>
        </>
      }
      Container={StyledModalContainer}
      onClose={onClose}
    >
      {!brick ? (
        <CircularProgress />
      ) : (
        <WorkflowBrickSettings
          name={brick.name}
          cytometer={brick.settings.cytometer}
          isRoot={!brick.parent}
          metaClean={brick.settings.metaclean}
          transformationMethod={brick.settings.transformation_method}
          clusterNames={brick.settings.cluster_names}
          lassos={brick.settings.lassos}
          isSubAnalysis={brick.is_sub_analysis}
          channels={brick.settings.channels}
          precisionAndGranularityMode={
            brick.settings.precision_and_granularity_mode
          }
          kt={brick.settings.kt}
          kd={brick.settings.kd}
          referenceAnalysis={brick.reference_analysis}
        />
      )}
      <Actions>
        <Button onClick={onClose}>Close</Button>
      </Actions>
    </Modal>
  )
}

const StyledModalContainer = styled(ModalContainer)`
  max-height: 90vh;
  min-width: 700px;
`

const DebugIds = styled.div`
  display: flex;
  gap: ${props => props.theme.spacing(2)}px;
  font-size: 10px;
  color: ${props => props.theme.colors.greyscale[30]};
  font-family: ${props => props.theme.font.style.light};
  justify-content: center;
`

const Actions = styled.div`
  margin-top: 24px;
`
