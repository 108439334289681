import { Base64 } from 'js-base64'

import { privateApi } from 'shared/api/private.api'
import { PaginatedResults } from 'shared/models/Result'

import { encodeTagParameters } from './utils'

export const exportApi = privateApi.injectEndpoints({
  endpoints: builder => ({
    getExportTasks: builder.query<GetExportTasksResult, GetExportTasksPayload>({
      query: payload => ({
        url: '/exports/',
        params: payload,
      }),
      providesTags: [
        { type: 'Export', id: encodeTagParameters({ id: 'list' }) },
      ],
    }),
    deleteExportTask: builder.mutation<unknown, { id: string }>({
      query: ({ id }) => ({
        url: `/exports/${id}/`,
        method: 'DELETE',
      }),
    }),
    exportGraphicalElements: builder.mutation<
      ExportTask,
      ExportGraphicalElementsPayload
    >({
      query: payload => ({
        url: `/analysis/${payload.analysisId}/export_graphical_elements/`,
        method: 'POST',
        body: {
          markup_list: payload.markupStrings.map(string =>
            Base64.encode(string),
          ),
        },
      }),
    }),
    generateEventsExport: builder.mutation<ExportTask, AnalyisisExportPayload>({
      query: payload => ({
        url: `/analysis/${payload.analysisId}/export_event_data/`,
        method: 'POST',
        body: { type: payload.type },
      }),
    }),
    generateStatisticsExport: builder.mutation<
      ExportTask,
      AnalyisisExportPayload
    >({
      query: payload => ({
        url: `/analysis/${payload.analysisId}/export_statistics/`,
        method: 'POST',
        body: { type: payload.type },
      }),
    }),
    generateBatchStatisticsExport: builder.mutation<
      ExportTask,
      BatchExportPayload
    >({
      query: payload => ({
        url: '/analysis/batch_export_statistics/',
        method: 'POST',
        body: payload,
      }),
    }),
    generateBatchCustomStatisticsExport: builder.mutation<
      ExportTask,
      BatchCustomExportPayload
    >({
      query: payload => ({
        url: '/analysis/batch_export_custom_statistics/',
        method: 'POST',
        body: payload,
      }),
    }),
    generateLogbookExport: builder.mutation<
      ExportTask,
      {
        analysisId: string
        mode: `${LogbookExportModes}`
        type: `${LogbookExportTypes}`
        entryIds: string[]
      }
    >({
      query: payload => ({
        url: `/analysis/${payload.analysisId}/export_logbook/`,
        method: 'POST',
        body: {
          mode: payload.mode,
          type: payload.type,
          exported_entries_pk: payload.entryIds,
        },
      }),
    }),
    generateAnalysisPdf: builder.mutation<
      ExportTask,
      { selectedGraphicalElementIdsByAnalysisId: Record<string, string[]> }
    >({
      query: payload => ({
        url: `/analysis/batch_export_to_pdf/`,
        method: 'POST',
        body: {
          selected_graphical_element_ids:
            payload.selectedGraphicalElementIdsByAnalysisId,
        },
      }),
    }),
    getExportTaskTypes: builder.query<ExportTaskType[], void>({
      query: () => ({
        url: '/exports-types/',
      }),
    }),
    getExportTaskStatus: builder.query<ExportTaskStatus[], void>({
      query: () => ({
        url: '/exports-statuses/',
      }),
    }),
    generateMetaAnalysisPdf: builder.mutation<
      ExportTask,
      { id: string; content: string }
    >({
      query: payload => ({
        url: `/meta-analysis/${payload.id}/export_to_pdf/`,
        method: 'POST',
        body: {
          content: payload.content,
        },
      }),
    }),
  }),
})

export const {
  useGetExportTasksQuery,
  useDeleteExportTaskMutation,
  useGenerateEventsExportMutation,
  useGenerateStatisticsExportMutation,
  useGenerateBatchStatisticsExportMutation,
  useGenerateBatchCustomStatisticsExportMutation,
  useGenerateLogbookExportMutation,
  useGenerateAnalysisPdfMutation,
  useGetExportTaskTypesQuery,
  useGetExportTaskStatusQuery,
} = exportApi

interface GetExportTasksPayload {
  page: number
  ordering?: string
  export_class?: string
  file_name?: string
  created_at_after?: string
  created_at_before?: string
  updated_at_after?: string
  updated_at_before?: string
  status?: string
  workflow?: string
}

interface ExportGraphicalElementsPayload {
  analysisId: string
  markupStrings: string[]
}

export enum ExportTypes {
  Xlsx = 'xlsx',
  Csv = 'csv',
}

export enum LogbookExportModes {
  All = 'all',
  Specific = 'specific',
}
export enum LogbookExportTypes {
  Csv = 'csv',
  Pdf = 'pdf',
  Xlsx = 'xlsx',
}

export interface ExportTask {
  id: string
  created_at: Date | undefined
  updated_at: Date | undefined
  user: string
  status: ExportTaskStatus['key']
  status_display: ExportTaskStatus['name']
  task_id: string
  file_name: string | undefined
  file_ready: boolean
  export_class: ExportTaskType['key']
  export_class_display: ExportTaskType['name']
  format: ExportTaskType['format'] | undefined
  workflow?: string
  is_stale: boolean
}

interface ExportTaskType {
  key: string
  name: string
  format: string
}

interface ExportTaskStatus {
  key: 'W' | 'P' | 'S' | 'E'
  name: string
}

interface AnalyisisExportPayload {
  analysisId: string
  type: `${ExportTypes}`
}

interface BatchExportPayload {
  analyses: string[]
  type: `${ExportTypes}`
}

interface BatchCustomExportPayload {
  analyses: string[]
  type: `${ExportTypes}`
  concatenate: boolean
}

type GetExportTasksResult = PaginatedResults<ExportTask[]>
