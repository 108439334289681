import { Typography } from '@material-ui/core'
import { useFormikContext } from 'formik'
import { FC } from 'react'
import styled from 'styled-components'

import { CircularProgress } from 'components/CircularProgress'
import Input from 'components/forms/Input'
import { Table, TableColumn, TableRow } from 'components/table/Table'

import { BrickReferenceOptions } from 'shared/api/experiments.api'
import { useGetBrickByIdQuery } from 'shared/api/workflows.api'

import { CreateExperimentFormValues } from './CreateExperimentWizard'
import { ReferenceAnalysisSelector } from './ReferenceAnalysisSelector'

type CreateExperimentWizardSelectReferenceStepProps = {
  references?: Record<string, BrickReferenceOptions>
}

export const CreateExperimentWizardSelectReferenceStep: FC<
  CreateExperimentWizardSelectReferenceStepProps
> = ({ references }) => {
  const { values, setFieldValue, handleChange, handleBlur } =
    useFormikContext<CreateExperimentFormValues>()
  const getBrickByIdQueryState = useGetBrickByIdQuery(
    Object.keys(references ?? {}),
  )

  const columns: TableColumn[] = [
    {
      key: 'step_name',
      label: 'Step name',
      style: { width: '100%' },
    },
    {
      key: 'analysis',
      label: 'Analysis',
      style: { width: 160 },
    },
  ]

  const rows: TableRow[] =
    getBrickByIdQueryState.data && references
      ? Object.entries(references).map(([brickId, reference]) => ({
          key: brickId,
          cells: [
            {
              key: 'step_name',
              content: reference.brick_name,
            },
            {
              key: 'analysis',
              content: (
                <ReferenceAnalysisSelector
                  availableReferences={reference.brick_references_analyses}
                  selectedReference={
                    values.referenceAnalyses[reference.brick_id]?.id
                  }
                  onSelectReference={id =>
                    setFieldValue('referenceAnalyses', {
                      ...values.referenceAnalyses,
                      [reference.brick_id]:
                        reference.brick_references_analyses.find(
                          analysis => analysis.id === id,
                        ),
                    })
                  }
                />
              ),
            },
          ],
        }))
      : []

  return (
    <StepRoot>
      {getBrickByIdQueryState.isLoading ? (
        <CircularProgress />
      ) : (
        <>
          <StepTitle variant="h6" align="center">
            Name
          </StepTitle>
          <Input
            name="name"
            value={values.name ?? ''}
            onChange={handleChange}
            onBlur={handleBlur}
          />
          <StepTitle variant="h6" align="center">
            Select reference analyses
          </StepTitle>
          <StyledTable columns={columns} rows={rows} />
        </>
      )}
    </StepRoot>
  )
}

const StepRoot = styled.div`
  min-width: 600px;
  min-height: 200px;
  display: flex;
  flex-direction: column;
`

const StepTitle = styled(Typography)`
  margin-bottom: ${props => props.theme.spacing(2)}px;
`

const StyledTable = styled(Table)`
  display: block;
  max-height: 50vh;
  overflow-y: auto;

  & .MuiTableRow-root {
    box-shadow: none;
  }

  & .MuiTableRow-body,
  .MuiTableCell-body {
    background-color: ${props => props.theme.colors.primary[10]};
  }
`
