/* eslint-disable @typescript-eslint/no-explicit-any */
import { SerializedError, ThunkAction } from '@reduxjs/toolkit'
import { FetchBaseQueryArgs } from '@reduxjs/toolkit/dist/query/fetchBaseQuery'
import {
  FetchBaseQueryError,
  QueryActionCreatorResult,
  QueryDefinition,
} from '@reduxjs/toolkit/query'

import { AppDispatch, RootState } from 'shared/store'
import { selectApplicationInstanceId } from 'shared/store/auth.slice'

import {
  APPLICATION_INSTANCE_ID_HEADER_NAME,
  DONT_SET_HEADER_VALUE,
} from './cookies'

export const makeMultipleQueries = <A, R>(
  dispatch: AppDispatch,
  initiate: (
    arg: A,
  ) => ThunkAction<
    QueryActionCreatorResult<QueryDefinition<A, any, any, R>>,
    any,
    any,
    any
  >,
  args: A[],
): Promise<R[]> => {
  return Promise.all(args.map(arg => dispatch(initiate(arg)).unwrap()))
}

export const encodeTagParameters = (
  parameters: Record<string, string>,
): string => {
  return Object.entries(parameters)
    .sort(([key1], [key2]) => key1.localeCompare(key2))
    .map(([key, value]) => `${key}=${value}`)
    .join(',')
}

export const prepareHeaders: FetchBaseQueryArgs['prepareHeaders'] = (
  headers,
  api,
) => {
  const applicationInstanceId = selectApplicationInstanceId(
    api.getState() as RootState,
  )
  if (
    headers.get(APPLICATION_INSTANCE_ID_HEADER_NAME) !== DONT_SET_HEADER_VALUE
  ) {
    headers.set(APPLICATION_INSTANCE_ID_HEADER_NAME, applicationInstanceId)
  } else {
    headers.delete(APPLICATION_INSTANCE_ID_HEADER_NAME)
  }

  return headers
}

export function unpackError(error: FetchBaseQueryError | SerializedError): {
  status: string
  data: string | Record<string, unknown>
}

export function unpackError<T>(
  error: FetchBaseQueryError | SerializedError,
  transformData?: (data: Record<string, unknown>) => T,
): {
  status: string
  data: T
}

export function unpackError<T = Record<string, unknown>>(
  error: FetchBaseQueryError | SerializedError,
  transformData?: (data: Record<string, unknown>) => T,
): {
  status: string
  data: string | Record<string, unknown> | T
} {
  if (!('status' in error)) {
    return {
      status: error.code ?? 'unknown',
      data: error.message ?? 'No details were given',
    }
  }

  if (
    error.status === 'FETCH_ERROR' ||
    error.status === 'PARSING_ERROR' ||
    error.status === 'CUSTOM_ERROR'
  ) {
    return { status: error.status, data: error.error }
  }

  return {
    status: error.status.toString(),
    data: transformData
      ? transformData(error.data as Record<string, unknown>)
      : (error.data as Record<string, unknown>),
  }
}
