import { difference, mapValues, union } from 'lodash'
import { FC } from 'react'
import styled from 'styled-components'

import { Checkbox } from 'components/Checkbox'
import { StatusDots } from 'components/StatusDots'

import {
  ValidatedAction,
  useAnalysisStatuses,
} from 'pages/analysis/useAnalysisStatuses'

import { BrickDetails } from 'shared/api/workflows.api'

import { FoldableWizardSection } from './FoldableWizardSection'

type BrickAnalysisSelectorProps = {
  selectedAnalysisIds: string[]
  brickById: Record<string, BrickDetails>
  validationAction: ValidatedAction
  onChange: (selectedAnalysisIds: string[]) => void
}

export const BrickAnalysisSelector: FC<BrickAnalysisSelectorProps> = ({
  selectedAnalysisIds,
  brickById,
  validationAction,
  onChange,
}) => {
  const { validateActionBasedOnAnalysisStatus } = useAnalysisStatuses()

  const selectableAnalysisIdsByBrickId = mapValues(brickById, brick =>
    brick.analyses
      .filter(
        analysis =>
          !validateActionBasedOnAnalysisStatus(
            analysis.status,
            validationAction,
          ),
      )
      .map(analysis => analysis.id),
  )

  const areAllAnalysesSelectedByBrickId = mapValues(brickById, brick =>
    brick.analyses.every(
      analysis =>
        !selectableAnalysisIdsByBrickId[brick.id].includes(analysis.id) ||
        selectedAnalysisIds.includes(analysis.id),
    ),
  )

  const handleToggleAnalysis = (analysisId: string) => {
    onChange(
      selectedAnalysisIds.includes(analysisId)
        ? selectedAnalysisIds.filter(id => id !== analysisId)
        : [...selectedAnalysisIds, analysisId],
    )
  }

  const handleToggleSelectAllAnalysesInBrick = (brickId: string) => {
    if (areAllAnalysesSelectedByBrickId[brickId]) {
      onChange(
        difference(
          selectedAnalysisIds,
          selectableAnalysisIdsByBrickId[brickId],
        ),
      )
    } else {
      onChange(
        union(selectedAnalysisIds, selectableAnalysisIdsByBrickId[brickId]),
      )
    }
  }

  return (
    <Bricks>
      {Object.values(brickById).map(brick => {
        return (
          <FoldableWizardSection
            key={brick.id}
            title={
              <Checkbox
                checked={
                  selectableAnalysisIdsByBrickId[brick.id].length > 0 &&
                  areAllAnalysesSelectedByBrickId[brick.id]
                }
                onChange={() => handleToggleSelectAllAnalysesInBrick(brick.id)}
                label={brick.name}
                disabled={selectableAnalysisIdsByBrickId[brick.id].length === 0}
              />
            }
          >
            <Analyses>
              {brick.analyses.map(analysis => (
                <Checkbox
                  key={analysis.id}
                  checked={selectedAnalysisIds.includes(analysis.id)}
                  onChange={() => handleToggleAnalysis(analysis.id)}
                  label={
                    <AnalysisRow>
                      <span>{analysis.name}</span>
                      <StatusDots status={analysis.status} singleLine />
                    </AnalysisRow>
                  }
                  disabled={
                    !selectableAnalysisIdsByBrickId[brick.id].includes(
                      analysis.id,
                    )
                  }
                />
              ))}
            </Analyses>
          </FoldableWizardSection>
        )
      })}
    </Bricks>
  )
}

const Bricks = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
`

const Analyses = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
  padding-left: 4px;
`

const AnalysisRow = styled.div`
  display: flex;
  gap: 8px;
`
