import { Point, Polygon } from 'geometric'

import { translateToChartPoint } from 'shared/utils/highcharts-utils'

import { LassoHighcharts } from './LassoHighcharts'
import { LassoToolProps, LassoToolState } from './LassoTool'
import { renderStaticLasso } from './renderStaticLasso'

export class LassoRenderer {
  private props: LassoToolProps
  private state: LassoToolState
  private lassoHighcharts: LassoHighcharts

  constructor(props: LassoToolProps, state: LassoToolState) {
    this.props = props
    this.state = state
    this.lassoHighcharts = new LassoHighcharts(state)
  }

  onReactRender(props: LassoToolProps): void {
    this.props = props
  }

  clear(): void {
    this.state.svgGroup?.destroy()
  }

  render(hoveredPolygonPoint: Point | undefined): void {
    const chart = this.lassoHighcharts.getChart()

    if (!chart?.renderer) {
      return
    }

    this.clear()

    const {
      isActive,
      lassos,
      currentLassoId,
      mousePoint,
      movedPolygonPointIndex,
      __computed__hoveredLassoId,
      __computed__canFinishLasso,
    } = this.state

    if (!isActive) {
      return
    }

    this.state.svgGroup = chart.renderer
      .g()
      .add()
      .toFront()
      .css({ pointerEvents: 'none' })

    for (const lasso of Object.values(lassos)) {
      let polygon = lasso.polygon
      if (
        movedPolygonPointIndex !== undefined &&
        mousePoint &&
        lasso.id === currentLassoId
      )
        if (lasso.type === 'rectangle') {
          polygon = this.resizeRectangle(mousePoint, polygon)
        } else {
          polygon = polygon.map((point, index) =>
            index === movedPolygonPointIndex ? mousePoint : point,
          )
        }

      if (lasso.isFinished || __computed__canFinishLasso) {
        this.renderFinishedLasso(
          chart,
          polygon,
          lasso.id === currentLassoId,
          currentLassoId === __computed__hoveredLassoId
            ? hoveredPolygonPoint
            : undefined,
        )
      } else {
        const translatedPolygon = polygon.map(point =>
          translateToChartPoint(point, chart),
        )

        this.renderOpenPath(
          chart,
          mousePoint
            ? [...translatedPolygon, translateToChartPoint(mousePoint, chart)]
            : translatedPolygon,
        )
      }
    }
  }

  resizeRectangle(point: Point, polygon: Polygon): Polygon {
    if (
      this.state.movedPolygonPointIndex == undefined ||
      this.state.__computed__horizontalConnectedVertexIndex === undefined ||
      this.state.__computed__verticalConnectedVertexIndex == undefined
    ) {
      return polygon
    }
    return polygon.map((polygonPoint, index) => {
      if (index === this.state.movedPolygonPointIndex) {
        return point
      }
      if (index === this.state.__computed__horizontalConnectedVertexIndex) {
        return [polygonPoint[0], point[1]]
      }
      if (index === this.state.__computed__verticalConnectedVertexIndex) {
        return [point[0], polygonPoint[1]]
      }
      return polygonPoint
    })
  }

  private renderOpenPath(chart: Highcharts.Chart, path: Point[]) {
    for (let i = 0; i < path.length - 1; i++) {
      chart.renderer
        .createElement('line')
        .attr({
          x1: path[i][0],
          y1: path[i][1],
          x2: path[i + 1][0],
          y2: path[i + 1][1],
          style: `stroke: ${this.props.theme.colors.primary[100]}; stroke-width: 1;`,
        })
        .add(this.state.svgGroup)
    }
  }

  private renderFinishedLasso(
    chart: Highcharts.Chart,
    polygon: Polygon,
    isCurrentLasso: boolean,
    hoveredPolygonPoint: Point | undefined,
  ) {
    renderStaticLasso({
      chart,
      polygon,
      svgGroup: this.state.svgGroup,
      theme: this.props.theme,
      isCurrentLasso,
    })

    if (hoveredPolygonPoint) {
      const translatedHoveredPolygonPoint = translateToChartPoint(
        hoveredPolygonPoint,
        chart,
      )

      chart.renderer
        .circle({
          cx: translatedHoveredPolygonPoint[0],
          cy: translatedHoveredPolygonPoint[1],
          r: 5,
          fill: this.props.theme.colors.error,
        })
        .add(this.state.svgGroup)
    }
  }
}
