import { FC, useState } from 'react'
import { FileRejection, useDropzone } from 'react-dropzone'
import styled from 'styled-components'

import { Icon } from 'assets/images/icons/Icon'

type DropzoneProps = Readonly<{
  fileType: 'fcs' | 'spillover'
  onFileSelection: (files: File[]) => void
  onDropError: (r: FileRejection[]) => void
  maxSize?: number
  accept?: string[]
  multiple?: boolean
  className?: string
}>

export const Dropzone: FC<DropzoneProps> = ({
  fileType,
  onFileSelection,
  onDropError,
  maxSize,
  accept,
  multiple,
  className,
}) => {
  const [hovered, setHovered] = useState(false)
  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop: onFileSelection,
    onDropRejected: onDropError,
    multiple,
    maxSize,
    accept,
  })

  return (
    <Container
      {...getRootProps()}
      className={className}
      onMouseEnter={() => setHovered(true)}
      onMouseLeave={() => setHovered(false)}
    >
      <StyledIcon name="fileUpload" />
      <input {...getInputProps()} />
      {isDragActive ? (
        <Text $hovered={hovered}>Drop the files here ...</Text>
      ) : (
        <Text $hovered={hovered}>
          Drag and drop {fileType === 'fcs' ? 'FCS files' : 'matrix files'}
          <span> OR </span>
          <Browse $hovered={hovered}>Browse</Browse>
        </Text>
      )}
    </Container>
  )
}

const Container = styled.div`
  min-height: 190px;
  display: flex;
  background: ${({ theme }) => theme.colors.primary[10]};
  border: 2px dashed ${({ theme }) => theme.colors.primary[70]};
  border-radius: ${({ theme }) => theme.radius[2]}px;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  outline: none;
  cursor: pointer;
`

const StyledIcon = styled(Icon)`
  margin-bottom: 40px;
`

const Browse = styled.div<{ $hovered: boolean }>`
  padding: 14px 28px;
  background-color: ${({ theme, $hovered }) =>
    theme.colors.primary[$hovered ? 100 : 70]};
  color: ${({ theme }) => theme.colors.white};
  border-radius: ${({ theme }) => theme.radius['1']}px;
  margin-left: 3px;
  font-size: ${({ theme }) => theme.font.size.regular}px;
  transition: background-color 0.3s ease;
`

const Text = styled.div<{ $hovered: boolean }>`
  display: flex;
  align-items: center;
  color: ${({ theme, $hovered }) => theme.colors.primary[$hovered ? 100 : 70]};
  font-size: ${({ theme }) => theme.font.size.h2}px;
  font-family: ${({ theme }) => theme.font.style.bold};
  transition: color 0.3s ease;
  & > span {
    margin: 0 8px;
    color: ${({ theme }) => theme.colors.primary[50]};
  }
`
