import Highcharts from 'highcharts'
import { useMemo } from 'react'

import {
  Cluster,
  selectGloballyHiddenClusters,
  selectHighlightedCluster,
} from 'pages/analysis/store/selectors'

import { useAppSelector } from 'shared/store'

import { createAnalysisNetworkGraphBaseOptions } from './AnalysisNetworkGraphBaseOptions'
import { Chart } from './Chart'

type NetworkGraphProps = {
  activeClusters: Cluster[]
  width: number
  height: number
}

export const NetworkGraph = ({
  activeClusters,
  width,
  height,
}: NetworkGraphProps): JSX.Element => {
  const highlightedCluster = useAppSelector(selectHighlightedCluster)
  const globallyHiddenClusters = useAppSelector(selectGloballyHiddenClusters)

  const options: Highcharts.Options = useMemo(() => {
    const AnalysisNetworkGraphBaseOptions =
      createAnalysisNetworkGraphBaseOptions({
        activeClusters,
        globallyHiddenClusters,
        highlightedCluster,
      })

    return {
      ...AnalysisNetworkGraphBaseOptions,
      chart: {
        ...AnalysisNetworkGraphBaseOptions.chart,
        width,
        height,
      },
    }
  }, [
    activeClusters,
    globallyHiddenClusters,
    highlightedCluster,
    width,
    height,
  ])

  return (
    <Chart
      highcharts={Highcharts}
      options={options}
      immutable
      data-cy="network-chart"
    />
  )
}
