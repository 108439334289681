import React, { FC } from 'react'
import styled from 'styled-components'

import { Icon } from 'assets/images/icons/Icon'

type EmptyListProps = Readonly<{
  text: string
}>

export const EmptyList: FC<EmptyListProps> = ({ text }) => {
  return (
    <Container>
      <Icon name="box" />
      <Text>{text}</Text>
    </Container>
  )
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 30%;
`

const Text = styled.p`
  margin-top: 35px;
  color: ${props => props.theme.colors.primaryDark[50]};
  font-size: ${props => props.theme.font.size.h1}px;
  font-family: ${props => props.theme.font.style.bold};
`
