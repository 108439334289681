import { Link } from 'react-router-dom'
import styled from 'styled-components'

import { Icon, IconNames } from 'assets/images/icons/Icon'
import { ReactComponent as Logo } from 'assets/images/logo.svg'

import { Header } from 'components/Header'

import { theme } from 'Theme'

type HomeButton = {
  icon: IconNames
  label: string
  linkTo: string | object
  target?: string
}

const homeButtons: HomeButton[] = [
  {
    icon: 'cog',
    label: 'Manage profile',
    linkTo: '/profile',
  },
  {
    icon: 'flow',
    label: 'Manage projects',
    linkTo: '/projects',
  },
  {
    icon: 'brain',
    label: 'Learn about Metaflow',
    linkTo: { pathname: `${window.location.origin}/learn/` },
    target: '_blank',
  },
]

export const HomePage = (): JSX.Element => {
  return (
    <HomePageRoot>
      <StyledHeader />
      <BodyContainer>
        <StyledLogo />
        <HeaderText>What would you like to do?</HeaderText>
        <ButtonsContainer>
          {homeButtons.map(button => (
            <StyledLinkButton
              key={button.label}
              to={button.linkTo}
              target={button.target}
            >
              <Icon name={button.icon} color={theme.colors.primaryDark[100]} />
              <ButtonLabel>{button.label}</ButtonLabel>
            </StyledLinkButton>
          ))}
        </ButtonsContainer>
      </BodyContainer>
    </HomePageRoot>
  )
}

const HomePageRoot = styled.div`
  color: ${({ theme }) => theme.colors.background};
`

const BodyContainer = styled.div`
  display: flex;
  flex-direction: column;
  height: max;
  align-items: center;
  justify-content: center;
`

const StyledLogo = styled(Logo)`
  width: 340px;
  height: 87px;
  margin-bottom: 30px;
`

const HeaderText = styled.p`
  text-align: center;
  line-height: 18px;
  font-size: 28px;
  color: ${({ theme }) => theme.colors.greyscale[100]};
  font-family: ${({ theme }) => theme.font.style.regular};
  font-style: normal;
  margin-bottom: 100px;
`

const ButtonsContainer = styled.div`
  display: flex;
  flex-direction: row;
  margin-bottom: 20%;
  &:hover > a:hover {
    opacity: 1;
  }
  @media (max-width: 768px) {
    flex-direction: column;
  }
`

const StyledLinkButton = styled(Link)`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: ${({ theme }) => theme.colors.white};
  border: 1px solid ${({ theme }) => theme.colors.primary[10]};
  box-sizing: border-box;
  box-shadow: 0px 4px 10px ${({ theme }) => theme.shadow[1]};
  border-radius: 16px;
  height: 222px;
  width: 222px;
  margin-left: 12px;
  margin-right: 12px;
  text-decoration: none;
  &:hover {
    background: ${({ theme }) => theme.colors.primaryDark[100]};
  }
  &:hover path {
    fill: ${({ theme }) => theme.colors.white};
  }
  &:hover p {
    color: ${({ theme }) => theme.colors.white};
  }
`

const ButtonLabel = styled.p`
  color: ${({ theme }) => theme.colors.greyscale[100]};
  font-size: ${({ theme }) => theme.font.size.regular}px;
  line-height: 18px;
  text-align: center;
  margin-top: 12px;
  font-style: normal;
  font-weight: bold;
  font-family: ${({ theme }) => theme.font.style.regular};
`

const StyledHeader = styled(Header)`
  margin: 60px 100px 0;
`
