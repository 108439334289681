import { init } from '@sentry/react'
import { BrowserTracing } from '@sentry/tracing'

import { getInjectedEnvironmentVariable } from 'shared/utils/getInjectedEnvironmentVariable'

const SENTRY_DSN = getInjectedEnvironmentVariable('REACT_APP_SENTRY_DSN')
const SENTRY_MAX_BREADCRUMBS = getInjectedEnvironmentVariable(
  'REACT_APP_SENTRY_MAX_BREADCRUMBS',
)?.match('^[0-9]+$')
  ? Number(getInjectedEnvironmentVariable('REACT_APP_SENTRY_MAX_BREADCRUMBS'))
  : 20
const SENTRY_ENVIRONMENT = getInjectedEnvironmentVariable(
  'REACT_APP_SENTRY_ENVIRONMENT',
)
const SENTRY_SEND_DEFAULT_PII =
  (
    getInjectedEnvironmentVariable('REACT_APP_SENTRY_SEND_DEFAULT_PII') ??
    'true'
  ).toLowerCase() === 'true'

export const initSentry = (): void => {
  if (!SENTRY_DSN || !SENTRY_ENVIRONMENT) {
    console.warn('Skipping Sentry initialization.')
    return
  }

  try {
    init({
      dsn: SENTRY_DSN,
      environment: SENTRY_ENVIRONMENT,
      maxBreadcrumbs: SENTRY_MAX_BREADCRUMBS,
      sendDefaultPii: SENTRY_SEND_DEFAULT_PII,
      integrations: [new BrowserTracing()],
    })
  } catch (error) {
    console.error(error)
  }
}
