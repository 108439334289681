import Highcharts from 'highcharts'
import { mapValues } from 'lodash'

import {
  computeSeriesImageDataURL,
  renderSeriesImage,
} from 'components/graphs/high-performance-scatter-plot.utils'

import { FcsFile } from 'shared/api/files.api'
import {
  MetaAnalysisFile,
  MetaAnalysisGlobalVizFile,
  MetaAnalysisScatterPlotType,
} from 'shared/api/meta-analysis.api'
import { metaAnalysisWorker } from 'shared/worker'

type RenderScatterPlotProps = {
  chart: MetaAnalysisScatterPlotType
  metaAnalysisFile: MetaAnalysisFile
  files: FcsFile[]
  globalVizFile: MetaAnalysisGlobalVizFile
  dimensionalityReductionMethod: string
  width: number
  height: number
}

export const renderScatterPlot = async ({
  chart,
  metaAnalysisFile,
  globalVizFile,
  files,
  width,
  height,
}: RenderScatterPlotProps): Promise<string> => {
  const { scale } = await metaAnalysisWorker.computeScatterPlotSeries({
    chart,
    metaAnalysisFile,
    globalVizFile,
    files,
    plotWidth: undefined,
    plotHeight: undefined,
    zoom: undefined,
  })

  const highchartsChart = new Highcharts.Chart(document.createElement('div'), {
    chart: {
      type: 'scatter',
      width,
      height,
    } as Highcharts.ChartOptions,
    xAxis: {
      title: {
        text: chart.xAxis,
      },
      ...scale.xAxis,
    },
    yAxis: {
      title: {
        text: chart.yAxis,
      },
      ...scale.yAxis,
    },
    title: {
      text: chart.name,
    },
  })

  const { series, metadataBySeriesId } =
    await metaAnalysisWorker.computeScatterPlotSeries({
      metaAnalysisFile,
      globalVizFile,
      files,
      plotWidth: highchartsChart.plotWidth,
      plotHeight: highchartsChart.plotHeight,
      zoom: undefined,
      chart,
    })

  if (!series) {
    throw new Error('Could not compute series')
  }

  const imageDataUrl = computeSeriesImageDataURL({
    width: series.plotWidth,
    height: series.plotHeight,
    seriesIdByPixelPosition: series.seriesIdByPixelPosition,
    colorBySeriesId: mapValues(metadataBySeriesId, 'color'),
  })

  highchartsChart.options.chart!.events = {
    render: function (this: Highcharts.Chart) {
      renderSeriesImage(this, imageDataUrl)
    },
  }

  return highchartsChart.getSVG()
}
