import { Cluster } from 'pages/analysis/store/selectors'

import { Graph } from 'shared/models/Graphs'

import { SeriesEvents } from './SeriesEvents'
import { downsampleHistogramData } from './downsampleHistogramData'

type ComputeHighPerformanceSeriesProps = {
  chart: Pick<
    Graph,
    | 'id'
    | 'chart_type'
    | 'x_axis'
    | 'y_axis'
    | 'hidden_cluster_ids'
    | 'event_limit'
  > & {
    activeLeaves: (Pick<Cluster, 'id' | 'color' | 'label' | 'parent_rate'> & {
      stats: {
        count: number
      }
    })[]
    activeClusters: string[]
    scale: [number, number]
  }
  numberOfBins?: number
}

export class HistogramSeries {
  private seriesEvents: SeriesEvents

  constructor(seriesEvents: SeriesEvents) {
    this.seriesEvents = seriesEvents
  }

  public async computeHighPerformanceSeries({
    chart,
    numberOfBins,
  }: ComputeHighPerformanceSeriesProps): Promise<
    Highcharts.SeriesColumnOptions[]
  > {
    const activeHiddenClusters = chart.hidden_cluster_ids.filter(id =>
      chart.activeClusters.includes(id),
    )

    const eventsByLeafId = await this.seriesEvents.computeEventsByLeafId({
      xAxis: chart.x_axis,
      leafIds: chart.activeLeaves.map(leaf => leaf.id),
      hiddenClustersIds: activeHiddenClusters,
      eventLimit: chart.event_limit,
    })

    return Object.entries(
      downsampleHistogramData({
        numberOfBins,
        scale: chart.scale,
        pointsBySeriesId: eventsByLeafId,
      }),
    ).map(([leafId, data]) => {
      const leaf = chart.activeLeaves.find(leaf => leaf.id === leafId)
      if (!leaf) {
        throw new Error('Cannot find active cluster')
      }

      return {
        type: 'column',
        id: leafId,
        name: leaf.label,
        color: leaf.color,
        data,
        custom: {
          parentRate: leaf.parent_rate,
          count: leaf.stats.count,
        },
      }
    })
  }
}
