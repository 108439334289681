import {
  FloatingPortal,
  safePolygon,
  useDismiss,
  useFloating,
  useHover,
  useInteractions,
} from '@floating-ui/react'
import { IconButton } from '@material-ui/core'
import { ArrowBackIos, ArrowForwardIos, Search } from '@material-ui/icons'
import { FC, useMemo, useRef, useState } from 'react'
import styled from 'styled-components'

import { Button } from 'components/button/Button'
import { DEFAULT_USE_FLOATING_PROPS } from 'components/tooltip'

import { BrickAnalysisList } from 'pages/project/BrickAnalysisList'

import { useSafeNavigate } from 'shared/hooks/router'
import { useEventCallback } from 'shared/hooks/useEventCallback'
import { useAppSelector } from 'shared/store'

import { DataLine } from './DataLine'
import {
  selectAnalysis,
  selectAnalysisBrick,
  selectAnalysisCompensatedFile,
  selectAnalysisProject,
} from './store/selectors'
import { useAnalysisStatuses } from './useAnalysisStatuses'

type BrickNavigationProps = {
  className?: string
}

export const BrickNavigation: FC<BrickNavigationProps> = ({ className }) => {
  const currentAnalysis = useAppSelector(selectAnalysis)
  const brick = useAppSelector(selectAnalysisBrick)
  const compensatedFile = useAppSelector(selectAnalysisCompensatedFile)
  const project = useAppSelector(selectAnalysisProject)

  const safeNavigate = useSafeNavigate()
  const { validateActionBasedOnAnalysisStatus } = useAnalysisStatuses()

  const [isOpen, setIsOpen] = useState(false)

  const navigationRef = useRef<HTMLDivElement>(null)

  const { floatingStyles, refs, context } = useFloating({
    ...DEFAULT_USE_FLOATING_PROPS,
    open: isOpen,
    onOpenChange: setIsOpen,
    transform: false,
    placement: 'bottom-end',
  })
  const { getReferenceProps, getFloatingProps } = useInteractions([
    useHover(context, { handleClose: safePolygon() }),
    useDismiss(context, { escapeKey: true }),
  ])

  const analyses = brick.analyses
  const currentAnalysisIndex = analyses?.findIndex(
    analysis => analysis.id === currentAnalysis.id,
  )

  const previousAnalysisId = useMemo(() => {
    return currentAnalysisIndex !== undefined
      ? analyses?.findLast(
          (analysis, index) =>
            index < currentAnalysisIndex &&
            validateActionBasedOnAnalysisStatus(
              analysis.status,
              'view-analysis',
            ) === undefined,
        )?.id
      : undefined
  }, [analyses, currentAnalysisIndex, validateActionBasedOnAnalysisStatus])

  const nextAnalysisId = useMemo(() => {
    return currentAnalysisIndex !== undefined
      ? analyses?.find(
          (analysis, index) =>
            index > currentAnalysisIndex &&
            validateActionBasedOnAnalysisStatus(
              analysis.status,
              'view-analysis',
            ) === undefined,
        )?.id
      : undefined
  }, [analyses, currentAnalysisIndex, validateActionBasedOnAnalysisStatus])

  const handleNext = useEventCallback(() => {
    safeNavigate(`./${nextAnalysisId}`)
  })

  const handlePrevious = useEventCallback(() => {
    safeNavigate(`./${previousAnalysisId}`)
  })

  return (
    <BrickNavigationRoot className={className} ref={navigationRef}>
      <Title
        title={compensatedFile.name}
        content={project.name}
        inverted={true}
      />
      <NavigationButtons>
        <Button
          disabled={previousAnalysisId === undefined}
          startIcon={<ArrowBackIos />}
          onClick={handlePrevious}
        >
          Previous
        </Button>
        <Button
          disabled={nextAnalysisId === undefined}
          endIcon={<ArrowForwardIos />}
          onClick={handleNext}
        >
          Next
        </Button>
        <IconButton
          color="primary"
          ref={refs.setReference}
          {...getReferenceProps()}
        >
          <Search />
        </IconButton>
        {isOpen && (
          <FloatingPortal root={navigationRef}>
            <BrickAnalysisListContainer
              ref={refs.setFloating}
              style={floatingStyles}
              onClick={event => event.stopPropagation()}
              {...getFloatingProps()}
            >
              <BrickAnalysisList
                brickId={brick.id}
                onClose={() => setIsOpen(false)}
                onCloseContextMenu={() => setIsOpen(false)}
              />
            </BrickAnalysisListContainer>
          </FloatingPortal>
        )}
      </NavigationButtons>
    </BrickNavigationRoot>
  )
}

const BrickNavigationRoot = styled.div`
  display: flex;
  align-items: center;
  padding: 10px 20px;
  background-color: ${props => props.theme.colors.primaryDark[20]};
  border-radius: ${props => props.theme.radius[2]}px;
`

const Title = styled(DataLine)`
  max-width: 300px;
  font-family: ${props => props.theme.font.style.bold};
  margin-right: 20px;
`

const NavigationButtons = styled.div`
  display: flex;
  align-items: center;
  gap: ${props => props.theme.spacing(1)}px;
`

const BrickAnalysisListContainer = styled.div`
  z-index: 1301;
  box-shadow: 2px 2px 10px 2px rgba(0, 0, 0, 0.1);
  border-radius: 4px;
  overflow: hidden;
  display: grid;
  grid-template-rows: 1fr;
`
