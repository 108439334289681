import {
  FormControlLabel,
  FormControlLabelProps,
  Radio,
} from '@material-ui/core'
import { ReactElement } from 'react'

type RadioButtonProps = Pick<
  FormControlLabelProps,
  'value' | 'label' | 'disabled'
>

export default function RadioButton(props: RadioButtonProps): ReactElement {
  return (
    <FormControlLabel
      value={props.value}
      label={props.label}
      disabled={props.disabled}
      control={<Radio color="primary" />}
    />
  )
}
