import dedent from 'dedent'

import { useLazyGetMetricsQuery } from 'shared/api/utils.api'
import { useAppSelector } from 'shared/store'
import { formatDateTime } from 'shared/utils/utils'

import {
  selectMetaAnalysisCreationDate,
  selectMetaAnalysisWorkflowName,
  selectMetaAnalysisName,
  selectMetaAnalysisProjectName,
} from './store/selectors'

export const useMetaAnalysisCsvMetadata = (): (() => Promise<string>) => {
  const [triggerGetMetricsQuery] = useLazyGetMetricsQuery()
  const projectName = useAppSelector(selectMetaAnalysisProjectName)
  const workflowName = useAppSelector(selectMetaAnalysisWorkflowName)
  const name = useAppSelector(selectMetaAnalysisName)
  const metaAnalysisCreationDate = useAppSelector(
    selectMetaAnalysisCreationDate,
  )

  const createCsvMetadata = async () => {
    const metrics = await triggerGetMetricsQuery().unwrap()

    return (
      dedent`
      Project:,${projectName}
      Workflow:,${workflowName}
      Name:,${name}
      Created:,${formatDateTime(metaAnalysisCreationDate)}
        
      Export created:, ${formatDateTime(new Date(Date.now()))}
      Metaflow version:, ${metrics.metaflow_version}
    ` + '\n\n'
    )
  }

  return createCsvMetadata
}
