import { FC, useEffect, useMemo, useState } from 'react'
import styled from 'styled-components'

import { Button } from 'components/Button'
import { Modal } from 'components/Modal'
import { LayoutItemCard } from 'components/graphs/LayoutItemCard'
import { LayoutItemCardHeader } from 'components/graphs/LayoutItemCardHeader'

import { CLUSTER_LIST_ID } from 'shared/constants'
import { useEventCallback } from 'shared/hooks/useEventCallback'
import { useAppDispatch, useAppSelector } from 'shared/store'

import { ClusterList } from './ClusterList'
import {
  changeClusterDotSize,
  changeClustersVisibility,
  changeClustersVisibilityOnAllCharts,
  renameCluster,
} from './store/analysis.slice'
import {
  selectAnalysisAccessMode,
  selectAnalysisLassos,
  selectAnalysisLoadingStatus,
  selectChartById,
  selectCharts,
  selectClusterListClusters,
  selectClusterListLinkedChartId,
  selectClusters,
  selectCurrentLayout,
  selectShownActiveLeafIds,
} from './store/selectors'

type AnalysisClustersListProps = {
  isExpanded?: boolean
  className?: string
  onCloseExpand?: () => void
}

export const AnalysisClustersList: FC<AnalysisClustersListProps> = ({
  isExpanded,
  className,
  onCloseExpand,
}) => {
  const dispatch = useAppDispatch()
  const selectedChartId = useAppSelector(selectClusterListLinkedChartId)
  const analysisCharts = useAppSelector(selectChartById)
  const isAnalysisLoaded =
    useAppSelector(selectAnalysisLoadingStatus) === 'success'
  const lassos = useAppSelector(selectAnalysisLassos)
  const clusters = useAppSelector(selectClusters)
  const activeLeavesWithoutRoot = useAppSelector(selectClusterListClusters)
  const charts = useAppSelector(selectCharts)
  const globallyShownActiveLeaves = useAppSelector(selectShownActiveLeafIds)
  const currentLayout = useAppSelector(selectCurrentLayout)
  const analysisAccessMode = useAppSelector(selectAnalysisAccessMode)

  const [selectedClusters, setSelectedClusters] = useState<string[]>([])
  const [increasedDotSizeClusterIds, setIncreasedDotSizeClusterIds] = useState<
    string[]
  >([])
  const [shouldShowExpandedSelf, setShouldShowExpandedSelf] = useState(false)

  const selectedChartName = useMemo(
    () =>
      Object.values(analysisCharts).find(({ id }) => id === selectedChartId)
        ?.name,
    [analysisCharts, selectedChartId],
  )

  const chart = useMemo(
    () => charts.find(g => g.id === selectedChartId),
    [charts, selectedChartId],
  )

  useEffect(() => {
    setSelectedClusters(
      selectedChartId
        ? clusters
            .map(cluster => cluster.id)
            .filter(clusterId => !chart?.hidden_cluster_ids.includes(clusterId))
        : globallyShownActiveLeaves,
    )
  }, [
    chart?.hidden_cluster_ids,
    clusters,
    globallyShownActiveLeaves,
    selectedChartId,
  ])

  const applyChanges = useEventCallback(() => {
    const hiddenClusters = activeLeavesWithoutRoot
      .map(leaf => leaf.id)
      .filter(leafId => !selectedClusters.includes(leafId))

    if (!selectedChartId) {
      dispatch(changeClustersVisibilityOnAllCharts({ hiddenClusters }))
    } else {
      dispatch(
        changeClustersVisibility({
          chartId: selectedChartId,
          hiddenClusters,
        }),
      )
    }
  })

  const handleDoubleClickClusterDot = useEventCallback((clusterId: string) => {
    if (increasedDotSizeClusterIds.includes(clusterId)) {
      dispatch(changeClusterDotSize({ clusterIds: [clusterId], size: 1 }))
      setIncreasedDotSizeClusterIds(
        increasedDotSizeClusterIds.filter(id => id !== clusterId),
      )
    } else {
      setIncreasedDotSizeClusterIds([...increasedDotSizeClusterIds, clusterId])
      dispatch(
        changeClusterDotSize({
          clusterIds: [clusterId],
          size: 3,
        }),
      )
    }
  })

  const handleRename = useEventCallback((clusterId: string, name: string) => {
    dispatch(renameCluster({ clusterId, name }))
  })

  return (
    <AnalysisClusterListRoot $isExpanded={isExpanded} className={className}>
      <StyledLayoutItemCardHeader
        title={`Cluster List - ${selectedChartName || 'All'}`}
        isExpanded={isExpanded}
        onExpand={() => setShouldShowExpandedSelf(true)}
        onCloseExpand={onCloseExpand}
      />
      <ClusterList
        clusters={activeLeavesWithoutRoot}
        lassos={lassos}
        selectedIds={selectedClusters}
        onChange={
          analysisAccessMode === 'read-and-write'
            ? setSelectedClusters
            : undefined
        }
        onRename={analysisAccessMode !== 'read-only' ? handleRename : undefined}
        columns={currentLayout[CLUSTER_LIST_ID].w}
        selectedDotClusterIds={increasedDotSizeClusterIds}
        onDoubleClickClusterDot={handleDoubleClickClusterDot}
      ></ClusterList>
      <ButtonContainer>
        <StyledButton
          onClick={applyChanges}
          disabled={
            !isAnalysisLoaded || analysisAccessMode !== 'read-and-write'
          }
        >
          Apply Changes
        </StyledButton>
      </ButtonContainer>
      {shouldShowExpandedSelf && (
        <Modal open onClose={() => setShouldShowExpandedSelf(false)}>
          <ExpandedAnalysisClusterList
            onCloseExpand={() => setShouldShowExpandedSelf(false)}
          />
        </Modal>
      )}
    </AnalysisClusterListRoot>
  )
}

const AnalysisClusterListRoot = styled(LayoutItemCard)`
  padding: 0;
  grid-template-rows: auto 1fr auto;
`

const StyledLayoutItemCardHeader = styled(LayoutItemCardHeader)`
  padding: 12px;
`

const ButtonContainer = styled.div`
  padding: 4px;
  display: flex;
  flex: 1;
  align-items: flex-end;
  border-top: 1px solid ${props => props.theme.colors.primary[20]};
`

const StyledButton = styled(Button)`
  margin-left: auto;
  font-size: ${props => props.theme.font.size.smallest}px;
  height: 28px;
  overflow: hidden;
  padding: 0 10px;
`

const ExpandedAnalysisClusterList = styled(AnalysisClustersList).attrs({
  isExpanded: true,
})`
  width: 90vw;
  height: 90vh;
`
