import { DialogActions, DialogContent, DialogTitle } from '@material-ui/core'
import { Formik } from 'formik'
import { union } from 'lodash'
import { useMemo } from 'react'
import styled from 'styled-components'
import { object, string } from 'yup'

import { Button } from 'components/Button'
import Input from 'components/forms/Input'
import Select from 'components/forms/Select'

import { useGetBrickByIdQuery } from 'shared/api/workflows.api'
import { useAppDispatch, useAppSelector } from 'shared/store'

import { renameCluster } from './store/analysis.history.slice'
import {
  Cluster,
  selectAnalysisAccessMode,
  selectAnalysisBrick,
  selectAnalysisWorkflow,
  selectUsedClusterLabels,
} from './store/selectors'

type RenameClusterDialogProps = {
  cluster: Pick<Cluster, 'id' | 'label' | 'defaultLabel'>
  onClose: () => void
}

export const RenameClusterDialog = ({
  cluster,
  onClose,
}: RenameClusterDialogProps): JSX.Element => {
  const dispatch = useAppDispatch()
  const usedClusterLabels = useAppSelector(selectUsedClusterLabels)
  const analysisAccessMode = useAppSelector(selectAnalysisAccessMode)
  const analysisBrick = useAppSelector(selectAnalysisBrick)
  const analysisWorkflow = useAppSelector(selectAnalysisWorkflow)

  const RenameClusterDialogFormSchema = useMemo(
    () =>
      object({
        clusterName: string().test(
          'unique-cluster-name',
          'Cluster with this name already exists',
          value => !usedClusterLabels.has(value || cluster.defaultLabel),
        ),
      }),
    [cluster.defaultLabel, usedClusterLabels],
  )

  const childBrickIds = useMemo(() => {
    return analysisWorkflow.bricks
      .filter(brick => brick.parent === analysisBrick.id)
      .map(brick => brick.id)
  }, [analysisBrick.id, analysisWorkflow.bricks])

  const getBrickByIdQuery = useGetBrickByIdQuery(childBrickIds, {
    skip: analysisAccessMode !== 'experiment',
  })

  const availableClusterNames = useMemo(() => {
    return getBrickByIdQuery.data
      ? union(
          ...Object.values(getBrickByIdQuery.data).map(
            brick => brick.original_settings?.cluster_names ?? [],
          ),
        )
      : []
  }, [getBrickByIdQuery.data])

  return (
    <Formik<RenameClusterDialogFormValue>
      initialValues={{ clusterName: cluster.label }}
      onSubmit={value => {
        dispatch(
          renameCluster({ clusterId: cluster.id, name: value.clusterName }),
        )
        onClose()
      }}
      validationSchema={RenameClusterDialogFormSchema}
    >
      {({
        handleSubmit,
        values,
        handleChange,
        handleBlur,
        errors,
        isValid,
        resetForm,
      }) => (
        <StyledForm onSubmit={handleSubmit}>
          <StyledDialogTitle>Rename {cluster.label} cluster</StyledDialogTitle>
          <DialogContent>
            {analysisAccessMode === 'experiment' ? (
              <Select
                name="clusterName"
                value={values.clusterName}
                options={availableClusterNames.map(name => ({
                  value: name,
                  label: name,
                }))}
                error={errors.clusterName}
                disabled={getBrickByIdQuery.isLoading}
                onChange={handleChange}
              />
            ) : (
              <Input
                name="clusterName"
                label="Name"
                value={values.clusterName}
                placeholder={cluster.defaultLabel}
                onBlur={handleBlur}
                onChange={handleChange}
                error={errors.clusterName}
                autoFocus
              />
            )}
          </DialogContent>
          <DialogActions>
            <Button
              onClick={() => {
                resetForm()
                onClose()
              }}
            >
              Cancel
            </Button>
            <Button disabled={!isValid} type="submit">
              Save
            </Button>
          </DialogActions>
        </StyledForm>
      )}
    </Formik>
  )
}

type RenameClusterDialogFormValue = {
  clusterName: string
}

const StyledForm = styled.form`
  width: 400px;
`

const StyledDialogTitle = styled(DialogTitle)`
  color: ${({ theme }) => theme.colors.primaryDark[100]};
`
