import { Typography } from '@material-ui/core'
import { Store, configureStore } from '@reduxjs/toolkit'
import { Provider } from 'react-redux'

import { AnalysisStatisticsTable } from 'pages/analysis/AnalysisStatisticsTable'
import {
  selectAnalysisLassos,
  selectAnalysisStatistics,
  selectChannelDisplayNames,
  selectCharts,
  selectClusterById,
} from 'pages/analysis/store/selectors'
import { selectGatesById } from 'pages/analysis/store/selectors/gates.selectors'

import { analysisApi } from 'shared/api/analysis.api'
import { exportApi } from 'shared/api/export.api'
import { privateApi } from 'shared/api/private.api'
import {
  ANALYSIS_HEATMAP_ID,
  ANALYSIS_HIERARCHY_NETWORK_ID,
  ANALYSIS_HIERARCHY_PIE_CHART_ID,
  ANALYSIS_HIERARCHY_SUNBURST_ID,
  ANALYSIS_LASSOS_HIERARCHY_GRAPH_ID,
} from 'shared/constants'
import { reducer } from 'shared/store'
import { globalErrorHandlingMiddleware } from 'shared/store/global-error-handling.middleware'

import { Theme } from 'Theme'

import { generateHtml, showLoadingMask } from '..'
import { renderAnalysisChart } from './renderAnalysisChart'
import { renderHeatmap } from './renderHeatmap'
import { renderLassosHierarchyGraph } from './renderLassosHierarchyGraph'
import { renderNetworkGraph } from './renderNetworkGraph'
import { renderPieChart } from './renderPieChart'
import { renderSunburst } from './renderSunburst'

export const generateAnalysisGraphicalElementsExport = async (
  analysisId: string,
  selectedGraphicalElementIds: string[],
  theme: Theme,
): Promise<void> => {
  const removeLoadingMask = showLoadingMask(
    'Preparing selected graphical elements export...',
  )

  const store = configureStore({
    reducer,
    middleware: getDefaultMiddleware =>
      getDefaultMiddleware({
        serializableCheck: false,
        immutableCheck: false,
      }).concat(privateApi.middleware, globalErrorHandlingMiddleware),
  })

  try {
    await store
      .dispatch(analysisApi.endpoints.getAnalysis.initiate(analysisId))
      .unwrap()

    const markupStrings = await generateMarkupStrings(
      store,
      selectedGraphicalElementIds,
      theme,
    )

    await store.dispatch(
      exportApi.endpoints.exportGraphicalElements.initiate({
        analysisId,
        markupStrings,
      }),
    )
  } catch (error) {
    console.error(error)
  }

  removeLoadingMask()
}

const CHART_WIDTH = 500
const CHART_HEIGHT = 500

const generateMarkupStrings = async (
  store: Store,
  selectedGraphicalElementIds: string[],
  theme: Theme,
): Promise<string[]> => {
  const state = store.getState()
  const charts = selectCharts(state)
  const statistics = selectAnalysisStatistics(state)
  const lassoById = selectAnalysisLassos(state)
  const gateById = selectGatesById(state)
  const clusterById = selectClusterById(state)
  const channelDisplayNameById = selectChannelDisplayNames(state)

  const markupStrings: string[] = []
  for (const elementId of selectedGraphicalElementIds) {
    switch (elementId) {
      case ANALYSIS_HIERARCHY_SUNBURST_ID:
        markupStrings.push(
          await renderSunburst({
            state,
            width: CHART_WIDTH,
            height: CHART_HEIGHT,
          }),
        )
        break

      case ANALYSIS_HIERARCHY_NETWORK_ID:
        markupStrings.push(
          await renderNetworkGraph({
            state,
            width: CHART_WIDTH,
            height: CHART_HEIGHT,
          }),
        )
        break

      case ANALYSIS_HIERARCHY_PIE_CHART_ID:
        markupStrings.push(
          await renderPieChart({
            state,
            width: CHART_WIDTH,
            height: CHART_HEIGHT,
          }),
        )
        break

      case ANALYSIS_HEATMAP_ID:
        markupStrings.push(
          await renderHeatmap({
            state,
            theme,
          }),
        )
        break

      case ANALYSIS_LASSOS_HIERARCHY_GRAPH_ID:
        markupStrings.push(
          (
            await renderLassosHierarchyGraph({
              state,
              theme,
            })
          ).svg,
        )
        break

      default: {
        const statisticsItem = statistics.find(
          statisticsItem => statisticsItem.id === elementId,
        )
        if (statisticsItem) {
          const html = await generateHtml(
            <Provider store={store}>
              <Typography variant="body1" align="center">
                {statisticsItem.name}
              </Typography>
              <AnalysisStatisticsTable
                statisticsItem={statisticsItem}
                clusterById={clusterById}
                gateById={gateById}
                lassoById={lassoById}
                channelDisplayNameById={channelDisplayNameById}
              />
            </Provider>,
          )
          markupStrings.push(html)
          break
        }

        const chart = charts.find(chart => chart.id === elementId)
        if (!chart) {
          throw new Error(`Element with id ${elementId} not found`)
        }
        const svg = (
          await renderAnalysisChart({
            state,
            chart,
            shouldShowAxisLabels: true,
            theme,
          })
        ).svg
        if (svg) {
          markupStrings.push(svg)
        }
      }
    }
  }
  return markupStrings
}
