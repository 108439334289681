import { useState } from 'react'
import styled from 'styled-components'

import { CircularProgress } from 'components/CircularProgress'
import { NoDataFound } from 'components/NoDataFound'
import { OptionsContextMenu } from 'components/OptionsContextMenu'
import { Button } from 'components/button/Button'
import { Modal } from 'components/modal/Modal'
import { ModalContainer } from 'components/modal/ModalContainer'
import { TableColumn, TableRow } from 'components/table/Table'
import { TableWithPagination } from 'components/table/TableWithPagination'

import { useLazyGetExperimentQuery } from 'shared/api/experiments.api'
import {
  useDeleteExportTaskMutation,
  useGetExportTasksQuery,
} from 'shared/api/export.api'
import {
  useLazyGetBrickByIdQuery,
  useLazyGetWorkflowQuery,
} from 'shared/api/workflows.api'
import { useDialog } from 'shared/contexts/DialogContext'
import { useModal } from 'shared/contexts/ModalContext'
import { useAppDispatch } from 'shared/store'
import { showNotification } from 'shared/store/notification.slice'
import { downloadFile, formatDateTime, href } from 'shared/utils/utils'

import { WorkflowExportModal } from './WorkflowExportModal'

type WorkflowExportTasksModalProps = {
  type: 'workflow' | 'experiment'
  workflowId: string
  workflowName: string
  onClose: () => void
}

export const WorkflowExportTasksModal: React.FC<
  WorkflowExportTasksModalProps
> = ({ type, workflowId, workflowName, onClose }) => {
  const dispatch = useAppDispatch()
  const { showModal } = useModal()
  const { showConfirmationDialog } = useDialog()
  const [page, setPage] = useState(1)
  const getExportsQueryState = useGetExportTasksQuery({
    page,
    workflow: workflowId,
  })
  const [triggerDeleteExportTask] = useDeleteExportTaskMutation()
  const [triggerGetExperimentQuery, getExperimentQueryState] =
    useLazyGetExperimentQuery()
  const [triggerGetWorkflowQuery, getWorkflowQueryState] =
    useLazyGetWorkflowQuery()
  const [triggerGetBrickByIdQuery, getBrickByIdQueryState] =
    useLazyGetBrickByIdQuery()

  const areLazyQueriesLoading =
    getExperimentQueryState.isLoading ||
    getBrickByIdQueryState.isLoading ||
    getWorkflowQueryState.isLoading

  const columns: TableColumn[] = [
    {
      key: 'export_type',
      label: 'Export type',
      style: { minWidth: 200 },
    },
    {
      key: 'file_name',
      label: 'File name',
      style: { whiteSpace: 'nowrap' },
    },
    {
      key: 'format',
      label: 'Format',
      style: { width: 0 },
    },
    {
      key: 'created_at',
      label: 'Created at',
      style: { width: 100 },
    },
    {
      key: 'updated_at',
      label: 'Updated at',
      style: { width: 100 },
    },
    {
      key: 'status',
      label: 'Status',
      style: {
        width: 0,
      },
    },
    {
      key: 'actions',
      label: '',
      canSort: false,
      style: {
        width: 0,
      },
    },
  ]

  const rows: TableRow[] = getExportsQueryState.data
    ? getExportsQueryState.data.results.map(exportTask => ({
        key: exportTask.id,
        tooltip: exportTask.is_stale
          ? 'No current version : this export does not contain the latest version of the file'
          : undefined,
        cells: [
          {
            key: 'export_type',
            content: (
              <CellText $isStale={exportTask.is_stale}>
                {exportTask.export_class_display}
              </CellText>
            ),
          },
          {
            key: 'file_name',
            content: (
              <CellText $isStale={exportTask.is_stale}>
                {exportTask.file_name}
              </CellText>
            ),
          },
          {
            key: 'format',
            content: (
              <CellText $isStale={exportTask.is_stale}>
                {exportTask.format}
              </CellText>
            ),
          },
          {
            key: 'created_at',
            content: (
              <CellText $isStale={exportTask.is_stale}>
                {formatDateTime(exportTask.created_at)}
              </CellText>
            ),
          },
          {
            key: 'updated_at',
            content: (
              <CellText $isStale={exportTask.is_stale}>
                {formatDateTime(exportTask.updated_at)}
              </CellText>
            ),
          },
          {
            key: 'status',
            content: (
              <StatusText $status={exportTask.status}>
                <CellText $isStale={exportTask.is_stale}>
                  {exportTask.status_display}
                </CellText>
              </StatusText>
            ),
          },
          {
            key: 'actions',
            content: (
              <OptionsContextMenu
                options={[
                  {
                    label: 'Download',
                    disabled: !exportTask.file_ready,
                    onClick: () => {
                      handleDownloadFile(exportTask.id, exportTask.file_name)
                    },
                  },
                  {
                    label: 'Delete',
                    textColor: 'error',
                    onClick: () => {
                      handleShowDeleteExportConfirmationDialog(exportTask.id)
                    },
                  },
                ]}
              />
            ),
          },
        ],
      }))
    : []

  const handleDownloadFile = (exportTaskId: string, fileName?: string) => {
    downloadFile(href(`/exports/${exportTaskId}/export_file/`), fileName).then(
      () => {
        dispatch(
          showNotification({
            type: 'success',
            description: 'File downloaded successfully',
          }),
        )
      },
      () => {
        dispatch(
          showNotification({
            type: 'error',
            description: 'Cannot download file',
          }),
        )
      },
    )
  }

  const handleShowDeleteExportConfirmationDialog = (exportTaskId: string) => {
    showConfirmationDialog({
      title: 'Delete export task',
      message:
        'Are you sure you want to delete this export task and all generated files?',
      onConfirm: () => handleDeleteExportTask(exportTaskId),
    })
  }

  const handleDeleteExportTask = (exportTaskId: string) => {
    triggerDeleteExportTask({ id: exportTaskId }).then(
      () => {
        dispatch(
          showNotification({
            type: 'success',
            description: 'Export task and file deleted succesfully',
          }),
        )
      },
      () => {
        dispatch(
          showNotification({
            type: 'error',
            description: 'Could not delete export task',
          }),
        )
      },
    )
  }

  const handleShowExperimentExportModal = async (
    experimentId: string,
    type: 'custom' | 'global',
  ) => {
    const experimentDetails =
      await triggerGetExperimentQuery(experimentId).unwrap()
    const brickById = await triggerGetBrickByIdQuery(
      experimentDetails.bricks.map(brick => brick.id),
    ).unwrap()
    showModal(WorkflowExportModal, {
      type,
      brickById,
    })
  }

  const handleShowWorkflowsExportModal = async (
    experimentId: string,
    type: 'custom' | 'global',
  ) => {
    const workflowDetails = await triggerGetWorkflowQuery(experimentId).unwrap()
    const brickById = await triggerGetBrickByIdQuery(
      workflowDetails.bricks.map(brick => brick.id),
    ).unwrap()
    showModal(WorkflowExportModal, {
      type,
      brickById,
    })
  }

  return (
    <Modal
      open
      title={
        <Title>
          <h1>
            Export tasks for {type} {workflowName}
          </h1>
        </Title>
      }
      Container={StyledModalContainer}
      onClose={onClose}
    >
      {getExportsQueryState.isLoading ? (
        <CircularProgress />
      ) : (
        <ScrollWrapper>
          {rows.length > 0 ? (
            <TableWithPagination
              columns={columns}
              rows={rows}
              page={page}
              pageCount={getExportsQueryState.data?.page_count ?? 1}
              onPageChange={(_event, page) => setPage(page)}
            />
          ) : (
            <NoDataFound>No export tasks found</NoDataFound>
          )}
        </ScrollWrapper>
      )}
      <Actions>
        <Button colorOverride="greyscale" onClick={onClose}>
          Close
        </Button>
        <Button
          disabled={areLazyQueriesLoading}
          loading={areLazyQueriesLoading}
          onClick={async () => {
            if (type === 'experiment') {
              await handleShowExperimentExportModal(workflowId, 'global')
            } else {
              await handleShowWorkflowsExportModal(workflowId, 'global')
            }
            onClose()
          }}
        >
          Export batch global statistics
        </Button>
        <Button
          disabled={areLazyQueriesLoading}
          loading={areLazyQueriesLoading}
          onClick={async () => {
            if (type === 'experiment') {
              await handleShowExperimentExportModal(workflowId, 'custom')
            } else {
              await handleShowWorkflowsExportModal(workflowId, 'custom')
            }
            onClose()
          }}
        >
          Export batch custom statistics
        </Button>
      </Actions>
    </Modal>
  )
}

const StyledModalContainer = styled(ModalContainer)`
  align-items: flex-start;
  width: 80vw;
  position: relative;
`

const Title = styled.div`
  h1 {
    font-size: 20px;
    margin: 0;
  }

  h2 {
    font-family: ${props => props.theme.font.style.light};
    font-size: 14px;
    margin-top: 4px;
  }
`

const ScrollWrapper = styled.div`
  width: 100%;
  overflow-y: auto;
  max-height: 60vh;
  text-align: center;
`

const StatusText = styled.p<{ $status?: 'W' | 'P' | 'S' | 'E' }>`
  color: ${props =>
    props.$status === 'E'
      ? props.theme.colors.error
      : props.$status === 'S'
      ? props.theme.colors.success
      : props.theme.colors.primaryDark[100]};
`

const Actions = styled.div`
  align-self: flex-end;
  display: flex;
  gap: 10px;
`

const CellText = styled.p<{ $isStale: boolean }>`
  color: ${props =>
    props.$isStale
      ? props.theme.colors.greyscale[50]
      : props.theme.colors.primaryDark[100]};
`
