import { Formik, FormikConfig } from 'formik'
import { FC, useCallback } from 'react'
import styled from 'styled-components'
import { object, string } from 'yup'

import { Button } from 'components/button/Button'
import Input from 'components/forms/Input'
import { Modal } from 'components/modal/Modal'
import { ModalContainer } from 'components/modal/ModalContainer'

import { useCreateProjectMutation } from 'shared/api/projects.api'

type CreateProjectModalProps = {
  onClose: () => void
}

type CreateProjectFormValues = {
  name: string
  description: string
}

const CreateProjectFormSchema = object({
  name: string().required().max(80, 'Name cannot be longer than 80 characters'),
  description: string().max(
    80,
    'Description cannot be longer than 80 characters',
  ),
})

const initialValues: CreateProjectFormValues = {
  name: '',
  description: '',
}

export const CreateProjectModal: FC<CreateProjectModalProps> = ({
  onClose,
}) => {
  const [createProject] = useCreateProjectMutation()

  const handleSubmit: FormikConfig<CreateProjectFormValues>['onSubmit'] =
    useCallback(
      (values, { setSubmitting }) => {
        createProject({ ...values })
        setSubmitting(false)
        onClose()
      },
      [createProject, onClose],
    )

  return (
    <Modal
      open
      title="Create a new project"
      onClose={onClose}
      Container={StyledModalContainer}
    >
      <Formik<CreateProjectFormValues>
        initialValues={initialValues}
        validationSchema={CreateProjectFormSchema}
        onSubmit={handleSubmit}
      >
        {({
          values,
          touched,
          errors,
          isSubmitting,
          isValid,
          handleChange,
          handleBlur,
          handleSubmit,
        }) => (
          <Form onSubmit={handleSubmit}>
            <>
              <Input
                id="name"
                name="name"
                value={values.name}
                placeholder="Project name"
                onChange={handleChange}
                onBlur={handleBlur}
                type="text"
                autoComplete="off"
                error={touched.name ? errors.name : undefined}
              />
              <TextArea
                id="description"
                name="description"
                value={values.description}
                placeholder="Description"
                onChange={handleChange}
                onBlur={handleBlur}
                type="text-area"
                autoComplete="off"
                error={touched.description ? errors.description : undefined}
              />
            </>
            <Buttons>
              <Button colorOverride="greyscale" onClick={onClose}>
                Cancel
              </Button>
              <Button
                disabled={isSubmitting || !isValid}
                type="submit"
                colorOverride="success"
              >
                Create
              </Button>
            </Buttons>
          </Form>
        )}
      </Formik>
    </Modal>
  )
}

const StyledModalContainer = styled(ModalContainer)`
  width: 700px;
`

const Buttons = styled.div`
  display: flex;
  justify-content: center;
  gap: ${props => props.theme.spacing(1)}px;
  margin-top: ${props => props.theme.spacing(2)}px;
`

const Form = styled.form`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
  height: 100%;
  margin-top: ${props => props.theme.spacing(4)}px;
`

const TextArea = styled(Input)`
  & textarea {
    min-height: 140px;
  }
`
