import { filesize } from 'filesize'
import { useState } from 'react'
import { useBeforeUnload } from 'react-use'
import styled from 'styled-components'

import { ReactComponent as FilesIcon } from 'assets/images/icons/fcs-file.svg'
import { ReactComponent as UploadFileIcon } from 'assets/images/icons/upload-file.svg'

import { CircularProgress } from 'components/CircularProgress'
import { OptionsContextMenu } from 'components/OptionsContextMenu'
import { UserWidget } from 'components/UserWidget'
import { Button } from 'components/button/Button'
import { SearchInput } from 'components/input/SearchInput'
import { EmptyTableInfo } from 'components/table/EmptyTableInfo'
import { TableColumn, TableRow } from 'components/table/Table'
import { TableWithPagination } from 'components/table/TableWithPagination'
import {
  DateFilter,
  DateFilterValue,
} from 'components/table/filters/DateFilter'
import { MultipleSelectFilterWithPagination } from 'components/table/filters/MultipleSelectFilterWithPagination'
import { NumberRangeFilter } from 'components/table/filters/NumberRangeFilter'
import { SelectFilter } from 'components/table/filters/SelectFilter'
import { useMultipleSelectFilter } from 'components/table/filters/useMultipleSelectFilter'
import { useTableSorting } from 'components/table/useTableSorting'
import { UploadBanner } from 'components/upload-banner/UploadBanner'

import {
  FileStatus,
  SpilloverFile,
  useDeleteSpilloverFileMutation,
  useGetSpilloverFileAuthorsQuery,
  useGetSpilloverFileBatchNamesQuery,
  useGetSpilloverFilesQuery,
  useLazyDownloadSpilloverFileQuery,
} from 'shared/api/files.api'
import { useModal } from 'shared/contexts/ModalContext'
import { useEventCallback } from 'shared/hooks/useEventCallback'
import { useAppSelector } from 'shared/store'
import { downloadFile, formatDateTime, includeIf } from 'shared/utils/utils'

import { CompensationMatrixRenameModal } from './CompensationMatrixRenameModal'
import { CompensationMatrixUploadModal } from './CompensationMatrixUploadModal'
import { SectionHeader } from './SectionHeader'
import { StatusChip } from './StatusChip'
import { selectIsAnySpilloverFileBeingUploaded } from './store/ui.selectors'

type CompensationMatrixProps = {
  projectId: string
}

export const CompensationMatrix: React.FC<CompensationMatrixProps> = ({
  projectId,
}) => {
  const isAnySpilloverFileBeingUploaded = useAppSelector(
    selectIsAnySpilloverFileBeingUploaded,
  )

  const [page, setPage] = useState(1)
  const [searchValue, setSearchValue] = useState<string | undefined>(undefined)
  const [dateFilterValue, setDateFilterValue] =
    useState<DateFilterValue>(undefined)
  const [sizeFilterValue, setSizeFilterValue] = useState<
    [number | undefined, number | undefined]
  >([undefined, undefined])
  const [selectedStatus, setSelectedStatus] = useState<
    `${FileStatus}` | undefined
  >()

  const { showModal } = useModal()

  useBeforeUnload(isAnySpilloverFileBeingUploaded)

  const { sortingColumn, sortingDirection, orderingString, setSorting } =
    useTableSorting('created_at', 'desc')

  const {
    selectedIds: selectedBatchNames,
    page: batchNamesFilterPage,
    filterSearchValue: batchNamesFilterSearchValue,
    handleSelectOption: handleSelectBatchName,
    handlePageChange: handleBatchNamesFilterPageChange,
    handleFilterSearchValueChange: handleBatchNamesFilterSearchValueChange,
  } = useMultipleSelectFilter()

  const {
    selectedIds: selectedAuthorIds,
    page: authorsFilterPage,
    filterSearchValue: authorsFilterSearchValue,
    handleSelectOption: handleSelectAuthor,
    handleFilterSearchValueChange: handleAuthorsFilterSearchValueChange,
    handlePageChange: handleAuthorsFilterPageChange,
  } = useMultipleSelectFilter()

  const getSpilloverFilesQueryState = useGetSpilloverFilesQuery({
    projectId,
    ordering: orderingString,
    page,
    search: {
      file_name: searchValue,
      batch_name: selectedBatchNames.join(','),
      author: selectedAuthorIds.join(','),
      created_at_after: dateFilterValue?.[0]?.toISOString(),
      created_at_before: dateFilterValue?.[1]?.toISOString(),
      size_min: sizeFilterValue[0] ? sizeFilterValue[0] * 1000 : undefined,
      size_max: sizeFilterValue[1] ? sizeFilterValue[1] * 1000 : undefined,
      status: selectedStatus,
    },
  })

  const getSpilloverFileBatchNamesQueryState =
    useGetSpilloverFileBatchNamesQuery(
      {
        projectId,
        page: batchNamesFilterPage,
        search: {
          lookup_batch_name: batchNamesFilterSearchValue,
        },
      },
      { refetchOnMountOrArgChange: true },
    )

  const getSpilloverFileAuthorsQueryState = useGetSpilloverFileAuthorsQuery({
    projectId,
    page: authorsFilterPage,
    search: {
      lookup_author: authorsFilterSearchValue,
    },
  })

  const [triggerDownloadSpilloverFileQuery] =
    useLazyDownloadSpilloverFileQuery()
  const [triggerDeleteSpilloverFileMutation] = useDeleteSpilloverFileMutation()

  const handleShowUploadModal = useEventCallback(() => {
    showModal(CompensationMatrixUploadModal, { projectId })
  })

  const handleRename = (file: SpilloverFile) => () => {
    showModal(CompensationMatrixRenameModal, { file })
  }

  const handleDownload = (id: string) => () => {
    triggerDownloadSpilloverFileQuery(id)
      .unwrap()
      .then(file => {
        downloadFile(file.url)
      })
  }

  const handleDelete = (id: string) => () => {
    triggerDeleteSpilloverFileMutation(id)
  }

  const columns: TableColumn[] = [
    {
      key: 'file_name',
      label: 'Matrix Name',
      canSort: true,
    },
    {
      key: 'status',
      label: 'Status',
      style: { width: 0 },
      canSort: true,
    },
    {
      key: 'actions',
      style: { width: 0 },
      label: undefined,
      canSort: false,
    },
    {
      key: 'created_at',
      label: 'Created',
      canSort: true,
    },
    {
      key: 'author__name',
      label: 'Author',
      canSort: true,
    },
    {
      key: 'size',
      label: 'Size',
      canSort: true,
    },
    {
      key: 'batch_name',
      label: 'Batch name',
      canSort: true,
    },
  ]

  const rows: TableRow[] =
    getSpilloverFilesQueryState.data?.results.map(file => ({
      key: file.id,
      cells: [
        { key: 'file_name', content: file.file_name },
        {
          key: 'status',
          content: <StatusChip status={file.status} />,
        },
        {
          key: 'actions',
          content: (
            <OptionsContextMenu
              options={[
                {
                  label: 'Rename',
                  onClick: handleRename(file),
                },
                {
                  label: 'Copy ID',
                  onClick: () =>
                    navigator.clipboard.writeText(`spillover-file:${file.id}`),
                },
                ...includeIf(file.status === FileStatus.Error, [
                  {
                    label: 'Delete',
                    textColor: 'error' as const,
                    onClick: handleDelete(file.id),
                  },
                ]),
                ...includeIf(file.status === FileStatus.Ready, [
                  { label: 'Download', onClick: handleDownload(file.id) },
                ]),
              ]}
            />
          ),
        },
        { key: 'created_at', content: formatDateTime(file.created_at) },
        { key: 'author__name', content: file.author_name },
        { key: 'size', content: filesize(file.size * 1000) },
        { key: 'batch_name', content: file.batch_name },
      ],
    })) ?? []

  return (
    <CompensationMatrixRoot>
      <Header>
        {isAnySpilloverFileBeingUploaded ? <UploadBanner /> : <span />}
        <UserWidget />
      </Header>
      <Main>
        <SectionHeader title="Compensation Matrices">
          <SectionHeaderActionButton
            colorOverride="success"
            onClick={handleShowUploadModal}
          >
            <StyledUploadFileIcon />
            Upload files
          </SectionHeaderActionButton>
        </SectionHeader>
        {getSpilloverFilesQueryState.isLoading ? (
          <CircularProgress />
        ) : (
          <>
            <TableFilters>
              <SearchInput
                placeholder="Search files"
                onChange={setSearchValue}
              />
              <MultipleSelectFilterWithPagination
                title="Batch name"
                placeholder="Search batch names"
                options={
                  getSpilloverFileBatchNamesQueryState.data?.results.map(
                    batchName => ({ id: batchName, label: batchName }),
                  ) ?? []
                }
                selectedIds={selectedBatchNames}
                onSelect={handleSelectBatchName}
                page={batchNamesFilterPage}
                pageCount={
                  getSpilloverFileBatchNamesQueryState.data?.page_count ?? 1
                }
                onPageChange={handleBatchNamesFilterPageChange}
                onSearchValueChange={handleBatchNamesFilterSearchValueChange}
              />
              <DateFilter
                value={dateFilterValue}
                onChange={setDateFilterValue}
              />
              <MultipleSelectFilterWithPagination
                title="Author"
                placeholder="Search authors"
                options={
                  getSpilloverFileAuthorsQueryState.data?.results.map(user => ({
                    id: user.id,
                    label: user.username,
                  })) ?? []
                }
                selectedIds={selectedAuthorIds}
                onSelect={handleSelectAuthor}
                page={authorsFilterPage}
                pageCount={
                  getSpilloverFileAuthorsQueryState.data?.page_count ?? 1
                }
                onPageChange={handleAuthorsFilterPageChange}
                onSearchValueChange={handleAuthorsFilterSearchValueChange}
              />
              <NumberRangeFilter
                title="Size"
                value={sizeFilterValue}
                onChange={setSizeFilterValue}
              />
              <SelectFilter
                title="Status"
                options={Object.keys(FileStatus).map(key => ({
                  id: key,
                  label: key,
                }))}
                selectedId={selectedStatus}
                onSelect={id =>
                  setSelectedStatus(id as `${FileStatus}` | undefined)
                }
              />
            </TableFilters>
            {getSpilloverFilesQueryState.data?.results.length ? (
              <TableWithPagination
                rows={rows}
                columns={columns}
                sortingColumn={sortingColumn}
                sortingDirection={sortingDirection}
                onSortingChange={setSorting}
                page={page}
                onPageChange={(_, newPage) => setPage(newPage)}
                pageCount={getSpilloverFilesQueryState.data.page_count}
              />
            ) : (
              <StyledEmptyTableInfo
                text="No files found"
                Icon={<StyledFilesIcon />}
              />
            )}
          </>
        )}
      </Main>
    </CompensationMatrixRoot>
  )
}

const CompensationMatrixRoot = styled.div`
  margin: 60px 100px;
`

const Header = styled.div`
  display: flex;
  justify-content: space-between;
`

const Main = styled.main``

const SectionHeaderActionButton = styled(Button)`
  height: 48px;
  padding: 0 ${props => props.theme.spacing(2)}px;

  & > span > svg {
    margin-right: ${props => props.theme.spacing(1)}px;
  }
`

const StyledUploadFileIcon = styled(UploadFileIcon)`
  margin-right: 5px;
  width: 1.3em;
  height: 1.3em;
`

const TableFilters = styled.div`
  display: flex;
  align-items: center;
  gap: ${props => props.theme.spacing(2)}px;
  margin: ${props => props.theme.spacing(4)}px 0;
`

const StyledEmptyTableInfo = styled(EmptyTableInfo)`
  margin-top: ${props => props.theme.spacing(8)}px;
`

const StyledFilesIcon = styled(FilesIcon)`
  width: 160px;
  height: 160px;
  stroke: ${props => props.theme.colors.greyscale[20]};
  stroke-width: 0.1;
  & > path {
    fill: ${props => props.theme.colors.greyscale[20]};
  }
`
