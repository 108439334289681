import { useEffect, useMemo, useState } from 'react'
import styled from 'styled-components'

import { useEventCallback } from 'shared/hooks/useEventCallback'
import {
  getSortedChannelLetters,
  groupChannelsByLetter,
} from 'shared/utils/channels.utils'

import {
  ChannelsLetterList,
  ChannelsLetterListChannel,
} from './ChannelsLetterList'

type ChannelsListProps = {
  className?: string
  channels: ChannelsListChannel[]
  selectedChannels: string[]
  excludedChannels?: string[]
  selectable?: boolean
  onSelectedChannelsChange?: (selectedChannels: string[]) => void
  writable?: boolean
  onLabelsChange?: (editedLabels: ChannelsListEditedLabels) => void
  disabled?: boolean
  columns: number
}

type ChannelsListChannel = ChannelsLetterListChannel & { letter: string }

export type ChannelsListEditedLabels = {
  [channel: string]: string
}

export const ChannelsList = (props: ChannelsListProps): JSX.Element => {
  const { groupedChannels, sortedLetters, changeChannelLabel } =
    useChannels(props)

  const lettersWithChannels = useMemo(() => {
    return sortedLetters.map(letter => {
      return [
        letter,
        groupedChannels[letter]?.filter(
          channel => !(props.excludedChannels ?? []).includes(channel.id),
        ) || [],
      ] as const
    })
  }, [groupedChannels, props.excludedChannels, sortedLetters])

  const handleChangeSelectedChannels = useEventCallback(
    (newSelectedChannels: string[]) => {
      props.onSelectedChannelsChange?.(newSelectedChannels)
    },
  )

  return (
    <ChannelsListRoot className={props.className}>
      {lettersWithChannels.map(([letter, letterChannels]) => {
        return (
          letterChannels.length > 0 && (
            <ChannelsLetterList
              key={letter}
              letter={letter}
              channels={letterChannels}
              selectable={props.selectable}
              writable={props.writable}
              selectedChannels={props.selectedChannels}
              onChangeSelectedChannels={handleChangeSelectedChannels}
              onChangeChannelLabel={changeChannelLabel}
              disabled={props.disabled}
              columns={props.columns}
            />
          )
        )
      })}
    </ChannelsListRoot>
  )
}

const useChannels = ({ channels, onLabelsChange }: ChannelsListProps) => {
  const groupedChannels = useMemo(
    () => groupChannelsByLetter(channels),
    [channels],
  )
  const sortedLetters = useMemo(
    () => getSortedChannelLetters(groupedChannels),
    [groupedChannels],
  )

  const { groupedChannelsWithEditedLabels, changeChannelLabel } =
    useWritableLabels(groupedChannels, onLabelsChange)

  return {
    groupedChannels: groupedChannelsWithEditedLabels,
    sortedLetters,
    changeChannelLabel,
  }
}

const useWritableLabels = (
  groupedChannels: ChannelsListGroupedChannels,
  onLabelsChange: ((labels: ChannelsListEditedLabels) => void) | undefined,
) => {
  const [editedLabels, setEditedLabels] = useState<ChannelsListEditedLabels>({})
  const [notifyLabelsChange, setNotifyLabelsChange] = useState(false)

  const groupedChannelsWithEditedLabels = useMemo(() => {
    const newGroupedChannels: ChannelsListGroupedChannels = {}
    for (const [letter, channels] of Object.entries(groupedChannels)) {
      newGroupedChannels[letter] = channels.map(channel => {
        return {
          ...channel,
          label: editedLabels[channel.id] ?? channel.label,
        }
      })
    }
    return newGroupedChannels
  }, [editedLabels, groupedChannels])

  const changeChannelLabel = useEventCallback(
    (channel: string, name: string) => {
      setEditedLabels({ ...editedLabels, [channel]: name })
      setNotifyLabelsChange(true)
    },
  )

  useEffect(() => {
    setEditedLabels({})
  }, [groupedChannels])

  useEffect(() => {
    if (notifyLabelsChange) {
      onLabelsChange?.(editedLabels)
      setNotifyLabelsChange(false)
    }
  }, [editedLabels, notifyLabelsChange, onLabelsChange])

  return {
    groupedChannelsWithEditedLabels,
    changeChannelLabel,
  }
}

type ChannelsListGroupedChannels = {
  [letter: string]: ChannelsListChannel[]
}

const ChannelsListRoot = styled.div`
  height: 100%;
`
