import { Formik, FormikConfig } from 'formik'
import { isEqual } from 'lodash'
import React, { useCallback, useEffect, useMemo } from 'react'
import styled from 'styled-components'
import { object, string } from 'yup'

import { Button } from 'components/Button'
import { Section } from 'components/Section'
import Input from 'components/forms/Input'

import { useUpdateUserProfileMutation } from 'shared/api/user.api'
import { User } from 'shared/models/User'
import { useAppDispatch } from 'shared/store'
import { showNotification } from 'shared/store/notification.slice'
import { handleError } from 'shared/utils/errorHandler'

type UpdateProfileFormProps = {
  user: User
  onProfileUpdate: () => void
}

type UpdateProfileFormValues = {
  firstName: string
  lastName: string
  job?: string
}

const UpdateProfileFormSchema = object({
  firstName: string(),
  lastName: string(),
  job: string(),
})

export const UpdateProfileForm = ({
  user,
  onProfileUpdate,
}: UpdateProfileFormProps): JSX.Element => {
  const dispatch = useAppDispatch()
  const [updateUserProfile, updateUserProfileResult] =
    useUpdateUserProfileMutation()

  const updateProfileInitialValues: UpdateProfileFormValues = useMemo(
    () => ({
      firstName: user.first_name ?? '',
      lastName: user.last_name ?? '',
      job: user.job ?? '',
    }),
    [user],
  )

  const handleUpdateProfileSubmit: FormikConfig<UpdateProfileFormValues>['onSubmit'] =
    useCallback(
      async (values: UpdateProfileFormValues) => {
        try {
          for (const key in values) {
            if (values[key] === '') {
              values[key] = undefined
            }
          }
          await updateUserProfile({ ...values }).unwrap()
          onProfileUpdate()
        } catch (error) {
          handleError(error)
        }
      },
      [onProfileUpdate, updateUserProfile],
    )

  useEffect(() => {
    if (updateUserProfileResult.isSuccess) {
      dispatch(
        showNotification({
          type: 'success',
          description: 'Profile information updated successfully',
          autoHideDuration: 5000,
        }),
      )
    }

    if (updateUserProfileResult.error) {
      dispatch(
        showNotification({
          type: 'error',
          description:
            'Something went wrong when updating the profile. Try again.',
        }),
      )
    }
  }, [dispatch, updateUserProfileResult])

  return (
    <Formik<UpdateProfileFormValues>
      enableReinitialize
      initialValues={updateProfileInitialValues}
      validationSchema={UpdateProfileFormSchema}
      onSubmit={handleUpdateProfileSubmit}
    >
      {({
        values,
        touched,
        errors,
        isSubmitting,
        isValid,
        initialValues,
        handleChange,
        handleBlur,
        handleSubmit,
        handleReset,
      }) => (
        <form onSubmit={handleSubmit} onReset={handleReset}>
          <Section title="Personal details">
            <Input
              name="firstName"
              label="First name"
              value={values.firstName}
              error={touched.firstName ? errors.firstName : undefined}
              onChange={handleChange}
              onBlur={handleBlur}
            />
            <Input
              name="lastName"
              label="Last name"
              value={values.lastName}
              error={touched.lastName ? errors.lastName : undefined}
              onChange={handleChange}
              onBlur={handleBlur}
            />
            <Input
              name="job"
              label="Job"
              value={values.job ?? ''}
              error={touched.job ? errors.job : undefined}
              onChange={handleChange}
              onBlur={handleBlur}
            />
            <ButtonContainer>
              <Button type="reset" grey>
                Reset form
              </Button>
              <Button
                type="submit"
                disabled={
                  isSubmitting || !isValid || isEqual(values, initialValues)
                }
              >
                Update profile
              </Button>
            </ButtonContainer>
          </Section>
        </form>
      )}
    </Formik>
  )
}

const ButtonContainer = styled.div`
  display: flex;
  justify-content: center;
  gap: 8px;
  margin: 16px 0;
`
