import { CircularProgress } from '@material-ui/core'
import { FC } from 'react'
import styled from 'styled-components'

import { Button } from 'components/button/Button'
import { Table, TableColumn, TableRow } from 'components/table/Table'

import {
  Pipeline,
  useDeletePipelineMutation,
  useGetPipelinesQuery,
} from 'shared/api/pipelines.api'
import { useDialog } from 'shared/contexts/DialogContext'
import { useModal } from 'shared/contexts/ModalContext'
import { formatDate, formatDateTime } from 'shared/utils/utils'

import { CreateExperimentWizard } from './CreateExperimentWizard'
import { PipelinePreviewModal } from './PipelinePreviewModal'
import { PipelineReferenceModal } from './PipelineReferenceModal'
import { PipelineSettingsModal } from './PipelineSettingsModal'
import { StatusChip } from './StatusChip'

type PipelinesInnerTableProps = {
  projectId: string
  workflowId: string
  columns: TableColumn[]
}

export const PipelinesInnerTable: FC<PipelinesInnerTableProps> = ({
  projectId,
  workflowId,
  columns,
}) => {
  const { showModal } = useModal()
  const { showConfirmationDialog } = useDialog()

  const getPipelinesInWorkflowQueryState = useGetPipelinesQuery({
    projectId,
    workflowId,
    page: 1,
  })
  const [triggerDeletePipelineMutation] = useDeletePipelineMutation()

  const handleDeletePipeline = (pipeline: Pipeline) => {
    showConfirmationDialog({
      title: 'Delete pipeline',
      message: `Are you sure you want to delete the pipeline "${pipeline.name}"?`,
      onConfirm: () => {
        triggerDeletePipelineMutation(pipeline.id)
      },
    })
  }

  const rows: TableRow[] =
    getPipelinesInWorkflowQueryState.data?.results.map(pipeline => ({
      key: pipeline.id,
      cells: [
        {
          key: 'offset',
          content: '',
        },
        {
          key: 'name',
          content: pipeline.name,
        },
        {
          key: 'status',
          content: <StatusChip status={pipeline.status} />,
        },
        {
          key: 'actions',
          content: (
            <PipelineActions>
              <ActionButton
                onClick={() =>
                  showModal(PipelinePreviewModal, { pipelineId: pipeline.id })
                }
              >
                View pipeline
              </ActionButton>
              <ActionButton
                onClick={() =>
                  showModal(PipelineReferenceModal, { pipelineId: pipeline.id })
                }
              >
                Reference files
              </ActionButton>
              <ActionButton
                onClick={() => showModal(PipelineSettingsModal, { pipeline })}
              >
                Description
              </ActionButton>
              <ActionButton
                onClick={() =>
                  showModal(CreateExperimentWizard, {
                    projectId: projectId,
                    pipelineId: pipeline.id,
                  })
                }
              >
                Create experiment
              </ActionButton>
              <ActionButton
                colorOverride="error"
                onClick={() => handleDeletePipeline(pipeline)}
              >
                Delete
              </ActionButton>
            </PipelineActions>
          ),
        },
        {
          key: 'created_at',
          content: formatDate(pipeline.created_at),
        },
        {
          key: 'version',
          content: formatDateTime(pipeline.version),
        },
        {
          key: 'author',
          content: pipeline.author_name,
        },
        {
          key: 'description',
          content: (
            <PipelineDescription>{pipeline.description}</PipelineDescription>
          ),
        },
      ],
    })) ?? []

  return (
    <>
      {getPipelinesInWorkflowQueryState.isLoading ? (
        <ProgressContainer>
          <CircularProgress />
        </ProgressContainer>
      ) : (
        <StyledTable
          rows={rows}
          columns={[{ key: 'offset', style: { width: 80 } }, ...columns]}
          showHeader={false}
        />
      )}
    </>
  )
}

const StyledTable = styled(Table)`
  border-spacing: 0;
  border-collapse: separate;
  border-top: 20px solid ${props => props.theme.colors.greyscale[5]};

  & .MuiTableRow-root {
    box-shadow: none;
    background-color: ${props => props.theme.colors.greyscale[5]};

    & .MuiTableCell-body {
      background-color: ${props => props.theme.colors.greyscale[5]};
      border-radius: 0;
    }

    &:last-of-type {
      box-shadow: 0px 4px 10px 0px #e8e9f399;

      & .MuiTableCell-body {
        &:first-of-type {
          border-bottom-left-radius: ${props => props.theme.radius[2]}px;
        }
        &:last-of-type {
          border-bottom-right-radius: ${props => props.theme.radius[2]}px;
        }
      }
    }
  }
`

const PipelineActions = styled.div`
  display: flex;
  gap: ${props => props.theme.spacing(1)}px;
`

export const PipelineDescription = styled.div`
  height: 54px;
  padding: ${props => props.theme.spacing(1)}px;
  background: ${props => props.theme.colors.white};
  border-radius: ${props => props.theme.radius[2]}px;
  font-family: ${props => props.theme.font.style.regular};
  overflow-y: auto;
`

const ProgressContainer = styled.div`
  width: calc(100vw - 480px);
  height: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: ${props => props.theme.colors.greyscale[5]};
  border-bottom-left-radius: ${props => props.theme.radius[2]}px;
  border-bottom-right-radius: ${props => props.theme.radius[2]}px;
`

const ActionButton = styled(Button)`
  font-size: 12px;
  padding: 4px 6px;
`
