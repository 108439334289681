import { useEffect, useRef } from 'react'

// eslint-disable-next-line
const noop = () => {}

const requestTimeout = (
  fn: (args?: unknown) => void,
  delay: number,
  registerCancel: (fn: () => void) => () => void,
) => {
  const start = new Date().getTime()

  const loop = () => {
    const delta = new Date().getTime() - start

    if (delta >= delay) {
      fn()
      registerCancel(noop)
      return
    }

    const raf = requestAnimationFrame(loop)
    registerCancel(() => cancelAnimationFrame(raf))
  }

  const raf = requestAnimationFrame(loop)
  registerCancel(() => cancelAnimationFrame(raf))
}

type CancelableScheduledWork = [(fn: () => void) => () => void, () => void]

const useCancelableScheduledWork = (): CancelableScheduledWork => {
  const cancelCallback = useRef(noop)
  const registerCancel = (fn: () => void) => (cancelCallback.current = fn)
  const cancelScheduledWork = () => cancelCallback.current()

  // Cancels the current sheduled work before the "unmount"
  useEffect(() => {
    return cancelScheduledWork
  }, [])

  return [registerCancel, cancelScheduledWork]
}

type OnClickPreventionArgs = {
  onClick(args?: unknown): void
  onDoubleClick(args?: unknown): void
  delay?: number
}

type OnClickPrevention = [(args?: unknown) => void, (args?: unknown) => void]

export const useClickPrevention = ({
  onClick,
  onDoubleClick,
  delay = 300,
}: OnClickPreventionArgs): OnClickPrevention => {
  const [registerCancel, cancelScheduledRaf] = useCancelableScheduledWork()

  const handleClick = (args?: unknown) => {
    cancelScheduledRaf()
    requestTimeout(() => onClick(args), delay, registerCancel)
  }

  const handleDoubleClick = (args?: unknown) => {
    cancelScheduledRaf()
    onDoubleClick(args)
  }

  return [handleClick, handleDoubleClick]
}
