import { CircularProgress } from '@material-ui/core'
import React from 'react'
import styled, { css } from 'styled-components'

import { useAnalysisStatuses } from 'pages/analysis/useAnalysisStatuses'

import { MetaAnalysisStatus } from 'shared/api/meta-analysis.api'
import { AnalysisStatus } from 'shared/models/AnalysisModels'

import { AppTheme } from 'Theme'

type Status = AnalysisStatus | MetaAnalysisStatus

type StatusDotsProps = Readonly<{
  status: Status
  singleLine?: boolean
  disabled?: boolean
  otherText?: string
}>

type StatusStyle = {
  value: number
  variant: 'determinate' | 'indeterminate'
}

export const StatusDots = ({
  status,
  singleLine,
  disabled,
  otherText,
}: StatusDotsProps): JSX.Element => {
  const [firstLoader, secondLoader] = getLoaderProps(status)
  const { translations } = useAnalysisStatuses()

  return (
    <StatusDotsRoot
      $status={status}
      $singleLine={singleLine}
      $disabled={disabled}
    >
      <Progress>
        <FirstLoader
          $status={status}
          size="10"
          value={firstLoader.value}
          variant={firstLoader.variant}
        />
        <SecondLoader
          $status={status}
          size="10"
          value={secondLoader.value}
          variant={secondLoader.variant}
        />
      </Progress>
      <span>{disabled ? otherText : translations?.[status] ?? status}</span>
    </StatusDotsRoot>
  )
}

function getLoaderProps(status: Status) {
  const loadingStyle: StatusStyle = {
    value: 100,
    variant: 'indeterminate',
  }
  const fixedStyle: StatusStyle = { value: 0, variant: 'determinate' }

  if (
    (
      [
        'New',
        'Processing',
        'Clustering',
        'Transfering_source',
        'Transfering_target',
        'Comparing',
        'Calculating_statistics',
      ] as Status[]
    ).includes(status)
  ) {
    return [loadingStyle, fixedStyle]
  }

  if (
    (
      [
        'Analysed',
        'Approved',
        'Clustered',
        'Done',
        'Error',
        'Open',
        'Reviewed',
        'Transfered',
      ] as Status[]
    ).includes(status)
  ) {
    return [fixedStyle, fixedStyle]
  }

  throw new Error(`Unhandled status: ${status}`)
}

const getColor = (status: Status, theme: AppTheme) => {
  if (
    (
      [
        'New',
        'Processing',
        'Clustering',
        'Transfering_source',
        'Transfering_target',
        'Comparing',
        'Calculating_statistics',
      ] as Status[]
    ).includes(status)
  ) {
    return {
      background: ['transparent', theme.colors.primaryDark[20]],
      text: theme.colors.warning,
    }
  }

  if ((['Clustered', 'Transfered'] as Status[]).includes(status)) {
    return {
      background: [theme.colors.info, theme.colors.primaryDark[20]],
      text: theme.colors.info,
    }
  }

  if (
    (['Open', 'Analysed', 'Approved', 'Done', 'Reviewed'] as Status[]).includes(
      status,
    )
  ) {
    return {
      background: [theme.colors.info, theme.colors.success],
      text: theme.colors.success,
    }
  }

  if ((['Error'] as Status[]).includes(status)) {
    return {
      background: [theme.colors.error, theme.colors.primaryDark[20]],
      text: theme.colors.error,
    }
  }

  throw new Error(`Unhandled status: ${status}`)
}

const StatusDotsRoot = styled.div<{
  $status: Status
  $singleLine: boolean | undefined
  $disabled: boolean | undefined
}>`
  font-size: ${({ theme }) => theme.font.size.smallest}px;
  font-family: ${({ theme }) => theme.font.style.bold};
  color: ${({ $status, theme }) => getColor($status, theme).text};
  text-align: center;
  filter: ${({ $disabled }) => $disabled && 'grayscale(1)'};

  ${({ $singleLine }) =>
    $singleLine &&
    css`
      display: flex;
      flex-direction: row-reverse;
      align-items: center;

      ${Progress} {
        margin-bottom: 0;
        margin-left: 5px;
      }
    `}
`

const Progress = styled.div`
  display: flex;
  justify-content: center;
  margin-bottom: 5px;
`

const Loader = styled(CircularProgress)<{
  $status: Status
}>`
  width: 10px;
  height: 10px;
  margin: 0 2px;
  border-radius: 50%;
  background-color: ${({ theme }) => theme.colors.primaryDark[20]};
  color: ${({ $status, theme }) => getColor($status, theme).text};
`

const FirstLoader = styled(Loader)`
  background-color: ${({ $status, theme }) =>
    getColor($status, theme).background[0]};
`

const SecondLoader = styled(Loader)`
  background-color: ${({ $status, theme }) =>
    getColor($status, theme).background[1]};
`
