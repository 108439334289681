import { RadioGroup } from '@material-ui/core'
import styled from 'styled-components'

import { Checkbox } from 'components/Checkbox'
import { CircularProgress } from 'components/CircularProgress'
import RadioButton from 'components/RadioButton'
import { Button } from 'components/button/Button'
import { Modal } from 'components/modal/Modal'
import { ModalContainer } from 'components/modal/ModalContainer'

import { useGetCompensatedFileByIdQuery } from 'shared/api/files.api'
import { MetaAnalysis } from 'shared/api/meta-analysis.api'
import { useGetBrickByIdQuery } from 'shared/api/workflows.api'

import { WizardSectionCard } from './WizardSectionCard'

type MetaAnalysisDetailsModalProps = {
  metaAnalysis: MetaAnalysis
  onClose: () => void
}

export const MetaAnalysisDetailsModal: React.FC<
  MetaAnalysisDetailsModalProps
> = ({ metaAnalysis, onClose }) => {
  const compensatedFileByIdQuery = useGetCompensatedFileByIdQuery(
    metaAnalysis.analyses.map(analysis => analysis.compensated_file),
  )
  const brickByIdQuery = useGetBrickByIdQuery(
    metaAnalysis.analyses.map(analysis => analysis.brick),
  )

  const analysisIds = metaAnalysis.analyses.map(analysis => analysis.id)

  return (
    <Modal
      open
      title="Meta-analysis settings"
      Container={StyledModalContainer}
      onClose={onClose}
    >
      {compensatedFileByIdQuery.isLoading || brickByIdQuery.isLoading ? (
        <CircularProgress />
      ) : (
        <ScrollContainer>
          <WizardSectionCard title="Name">
            {metaAnalysis.name}
          </WizardSectionCard>
          <WizardSectionCard title="Description">
            {metaAnalysis.description}
          </WizardSectionCard>
          <WizardSectionCard title="Dimensionality reduction">
            <RadioGroup row value={metaAnalysis.compute_global_viz ?? 'none'}>
              <RadioButton value="none" label="None" />
              <RadioButton value="tsne" label="t-SNE" />
              <RadioButton value="umap" label="UMAP" />
            </RadioGroup>
          </WizardSectionCard>
          <WizardSectionCard title="Additional options">
            <Checkbox
              label="Batch effect removal"
              checked={metaAnalysis.apply_normalization}
            />
            <Checkbox
              label="Groups definition"
              checked={metaAnalysis.search_groups}
            />
          </WizardSectionCard>
          <WizardSectionCard title="Groups">
            {Object.values(compensatedFileByIdQuery.data ?? {}).map(
              compensatedFile => (
                <div>
                  <Bold>{compensatedFile.fcs_file_name} • </Bold>
                  {metaAnalysis.groups[compensatedFile.fcs_file]}
                </div>
              ),
            )}
          </WizardSectionCard>
          <WizardSectionCard title="Steps">
            {Object.values(brickByIdQuery.data ?? {}).map(brick => (
              <div>
                <Bold>{brick.name} • </Bold>
                {brick.analyses
                  .filter(analysis => analysisIds.includes(analysis.id))
                  .map(analysis => analysis.name)
                  .join(' • ')}
              </div>
            ))}
          </WizardSectionCard>
        </ScrollContainer>
      )}
      <Actions>
        <Button onClick={onClose}>Close</Button>
      </Actions>
    </Modal>
  )
}

const StyledModalContainer = styled(ModalContainer)`
  max-height: 90vh;
  max-width: 1000px;
`

const ScrollContainer = styled.div`
  overflow-y: auto;
`

const Bold = styled.span`
  font-family: ${props => props.theme.font.style.bold};
`

const Actions = styled.div`
  margin-top: 24px;
`
