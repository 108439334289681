import { useState } from 'react'
import styled from 'styled-components'

import { Button } from 'components/button/Button'
import { Modal } from 'components/modal/Modal'
import { ModalContainer } from 'components/modal/ModalContainer'
import { TextField } from 'components/text-field/TextField'

import { BrickDetails, useUpdateBrickMutation } from 'shared/api/workflows.api'
import { useEventCallback } from 'shared/hooks/useEventCallback'

type RenameBrickModalProps = {
  brick: BrickDetails
  onClose: () => void
}

export const RenameBrickModal: React.FC<RenameBrickModalProps> = ({
  brick,
  onClose,
}) => {
  const [triggerUpdateBrickMutation, updateBrickMutationState] =
    useUpdateBrickMutation()
  const [name, setName] = useState<string>(brick.name)

  const handleSave = useEventCallback(() => {
    if (!name) return

    triggerUpdateBrickMutation({
      id: brick.id,
      name,
    })
      .unwrap()
      .then(() => onClose())
  })

  return (
    <Modal
      open
      title="Rename step"
      Container={StyledModalContainer}
      onClose={onClose}
    >
      <TextField
        label="Step name"
        value={name}
        placeholder="Enter step name"
        onChange={e => setName(e.target.value)}
        fullWidth
      />
      <ButtonsContainer>
        <Button colorOverride="greyscale" onClick={onClose}>
          Cancel
        </Button>
        <Button
          loading={updateBrickMutationState.isLoading}
          onClick={handleSave}
        >
          Save
        </Button>
      </ButtonsContainer>
    </Modal>
  )
}

const StyledModalContainer = styled(ModalContainer)`
  display: flex;
  flex-direction: column;
  gap: ${props => props.theme.spacing(4)}px;
  align-items: center;
`

const ButtonsContainer = styled.div`
  display: flex;
  justify-content: center;
  gap: ${props => props.theme.spacing(1)}px;
`
