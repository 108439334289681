export const AnalysisHistogramBaseOptions = {
  chart: {
    type: 'column',
  },
  boost: {
    enabled: true,
    seriesThreshold: 1,
  },
  plotOptions: {
    column: {
      grouping: false,
    },
    series: {
      boostThreshold: 1,
    },
  },
  yAxis: {
    title: {
      text: 'Count',
    },
  },
  title: {
    text: undefined,
  },
}
