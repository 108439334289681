import { SelectEventObject } from 'highcharts'
import { useCallback, useMemo } from 'react'
import { renderToStaticMarkup } from 'react-dom/server'
import {
  ThemeProvider as StyledComponentsThemeProvider,
  useTheme,
} from 'styled-components'

import { ChartInfo } from 'components/ChartInfo'

import { zoomChart } from 'pages/analysis/store/analysis.history.slice'
import { selectClusterDotSizes } from 'pages/analysis/store/selectors'

import { useAsyncMemo } from 'shared/hooks/useAsyncMemo'
import { Graph } from 'shared/models/Graphs'
import { RootState, useAppDispatch, useAppSelector } from 'shared/store'
import { analysisWorker } from 'shared/worker'

import { AnalysisChartContainer } from './AnalysisChartContainer'
import { createAnalysisMetaCleanChartBaseOptions } from './AnalysisMetaCleanChartBaseOptions'
import { AnalysisMetaCleanChartTooltip } from './AnalysisMetaCleanChartTooltip'
import { Chart } from './Chart'

type AnalysisMetaCleanChartProps = {
  chart: Graph
}

export const AnalysisMetaCleanChart: React.FC<AnalysisMetaCleanChartProps> = ({
  chart,
}) => {
  const theme = useTheme()

  const dispatch = useAppDispatch()

  const clusterDotSizes = useAppSelector(
    (state: RootState) => selectClusterDotSizes(state)[chart.id],
  )

  const seriesResult = useAsyncMemo(
    useCallback(() => {
      return analysisWorker.computeMetaCleanSeries()
    }, []),
  )

  const options = useMemo((): Highcharts.Options => {
    const series = seriesResult.value?.series?.map(series => {
      return {
        ...series,
        marker: {
          radius: (series.id ? clusterDotSizes?.[series.id] : undefined) ?? 1,
        },
      }
    })

    const formatTooltip = function (this) {
      return renderToStaticMarkup(
        <StyledComponentsThemeProvider theme={theme}>
          <AnalysisMetaCleanChartTooltip
            name={this.series.options.custom?.displayName || this.series.name}
            color={this.point.color}
            seriesName={this.series.name}
            x={this.x}
            y={this.y}
          />
        </StyledComponentsThemeProvider>,
      )
    }

    const handleZoom = (e: SelectEventObject) => {
      dispatch(
        zoomChart({
          chartId: chart.id,
          zoom: {
            x_min: e.xAxis[0].min,
            x_max: e.xAxis[0].max,
            y_min: e.yAxis[0].min,
            y_max: e.yAxis[0].max,
          },
        }),
      )
      return false
    }

    const AnalysisMetaCleanChartBaseOptions =
      createAnalysisMetaCleanChartBaseOptions(series)

    return {
      ...AnalysisMetaCleanChartBaseOptions,
      chart: {
        ...AnalysisMetaCleanChartBaseOptions.chart,
        zooming: { type: 'xy' },
        events: {
          selection: handleZoom,
        },
      },
      series,
      xAxis: {
        ...AnalysisMetaCleanChartBaseOptions.xAxis,
        min: chart.zoom?.x_min ?? seriesResult.value?.scale.xAxis.min,
        max: chart.zoom?.x_max ?? seriesResult.value?.scale.xAxis.max,
        title: {
          ...AnalysisMetaCleanChartBaseOptions.xAxis?.title,
          text: chart.x_axis,
        },
      },
      yAxis: {
        ...AnalysisMetaCleanChartBaseOptions.yAxis,
        min: chart.zoom?.y_min ?? seriesResult.value?.scale.yAxis.min,
        max: chart.zoom?.y_max ?? seriesResult.value?.scale.yAxis.max,
        title: {
          ...AnalysisMetaCleanChartBaseOptions.yAxis?.title,
          text: chart.y_axis,
        },
      },
      tooltip: {
        formatter: formatTooltip,
      },
    }
  }, [
    chart.id,
    chart.x_axis,
    chart.y_axis,
    chart.zoom?.x_max,
    chart.zoom?.x_min,
    chart.zoom?.y_max,
    chart.zoom?.y_min,
    clusterDotSizes,
    dispatch,
    seriesResult.value?.scale.xAxis.max,
    seriesResult.value?.scale.xAxis.min,
    seriesResult.value?.scale.yAxis.max,
    seriesResult.value?.scale.yAxis.min,
    seriesResult.value?.series,
    theme,
  ])

  if (seriesResult.isError) {
    return (
      <AnalysisChartContainer chart={chart}>
        <ChartInfo
          title={'No data to display'}
          description={
            'MetaClean data was not calculated, because the total number of events is too low.'
          }
        />
      </AnalysisChartContainer>
    )
  }

  return (
    <AnalysisChartContainer chart={chart}>
      <Chart immutable options={options} />
    </AnalysisChartContainer>
  )
}
