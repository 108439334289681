import { LogActions } from 'shared/contexts/LogContext'
import { LogEntry } from 'shared/models/Logs'
import { PaginatedResults } from 'shared/models/Result'
import { toQueryString } from 'shared/utils/utils'

import { privateApi } from './private.api'
import { encodeTagParameters } from './utils'

export const logbookApi = privateApi.injectEndpoints({
  endpoints: builder => ({
    getEntries: builder.query<GetEntriesResponse, GetEntriesPayload>({
      query: payload =>
        `/logbook-entries/?${toQueryString({
          analysis_id: payload.analysisId,
          page: payload.page,
        })}`,
      providesTags: [
        { type: 'Logbook', id: encodeTagParameters({ id: 'list' }) },
      ],
    }),
    createEntries: builder.mutation<void, CreateEntriesPayload>({
      query: payload => ({
        url: '/logbook-entries/create_entries/',
        method: 'POST',
        body: {
          analysis_id: payload.analysisId,
          actions: payload.entries,
        },
      }),
    }),
    archiveEntries: builder.mutation<void, ArchiveEntriesPayload>({
      query: payload => ({
        url: '/logbook-entries/archive/',
        method: 'POST',
        body: {
          analysis_id: payload.analysisId,
          archived_entries_id: payload.entryIds,
        },
      }),
    }),
    endSession: builder.mutation<void, EndSessionPayload>({
      query: payload => ({
        url: `/logbook-session/${payload.sessionId}/end_session/`,
        method: 'POST',
      }),
    }),
  }),
})

export const {
  useGetEntriesQuery,
  useCreateEntriesMutation,
  useArchiveEntriesMutation,
  useEndSessionMutation,
} = logbookApi

type GetEntriesPayload = {
  analysisId: string
  page: number
}

type GetEntriesResponse = PaginatedResults<LogEntry[]>

type CreateEntriesPayload = {
  analysisId: string
  entries: {
    type: LogActions
    created_at: string
  }[]
}

type ArchiveEntriesPayload = {
  analysisId: string
  entryIds: string[]
}

type EndSessionPayload = {
  sessionId: string
}
