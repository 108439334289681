import HighchartsReact from 'highcharts-react-official'
import { useCallback, useEffect, useRef } from 'react'
import { useTheme } from 'styled-components'

import { Graph } from 'shared/models/Graphs'

import { drawGates } from './drawGates'

type UseGatesProps = {
  chartRef: React.RefObject<HighchartsReact.RefObject>
  gates: Graph.Gate[]
  zoom: Graph['zoom']
}

export const useDrawGates = ({
  chartRef,
  gates,
  zoom,
}: UseGatesProps): (() => void) => {
  const theme = useTheme()
  const cleanupRef = useRef<() => void>()

  const refresh = useCallback(() => {
    cleanupRef.current?.()
    if (chartRef.current?.chart) {
      cleanupRef.current = drawGates(chartRef.current.chart, gates, zoom, theme)
    }
  }, [chartRef, gates, theme, zoom])

  useEffect(() => {
    refresh()
  }, [refresh])

  return refresh
}
