import { renderToStaticMarkup } from 'react-dom/server'

export const MetaAnalysisGlobalHeatMapChartBaseOptions = {
  chart: {
    type: 'bar',
    animation: false,
  },
  xAxis: {
    title: {
      text: null,
    },
    labels: {
      width: 20,
      useHTML: true,
      formatter: function (
        this: Highcharts.AxisLabelsFormatterContextObject,
      ): string {
        const fileName = this.value as string
        return renderToStaticMarkup(<span title={fileName}>{fileName}</span>)
      },
      style: {
        textOverflow: 'ellipsis',
        fontSize: '12px',
      },
    },
    lineWidth: 0,
  },
  yAxis: {
    title: { text: null },
  },
  plotOptions: {
    series: {
      animation: false,
      stacking: 'normal',
      dataLabels: {
        enabled: false,
      },
    },
  } as Highcharts.PlotOptions,
  tooltip: {
    enabled: true,
    useHTML: true,
  },
  legend: {
    enabled: true,
  },
  title: {
    text: undefined,
  },
}
