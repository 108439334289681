import React from 'react'
import styled from 'styled-components'

import { Checkbox } from 'components/Checkbox'

import { SignatureType } from 'shared/models/Signature'

import { theme } from 'Theme'

type SignatureCreateProps = {
  type: SignatureType
  disabled?: boolean
  disabledTooltipLabel?: string
  onClick: (type: SignatureType) => void
}

export const SignatureCreate = ({
  type,
  disabledTooltipLabel = 'You cannot sign this analysis.',
  disabled,
  onClick,
}: SignatureCreateProps): JSX.Element => {
  return (
    <Wrapper>
      <Checkbox
        onClick={() => onClick(type)}
        checked={false}
        labelFontSize={24}
        disabled={disabled}
        disabledColor={theme.colors.greyscale[70]}
      />
      {disabled && <ToolTip>{disabledTooltipLabel}</ToolTip>}
    </Wrapper>
  )
}

const Wrapper = styled.div`
  position: relative;
  margin: auto;
  text-align: center;
  &:hover span {
    opacity: 1;
  }
`

const ToolTip = styled.span`
  display: block;
  padding: 3px 6px;
  bottom: -200%;
  left: 50%;
  min-width: 250px;
  border-radius: ${props => props.theme.radius[1]}px;
  color: ${props => props.theme.colors.white};
  background-color: ${props => props.theme.colors.primaryDark[100]};
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  position: absolute;
  transform: translateX(-50%);
  cursor: default;
  opacity: 0;
  transition: opacity 200ms ease;
  user-select: none;
`
