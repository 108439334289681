import { CircularProgress } from '@material-ui/core'
import styled from 'styled-components'

import { Card } from 'components/card/Card'

export const UploadBanner: React.FC = () => {
  return (
    <UploadBannerRoot>
      <FirstLine>
        <StyledCircularProgress size={16} />
        <span>Upload in progress</span>
      </FirstLine>
      <SecondLine>Please do not close or refresh the app!</SecondLine>
    </UploadBannerRoot>
  )
}

const UploadBannerRoot = styled(Card)`
  padding: 20px;
  display: flex;
  flex-direction: column;
  gap: 8px;
`

const FirstLine = styled.div`
  color: ${({ theme }) => theme.colors.success};
  font-size: 14px;
  font-family: ${({ theme }) => theme.font.style.bold};
  display: flex;
  align-items: center;
  gap: 6px;
`

const SecondLine = styled.div`
  font-size: 12px;
`

const StyledCircularProgress = styled(CircularProgress)`
  color: ${({ theme }) => theme.colors.success};
`
