import { uniq } from 'lodash'

import {
  AnalysisStatus,
  Lasso,
  MessagesForUser,
} from 'shared/models/AnalysisModels'
import { PaginatedResults } from 'shared/models/Result'

import { CompensatedFile, FcsFileChannel } from './files.api'
import { privateApi } from './private.api'
import { encodeTagParameters } from './utils'

export const workflowsApi = privateApi.injectEndpoints({
  endpoints: builder => ({
    preCreateWorkflow: builder.mutation<
      PreCreateWorkflowResult,
      PreCreateWorkflowPayload
    >({
      query: payload => ({
        url: `/workflows/pre_create/`,
        method: 'POST',
        body: payload,
      }),
    }),
    createWorkflow: builder.mutation<unknown, CreateWorkflowPayload>({
      query: payload => ({
        url: `/workflows/`,
        method: 'POST',
        body: payload,
      }),
    }),
    getWorkflows: builder.query<GetWorkflowsResult, GetWorkflowsPayload>({
      query: ({ projectId, page, ordering, search }) => ({
        url: `/workflows/`,
        params: {
          project: projectId,
          page,
          ordering,
          is_active: 'true',
          ...search,
        },
      }),
      providesTags: [
        { type: 'Workflow', id: encodeTagParameters({ id: 'list' }) },
      ],
    }),
    getWorkflow: builder.query<WorkflowDetails, string>({
      query: workflowId => `/workflows/${workflowId}/`,
      providesTags: (_result, _error, workflowId) => [
        { type: 'Workflow', id: encodeTagParameters({ id: workflowId }) },
      ],
    }),
    updateWorkflow: builder.mutation<unknown, UpdateWorkflowPayload>({
      query: ({ id, ...payload }) => ({
        url: `/workflows/${id}/`,
        method: 'PATCH',
        body: payload,
      }),
    }),
    deleteWorkflow: builder.mutation<unknown, DeleteWorkflowPayload>({
      query: payload => ({
        url: `/workflows/${payload.workflowId}/`,
        method: 'DELETE',
        body: {
          remove_pipelines: payload.deletePipelines,
        },
      }),
    }),
    preCreateBuildBrickSettings: builder.mutation<
      PreCreateBuildBrickSettingsResult,
      PreCreateBuildBrickSettingsPayload
    >({
      query: payload => ({
        url: `/bricks/pre_create_build_settings/`,
        method: 'POST',
        body: payload,
      }),
    }),
    preCreateAdjustBrickSettings: builder.mutation<
      PreCreateAdjustBrickSettingsResult,
      PreCreateAdjustBrickSettingsPayload
    >({
      query: payload => ({
        url: `/bricks/pre_create_adjust_settings/`,
        method: 'POST',
        body: payload,
      }),
    }),
    createBrick: builder.mutation<unknown, CreateBrickPayload>({
      query: payload => ({
        url: `/bricks/`,
        method: 'POST',
        body: payload,
      }),
    }),
    getBrick: builder.query<BrickDetails, string>({
      query: brickId => `/bricks/${brickId}/`,
      providesTags: (_result, _error, brickId) => [
        { type: 'Brick', id: encodeTagParameters({ id: brickId }) },
      ],
    }),
    getBrickById: builder.query<Record<string, BrickDetails>, string[]>({
      queryFn: async (brickIds, _queryApi, _extraOptions, baseQuery) => {
        const brickById = {} as Record<string, BrickDetails>
        for (const brickId of uniq(brickIds)) {
          const brickResult = await baseQuery(`/bricks/${brickId}/`)
          if (brickResult.error) {
            return brickResult
          }
          brickById[brickId] = brickResult.data as BrickDetails
        }
        return { data: brickById }
      },
      providesTags: (_result, _error, brickIds) =>
        brickIds.map(brickId => ({
          type: 'Brick',
          id: encodeTagParameters({ id: brickId }),
        })),
    }),
    duplicateBrick: builder.mutation<DuplicateBrickResult, string>({
      query: brickId => ({
        url: `/bricks/${brickId}/create_copy/`,
        method: 'POST',
      }),
    }),
    deleteBrick: builder.mutation<unknown, string>({
      query: brickId => ({
        url: `/bricks/${brickId}/`,
        method: 'DELETE',
      }),
    }),
    updateBrick: builder.mutation<unknown, UpdateBrickPayload>({
      query: ({ id, ...payload }) => ({
        url: `/bricks/${id}/`,
        method: 'PUT',
        body: payload,
      }),
    }),
    getWorkflowsWithPipelines: builder.query<
      GetWorkflowsWithPipelinesResult,
      GetWorkflowsWithPipelinesPayload
    >({
      query: payload =>
        `/workflows/?project=${payload.projectId}&has_pipelines=True&page=${payload.page}`,
      providesTags: [
        { type: 'Workflow', id: encodeTagParameters({ id: 'list' }) },
      ],
    }),
    createPipeline: builder.mutation<unknown, string>({
      query: (workflowId: string) => ({
        url: `/workflows/${workflowId}/create_version/`,
        method: 'POST',
      }),
    }),
  }),
})

export const {
  usePreCreateWorkflowMutation,
  useCreateWorkflowMutation,
  useGetWorkflowsQuery,
  useGetWorkflowQuery,
  useUpdateWorkflowMutation,
  useDeleteWorkflowMutation,
  usePreCreateBuildBrickSettingsMutation,
  usePreCreateAdjustBrickSettingsMutation,
  useCreateBrickMutation,
  useGetBrickQuery,
  useLazyGetBrickQuery,
  useGetBrickByIdQuery,
  useLazyGetBrickByIdQuery,
  useDuplicateBrickMutation,
  useDeleteBrickMutation,
  useUpdateBrickMutation,
  useGetWorkflowsWithPipelinesQuery,
  useCreatePipelineMutation,
  useLazyGetWorkflowQuery,
} = workflowsApi

type PreCreateWorkflowPayload = {
  project: string
  compensated_files: string[]
}

type PreCreateWorkflowResult = {
  name: string
  project: string
  compensated_files: string[]
  settings: BrickSettings
}

type CreateWorkflowPayload = {
  name: string
  description: string
  project: string
  compensated_files: string[]
  settings: BrickSettings
}

type GetWorkflowsPayload = {
  page: number
  projectId?: string
  ordering?: string
  search?: Record<string, string | undefined>
}

type GetWorkflowsResult = PaginatedResults<Workflow[]>

type UpdateWorkflowPayload = {
  id: string
  name?: string
  description?: string
}

export type Workflow = {
  author_name: string
  created_at: string
  id: string
  name: string
  project: string
  updated_at: string
  mode: 'workflow'
  status: `${WorkflowStatus}`
  description?: string
  version_source?: string
  messages_for_user: MessagesForUser
}

export enum WorkflowStatus {
  New = 'New',
  Creating = 'Creating',
  CreatingSource = 'Creating source',
  CreatingCopy = 'Creating copy',
  Processing = 'Processing',
  Ready = 'Ready',
  Error = 'Error',
}

export type WorkflowDetails = Workflow & {
  bricks: Brick[]
  compensated_files: CompensatedFile[]
}

export type Brick = {
  author_name: string
  created_at: string
  id: string
  name: string
  parent: string | null
  updated_at: string
  workflow: string
  is_sub_analysis: boolean
  is_leaf: boolean
  status: BrickStatus
  messages_for_user: MessagesForUser
}

export type BrickStatus = 'Pending' | 'Processing' | 'Ready' | 'Error'

export type BrickDetails = Brick & {
  analyses: BrickAnalysis[]
  settings: BrickSettings
  original_settings?: BrickSettings
  reference_analysis?: BrickAnalysis
}

export type BrickAnalysis = {
  author_name: string
  brick: string
  compensated_file: string
  created_at: string
  id: string
  name: string
  parent_analysis: string
  protection_mode: boolean
  protection_mode_author: string
  protection_mode_date: string | null
  status: AnalysisStatus
  transfer_date: string | null
  transfer_source_analysis: string | null
  updated_at: string
  messages_for_user: MessagesForUser
}

type BrickSettings = {
  add_graphs_for_selected_channels: boolean
  align_clusters: boolean
  channels: Record<string, FcsFileChannel>
  cluster_names?: string[]
  clustering_mode: string
  concatenate_files: boolean
  cytometer: BrickSettingsCytometerType
  doublet_exclusion: boolean
  precision_and_granularity_mode: PrecisionAndGranularityMode
  kd: number
  kt: number
  lassos?: Record<string, Lasso>
  metaclean: boolean
  normalisation: boolean
  transformation_method: BrickSettingsTransformationType | null
  transformation_args: {
    factor: number
  }
}

export type BrickSettingsTransformationType = 'arcsinh' | 'logicle' | 'biexp'

export type BrickSettingsCytometerType = 'conventional' | 'spectral' | 'mass'

export type PrecisionAndGranularityMode = 'standard' | 'rare-cell' | 'expert'

type PreCreateBuildBrickSettingsPayload = {
  parent: string
}

type PreCreateBuildBrickSettingsResult = {
  parent: string
  name: string
  settings: BrickSettings & { cluster_names: string[] }
}

type PreCreateAdjustBrickSettingsPayload = PreCreateBuildBrickSettingsResult

type PreCreateAdjustBrickSettingsResult = PreCreateAdjustBrickSettingsPayload

export type CreateBrickPayload = PreCreateAdjustBrickSettingsResult

type DuplicateBrickResult = {
  copy_brick: Brick
  source_brick: Brick
  copy_report: {
    analyses_result: { old_analysis: string; new_analysis: string }[]
  }
}

type GetWorkflowsWithPipelinesResult = PaginatedResults<Workflow[]>

type GetWorkflowsWithPipelinesPayload = {
  projectId: string
  page: number
}

type UpdateBrickPayload = {
  id: string
  name: string
}

type DeleteWorkflowPayload = {
  workflowId: string
  deletePipelines: boolean
}
