import React from 'react'
import styled from 'styled-components'

import Input from 'components/forms/Input'
import Select from 'components/forms/Select'

import { CountryCode } from 'shared/api/utils.api'

interface PhoneNumberInputProps {
  name: string
  label: string
  areaCode: CountryCode['code']
  areaCodeValue: string
  phoneNumber: string
  countryCodes: CountryCode[]
  error?: string
  onAreaCodeChange: (areaCode: string) => void
  onPhoneNumberChange: (value: string) => void
  onBlur: (
    e: React.FocusEventHandler<HTMLInputElement | HTMLTextAreaElement>,
  ) => void
}

export const PhoneNumberInput = ({
  name,
  label,
  areaCode,
  areaCodeValue,
  phoneNumber,
  countryCodes,
  error,
  onAreaCodeChange,
  onPhoneNumberChange,
  onBlur,
}: PhoneNumberInputProps): JSX.Element => {
  const countryFlags = countryCodes.map(({ code, name, calling_code }) => ({
    value: code,
    label: (
      <>
        <StyledLabel>
          <img
            loading="lazy"
            width="20"
            src={`https://flagcdn.com/w20/${code.toLowerCase()}.png`}
            srcSet={`https://flagcdn.com/w40/${code.toLowerCase()}.png 2x`}
            alt=""
          />
          {name} {calling_code && '+' + calling_code}
        </StyledLabel>
      </>
    ),
  }))

  return (
    <PhoneWrapper>
      <AreaCodeSelect
        id="organizationPhoneNumberAreaCode"
        name="organizationPhoneNumberAreaCode"
        value={areaCode}
        onChange={({ target }) => {
          onAreaCodeChange(target.value as string)
        }}
        options={countryFlags}
      />
      <StyledAreaCode
        disabled
        id="areaCodeValue"
        name="areaCodeValue"
        value={areaCodeValue}
      />
      <StyledInput
        id={name}
        name={name}
        label={label}
        value={phoneNumber}
        error={error}
        onChange={event =>
          onPhoneNumberChange(event.target.value.replaceAll(/[^0-9 -]/g, ''))
        }
        onBlur={onBlur}
      />
    </PhoneWrapper>
  )
}

const AreaCodeSelect = styled(Select)`
  width: 85px;
  padding-top: 20px;
  padding-right: 8px;
`

const StyledInput = styled(Input)`
  flex-grow: 2;

  & label {
    margin-left: -144px;
  }
`

const StyledAreaCode = styled(Input)`
  width: 52px;
  margin-right: 8px;
  padding-top: 20px;
`

const PhoneWrapper = styled.div`
  display: flex;
`

const StyledLabel = styled.div`
  display: flex;
  overflow: hidden;
  & img {
    margin-right: 10px;
    width: 20px;
  }
`
