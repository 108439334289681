import { Pagination as MuiPagination } from '@material-ui/lab'
import styled from 'styled-components'

export const Pagination = styled(MuiPagination)`
  display: flex;
  justify-content: center;
  width: 100%;
  margin-top: ${props => props.theme.spacing(4)}px;
  & button {
    color: ${props => props.theme.colors.primaryDark[70]};

    &.Mui-selected {
      background: ${props => props.theme.colors.primaryDark[20]};
      color: ${props => props.theme.colors.primaryDark[100]};
    }

    &:disabled {
      color: ${props => props.theme.colors.primaryDark[50]};
    }

    &:hover {
      background: ${props => props.theme.colors.greyscale[10]};
      color: ${props => props.theme.colors.primaryDark[100]};
    }
  }
`
