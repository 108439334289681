import { Typography } from '@material-ui/core'
import { useState } from 'react'
import styled from 'styled-components'

import { Button } from 'components/Button'
import { Checkbox } from 'components/Checkbox'

import { useGetUsersInvitationsQuery } from 'shared/api/organization.api'
import { useEventCallback } from 'shared/hooks/useEventCallback'
import { useObserve } from 'shared/hooks/useObserve'
import { OrganizationInvitation } from 'shared/models/OrganizationModels'
import { SortingOrder } from 'shared/utils/array'

import { InvitationHeader, InvitationsTable } from './InvitationsTable'
import { InviteMemberModal } from './InviteMemberModal'

const sortingFields = [
  { header: 'E-mail', field: 'email' },
  { header: 'Created', field: 'created_at' },
  { header: 'Send date', field: 'send_date' },
  { header: 'Status', field: 'status' },
]

export const InvitationsPageContent = (): JSX.Element => {
  const [page, setPage] = useState(1)
  const [sortingCategory, setSortingCategory] =
    useState<InvitationHeader>('Send date')
  const [sortingOrder, setSortingOrder] = useState<SortingOrder>('descending')
  const [isInvitationModalOpen, setIsInvitationModalOpen] = useState(false)
  const [shownStatuses, setShownStatuses] = useState<
    OrganizationInvitation['status'][]
  >(['acceptance pending', 'expired'])

  const invitationsResult = useGetUsersInvitationsQuery({
    page,
    ordering: computeOrdering(sortingCategory, sortingOrder),
    statuses: shownStatuses,
  })

  useObserve(invitationsResult.data?.page, realCurrentPage => {
    if (realCurrentPage && realCurrentPage !== page) {
      setPage(realCurrentPage)
    }
  })

  const handleToggleShowStatus = useEventCallback(
    (status: OrganizationInvitation['status'], checked: boolean): void => {
      setShownStatuses(
        checked
          ? [...shownStatuses, status]
          : shownStatuses.filter(s => s !== status),
      )
    },
  )

  return (
    <>
      <InviteButtonWrapper>
        <Button onClick={() => setIsInvitationModalOpen(true)}>
          Invite Member
        </Button>
      </InviteButtonWrapper>
      <InvitationsHeader>
        <Typography variant="h4" align="left">
          Invitations
        </Typography>
        <Checkbox
          checked={shownStatuses.includes('expired')}
          label="Include expired"
          onChange={(_, checked) => handleToggleShowStatus('expired', checked)}
        />
        <Checkbox
          checked={shownStatuses.includes('accepted')}
          label="Include accepted"
          onChange={(_, checked) => handleToggleShowStatus('accepted', checked)}
        />
      </InvitationsHeader>
      <InvitationsTable
        isLoading={invitationsResult.isLoading}
        isFetching={invitationsResult.isFetching}
        invitations={invitationsResult.data?.results ?? []}
        page={page}
        numberOfPages={invitationsResult.data?.page_count ?? 1}
        sortingCategory={sortingCategory}
        sortingOrder={sortingOrder}
        onPageChange={setPage}
        onSortingCategoryChange={setSortingCategory}
        onSortingOrderChange={setSortingOrder}
      />
      <InviteMemberModal
        isInvitationModalOpen={isInvitationModalOpen}
        setIsInvitationModalOpen={setIsInvitationModalOpen}
      />
    </>
  )
}

const computeOrdering = (header: InvitationHeader, order: SortingOrder) => {
  const orderColumnIndex = sortingFields.findIndex(
    column => column.header === header,
  )
  const orderCategory = sortingFields[orderColumnIndex]['field']
  return order === 'descending' ? '-' + orderCategory : orderCategory
}

const InviteButtonWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  width: 100%;
`

const InvitationsHeader = styled.div`
  display: flex;
  gap: ${props => props.theme.spacing(2)}px;

  > :not(:first-child) {
    margin-top: -2px;
  }
`
