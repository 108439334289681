import { FC, useEffect, useMemo, useState } from 'react'
import { renderToStaticMarkup } from 'react-dom/server'
import { useError } from 'react-use'
import styled, { ThemeProvider, useTheme } from 'styled-components'

import { DefaultErrorBoundary } from 'components/DefaultErrorBoundary'
import { Modal } from 'components/Modal'
import { Chart } from 'components/graphs/Chart'

import { FcsFile } from 'shared/api/files.api'
import {
  MetaAnalysisColors,
  MetaAnalysisFile,
  MetaAnalysisGlobalVizFile,
  MetaAnalysisHistogramType,
} from 'shared/api/meta-analysis.api'
import { useEventCallback } from 'shared/hooks/useEventCallback'
import { useStable } from 'shared/hooks/useStable'
import { useAppDispatch, useAppSelector } from 'shared/store'
import { getSeriesMinMaxXY } from 'shared/utils/array'
import { metaAnalysisWorker } from 'shared/worker'
import { ComputeHistogramSeriesReturnValue } from 'shared/worker/meta-analysis-worker'

import { MetaAnalysisChartContainer } from './MetaAnalysisChartContainer'
import { MetaAnalysisHistogramOptions } from './MetaAnalysisHistogramOptions'
import { MetaAnalysisHistogramTooltip } from './MetaAnalysisHistogramTooltip'
import { updateMetaAnalysisChart } from './store/meta-analysis.history.slice'
import {
  selectMetaAnalysisFcsFiles,
  selectMetaAnalysisFile,
  selectMetaAnalysisGlobalVizFile,
} from './store/selectors'

type MetaAnalysisHistogramProps = {
  chart: MetaAnalysisHistogramType
  colors: MetaAnalysisColors
  isExpanded?: boolean
  className?: string
  onCloseExpand?: () => void
}

export const MetaAnalysisHistogram = ({
  chart,
  colors,
  isExpanded,
  className,
  onCloseExpand,
}: MetaAnalysisHistogramProps): JSX.Element => {
  const dispatch = useAppDispatch()

  const [shouldDisplayChartOptions, setShouldDisplayChartOptions] =
    useState(false)
  const [shouldShowExpandedSelf, setShouldShowExpandedSelf] = useState(false)

  const handleChangeOptions = useEventCallback(() => {
    setShouldDisplayChartOptions(true)
  })

  const handleCancel = useEventCallback(() => {
    setShouldDisplayChartOptions(false)
  })

  const handleApplyOptions = useEventCallback(
    (values: { fileIds: string[]; clusters: string[]; channel: string }) => {
      dispatch(
        updateMetaAnalysisChart({
          id: chart.id,
          ...values,
        }),
      )
      setShouldDisplayChartOptions(false)
    },
  )

  return (
    <MetaAnalysisChartContainer
      chart={chart}
      isExpanded={isExpanded}
      className={className}
      onChangeOptions={handleChangeOptions}
      onExpand={() => setShouldShowExpandedSelf(true)}
      onCloseExpand={onCloseExpand}
    >
      {shouldDisplayChartOptions && (
        <StyledMetaAnalysisHistogramOptions
          mode="edit"
          initialValues={chart}
          onCancel={handleCancel}
          onFinish={handleApplyOptions}
        />
      )}
      <DefaultErrorBoundary>
        <InnerPlot chart={chart} colors={colors} />
      </DefaultErrorBoundary>
      {shouldShowExpandedSelf && (
        <Modal open onClose={() => setShouldShowExpandedSelf(false)}>
          <ExpandedMetaAnalysisScatterPlot
            chart={chart}
            colors={colors}
            onCloseExpand={() => setShouldShowExpandedSelf(false)}
          />
        </Modal>
      )}
    </MetaAnalysisChartContainer>
  )
}

type InnerPlotProps = Pick<MetaAnalysisHistogramProps, 'chart' | 'colors'>

const InnerPlot: FC<InnerPlotProps> = ({ chart, colors }) => {
  const theme = useTheme()

  const metaAnalysisFile = useAppSelector(selectMetaAnalysisFile)
  const globalVizFile = useAppSelector(selectMetaAnalysisGlobalVizFile)
  const files = useAppSelector(selectMetaAnalysisFcsFiles)

  const { manySeries, metadataBySeriesId } = useManySeries({
    metaAnalysisFile,
    globalVizFile,
    chart,
    colors,
    files,
  })

  const manyOptions = useMemo((): {
    fileId: string
    options: Highcharts.Options
  }[] => {
    const { xMin, xMax, yMax } = getSeriesMinMaxXY(
      manySeries.map(s => s.series[0].data).flat(),
    )

    return manySeries.map(({ series, fileId }) => {
      return {
        fileId,
        options: {
          chart: {
            type: 'column',
          },
          boost: {
            seriesThreshold: 1,
          },
          xAxis: {
            max: xMax,
            min: xMin,
            title: {
              text: undefined,
            },
          },
          yAxis: {
            max: yMax,
            min: 0,
            title: {
              text: files.find(file => file.id === fileId)?.file_name,
              style: {
                textOverflow: 'ellipsis',
                whiteSpace: 'nowrap',
                overflow: 'hidden',
              },
            },
          },
          title: {
            text: undefined,
          },
          series,
          tooltip: {
            formatter: function () {
              return renderToStaticMarkup(
                <ThemeProvider theme={theme}>
                  <MetaAnalysisHistogramTooltip
                    name={this.series.name}
                    color={this.point.color as string}
                    numberOfEvents={
                      metadataBySeriesId![this.point.series.userOptions.id!]
                        .numberOfEvents
                    }
                  />
                </ThemeProvider>,
              )
            },
          },
        },
      }
    })
  }, [files, manySeries, metadataBySeriesId, theme])

  return (
    <Histograms $numberOfFiles={manySeries.length}>
      {manyOptions.map(({ fileId, options }) => (
        <Chart key={fileId} immutable options={options} />
      ))}
      <Channel>{chart.channel}</Channel>
    </Histograms>
  )
}

const useManySeries = ({
  metaAnalysisFile,
  globalVizFile,
  chart,
  files,
  colors,
}: {
  metaAnalysisFile: MetaAnalysisFile
  globalVizFile: MetaAnalysisGlobalVizFile | undefined
  chart: MetaAnalysisHistogramType
  files: FcsFile[]
  colors: MetaAnalysisColors
}) => {
  const throwError = useError()

  const [series, setSeries] =
    useState<ComputeHistogramSeriesReturnValue['series']>()
  const [metadataBySeriesId, setMetadataBySeriesId] =
    useState<ComputeHistogramSeriesReturnValue['metadataBySeriesId']>()

  const computationParams = useStable(
    useMemo(() => {
      if (!globalVizFile) {
        return undefined
      }

      return {
        metaAnalysisFile,
        globalVizFile,
        chart,
        colors,
        files,
      }
    }, [chart, files, globalVizFile, metaAnalysisFile, colors]),
  )

  useEffect(() => {
    let cancelled = false

    if (computationParams) {
      metaAnalysisWorker
        .computeHistogramSeries(computationParams)
        .then(({ series, metadataBySeriesId }) => {
          if (!cancelled) {
            setSeries(series)
            setMetadataBySeriesId(metadataBySeriesId)
          }
        })
        .catch(throwError)
    }

    return () => {
      cancelled = true
    }
  }, [computationParams, throwError])

  return {
    manySeries:
      series !== undefined
        ? Object.entries(series).map(([fileId, series]) => ({ fileId, series }))
        : [],
    metadataBySeriesId,
  }
}

const Histograms = styled.div<{ $numberOfFiles: number }>`
  width: 100%;
  height: 100%;
  display: grid;
  grid-template-rows: repeat(
    ${props => props.$numberOfFiles},
    minmax(150px, 1fr)
  );
  overflow: auto;
`

const Channel = styled.div`
  text-align: center;
  font-family: ${props => props.theme.font.style.bold};
  font-size: 12px;
`

const StyledMetaAnalysisHistogramOptions = styled(MetaAnalysisHistogramOptions)`
  position: absolute;
  top: 0;
  left: 0;
  z-index: 2;
`

const ExpandedMetaAnalysisScatterPlot = styled(MetaAnalysisHistogram).attrs({
  isExpanded: true,
})`
  width: 90vw;
  height: 90vh;
`
