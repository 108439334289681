import {
  FetchArgs,
  FetchBaseQueryError,
  FetchBaseQueryMeta,
} from '@reduxjs/toolkit/dist/query'
import { QueryReturnValue } from '@reduxjs/toolkit/dist/query/baseQueryTypes'
import { MaybePromise } from '@reduxjs/toolkit/dist/query/tsHelpers'
import localForage from 'localforage'
import sizeof from 'object-sizeof'

import {
  APPLICATION_INSTANCE_ID_HEADER_NAME,
  CSRF_TOKEN_REQUEST_HEADER_NAME,
  DONT_SET_HEADER_VALUE,
} from 'shared/api/cookies'
import {
  LOCAL_FORAGE_RAW_LEAF_LABELS_KEY,
  LOCAL_FORAGE_RAW_TRANSFORMED_DATA_KEY,
  LOCAL_FORAGE_SCALES_KEY,
} from 'shared/constants'

// This number comes from error thrown by Firefox when trying to store bigger files
// TODO: It might change in the future, so needs more universal solution
const INDEXED_DB_STORAGE_VALUE_LIMIT = 267386880

const createStorage = (storeName: string) =>
  localForage.createInstance({
    driver: localForage.INDEXEDDB,
    name: 'METAFORA',
    storeName,
  })

export const getStoredLeafLabels = async (
  storeName: string,
): Promise<Analysis.LeafLabels> => {
  return JSON.parse(
    await getData({
      storeName,
      key: LOCAL_FORAGE_RAW_LEAF_LABELS_KEY,
    }),
  ).cluster
}

export const getStoredScales = async (
  storeName: string,
): Promise<Analysis.Scales> => {
  return JSON.parse(
    await getData({
      storeName,
      key: LOCAL_FORAGE_SCALES_KEY,
    }),
  )
}

export const getStoredTransformedData = async (
  storeName: string,
): Promise<Analysis.TransformedDataResponse> => {
  return JSON.parse(
    await getData({ storeName, key: LOCAL_FORAGE_RAW_TRANSFORMED_DATA_KEY }),
  )
}

type LoadClusteringFileProps = {
  storeName: string
  key: string
  url: string
  baseQuery: (
    arg: string | FetchArgs,
  ) => MaybePromise<
    QueryReturnValue<unknown, FetchBaseQueryError, FetchBaseQueryMeta>
  >
}

export const loadClusteringFile = async ({
  storeName,
  key,
  url,
  baseQuery,
}: LoadClusteringFileProps): Promise<
  QueryReturnValue<string, FetchBaseQueryError, FetchBaseQueryMeta>
> => {
  if (!url) {
    throw new Error('Cannot load clustering file because URL is missing')
  }
  const storage = createStorage(storeName)
  const etag = await storage.getItem<string>(key + '-etag')

  const fileResult = (await baseQuery({
    url,
    headers: {
      ...(etag ? { 'If-None-Match': etag } : {}),
      'Access-Control-Request-Headers': 'etag',
      Accept: 'application/json',
      [CSRF_TOKEN_REQUEST_HEADER_NAME]: undefined,
      [APPLICATION_INSTANCE_ID_HEADER_NAME]: DONT_SET_HEADER_VALUE,
    },
    responseHandler: 'text',
    validateStatus: response => response.ok || response.status === 304,
  })) as QueryReturnValue<string, FetchBaseQueryError, FetchBaseQueryMeta>

  if (fileResult.error) {
    return fileResult
  }

  if (!fileResult.meta?.response) {
    throw new Error('Response metadata is missing')
  }

  if (fileResult.meta.response.status === 304) {
    const data = (await getData({ storeName, key })) as string
    return { data }
  } else {
    const data = fileResult.data
    const etag = fileResult.meta.response.headers.get('etag')
    await setData({ storeName, key, data, etag })
    return fileResult
  }
}

type SetDataProps = {
  storeName: string
  key: string
  data: string
  etag: string | null
}

const setData = async ({
  storeName,
  key,
  data,
  etag,
}: SetDataProps): Promise<void> => {
  const storage = createStorage(storeName)
  const sizeOfData = sizeof(data)
  await storage.setItem(key + '-etag', etag)

  if (sizeOfData > INDEXED_DB_STORAGE_VALUE_LIMIT) {
    const numberOfParts = Math.ceil(sizeOfData / INDEXED_DB_STORAGE_VALUE_LIMIT)
    const singularDataChunksLength = Math.ceil(data.length / numberOfParts)
    await storage.setItem(key + 'NumberOfParts', numberOfParts)

    for (
      let index = 0, offset = 0;
      index < numberOfParts;
      index++, offset += singularDataChunksLength
    ) {
      const dataChunk = data.substring(
        offset,
        offset + singularDataChunksLength,
      )
      if (sizeof(dataChunk) > INDEXED_DB_STORAGE_VALUE_LIMIT) {
        throw new Error(`${key} chunk is too big for storage`)
      }
      await storage.setItem(key + index, dataChunk)
    }
  } else {
    await storage.setItem(key, data)
  }
}

type GetDataProps = {
  storeName: string
  key: string
}

const getData = async ({ storeName, key }: GetDataProps): Promise<string> => {
  const storage = createStorage(storeName)
  const numberOfParts = await storage.getItem<number>(key + 'NumberOfParts')

  if (numberOfParts) {
    let data = ''
    for (let x = 0; x < numberOfParts; x++) {
      const chunk = await storage.getItem<string>(key + x)
      if (!chunk) {
        throw new Error(`Could not load ${key} chunk`)
      }

      data = data.concat(chunk)
    }
    return data
  } else {
    const data = await storage.getItem<string>(key)
    if (!data) {
      throw new Error(`Could not load ${key} data`)
    }
    return data
  }
}
