import { ZoomOut } from '@material-ui/icons'
import { SelectEventObject } from 'highcharts'
import { mapValues } from 'lodash'
import { FC, useEffect, useMemo, useRef, useState } from 'react'
import { useError, usePrevious } from 'react-use'
import styled from 'styled-components'

import { DefaultErrorBoundary } from 'components/DefaultErrorBoundary'
import { Modal } from 'components/Modal'
import { HighPerformanceScatterPlotBase } from 'components/graphs/HighPerformanceScatterPlotBase'

import { ScatterPlotChartScale } from 'pages/analysis/store/selectors'

import { FcsFile } from 'shared/api/files.api'
import {
  MetaAnalysisFile,
  MetaAnalysisGlobalVizFile,
  MetaAnalysisScatterPlotType,
} from 'shared/api/meta-analysis.api'
import { useEventCallback } from 'shared/hooks/useEventCallback'
import { useStable } from 'shared/hooks/useStable'
import { useAppDispatch, useAppSelector } from 'shared/store'
import { metaAnalysisWorker } from 'shared/worker'
import { ComputeScatterPlotSeriesReturnValue } from 'shared/worker/meta-analysis-worker'

import { MetaAnalysisChartContainer } from './MetaAnalysisChartContainer'
import { MetaAnalysisScatterPlotOptions } from './MetaAnalysisScatterPlotOptions'
import { MetaAnalysisScatterPlotTooltip } from './MetaAnalysisScatterPlotTooltip'
import { updateMetaAnalysisChart } from './store/meta-analysis.history.slice'
import {
  selectMetaAnalysisFcsFiles,
  selectMetaAnalysisFile,
  selectMetaAnalysisGlobalVizFile,
} from './store/selectors'

type MetaAnalysisScatterPlotProps = {
  chart: MetaAnalysisScatterPlotType
  isExpanded?: boolean
  className?: string
  onCloseExpand?: () => void
}

export const MetaAnalysisScatterPlot: FC<MetaAnalysisScatterPlotProps> = ({
  chart,
  isExpanded,
  className,
  onCloseExpand,
}) => {
  const dispatch = useAppDispatch()
  const [zoom, setZoom] = useState<ScatterPlotChartScale>()
  const [shouldDisplayChartOptions, setShouldDisplayChartOptions] =
    useState(false)
  const [shouldShowExpandedSelf, setShouldShowExpandedSelf] = useState(false)

  const handleChangeOptions = useEventCallback(() => {
    setShouldDisplayChartOptions(true)
  })

  const handleCancel = useEventCallback(() => {
    setShouldDisplayChartOptions(false)
  })

  const handleApplyOptions = useEventCallback(
    (values: {
      fileIds: string[]
      clusters: string[]
      xAxis: string
      yAxis: string
    }) => {
      dispatch(
        updateMetaAnalysisChart({
          id: chart.id,
          ...values,
        }),
      )
      setShouldDisplayChartOptions(false)
    },
  )

  return (
    <MetaAnalysisChartContainer
      chart={chart}
      isExpanded={isExpanded}
      className={className}
      actions={
        zoom
          ? [
              {
                tooltip: 'Reset zoom',
                icon: <ZoomOut />,
                onClick: () => setZoom(undefined),
              },
            ]
          : undefined
      }
      onChangeOptions={handleChangeOptions}
      onExpand={() => setShouldShowExpandedSelf(true)}
      onCloseExpand={onCloseExpand}
    >
      {shouldDisplayChartOptions ? (
        <StyledMetaAnalysisScatterPlotOptions
          mode="edit"
          initialValues={chart}
          onCancel={handleCancel}
          onFinish={handleApplyOptions}
        />
      ) : (
        <DefaultErrorBoundary>
          <InnerPlot chart={chart} zoom={zoom} onZoomChange={setZoom} />
        </DefaultErrorBoundary>
      )}
      {shouldShowExpandedSelf && (
        <Modal open onClose={() => setShouldShowExpandedSelf(false)}>
          <ExpandedMetaAnalysisScatterPlot
            chart={chart}
            onCloseExpand={() => setShouldShowExpandedSelf(false)}
          />
        </Modal>
      )}
    </MetaAnalysisChartContainer>
  )
}

type InnerPlotProps = Pick<MetaAnalysisScatterPlotProps, 'chart'> & {
  zoom: ScatterPlotChartScale | undefined
  onZoomChange: (zoom: ScatterPlotChartScale | undefined) => void
}

const InnerPlot: FC<InnerPlotProps> = ({ chart, zoom, onZoomChange }) => {
  const metaAnalysisFile = useAppSelector(selectMetaAnalysisFile)
  const globalVizFile = useAppSelector(selectMetaAnalysisGlobalVizFile)
  const files = useAppSelector(selectMetaAnalysisFcsFiles)

  const [highchartsChart, setHighchartsChart] = useState<Highcharts.Chart>()

  const {
    colorBySeriesId,
    metadataBySeriesId,
    series,
    scale,
    isComputingSeriesRef,
  } = useSeries({
    metaAnalysisFile,
    globalVizFile,
    highchartsChart,
    zoom,
    chart,
    files,
  })

  const handleZoom = useEventCallback((e: SelectEventObject) => {
    onZoomChange({
      xAxis: {
        min: e.xAxis[0].min,
        max: e.xAxis[0].max,
      },
      yAxis: {
        min: e.yAxis[0].min,
        max: e.yAxis[0].max,
      },
    })
    return false
  })

  const options = useMemo(() => {
    return {
      chart: {
        type: 'scatter',
        zooming: {
          type: 'xy',
        },
        events: {
          selection: handleZoom,
        },
      } as Highcharts.ChartOptions,
      xAxis: {
        title: {
          text: chart.xAxis,
        },
      },
      yAxis: {
        title: {
          text: chart.yAxis,
        },
      },
      title: {
        text: undefined,
      },
    }
  }, [chart.xAxis, chart.yAxis, handleZoom])

  return (
    <>
      <HighPerformanceScatterPlotBase
        options={options}
        series={series}
        isComputingSeriesRef={isComputingSeriesRef}
        scale={zoom ?? scale}
        colorBySeriesId={colorBySeriesId}
        onHighchartsChartChange={setHighchartsChart}
        Tooltip={MetaAnalysisScatterPlotTooltip}
        tooltipProps={{ metadataBySeriesId }}
      />
    </>
  )
}

const StyledMetaAnalysisScatterPlotOptions = styled(
  MetaAnalysisScatterPlotOptions,
)`
  position: absolute;
  top: 0;
  left: 0;
  z-index: 2;
`

const ExpandedMetaAnalysisScatterPlot = styled(MetaAnalysisScatterPlot).attrs({
  isExpanded: true,
})`
  width: 90vw;
  height: 90vh;
`

const useSeries = ({
  metaAnalysisFile,
  globalVizFile,
  highchartsChart,
  zoom,
  chart,
  files,
}: {
  metaAnalysisFile: MetaAnalysisFile
  globalVizFile: MetaAnalysisGlobalVizFile | undefined
  highchartsChart: Highcharts.Chart | undefined
  zoom: ScatterPlotChartScale | undefined
  chart: MetaAnalysisScatterPlotType
  files: FcsFile[]
}) => {
  const throwError = useError()
  const isComputingSeriesRef = useRef(false)
  const [scale, setScale] = useState<ScatterPlotChartScale>()

  const [metadataBySeriesId, setMetadataBySeriesId] =
    useState<ComputeScatterPlotSeriesReturnValue['metadataBySeriesId']>()
  const [series, setSeries] = useState<{
    seriesIdByPixelPosition: Map<string, string>
    plotWidth: number
    plotHeight: number
  }>()

  const previousZoom = usePrevious(zoom)

  const colorBySeriesId = useMemo(
    () => mapValues(metadataBySeriesId, 'color'),
    [metadataBySeriesId],
  )

  const computationParams = useStable(
    useMemo(() => {
      if (!globalVizFile || previousZoom !== zoom) {
        return undefined
      }

      return {
        plotWidth: highchartsChart?.plotWidth,
        plotHeight: highchartsChart?.plotHeight,
        metaAnalysisFile,
        globalVizFile,
        zoom,
        chart,
        files,
      }
    }, [
      chart,
      files,
      globalVizFile,
      highchartsChart?.plotHeight,
      highchartsChart?.plotWidth,
      metaAnalysisFile,
      previousZoom,
      zoom,
    ]),
  )

  useEffect(() => {
    let cancelled = false

    if (computationParams) {
      isComputingSeriesRef.current = true
      metaAnalysisWorker
        .computeScatterPlotSeries(computationParams)
        .then(({ series, scale, metadataBySeriesId }) => {
          if (!cancelled) {
            setSeries(series)
            setScale(scale)
            setMetadataBySeriesId(metadataBySeriesId)

            if (series) {
              isComputingSeriesRef.current = false
            }
          }
        })
        .catch(throwError)
    }

    return () => {
      cancelled = true
    }
  }, [computationParams, throwError])

  return {
    series,
    scale,
    metadataBySeriesId,
    colorBySeriesId,
    isComputingSeriesRef,
  }
}
