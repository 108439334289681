import { IconButton } from '@material-ui/core'
import { CloudDownload as DownloadIcon } from '@material-ui/icons'
import { SnackbarKey, useSnackbar } from 'notistack'
import { HTMLAttributes, useEffect, useRef } from 'react'
import { useTheme } from 'styled-components'

import { Icon } from 'assets/images/icons/Icon'

import { useAppSelector } from 'shared/store'
import { selectNotifications } from 'shared/store/notification.slice'

export const Notification = (): null => {
  const theme = useTheme()
  const notifications = useAppSelector(selectNotifications)
  const handledNotificationIdsRef = useRef(new Set<string>())
  const { enqueueSnackbar, closeSnackbar } = useSnackbar()

  type NotificationActionButton = {
    title?: string
    icon: JSX.Element
    otherProps?: Omit<HTMLAttributes<HTMLButtonElement>, 'color'>
    onClick: (snackbarId: SnackbarKey) => void
  }

  useEffect(() => {
    for (const notification of notifications) {
      if (handledNotificationIdsRef.current.has(notification.id)) {
        continue
      }

      const actionButtons: NotificationActionButton[] = []

      if (notification.extraActions) {
        for (const extraAction of notification.extraActions) {
          switch (extraAction.type) {
            case 'download-file':
              actionButtons.push({
                title: 'Download file',
                icon: <DownloadIcon />,
                onClick: snackbarId => {
                  window.open(extraAction.file_url, '__blank')
                  closeSnackbar(snackbarId)
                },
              })
              break
            default:
              continue
          }
        }
      }

      actionButtons.push({
        icon: <Icon name="close" color={theme.colors.white} />,
        otherProps: { 'aria-label': 'close' },
        onClick: snackbarId => closeSnackbar(snackbarId),
      })

      enqueueSnackbar(notification.description, {
        variant: notification.type,
        autoHideDuration: notification.autoHideDuration * 1000,
        action: snackbarId => (
          <>
            {actionButtons.map((actionButton, index) => (
              <IconButton
                key={`${snackbarId}[${index}]`}
                size="small"
                color="inherit"
                onClick={() => actionButton.onClick(snackbarId)}
                {...actionButton.otherProps}
              >
                {actionButton.icon}
              </IconButton>
            ))}
          </>
        ),
        anchorOrigin: { vertical: 'bottom', horizontal: 'right' },
        style: {
          fontFamily: theme.font.style.bold,
        },
      })

      handledNotificationIdsRef.current.add(notification.id)
    }
  }, [
    closeSnackbar,
    enqueueSnackbar,
    notifications,
    theme.colors,
    theme.font.style.bold,
  ])

  return null
}
