import {
  useFloating,
  useInteractions,
  safePolygon,
  useHover,
  useDismiss,
  FloatingPortal,
} from '@floating-ui/react'
import { Tooltip } from '@material-ui/core'
import { Add, Cached } from '@material-ui/icons'
import { FC, useRef, useState } from 'react'
import styled from 'styled-components'

import { SearchableAnalysisList } from 'components/analysis-list/SearchableAnalysisList'
import { Button } from 'components/button/Button'
import { DEFAULT_USE_FLOATING_PROPS } from 'components/tooltip'

import { BrickAnalysis } from 'shared/api/workflows.api'
import { useEventCallback } from 'shared/hooks/useEventCallback'

type ReferenceAnalysisSelectorProps = {
  availableReferences: BrickAnalysis[]
  onSelectReference: (id: string) => void
  selectedReference?: string
}

export const ReferenceAnalysisSelector: FC<ReferenceAnalysisSelectorProps> = ({
  availableReferences,
  onSelectReference,
  selectedReference,
}) => {
  const [isOpen, setIsOpen] = useState(false)
  const [searchValue, setSearchValue] = useState('')

  const selectorRef = useRef<HTMLDivElement>(null)

  const { floatingStyles, refs, context } = useFloating({
    ...DEFAULT_USE_FLOATING_PROPS,
    open: isOpen,
    onOpenChange: setIsOpen,
    transform: false,
    placement: 'bottom-end',
  })
  const { getReferenceProps, getFloatingProps } = useInteractions([
    useHover(context, { handleClose: safePolygon() }),
    useDismiss(context, { escapeKey: true }),
  ])

  const selectedAnalysisName = availableReferences.find(
    analysis => analysis.id === selectedReference,
  )?.name

  const availableAnalyses = availableReferences.filter(analysis =>
    analysis.name.includes(searchValue),
  )

  const handleSelectReference = useEventCallback((analysisId: string) => {
    onSelectReference(analysisId)
    setIsOpen(false)
  })

  return (
    <ReferenceAnalysisSelectorRoot ref={selectorRef}>
      <Tooltip title={selectedAnalysisName ?? ''} enterDelay={500}>
        <div>
          <StyledButton
            ref={refs.setReference}
            onClick={() => setIsOpen(!isOpen)}
            endIcon={selectedReference ? <Cached /> : <Add />}
            {...getReferenceProps()}
          >
            {selectedAnalysisName ?? 'Select analysis'}
          </StyledButton>
        </div>
      </Tooltip>
      {isOpen && (
        <FloatingPortal root={selectorRef.current}>
          <AnalysisListContainer
            ref={refs.setFloating}
            style={floatingStyles}
            onClick={event => event.stopPropagation()}
            {...getFloatingProps()}
          >
            <SearchableAnalysisList
              availableAnalyses={availableAnalyses}
              validationAction="select-analysis-for-transfer-fit"
              onSearchValueChange={setSearchValue}
              onAnalysisClick={handleSelectReference}
              onCloseContextMenu={() => setIsOpen(false)}
            />
          </AnalysisListContainer>
        </FloatingPortal>
      )}
    </ReferenceAnalysisSelectorRoot>
  )
}

const ReferenceAnalysisSelectorRoot = styled.div``

const StyledButton = styled(Button)`
  position: relative;
  width: 160px;
  justify-content: start;
  padding: inherit 0 inherit;
  background: white;
  color: ${props => props.theme.colors.greyscale[30]};
  border-radius: 50px;

  & .MuiButton-label {
    display: block;
    width: 120px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;

    & .MuiButton-endIcon {
      position: absolute;
      right: 12px;
      top: 8px;
    }
  }

  &:hover {
    background: ${props => props.theme.colors.primary[20]};
    color: ${props => props.theme.colors.greyscale[30]};
  }
`

const AnalysisListContainer = styled.div`
  z-index: 1301;
  box-shadow: 2px 2px 10px 2px rgba(0, 0, 0, 0.1);
  border-radius: 4px;
  overflow: hidden;
  display: grid;
  grid-template-rows: 1fr;
`
