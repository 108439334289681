import {
  Button as MuiButton,
  ButtonProps as MuiButtonProps,
  CircularProgress,
} from '@material-ui/core'
import React, { ReactNode } from 'react'
import styled from 'styled-components'

import { Theme } from 'Theme'

type ButtonProps = Readonly<{
  loading?: boolean
  disableWhileLoading?: boolean
  children?: ReactNode
  grey?: boolean
}> &
  MuiButtonProps

export const Button = React.forwardRef<HTMLButtonElement, ButtonProps>(
  (
    {
      loading,
      disableWhileLoading = true,
      children,
      className,
      grey,
      ...props
    },
    ref,
  ) => {
    return (
      <StyledMuiButton
        ref={ref}
        {...props}
        disableElevation={true}
        disabled={(disableWhileLoading && loading) || props.disabled}
        className={className}
        $grey={grey}
      >
        {loading ? (
          <StyledCircularProgress
            size={20}
            $disableWhileLoading={disableWhileLoading}
          />
        ) : (
          children
        )}
      </StyledMuiButton>
    )
  },
)

const StyledMuiButton = styled(MuiButton)<{ $grey: ButtonProps['grey'] }>`
  font-family: ${props => props.theme.font.style.bold};
  padding: 16px 32px;
  max-height: 50px;
  color: ${props =>
    props.$grey
      ? props.theme.colors.primaryDark[100]
      : props.theme.colors.white};
  background: ${props =>
    props.$grey
      ? getBackgroundColor(props)[20]
      : getBackgroundColor(props)[100]};
  &:hover {
    background: ${props =>
      props.$grey
        ? props.theme.colors.greyscale[10]
        : getBackgroundColor(props)[70]};
    color: ${props =>
      props.$grey
        ? props.theme.colors.primaryDark[100]
        : props.theme.colors.white};
  }
  &:disabled {
    background: ${props => getBackgroundColor(props)[20]};
    color: ${props => getBackgroundColor(props)[50]};
    & svg > path {
      fill: ${props => getBackgroundColor(props)[50]};
    }
  }
  & svg > circle {
    fill: none;
  }
`

const StyledCircularProgress = styled(CircularProgress)<{
  $disableWhileLoading: ButtonProps['disableWhileLoading']
}>`
  color: ${props =>
    props.$disableWhileLoading
      ? props.theme.colors.primaryDark[100]
      : props.theme.colors.white};
`

const getBackgroundColor = (props: {
  color?: ButtonProps['color']
  theme: Theme
}) =>
  props.color === 'secondary'
    ? props.theme.colors.secondary
    : props.theme.colors.primaryDark
