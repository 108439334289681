import { MenuItem, Select } from '@material-ui/core'
import { FC, useState } from 'react'
import styled from 'styled-components'

import { Icon } from 'assets/images/icons/Icon'

import { Button } from 'components/Button'
import { Checkbox } from 'components/Checkbox'

import { Graph } from 'shared/models/Graphs'
import { useAppDispatch, useAppSelector } from 'shared/store'

import { updateChartType } from './store/analysis.slice'
import { selectChannels, selectChannelById } from './store/selectors'

type GraphChangeTypeProps = {
  graph: Graph
  onClose: () => void
}

export const GraphChangeType: FC<GraphChangeTypeProps> = props => {
  const { graph, onClose } = props
  const channelById = useAppSelector(selectChannelById)

  // Hooks
  const [selectedXAxis, setSelectedXAxis] = useState(graph.x_axis)
  const [selectedYAxis, setSelectedYAxis] = useState(graph.x_axis)
  const [xAxisScaleType, setXAxisScaleType] = useState(graph.x_axis_scale_type)
  const [yAxisScaleType, setYAxisScaleType] = useState(graph.y_axis_scale_type)

  const dispatch = useAppDispatch()
  const channels = useAppSelector(selectChannels)

  // Methods
  const updateGraphType = () => {
    const updatedGraph: Graph = {
      ...graph,
      x_axis: selectedXAxis,
      y_axis: selectedYAxis,
      chart_type: graph.chart_type === 'Dot plot' ? 'Histogram' : 'Dot plot',
      gates: [],
    }
    dispatch(updateChartType(updatedGraph))
    onClose()
  }

  const updateAxesType = () => {
    const updatedGraph: Graph = {
      ...graph,
      x_axis_scale_type: xAxisScaleType,
      y_axis_scale_type: yAxisScaleType,
      gates: [],
    }
    dispatch(updateChartType(updatedGraph))
    onClose()
  }

  const channelSelector = (axis: 'selectedXAxis' | 'selectedYAxis') => {
    return (
      <StyledSelect
        value={axis === 'selectedXAxis' ? selectedXAxis : selectedYAxis}
        onChange={e => {
          if (axis === 'selectedXAxis')
            setSelectedXAxis(e.target.value as string)
          else setSelectedYAxis(e.target.value as string)
        }}
        disableUnderline
        IconComponent={() => <Icon name="smallArrowDown" />}
      >
        {channels.map(channel => (
          <StyledMenuItem value={channel.id} key={channel.id}>
            {channel.__computed__displayName}
          </StyledMenuItem>
        ))}
      </StyledSelect>
    )
  }

  return (
    <Wrapper>
      <Title>
        Change graph to{' '}
        <b>{graph.chart_type === 'Dot plot' ? 'Histogram' : 'Dot plot'}</b>
      </Title>
      <div>
        <Selector>X axis : {channelSelector('selectedXAxis')}</Selector>

        {graph.chart_type === 'Histogram' && (
          <Selector>Y axis : {channelSelector('selectedYAxis')}</Selector>
        )}
      </div>
      <ValidationWrapper>
        <StyledButton onClick={updateGraphType}>Change graph type</StyledButton>
        <StyledButton grey onClick={onClose}>
          Cancel
        </StyledButton>
      </ValidationWrapper>
      <Title>Change axes scale type</Title>
      <AxisSelector>
        {'X axis : Linear'}
        <Checkbox
          checked={
            xAxisScaleType === 'linear' &&
            channelById[graph.x_axis]?.type === 'morpho'
          }
          onClick={() => setXAxisScaleType('linear')}
          disabled={channelById[graph.x_axis]?.type !== 'morpho'}
        />
        {' Logarithmic'}
        <Checkbox
          checked={
            xAxisScaleType === 'logarithmic' ||
            channelById[graph.x_axis]?.type !== 'morpho'
          }
          onClick={() => setXAxisScaleType('logarithmic')}
          disabled={channelById[graph.x_axis]?.type !== 'morpho'}
        />
      </AxisSelector>
      {graph.chart_type === 'Dot plot' && graph.y_axis && (
        <AxisSelector>
          {'Y axis : Linear'}
          <Checkbox
            checked={
              yAxisScaleType === 'linear' &&
              channelById[graph.y_axis]?.type === 'morpho'
            }
            onClick={() => setYAxisScaleType('linear')}
            disabled={channelById[graph.y_axis]?.type !== 'morpho'}
          />
          {' Logarithmic'}
          <Checkbox
            checked={
              yAxisScaleType === 'logarithmic' ||
              channelById[graph.y_axis]?.type !== 'morpho'
            }
            onClick={() => setYAxisScaleType('logarithmic')}
            disabled={channelById[graph.y_axis]?.type !== 'morpho'}
          />
        </AxisSelector>
      )}
      <ValidationWrapper>
        <StyledButton onClick={updateAxesType}>Change axes type</StyledButton>
        <StyledButton grey onClick={onClose}>
          Cancel
        </StyledButton>
      </ValidationWrapper>
    </Wrapper>
  )
}

const Wrapper = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  padding: 12px;
  background: white;
  z-index: 2;
  width: 100%;
  height: 100%;
  flex: 1;
  display: flex;
  flex-direction: column;
  text-align: left;
  & > * {
    width: 100%;
  }
`
const Title = styled.div`
  font-size: ${props => props.theme.font.size.h3}px;
  font-weight: bold;
  margin-bottom: 16px;
  user-select: none;
`
const StyledSelect = styled(Select)`
  height: 30px;
  color: ${props => props.theme.colors.primaryDark[100]};
  font-size: ${props => props.theme.font.size.small}px;
  max-width: 200px;
  max-height: 200px;
  min-width: 100px;
  border: 1px solid ${props => props.theme.colors.primaryDark[20]};
  padding: 2px;
`
const Selector = styled.div`
  font-size: ${props => props.theme.font.size.small}px;
  margin-bottom: 4px;
  padding: 2px;
`
const AxisSelector = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`
const StyledMenuItem = styled(MenuItem)`
  color: ${props => props.theme.colors.primaryDark[100]}px;
  font-size: ${props => props.theme.font.size.small}px;
`
const ValidationWrapper = styled.div`
  display: flex;
  justify-content: center;
  margin-bottom: 5px;
`
const StyledButton = styled(Button)`
  height: 30px;
  margin: 10px;
  font-size: ${props => props.theme.font.size.small}px;
`
