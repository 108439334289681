import { COLOR_PICKER_SIZE } from 'shared/constants'
import { AnchorPoint } from 'shared/models/AnchorPoint'

export const positionColorMenu = ({ x, y }: AnchorPoint): AnchorPoint => {
  return {
    x:
      x < window.innerWidth - COLOR_PICKER_SIZE.width
        ? x
        : x - COLOR_PICKER_SIZE.width,
    y:
      y < window.innerHeight - COLOR_PICKER_SIZE.height
        ? y
        : y - COLOR_PICKER_SIZE.height,
  }
}
