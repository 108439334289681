import { Link, useParams } from 'react-router-dom'
import styled, { css } from 'styled-components'

import { ReactComponent as BackCircleIcon } from 'assets/images/icons/back-circle.svg'
import { ReactComponent as CompensatedFilesIcon } from 'assets/images/icons/compensated-file.svg'
import { ReactComponent as CompensationMatrixIcon } from 'assets/images/icons/compensation-matrix.svg'
import { ReactComponent as ExperimentsIconIcon } from 'assets/images/icons/experiments.svg'
import { ReactComponent as FcsFileIcon } from 'assets/images/icons/fcs-file.svg'
import { ReactComponent as HelpIcon } from 'assets/images/icons/help.svg'
import { ReactComponent as InfoIcon } from 'assets/images/icons/info.svg'
import { ReactComponent as PipelinesIcon } from 'assets/images/icons/pipelines.svg'
import { ReactComponent as WorkflowsIcon } from 'assets/images/icons/workflows.svg'

import { MetaflowLogoLink } from 'components/MetaflowLogoLink'

import { useGetProjectQuery } from 'shared/api/projects.api'

import { ProjectPageParams } from './ProjectPage'

type NavigationSidebarProps = {
  projectId: string
}

export const NavigationSidebar: React.FC<NavigationSidebarProps> = ({
  projectId,
}) => {
  const { tab } = useParams<ProjectPageParams>()
  const projectQueryState = useGetProjectQuery(projectId)

  return (
    <NavigationSidebarRoot>
      <MetaflowLogoLink />
      <BackButton to="/projects">
        <BackCircleIcon />
        <Title>{projectQueryState.data?.name}</Title>
      </BackButton>
      <Links>
        <StyledLink $isActive={tab === 'raw-fcs-files'} to="raw-fcs-files">
          <FcsFileIcon />
          <Title>Raw FCS Files</Title>
        </StyledLink>
        <StyledLink
          $isActive={tab === 'compensation-matrix'}
          to="compensation-matrix"
        >
          <CompensationMatrixIcon />
          <Title>Compensation & Unmixing</Title>
        </StyledLink>
        <StyledLink
          $isActive={tab === 'compensated-files'}
          to="compensated-files"
        >
          <CompensatedFilesIcon />
          <Title>Compensated Files</Title>
        </StyledLink>
        <StyledLink $isActive={tab === 'workflows'} to="workflows">
          <WorkflowsIcon />
          <Title>Workflows</Title>
        </StyledLink>
        <StyledLink $isActive={tab === 'pipelines'} to="pipelines">
          <PipelinesIcon />
          <Title>Pipelines</Title>
        </StyledLink>
        <StyledLink $isActive={tab === 'experiments'} to="experiments">
          <ExperimentsIconIcon />
          <Title>Experiments</Title>
        </StyledLink>
        <StyledLink $isActive={tab === 'help'} to="help">
          <HelpIcon />
          <Title>Help</Title>
        </StyledLink>
        <StyledLink $isActive={tab === 'about'} to="about">
          <InfoIcon />
          <Title>About this project</Title>
        </StyledLink>
      </Links>
    </NavigationSidebarRoot>
  )
}

const NavigationSidebarRoot = styled.nav`
  width: 320px;
  background-color: #ffffff;
  padding: 70px 40px;
  display: flex;
  flex-direction: column;
  border-radius: 0 60px 60px 0;
`

const Links = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
`

const StyledLink = styled(Link)<{ $isActive?: boolean }>`
  display: flex;
  align-items: center;
  gap: 10px;
  padding: 10px 20px;
  color: #bcbcbc;
  font-size: 14px;
  font-family: 'OpenSans-Bold';
  text-decoration: none;

  ${props =>
    props.$isActive &&
    css`
      color: ${props.theme.colors.primaryDark[100]};
      border-radius: 6px;
      background-color: #f9f9f9;
    `}
`

const BackButton = styled(StyledLink)`
  margin-bottom: 30px;
  margin-top: 40px;
  color: ${props => props.theme.colors.primaryDark[50]};
`

const Title = styled.span``
