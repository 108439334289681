import styled from 'styled-components'

type WizardStepperProps = {
  steps: string[]
  currentStepIndex: number
  className?: string
}

export const WizardStepper: React.FC<WizardStepperProps> = ({
  steps,
  currentStepIndex,
  className,
}) => {
  return (
    <WizardStepperRoot className={className}>
      <Steps>
        {steps.map((_, index) => (
          <Step key={index} $isFilled={index <= currentStepIndex} />
        ))}
      </Steps>
      <CurrentStepLabel>{steps[currentStepIndex]}</CurrentStepLabel>
    </WizardStepperRoot>
  )
}

const WizardStepperRoot = styled.div``

const Steps = styled.div`
  display: flex;
  gap: 4px;
`

const Step = styled.div<{ $isFilled: boolean }>`
  width: 50px;
  height: 7px;
  border-radius: 26px;
  background-color: ${({ $isFilled, theme }) =>
    $isFilled ? theme.colors.primaryDark[100] : '#bcbcbc'};
`

const CurrentStepLabel = styled.p`
  margin-top: 8px;
`
