import { Switch } from '@material-ui/core'
import { capitalize } from 'lodash'

type ToggleInputProps<TValue extends string> = {
  label?: string
  value: NoInfer<TValue>
  options: readonly [TValue, TValue]
  onToggle: () => void
}

export const ToggleInput = <T extends string>({
  onToggle,
  label,
  value,
  options,
}: ToggleInputProps<T>): JSX.Element => {
  return (
    <>
      {label && <strong>{label}: </strong>}
      {capitalize(options[0])}
      <Switch
        checked={value !== options[0]}
        color="primary"
        onClick={onToggle}
      />
      {capitalize(options[1])}
    </>
  )
}
