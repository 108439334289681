import React, { FC, SVGProps } from 'react'
import styled from 'styled-components'

import { ReactComponent as AddIcon } from './add.svg'
import { ReactComponent as ArrowDownLight } from './arrow-down-light.svg'
import { ReactComponent as ArrowRotateRight } from './arrow-rotate-right.svg'
import { ReactComponent as ArrowUpLight } from './arrow-up-light.svg'
import { ReactComponent as ArrowLeft } from './arrowLeft.svg'
import { ReactComponent as ArrowRight } from './arrowRight.svg'
import { ReactComponent as ArrowUp } from './arrowUp.svg'
import { ReactComponent as Batch } from './batch.svg'
import { ReactComponent as Box } from './box.svg'
import { ReactComponent as Brain } from './brain.svg'
import { ReactComponent as Browser } from './browser.svg'
import { ReactComponent as CaretDown } from './caret-down.svg'
import { ReactComponent as CaretUpAndDown } from './caret-up-and-down.svg'
import { ReactComponent as CaretUp } from './caret-up.svg'
import { ReactComponent as Channels } from './channels.svg'
import { ReactComponent as ChartPie } from './chart-pie.svg'
import { ReactComponent as Check } from './check.svg'
import { ReactComponent as ChevronDown } from './chevron-down.svg'
import { ReactComponent as CloseIcon } from './close.svg'
import { ReactComponent as Clustering } from './clustering.svg'
import { ReactComponent as ClusteringChannels } from './clusteringChannels.svg'
import { ReactComponent as Cog } from './cog.svg'
import { ReactComponent as Conversation } from './conversation.svg'
import { ReactComponent as Copy } from './copy.svg'
import { ReactComponent as Danger } from './danger.svg'
import { ReactComponent as Delete } from './delete.svg'
import { ReactComponent as DragHandle } from './drag-handle.svg'
import { ReactComponent as Duplicate } from './duplicate.svg'
import { ReactComponent as EditIcon } from './edit.svg'
import { ReactComponent as Exchange } from './exchange.svg'
import { ReactComponent as Expand } from './expand.svg'
import { ReactComponent as Export } from './export.svg'
import { ReactComponent as EyeClosed } from './eyeClosed.svg'
import { ReactComponent as EyeOpen } from './eyeOpen.svg'
import { ReactComponent as FileUpload } from './file_upload.svg'
import { ReactComponent as Files } from './files.svg'
import { ReactComponent as Flow } from './flow.svg'
import { ReactComponent as Folder } from './folder.svg'
import { ReactComponent as Gating } from './gating.svg'
import { ReactComponent as Gear } from './gear.svg'
import { ReactComponent as Glossary } from './glossary.svg'
import { ReactComponent as Goto } from './goto.svg'
import { ReactComponent as Hand } from './hand.svg'
import { ReactComponent as Home } from './home.svg'
import { ReactComponent as Less } from './less.svg'
import { ReactComponent as Link } from './link.svg'
import { ReactComponent as List } from './list-ul-solid.svg'
import { ReactComponent as Loaded } from './loaded.svg'
import { ReactComponent as LoadingError } from './loading_error.svg'
import { ReactComponent as Logbook } from './logbook.svg'
import { ReactComponent as Logout } from './logout.svg'
import { ReactComponent as MagnifyingGlassChart } from './magnifying-glass-chart.svg'
import { ReactComponent as More } from './more.svg'
import { ReactComponent as Move } from './move.svg'
import { ReactComponent as Network } from './network.svg'
import { ReactComponent as Open } from './open.svg'
import { ReactComponent as Palette } from './palette.svg'
import { ReactComponent as Play } from './play.svg'
import { ReactComponent as Plus } from './plus.svg'
import { ReactComponent as Print } from './print.svg'
import { ReactComponent as Projects } from './projects.svg'
import { ReactComponent as RedoInactive } from './redo-inactive.svg'
import { ReactComponent as Redo } from './redo.svg'
import { ReactComponent as Save } from './save.svg'
import { ReactComponent as Search } from './search.svg'
import { ReactComponent as Settings } from './settings.svg'
import { ReactComponent as Signature } from './signature.svg'
import { ReactComponent as SmallArrowDown } from './smallArrowDown.svg'
import { ReactComponent as SmallArrowRight } from './smallArrowRight.svg'
import { ReactComponent as SortAlphaDownLight } from './sort-alpha-down-light.svg'
import { ReactComponent as SortAlphaUpLight } from './sort-alpha-up-light.svg'
import { ReactComponent as SortNumericDownLight } from './sort-numeric-down-light.svg'
import { ReactComponent as SortNumericUpLight } from './sort-numeric-up-light.svg'
import { ReactComponent as Sort } from './sort.svg'
import { ReactComponent as StockGrowing } from './stock-growing.svg'
import { ReactComponent as Sun } from './sun.svg'
import { ReactComponent as UndoInactive } from './undo-inactive.svg'
import { ReactComponent as Undo } from './undo.svg'
import { ReactComponent as UnExpand } from './unexpand.svg'
import { ReactComponent as UserGroup } from './user-group-solid.svg'
import { ReactComponent as User } from './user.svg'
import { ReactComponent as Warning } from './warning.svg'

type IconProps = {
  name: IconNames
} & React.SVGProps<SVGSVGElement>

export type IconNames =
  | 'play'
  | 'list'
  | 'close'
  | 'edit'
  | 'eyeClosed'
  | 'eyeOpen'
  | 'export'
  | 'arrowRight'
  | 'add'
  | 'clustering'
  | 'clusteringChannels'
  | 'arrowLeft'
  | 'arrowUp'
  | 'loaded'
  | 'check'
  | 'box'
  | 'fileUpload'
  | 'folder'
  | 'loadingError'
  | 'delete'
  | 'channels'
  | 'hand'
  | 'less'
  | 'plus'
  | 'duplicate'
  | 'move'
  | 'settings'
  | 'more'
  | 'expand'
  | 'smallArrowDown'
  | 'smallArrowRight'
  | 'warning'
  | 'danger'
  | 'open'
  | 'gating'
  | 'undo'
  | 'undoInactive'
  | 'redo'
  | 'redoInactive'
  | 'logbook'
  | 'print'
  | 'batch'
  | 'save'
  | 'unexpand'
  | 'glossary'
  | 'exchange'
  | 'search'
  | 'palette'
  | 'signature'
  | 'sort'
  | 'arrowUpLight'
  | 'arrowDownLight'
  | 'sortAlphaUpLight'
  | 'sortAlphaDownLight'
  | 'sortNumericUpLight'
  | 'sortNumericDownLight'
  | 'cog'
  | 'brain'
  | 'browser'
  | 'conversation'
  | 'flow'
  | 'home'
  | 'chevronDown'
  | 'link'
  | 'copy'
  | 'caretUp'
  | 'caretDown'
  | 'caretUpAndDown'
  | 'files'
  | 'logout'
  | 'gear'
  | 'goto'
  | 'dragHandle'
  | 'chartPie'
  | 'sun'
  | 'network'
  | 'arrowRotateRight'
  | 'magnifyingGlassChart'
  | 'stockGrowing'
  | 'userGroup'
  | 'user'
  | 'projects'

const icons: { [key in IconNames]: FC<SVGProps<SVGSVGElement>> } = {
  play: Play,
  list: List,
  close: CloseIcon,
  edit: EditIcon,
  eyeClosed: EyeClosed,
  eyeOpen: EyeOpen,
  export: Export,
  arrowRight: ArrowRight,
  arrowUp: ArrowUp,
  add: AddIcon,
  clustering: Clustering,
  clusteringChannels: ClusteringChannels,
  arrowLeft: ArrowLeft,
  loaded: Loaded,
  check: Check,
  box: Box,
  fileUpload: FileUpload,
  loadingError: LoadingError,
  delete: Delete,
  channels: Channels,
  hand: Hand,
  less: Less,
  plus: Plus,
  duplicate: Duplicate,
  move: Move,
  settings: Settings,
  more: More,
  expand: Expand,
  smallArrowDown: SmallArrowDown,
  smallArrowRight: SmallArrowRight,
  warning: Warning,
  danger: Danger,
  open: Open,
  gating: Gating,
  undo: Undo,
  redo: Redo,
  undoInactive: UndoInactive,
  redoInactive: RedoInactive,
  logbook: Logbook,
  print: Print,
  batch: Batch,
  save: Save,
  unexpand: UnExpand,
  glossary: Glossary,
  exchange: Exchange,
  search: Search,
  palette: Palette,
  signature: Signature,
  sort: Sort,
  arrowUpLight: ArrowUpLight,
  arrowDownLight: ArrowDownLight,
  sortAlphaUpLight: SortAlphaUpLight,
  sortAlphaDownLight: SortAlphaDownLight,
  sortNumericUpLight: SortNumericUpLight,
  sortNumericDownLight: SortNumericDownLight,
  cog: Cog,
  brain: Brain,
  browser: Browser,
  conversation: Conversation,
  flow: Flow,
  home: Home,
  chevronDown: ChevronDown,
  link: Link,
  copy: Copy,
  caretUp: CaretUp,
  caretDown: CaretDown,
  caretUpAndDown: CaretUpAndDown,
  folder: Folder,
  files: Files,
  logout: Logout,
  gear: Gear,
  goto: Goto,
  dragHandle: DragHandle,
  chartPie: ChartPie,
  sun: Sun,
  network: Network,
  arrowRotateRight: ArrowRotateRight,
  magnifyingGlassChart: MagnifyingGlassChart,
  stockGrowing: StockGrowing,
  userGroup: UserGroup,
  user: User,
  projects: Projects,
}

const _Icon = ({ name, ...rest }: IconProps) => {
  const IconComponent = icons[name]
  return <IconComponent {...rest} />
}

export const Icon = styled(_Icon)`
  path {
    fill: ${props => props.color};
    stroke: ${props => props.stroke};
  }
`
