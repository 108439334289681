import { Grid, GridSize } from '@material-ui/core'
import React from 'react'
import styled from 'styled-components'

import { Icon } from 'assets/images/icons/Icon'

import { SortingOrder } from 'shared/utils/array'

import { theme } from 'Theme'

type DefaultColumns = 'Actions'

type ColumnProps<K extends string> = {
  columnName: (DefaultColumns & K) | K | DefaultColumns
  currentSortingCategory: (DefaultColumns & K) | K | DefaultColumns
  currentSortingOrder: SortingOrder
  disabledSortingFields?: K[]
  size: GridSize
  setSortingCategory: (
    category: (DefaultColumns & K) | K | DefaultColumns,
  ) => void
  setSortingOrder: (order: SortingOrder) => void
}

export const TableColumn = <K extends string>({
  columnName,
  currentSortingCategory,
  currentSortingOrder,
  disabledSortingFields = [],
  size,
  setSortingCategory,
  setSortingOrder,
}: ColumnProps<K>): JSX.Element => {
  const setSorting = () => {
    if (currentSortingCategory !== columnName) {
      setSortingCategory(columnName)
      setSortingOrder('descending')
    } else {
      setSortingOrder(
        currentSortingOrder === 'descending' ? 'ascending' : 'descending',
      )
    }
  }

  const isSortingDisabled = ['Actions', ...disabledSortingFields].includes(
    columnName,
  )

  return (
    <Grid item xs={size}>
      <Column
        onClick={isSortingDisabled ? () => null : setSorting}
        $disabled={isSortingDisabled}
      >
        <Category>{columnName}</Category>
        {!isSortingDisabled && (
          <>
            {currentSortingCategory !== columnName ? (
              <StyledIcon
                name="caretUpAndDown"
                color={theme.colors.greyscale[50]}
              />
            ) : currentSortingOrder === 'descending' ? (
              <StyledIcon name="caretDown" color={theme.colors.greyscale[50]} />
            ) : (
              <StyledIcon name="caretUp" color={theme.colors.greyscale[50]} />
            )}
          </>
        )}
      </Column>
    </Grid>
  )
}
const Column = styled.span<{ $disabled: boolean }>`
  display: flex;
  width: min-content;
  user-select: none;
  cursor: ${props => (props.$disabled ? 'default' : 'pointer')};
`

const Category = styled.p`
  font-size: 14px;
  line-height: 16px;
  color: ${props => props.theme.colors.greyscale[50]};
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`

const StyledIcon = styled(Icon)`
  height: 16px;
  width: 16px;
  margin-left: 4px;
`
