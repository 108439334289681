import styled from 'styled-components'

import { DotLabel } from 'components/DotLabel'

type AnalysisHistogramTooltipProps = {
  name: string
  color: string
  numberOfEvents: number
}

export const MetaAnalysisHistogramTooltip: React.FC<
  AnalysisHistogramTooltipProps
> = ({ name, color, numberOfEvents }) => {
  return (
    <MetaAnalysisHistogramTooltipRoot>
      <Header>
        <File>{name}</File>
        <StyledDotLabel $color={color} />
      </Header>
      <NumberOfEvents>{numberOfEvents} events</NumberOfEvents>
    </MetaAnalysisHistogramTooltipRoot>
  )
}

const MetaAnalysisHistogramTooltipRoot = styled.div`
  max-height: 80vh;
  overflow: hidden;
  padding: 12px;
`

const Header = styled.div`
  display: flex;
  align-items: center;
  padding-bottom: 3px;
`

const File = styled.div`
  font-family: ${props => props.theme.font.style.bold};
  font-size: 14px;
`

const StyledDotLabel = styled(DotLabel)`
  margin-left: auto;
`

const NumberOfEvents = styled.div`
  display: flex;
  align-items: center;
`
