import React, { ReactNode, useState } from 'react'
import ReCAPTCHA from 'react-google-recaptcha'
import styled from 'styled-components'

import { getInjectedEnvironmentVariable } from 'shared/utils/getInjectedEnvironmentVariable'

type ReCaptchaProps = {
  children: ReactNode
  onCompleted?: () => void
}

export const ReCaptcha = React.forwardRef<ReCAPTCHA, ReCaptchaProps>(
  ({ children, onCompleted }, ref) => {
    const [isLoaded, setIsLoaded] = useState(false)
    return (
      <>
        {isLoaded && children}
        <StyledReCAPTCHA
          sitekey={
            getInjectedEnvironmentVariable('REACT_APP_RECAPTCHA_SITE_KEY') || ''
          }
          size="normal"
          onChange={onCompleted}
          asyncScriptOnLoad={() => setIsLoaded(true)}
          ref={ref}
        />
      </>
    )
  },
)

const StyledReCAPTCHA = styled(ReCAPTCHA)`
  width: min-content;
  margin: 1rem auto;
`
