import { Route, Switch, useParams } from 'react-router-dom'
import styled from 'styled-components'

import { About } from './About'
import { CompensatedFiles } from './CompensatedFiles'
import { CompensationMatrix } from './CompensationMatrix'
import { Experiments } from './Experiments'
import { Help } from './Help'
import { NavigationSidebar } from './NavigationSidebar'
import { Pipelines } from './Pipelines'
import { RawFcsFiles } from './RawFcsFiles'
import { Workflows } from './Workflows'
import backgroundImage from './background-image.svg'

export type ProjectPageParams = {
  projectId: string
  tab: string
}

export const ProjectPage: React.FC = () => {
  const { projectId } = useParams<ProjectPageParams>()

  return (
    <ProjectPageRoot>
      <NavigationSidebar projectId={projectId} />
      <Switch>
        <Route path="/projects/:projectId/raw-fcs-files">
          <RawFcsFiles projectId={projectId} />
        </Route>
        <Route path="/projects/:projectId/compensation-matrix">
          <CompensationMatrix projectId={projectId} />
        </Route>
        <Route path="/projects/:projectId/compensated-files">
          <CompensatedFiles projectId={projectId} />
        </Route>
        <Route path="/projects/:projectId/workflows">
          <Workflows projectId={projectId} />
        </Route>
        <Route path="/projects/:projectId/pipelines">
          <Pipelines projectId={projectId} />
        </Route>
        <Route path="/projects/:projectId/experiments">
          <Experiments projectId={projectId} />
        </Route>
        <Route path="/projects/:projectId/help">
          <Help projectId={projectId} />
        </Route>
        <Route path="/projects/:projectId/about">
          <About projectId={projectId} />
        </Route>
      </Switch>
    </ProjectPageRoot>
  )
}

const ProjectPageRoot = styled.div`
  background-color: #f9f9f9;
  background-image: url(${backgroundImage});
  background-repeat: no-repeat;
  background-position: bottom right;
  display: grid;
  grid-template-columns: auto 1fr;
  min-height: 100vh;
`
