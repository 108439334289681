import { Delete, Edit, Replay } from '@material-ui/icons'
import { isEqual } from 'lodash'
import { useMemo, useState } from 'react'
import styled from 'styled-components'

import { LoadingMask } from 'components/LoadingMask'
import { Modal } from 'components/Modal'
import { LayoutItemCard } from 'components/graphs/LayoutItemCard'
import { LayoutItemCardHeader } from 'components/graphs/LayoutItemCardHeader'

import {
  GetStatisticsComputationResponse,
  useGetStatisticsComputationResponseQuery,
  useStartStatisticsComputationRequestMutation,
} from 'shared/api/analysis.api'
import { useEventCallback } from 'shared/hooks/useEventCallback'
import { useObserve } from 'shared/hooks/useObserve'
import { useStatefulRequest } from 'shared/hooks/useStatefulRequest'
import { useAppDispatch, useAppSelector } from 'shared/store'

import { AnalysisStatisticsTable } from './AnalysisStatisticsTable'
import { AnalysisStatisticsWizard } from './AnalysisStatisticsWizard'
import {
  changeStatistics,
  changeStatisticsName,
  deleteStatistic,
  markStatisticInitialComputationRequestAsTriggered,
} from './store/analysis.history.slice'
import {
  selectAnalysisSelectedGraphicalElementIds,
  toggleAnalysisGraphicalElementSelection,
} from './store/selected-graphical-elements.slice'
import {
  selectAnalysisLassos,
  selectChannelDisplayNames,
  selectClusterById,
} from './store/selectors'
import { selectGatesById } from './store/selectors/gates.selectors'

type AnalysisStatisticsProps = {
  statisticsItem: Analysis.StatisticsItem
  isExpanded?: boolean
  className?: string
  onCloseExpand?: () => void
}

export const AnalysisStatistics: React.FC<AnalysisStatisticsProps> = ({
  statisticsItem,
  isExpanded,
  className,
  onCloseExpand,
}) => {
  const dispatch = useAppDispatch()
  const lassoById = useAppSelector(selectAnalysisLassos)
  const gateById = useAppSelector(selectGatesById)
  const selectedGraphicalElements = useAppSelector(
    selectAnalysisSelectedGraphicalElementIds,
  )
  const clusterById = useAppSelector(selectClusterById)
  const channelDisplayNameById = useAppSelector(selectChannelDisplayNames)

  const [shouldShowWizard, setShouldShowWizard] = useState(false)
  const [shouldShowStaleDataWarning, setShouldShowStaleDataWarning] =
    useState(false)
  const [shouldShowExpandedSelf, setShouldShowExpandedSelf] = useState(false)

  const {
    isLoading,
    isError,
    start: loadStatistics,
  } = useStatefulRequest({
    useStartRequestMutation: useStartStatisticsComputationRequestMutation,
    useGetResponseQuery: useGetStatisticsComputationResponseQuery,
  })

  const handleLoadStatistics = useEventCallback(
    (response: GetStatisticsComputationResponse) => {
      if (response.isReady) {
        dispatch(
          changeStatistics({
            id: statisticsItem.id,
            statistics: response.statistics,
          }),
        )
      }
      return { isFinished: response.isReady }
    },
  )

  const isSelected = useMemo(
    () => selectedGraphicalElements.includes(statisticsItem.id),
    [selectedGraphicalElements, statisticsItem.id],
  )

  const handleSelection = useEventCallback(() => {
    dispatch(toggleAnalysisGraphicalElementSelection(statisticsItem.id))
  })

  const handleChangeName = useEventCallback((value: string) => {
    dispatch(changeStatisticsName({ id: statisticsItem.id, name: value }))
  })

  const handleRecompute = useEventCallback(() => {
    loadStatistics(statisticsItem.statistics, handleLoadStatistics)
    setShouldShowStaleDataWarning(false)
  })

  const handleOpenWizard = useEventCallback(() => {
    setShouldShowWizard(true)
  })

  const handleCloseWizard = useEventCallback(() => {
    setShouldShowWizard(false)
  })

  const handleConfigurationChange = useEventCallback(
    (configuration: Analysis.Statistics) => {
      loadStatistics(configuration, handleLoadStatistics)
      dispatch(
        changeStatistics({ id: statisticsItem.id, statistics: configuration }),
      )
      handleCloseWizard()
    },
  )

  const handleDelete = useEventCallback(() => {
    dispatch(deleteStatistic({ id: statisticsItem.id }))
  })

  useObserve(
    statisticsItem.shouldTriggerInitialComputationRequest,
    shouldTriggerInitialComputationRequest => {
      if (shouldTriggerInitialComputationRequest) {
        loadStatistics(statisticsItem.statistics, handleLoadStatistics)
        dispatch(
          markStatisticInitialComputationRequestAsTriggered({
            id: statisticsItem.id,
          }),
        )
      }
    },
  )

  useObserve(statisticsItem.recalculate, recalculate => {
    if (recalculate) {
      loadStatistics(statisticsItem.statistics, handleLoadStatistics)
    }
  })

  useObserve(
    useMemo(() => ({ lassoById, gateById }), [gateById, lassoById]),
    (current, previous) => {
      if (!previous) {
        return
      }

      for (const reportable of statisticsItem.statistics.reportables) {
        for (const statistic of reportable.statistics) {
          let currentEntity: unknown
          let previousEntity: unknown
          if (statistic.parameter?.type === 'lasso') {
            currentEntity = current.lassoById[statistic.parameter.id]
            previousEntity = previous.lassoById[statistic.parameter.id]
          } else if (statistic.parameter?.type === 'gate') {
            currentEntity = current.gateById[statistic.parameter.id]
            previousEntity = previous.gateById[statistic.parameter.id]
          }

          if (
            currentEntity &&
            previousEntity &&
            !isEqual(currentEntity, previousEntity)
          ) {
            setShouldShowStaleDataWarning(true)
            break
          }
        }
      }
    },
    isEqual,
  )

  return (
    <AnalysisStatisticsRoot $isExpanded={isExpanded} className={className}>
      <StyledLayoutItemCardHeader
        title={statisticsItem.name || statisticsItem.default_name}
        actions={[
          {
            onClick: handleRecompute,
            icon: <Replay />,
            tooltip: 'Recompute',
          },
        ]}
        menuActions={
          isExpanded
            ? undefined
            : [
                {
                  onClick: handleOpenWizard,
                  icon: <Edit />,
                  tooltip: 'Edit',
                },
                {
                  onClick: handleDelete,
                  icon: <Delete />,
                  tooltip: 'Delete',
                },
              ]
        }
        children={
          <>
            {shouldShowStaleDataWarning && (
              <Warning>The data might be stale</Warning>
            )}
            {isError && <Warning>There was an error</Warning>}
          </>
        }
        isSelected={isSelected}
        isExpanded={isExpanded}
        onSelect={handleSelection}
        onTitleChange={handleChangeName}
        onExpand={() => setShouldShowExpandedSelf(true)}
        onCloseExpand={onCloseExpand}
      />
      <TableContainer>
        <AnalysisStatisticsTable
          statisticsItem={statisticsItem}
          clusterById={clusterById}
          gateById={gateById}
          lassoById={lassoById}
          channelDisplayNameById={channelDisplayNameById}
        />
        {isLoading && <LoadingMask isFirstLoad />}
      </TableContainer>
      {shouldShowWizard && (
        <AnalysisStatisticsWizard
          initialValues={statisticsItem.statistics}
          onClose={handleCloseWizard}
          onApply={handleConfigurationChange}
        />
      )}
      {shouldShowExpandedSelf && (
        <Modal open onClose={() => setShouldShowExpandedSelf(false)}>
          <ExpandedAnalysisStatistics
            statisticsItem={statisticsItem}
            onCloseExpand={() => setShouldShowExpandedSelf(false)}
          />
        </Modal>
      )}
    </AnalysisStatisticsRoot>
  )
}

const AnalysisStatisticsRoot = styled(LayoutItemCard)`
  grid-template-rows: auto 1fr;
  padding: 0;
`

const StyledLayoutItemCardHeader = styled(LayoutItemCardHeader)`
  padding: 12px;
  border-bottom: 1px solid ${({ theme }) => theme.colors.greyscale[10]};
`

const TableContainer = styled.div`
  overflow: auto;
  position: relative;
  padding: 12px;
`

const Warning = styled.div`
  font-size: 10px;
  font-weight: bold;
  white-space: nowrap;
  color: ${({ theme }) => theme.colors.error};
`

const ExpandedAnalysisStatistics = styled(AnalysisStatistics).attrs({
  isExpanded: true,
})`
  width: 90vw;
  height: 90vh;
`
