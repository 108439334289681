import { LegendOptions, SeriesScatterOptions } from 'highcharts'
import { round } from 'lodash'
import { renderToStaticMarkup } from 'react-dom/server'

import { AnalysisMetaCleanChartLegendLabel } from './AnalysisMetaCleanChartLegendLabel'
import { AnalysisScatterPlotBaseOptions } from './AnalysisScatterPlotBaseOptions'

export const createAnalysisMetaCleanChartBaseOptions = (
  series: SeriesScatterOptions[] | undefined,
  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
) => {
  const metaCleanRatio = computeMetaCleanRatio(series)

  const formatLegendLabel: LegendOptions['labelFormatter'] = function (this) {
    const seriesName = this.name as 'keep' | 'drop'
    return renderToStaticMarkup(
      <AnalysisMetaCleanChartLegendLabel
        seriesName={seriesName}
        metaCleanRatio={metaCleanRatio?.[seriesName]}
      />,
    )
  }

  return {
    ...AnalysisScatterPlotBaseOptions,
    legend: {
      enabled: true,
      symbolWidth: 0,
      align: 'right' as const,
      useHTML: true,
      verticalAlign: 'top' as const,
      itemStyle: {
        fontWeight: 'normal',
      },
      labelFormatter: formatLegendLabel,
    },
    boost: {
      seriesThreshold: 1,
    },
  }
}

const computeMetaCleanRatio = (series: SeriesScatterOptions[] | undefined) => {
  return (
    series &&
    (() => {
      const [keep, drop] = ['keep', 'drop'].map(event => {
        const numberOfEvents = series.find(series => series.name === event)
          ?.data?.length
        if (numberOfEvents === undefined) {
          throw new Error(`${event} points are missing`)
        }
        return numberOfEvents
      })

      return {
        keep: round((100 * keep) / (keep + drop), 2),
        drop: round((100 * drop) / (keep + drop), 2),
      }
    })()
  )
}
