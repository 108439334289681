import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { isEqual } from 'lodash'
import { v4 as uuid } from 'uuid'

import { ChannelsListEditedLabels } from 'components/ChannelsList'
import { Layout, LayoutElement } from 'components/chart-grid'

import { LogActions } from 'shared/contexts/LogContext'
import { Analysis, HeatmapColors, Lasso } from 'shared/models/AnalysisModels'
import { Graph, GraphDisplaySettings, GraphZoom } from 'shared/models/Graphs'
import {
  checkIfClusterIsInsideLasso,
  computeHiddenClusters,
  findActiveDescendants,
  findClustersToHide,
  findClustersToShow,
  findLeaves,
  getMaxDepth,
  mergeSelectedClusters as mergeSelectedClustersUtilFunction,
} from 'shared/utils/clusters.utils'
import { rgbStringToValues } from 'shared/utils/colors'
import { findDescendantLassos } from 'shared/utils/lasso'
import { getCurrentLayoutKey } from 'shared/utils/multi-tab'
import { includeIf } from 'shared/utils/utils'

import type { AnalysisSliceState } from './analysis.slice'
import {
  selectActiveLeafIds,
  selectActiveLeavesByChartId,
  selectAnalysisClusterTreeNodeById,
  selectAnalysisLassos,
  selectChartById,
  selectClusterById,
  selectDescendentChartsById,
  selectNextFreeStatisticsName,
  selectNextLayoutPositions,
  selectParentLassoIdByLassoId,
  selectRootClusterId,
  selectUsedClusterLabels,
} from './selectors'

export type HistoryState = {
  isAnalysisSaved: boolean
  analysis: Analysis | undefined
  ui: {
    highlightedCluster?: HighlightedCluster
    clustersSortMode?: ClustersSortMode
    channelsSortMode?: ChannelsSortMode
    clusterDotSizes: ClusterDotSizesByChartId
  }
  logs: { type: LogActions; created_at: string }[]
}

export type HighlightedCluster = {
  clusterId: string
  highlightOnlyOnSunburst?: boolean
}

export type ChannelsSortMode =
  | { type: 'by-label'; order: 'asc' | 'desc' }
  | ManualSortMode
  | SelfOrganizeSortMode

export type ClustersSortMode =
  | SortByPropertyMode
  | { type: 'by-normalized-value'; order: 'asc' | 'desc'; channel: string }
  | ManualSortMode
  | SelfOrganizeSortMode

export type SortByPropertyMode = {
  type: 'by-property'
  property: string
  order: 'asc' | 'desc'
}

type ManualSortMode = {
  type: 'manual'
  order: string[]
}

type SelfOrganizeSortMode = {
  type: 'self-organize'
}

export type ClusterDotSizesByChartId = {
  [chartId: string]: {
    [clusterId: string]: number
  }
}

type HistoryStateWithDefinedAnalysis = Omit<HistoryState, 'analysis'> & {
  analysis: Analysis
}

type WrappedAnalysisSliceState = {
  analysisPage: { analysis: Required<AnalysisSliceState> }
}

/** A higher order function for the historySlice case reducers.
 * Ensures data integrity. Injects the entire analysis slice state. Updates the logs. */
const _ = <
  C extends (
    state: HistoryStateWithDefinedAnalysis,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    action: any,
    wrappedAnalysisSliceState: WrappedAnalysisSliceState,
  ) => HistoryState | void,
>(
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  log: LogActions | ((action: any) => LogActions | undefined) | undefined,
  caseReducer: C,
) => {
  type A = C extends (
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    state: any,
    action: infer A,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    wrappedAnalysisSliceState: any,
  ) => HistoryState | void
    ? A
    : never

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  return ((state: HistoryState, action: any) => {
    if (state.analysis === undefined) {
      throw new Error('Cannot perform action on undefined analysis')
    }

    log = typeof log === 'function' ? log(action) : log
    if (log !== undefined) {
      state.logs.push({ type: log, created_at: new Date().toISOString() })
    }

    state.isAnalysisSaved = false

    return caseReducer(
      state as HistoryStateWithDefinedAnalysis,
      action,
      action.wrappedAnalysisSliceState,
    )
  }) as unknown extends A
    ? (state: HistoryState) => HistoryState
    : (state: HistoryState, action: A) => HistoryState
}
const getChart = (analysis: Analysis, chartId: string): Graph => {
  const chart = analysis.graphs.find(chart => chart.id === chartId)
  if (!chart) {
    throw new Error(`Chart with id ${chartId} not found`)
  }
  return chart
}

export const INITIAL_HISTORY_STATE: HistoryState = {
  isAnalysisSaved: true,
  analysis: undefined,
  ui: { clusterDotSizes: {} },
  logs: [],
}

export const historySlice = createSlice({
  name: 'analysis/modify',
  initialState: INITIAL_HISTORY_STATE,
  reducers: {
    setSelectedChannels: _(
      undefined,
      ({ analysis }, action: PayloadAction<string[]>) => {
        analysis.sorted_selected_channels = action.payload
      },
    ),
    sortChannels: _(
      LogActions.h_clusters_and_channels_order_updated,
      (
        { ui },
        { payload: sortMode }: PayloadAction<ChannelsSortMode | undefined>,
      ) => {
        ui.channelsSortMode = sortMode
      },
    ),
    updateChannelLabels: _(
      LogActions.l_channel_name_changed,
      ({ analysis }, action: PayloadAction<ChannelsListEditedLabels>) => {
        analysis.channel_names = analysis.channel_names || {}
        for (const id in action.payload) {
          analysis.channel_names[id] = action.payload[id]
        }
      },
    ),
    sortClusters: _(
      LogActions.h_clusters_and_channels_order_updated,
      (
        { ui },
        { payload: sortMode }: PayloadAction<ClustersSortMode | undefined>,
      ) => {
        ui.clustersSortMode = sortMode
      },
    ),
    resetClusterHighlighting: _(undefined, (state: HistoryState) => {
      state.ui.highlightedCluster = undefined
    }),
    changeClusterDotSize: _(
      undefined,
      (
        { analysis, ui },
        {
          payload: { clusterIds, chartIds, size },
        }: PayloadAction<{
          chartIds?: string[]
          clusterIds: string[]
          size: number
        }>,
      ) => {
        chartIds = chartIds ?? analysis.graphs.map(graph => graph.id)

        for (const chartId of chartIds) {
          if (!ui.clusterDotSizes[chartId]) {
            ui.clusterDotSizes[chartId] = {}
          }
          for (const clusterId of clusterIds) {
            ui.clusterDotSizes[chartId][clusterId] = size
          }
        }
      },
    ),
    changeHeatmapColors: _(
      LogActions.h_legend_colour_changed,
      (state, action: PayloadAction<HeatmapColors>) => {
        state.analysis.heatmap_colors = action.payload
      },
    ),
    updateChartType: _(
      LogActions.g_graph_type_changed,
      ({ analysis }, { payload: chart }: PayloadAction<Graph>) => {
        const index = analysis.graphs.findIndex(graph => graph.id === chart.id)
        analysis.graphs[index] = chart
      },
    ),
    resetAnalysisToAutofocus: _(
      LogActions.g_graph_type_changed,
      ({ analysis }) => {
        if (
          analysis.default_depth !== null &&
          analysis.default_active_leaf_ids
        ) {
          analysis.depth = analysis.default_depth
          analysis.active_leaf_ids = analysis.default_active_leaf_ids

          for (const chart of analysis.graphs) {
            chart.depth = analysis.default_depth
            chart.active_leaf_ids = analysis.default_active_leaf_ids
          }
        }
      },
    ),
    setDepth: _(
      LogActions.s_depth_changed,
      (
        { analysis },
        {
          payload,
        }: PayloadAction<{
          depth: number
        }>,
        wrappedAnalysisSliceState,
      ) => {
        const rootClusterId = selectRootClusterId(wrappedAnalysisSliceState)
        const clusterById = selectClusterById(wrappedAnalysisSliceState)
        analysis.depth = payload.depth
        analysis.active_leaf_ids = findLeaves(
          rootClusterId,
          clusterById,
          payload.depth,
        )

        for (const chart of analysis.graphs) {
          chart.depth = payload.depth
          chart.active_leaf_ids = analysis.active_leaf_ids

          if (chart.parent_lasso_id) {
            const parentLasso = analysis.lassos[chart.parent_lasso_id]
            const parentChart = analysis.graphs.find(graph =>
              Object.keys(graph.lasso_ids).includes(parentLasso.id),
            )

            if (!parentChart) {
              continue
            }

            chart.hidden_cluster_ids = analysis.active_leaf_ids.filter(
              leafId =>
                parentChart.y_axis &&
                (parentChart.hidden_cluster_ids.includes(leafId) ||
                  !checkIfClusterIsInsideLasso(
                    clusterById[leafId],
                    parentLasso.polygon,
                    parentChart.x_axis,
                    parentChart.y_axis,
                  )),
            )
          }
        }
      },
    ),
    toggleClusterActive: _(
      LogActions.s_cluster_expanded_merged,
      (
        { analysis },
        {
          payload: { clusterId, chartId },
        }: PayloadAction<{ clusterId: string; chartId?: string }>,
        wrappedAnalysisSliceState,
      ) => {
        const clusterById = selectClusterById(wrappedAnalysisSliceState)
        const activeLeafIds = selectActiveLeafIds(wrappedAnalysisSliceState)

        const cluster = clusterById[clusterId]
        const childrenAreInactive = cluster.children
          .map(childId => clusterById[childId])
          .every(cluster => !cluster.isActive)

        let newActiveLeafIds: string[]
        if (childrenAreInactive) {
          newActiveLeafIds = [
            ...activeLeafIds.filter(id => id !== clusterId),
            ...findLeaves(clusterId, clusterById, cluster.depth + 1),
          ]
        } else {
          const activeDescendants = new Set(
            findActiveDescendants(clusterId, clusterById),
          )
          newActiveLeafIds = [
            clusterId,
            ...activeLeafIds.filter(id => !activeDescendants.has(id)),
          ]
        }
        const depth = getMaxDepth(
          newActiveLeafIds.map(id => ({ depth: clusterById[id].depth })),
        )

        const charts = (
          chartId ? [chartId] : analysis.graphs.map(graph => graph.id)
        ).map(id => getChart(analysis, id))

        for (const chart of charts) {
          chart.depth = depth
          chart.active_leaf_ids = newActiveLeafIds
        }

        analysis.depth = depth
        analysis.active_leaf_ids = newActiveLeafIds
      },
    ),
    expandClusters: _(
      LogActions.s_visible_clusters_expanded,
      (
        { analysis },
        {
          payload: {
            shouldExpandAllVisibleClusters,
            selectedLeafIds,
            depthChange = 1,
          },
        }: PayloadAction<{
          shouldExpandAllVisibleClusters?: boolean
          selectedLeafIds?: string[]
          depthChange?: number
        }>,
        wrappedAnalysisSliceState,
      ) => {
        const clusterById = selectClusterById(wrappedAnalysisSliceState)
        const currentActiveLeaves = selectActiveLeafIds(
          wrappedAnalysisSliceState,
        )
        let newActiveLeaves: string[] = []

        if (shouldExpandAllVisibleClusters) {
          newActiveLeaves = currentActiveLeaves.flatMap(leaf =>
            findLeaves(
              leaf,
              clusterById,
              clusterById[leaf].depth + depthChange,
            ),
          )
        } else if (selectedLeafIds && selectedLeafIds.length > 0) {
          newActiveLeaves = [
            ...currentActiveLeaves.filter(
              leafId => !selectedLeafIds.includes(leafId),
            ),
            ...selectedLeafIds.flatMap(leafId =>
              findLeaves(
                leafId,
                clusterById,
                clusterById[leafId].depth + depthChange,
              ),
            ),
          ]
        } else {
          return
        }

        const newDepth = getMaxDepth(
          newActiveLeaves.map(leaf => ({ depth: clusterById[leaf].depth })),
        )

        for (const chart of analysis.graphs) {
          chart.depth = newDepth
          chart.active_leaf_ids = newActiveLeaves
        }

        analysis.depth = newDepth
        analysis.active_leaf_ids = newActiveLeaves
      },
    ),
    mergeClusters: _(
      LogActions.s_visible_clusters_merged,
      (
        { analysis },
        {
          payload: { selectedLeafIds, isExclusive } = {},
        }: PayloadAction<
          | {
              selectedLeafIds?: string[]
              isExclusive?: boolean
            }
          | undefined
        >,
        wrappedAnalysisSliceState,
      ) => {
        const clusterById = selectClusterById(wrappedAnalysisSliceState)
        const activeLeafIds = selectActiveLeafIds(wrappedAnalysisSliceState)

        if (!selectedLeafIds) {
          selectedLeafIds = activeLeafIds
        }

        const newActiveLeafIds = mergeSelectedClustersUtilFunction({
          selectedClusterIds: selectedLeafIds,
          isExclusive,
          clusterById,
          activeLeafIds,
        })

        const newDepth = getMaxDepth(
          newActiveLeafIds.map(leaf => ({ depth: clusterById[leaf].depth })),
        )
        for (const chart of analysis.graphs) {
          chart.depth = newDepth
          chart.active_leaf_ids = newActiveLeafIds
        }
        analysis.depth = newDepth
        analysis.active_leaf_ids = newActiveLeafIds
      },
    ),
    mergeLassoClustersRecursively: _(
      undefined,
      (
        { analysis },
        {
          payload: { chartId, lassoId },
        }: PayloadAction<{
          chartId: string
          lassoId: string
        }>,
        wrappedAnalysisSliceState,
      ) => {
        const clusterById = selectClusterById(wrappedAnalysisSliceState)
        const activeLeafIds = selectActiveLeafIds(wrappedAnalysisSliceState)
        const lasso = selectAnalysisLassos(wrappedAnalysisSliceState)[lassoId]
        const chart = getChart(analysis, chartId)

        let newActiveLeafIds = activeLeafIds
        let shouldTryMergeAgain = true
        do {
          const previousNewActiveLeafIds = newActiveLeafIds
          const lassoClusterIds = newActiveLeafIds.filter(leafId => {
            return checkIfClusterIsInsideLasso(
              {
                stats: clusterById[leafId].stats,
                isHidden: chart.hidden_cluster_ids.includes(leafId),
              },
              lasso.polygon,
              chart.x_axis,
              chart.y_axis!,
            )
          })
          newActiveLeafIds = mergeSelectedClustersUtilFunction({
            selectedClusterIds: lassoClusterIds,
            isExclusive: true,
            clusterById,
            activeLeafIds: newActiveLeafIds,
          })

          if (isEqual(newActiveLeafIds, previousNewActiveLeafIds)) {
            shouldTryMergeAgain = false
          }
        } while (shouldTryMergeAgain)

        const newDepth = getMaxDepth(
          newActiveLeafIds.map(leaf => ({ depth: clusterById[leaf].depth })),
        )
        for (const chart of analysis.graphs) {
          chart.depth = newDepth
          chart.active_leaf_ids = newActiveLeafIds
        }
        analysis.depth = newDepth
        analysis.active_leaf_ids = newActiveLeafIds
      },
    ),
    mergeVisibleClustersRecursively: _(
      LogActions.s_visible_clusters_merged,
      (
        { analysis },
        {
          payload: { hiddenClusterIds },
        }: PayloadAction<{
          hiddenClusterIds: string[]
        }>,
        wrappedAnalysisSliceState,
      ) => {
        const clusterById = selectClusterById(wrappedAnalysisSliceState)
        const activeLeafIds = selectActiveLeafIds(wrappedAnalysisSliceState)

        let newActiveLeafIds = activeLeafIds
        let shouldTryMergeAgain = true
        do {
          const previousNewActiveLeafIds = newActiveLeafIds
          newActiveLeafIds = mergeSelectedClustersUtilFunction({
            selectedClusterIds: newActiveLeafIds.filter(
              leafId => !hiddenClusterIds.includes(leafId),
            ),
            isExclusive: true,
            clusterById,
            activeLeafIds: newActiveLeafIds,
          })

          if (isEqual(newActiveLeafIds, previousNewActiveLeafIds)) {
            shouldTryMergeAgain = false
          }
        } while (shouldTryMergeAgain)

        const newDepth = getMaxDepth(
          newActiveLeafIds.map(leaf => ({ depth: clusterById[leaf].depth })),
        )
        for (const chart of analysis.graphs) {
          chart.depth = newDepth
          chart.active_leaf_ids = newActiveLeafIds
        }
        analysis.depth = newDepth
        analysis.active_leaf_ids = newActiveLeafIds
      },
    ),
    renameCluster: _(
      undefined,
      (
        { analysis },
        {
          payload: { clusterId, name },
        }: PayloadAction<{ clusterId: string; name: string }>,
        wrappedAnalysisSliceState,
      ) => {
        const clustersById = selectClusterById(wrappedAnalysisSliceState)
        const clusterNodeById = selectAnalysisClusterTreeNodeById(
          wrappedAnalysisSliceState,
        )
        const usedClusterLabels = selectUsedClusterLabels(
          wrappedAnalysisSliceState,
        )

        const renamedCluster = clustersById[clusterId]
        if (!renamedCluster) {
          throw new Error(`Cannot find cluster ${clusterId}`)
        }

        name = name.trim()
        const newName = name.length > 0 ? name : renamedCluster.defaultLabel

        if (newName === renamedCluster.label) {
          return
        }

        if (usedClusterLabels.has(newName)) {
          return
        }

        clusterNodeById[clusterId].label = newName
        // this is needed to trigger a re-computation of the selectClusters selector
        analysis.cluster_tree = { ...analysis.cluster_tree }
      },
    ),
    changeClusterColor: _(
      undefined,
      (
        { analysis },
        {
          payload: { clusterId, color },
        }: PayloadAction<{
          clusterId: string
          color: string
          source: 'graph' | 'heatmap' | 'sunburst'
        }>,
        wrappedAnalysisSliceState,
      ) => {
        const clusterNodeById = selectAnalysisClusterTreeNodeById(
          wrappedAnalysisSliceState,
        )

        const [r, g, b] = rgbStringToValues(color)
        clusterNodeById[clusterId].color = [r, g, b]
        // this is needed to trigger a re-computation of the selectClusters selector
        analysis.cluster_tree = { ...analysis.cluster_tree }
      },
    ),
    highlightCluster: _(
      undefined,
      ({ analysis, ui }, { payload }: PayloadAction<HighlightedCluster>) => {
        const isLeaf = analysis.active_leaf_ids.some(
          leafId => leafId === payload.clusterId,
        )
        if (!isLeaf || ui.highlightedCluster?.clusterId === payload.clusterId) {
          ui.highlightedCluster = undefined
        } else {
          ui.highlightedCluster = payload
        }
      },
    ),
    addChart: _(
      LogActions.l_graph_created,
      (
        { analysis },
        {
          payload: { chart },
        }: PayloadAction<{
          chart: Omit<Graph, 'id'>
        }>,
        wrappedAnalysisSliceState,
      ) => {
        const name = `${chart.x_axis} ${
          chart.y_axis ? `vs ${chart.y_axis}` : ''
        }`

        const newChart = {
          ...chart,
          id: uuid(),
          isUnsaved: true,
          name,
          default_name: chart.default_name || name,
        }

        analysis.graphs.push(newChart)

        addItemToLayout(analysis, wrappedAnalysisSliceState, newChart.id)
      },
    ),
    removeAnalysisChart: _(
      LogActions.l_graph_deleted,
      (
        { analysis },
        { payload: id }: PayloadAction<string>,
        wrappedAnalysisSliceState,
      ) => {
        const descendentChartsById = selectDescendentChartsById(
          wrappedAnalysisSliceState,
        )
        const directChartToRemove = analysis.graphs.find(
          chart => chart.id === id,
        )
        if (!directChartToRemove) {
          throw new Error(`Cannot find chart ${id}`)
        }

        const effectiveChartsToRemove = [
          directChartToRemove,
          ...descendentChartsById[directChartToRemove.id],
        ]

        for (const chartToRemove of effectiveChartsToRemove) {
          analysis.graphs = analysis.graphs.filter(
            chart => chart.id !== chartToRemove.id,
          )
          for (const lassoId in chartToRemove.lasso_ids) {
            delete analysis.lassos[lassoId]
          }

          removeItemFromLayout(analysis, chartToRemove.id)
        }
      },
    ),
    duplicateChart: _(
      LogActions.l_graph_duplicated,
      (
        { analysis, ui },
        {
          payload: { chartId, shownClusterIds, parentLasso },
        }: PayloadAction<{
          chartId: string
          shownClusterIds?: string[]
          parentLasso?: Lasso
        }>,
        wrappedAnalysisSliceState,
      ) => {
        const chartById = selectChartById(wrappedAnalysisSliceState)
        const activeLeavesByChartId = selectActiveLeavesByChartId(
          wrappedAnalysisSliceState,
        )

        const chart = chartById[chartId]
        const id = uuid()
        const name = parentLasso ? parentLasso.name : chart.name + ' copy'
        const duplicatedChart: Graph = {
          ...chart,
          id,
          isUnsaved: true,
          duplicated: true,
          name,
          default_name: name,
          zoom: null,
          gates: chart.gates.map(gate => ({ ...gate, id: uuid() })),
          ...includeIf(shownClusterIds, {
            hidden_cluster_ids: activeLeavesByChartId[chartId]
              .map(cluster => cluster.id)
              .filter(id => !shownClusterIds?.includes(id)),
          }),
          lasso_ids: {},
          parent_lasso_id:
            parentLasso?.id && parentLasso.id in analysis.lassos
              ? parentLasso.id
              : undefined,
          lassos_hierarchy: parentLasso
            ? [
                ...chart.lassos_hierarchy,
                {
                  polygon: parentLasso.polygon,
                  x_axis: chart.x_axis,
                  y_axis: chart.y_axis as string,
                },
              ]
            : [],
          created_at: new Date().toISOString(),
        }
        analysis.graphs.push(duplicatedChart)
        ui.clusterDotSizes[id] = ui.clusterDotSizes[chartId]

        addItemToLayout(analysis, wrappedAnalysisSliceState, id)
      },
    ),
    renameAnalysisChart: _(
      LogActions.g_graph_renamed,
      (
        { analysis },
        { payload: { id, name } }: PayloadAction<{ id: string; name: string }>,
      ) => {
        const chart = getChart(analysis, id)
        chart.name = name
      },
    ),
    zoomChart: _(
      LogActions.g_axes_scale_changed,
      (
        { analysis },
        {
          payload: { chartId, zoom },
        }: PayloadAction<{ chartId: string; zoom: GraphZoom }>,
      ) => {
        const chart = getChart(analysis, chartId)
        chart.zoom = zoom
      },
    ),
    resetChartZoom: _(
      LogActions.g_axes_scale_changed,
      (
        { analysis },
        { payload: { chartId } }: PayloadAction<{ chartId: string }>,
      ) => {
        const chart = getChart(analysis, chartId)
        chart.zoom = null
      },
    ),
    changeChartDisplaySettings: _(
      LogActions.g_graph_display_settings_changed,
      (
        { analysis },
        {
          payload: { chartId, settings },
        }: PayloadAction<{ chartId: string; settings: GraphDisplaySettings }>,
      ) => {
        const chart = getChart(analysis, chartId)
        chart.display_settings = settings
      },
    ),
    setChartEventLimit: _(
      undefined,
      (
        { analysis },
        {
          payload: { chartId, eventLimit },
        }: PayloadAction<{ chartId: string; eventLimit: number }>,
      ) => {
        const chart = getChart(analysis, chartId)
        chart.event_limit = eventLimit
      },
    ),
    setChartEventLimitForAllCharts: _(
      undefined,
      (
        state,
        { payload: { eventLimit } }: PayloadAction<{ eventLimit: number }>,
      ) => {
        for (const chart of state.analysis.graphs) {
          chart.event_limit = eventLimit
        }
      },
    ),
    toggleClusterVisibility: _(
      LogActions.l_cluster_toggle_global_visibility,
      (
        { analysis },
        {
          payload: { chartId, clusterId },
        }: PayloadAction<{ chartId: string; clusterId: string }>,
        wrappedAnalysisSliceState,
      ) => {
        const clusterById = selectClusterById(wrappedAnalysisSliceState)

        const chart = getChart(analysis, chartId)
        const currentlyHidden = chart.hidden_cluster_ids.includes(clusterId)

        chart.hidden_cluster_ids = computeHiddenClusters(
          chart.hidden_cluster_ids,
          currentlyHidden
            ? {
                clustersToShow: findClustersToShow(clusterId, clusterById),
              }
            : {
                clustersToHide: findClustersToHide([clusterId], clusterById),
              },
        )
      },
    ),
    showClusterOnAllCharts: _(
      LogActions.l_cluster_toggle_global_visibility,
      (
        { analysis },
        { payload: { clusterId } }: PayloadAction<{ clusterId: string }>,
        wrappedAnalysisSliceState,
      ) => {
        const clusterById = selectClusterById(wrappedAnalysisSliceState)

        const clustersToShow = findClustersToShow(clusterId, clusterById)
        for (const chart of analysis.graphs) {
          chart.hidden_cluster_ids = computeHiddenClusters(
            chart.hidden_cluster_ids,
            {
              clustersToShow,
            },
          )
        }

        analysis.hidden_cluster_ids = computeHiddenClusters(
          analysis.hidden_cluster_ids,
          { clustersToShow },
        )
      },
    ),
    hideClustersOnAllCharts: _(
      undefined,
      (
        { analysis },
        { payload: { clusterIds } }: PayloadAction<{ clusterIds: string[] }>,
        wrappedAnalysisSliceState,
      ) => {
        const clusterById = selectClusterById(wrappedAnalysisSliceState)

        const clustersToHide = findClustersToHide(clusterIds, clusterById)
        for (const chart of analysis.graphs) {
          chart.hidden_cluster_ids = computeHiddenClusters(
            chart.hidden_cluster_ids,
            {
              clustersToHide,
            },
          )
        }

        analysis.hidden_cluster_ids = computeHiddenClusters(
          analysis.hidden_cluster_ids,
          { clustersToHide },
        )
      },
    ),
    hideClusters: _(
      undefined,
      (
        { analysis },
        {
          payload: { chartId, clusterIds },
        }: PayloadAction<{ chartId: string; clusterIds: string[] }>,
      ) => {
        const chart = getChart(analysis, chartId)
        chart.hidden_cluster_ids = computeHiddenClusters(
          chart.hidden_cluster_ids,
          {
            clustersToHide: clusterIds,
          },
        )
      },
    ),
    changeClustersVisibility: _(
      LogActions.s_globally_shown_and_hidden_clusters_updated,
      (
        { analysis },
        {
          payload: { chartId, hiddenClusters },
        }: PayloadAction<{ chartId: string; hiddenClusters: string[] }>,
        wrappedAnalysisSliceState,
      ) => {
        const clusterById = selectClusterById(wrappedAnalysisSliceState)

        const chart = getChart(analysis, chartId)
        chart.hidden_cluster_ids = computeHiddenClusters([], {
          clustersToHide: findClustersToHide(hiddenClusters, clusterById),
        })
      },
    ),
    changeClustersVisibilityOnAllCharts: _(
      LogActions.s_globally_shown_and_hidden_clusters_updated,
      (
        { analysis },
        {
          payload: { hiddenClusters },
        }: PayloadAction<{ hiddenClusters: string[] }>,
        wrappedAnalysisSliceState,
      ) => {
        const clusterById = selectClusterById(wrappedAnalysisSliceState)

        for (const chart of analysis.graphs) {
          chart.hidden_cluster_ids = computeHiddenClusters([], {
            clustersToHide: findClustersToHide(hiddenClusters, clusterById),
          })
        }

        analysis.hidden_cluster_ids = computeHiddenClusters([], {
          clustersToHide: findClustersToHide(hiddenClusters, clusterById),
        })
      },
    ),
    changeSpecificLayout: _(
      LogActions.l_layout_changed,
      (
        { analysis },
        {
          payload: { layoutKey, layout },
        }: PayloadAction<{
          layoutKey: keyof Layout
          layout: LayoutElement
        }>,
      ) => {
        analysis.layout[layoutKey] = layout
      },
    ),
    changeLayout: _(
      LogActions.l_layout_changed,
      ({ analysis }, { payload }: PayloadAction<Layout>) => {
        analysis.layout = payload
      },
    ),
    acceptGate: _(
      action =>
        action.payload.gate.tempValues
          ? LogActions.g_gate_created
          : LogActions.g_gate_renamed,
      (
        { analysis },
        {
          payload: { chartId, gate },
        }: PayloadAction<{ chartId: string; gate: Graph.Gate }>,
      ) => {
        const chart = getChart(analysis, chartId)

        const gateValues: Graph.GateValues = gate.tempValues
          ? gate.tempValues
          : {
              xMin: gate.xMin,
              xMax: gate.xMax,
              yMin: gate.yMin,
              yMax: gate.yMax,
            }
        const newGate: Graph.Gate = {
          ...gate,
          ...gateValues,
          tempValues: undefined,
        }

        const existingGateIndex = chart.gates.findIndex(
          existingGate => existingGate.id === newGate.id,
        )
        if (existingGateIndex !== -1) {
          chart.gates[existingGateIndex] = newGate
        } else {
          chart.gates.push(newGate)
        }
      },
    ),
    removeGate: _(
      undefined,
      (
        { analysis },
        {
          payload: { chartId, gateId },
        }: PayloadAction<{ chartId: string; gateId: string }>,
      ) => {
        const chart = getChart(analysis, chartId)
        chart.gates = chart.gates.filter(gate => gate.id !== gateId)
      },
    ),
    duplicateGate: _(
      LogActions.g_gate_duplicated,
      (
        { analysis },
        {
          payload: { targetChartId, gate },
        }: PayloadAction<{ targetChartId: string; gate: Graph.Gate }>,
      ) => {
        const chart = getChart(analysis, targetChartId)
        chart.gates.push({
          ...gate,
          id: uuid(),
        })
      },
    ),
    toggleGateVisibility: _(
      LogActions.g_gate_toggle_visibility,
      (
        { analysis },
        {
          payload: { chartId, gateId },
        }: PayloadAction<{ chartId: string; gateId: string }>,
      ) => {
        const chart = getChart(analysis, chartId)
        const gate = chart.gates.find(gate => gate.id === gateId)
        gate && (gate.hidden = !gate.hidden)
      },
    ),
    saveLasso: _(
      undefined,
      (
        { analysis },
        {
          payload: { chartId, lasso },
        }: PayloadAction<{
          chartId: string
          lasso: Lasso
        }>,
      ) => {
        const chart = getChart(analysis, chartId)
        const currentLasso = analysis.lassos[lasso.id]

        if (currentLasso) {
          currentLasso.name = lasso.name
          currentLasso.type = lasso.type
          if (!isEqual(currentLasso.polygon, lasso.polygon)) {
            currentLasso.polygon = lasso.polygon
          }
        } else {
          analysis.lassos[lasso.id] = lasso
          chart.lasso_ids[lasso.id] = true
        }
      },
    ),
    deleteLasso: _(
      undefined,
      (
        { analysis },
        { payload: { lassoId } }: PayloadAction<{ lassoId: string }>,
        wrappedAnalysisSliceState,
      ) => {
        const parentLassoIdByLassoId = selectParentLassoIdByLassoId(
          wrappedAnalysisSliceState,
        )

        const lassosToDelete = [
          lassoId,
          ...findDescendantLassos([lassoId], parentLassoIdByLassoId),
        ]

        const chartsToDelete = analysis.graphs.filter(chart => {
          if (chart.parent_lasso_id) {
            return lassosToDelete.includes(chart.parent_lasso_id)
          }
          return false
        })

        for (const chart of chartsToDelete) {
          removeItemFromLayout(analysis, chart.id)
        }

        const newCharts = analysis.graphs.filter(
          chart => !chartsToDelete.includes(chart),
        )

        for (const chart of newCharts) {
          for (const lassoId of lassosToDelete) {
            delete chart.lasso_ids[lassoId]
          }
        }

        const newLassos = Object.fromEntries(
          Object.entries(analysis.lassos).filter(
            ([key]) => !lassosToDelete.includes(key),
          ),
        )

        analysis.lassos = newLassos
        analysis.graphs = newCharts
      },
    ),
    toggleShowLasso: _(
      undefined,
      (
        { analysis },
        {
          payload: { chartId, lassoId },
        }: PayloadAction<{
          chartId: string
          lassoId: string
        }>,
      ) => {
        const chart = getChart(analysis, chartId)
        chart.lasso_ids[lassoId] = !chart.lasso_ids[lassoId]
      },
    ),
    addStatistics: _(
      undefined,
      (
        { analysis },
        {
          payload: { statistics },
        }: PayloadAction<{
          statistics: Analysis.Statistics
        }>,
        wrappedAnalysisSliceState,
      ) => {
        const name = selectNextFreeStatisticsName(wrappedAnalysisSliceState)
        const id = uuid()

        addItemToLayout(analysis, wrappedAnalysisSliceState, id)

        analysis.statistics.push({
          id,
          name,
          default_name: name,
          statistics,
          isUnsaved: true,
          shouldTriggerInitialComputationRequest: true,
          created_at: new Date().toISOString(),
          updated_at: new Date().toISOString(),
        })
      },
    ),
    changeStatistics: _(
      undefined,
      (
        { analysis },
        {
          payload: { id, statistics },
        }: PayloadAction<{ id: string; statistics: Analysis.Statistics }>,
      ) => {
        const statistic = analysis.statistics.find(
          statistic => statistic.id === id,
        )
        if (!statistic) {
          throw new Error(`Cannot find statistic ${id}`)
        }
        statistic.statistics = statistics
      },
    ),
    deleteStatistic: _(
      undefined,
      ({ analysis }, { payload: { id } }: PayloadAction<{ id: string }>) => {
        analysis.statistics = analysis.statistics.filter(
          statistic => statistic.id !== id,
        )
        removeItemFromLayout(analysis, id)
      },
    ),
    changeStatisticsName: _(
      undefined,
      (
        { analysis },
        { payload: { id, name } }: PayloadAction<{ id: string; name: string }>,
      ) => {
        const statistic = analysis.statistics.find(
          statistic => statistic.id === id,
        )
        if (!statistic) {
          throw new Error(`Cannot find statistic ${id}`)
        }
        statistic.name = name
      },
    ),
    markStatisticInitialComputationRequestAsTriggered: _(
      undefined,
      ({ analysis }, { payload: { id } }: PayloadAction<{ id: string }>) => {
        const statistic = analysis.statistics.find(
          statistic => statistic.id === id,
        )
        if (!statistic) {
          throw new Error(`Cannot find statistic ${id}`)
        }
        statistic.shouldTriggerInitialComputationRequest = false
      },
    ),
  },
})

const addItemToLayout = (
  analysis: Analysis,
  wrappedAnalysisSliceState: WrappedAnalysisSliceState,
  id: string,
  size = { w: 1, h: 1 },
) => {
  const nextLayoutPositions = selectNextLayoutPositions(
    wrappedAnalysisSliceState,
  )

  const currentLayoutKey = getCurrentLayoutKey()

  analysis.layout[currentLayoutKey][id] = {
    ...nextLayoutPositions[currentLayoutKey],
    ...size,
  }
  if (currentLayoutKey === 'single') {
    analysis.layout.primary[id] = {
      ...nextLayoutPositions.primary,
      ...size,
    }
  } else {
    analysis.layout.single[id] = {
      ...nextLayoutPositions.single,
      ...size,
    }
  }
}

const removeItemFromLayout = (analysis: Analysis, id: string) => {
  for (const layoutKey in analysis.layout) {
    delete analysis.layout[layoutKey][id]
  }
}

export const {
  setSelectedChannels,
  sortChannels,
  updateChannelLabels,
  sortClusters,
  resetClusterHighlighting,
  changeClusterDotSize,
  changeHeatmapColors,
  updateChartType,
  resetAnalysisToAutofocus,
  setDepth,
  toggleClusterActive,
  expandClusters,
  mergeClusters,
  mergeVisibleClustersRecursively,
  mergeLassoClustersRecursively,
  renameCluster,
  changeClusterColor,
  highlightCluster,
  addChart,
  removeAnalysisChart,
  duplicateChart,
  renameAnalysisChart,
  zoomChart,
  resetChartZoom,
  changeChartDisplaySettings,
  setChartEventLimit,
  setChartEventLimitForAllCharts,
  toggleClusterVisibility,
  showClusterOnAllCharts,
  hideClustersOnAllCharts,
  hideClusters,
  changeClustersVisibility,
  changeClustersVisibilityOnAllCharts,
  changeSpecificLayout,
  changeLayout,
  acceptGate,
  removeGate,
  duplicateGate,
  toggleGateVisibility,
  saveLasso,
  deleteLasso,
  toggleShowLasso,
  addStatistics,
  changeStatistics,
  changeStatisticsName,
  markStatisticInitialComputationRequestAsTriggered,
  deleteStatistic,
} = historySlice.actions
