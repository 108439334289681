import { CircularProgress, IconButton } from '@material-ui/core'
import { useState } from 'react'
import styled from 'styled-components'

import { ReactComponent as ExportIcon } from 'assets/images/icons/export.svg'

import { Header } from 'components/Header'
import { Button } from 'components/button/Button'
import { SearchInput } from 'components/input/SearchInput'
import { TableColumn, TableRow } from 'components/table/Table'
import { TableWithPagination } from 'components/table/TableWithPagination'
import {
  DateFilter,
  DateFilterValue,
} from 'components/table/filters/DateFilter'
import { SelectFilter } from 'components/table/filters/SelectFilter'
import { useTableSorting } from 'components/table/useTableSorting'

import {
  Experiment,
  useDeleteExperimentMutation,
  useGetExperimentsQuery,
  useLazyGetExperimentQuery,
} from 'shared/api/experiments.api'
import {
  Workflow,
  WorkflowStatus,
  useGetWorkflowsQuery,
  useLazyGetWorkflowQuery,
} from 'shared/api/workflows.api'
import { useDialog } from 'shared/contexts/DialogContext'
import { useModal } from 'shared/contexts/ModalContext'
import { useDeleteWorkflow } from 'shared/hooks/useDeleteWorkflow'
import { formatDateTime } from 'shared/utils/utils'

import { ExperimentFilesModal } from './ExperimentFilesModal'
import { ExperimentWorkflowModal } from './ExperimentWorkflowModal'
import { FoldableWizardSection } from './FoldableWizardSection'
import { MetaAnalysisWizard } from './MetaAnalysisWizard'
import { SectionHeader } from './SectionHeader'
import { StatusChip } from './StatusChip'
import { WorkflowExportTasksModal } from './WorkflowExportTasksModal'
import { WorkflowFilesModal } from './WorkflowFilesModal'
import { WorkflowMetaAnalysisModal } from './WorkflowMetaAnalysisModal'
import { WorkflowModal } from './WorkflowModal'

type ExperimentsProps = {
  projectId: string
}

export const Experiments: React.FC<ExperimentsProps> = ({ projectId }) => {
  const { showModal } = useModal()
  const { showConfirmationDialog } = useDialog()

  const [experimentsPage, setExperimentsPage] = useState(1)
  const [workflowsPage, setWorkflowsPage] = useState(1)
  const [searchValue, setSearchValue] = useState<string>()
  const [dateFilterValue, setDateFilterValue] =
    useState<DateFilterValue>(undefined)
  const [selectedStatus, setSelectedStatus] = useState<`${WorkflowStatus}`>()

  const {
    sortingColumn: experimentsSortingColumn,
    sortingDirection: experimentsSortingDirection,
    orderingString: experimentsOrderingString,
    setSorting: setExperimentsSorting,
  } = useTableSorting('created_at', 'desc')
  const {
    sortingColumn: workflowsSortingColumn,
    sortingDirection: workflowsSortingDirection,
    orderingString: workflowsOrderingString,
    setSorting: setWorkflowsSorting,
  } = useTableSorting('created_at', 'desc')

  const experimentsQuery = useGetExperimentsQuery({
    projectId,
    page: experimentsPage,
    ordering: experimentsOrderingString,
    search: {
      search: searchValue,
      created_at_after: dateFilterValue?.[0]?.toISOString(),
      created_at_before: dateFilterValue?.[1]?.toISOString(),
      statuses: selectedStatus,
    },
  })
  const [triggerGetExperimentQuery] = useLazyGetExperimentQuery()
  const [triggerGetWorkflowQuery] = useLazyGetWorkflowQuery()
  const [triggerDeleteExperimentMutation] = useDeleteExperimentMutation()
  const deleteWorkflow = useDeleteWorkflow()

  const workflowsQuery = useGetWorkflowsQuery({
    projectId,
    page: workflowsPage,
    ordering: workflowsOrderingString,
    search: {
      search: searchValue,
      created_at_after: dateFilterValue?.[0]?.toISOString(),
      created_at_before: dateFilterValue?.[1]?.toISOString(),
      statuses: selectedStatus,
    },
  })

  const handleDeleteExperiment = (experiment: Experiment) => {
    showConfirmationDialog({
      title: 'Delete experiment',
      message: `Are you sure you want to delete the experiment "${experiment.name}"?`,
      onConfirm: () => triggerDeleteExperimentMutation(experiment.id),
    })
  }

  const handleDeleteWorkflow = (workflow: Workflow) => {
    deleteWorkflow({
      id: workflow.id,
      name: workflow.name,
    })
  }

  const columns: TableColumn[] = [
    {
      key: 'name',
      label: 'Name',
      canSort: true,
    },
    {
      key: 'status',
      label: 'Status',
      style: {
        width: 0,
      },
      canSort: true,
    },
    {
      key: 'actions',
      label: 'Actions',
      style: {
        width: 0,
      },
    },
    {
      key: 'created_at',
      label: 'Creation date',
      canSort: true,
    },
    {
      key: 'experiment_source',
      label: 'Pipeline',
      canSort: true,
    },
    {
      key: 'pipelineVersion',
      label: 'Pipeline version',
    },
    {
      key: 'progress',
      label: 'Progress',
    },
  ]

  const experimentRows: TableRow[] =
    experimentsQuery.data?.results.map(experiment => {
      const [currentStep, maxStep] = experiment.progress.split('/').map(Number)

      return {
        key: experiment.id,
        cells: [
          {
            key: 'name',
            content: experiment.name,
          },
          {
            key: 'status',
            content: <StatusChip status={experiment.status} />,
          },
          {
            key: 'actions',
            content: (
              <Actions>
                <ActionButton
                  onClick={() => {
                    showModal(ExperimentWorkflowModal, {
                      experimentId: experiment.id,
                    })
                  }}
                >
                  View pipeline
                </ActionButton>
                <ActionButton
                  onClick={() => {
                    showModal(ExperimentFilesModal, {
                      projectId: experiment.project,
                      experimentId: experiment.id,
                    })
                  }}
                >
                  Files
                </ActionButton>
                <ActionButton
                  onClick={async () => {
                    const experimentDetails = await triggerGetExperimentQuery(
                      experiment.id,
                    ).unwrap()
                    showModal(WorkflowMetaAnalysisModal, {
                      workflow: experimentDetails,
                      onShowMetaAnalysisWizard: () => {
                        showModal(MetaAnalysisWizard, {
                          workflow: experimentDetails,
                          brickIds: experimentDetails.bricks.map(
                            brick => brick.id,
                          ),
                        })
                      },
                    })
                  }}
                >
                  Meta-analysis
                </ActionButton>
                <ActionButton
                  colorOverride="error"
                  onClick={() => handleDeleteExperiment(experiment)}
                >
                  Delete
                </ActionButton>
                <IconButton
                  onClick={() =>
                    showModal(WorkflowExportTasksModal, {
                      type: 'experiment',
                      workflowId: experiment.id,
                      workflowName: experiment.name,
                    })
                  }
                >
                  <ExportIcon />
                </IconButton>
              </Actions>
            ),
          },
          {
            key: 'created_at',
            content: formatDateTime(experiment.created_at),
          },
          {
            key: 'experiment_source',
            content: experiment.origin_pipeline_name,
          },
          {
            key: 'pipelineVersion',
            content: formatDateTime(experiment.origin_pipeline_version),
          },
          {
            key: 'progress',
            content: (
              <Progress>
                <CircularProgress
                  size={14}
                  value={(currentStep / maxStep) * 100}
                  variant="determinate"
                />
                Step {experiment.progress}
              </Progress>
            ),
          },
        ],
      }
    }) ?? []

  const workflowRows: TableRow[] =
    workflowsQuery.data?.results.map(workflow => {
      return {
        key: workflow.id,
        cells: [
          {
            key: 'name',
            content: workflow.name,
          },
          {
            key: 'status',
            content: <StatusChip status={workflow.status} />,
          },
          {
            key: 'actions',
            content: (
              <Actions>
                <ActionButton
                  onClick={() => {
                    showModal(WorkflowModal, {
                      workflowId: workflow.id,
                    })
                  }}
                >
                  View pipeline
                </ActionButton>
                <ActionButton
                  onClick={async () => {
                    const workflowDetails = await triggerGetWorkflowQuery(
                      workflow.id,
                    ).unwrap()
                    showModal(WorkflowFilesModal, {
                      compensatedFiles: workflowDetails.compensated_files,
                    })
                  }}
                >
                  Files
                </ActionButton>
                <ActionButton
                  onClick={async () => {
                    const workflowDetails = await triggerGetWorkflowQuery(
                      workflow.id,
                    ).unwrap()
                    showModal(WorkflowMetaAnalysisModal, {
                      workflow: workflowDetails,
                      onShowMetaAnalysisWizard: () => {
                        showModal(MetaAnalysisWizard, {
                          workflow: workflowDetails,
                          brickIds: workflowDetails.bricks.map(
                            brick => brick.id,
                          ),
                        })
                      },
                    })
                  }}
                >
                  Meta-analysis
                </ActionButton>
                <ActionButton
                  colorOverride="error"
                  onClick={() => handleDeleteWorkflow(workflow)}
                >
                  Delete
                </ActionButton>
                <IconButton
                  onClick={() =>
                    showModal(WorkflowExportTasksModal, {
                      type: 'workflow',
                      workflowId: workflow.id,
                      workflowName: workflow.name,
                    })
                  }
                >
                  <ExportIcon />
                </IconButton>
              </Actions>
            ),
          },
          {
            key: 'created_at',
            content: formatDateTime(workflow.created_at),
          },
          {
            key: 'experiment_source',
            content: '',
          },
          {
            key: 'pipelineVersion',
            content: '',
          },
          {
            key: 'progress',
            content: '',
          },
        ],
      }
    }) ?? []

  return (
    <ExperimentsRoot>
      <StyledHeader />
      <Main>
        {experimentsQuery.isLoading ? (
          <Sticky>
            <SectionHeader title="Results" />
            <CircularProgress />
          </Sticky>
        ) : (
          <>
            <Sticky>
              <SectionHeader title="Results" />
              <TableFilters>
                <SearchInput
                  placeholder="Search experiments"
                  onChange={setSearchValue}
                />
                <DateFilter
                  value={dateFilterValue}
                  title="Creation date"
                  onChange={setDateFilterValue}
                />
                <SelectFilter
                  title="Status"
                  options={Object.entries(WorkflowStatus).map(
                    ([key, label]) => ({
                      id: key,
                      label,
                    }),
                  )}
                  selectedId={selectedStatus}
                  onSelect={status =>
                    setSelectedStatus(status as `${WorkflowStatus}` | undefined)
                  }
                />
              </TableFilters>
            </Sticky>
            <Tables>
              <FoldableWizardSection title="From analysis strategies">
                <TableWithPagination
                  page={workflowsPage}
                  pageCount={workflowsQuery.data?.page ?? 1}
                  onPageChange={(_event, page) => setWorkflowsPage(page)}
                  columns={columns}
                  rows={workflowRows}
                  sortingColumn={workflowsSortingColumn}
                  sortingDirection={workflowsSortingDirection}
                  onSortingChange={setWorkflowsSorting}
                />
              </FoldableWizardSection>
              <FoldableWizardSection title="From frozen pipelines">
                <TableWithPagination
                  page={experimentsPage}
                  pageCount={experimentsQuery.data?.page ?? 1}
                  onPageChange={(_event, page) => setExperimentsPage(page)}
                  columns={columns}
                  rows={experimentRows}
                  sortingColumn={experimentsSortingColumn}
                  sortingDirection={experimentsSortingDirection}
                  onSortingChange={setExperimentsSorting}
                />
              </FoldableWizardSection>
            </Tables>
          </>
        )}
      </Main>
    </ExperimentsRoot>
  )
}

const ExperimentsRoot = styled.div`
  height: 100vh;
  overflow: auto;
`

const StyledHeader = styled(Header)`
  padding: 60px 100px 0;
`

const Sticky = styled.div`
  position: sticky;
  top: 0;
  background-color: #f9f9f9;
  z-index: 2;
  padding: 0px 100px 20px;
`

const Main = styled.main``

const Actions = styled.div`
  display: flex;
  gap: ${props => props.theme.spacing(1)}px;
`

const Progress = styled.div`
  display: flex;
  align-items: center;
  gap: 4px;
`

const TableFilters = styled.div`
  display: flex;
  align-items: center;
  gap: ${props => props.theme.spacing(2)}px;
  margin: ${props => props.theme.spacing(4)}px 0;
`

const Tables = styled.div`
  padding: 0 100px 60px;
  display: flex;
  flex-direction: column;
  gap: 32px;
`

const ActionButton = styled(Button)`
  font-size: 12px;
  padding: 4px 6px;
`
