import { isEqual } from 'lodash'
import { useRef } from 'react'

export const useStable = <T>(value: T): T => {
  const stableValueRef = useRef<T>(value)
  const previousValueRef = useRef<T>()

  if (!isEqual(value, previousValueRef.current)) {
    stableValueRef.current = value
  }
  previousValueRef.current = value

  return stableValueRef.current
}
