import { createContext, useContext } from 'react'

type LogContextProps = Readonly<{
  currentSession: LogSession | undefined
  setCurrentSession: (session: LogSession | undefined) => void
}>

export type LogSession = Readonly<{
  id: string
  started_at: string
  ended_at: string | null
  user: string
  analysis: string
}>

export const LogContext = createContext<LogContextProps | undefined>(undefined)

export enum LogActions {
  log_in_logbook = 'log_in_logbook',
  log_out_logbook = 'log_out_logbook',
  s_depth_changed = 's_depth_changed',
  s_cluster_expanded_merged = 's_cluster_expanded_merged',
  s_visible_clusters_expanded = 's_visible_clusters_expanded',
  s_visible_clusters_merged = 's_visible_clusters_merged',
  s_cluster_renamed = 's_cluster_renamed',
  s_cluster_highlighted = 's_cluster_highlighted',
  s_cluster_color_change = 's_cluster_color_change',
  s_globally_shown_and_hidden_clusters_updated = 's_globally_shown_and_hidden_clusters_updated',
  s_sub_analysis_created_from_shown_clusters = 's_sub_analysis_created_from_shown_clusters',
  h_cluster_renamed = 'h_cluster_renamed',
  h_channel_edited = 'h_channel_edited',
  h_legend_colour_changed = 'h_legend_colour_changed',
  h_cluster_colour_changed = 'h_cluster_colour_changed',
  h_cluster_hidden = 'h_cluster_hidden',
  h_data_order_updated = 'h_data_order_updated',
  h_clusters_and_channels_order_updated = 'h_clusters_and_channels_order_updated',
  l_graph_created = 'l_graph_created',
  l_graph_deleted = 'l_graph_deleted',
  l_graph_duplicated = 'l_graph_duplicated',
  l_analysis_saved = 'l_analysis_saved',
  l_export_stat = 'l_export_stat',
  l_export_event = 'l_export_event',
  l_channel_edited = 'l_channel_edited',
  l_rearrange_order = 'l_rearrange_order',
  l_layout_changed = 'l_layout_changed',
  l_analysis_e_signed = 'l_analysis_e_signed',
  l_analysis_e_signed_cancelled = 'l_analysis_e_signed_cancelled',
  l_review_e_signed = 'l_review_e_signed',
  l_approved_e_signed = 'l_approved_e_signed',
  l_cluster_toggle_global_visibility = 'l_cluster_toggle_global_visibility',
  l_channel_name_changed = 'l_channel_name_changed',
  l_cluster_highlighted = 'l_cluster_highlighted',
  g_cluster_locally_shown = 'g_cluster_locally_shown',
  g_cluster_locally_hidden = 'g_cluster_locally_hidden',
  g_gate_created = 'g_gate_created',
  g_gate_renamed = 'g_gate_renamed',
  g_gate_duplicated = 'g_gate_duplicated',
  g_gate_toggle_visibility = 'g_gate_toggle_visibility',
  g_cluster_color_change = 'g_cluster_color_change',
  g_graph_renamed = 'g_graph_renamed',
  g_axes_scale_changed = 'g_axes_scale_changed',
  g_export_graph = 'g_export_graph',
  g_graph_type_changed = 'g_graph_type_changed',
  g_graph_display_settings_changed = 'g_graph_display_settings_changed',
}

export const useLogActions = (): LogContextProps => {
  const analysisContext = useContext(LogContext)
  if (analysisContext === undefined) {
    throw new Error('useLogActions must be used inside of LogContextProvider')
  }

  return analysisContext
}
