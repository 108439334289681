import { Base64 } from 'js-base64'
import styled from 'styled-components'

type InlineSvgProps = {
  className?: string
  src: string
  width: number
  height: number
}

export const InlineSvg: React.FC<InlineSvgProps> = ({
  className,
  src,
  width,
  height,
}) => {
  return (
    <StyledImg
      className={className}
      src={'data:image/svg+xml;base64,' + Base64.encode(src)}
      width={width}
      height={height}
    />
  )
}

const StyledImg = styled.img`
  overflow: hidden;
`
