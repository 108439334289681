import { createSelector } from '@reduxjs/toolkit'
import { mapValues } from 'lodash'

import { HeatmapColors, Lasso } from 'shared/models/AnalysisModels'
import { RootState } from 'shared/store'
import { sort } from 'shared/utils/array'
import {
  checkIfClusterIsInsideLasso,
  findActiveClusters,
  getMaxDepth,
} from 'shared/utils/clusters.utils'
import { groupByUnique } from 'shared/utils/collection.utils'
import { hexStringToRgbString } from 'shared/utils/colors'

import { theme } from 'Theme'

import { selectCharts } from '.'
import { ClusterDotSizesByChartId, HighlightedCluster } from '../analysis.slice'
import {
  selectActiveLeafIds,
  selectAnalysisClusterTree,
  selectAnalysisClusterTreeNodeById,
  selectAnalysisLassos,
  selectHeatmapColorsFromAnalysis,
  selectHiddenClusterIds,
  selectSelfOrganizingHeatMapQuery,
} from './analysis.selectors'
import {
  selectChartById,
  selectParentLassoIdByLassoId,
} from './charts.selectors'
import { selectIsSelfOrganizingHeatMapQueryStale } from './self-organizing-heat-map.selectors'
import { selectClustersSortMode } from './ui.selectors'

export type Cluster = Pick<
  Analysis.ClusterNode,
  'label' | 'parent_rate' | 'depth' | 'feat_imp' | 'stats'
> & {
  id: string
  parent: string | undefined
  children: string[]
  color: string
  defaultLabel: string
  positionDeterminants: [string, string, string] | undefined
  isHidden: boolean
  isActive: boolean
  isLeaf: boolean
  isRoot: boolean
  lassoIds: string[]
}

export const selectClusters = createSelector(
  selectAnalysisClusterTree,
  selectHiddenClusterIds,
  selectActiveLeafIds,
  selectCharts,
  selectAnalysisLassos,
  selectParentLassoIdByLassoId,
  (
    clusterTree,
    hiddenClusterIds,
    activeLeafIds,
    charts,
    lassos,
    parentLassoIdByLassoId,
  ): Cluster[] => {
    const hiddenClusterIdsSet = new Set(hiddenClusterIds)
    const activeLeafIdsSet = new Set(activeLeafIds)
    const clusterNodeById: Record<string, Analysis.ClusterNode> = {}
    const clusterParentIdById: Record<string, string | undefined> = {}

    const traverseClusterTree = (
      tree: Analysis.ClusterTree,
      parentId?: string,
    ) => {
      for (const [id, node] of Object.entries(tree)) {
        clusterNodeById[id] = node
        clusterParentIdById[id] = parentId
        traverseClusterTree(node.children, id)
      }
    }
    traverseClusterTree(clusterTree)

    const activeClusterIdsSet = new Set(
      findActiveClusters(activeLeafIds, clusterParentIdById),
    )

    return Object.keys(clusterNodeById).map(id => {
      const node = clusterNodeById[id]
      const isHidden = hiddenClusterIdsSet.has(id)
      const parent = clusterParentIdById[id]
      return {
        id,
        parent_rate: node.parent_rate,
        depth: node.depth,
        stats: node.stats,
        parent,
        children: Object.keys(node.children),
        label: node.label,
        defaultLabel: node.default_label ?? node.label,
        color: `rgb(${node.color.join(',')})`,
        positionDeterminants: node.feat_imp,
        isHidden,
        isActive: activeClusterIdsSet.has(id),
        isLeaf: activeLeafIdsSet.has(id),
        isRoot: !parent,
        lassoIds: sortLassos(
          charts.flatMap(chart =>
            Object.keys(chart.lasso_ids)
              .map(lassoId => lassos[lassoId])
              .filter(lasso => {
                if (!chart.active_leaf_ids.includes(id) || !chart.y_axis) {
                  return false
                }

                return checkIfClusterIsInsideLasso(
                  {
                    stats: node.stats,
                    isHidden: chart.hidden_cluster_ids.includes(id),
                  },
                  lasso.polygon,
                  chart.x_axis,
                  chart.y_axis,
                )
              }),
          ),
          parentLassoIdByLassoId,
        ),
      }
    })
  },
)

const sortLassos = (
  lassos: Lasso[],
  parentLassoIdByLassoId: Record<string, string | undefined>,
) => {
  return (
    trySortLassosInHierarchyOrder(
      lassos.map(lasso => lasso.id),
      parentLassoIdByLassoId,
    ) ?? sort(lassos, 'asc', lasso => lasso.name).map(lasso => lasso.id)
  )
}

const trySortLassosInHierarchyOrder = (
  lassoIds: string[],
  parentLassoIdByLassoId: Record<string, string | undefined>,
) => {
  const rootLassoId = lassoIds.find(lassoId => {
    const parentId = parentLassoIdByLassoId[lassoId]
    return !parentId || !lassoIds.includes(parentId)
  })
  if (!rootLassoId) {
    return undefined
  }

  const sortedLassoIds = [rootLassoId]
  const lassoIdsToCheck = [...lassoIds].filter(
    lassoId => lassoId !== rootLassoId,
  )
  let parentLassoId = rootLassoId
  while (lassoIdsToCheck.length > 0) {
    const nextLassoId = lassoIdsToCheck.find(
      lassoId => parentLassoIdByLassoId[lassoId] === parentLassoId,
    )
    if (nextLassoId) {
      sortedLassoIds.push(nextLassoId)
      lassoIdsToCheck.splice(lassoIdsToCheck.indexOf(nextLassoId), 1)
      parentLassoId = nextLassoId
    } else {
      return undefined
    }
  }
  return sortedLassoIds
}

export const selectClustersByLassoId = createSelector(
  selectClusters,
  clusters => {
    const result: Record<string, Cluster[]> = {}
    for (const cluster of clusters) {
      for (const lassoId of cluster.lassoIds) {
        if (!result[lassoId]) {
          result[lassoId] = []
        }
        result[lassoId].push(cluster)
      }
    }
    return result
  },
)

export const selectClusterById = createSelector(selectClusters, clusters => {
  return groupByUnique(clusters, 'id')
})

export const selectClusterColorById = createSelector(
  selectClusterById,
  clusterById => {
    return mapValues(clusterById, cluster => cluster.color)
  },
)

export const selectRootClusterId = createSelector(
  selectAnalysisClusterTree,
  tree => {
    return Object.keys(tree)[0]
  },
)

export const selectActiveClusters = createSelector(selectClusters, clusters => {
  return clusters.filter(cluster => cluster.isActive)
})

export const selectActiveClusterIds = createSelector(
  selectActiveClusters,
  (activeClusters): string[] => {
    return activeClusters.map(cluster => cluster.id)
  },
)

export const selectClusterDotSizes = createSelector(
  (state: RootState) =>
    state.analysisPage.analysis.history.present.ui.clusterDotSizes,
  selectAnalysisClusterTreeNodeById,
  selectChartById,
  (
    clusterDotSizes,
    clusterTreeNodeById,
    chartById,
  ): ClusterDotSizesByChartId => {
    const dotSizes: ClusterDotSizesByChartId = {}
    for (const chartId of Object.keys(chartById)) {
      dotSizes[chartId] = {}

      const customDotSizes = clusterDotSizes[chartId]
      for (const [clusterId, cluster] of Object.entries(clusterTreeNodeById)) {
        dotSizes[chartId][clusterId] =
          customDotSizes?.[clusterId] ?? cluster.dot_size
      }
    }

    return dotSizes
  },
)

export const selectHighlightedCluster = (
  state: RootState,
): HighlightedCluster | undefined =>
  state.analysisPage.analysis.history.present.ui.highlightedCluster

export const selectActiveLeaves = createSelector(
  selectClusters,
  selectClustersSortMode,
  selectSelfOrganizingHeatMapQuery,
  selectIsSelfOrganizingHeatMapQueryStale,
  (
    clusters,
    sortMode,
    selfOrganizingHeatMapQuery,
    isSelfOrganazingHeatMapQueryStale,
  ): Cluster[] => {
    const activeLeaves = clusters.filter(cluster => cluster.isLeaf)

    switch (sortMode?.type) {
      case undefined:
        return activeLeaves

      case 'manual':
        return sort(activeLeaves, 'asc', leaf =>
          sortMode.order.indexOf(leaf.id),
        )

      case 'by-normalized-value':
        return sort(
          activeLeaves,
          sortMode.order,
          leaf => leaf.stats.heatmap[sortMode.channel],
        )

      case 'by-property':
        switch (sortMode.property) {
          case 'name':
            return sort(activeLeaves, sortMode.order, leaf => leaf.label)

          case 'events':
            return sort(activeLeaves, sortMode.order, leaf => leaf.stats.count)

          default:
            throw new Error(`Unknown sort mode: ${sortMode}`)
        }

      case 'self-organize':
        return isSelfOrganazingHeatMapQueryStale || !selfOrganizingHeatMapQuery
          ? activeLeaves
          : sort(activeLeaves, 'asc', leaf =>
              selfOrganizingHeatMapQuery.result.hierarchical_clustering_results.ordered_node_ids
                .map(id => id.toString())
                .indexOf(leaf.id),
            )
    }
  },
)

export const selectShownActiveLeafIds = createSelector(
  selectActiveLeaves,
  (activeLeaves): string[] => {
    return activeLeaves?.filter(leaf => !leaf.isHidden).map(leaf => leaf.id)
  },
)

export const selectActiveLeavesWithoutRoot = createSelector(
  selectActiveLeaves,
  (activeLeaves): Cluster[] => {
    return activeLeaves.filter(cluster => cluster.parent)
  },
)

export const selectActiveLeavesCount = createSelector(
  selectActiveLeaves,
  (activeLeaves): number => {
    return activeLeaves.length
  },
)

export const selectMaxAvailableDepth = createSelector(
  selectClusters,
  (clusters): number => {
    return getMaxDepth(clusters)
  },
)

export const selectActiveClusterIdsByChartId = createSelector(
  selectChartById,
  selectClusterById,
  (chartById, clusterById): Record<string, string[]> => {
    return mapValues(chartById, chart => {
      return findActiveClusters(
        chart.active_leaf_ids,
        mapValues(clusterById, cluster => cluster.parent),
      )
    })
  },
)

export const selectActiveClustersByChartId = createSelector(
  selectChartById,
  selectActiveClusterIdsByChartId,
  selectClusterById,
  (
    chartById,
    activeClusterIdsByChartId,
    clusterById,
  ): Record<string, Cluster[]> => {
    return mapValues(chartById, chart => {
      const hiddenClusterIds = new Set(chart.hidden_cluster_ids)
      const activeLeafIds = new Set(chart.active_leaf_ids)

      return activeClusterIdsByChartId[chart.id].map(id => {
        return {
          ...clusterById[id],
          isHidden: hiddenClusterIds.has(id),
          isActive: true,
          isLeaf: activeLeafIds.has(id),
        }
      })
    })
  },
)

export const selectActiveLeavesByChartId = createSelector(
  selectActiveClustersByChartId,
  (clustersByChartId): Record<string, Cluster[]> => {
    return mapValues(clustersByChartId, clusters =>
      clusters.filter(cluster => cluster.isLeaf),
    )
  },
)

export const selectClusterListClusters = createSelector(
  selectActiveLeavesWithoutRoot,
  activeLeavesWithoutRoot => {
    return [...activeLeavesWithoutRoot].sort((firstCluster, secondCluster) => {
      const isFirstClusterCustomLabeled =
        firstCluster.defaultLabel !== firstCluster.label
      const isSecondClusterCustomLabeled =
        secondCluster.defaultLabel !== secondCluster.label

      if (isFirstClusterCustomLabeled && !isSecondClusterCustomLabeled) {
        return -1
      }

      if (!isFirstClusterCustomLabeled && isSecondClusterCustomLabeled) {
        return 1
      }

      return firstCluster.label.localeCompare(secondCluster.label)
    })
  },
)

export const selectUsedClusterLabels = createSelector(
  selectClusters,
  clusters => {
    return new Set(clusters.map(cluster => cluster.label))
  },
)

const DEFAULT_HEATMAP_COLORS = {
  min: hexStringToRgbString(theme.colors.greyscale[100]),
  max: hexStringToRgbString(theme.colors.primaryLight[100]),
}

export const selectHeatmapColors = (state: RootState): HeatmapColors => {
  const heatmapColorsFromAnalysis = selectHeatmapColorsFromAnalysis(state)
  if (heatmapColorsFromAnalysis) {
    return heatmapColorsFromAnalysis
  }

  return DEFAULT_HEATMAP_COLORS
}
