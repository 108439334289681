import { Typography } from '@material-ui/core'
import React from 'react'
import styled from 'styled-components'

import {
  RESOURCE_ICONS,
  RESOURCE_LABELS,
  ResourceType,
} from 'shared/models/LicenseModels'

type ResourceTypeCardProps = {
  type: ResourceType
}

export const ResourceTypeCard = (props: ResourceTypeCardProps): JSX.Element => {
  const Icon = RESOURCE_ICONS[props.type]

  return (
    <ResourceTypeCardRoot>
      <Icon />
      <Typography variant="h5">{RESOURCE_LABELS[props.type]}</Typography>
    </ResourceTypeCardRoot>
  )
}

const ResourceTypeCardRoot = styled.div`
  display: flex;
  height: 64px;
  padding: 12px;
  align-items: center;
  background-color: white;
  border: 1px solid ${props => props.theme.palette.grey[300]};
  border-radius: 4px;

  svg {
    height: 42px;
    width: 42px;
    color: ${props => props.theme.palette.primary.main};
    margin-right: 12px;
  }
`
