import { ClickAwayListener, Popper } from '@material-ui/core'
import { FC, ReactNode, useRef } from 'react'
import styled from 'styled-components'

import { FilterButton } from './FilterButton'

export type TableFilterProps = {
  title: string
  isSelected: boolean
  isExpanded: boolean
  children: ReactNode
  onButtonClick: () => void
  onClickAway: () => void
}

export const TableFilter: FC<TableFilterProps> = ({
  title,
  isSelected,
  isExpanded,
  children,
  onButtonClick,
  onClickAway,
}) => {
  const buttonRef = useRef<HTMLButtonElement | null>(null)

  return (
    <>
      <FilterButton
        ref={buttonRef}
        title={title}
        isSelected={isSelected}
        onClick={onButtonClick}
      />
      <Popper
        open={isExpanded}
        anchorEl={buttonRef.current}
        modifiers={{ offset: { offset: '0, 10' } }}
        disablePortal={true}
        style={{ zIndex: 1 }}
      >
        <ClickAwayListener onClickAway={onClickAway}>
          <FilterContainer>{children}</FilterContainer>
        </ClickAwayListener>
      </Popper>
    </>
  )
}

const FilterContainer = styled.div`
  padding: ${props => props.theme.spacing(2)}px;
  background: ${props => props.theme.colors.white};
  border-radius: ${props => props.theme.radius[4]}px;
  box-shadow: 0px 4px 10px 4px #e8e9f399;
`
