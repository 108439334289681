import { createSelector } from '@reduxjs/toolkit'
import { mapValues } from 'lodash'

import { ChannelLabels } from 'shared/models/AnalysisModels'
import { sort } from 'shared/utils/array'
import { groupByUnique } from 'shared/utils/collection.utils'

import {
  selectChannels,
  selectSelectedChannels,
  selectSelfOrganizingHeatMapQuery,
} from './analysis.selectors'
import { selectIsSelfOrganizingHeatMapQueryStale } from './self-organizing-heat-map.selectors'
import { selectChannelsSortMode } from './ui.selectors'

export const selectChannelById = createSelector(selectChannels, channels => {
  return groupByUnique(channels, channel => channel.id)
})

export type Channel = {
  id: string
  type: string
  PnS?: string | null
  letter: string
  label: string | undefined
  defaultLabel: string | undefined
  __computed__displayName: string
}

export const selectChannelDisplayNames = createSelector(
  selectChannelById,
  (channelsByName): ChannelLabels => {
    return mapValues(channelsByName, channel => channel.__computed__displayName)
  },
)

export const selectSortedSelectedChannels = createSelector(
  selectSelectedChannels,
  selectChannelsSortMode,
  selectChannelDisplayNames,
  selectSelfOrganizingHeatMapQuery,
  selectIsSelfOrganizingHeatMapQueryStale,
  (
    selectedChannels,
    sortMode,
    channelDisplayNameById,
    selfOrganizingHeatMapQuery,
    isSelfOrganazingHeatMapQueryStale,
  ) => {
    switch (sortMode?.type) {
      case undefined:
        return selectedChannels

      case 'manual':
        return sort(selectedChannels, 'asc', channel =>
          sortMode.order.indexOf(channel),
        )

      case 'by-label':
        return sort(
          selectedChannels,
          sortMode.order,
          channel => channelDisplayNameById[channel] ?? '',
        )

      case 'self-organize':
        return isSelfOrganazingHeatMapQueryStale || !selfOrganizingHeatMapQuery
          ? selectedChannels
          : sort(selectedChannels, 'asc', channel =>
              selfOrganizingHeatMapQuery.result.hierarchical_clustering_results.ordered_columns
                .map(id => id.toString())
                .indexOf(channel),
            )
    }
  },
)

export const selectSelectedChannelsWithDetails = createSelector(
  selectChannelById,
  selectSortedSelectedChannels,
  (channelsByName, selectedChannels) => {
    return selectedChannels.map(channel => channelsByName[channel])
  },
)
