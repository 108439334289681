import Highcharts from 'highcharts'

import { AnalysisHistogramBaseOptions } from 'components/graphs/AnalysisHistogramBaseOptions'

import {
  selectAnalysisHistogramSeriesComputationParams,
  selectChannelDisplayNames,
} from 'pages/analysis/store/selectors'

import { Graph } from 'shared/models/Graphs'
import { RootState } from 'shared/store'
import { analysisWorker } from 'shared/worker'

type RenderHistogramChartProps = {
  state: RootState
  chart: Graph
  width: number
  height: number
  shouldShowAxisLabels?: boolean
}

type RenderHistorgarmChartResult = {
  chartId: string
  svg: string
  title: string
  xAxis: string
  yAxis: undefined
  width: number
  height: number
}

export const renderHistogramChart = async ({
  state,
  chart,
  width,
  height,
  shouldShowAxisLabels,
}: RenderHistogramChartProps): Promise<RenderHistorgarmChartResult> => {
  const computationsParams =
    selectAnalysisHistogramSeriesComputationParams(state)[chart.id]
  const channelDisplayNames = selectChannelDisplayNames(state)
  if (!computationsParams) {
    throw new Error('Could not find chart params for chartId: ' + chart.id)
  }

  const series =
    await analysisWorker.computeHighPerformanceHistogramSeries(
      computationsParams,
    )

  return {
    chartId: chart.id,
    svg: new Highcharts.Chart(document.createElement('div'), {
      ...AnalysisHistogramBaseOptions,
      chart: {
        ...AnalysisHistogramBaseOptions.chart,
        height,
        width,
      },
      xAxis: {
        title: {
          text: shouldShowAxisLabels
            ? channelDisplayNames[chart.x_axis]
            : undefined,
        },
        labels: {
          style: {
            fontFamily: 'Arial',
          },
        },
      },
      yAxis: {
        ...AnalysisHistogramBaseOptions.yAxis,
        title: {
          text: shouldShowAxisLabels ? chart.y_axis : undefined,
        },
        labels: {
          style: {
            fontFamily: 'Arial',
          },
        },
      },
      series,
    }).getSVG(),
    title: chart.name,
    xAxis: chart.x_axis,
    yAxis: undefined,
    width,
    height,
  }
}
