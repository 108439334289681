import { filesize } from 'filesize'
import { useState } from 'react'
import styled from 'styled-components'

import { ReactComponent as FilesIcon } from 'assets/images/icons/fcs-file.svg'
import { ReactComponent as WorkflowsIcon } from 'assets/images/icons/workflows.svg'

import { CircularProgress } from 'components/CircularProgress'
import { OptionsContextMenu } from 'components/OptionsContextMenu'
import { UserWidget } from 'components/UserWidget'
import { Button } from 'components/button/Button'
import { SearchInput } from 'components/input/SearchInput'
import { EmptyTableInfo } from 'components/table/EmptyTableInfo'
import { TableColumn, TableRow } from 'components/table/Table'
import { TableWithPagination } from 'components/table/TableWithPagination'
import {
  DateFilter,
  DateFilterValue,
} from 'components/table/filters/DateFilter'
import { MultipleSelectFilterWithPagination } from 'components/table/filters/MultipleSelectFilterWithPagination'
import { NumberRangeFilter } from 'components/table/filters/NumberRangeFilter'
import { SelectFilter } from 'components/table/filters/SelectFilter'
import { useMultipleSelectFilter } from 'components/table/filters/useMultipleSelectFilter'
import { useTableSelection } from 'components/table/useTableSelection'
import { useTableSorting } from 'components/table/useTableSorting'

import {
  CompensatedFileStatus,
  useDeleteCompensatedFileMutation,
  useGetCompensatedFileAuthorsQuery,
  useGetCompensatedFileBatchNamesQuery,
  useGetCompensatedFileIdsQuery,
  useGetCompensatedFilesQuery,
} from 'shared/api/files.api'
import { useModal } from 'shared/contexts/ModalContext'
import { useEventCallback } from 'shared/hooks/useEventCallback'
import { formatDateTime, includeIf } from 'shared/utils/utils'

import { CreateWorkflowModal } from './CreateWorkflowModal'
import { SectionHeader } from './SectionHeader'
import { StatusChip } from './StatusChip'

type CompensatedFilesProps = {
  projectId: string
}

export const CompensatedFiles: React.FC<CompensatedFilesProps> = ({
  projectId,
}) => {
  const [page, setPage] = useState(1)
  const [searchValue, setSearchValue] = useState<string | undefined>(undefined)
  const [dateFilterValue, setDateFilterValue] =
    useState<DateFilterValue>(undefined)
  const [sizeFilterValue, setSizeFilterValue] = useState<
    [number | undefined, number | undefined]
  >([undefined, undefined])
  const [selectedStatus, setSelectedStatus] = useState<
    `${CompensatedFileStatus}` | undefined
  >()

  const { showModal } = useModal()

  const { sortingColumn, sortingDirection, orderingString, setSorting } =
    useTableSorting('created_at', 'desc')

  const {
    selectedIds: selectedBatchNames,
    page: batchNamesFilterPage,
    filterSearchValue: batchNamesFilterSearchValue,
    handleSelectOption: handleSelectBatchName,
    handlePageChange: handleBatchNamesFilterPageChange,
    handleFilterSearchValueChange: handleBatchNamesFilterSearchValueChange,
  } = useMultipleSelectFilter()

  const {
    selectedIds: selectedAuthorIds,
    page: authorsFilterPage,
    filterSearchValue: authorsFilterSearchValue,
    handleSelectOption: handleSelectAuthor,
    handleFilterSearchValueChange: handleAuthorsFilterSearchValueChange,
    handlePageChange: handleAuthorsFilterPageChange,
  } = useMultipleSelectFilter()

  const compensatedFilesQueryFilters = {
    name: searchValue,
    batch_name: selectedBatchNames.join(','),
    author: selectedAuthorIds.join(','),
    created_at_after: dateFilterValue?.[0]?.toISOString(),
    created_at_before: dateFilterValue?.[1]?.toISOString(),
    size_min: sizeFilterValue[0] ? sizeFilterValue[0] * 1000 : undefined,
    size_max: sizeFilterValue[1] ? sizeFilterValue[1] * 1000 : undefined,
    status: selectedStatus,
  }

  const getCompensatedFilesQueryState = useGetCompensatedFilesQuery({
    projectId,
    ordering: orderingString,
    page,
    search: compensatedFilesQueryFilters,
  })

  const compensatedFileIdsQueryState = useGetCompensatedFileIdsQuery({
    search: {
      ...compensatedFilesQueryFilters,
      project: projectId,
      status: CompensatedFileStatus.Ready,
    },
  })

  const {
    selectedIds,
    areAllSelected,
    handleSelect,
    handleSelectAll,
    handleSelectCurrentPage,
  } = useTableSelection({
    allIds: compensatedFileIdsQueryState.data ?? [],
    currentPageIds:
      getCompensatedFilesQueryState.data?.results
        .filter(file => file.status === CompensatedFileStatus.Ready)
        .map(file => file.id) ?? [],
  })

  const getCompensatedFileBatchNamesQueryState =
    useGetCompensatedFileBatchNamesQuery(
      {
        projectId,
        page: batchNamesFilterPage,
        search: {
          lookup_batch_name: batchNamesFilterSearchValue,
        },
      },
      { refetchOnMountOrArgChange: true },
    )

  const getCompensatedFileAuthorsQueryState = useGetCompensatedFileAuthorsQuery(
    {
      projectId,
      page: authorsFilterPage,
      search: {
        lookup_author: authorsFilterSearchValue,
      },
    },
  )

  const [triggerDeleteCompensatedFileMutation] =
    useDeleteCompensatedFileMutation()

  const handleDelete = (id: string) => () => {
    triggerDeleteCompensatedFileMutation(id)
  }

  const handleOpenCreateWorkflowModal = useEventCallback(() => {
    showModal(CreateWorkflowModal, {
      compensatedFileIds: selectedIds,
      projectId,
    })
  })

  const columns: TableColumn[] = [
    {
      key: 'batch_name',
      label: 'Batch Name',
      canSort: true,
      style: { width: 100 },
    },
    {
      key: 'file_name',
      label: 'File name',
      canSort: true,
    },
    {
      key: 'status',
      label: 'Status',
      style: { width: 0 },
      canSort: true,
    },
    {
      key: 'actions',
      label: '',
      style: { width: 0 },
      canSort: false,
    },
    {
      key: 'total_cell_count',
      label: 'Total cell count',
      canSort: true,
    },
    {
      key: 'author',
      label: 'Author',
      canSort: true,
    },
    {
      key: 'size',
      label: 'Size',
      canSort: true,
    },
    {
      key: 'analyses',
      label: 'Analyses',
      canSort: true,
    },
    {
      key: 'sample_acquisition_date',
      label: 'Sample Acquisition',
      canSort: true,
    },
  ]

  const rows: TableRow[] =
    getCompensatedFilesQueryState.data?.results.map(file => ({
      key: file.id,
      shouldDisableCheckbox: file.status !== CompensatedFileStatus.Ready,
      cells: [
        {
          key: 'batch_name',
          content: file.batch_name,
        },
        {
          key: 'fileName',
          content: file.name,
        },
        {
          key: 'status',
          title: file.status,
          content: <StatusChip status={file.status} />,
        },
        {
          key: 'actions',
          content: (
            <OptionsContextMenu
              options={[
                {
                  label: 'Copy ID',
                  onClick: () =>
                    navigator.clipboard.writeText(
                      `compensated-file:${file.id}`,
                    ),
                },
                ...includeIf(file.status === CompensatedFileStatus.Error, [
                  {
                    label: 'Delete',
                    textColor: 'error' as const,
                    onClick: handleDelete(file.id),
                  },
                ]),
              ]}
            />
          ),
        },
        {
          key: 'cellCount',
          content: file.fcs_file_total_cell_count.toLocaleString(),
        },
        {
          key: 'author',
          content: file.author_name,
        },
        {
          key: 'size',
          content: filesize((file.fcs_file_size ?? 0) * 1000),
        },
        {
          key: 'analyses',
          content: file.analyses_count,
        },
        {
          key: 'sampleAcquisition',
          content: formatDateTime(file.fcs_file_acquisition_date),
        },
      ],
    })) ?? []

  return (
    <CompensatedFilesRoot>
      <Header>
        <UserWidget />
      </Header>
      <Main>
        <SectionHeader title="Compensated files">
          <SectionHeaderButtonsContainer>
            <div
              title={
                !selectedIds.length ? 'You must select at least one file' : ''
              }
            >
              <SectionHeaderActionButton
                disabled={!selectedIds.length}
                onClick={handleOpenCreateWorkflowModal}
              >
                <WorkflowsIcon />
                Create workflow
              </SectionHeaderActionButton>
            </div>
          </SectionHeaderButtonsContainer>
        </SectionHeader>
        {getCompensatedFilesQueryState.isLoading ? (
          <CircularProgress />
        ) : (
          <>
            <TableFilters>
              <SearchInput
                placeholder="Search files"
                onChange={setSearchValue}
              />
              <MultipleSelectFilterWithPagination
                title="Batch name"
                placeholder="Search batch names"
                options={
                  getCompensatedFileBatchNamesQueryState.data?.results.map(
                    batchName => ({ id: batchName, label: batchName }),
                  ) ?? []
                }
                selectedIds={selectedBatchNames}
                onSelect={handleSelectBatchName}
                page={batchNamesFilterPage}
                pageCount={
                  getCompensatedFileBatchNamesQueryState.data?.page_count ?? 1
                }
                onPageChange={handleBatchNamesFilterPageChange}
                onSearchValueChange={handleBatchNamesFilterSearchValueChange}
              />
              <DateFilter
                value={dateFilterValue}
                onChange={setDateFilterValue}
              />
              <MultipleSelectFilterWithPagination
                title="Author"
                placeholder="Search authors"
                options={
                  getCompensatedFileAuthorsQueryState.data?.results.map(
                    user => ({
                      id: user.id,
                      label: user.username,
                    }),
                  ) ?? []
                }
                selectedIds={selectedAuthorIds}
                onSelect={handleSelectAuthor}
                page={authorsFilterPage}
                pageCount={
                  getCompensatedFileAuthorsQueryState.data?.page_count ?? 1
                }
                onPageChange={handleAuthorsFilterPageChange}
                onSearchValueChange={handleAuthorsFilterSearchValueChange}
              />
              <NumberRangeFilter
                title="Size"
                value={sizeFilterValue}
                onChange={setSizeFilterValue}
              />
              <SelectFilter
                title="Status"
                options={Object.keys(CompensatedFileStatus).map(key => ({
                  id: key,
                  label: key,
                }))}
                selectedId={selectedStatus}
                onSelect={id =>
                  setSelectedStatus(
                    id as `${CompensatedFileStatus}` | undefined,
                  )
                }
              />
            </TableFilters>
            {getCompensatedFilesQueryState.data?.results.length ? (
              <TableWithPagination
                rows={rows}
                columns={columns}
                sortingColumn={sortingColumn}
                sortingDirection={sortingDirection}
                onSortingChange={setSorting}
                selectedElements={selectedIds}
                areAllSelected={areAllSelected}
                isSelectionDisabled={getCompensatedFilesQueryState.data?.results.every(
                  file => file.status !== CompensatedFileStatus.Ready,
                )}
                onToggleSelection={handleSelect}
                onToggleSelectAll={handleSelectAll}
                onToggleSelectAllEntriesFromCurrentPage={
                  handleSelectCurrentPage
                }
                page={page}
                pageCount={getCompensatedFilesQueryState.data.page_count}
                onPageChange={(_event, page) => setPage(page)}
              />
            ) : (
              <StyledEmptyTableInfo
                text="No files found"
                Icon={<StyledFilesIcon />}
              />
            )}
          </>
        )}
      </Main>
    </CompensatedFilesRoot>
  )
}

const CompensatedFilesRoot = styled.div`
  margin: 60px 100px;
`

const Header = styled.div`
  display: flex;
  justify-content: end;
`

const Main = styled.main``

const SectionHeaderButtonsContainer = styled.div`
  display: flex;
  gap: ${props => props.theme.spacing(2)}px;
`

const SectionHeaderActionButton = styled(Button)`
  height: 48px;
  padding: 0 ${props => props.theme.spacing(2)}px;

  & > span > svg {
    margin-right: ${props => props.theme.spacing(1)}px;
  }
`

const StyledFilesIcon = styled(FilesIcon)`
  width: 160px;
  height: 160px;
  stroke: ${props => props.theme.colors.greyscale[20]};
  stroke-width: 0.1;
  & > path {
    fill: ${props => props.theme.colors.greyscale[20]};
  }
`

const TableFilters = styled.div`
  display: flex;
  align-items: center;
  gap: ${props => props.theme.spacing(2)}px;
  margin: ${props => props.theme.spacing(4)}px 0;
`

const StyledEmptyTableInfo = styled(EmptyTableInfo)`
  margin-top: ${props => props.theme.spacing(8)}px;
`
