import { Tooltip } from '@material-ui/core'
import React, { FC, ReactNode } from 'react'
import styled, { css } from 'styled-components'

import { Icon, IconNames } from 'assets/images/icons/Icon'

type ContextMenuItemProps = {
  label: ReactNode
  onClick: React.MouseEventHandler<HTMLDivElement>
  startIcon?: IconNames
  className?: string
  disabled?: boolean
  tooltip?: string
}

export const ContextMenuItem: FC<ContextMenuItemProps> = ({
  label,
  onClick,
  startIcon,
  className,
  disabled,
  tooltip,
}) => {
  return (
    <Tooltip title={tooltip ?? ''} placement="right">
      <Container
        className={className}
        onClick={disabled ? undefined : onClick}
        $disabled={disabled}
      >
        {startIcon && <StartIcon name={startIcon} />}
        {label}
      </Container>
    </Tooltip>
  )
}

const Container = styled.div<{ $disabled: boolean | undefined }>`
  width: 100%;
  min-height: 52px;
  padding: 0 24px;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  border-bottom: 1px solid ${props => props.theme.colors.primaryDark[20]};
  cursor: pointer;
  font-size: ${props => props.theme.font.size.smallest}px;
  &:hover {
    background-color: ${props => props.theme.colors.background};
  }
  &:last-of-type {
    border: none;
  }

  ${props =>
    props.$disabled &&
    css`
      color: ${props.theme.colors.greyscale[40]};
      background-color: ${props.theme.colors.greyscale[10]};
      cursor: not-allowed;
    `}
`

const StartIcon = styled(Icon)`
  margin-right: 10px;
  width: 24px;
  height: 24px;
`
