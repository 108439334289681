import { Typography } from '@material-ui/core'
import styled from 'styled-components'

import { Button } from 'components/Button'
import Input from 'components/forms/Input'

import { LicensePackageResourceType } from 'shared/models/LicenseModels'

import { PackageResourceCard } from './PackageResourceCard'

type LicensePackageResourceWithCustomValues = Omit<
  LicensePackageResourceType,
  'value'
> & { value: number | string }

type PackageProps = {
  name: string
  resources: LicensePackageResourceWithCustomValues[]
  canChangeSeats?: boolean
  isSelected?: boolean
  price?: string
  currency?: string
  vat?: string
  seats?: number
  maxSeats?: number
  description?: string
  buttonText?: string
  displayOnly?: boolean
  onPackageClick?: () => void
  onButtonClick?: () => void
  onSeatChange?: (mode: 'add' | 'subtract') => void
}

export const Package = ({
  name,
  resources,
  canChangeSeats = false,
  isSelected = false,
  price,
  currency,
  vat,
  seats = 1,
  description,
  buttonText,
  displayOnly = false,
  onPackageClick,
  onButtonClick,
  onSeatChange,
}: PackageProps): JSX.Element => {
  const isEnterprise = name === 'Enterprise'
  return (
    <PackageRoot
      $displayOnly={displayOnly}
      $isEnterprise={isEnterprise}
      $isSelected={isSelected}
      onClick={onPackageClick}
    >
      <PackageHeader $isEnterprise={isEnterprise} $isSelected={isSelected}>
        <Typography variant="h4" align="center">
          {name}
        </Typography>
      </PackageHeader>
      {description && <PackageDescription>{description}</PackageDescription>}

      {['CP', 'SP', 'CG', 'ST'].map(resourceType => {
        const resource = resources.find(
          resource => resource.resource_type === resourceType,
        )
        if (!resource) {
          return null
        }
        const { resource_type, value } = resource
        return (
          <PackageResourceCard
            key={name + resource_type}
            type={resource_type}
            value={value}
          />
        )
      })}
      {canChangeSeats && onSeatChange ? (
        <SeatSelectorContainer>
          <Typography variant="body1" align="center">
            Seats
          </Typography>
          <SeatSelectorInput>
            <SeatSelectorButton
              onClick={() => onSeatChange('subtract')}
              disabled={seats === 1 || !isSelected}
            >
              -
            </SeatSelectorButton>
            <StyledInput value={seats ?? 1} readOnly={true} />
            <SeatSelectorButton
              onClick={() => onSeatChange('add')}
              disabled={!isSelected}
            >
              +
            </SeatSelectorButton>
          </SeatSelectorInput>
        </SeatSelectorContainer>
      ) : null}
      {price && currency ? (
        <PriceContainer>
          <Typography variant="body1" align="center">
            Yearly license:
          </Typography>
          <Typography variant="body1" align="center">
            Net price: <b>{price}</b> {currency}
          </Typography>
          <Typography variant="body1" align="center">
            Gross price:{' '}
            <b>{(parseInt(price) + parseInt(vat ?? '0')).toFixed(2)}</b>{' '}
            {currency}
          </Typography>
        </PriceContainer>
      ) : null}
      {buttonText ? (
        <BuyButton
          color="secondary"
          onClick={onButtonClick}
          disabled={!isEnterprise && !isSelected}
        >
          {buttonText}
        </BuyButton>
      ) : null}
    </PackageRoot>
  )
}

const PackageRoot = styled.div<{
  $isEnterprise: boolean
  $isSelected: boolean
  $displayOnly: boolean
}>`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 250px;
  height: fit-content;
  border: 2px solid
    ${props =>
      props.$isEnterprise
        ? props.theme.colors.gold
        : props.theme.colors.primary[props.$isSelected ? 100 : 20]};
  border-radius: 10px;
  margin: 0 ${props => props.theme.spacing(1)}px;
  padding-bottom: ${props => props.theme.spacing(2)}px;
  cursor: ${props =>
    props.$isEnterprise || props.$displayOnly ? 'default' : 'pointer'};
`
const PackageHeader = styled.div<{
  $isEnterprise: boolean
  $isSelected: boolean
}>`
  width: 100%;
  padding: 20px 40px;
  margin-bottom: 4px;
  background: ${props =>
    props.$isEnterprise
      ? props.theme.colors.gold
      : props.theme.colors.primary[props.$isSelected ? 100 : 20]};
  color: ${props =>
    props.$isSelected
      ? props.theme.colors.white
      : props.theme.colors.primaryDark[100]};
`

const PackageDescription = styled.div`
  margin: ${props => props.theme.spacing(1)}px
    ${props => props.theme.spacing(2)}px;
`

const BuyButton = styled(Button)`
  width: calc(100% - 16px);
  margin: 12px 0;
`

const PriceContainer = styled.div`
  margin: ${props => props.theme.spacing(1)}px 0;
`

const SeatSelectorContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: ${props => props.theme.spacing(1)}px
    ${props => props.theme.spacing(1)}px 0;
`

const SeatSelectorInput = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
`

const StyledInput = styled(Input)`
  width: 48px;
  margin: ${props => props.theme.spacing(1)}px
    ${props => props.theme.spacing(2)}px;

  & > div {
    padding: 0;
  }
`

const SeatSelectorButton = styled(Button)`
  width: 36px;
  min-width: 36px;
  height: 36px;
  padding: 12px;
  font-size: ${props => props.theme.font.size.h2}px;

  & > .MuiButton-label {
    height: 12px;
    line-height: 6px;
  }
`
