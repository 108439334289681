import { CircularProgress, Typography } from '@material-ui/core'
import { useEffect, useMemo, useState } from 'react'
import styled from 'styled-components'

import { Button } from 'components/Button'
import { LicenseAgreementConfirmation } from 'components/LicenseAgreementConfirmation'
import { Modal } from 'components/Modal'

import {
  useGetBasketQuery,
  usePurchaseBasketMutation,
} from 'shared/api/baskets.api'

import { Package } from './Package'

type PackageDetailsModalProps = {
  basketId: string
  onClose: () => void
}

export const PackageDetailsModal = ({
  basketId,
  onClose,
}: PackageDetailsModalProps): JSX.Element => {
  const basketResult = useGetBasketQuery({ id: basketId })
  const [purchaseBasket, purchaseBasketResult] = usePurchaseBasketMutation()
  const [isLicenseCheckboxChecked, setLicenseCheckboxChecked] = useState(false)

  useEffect(() => {
    if (purchaseBasketResult.isSuccess && purchaseBasketResult.data.url) {
      window.location.href = purchaseBasketResult.data.url
    }
  }, [purchaseBasketResult.data, purchaseBasketResult.isSuccess])

  const { licensePackage, seatPackage } = useMemo(() => {
    const basketItems = basketResult.data?.baskets_items
    const seatBasketItem = basketItems?.find(item =>
      item.license_package.name.includes('seats'),
    )
    const licensePackage = basketItems?.filter(
      item => item !== seatBasketItem,
    )[0].license_package
    const seatPackage = seatBasketItem?.license_package
    return { licensePackage, seatPackage }
  }, [basketResult.data?.baskets_items])

  return (
    <Modal open onClose={onClose}>
      <PackageDetailsRoot>
        {basketResult.isLoading || purchaseBasketResult.isLoading ? (
          <PackageDetailsInfoContainer>
            <CircularProgress />
          </PackageDetailsInfoContainer>
        ) : purchaseBasketResult.error ? (
          <PackageDetailsInfoContainer>
            <Typography variant="h5">
              Something went wrong while generating payment session.{' '}
              <b>You have not been billed</b> and can safely try again. You may
              find a failed purchase entry in your purchase history.
            </Typography>
            <ButtonsContainer>
              <Button onClick={onClose} grey>
                Cancel
              </Button>
              <Button onClick={purchaseBasketResult.reset}>Retry</Button>
            </ButtonsContainer>
          </PackageDetailsInfoContainer>
        ) : !basketResult.data ||
          basketResult.error ||
          !licensePackage ||
          !seatPackage ? (
          <PackageDetailsInfoContainer>
            <Typography variant="h5">
              Something went wrong while fetching package details, please try
              again.
            </Typography>
            <ButtonsContainer>
              <Button onClick={onClose} grey>
                Cancel
              </Button>
              <Button onClick={basketResult.refetch}>Retry</Button>
            </ButtonsContainer>
          </PackageDetailsInfoContainer>
        ) : (
          <PackageDetailsWrapper>
            <PackageDetailsContainer>
              <Typography variant="h4">You are about to purchase:</Typography>
              <StyledPackage
                name={licensePackage.name}
                resources={[
                  ...licensePackage.license_packages_resource_types,
                  ...seatPackage.license_packages_resource_types,
                ]}
                description={licensePackage.description}
                price={basketResult.data.net_price}
                vat={basketResult.data.vat_value}
                currency={basketResult.data.currency}
                isSelected={true}
                displayOnly={true}
              />
              <div>
                Package contents will be available for{' '}
                {licensePackage.durability.split(' ')[0]} days from purchase
                date
              </div>
            </PackageDetailsContainer>
            {basketResult.data.license_agreement && (
              <LicenseAgreementConfirmation
                licenseLink={basketResult.data.license_agreement}
                isChecked={isLicenseCheckboxChecked}
                onChange={() =>
                  setLicenseCheckboxChecked(!isLicenseCheckboxChecked)
                }
              />
            )}
            <ButtonsContainer>
              <Button onClick={onClose} grey>
                Cancel
              </Button>
              <Button
                onClick={() => purchaseBasket({ id: basketId })}
                disabled={!isLicenseCheckboxChecked}
                color="secondary"
              >
                Proceed to payment
              </Button>
            </ButtonsContainer>
          </PackageDetailsWrapper>
        )}
      </PackageDetailsRoot>
    </Modal>
  )
}

const PackageDetailsRoot = styled.div`
  width: 600px;
  min-height: 400px;
  padding: 20px 0;
  background: ${props => props.theme.colors.background};
  border-radius: 20px;
`

const PackageDetailsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`

const PackageDetailsContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 16px;
  max-height: 80vh;
  overflow-y: auto;
`

const PackageDetailsInfoContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 400px;
  gap: 32px;
  text-align: center;
`

const ButtonsContainer = styled.div`
  display: flex;
  gap: 16px;
`

const StyledPackage = styled(Package)`
  cursor: default;
  pointer-events: none;
`
