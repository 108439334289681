import { Formik, FormikConfig } from 'formik'
import { omit, isEqual } from 'lodash'
import React, { useCallback, useEffect, useRef, useState } from 'react'
import ReCAPTCHA from 'react-google-recaptcha'
import styled from 'styled-components'
import { object, string } from 'yup'

import { Button } from 'components/Button'
import { Section } from 'components/Section'
import Input from 'components/forms/Input'
import { ReCaptcha } from 'components/forms/ReCaptcha'

import { useLoginMutation } from 'shared/api/auth.api'
import { useUpdatePasswordMutation } from 'shared/api/user.api'
import {
  ReCaptchaFlags,
  useReCaptchaRequired,
} from 'shared/hooks/useReCaptchaRequired'
import { useServerFormValidation } from 'shared/hooks/useServerFormValidation'
import { User } from 'shared/models/User'
import { useAppDispatch } from 'shared/store'
import { setIsAuthenticated } from 'shared/store/auth.slice'
import { showNotification } from 'shared/store/notification.slice'
import { handleError } from 'shared/utils/errorHandler'

type UpdatePasswordFormProps = {
  user: User
}

type UpdatePasswordFormValues = {
  oldPassword: string
  newPassword: string
  confirmPassword: string
}

const UpdatePasswordFormSchema = object({
  oldPassword: string().required(),
  newPassword: string()
    .required()
    .min(8, 'Password must be at least 8 characters')
    .matches(/[a-z]/, 'Password must contain at least one lowercase letter')
    .matches(/[A-Z]/, 'Password must contain at least one uppercase letter')
    .matches(/[0-9]/, 'Password must contain at least one number')
    .matches(
      /[^a-zA-Z0-9]/,
      'Password must contain at least one special character',
    )
    .test(
      'password-different',
      'New password cannot be the same as current password',
      function (value, context) {
        return value !== context.parent.oldPassword
      },
    ),
  confirmPassword: string()
    .required()
    .test('passwords-match', 'Passwords must match', function (value, context) {
      return value === context.parent.newPassword
    }),
})

const updatePasswordInitialValues: UpdatePasswordFormValues = {
  oldPassword: '',
  newPassword: '',
  confirmPassword: '',
}

export const UpdatePasswordForm = ({
  user,
}: UpdatePasswordFormProps): JSX.Element => {
  const dispatch = useAppDispatch()
  const [updatePassword, updatePasswordResult] = useUpdatePasswordMutation()
  const [login, loginResult] = useLoginMutation()
  const isReCaptchaRequired = useReCaptchaRequired(ReCaptchaFlags.Login)
  const [isReCaptchaCompleted, setReCaptchaCompleted] = useState(false)
  const reCaptchaRef = useRef<ReCAPTCHA>(null)

  const { globalError, getFieldRequestError, setSubmittedValues } =
    useServerFormValidation({
      error: updatePasswordResult.error,
      fields: Object.keys(
        updatePasswordInitialValues,
      ) as (keyof UpdatePasswordFormValues)[],
    })

  const handleUpdatePasswordSubmit: FormikConfig<UpdatePasswordFormValues>['onSubmit'] =
    useCallback(
      async (values: UpdatePasswordFormValues, { resetForm }) => {
        try {
          await updatePassword({
            ...omit(values, 'confirmPassword'),
          }).unwrap()
          resetForm()

          const reCaptchaToken =
            (reCaptchaRef.current && reCaptchaRef.current.getValue()) || ''

          await login({
            email: user?.email ?? '',
            password: values.newPassword,
            reCaptchaToken,
          })
        } catch (error) {
          handleError(error)
        } finally {
          setSubmittedValues(values)
          reCaptchaRef.current && reCaptchaRef.current.reset()
        }
      },
      [login, setSubmittedValues, updatePassword, user?.email],
    )

  useEffect(() => {
    if (updatePasswordResult.isSuccess) {
      dispatch(
        showNotification({
          type: 'success',
          description: 'Password updated successfully',
          autoHideDuration: 5000,
        }),
      )
    }

    if (updatePasswordResult.error && globalError) {
      dispatch(
        showNotification({
          type: 'error',
          description: `An error occured when updating password: ${globalError}`,
        }),
      )
    }
  }, [dispatch, globalError, updatePasswordResult])

  useEffect(() => {
    if (loginResult.error) {
      dispatch(setIsAuthenticated(false))
    }
  }, [dispatch, loginResult.error])

  return (
    <Formik<UpdatePasswordFormValues>
      initialValues={updatePasswordInitialValues}
      validationSchema={UpdatePasswordFormSchema}
      onSubmit={handleUpdatePasswordSubmit}
    >
      {({
        values,
        touched,
        errors,
        isSubmitting,
        isValid,
        initialValues,
        handleChange,
        handleBlur,
        handleSubmit,
        handleReset,
      }) => (
        <form onSubmit={handleSubmit} onReset={handleReset}>
          <Section title="Change password">
            <ReCaptcha
              onCompleted={() => setReCaptchaCompleted(true)}
              ref={reCaptchaRef}
            >
              <Input
                name="oldPassword"
                type="password"
                label="Current password"
                value={values.oldPassword}
                error={
                  touched.oldPassword
                    ? getFieldRequestError('oldPassword', values.oldPassword) ||
                      errors.oldPassword
                    : undefined
                }
                onChange={handleChange}
                onBlur={handleBlur}
              />
              <Input
                name="newPassword"
                type="password"
                label="New password"
                value={values.newPassword}
                error={
                  touched.newPassword
                    ? getFieldRequestError('newPassword', values.newPassword) ||
                      errors.newPassword
                    : undefined
                }
                onChange={handleChange}
                onBlur={handleBlur}
              />
              <Input
                name="confirmPassword"
                type="password"
                label="Retype new password"
                value={values.confirmPassword}
                error={
                  touched.confirmPassword ? errors.confirmPassword : undefined
                }
                onChange={handleChange}
                onBlur={handleBlur}
              />
            </ReCaptcha>
            <ButtonContainer>
              <Button type="reset" grey>
                Reset form
              </Button>
              <Button
                type="submit"
                disabled={
                  isSubmitting ||
                  !isValid ||
                  isEqual(values, initialValues) ||
                  (isReCaptchaRequired && !isReCaptchaCompleted)
                }
              >
                Update password
              </Button>
            </ButtonContainer>
          </Section>
        </form>
      )}
    </Formik>
  )
}

const ButtonContainer = styled.div`
  display: flex;
  justify-content: center;
  gap: 8px;
  margin: 16px 0;
`
