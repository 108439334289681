import {
  Button,
  IconButton,
  InputBase,
  Tooltip,
  Typography,
} from '@material-ui/core'
import {
  AddCircleOutline,
  Check as CheckIcon,
  Close as CloseIcon,
  Delete as DeleteIcon,
  Edit as EditIcon,
  Warning as WarningIcon,
} from '@material-ui/icons'
import { useState } from 'react'
import styled from 'styled-components'

type EditableLabelProps = {
  className?: string
  value: string
  emptyLabel?: string
  onChange: (value: string) => void
  onValidate?: (value: string) => string
  onDelete?: () => void
}

export const EditableLabel = ({
  className,
  value,
  emptyLabel,
  onChange,
  onValidate,
  onDelete,
}: EditableLabelProps): JSX.Element => {
  const [mode, setMode] = useState<'view' | 'edit'>('view')
  const [internalValue, setInternalValue] = useState(value)

  const error = onValidate?.(internalValue)

  const handleEdit = () => {
    setInternalValue(value)
    setMode('edit')
  }

  const handleSubmit = () => {
    if (!error) {
      onChange(internalValue)
      handleClose()
    }
  }

  const handleClose = () => {
    setMode('view')
  }

  return (
    <EditableLabelRoot className={className}>
      {mode === 'view' && (
        <>
          {value ? (
            <>
              {onDelete ? (
                <StyledIconButton onClick={onDelete}>
                  <DeleteIcon />
                </StyledIconButton>
              ) : (
                <span />
              )}
              <StyledIconButton onClick={handleEdit}>
                <EditIcon />
              </StyledIconButton>
              <Typography
                title={value}
                className="label"
                align="left"
                onDoubleClick={handleEdit}
              >
                {value}
              </Typography>
            </>
          ) : (
            emptyLabel && (
              <AddButton variant="text" color="primary" onClick={handleEdit}>
                <AddCircleOutline />
                {emptyLabel}
              </AddButton>
            )
          )}
        </>
      )}
      {mode === 'edit' && (
        <>
          <StyledIconButton onClick={handleClose}>
            <CloseIcon />
          </StyledIconButton>
          <Tooltip title={error ?? ''}>
            {error ? (
              <WarningIcon />
            ) : (
              <StyledIconButton onClick={handleSubmit}>
                <CheckIcon />
              </StyledIconButton>
            )}
          </Tooltip>
          <InputBase
            className="input-root"
            autoFocus
            value={internalValue}
            onChange={event => setInternalValue(event.target.value)}
            onKeyPress={event => {
              if (event.key === 'Enter') {
                handleSubmit()
              }
            }}
          />
        </>
      )}
    </EditableLabelRoot>
  )
}

const EditableLabelRoot = styled.div`
  display: grid;
  grid-template-columns: 24px 24px 1fr;
  align-items: center;
  width: 100%;

  .label {
    padding: 1px 3px;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    line-height: unset;
  }

  .MuiInputBase-root {
    padding: 0;
    line-height: unset;

    input {
      padding: 3px 3px;
      color: ${props => props.theme.colors.primaryDark['100']};
    }
  }

  svg {
    width: 16px;
    height: 16px;
    cursor: pointer;
    place-self: center;
  }
`

const StyledIconButton = styled(IconButton)`
  width: 16px;
  height: 16px;
  color: ${props => props.theme.colors.primaryDark['100']};
`

const AddButton = styled(Button)`
  width: max-content;
  padding-left: 26px;
  vertical-align: middle;
  font-size: ${props => props.theme.font.size.smallest}px;

  & .MuiSvgIcon-root {
    margin-right: 4px;
  }
`
