import React, { FC } from 'react'
import styled from 'styled-components'

import { Checkbox } from 'components/Checkbox'
import { Table } from 'components/Table'
import { TableRow } from 'components/TableRow'

import { Logs } from 'shared/models/Logs'

import { LogEntry } from './LogEntry'

type LogTableProps = Readonly<{
  logs: Logs
  allSelected: boolean
  onSelectAll: () => void
  isSelected: (id: string) => boolean
  onSelect: (id: string) => void
}>

export const LogTable: FC<LogTableProps> = ({
  logs,
  allSelected,
  onSelectAll,
  isSelected,
  onSelect,
}) => {
  return (
    <StyledTable
      layout={['5%', '15%', '15%', '10%', '10%', '10%', '10%', '15%', '10%']}
    >
      <ListHeader>
        <Checkbox checked={allSelected} onClick={() => onSelectAll()} />
        <p>Name</p>
        <p>User name</p>
        <p>Permission level</p>
        <p>Station IP</p>
        <p>Date</p>
        <p>Time</p>
        <p>Modification</p>
        <p>Target File</p>
      </ListHeader>
      <LogEntriesContainer>
        {logs.map(entry => (
          <LogEntry
            {...entry}
            isSelected={isSelected}
            onSelect={onSelect}
            key={entry.id}
          />
        ))}
      </LogEntriesContainer>
    </StyledTable>
  )
}

const ListHeader = styled(TableRow)`
  color: ${props => props.theme.colors.greyscale[50]};
  font-family: ${props => props.theme.font.style.bold};
  align-items: center;
  padding: 0 20px 12px;
`

const StyledTable = styled(Table)`
  overflow: hidden;
  height: 100%;
`

const LogEntriesContainer = styled.div`
  height: calc(100% - 48px - 20px); // 100% - Header height - padding
  overflow: auto;
`
