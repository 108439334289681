import { identicon } from 'minidenticons'
import React from 'react'
import styled from 'styled-components'

type UserAvatarProps = {
  username: string
  size: number
  saturation?: number
  lightness?: number
}

export const UserAvatar = (props: UserAvatarProps): JSX.Element => {
  return (
    <Avatar
      dangerouslySetInnerHTML={{
        __html: identicon(
          props.username,
          props.saturation ?? 80,
          props.lightness ?? 80,
        ),
      }}
      $size={props.size}
    />
  )
}

const Avatar = styled.div<{ $size: number }>`
  display: block;
  width: ${props => props.$size}px;
  height: ${props => props.$size}px;

  > svg {
    background-color: ${props => props.theme.colors.primaryDark[100]};
    border-radius: 50%;
  }
`
