import { FormControlLabel } from '@material-ui/core'
import { useState } from 'react'
import styled from 'styled-components'

import { Checkbox } from 'components/Checkbox'
import { MenuItem } from 'components/menu/MenuItem'

import { useEventCallback } from 'shared/hooks/useEventCallback'

type ChangeDotSizeMenuItemProps = {
  value: number
  mode: 'cluster' | 'lasso'
  onChange: (
    value: number,
    shouldApplyToAllCharts: boolean,
    mode: 'cluster' | 'lasso',
  ) => void
  shouldShowApplyToAllCharts?: boolean
}

export const ChangeDotSizeMenuItem: React.FC<ChangeDotSizeMenuItemProps> = ({
  value,
  mode,
  shouldShowApplyToAllCharts,
  onChange,
}) => {
  const [shouldShowMenu, setShouldShowMenu] = useState(false)
  const [shouldApplyToAllCharts, setShouldApplyToAllCharts] = useState(false)

  const label = `Change ${
    mode === 'lasso' ? 'lasso clusters' : 'cluster'
  } dot size`

  const handleShowMenu = useEventCallback(() => {
    setShouldShowMenu(true)
  })

  const handleChange = useEventCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const value = +event.target.value
      onChange(value, shouldApplyToAllCharts, mode)
    },
  )

  const handleChangeShouldApplyToAllCharts = useEventCallback(
    (_event: unknown, checked: boolean) => {
      setShouldApplyToAllCharts(checked)
    },
  )

  return (
    <ChangeDotSizeMenuItemRoot
      onClick={shouldShowMenu ? undefined : handleShowMenu}
    >
      {!shouldShowMenu && <span>{label}</span>}
      {shouldShowMenu && (
        <ChangeDotSizeMenuItemMenu>
          <div>
            <span>{label}</span>
            <StyledInput
              type="number"
              min={1}
              max={100}
              defaultValue={value}
              onChange={handleChange}
            />
          </div>
          {shouldShowApplyToAllCharts && (
            <StyledFormControlLabel
              label="Apply size to all graphs"
              control={
                <Checkbox
                  checked={shouldApplyToAllCharts}
                  onChange={handleChangeShouldApplyToAllCharts}
                />
              }
            />
          )}
        </ChangeDotSizeMenuItemMenu>
      )}
    </ChangeDotSizeMenuItemRoot>
  )
}

const ChangeDotSizeMenuItemRoot = styled(MenuItem)``

const ChangeDotSizeMenuItemMenu = styled.div``

const StyledInput = styled.input`
  width: 50px;
  font-size: ${props => props.theme.font.size.small}px;
  outline: none;
  color: ${props => props.theme.colors.primaryDark[100]};
  margin-left: 8px;
`

const StyledFormControlLabel = styled(FormControlLabel)`
  .MuiFormControlLabel-label {
    font-size: 12px;
  }
`
