import { PopperProps } from '@material-ui/core'

export const createVirtualElement = (
  x: number,
  y: number,
  width = 0,
  height = 0,
): PopperProps['anchorEl'] => {
  return {
    clientWidth: width,
    clientHeight: height,
    getBoundingClientRect: () => ({
      left: x,
      top: y,
      right: x + width,
      bottom: y + height,
      width,
      height,
      x,
      y,
      toJSON: () => null,
    }),
  }
}
