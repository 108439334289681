import {
  getStoredLeafLabels,
  getStoredScales,
  getStoredTransformedData,
} from 'shared/utils/storage'

export class AnalysisWorkerStore {
  private clusteringFiles: {
    ongoingPromise?: Promise<void>
    transformedData?: Analysis.TransformedData
    metaClean?: Analysis.MetaCleanData
    leafLabels?: Analysis.LeafLabels
    scales?: Analysis.Scales
  } = {}
  private clusterTree: Analysis.ClusterTree | undefined

  public loadClusteringFiles(
    storeName: string,
    clusterTree: Analysis.ClusterTree,
  ): void {
    this.clusterTree = clusterTree
    const promise = this.internalLoadClusteringFiles(storeName)
    this.clusteringFiles.ongoingPromise = promise
  }

  public waitForClusteringFiles(): Promise<void> | undefined {
    return this.clusteringFiles.ongoingPromise
  }

  private async internalLoadClusteringFiles(storeName: string): Promise<void> {
    this.clusteringFiles = {}

    this.clusteringFiles.leafLabels = await getStoredLeafLabels(storeName)
    this.clusteringFiles.scales = await getStoredScales(storeName)

    const transformedDataResponse = await getStoredTransformedData(storeName)
    if (transformedDataResponse.metaclean && transformedDataResponse.data) {
      this.clusteringFiles.metaClean =
        transformedDataResponse.metaclean as Analysis.MetaCleanData
      this.clusteringFiles.transformedData =
        transformedDataResponse.data as Analysis.TransformedData
    } else {
      this.clusteringFiles.transformedData =
        transformedDataResponse as Analysis.TransformedData
    }
  }

  public getTransformedData(): Analysis.TransformedData {
    if (!this.clusteringFiles.transformedData) {
      throw new Error('No transformed data')
    }
    return this.clusteringFiles.transformedData
  }

  public getMetaCleanData(): Analysis.MetaCleanData {
    if (!this.clusteringFiles.metaClean) {
      throw new Error('No meta clean data')
    }
    return this.clusteringFiles.metaClean
  }

  public getLeafLabels(): Analysis.LeafLabels {
    if (!this.clusteringFiles.leafLabels) {
      throw new Error('No leaf labels')
    }
    return this.clusteringFiles.leafLabels
  }

  public getScales(): Analysis.Scales {
    if (!this.clusteringFiles.scales) {
      throw new Error('No cluster tree')
    }
    return this.clusteringFiles.scales
  }

  public getClusterTree(): Analysis.ClusterTree {
    if (!this.clusterTree) {
      throw new Error('No cluster tree')
    }
    return this.clusterTree
  }
}
