import { useState } from 'react'

import { SearchableAnalysisList } from 'components/analysis-list/SearchableAnalysisList'

import { useGetBrickQuery } from 'shared/api/workflows.api'
import { useSafeNavigate } from 'shared/hooks/router'
import { useEventCallback } from 'shared/hooks/useEventCallback'

type BrickAnalysisListProps = {
  brickId: string
  onClose: () => void
  onCloseContextMenu: () => void
}

export const BrickAnalysisList: React.FC<BrickAnalysisListProps> = ({
  brickId,
  onClose,
  onCloseContextMenu,
}) => {
  const safeNavigate = useSafeNavigate()
  const getBrickQueryState = useGetBrickQuery(brickId)

  const [search, setSearch] = useState('')

  const displayedAnalyses =
    getBrickQueryState.data?.analyses.filter(analysis =>
      analysis.name.includes(search),
    ) ?? []

  const handleAnalysisClick = useEventCallback(analysisId => {
    safeNavigate(`/analysis/${analysisId}`)
    onClose()
  })

  return (
    <SearchableAnalysisList
      availableAnalyses={displayedAnalyses}
      validationAction="view-analysis"
      onSearchValueChange={value => setSearch(value)}
      onAnalysisClick={handleAnalysisClick}
      onCloseContextMenu={onCloseContextMenu}
    />
  )
}
