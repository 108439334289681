import { useTheme } from '@material-ui/core'
import { useRef, useState } from 'react'
import styled from 'styled-components'

import { Icon } from 'assets/images/icons/Icon'

import { Button } from 'components/Button'
import { Menu } from 'components/menu/Menu'
import { MenuItem } from 'components/menu/MenuItem'
import { NestedMenuItem } from 'components/menu/NestedMenuItem'

import {
  LogbookExportModes,
  LogbookExportTypes,
  useGenerateLogbookExportMutation,
} from 'shared/api/export.api'
import { useAppDispatch } from 'shared/store'
import { showNotification } from 'shared/store/notification.slice'

import { AppTheme } from 'Theme'

type LogbookExportButtonProps = {
  analysisId: string
  selectedEntryIds: string[]
}

export const LogbookExportButton = (
  props: LogbookExportButtonProps,
): JSX.Element => {
  const { analysisId, selectedEntryIds } = props

  const theme = useTheme<AppTheme>()
  const dispatch = useAppDispatch()
  const buttonWrapperRef = useRef<HTMLDivElement>(null)
  const [showExportMenu, setShowExportMenu] = useState(false)

  const [generateLogbookExport, generateLogbookExportResult] =
    useGenerateLogbookExportMutation()

  const exportHandler = (
    type: `${LogbookExportTypes}`,
    mode: `${LogbookExportModes}`,
  ) => {
    if (mode === LogbookExportModes.Specific && selectedEntryIds.length === 0) {
      dispatch(
        showNotification({
          type: 'error',
          description: 'Select at least one entry to export.',
        }),
      )
      return
    }

    generateLogbookExport({
      analysisId,
      mode,
      type,
      entryIds: selectedEntryIds,
    })
    setShowExportMenu(false)
  }

  const loading = generateLogbookExportResult.isLoading

  return (
    <>
      <div ref={buttonWrapperRef}>
        <Button
          onClick={() => !loading && setShowExportMenu(true)}
          loading={loading}
          endIcon={
            !loading && <Icon name="export" color={theme.colors.white} />
          }
        >
          Export entries
        </Button>
      </div>

      <Menu
        open={showExportMenu}
        anchorEl={buttonWrapperRef.current}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        onClose={() => setShowExportMenu(false)}
      >
        <NestedMenuItem label="Export selected entries">
          {[
            LogbookExportTypes.Pdf,
            LogbookExportTypes.Csv,
            LogbookExportTypes.Xlsx,
          ].map(type => (
            <StyledMenuItem
              onClick={() => exportHandler(type, LogbookExportModes.Specific)}
            >
              Export as{' '}
              {type === LogbookExportTypes.Xlsx
                ? 'CSV for Excel'
                : type.toUpperCase()}
            </StyledMenuItem>
          ))}
        </NestedMenuItem>
        <NestedMenuItem label="Export all entries">
          {[
            LogbookExportTypes.Pdf,
            LogbookExportTypes.Csv,
            LogbookExportTypes.Xlsx,
          ].map(type => (
            <StyledMenuItem
              onClick={() => exportHandler(type, LogbookExportModes.All)}
            >
              Export as{' '}
              {type === LogbookExportTypes.Xlsx
                ? 'CSV for Excel'
                : type.toUpperCase()}
            </StyledMenuItem>
          ))}
        </NestedMenuItem>
      </Menu>
    </>
  )
}

const StyledMenuItem = styled(MenuItem)`
  background: ${props => props.theme.colors.white};
`
