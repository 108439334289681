import { FC, useState } from 'react'
import styled from 'styled-components'

import { AddButton } from 'components/AddButton'
import Select from 'components/forms/Select'
import { LayoutItemCard } from 'components/graphs/LayoutItemCard'

import { useEventCallback } from 'shared/hooks/useEventCallback'
import { Graph } from 'shared/models/Graphs'

import { AddGraphForm } from './AddGraphForm'
import { AnalysisStatisticsWizard } from './AnalysisStatisticsWizard'

type AddLayoutItemProps = {
  onAddChart: (chart: Graph) => void
  onAddStatistics: (statistics: Analysis.Statistics) => void
}

type LayoutItemType = 'chart' | 'statistics'

export const AddLayoutItem: FC<AddLayoutItemProps> = ({
  onAddChart,
  onAddStatistics,
}) => {
  const [shouldDisplayForm, setShouldDisplayForm] = useState(false)
  const [itemType, setItemType] = useState<LayoutItemType>('chart')

  const handleOpen = useEventCallback(() => setShouldDisplayForm(true))

  const handleClose = useEventCallback(() => setShouldDisplayForm(false))

  const handleAddChart = useEventCallback((chart: Graph) => {
    onAddChart(chart)
    setShouldDisplayForm(false)
  })

  const handleCloseStatisticsWizard = useEventCallback(() => {
    setItemType('chart')
  })

  const handleAddStatistics = useEventCallback(
    (configuration: Analysis.Statistics) => {
      setItemType('chart')
      setShouldDisplayForm(false)
      onAddStatistics(configuration)
    },
  )

  if (!shouldDisplayForm) {
    return (
      <AddLayoutItemRoot>
        <AddButton onClick={handleOpen} />
      </AddLayoutItemRoot>
    )
  }

  return (
    <AddLayoutItemRoot>
      <FormContainer>
        <TypeSelector>
          <span>Add a new</span>
          <StyledSelect
            value={itemType}
            options={[
              {
                value: 'chart',
                label: 'chart',
              },
              {
                value: 'statistics',
                label: 'statistics',
              },
            ]}
            onChange={event =>
              setItemType(event.target.value as LayoutItemType)
            }
          />
        </TypeSelector>

        {itemType === 'chart' && (
          <AddGraphForm onAdd={handleAddChart} onCancel={handleClose} />
        )}
        {itemType === 'statistics' && (
          <AnalysisStatisticsWizard
            onClose={handleCloseStatisticsWizard}
            onApply={handleAddStatistics}
          />
        )}
      </FormContainer>
    </AddLayoutItemRoot>
  )
}

const AddLayoutItemRoot = styled(LayoutItemCard)`
  place-items: center;
`

const FormContainer = styled.div`
  display: grid;
  grid-template-rows: auto 1fr;
  width: 100%;
  height: 100%;
`

const TypeSelector = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;

  span {
    font-family: ${props => props.theme.font.style.bold};
    font-size: ${props => props.theme.font.size.regular}px;
  }
`

const StyledSelect = styled(Select)`
  width: 130px;
  > div {
    padding: 0;
  }
`
