import { createApi } from '@reduxjs/toolkit/query/react'

import { publicFetchBaseQuery } from './publicFetchBaseQuery'

export const PUBLIC_API_TAGS = [] as const

export type PublicApiTag = (typeof PUBLIC_API_TAGS)[number]

// all other public apis inject their endpoints to this api
// in order to split the code between files
export const publicApi = createApi({
  reducerPath: 'publicApi',
  baseQuery: publicFetchBaseQuery(),
  tagTypes: PUBLIC_API_TAGS,
  endpoints: () => ({}),
})
