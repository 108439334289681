import { produce } from 'immer'
import { ReactNode, useState } from 'react'
import { useLocation } from 'react-router-dom'
import { v4 as uuid } from 'uuid'

import { useEventCallback } from 'shared/hooks/useEventCallback'
import { useObserve } from 'shared/hooks/useObserve'

import { ModalContext, ModalContextValue } from './ModalContext'

type ModalContextProviderProps = {
  children: ReactNode
}

export const ModalContextProvider = ({
  children,
}: ModalContextProviderProps): JSX.Element => {
  const location = useLocation()

  const [modalById, setModalById] = useState<
    Record<
      string,
      {
        id: string
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        Component: React.ComponentType<any>
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        props: any
        options: {
          shouldCloseOnRouteChange?: boolean
        }
      }
    >
  >({})

  const showModal: ModalContextValue['showModal'] = useEventCallback(
    (component, props, options = { shouldCloseOnRouteChange: true }) => {
      const id = uuid()

      const closeModal = () => {
        setModalById(prev => {
          return produce(prev, draft => {
            delete draft[id]
          })
        })
      }

      setModalById(prev => {
        return {
          ...prev,
          [id]: {
            id,
            Component: component,
            props: {
              ...props,
              onClose: closeModal,
            },
            options,
          },
        }
      })
    },
  )

  useObserve(location.pathname, (_, previousPath) => {
    if (previousPath !== undefined) {
      Object.values(modalById).forEach(modal => {
        if (modal.options.shouldCloseOnRouteChange) {
          modal.props.onClose()
        }
      })
    }
  })

  return (
    <ModalContext.Provider
      value={{
        showModal,
      }}
    >
      {children}
      {Object.values(modalById).map(modal => {
        return <modal.Component key={modal.id} {...modal.props} />
      })}
    </ModalContext.Provider>
  )
}
