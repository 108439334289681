import {
  autoUpdate,
  useDismiss,
  useFloating,
  useInteractions,
} from '@floating-ui/react'
import { FC, useState } from 'react'

import { ListMenu } from 'components/list-menu/ListMenu'
import { ListMenuItem } from 'components/list-menu/ListeMenuItem'
import { DEFAULT_USE_FLOATING_PROPS } from 'components/tooltip'

import { Pagination } from './Pagination'
import { Table, TableProps } from './Table'

interface TableWithPaginationProps extends TableProps {
  page: number
  pageCount: number
  className?: string
  onPageChange: (event: object, page: number) => void
  onToggleSelectAllEntriesFromCurrentPage?: () => void
}

export const TableWithPagination: FC<TableWithPaginationProps> = ({
  page,
  pageCount,
  className,
  onPageChange,
  onToggleSelectAll,
  onToggleSelectAllEntriesFromCurrentPage,
  ...props
}) => {
  const [isOpen, setIsOpen] = useState(false)

  const { floatingStyles, refs, context } = useFloating({
    ...DEFAULT_USE_FLOATING_PROPS,
    open: isOpen,
    onOpenChange: setIsOpen,
    placement: 'bottom',
    whileElementsMounted: autoUpdate,
  })
  const { getReferenceProps, getFloatingProps } = useInteractions([
    useDismiss(context, { escapeKey: true }),
  ])

  const handleSelectAll = () => {
    onToggleSelectAll?.()
    setIsOpen(false)
  }

  const handleSelectCurrentPage = () => {
    onToggleSelectAllEntriesFromCurrentPage?.()
    setIsOpen(false)
  }

  return (
    <>
      <Table
        className={className}
        selectAllProps={{ ref: refs.setReference, ...getReferenceProps() }}
        onToggleSelectAll={
          onToggleSelectAll || onToggleSelectAllEntriesFromCurrentPage
            ? () => setIsOpen(!isOpen)
            : undefined
        }
        {...props}
      />
      <Pagination page={page} count={pageCount} onChange={onPageChange} />
      {isOpen && (
        <ListMenu
          ref={refs.setFloating}
          style={floatingStyles}
          {...getFloatingProps()}
        >
          <ListMenuItem onClick={handleSelectAll}>
            Select all entries
          </ListMenuItem>
          <ListMenuItem onClick={handleSelectCurrentPage}>
            Select current page
          </ListMenuItem>
        </ListMenu>
      )}
    </>
  )
}
