import Highcharts from 'highcharts'

import { createAnalysisMetaCleanChartBaseOptions } from 'components/graphs/AnalysisMetaCleanChartBaseOptions'

import { Graph } from 'shared/models/Graphs'
import { RootState } from 'shared/store'
import { analysisWorker } from 'shared/worker'

type RenderMetaCleanChartProps = {
  state: RootState
  chart: Graph
  width: number
  height: number
  shouldShowAxisLabels?: boolean
}

type RenderMetaCleanChartResult = {
  chartId: string
  svg: string
  title: string
  xAxis: string
  yAxis: string
  width: number
  height: number
}

export const renderMetaCleanChart = async ({
  chart,
  width,
  height,
  shouldShowAxisLabels,
}: RenderMetaCleanChartProps): Promise<RenderMetaCleanChartResult> => {
  if (!chart.y_axis) {
    throw new Error('Chart does not have a y axis')
  }
  const { series, scale } = await analysisWorker.computeMetaCleanSeries()

  const AnalysisMetaCleanChartBaseOptions =
    createAnalysisMetaCleanChartBaseOptions(series)

  return {
    chartId: chart.id,
    svg: new Highcharts.Chart(document.createElement('div'), {
      ...AnalysisMetaCleanChartBaseOptions,
      chart: {
        ...AnalysisMetaCleanChartBaseOptions.chart,
        height,
        width,
      },
      series,
      xAxis: {
        ...AnalysisMetaCleanChartBaseOptions.xAxis,
        min: chart.zoom?.x_min ?? scale.xAxis.min,
        max: chart.zoom?.x_max ?? scale.xAxis.max,
        title: {
          ...AnalysisMetaCleanChartBaseOptions.xAxis.title,
          text: shouldShowAxisLabels ? chart.x_axis : undefined,
        },
        labels: {
          style: {
            fontFamily: 'Arial',
          },
        },
      },
      yAxis: {
        ...AnalysisMetaCleanChartBaseOptions.yAxis,
        min: chart.zoom?.y_min ?? scale.yAxis?.min,
        max: chart.zoom?.y_max ?? scale.yAxis?.max,
        title: {
          ...AnalysisMetaCleanChartBaseOptions.yAxis.title,
          text: shouldShowAxisLabels ? chart.y_axis : undefined,
        },
        labels: {
          style: {
            fontFamily: 'Arial',
          },
        },
      },
      legend: {
        ...AnalysisMetaCleanChartBaseOptions.legend,
        itemStyle: {
          ...AnalysisMetaCleanChartBaseOptions.legend.itemStyle,
          fontFamily: 'Arial',
        },
      },
    }).getSVG(),
    title: chart.name,
    xAxis: chart.x_axis,
    yAxis: chart.y_axis,
    width,
    height,
  }
}
