export function shortenClusterLabel(label: string): string {
  if (label.length <= 10) {
    return label
  } else {
    return (
      label.substring(0, 4) +
      '...' +
      label.substring(label.length - 4, label.length)
    )
  }
}

/**
 * Returns the step value depending of the current gate coordinate value.
 * Current scale : 1/100.
 *
 * Examples :
 * - 53000 => 100
 * - 52 => 0.1
 * - 0.45 => 0.001
 * @param value The value of the coordinates of the gate.
 * @returns The step value for the number input of the gate.
 */
export const getGateStepValue = (value: number): number => {
  const [, exponential] = value.toExponential().split('e')
  return Number(`1e${exponential}`) / 100
}
