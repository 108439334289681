import styled from 'styled-components'

import { Icon } from 'assets/images/icons/Icon'

import { Button } from 'components/Button'

import { useSaveMetaAnalysisMutation } from 'shared/api/meta-analysis.api'

export const SaveMetaAnalysisButton = (): JSX.Element => {
  const [triggerSaveMetaAnalysis, saveMetaAnalysisResult] =
    useSaveMetaAnalysisMutation()

  const handleSave = () => {
    triggerSaveMetaAnalysis()
  }

  return (
    <StyledButton
      onClick={handleSave}
      loading={saveMetaAnalysisResult.isLoading}
      startIcon={<Icon name="save" />}
    >
      Save
    </StyledButton>
  )
}

const StyledButton = styled(Button)`
  font-size: ${props => props.theme.font.size.smallest}px;
  padding: 0 12px;
  background: ${props => props.theme.colors.warning};
  transition: opacity 300ms ease;
  &:hover {
    background: ${props => props.theme.colors.warning};
    opacity: 0.7;
  }
`
