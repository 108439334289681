import { FC } from 'react'

import {
  useCreatePipelineMutation,
  useDeleteBrickMutation,
  useGetWorkflowQuery,
  useUpdateWorkflowMutation,
} from 'shared/api/workflows.api'
import { useDialog } from 'shared/contexts/DialogContext'
import { useDeleteWorkflow } from 'shared/hooks/useDeleteWorkflow'

import { WorkflowView } from './WorkflowView'

type WorkflowProps = {
  workflowId: string
}

export const Workflow: FC<WorkflowProps> = ({ workflowId }) => {
  const { showConfirmationDialog } = useDialog()
  const deleteWorkflow = useDeleteWorkflow()
  const getWorkflowQueryState = useGetWorkflowQuery(workflowId)
  const [triggerUpdateWorkflowMutation] = useUpdateWorkflowMutation()
  const [triggerDeleteBrickMutation] = useDeleteBrickMutation()
  const [triggerCreatePipelineMutation] = useCreatePipelineMutation()

  const updateWorkflow = ({
    name,
    description,
  }: {
    name?: string
    description?: string
  }) => {
    triggerUpdateWorkflowMutation({ id: workflowId, name, description })
  }

  const deleteBrick = (brickId: string, isLastRootBrick: boolean) => {
    if (isLastRootBrick) {
      showConfirmationDialog({
        title: 'Delete brick',
        message:
          'The brick you want to delete is the last root brick, so the entire' +
          ' workflow will be removed. Are you sure you want to proceed?',
        onConfirm: () =>
          deleteWorkflow({
            id: workflowId,
            name: getWorkflowQueryState.data?.name,
            shouldShowConfirmation: false,
          }),
      })
    } else {
      triggerDeleteBrickMutation(brickId)
    }
  }

  const createPipeline = () => triggerCreatePipelineMutation(workflowId)

  return (
    <WorkflowView
      workflow={getWorkflowQueryState.data}
      shouldShowDebugId
      onUpdate={updateWorkflow}
      onDeleteBrick={deleteBrick}
      onCreatePipeline={createPipeline}
      onDelete={() =>
        deleteWorkflow({
          id: workflowId,
          name: getWorkflowQueryState.data?.name,
        })
      }
    />
  )
}
