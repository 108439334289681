import Highcharts from 'highcharts'
import { FC, useEffect, useMemo, useState } from 'react'

import { ClusterActionsContextMenu } from 'pages/analysis/ClusterActionsContextMenu'
import { highlightCluster } from 'pages/analysis/store/analysis.slice'
import {
  Cluster,
  selectClusterById,
  selectSunburstSeriesComputationParams,
} from 'pages/analysis/store/selectors'

import { useClickPrevention } from 'shared/hooks/useClickPrevention'
import { useEventCallback } from 'shared/hooks/useEventCallback'
import { useLoadSeries } from 'shared/hooks/useLoadSeries'
import { useAppDispatch, useAppSelector } from 'shared/store'
import { analysisWorker } from 'shared/worker'

import { AnalysisSunburstBaseOptions } from './AnalysisSunburstBaseOptions'
import { Chart } from './Chart'

type SunburstProps = Readonly<{
  width: number
  height: number
  allowTraversingTree: boolean
  onClusterClick: (id: string) => void
  onHoveredClusterChange: (
    cluster: Cluster | undefined,
    point: [number, number],
  ) => void
}>

export const Sunburst: FC<SunburstProps> = ({
  width,
  height,
  allowTraversingTree,
  onClusterClick,
  onHoveredClusterChange,
}) => {
  const dispatch = useAppDispatch()

  const seriesComputationsParams = useAppSelector(
    selectSunburstSeriesComputationParams,
  )
  const clusterById = useAppSelector(selectClusterById)

  const [contextMenuId, setContextMenuId] = useState<string>()
  const [menuOrigin, setMenuOrigin] = useState<{ x: number; y: number }>()
  const [chart, setChart] = useState<Highcharts.Chart>()

  const series = useLoadSeries(
    { ...seriesComputationsParams, allowTraversingTree },
    useMemo(
      () => analysisWorker.computeSunburstSeries.bind(analysisWorker),
      [],
    ),
  )

  const openContextMenu = useEventCallback(
    (event: MouseEvent, p: Highcharts.Point) => {
      event.preventDefault()
      const { clientX, clientY } = event
      setContextMenuId(p.id)
      setMenuOrigin({ x: clientX, y: clientY })
    },
  )

  const openTooltip = useEventCallback(
    (
      event: MouseEvent,
      point: Highcharts.Point & Partial<Pick<Cluster, 'parent_rate'>>,
    ) => {
      event.preventDefault()
      const { clientX: x, clientY: y } = event
      const cluster = clusterById[point.id]

      onHoveredClusterChange(cluster, [x, y])
    },
  )

  const closeTooltip = useEventCallback(() => {
    onHoveredClusterChange(undefined, [0, 0])
  })

  const onDoubleClick = useEventCallback((p?: Highcharts.Point) => {
    if (p?.id) {
      dispatch(highlightCluster({ clusterId: p.id }))
    }
  })

  const [handleClick, handleDoubleClick] = useClickPrevention({
    onClick: onClusterClick,
    onDoubleClick,
  })

  const handleRender = useEventCallback(() => console.count('sunburst'))

  const options: Highcharts.Options = useMemo(
    () => ({
      // Important!: every function used in the options should be wrapped in useEventCallback
      // to avoid unnecessary re-renders
      ...AnalysisSunburstBaseOptions,
      chart: {
        ...AnalysisSunburstBaseOptions.chart,
        height: Math.min(width, height),
        width: Math.min(width, height),
        events: {
          render:
            import.meta.env.VITE_DEV_DEBUG_CHART_PERFORMANCE === 'true'
              ? handleRender
              : undefined,
        },
      },
      series,
    }),
    [handleRender, height, series, width],
  )

  // Effects
  useEffect(() => {
    chart?.series?.forEach(s => {
      s.points.forEach((p?: Highcharts.Point) => {
        if (!p || !p.graphic) return
        const elm = p.graphic.element
        elm.oncontextmenu = e => openContextMenu(e, p)
        elm.ondblclick = () => handleDoubleClick(p)
        elm.onclick = !allowTraversingTree
          ? () => handleClick(p.id)
          : () => undefined
        elm.onmouseenter = e => openTooltip(e, p)
        elm.onmouseleave = () => closeTooltip()
      })
    })
  }, [
    chart,
    openContextMenu,
    openTooltip,
    closeTooltip,
    allowTraversingTree,
    handleClick,
    handleDoubleClick,
  ])

  return (
    <div>
      <Chart
        highcharts={Highcharts}
        options={options}
        callback={setChart}
        data-cy="sunburst-chart"
      />
      {contextMenuId && menuOrigin && (
        <ClusterActionsContextMenu
          clusterId={contextMenuId}
          menuOrigin={menuOrigin}
          disablePortal={false}
          onClose={() => {
            setContextMenuId(undefined)
            setMenuOrigin(undefined)
          }}
          source="sunburst"
          actions={{
            changeClusterColor: true,
            changeClusterName: true,
            expandCluster: true,
            expandVisibleClusters: true,
            mergeCluster: true,
            mergeVisibleClusters: true,
            highlightCluster: true,
            shouldChangeDotSize: true,
            toggleClusterVisibilityAll: true,
          }}
        />
      )}
    </div>
  )
}
