import { Box } from '@material-ui/core'
import React from 'react'
import styled from 'styled-components'
import zxcvbn from 'zxcvbn'

type PasswordStrengthMeterProps = {
  password: string
}

const PasswordStrengthMeter: React.FC<PasswordStrengthMeterProps> = ({
  password,
}) => {
  const passStrength = zxcvbn(password)

  const createPasswordLabel = (score: number) => {
    switch (score) {
      default:
      case 0:
      case 1:
        return 'Weak' as const
      case 2:
        return 'Fair' as const
      case 3:
        return 'Good' as const
      case 4:
        return 'Strong' as const
    }
  }
  const level = `strength${createPasswordLabel(passStrength.score)}`

  return (
    <Box marginBottom={3}>
      <Progress className={level} value={passStrength.score} max="4" />
      <Label>
        <strong>Password strength</strong> :{' '}
        {createPasswordLabel(passStrength.score)}
      </Label>
    </Box>
  )
}

const Progress = styled.progress`
  appearance: none;
  width: 100%;
  height: 8px;
  &::-webkit-progress-bar {
    background: ${props => props.theme.colors.greyscale[10]};
    border-radius: 3px;
  }
  &::-webkit-progress-value {
    border-radius: 2px;
    background-size:
      35px 20px,
      100% 100%,
      100% 100%;
  }
  &.strengthWeak::-webkit-progress-value {
    background: ${props => props.theme.colors.error};
  }
  &.strengthFair::-webkit-progress-value {
    background: ${props => props.theme.colors.warning};
  }
  &.strengthGood::-webkit-progress-value {
    background: ${props => props.theme.colors.info};
  }
  &.strengthStrong::-webkit-progress-value {
    background: ${props => props.theme.colors.success};
  }
`

const Label = styled.label`
  font-size: ${props => props.theme.font.size.smallest}px;
  display: block;
  margin-top: ${props => props.theme.spacing(1)}px;
`

export default PasswordStrengthMeter
