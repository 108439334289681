import { IconButton } from '@material-ui/core'
import styled, { css } from 'styled-components'

export const LayoutItemCardHeaderIconButton = styled(IconButton)<{
  $isActive?: boolean
}>`
  color: ${props => props.theme.colors.primaryDark['100']};
  width: 24px;
  height: 24px;
  padding: 0;

  svg {
    width: 20px;
    height: 20px;
  }

  ${props =>
    props.$isActive &&
    css`
      color: white;
      background: ${props.theme.colors.primaryDark[100]};
      border-radius: 4px;

      &:hover {
        background: ${props.theme.colors.primaryDark[100]};
        opacity: 0.8;
      }
    `}

  transition: border-radius 0.2s;
`
