export const MetaAnalysisFrequencyChartBaseOptions = {
  chart: {
    type: 'variwide',
    animation: false,
  },
  xAxis: {
    type: 'category',
    labels: {
      style: {
        display: 'flex',
        justifyContent: 'center',
      },
    },
  } as Highcharts.XAxisOptions,
  plotOptions: {
    series: {
      animation: false,
      stacking: 'normal',
      dataLabels: {
        enabled: false,
      },
    },
  } as Highcharts.PlotOptions,
  tooltip: {
    enabled: true,
    useHTML: true,
  },
  title: {
    text: '',
  },
  caption: {
    text: 'Column widths are proportional to % of total frequency between all files',
  },
}
