import styled from 'styled-components'

export const DotLabel = styled.div.attrs<{
  $color: string
  $isHighlighted?: boolean
}>(props => ({
  style: {
    backgroundColor: props.$color,
    outline: props.$isHighlighted
      ? '3px solid black'
      : props.$color === 'white'
      ? `1px solid ${props.theme.colors.greyscale[20]}`
      : 'none',
  },
}))<{ $color: string; $isHighlighted?: boolean }>`
  min-width: 12px;
  height: 12px;
  margin-left: 4px;
  border-radius: 50%;
  border: 2px solid white;
  box-sizing: content-box;
`
