import { FC, ReactNode } from 'react'
import styled from 'styled-components'

import { Icon as IconComponent, IconNames } from 'assets/images/icons/Icon'

type EmptyTableInfoProps = {
  text: string
  className?: string
  Icon?: ReactNode
  iconName?: IconNames
  children?: ReactNode
}

export const EmptyTableInfo: FC<EmptyTableInfoProps> = ({
  text,
  className,
  Icon,
  iconName,
  children,
}) => {
  return (
    <EmptyTableInfoRoot className={className}>
      {Icon ? Icon : iconName ? <IconComponent name={iconName} /> : null}
      <EmptyTableInfoText>{text}</EmptyTableInfoText>
      <div>{children}</div>
    </EmptyTableInfoRoot>
  )
}

const EmptyTableInfoRoot = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: ${props => props.theme.spacing(4)}px;
  height: 100%;
`

const EmptyTableInfoText = styled.p`
  font-size: ${props => props.theme.font.size.smallest}px;
  font-family: ${props => props.theme.font.style.light};
  color: ${props => props.theme.colors.primaryDark[50]};
`
