import { Analysis } from 'shared/models/AnalysisModels'
import { Signature, SignatureType } from 'shared/models/Signature'
import { toQueryString } from 'shared/utils/utils'

import { privateApi } from './private.api'
import { encodeTagParameters } from './utils'

export const signaturesApi = privateApi.injectEndpoints({
  endpoints: builder => ({
    getSignatures: builder.query<Signature[], string>({
      query: analysisId =>
        `/signatures/?${toQueryString({
          analysis_id: analysisId,
        })}`,
      providesTags: [
        { type: 'Signature', id: encodeTagParameters({ id: 'list' }) },
      ],
    }),
    createSignature: builder.mutation<
      CreateSignatureResponse,
      CreateSignaturePayload
    >({
      query: payload => ({
        url: '/signatures/',
        method: 'POST',
        body: {
          analysis_id: payload.analysisId,
          type: payload.type,
        },
      }),
    }),
    cancelSignature: builder.mutation<
      CancelSignatureResponse,
      CancelSignaturePayload
    >({
      query: payload => ({
        url: `/signatures/${payload.signatureId}/cancel/`,
        method: 'PUT',
        body: {
          analysis_id: payload.analysisId,
        },
      }),
    }),
  }),
})

export const {
  useGetSignaturesQuery,
  useCreateSignatureMutation,
  useCancelSignatureMutation,
} = signaturesApi

type CreateSignaturePayload = {
  analysisId: string
  type: SignatureType
}

type CancelSignaturePayload = {
  analysisId: string
  signatureId: string
}

type CreateSignatureResponse = {
  analysis: Analysis
}

type CancelSignatureResponse = {
  analysis: Analysis
}
