import React, { useState } from 'react'
import styled from 'styled-components'

import { Button } from 'components/Button'
import Input from 'components/forms/Input'
import Select from 'components/forms/Select'

import { useAppSelector } from 'shared/store'

import { selectMetaAnalysisGroupNames } from './store/selectors'

type MetaAnalysisVolcanoPlotOptionsProps = {
  mode: 'edit' | 'create'
  initialSelectedGroups: [string, string]
  initialXThreshold: number
  initialYThreshold: number
  onCancel?: () => void
  onFinish: (
    groups: [string, string],
    xThreshold: number,
    yThreshold: number,
  ) => void
  className?: string
}

export const MetaAnalysisVolcanoPlotOptions = ({
  mode,
  initialSelectedGroups,
  initialXThreshold,
  initialYThreshold,
  onCancel,
  onFinish,
  className,
}: MetaAnalysisVolcanoPlotOptionsProps): JSX.Element => {
  const groupNames = useAppSelector(selectMetaAnalysisGroupNames)

  const [selectedGroups, setSelectedGroups] = useState<[string, string]>(
    initialSelectedGroups,
  )
  const [xThreshold, setXThreshold] = useState(initialXThreshold)
  const [yThreshold, setYThreshold] = useState(initialYThreshold)

  return (
    <MetaAnalysisVolcanoPlotOptionsRoot className={className}>
      <Options>
        <Header>Groups for comparison</Header>
        <RowInputs>
          <StyledSelect
            label="Group 1"
            value={selectedGroups[0]}
            options={groupNames.map(name => ({
              value: name,
              label: name,
              disabled: selectedGroups.includes(name),
            }))}
            onChange={event =>
              setSelectedGroups([
                event.target.value as string,
                selectedGroups[1],
              ])
            }
          />
          <StyledSelect
            label="Group 2"
            value={selectedGroups[1]}
            options={groupNames.map(name => ({
              value: name,
              label: name,
              disabled: selectedGroups.includes(name),
            }))}
            onChange={event =>
              setSelectedGroups([
                selectedGroups[0],
                event.target.value as string,
              ])
            }
          />
        </RowInputs>
        <Header>Thresholds</Header>
        <RowInputs>
          <StyledInput
            type="number"
            value={xThreshold}
            onChange={event => setXThreshold(Number(event.target.value))}
            label="X threshold"
            min="0.01"
            max="1"
            step="0.01"
          />
          <StyledInput
            type="number"
            value={yThreshold}
            onChange={event => setYThreshold(Number(event.target.value))}
            label="Y threshold"
            min="0.01"
            step="0.01"
          />
        </RowInputs>
      </Options>
      <Buttons>
        <StyledButton
          onClick={() => onFinish(selectedGroups, xThreshold, yThreshold)}
        >
          {mode === 'edit' ? 'Apply' : 'Create'}
        </StyledButton>
        <StyledButton onClick={onCancel}>Cancel</StyledButton>
      </Buttons>
    </MetaAnalysisVolcanoPlotOptionsRoot>
  )
}

const MetaAnalysisVolcanoPlotOptionsRoot = styled.div`
  background: ${props => props.theme.colors.white};
  width: 100%;
  height: 100%;
  overflow: hidden;
  display: grid;
  grid-template-rows: 1fr auto;
`

const Options = styled.div`
  overflow-y: auto;
  overflow-x: hidden;
  padding: 8px;
`

const StyledSelect = styled(Select)`
  flex: 1;
`

const StyledInput = styled(Input)`
  flex: 1;
  min-width: 100px;
`

const Buttons = styled.div`
  border-top: 1px solid ${props => props.theme.colors.primaryDark[20]};
  padding: 8px;
  display: flex;
  flex-direction: row-reverse;
`

const StyledButton = styled(Button)`
  height: 16px;
  font-size: 12px;

  :first-of-type {
    margin-left: 8px;
  }
`

const Header = styled.p`
  font-family: ${props => props.theme.font.style.bold};
`

const RowInputs = styled.div`
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  gap: ${props => props.theme.spacing(2)}px;
  width: 100%;
  margin-top: ${props => props.theme.spacing(2)}px;
`
