import { useState } from 'react'
import styled from 'styled-components'

import { CircularProgress } from 'components/CircularProgress'
import { Header } from 'components/Header'
import { Pagination } from 'components/table/Pagination'

import { useGetWorkflowsQuery } from 'shared/api/workflows.api'

import { SectionHeader } from './SectionHeader'
import { Workflow } from './Workflow'

type WorkflowsProps = {
  projectId: string
}

export const Workflows: React.FC<WorkflowsProps> = ({ projectId }) => {
  const [page, setPage] = useState(1)

  const getWorkflowsQueryState = useGetWorkflowsQuery({
    projectId,
    page,
  })

  return (
    <WorkflowsRoot>
      <Sticky>
        <Header />
        <SectionHeader title="Workflows" />
      </Sticky>
      <Main>
        {getWorkflowsQueryState.isLoading ? (
          <CircularProgress />
        ) : (
          <>
            <WorkflowList>
              {getWorkflowsQueryState.data?.results.map(workflow => (
                <Workflow key={workflow.id} workflowId={workflow.id} />
              ))}
            </WorkflowList>
            <Pagination
              page={page}
              count={getWorkflowsQueryState.data?.page_count}
              onChange={(_event, page) => setPage(page)}
            />
          </>
        )}
      </Main>
    </WorkflowsRoot>
  )
}

const WorkflowsRoot = styled.div`
  height: 100vh;
  overflow-y: auto;
`

const Main = styled.main`
  padding: 0 100px 60px;
`

const WorkflowList = styled.div`
  display: flex;
  flex-direction: column;
  gap: 32px;
`

const Sticky = styled.div`
  position: sticky;
  top: 0;
  background-color: #f9f9f9;
  z-index: 2;
  padding: 60px 100px 0;
`
