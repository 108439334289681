import React from 'react'
import { RGBColor } from 'react-color'

import { SelectColorContextMenu } from 'components/SelectColorContextMenu'

import { useAppDispatch, useAppSelector } from 'shared/store'
import { showNotification } from 'shared/store/notification.slice'
import { validateClusterColor } from 'shared/utils/clusters.utils'

import { changeClusterColor } from './store/analysis.slice'
import { selectClusters } from './store/selectors'

type ClusterColorContextMenuProps = {
  clusterId: string
  menuOrigin: {
    x: number
    y: number
  }
  initialColor?: RGBColor
  source: 'graph' | 'sunburst' | 'heatmap'
  closeColorMenu: () => void
  onColorValidate?: (newColor?: RGBColor | null) => void
}

export const ClusterColorContextMenu: React.FC<
  ClusterColorContextMenuProps
> = props => {
  const {
    clusterId,
    menuOrigin,
    initialColor,
    source,
    closeColorMenu,
    onColorValidate,
  } = props

  const dispatch = useAppDispatch()
  const clusters = useAppSelector(selectClusters)

  const clusterChangeColorHandler = (
    clusterId: string,
    color: RGBColor | null,
  ) => {
    if (onColorValidate) {
      onColorValidate()
    }

    if (!color) {
      closeColorMenu()
      return
    }
    const { r, g, b } = color
    const colorString = `rgba(${r}, ${g}, ${b}, 1)`

    const error = validateClusterColor(colorString, clusters, clusterId)

    if (error) {
      dispatch(
        showNotification({
          type: 'error',
          description: error,
        }),
      )
    } else {
      dispatch(
        changeClusterColor({
          clusterId,
          color: `rgb(${r}, ${g}, ${b})`,
          source,
        }),
      )
      closeColorMenu()
    }
  }

  return (
    <SelectColorContextMenu
      menuOrigin={menuOrigin}
      initialColor={initialColor}
      onColorValidate={newColor =>
        clusterChangeColorHandler(clusterId, newColor)
      }
      onCancel={closeColorMenu}
      onClickOutside={closeColorMenu}
    />
  )
}
