import { Grid, Tooltip } from '@material-ui/core'
import React from 'react'
import styled from 'styled-components'

import { Button } from 'components/Button'

import {
  useDeleteInvitationMutation,
  useSentInvitationMutation,
} from 'shared/api/organization.api'
import { OrganizationInvitation } from 'shared/models/OrganizationModels'
import { formatDateTime } from 'shared/utils/utils'

export const InvitationRow = ({
  id,
  email,
  created,
  sendDate,
  status,
}: {
  id: string
  email: string
  created: string
  sendDate?: string
  status: OrganizationInvitation['status']
}): JSX.Element => {
  const [resendInvitation] = useSentInvitationMutation()
  const [deleteInvitation] = useDeleteInvitationMutation()

  return (
    <RowWrapper key={id} container spacing={0}>
      <Grid item xs={4}>
        <StyledText title={email}>{email}</StyledText>
      </Grid>
      <Grid item xs={2}>
        <StyledText title={formatDateTime(new Date(created))}>
          {formatDateTime(new Date(created))}
        </StyledText>
      </Grid>
      <Grid item xs={2}>
        <StyledText
          title={sendDate ? formatDateTime(new Date(sendDate)) : 'No data'}
        >
          {sendDate ? formatDateTime(new Date(sendDate)) : 'No data'}
        </StyledText>
      </Grid>
      <Grid item xs={1}>
        <StatusText $status={status} title={status}>
          {status}
        </StatusText>
      </Grid>

      <Grid item xs={3}>
        <ActionsContainer>
          <InvitationActionBtn
            title="Remove"
            onClick={() => deleteInvitation({ id })}
          >
            Remove
          </InvitationActionBtn>
          <Tooltip
            title={
              status === 'accepted'
                ? 'You cannot resend an accepted invitation'
                : ''
            }
          >
            <div>
              <InvitationActionBtn
                disabled={status === 'accepted'}
                title="Resend"
                onClick={() => resendInvitation({ email })}
              >
                Resend
              </InvitationActionBtn>
            </div>
          </Tooltip>
        </ActionsContainer>
      </Grid>
    </RowWrapper>
  )
}

const InvitationActionBtn = styled(Button)`
  border-radius: 4px;
  font-size: 12px;
  padding: 8px 24px;
  white-space: nowrap;
  height: 34px;
  &:hover {
    background: ${props => props.theme.colors.primary};
  }

  &:not(&:first-child) {
    margin-left: 24px;
  }
`

const RowWrapper = styled(Grid)`
  color: ${props => props.theme.colors.primaryDark[100]};
  align-items: center;
  border-bottom: 1px solid ${props => props.theme.colors.primaryDark[20]};
  padding: 0 10px 10px;
  margin: 10px 0;
`

const StyledText = styled.p`
  overflow: hidden;
  text-overflow: ellipsis;
`

const StatusText = styled(StyledText)<{
  $status: OrganizationInvitation['status']
}>`
  color: ${props =>
    props.$status === 'accepted'
      ? props.theme.colors.success
      : props.$status === 'expired'
      ? props.theme.colors.error
      : 'default'};
`

const ActionsContainer = styled.div`
  display: flex;
  gap: ${props => props.theme.spacing(1)}px;
`
