import { Dialog } from '@material-ui/core'
import { ReactNode, useState } from 'react'

import { SimpleConfirmationDialog } from 'components/SimpleConfirmationDialog'
import { SimpleOptionDialog } from 'components/SimpleOptionDialog'

import { useEventCallback } from 'shared/hooks/useEventCallback'

import {
  DialogContext,
  DialogContextValue,
  DialogFactory,
} from './DialogContext'

type DialogContextProviderProps = {
  children: ReactNode
}

export const DialogContextProvider = ({
  children,
}: DialogContextProviderProps): JSX.Element => {
  const [dialogFactory, setDialogFactory] = useState<DialogFactory>()

  const showDialog: DialogContextValue['showDialog'] = useEventCallback(
    dialogFactory => {
      setDialogFactory(() => dialogFactory)
    },
  )

  const showConfirmationDialog: DialogContextValue['showConfirmationDialog'] =
    useEventCallback(({ title, message, onConfirm }) => {
      setDialogFactory(() => closeDialog => (
        <SimpleConfirmationDialog
          title={title}
          message={message}
          onConfirm={onConfirm}
          onClose={closeDialog}
        />
      ))
    })

  const ensureConditionIsMetBeforeAction: DialogContextValue['ensureConditionIsMetBeforeAction'] =
    useEventCallback(
      ({ condition, performAction, performFix, title, message }) => {
        if (condition) {
          performAction()
        } else {
          showConfirmationDialog({
            title,
            message,
            onConfirm: async () => {
              if (await performFix()) {
                performAction()
              }
            },
          })
        }
      },
    )

  const showOptionDialog: DialogContextValue['showOptionDialog'] =
    useEventCallback(({ title, message, options }) => {
      setDialogFactory(() => closeDialog => (
        <SimpleOptionDialog
          title={title}
          message={message}
          options={options}
          onClose={closeDialog}
        />
      ))
    })

  return (
    <DialogContext.Provider
      value={{
        showDialog,
        showConfirmationDialog,
        ensureConditionIsMetBeforeAction,
        showOptionDialog,
      }}
    >
      {children}
      <Dialog open={!!dialogFactory}>
        {dialogFactory?.(() => setDialogFactory(undefined))}
      </Dialog>
    </DialogContext.Provider>
  )
}
