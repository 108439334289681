import {
  CssBaseline,
  ThemeProvider as MuiThemeProvider,
} from '@material-ui/core'
import { intersection } from 'lodash'
import { ReactNode } from 'react'
import styled, {
  ThemeProvider as StyledComponentsThemeProvider,
} from 'styled-components'

import { DownsamplingInfo } from 'components/DownsamplingInfo'

import { AnalysisStatisticsTable } from 'pages/analysis/AnalysisStatisticsTable'
import {
  selectActiveLeavesCount,
  selectAnalysis,
  selectAnalysisCompensatedFile,
  selectAnalysisFcsFile,
  selectAnalysisLassos,
  selectAnalysisProject,
  selectAnalysisStatistics,
  selectChannelDisplayNames,
  selectChannels,
  selectCharts,
  selectClusterById,
  selectClusterListClusters,
  selectCurrentDepth,
  selectMaxAvailableDepth,
  selectShownActiveLeafIds,
  selectSortedChartIds,
} from 'pages/analysis/store/selectors'
import { selectGatesById } from 'pages/analysis/store/selectors/gates.selectors'

import { signaturesApi } from 'shared/api/signatures.api'
import { ANALYSIS_LASSOS_HIERARCHY_GRAPH_ID } from 'shared/constants'
import { Signature, SignatureType } from 'shared/models/Signature'
import { RootState } from 'shared/store'

import { Theme } from 'Theme'

import { InlineSvg } from '../InlineSvg'
import { AnalysisPdfChartContainer } from './AnalysisPdfChartContainer'
import { AnalysisPdfClusterList } from './AnalysisPdfClusterList'
import { AnalysisPdfDepthSlider } from './AnalysisPdfDepthSlider'
import { AnalysisPdfPage } from './AnalysisPdfPage'
import { groupAnalysisPrintItems } from './groupAnalysisPrintItems'
import { renderAnalysisChart } from './renderAnalysisChart'
import { renderHeatmaps } from './renderHeatmap'
import { renderLassosHierarchyGraph } from './renderLassosHierarchyGraph'
import { renderSunburst } from './renderSunburst'

export type PrintSignatures = {
  [key in SignatureType]: Signature | undefined
}

type AnalysisPdfProps = {
  state: RootState
  selectedGraphicalElementIds: string[]
  theme: Theme
  exportHash: string
}

const HIERARCHY_CHART_WIDTH = 700
const HIERARCHY_CHART_HEIGHT = 700

export const AnalysisPdf = async ({
  state,
  selectedGraphicalElementIds,
  theme,
  exportHash,
}: AnalysisPdfProps): Promise<ReactNode> => {
  const analysis = selectAnalysis(state)
  const compensatedFile = selectAnalysisCompensatedFile(state)
  const fcsFile = selectAnalysisFcsFile(state)
  const project = selectAnalysisProject(state)
  const charts = selectCharts(state)
  const globallyShownActiveLeaves = selectShownActiveLeafIds(state)
  const depth = selectCurrentDepth(state)
  const maxDepth = selectMaxAvailableDepth(state)
  const signatures = unpackSignatures(
    signaturesApi.endpoints.getSignatures.select(analysis.id)(state).data,
  )
  const activeLeavesCount = selectActiveLeavesCount(state)
  const activeClusters = selectClusterListClusters(state)
  const sortedChartIds = selectSortedChartIds(state)
  const channels = selectChannels(state)
  const statisticsItems = selectAnalysisStatistics(state)
  const lassoById = selectAnalysisLassos(state)
  const gateById = selectGatesById(state)
  const clusterById = selectClusterById(state)
  const channelDisplayNameById = selectChannelDisplayNames(state)

  const {
    globalClusters,
    lastClustersPageChartIds,
    entirePageChartGroups,
    lastChartsPageChartIds,
    lastChartsPageHeatmapClustersCount,
    groupedStatisticsItems,
  } = groupAnalysisPrintItems({
    activeLeavesCount,
    activeClusters,
    sortedChartIds: intersection(sortedChartIds, selectedGraphicalElementIds),
    channels,
    statisticsItems: statisticsItems.filter(statistic =>
      selectedGraphicalElementIds.includes(statistic.id),
    ),
  })

  const sunburstSvg = await renderSunburst({
    state,
    width: HIERARCHY_CHART_WIDTH,
    height: HIERARCHY_CHART_HEIGHT,
  })

  const heatmapSvgs = await renderHeatmaps({
    state,
    theme,
    lastChartsPageHeatmapClustersCount,
  })
  const analysisChartSvgs = Object.fromEntries(
    (
      await Promise.all(
        charts.map(chart => renderAnalysisChart({ state, chart, theme })),
      )
    ).map(chart => [chart.chartId, chart]),
  )

  const lassosHierarchyGraphSvg = await renderLassosHierarchyGraph({
    state,
    theme,
  })

  const lastGlobalClustersPageNumber =
    1 +
    globalClusters.entirePageClusterGroups.length +
    (globalClusters.lastClustersPageClusters ? 1 : 0)

  const lastChartsPageNumber =
    lastGlobalClustersPageNumber +
    entirePageChartGroups.length +
    (lastChartsPageChartIds ? 1 : 0)

  const lastHeatMapPageNumber =
    lastChartsPageNumber +
    heatmapSvgs.length -
    (lastChartsPageHeatmapClustersCount > 0 ? 1 : 0)

  const totalPages =
    lastHeatMapPageNumber +
    groupedStatisticsItems.entirePageStatisticsGroups.length

  const renderChart = (chartId: string) => {
    if (chartId === ANALYSIS_LASSOS_HIERARCHY_GRAPH_ID) {
      return (
        <AnalysisPdfChartContainer key={chartId} title="Lassos hierarchy">
          <InlineSvg
            src={lassosHierarchyGraphSvg.svg}
            width={lassosHierarchyGraphSvg.width}
            height={lassosHierarchyGraphSvg.height}
          />
        </AnalysisPdfChartContainer>
      )
    }

    const chart = analysisChartSvgs[chartId]
    return (
      <AnalysisPdfChartContainer
        key={chartId}
        title={chart.title}
        xAxis={chart.xAxis}
        yAxis={chart.yAxis ?? 'Count'}
        channelDisplayNames={channelDisplayNameById}
      >
        {chart.svg ? (
          <InlineSvg
            src={chart.svg}
            width={chart.width}
            height={chart.height}
          />
        ) : (
          <StyledDownsamplingInfo />
        )}
      </AnalysisPdfChartContainer>
    )
  }

  return (
    <MuiThemeProvider theme={theme}>
      <StyledComponentsThemeProvider theme={theme}>
        <CssBaseline />
        <AnalysisPrintRoot className="analysis-print-root">
          <AnalysisPdfPage
            analysis={analysis}
            compensatedFile={compensatedFile}
            fcsFile={fcsFile}
            project={project}
            pageNumber={1}
            totalPages={totalPages}
            signatures={signatures}
          >
            <SecretHash>{exportHash}</SecretHash>
            <HierarchyContainer>
              <CenteredInlineSvg
                src={sunburstSvg}
                width={HIERARCHY_CHART_WIDTH}
                height={HIERARCHY_CHART_HEIGHT}
              />
              <ClustersCount>
                {globallyShownActiveLeaves.length} clusters
              </ClustersCount>
              <DepthSliderContainer>
                <AnalysisPdfDepthSlider value={depth} min={1} max={maxDepth} />
              </DepthSliderContainer>
            </HierarchyContainer>
            <AnalysisPdfClusterList
              clusters={globalClusters.firstPageClusters}
              selectedClusterIds={globallyShownActiveLeaves}
            />
          </AnalysisPdfPage>
          {globalClusters.entirePageClusterGroups.map((clusters, i) => (
            <AnalysisPdfPage
              analysis={analysis}
              compensatedFile={compensatedFile}
              fcsFile={fcsFile}
              project={project}
              pageNumber={2 + i}
              totalPages={totalPages}
              signatures={signatures}
            >
              <AnalysisPdfClusterList
                clusters={clusters}
                selectedClusterIds={globallyShownActiveLeaves}
              />
            </AnalysisPdfPage>
          ))}
          {globalClusters.lastClustersPageClusters && (
            <AnalysisPdfPage
              analysis={analysis}
              compensatedFile={compensatedFile}
              fcsFile={fcsFile}
              project={project}
              pageNumber={lastGlobalClustersPageNumber}
              totalPages={totalPages}
              signatures={signatures}
            >
              <LastClustersListPageContainer>
                <AnalysisPdfClusterList
                  clusters={globalClusters.lastClustersPageClusters}
                  selectedClusterIds={globallyShownActiveLeaves}
                />
                {lastClustersPageChartIds.length > 0 && (
                  <ChartGroup
                    $rows={Math.ceil(lastClustersPageChartIds.length / 2)}
                  >
                    {lastClustersPageChartIds.map(renderChart)}
                  </ChartGroup>
                )}
              </LastClustersListPageContainer>
            </AnalysisPdfPage>
          )}
          {entirePageChartGroups.map((chartIds, i) => {
            return (
              <AnalysisPdfPage
                key={i}
                analysis={analysis}
                compensatedFile={compensatedFile}
                fcsFile={fcsFile}
                project={project}
                pageNumber={lastGlobalClustersPageNumber + i + 1}
                totalPages={totalPages}
                signatures={signatures}
              >
                <ChartGroup $rows={4}>{chartIds.map(renderChart)}</ChartGroup>
              </AnalysisPdfPage>
            )
          })}
          {lastChartsPageChartIds && (
            <AnalysisPdfPage
              analysis={analysis}
              compensatedFile={compensatedFile}
              fcsFile={fcsFile}
              project={project}
              pageNumber={lastChartsPageNumber}
              totalPages={totalPages}
              signatures={signatures}
            >
              <LastChartsPageContainer>
                <ChartGroup
                  $rows={Math.ceil(lastChartsPageChartIds.length / 2)}
                >
                  {lastChartsPageChartIds.map(renderChart)}
                </ChartGroup>
                {lastChartsPageHeatmapClustersCount > 0 && (
                  <HeatmapContainer>
                    <HeatMapInlineSvg
                      src={heatmapSvgs[0].svg}
                      width={heatmapSvgs[0].width}
                      height={heatmapSvgs[0].height}
                    />
                  </HeatmapContainer>
                )}
              </LastChartsPageContainer>
            </AnalysisPdfPage>
          )}
          {heatmapSvgs
            .slice(lastChartsPageHeatmapClustersCount > 0 ? 1 : 0)
            .map(({ svg, width, height }, i) => (
              <AnalysisPdfPage
                key={i}
                analysis={analysis}
                compensatedFile={compensatedFile}
                fcsFile={fcsFile}
                project={project}
                pageNumber={lastChartsPageNumber + i + 1}
                totalPages={totalPages}
                signatures={signatures}
              >
                <HeatmapContainer>
                  <HeatMapInlineSvg src={svg} width={width} height={height} />
                </HeatmapContainer>
              </AnalysisPdfPage>
            ))}
          {groupedStatisticsItems.entirePageStatisticsGroups.map(
            ({ statisticsItems }, index) => {
              return (
                <AnalysisPdfPage
                  analysis={analysis}
                  compensatedFile={compensatedFile}
                  fcsFile={fcsFile}
                  project={project}
                  pageNumber={lastHeatMapPageNumber + index + 1}
                  totalPages={totalPages}
                  signatures={signatures}
                >
                  <div>
                    {statisticsItems.map(statisticItem => (
                      <StatisticsTableContainer>
                        <StatisticsTableTitle>
                          {statisticItem.name}
                        </StatisticsTableTitle>
                        <AnalysisStatisticsTable
                          statisticsItem={statisticItem}
                          channelDisplayNameById={channelDisplayNameById}
                          clusterById={clusterById}
                          gateById={gateById}
                          lassoById={lassoById}
                        />
                      </StatisticsTableContainer>
                    ))}
                  </div>
                </AnalysisPdfPage>
              )
            },
          )}
        </AnalysisPrintRoot>
      </StyledComponentsThemeProvider>
    </MuiThemeProvider>
  )
}

const unpackSignatures = (
  signatures: Signature[] | undefined,
): PrintSignatures => {
  if (!signatures) {
    throw new Error('Signatures were not fetched')
  }

  const validSignatures = signatures.filter(signature => !signature.canceled_at)

  const analysed = validSignatures.find(
    signature => signature.type === 'analysed',
  )
  const reviewed = validSignatures.find(
    signature => signature.type === 'reviewed',
  )
  const approved = validSignatures.find(
    signature => signature.type === 'approved',
  )

  return { analysed, reviewed, approved }
}

const AnalysisPrintRoot = styled.div`
  @page {
    size: A3 portrait;
    margin: 32px;
  }
`

const HierarchyContainer = styled.div`
  display: grid;
  grid-template-rows: 1fr auto auto;
  border: 1px solid ${props => props.theme.colors.primaryDark[20]};
  border-radius: ${props => props.theme.radius[2]}px;
  margin-bottom: 32px;
`

const ClustersCount = styled.div`
  font-weight: bold;
  font-size: ${props => props.theme.font.size.h3}px;
  color: ${props => props.theme.colors.primaryDark[70]};
  justify-self: start;
  padding-left: 16px;
  padding-bottom: 16px;
  justify-self: center;
  align-self: center;
`

const DepthSliderContainer = styled.div`
  padding: 32px;
  width: 100%;
  align-self: end;
  border-top: 1px solid ${props => props.theme.colors.primaryDark[20]};
  justify-self: center;
  align-self: center;
`

const CenteredInlineSvg = styled(InlineSvg)`
  justify-self: center;
  align-self: center;
`

const LastClustersListPageContainer = styled.div`
  display: grid;
  grid-template-rows: auto 1fr;
  gap: 32px;
`

const ChartGroup = styled.div<{ $rows: number }>`
  flex-grow: 1;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: ${props => `repeat(${props.$rows}, 320px)`};
  grid-row-gap: 32px;
  grid-column-gap: 32px;
`

const LastChartsPageContainer = styled.div`
  display: grid;
  grid-template-rows: auto 1fr;
`

const HeatmapContainer = styled.div`
  display: grid;
`

const HeatMapInlineSvg = styled(InlineSvg)`
  justify-self: center;
  align-self: center;
  border: 1px solid ${props => props.theme.colors.primaryDark[20]};
  border-radius: ${props => props.theme.radius[2]}px;
  padding: 0 14px 0 12px;
`

const StyledDownsamplingInfo = styled(DownsamplingInfo)`
  grid-column: 2 / -1;
  grid-row: 1 / -2;
`

const StatisticsTableContainer = styled.div`
  margin-bottom: 24px;

  th {
    font-weight: bold;
    font-size: 12px;
    border-color: ${props => props.theme.colors.primaryDark[20]};
    background-color: ${props => props.theme.colors.greyscale[5]};
  }
  td {
    font-size: 11px;
    border-color: ${props => props.theme.colors.primaryDark[20]};
  }
`

const StatisticsTableTitle = styled.h3`
  margin-top: 0;
  margin-bottom: 4px;
`

const SecretHash = styled.div`
  position: fixed;
  color: rgba(0, 0, 0, 0.01);
`
