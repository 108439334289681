import { Tooltip } from '@material-ui/core'
import { Formik } from 'formik'
import { produce } from 'immer'
import { groupBy, uniq } from 'lodash'
import { useMemo } from 'react'
import styled from 'styled-components'
import { array, object } from 'yup'

import { Icon } from 'assets/images/icons/Icon'

import { AddButton } from 'components/AddButton'
import { Button } from 'components/Button'
import { Modal } from 'components/Modal'

import {
  selectAnalysisLassos,
  selectChannels,
  selectClusters,
} from 'pages/analysis/store/selectors'
import { selectGates } from 'pages/analysis/store/selectors/gates.selectors'

import { useAppSelector } from 'shared/store'

import { AnalysisStatisticsWizardReportable } from './AnalysisStatisticsWizardReportable'

type AnalysisStatisticsWizardProps = {
  initialValues?: Analysis.Statistics
  onApply: (configuration: Analysis.Statistics) => void
  onClose: () => void
}

type AnalysisStatisticsWizardFormValues = {
  reportables: Analysis.Reportable[]
}

const AnalysisStatisticsWizardFormSchema = object({
  reportables: array(
    object({
      statistics: array()
        .required()
        .min(1, 'At least one statistic is required for a reportable'),
    }),
  )
    .required()
    .min(1, 'At least one reportable must be configured'),
})

export const AnalysisStatisticsWizard: React.FC<
  AnalysisStatisticsWizardProps
> = ({ initialValues, onClose, onApply }) => {
  const clusters = useAppSelector(selectClusters)
  const lassos = useAppSelector(selectAnalysisLassos)
  const gates = useAppSelector(selectGates)
  const channels = useAppSelector(selectChannels)

  const clustersByLabel = useMemo(() => {
    return groupBy(clusters, cluster => cluster.label)
  }, [clusters])

  const availableOptions = useMemo(() => {
    return {
      cluster: uniq(Object.keys(clustersByLabel))
        .filter(label => {
          return clustersByLabel[label].every(
            cluster =>
              cluster.isActive ||
              cluster.isRoot ||
              cluster.label !== cluster.defaultLabel,
          )
        })
        .map(label => ({
          value: clustersByLabel[label].map(cluster => cluster.id),
          label,
        })),
      lasso: Object.values(lassos).map(lasso => ({
        value: lasso.id,
        label: lasso.name,
      })),
      gate: gates.map(gate => ({
        value: gate.id,
        label: gate.name,
      })),
      channel: channels.map(channel => ({
        value: channel.id,
        label: channel.__computed__displayName,
      })),
    }
  }, [channels, clustersByLabel, gates, lassos])

  return (
    <Modal open onClose={onClose}>
      <AnalysisStatisticsWizardRoot>
        <Formik<AnalysisStatisticsWizardFormValues>
          initialValues={initialValues ?? { reportables: [] }}
          validationSchema={AnalysisStatisticsWizardFormSchema}
          onSubmit={onApply}
        >
          {({ values, errors, setValues, handleSubmit }) => {
            const handleAddReportable = () => {
              setValues(values =>
                produce(values, draft => {
                  draft.reportables.push({
                    type: 'cluster',
                    ids: availableOptions.cluster[0].value,
                    label: `Reportable ${draft.reportables.length + 1}`,
                    statistics: [],
                  })
                }),
              )
            }

            const handleDeleteReportable = (index: number) => () => {
              setValues(values =>
                produce(values, draft => {
                  draft.reportables = draft.reportables.filter(
                    (_, i) => i !== index,
                  )
                }),
              )
            }

            let firstApplicableError: string | undefined
            if (typeof errors.reportables === 'string') {
              firstApplicableError = errors.reportables
            } else {
              firstApplicableError = errors.reportables
                ?.map(reportable => {
                  if (typeof reportable === 'string') {
                    return reportable
                  }
                  if (typeof reportable.statistics === 'string') {
                    return reportable.statistics
                  }
                  return ''
                })
                .filter(Boolean)[0]
            }

            return (
              <Form onSubmit={handleSubmit}>
                <Header>Configure analysis statistics</Header>
                <Content>
                  <Reportables>
                    {values.reportables.map((_, index) => {
                      const path = `reportables.${index}`
                      return (
                        <AnalysisStatisticsWizardReportable
                          key={path}
                          path={path}
                          availableOptions={availableOptions}
                          onDelete={handleDeleteReportable(index)}
                        />
                      )
                    })}
                    <AddReportablePlaceholder>
                      <AddButton onClick={handleAddReportable} />
                    </AddReportablePlaceholder>
                  </Reportables>
                </Content>
                <Actions>
                  <Button onClick={onClose} variant="contained" grey>
                    Cancel
                  </Button>
                  <Tooltip
                    title={firstApplicableError ?? ''}
                    enterDelay={1}
                    placement="top"
                    arrow
                  >
                    {/* TODO: the div is required for the tooltip to work https://github.com/mui/material-ui/issues/8416 */}
                    <div>
                      <Button
                        variant="contained"
                        color="primary"
                        endIcon={<Icon name="arrowRight" />}
                        type="submit"
                        disabled={!!firstApplicableError}
                      >
                        Apply
                      </Button>
                    </div>
                  </Tooltip>
                </Actions>
              </Form>
            )
          }}
        </Formik>
      </AnalysisStatisticsWizardRoot>
    </Modal>
  )
}

const AnalysisStatisticsWizardRoot = styled.div`
  background-color: ${props => props.theme.colors.background};
  border-radius: ${props => props.theme.radius[2]}px;
  width: ${props => props.theme.layout.modalWidth}px;
  height: 80%;
  overflow: auto;
`

const Form = styled.form`
  display: grid;
  grid-template-rows: auto 1fr auto;
  height: 100%;
`

const Header = styled.h1`
  padding: 16px;
  border-bottom: 1px solid ${props => props.theme.colors.greyscale[10]};
  box-shadow: rgba(0, 0, 0, 0.1) 0 1px 4px;
  margin: 0;
  font-size: 15px;
`

const Actions = styled.div`
  border-top: 1px solid ${props => props.theme.colors.greyscale[10]};
  padding: 16px;
  display: flex;
  justify-content: flex-end;
  box-shadow: ${props => props.theme.shadows[3]};
  gap: 10px;

  & button {
    height: 32px;
    font-size: ${props => props.theme.font.size.small}px;
  }
`

const Content = styled.div`
  padding: 16px;
  overflow: auto;
`

const Reportables = styled.div`
  display: flex;
  gap: 16px;
  flex-direction: column;
`

const AddReportablePlaceholder = styled.div`
  border: 1px solid ${props => props.theme.colors.greyscale[10]};
  background: ${props => props.theme.colors.white};
  box-shadow: rgba(0, 0, 0, 0.1) 0 1px 4px;
  border-radius: ${props => props.theme.radius[2]}px;
  display: flex;
  place-items: center;
  height: 300px;
`
