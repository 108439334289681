import { ChartInfo } from './ChartInfo'

export const NoClusterInfo = (): JSX.Element => {
  return (
    <ChartInfo
      title="All data has been hidden from this graph"
      description="The chart is empty because the data was downsampled and at least one of the chosen channels was not used for clustering."
    />
  )
}
