import { useMemo } from 'react'

import { useReactiveRef } from './useReactiveRef'

type EventCallback<ARGS extends unknown[], R> = (...args: ARGS) => R

export const useEventCallback = <A extends unknown[], R>(
  fn: EventCallback<A, R>,
): EventCallback<A, R> => {
  const fnRef = useReactiveRef(fn)

  return useMemo(
    () =>
      function (this, ...args: A): R {
        return fnRef.current.call(this, ...args)
      },
    [fnRef],
  )
}
