import _ from 'lodash'
import { useEffect, useState } from 'react'

type UseSelect = {
  onSelect: (id: string) => void
  isSelected: (id: string) => boolean
  onSelectAll: () => void
  onDeselectAll: () => void
  allSelected: boolean
  selectedIds: string[]
}

export const useSelect = (
  allIds?: string[],
  initialIds?: string[],
  onChange?: (ids: string[]) => void,
): UseSelect => {
  const [selectedIds, setSelectedIdsWithoutCallback] = useState(
    initialIds || [],
  )
  const [allSelected, setAllSelected] = useState(false)

  const setSelectedIds = (ids: string[]) => {
    setSelectedIdsWithoutCallback(ids)
    onChange?.(ids)
  }

  const onSelect = (id: string) => {
    const selectedId = selectedIds.find(selectedId => selectedId === id)
    if (selectedId !== undefined) {
      setSelectedIds(selectedIds.filter(selectedId => selectedId !== id))
    } else {
      setSelectedIds([id, ...selectedIds])
    }
  }

  const onSelectAll = () => {
    if (!allSelected) {
      setSelectedIds(allIds || [])
      setAllSelected(true)
    } else {
      setSelectedIds([])
      setAllSelected(false)
    }
  }

  const onDeselectAll = () => {
    setSelectedIds([])
  }

  const isSelected = (id: string) => selectedIds.some(id_ => id_ === id)

  useEffect(() => {
    if (selectedIds.length === allIds?.length) {
      setAllSelected(true)
    } else {
      setAllSelected(false)
    }
  }, [allIds, selectedIds])

  useEffect(() => {
    if (!_.isEqual(initialIds, selectedIds)) {
      setSelectedIdsWithoutCallback(initialIds || [])
    }
    // eslint-disable-next-line
  }, [initialIds])

  return {
    onSelect,
    onSelectAll,
    onDeselectAll,
    allSelected,
    selectedIds,
    isSelected,
  }
}
