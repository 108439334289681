import { IconButton } from '@material-ui/core'
import { produce } from 'immer'
import styled from 'styled-components'

import { Icon } from 'assets/images/icons/Icon'

import { Layout, LayoutKey } from 'components/chart-grid'

import {
  ANALYSIS_HEATMAP_ID,
  ANALYSIS_HIERARCHY_ID,
  CLUSTER_LIST_ID,
} from 'shared/constants'
import { useEventCallback } from 'shared/hooks/useEventCallback'
import { useAppDispatch, useAppSelector } from 'shared/store'

import { theme } from 'Theme'

import { changeLayout } from './store/analysis.history.slice'
import {
  selectAnalysisStatisticsById,
  selectChartById,
  selectLayout,
  selectNextLayoutPositions,
} from './store/selectors'

export const AnalysisMultiTabMenu = (): JSX.Element => {
  const layout = useAppSelector(selectLayout)

  return (
    <Container>
      <Header>Manage multi-tab layouts</Header>
      <Column>
        <ColumnHeader>Primary</ColumnHeader>
        <ScrollableContainer>
          <LayoutItemRows layout={layout} layoutKey="primary" />
        </ScrollableContainer>
      </Column>
      <Column>
        <ColumnHeader>Secondary</ColumnHeader>
        <ScrollableContainer>
          <LayoutItemRows layout={layout} layoutKey="secondary" />
        </ScrollableContainer>
      </Column>
    </Container>
  )
}

const LayoutItemRows = ({
  layout,
  layoutKey,
}: {
  layout: Layout
  layoutKey: LayoutKey
}) => {
  const chartsById = useAppSelector(selectChartById)
  const statisticsById = useAppSelector(selectAnalysisStatisticsById)

  return Object.keys(layout[layoutKey]).map(id => {
    if (chartsById[id]) {
      return (
        <LayoutItemRow
          label={chartsById[id].name}
          key={id}
          id={id}
          layout={layout}
          layoutKey={layoutKey}
        />
      )
    }
    if (statisticsById[id]) {
      return (
        <LayoutItemRow
          key={id}
          id={id}
          layout={layout}
          layoutKey={layoutKey}
          label={statisticsById[id].name || statisticsById[id].default_name}
        />
      )
    }
    if (id === ANALYSIS_HEATMAP_ID) {
      return (
        <LayoutItemRow
          key={id}
          id={id}
          layout={layout}
          layoutKey={layoutKey}
          label={'Heat map'}
        />
      )
    }
    if (id === ANALYSIS_HIERARCHY_ID) {
      return (
        <LayoutItemRow
          key={id}
          id={id}
          layout={layout}
          layoutKey={layoutKey}
          label={'Clustering Hierarchy'}
        />
      )
    }
    if (id === CLUSTER_LIST_ID) {
      return (
        <LayoutItemRow
          key={id}
          id={id}
          layout={layout}
          layoutKey={layoutKey}
          label={'Cluster List'}
        />
      )
    }

    return undefined
  })
}

const LayoutItemRow = ({
  layout,
  layoutKey,
  id,
  label,
}: {
  layout: Layout
  layoutKey: LayoutKey
  id: string
  label: string
}): JSX.Element => {
  const dispatch = useAppDispatch()
  const nextLayoutPositions = useAppSelector(selectNextLayoutPositions)

  const handleMoveItem = useEventCallback(() => {
    const targetLayoutKey = layoutKey === 'primary' ? 'secondary' : 'primary'

    dispatch(
      changeLayout(
        produce(layout, draft => {
          draft[targetLayoutKey][id] = {
            ...layout[layoutKey][id],
            ...nextLayoutPositions[layoutKey],
          }

          delete draft[layoutKey][id]
        }),
      ),
    )
  })

  return (
    <GraphRow>
      {layoutKey === 'primary' && <span>{label}</span>}
      <IconButton size="small" onClick={handleMoveItem}>
        <Icon
          name={layoutKey === 'primary' ? 'arrowRight' : 'arrowLeft'}
          color={theme.colors.primaryDark[100]}
        />
      </IconButton>
      {layoutKey === 'secondary' && <span>{label}</span>}
    </GraphRow>
  )
}

const Container = styled.div`
  width: 100%;
  height: 100%;
  display: grid;
  grid-template-rows: 56px 1fr;
  grid-template-columns: 1fr 1fr;
  background-color: ${props => props.theme.colors.white};
  border: 1px solid ${props => props.theme.colors.primaryDark[20]};
  border-radius: ${props => props.theme.radius[2]}px;
  padding: 12px;
`

const Header = styled.div`
  grid-row: 1;
  grid-column: 1 / 3;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  padding-bottom: 20px;
  font-family: ${props => props.theme.font.style.bold};
`

const Column = styled.div`
  height: calc(100% - 36px);
  grid-row: 2;
  display: flex;
  flex-direction: column;
  &:nth-child(2) {
    border-right: 1px solid ${props => props.theme.colors.primary[20]};
  }
  overflow: hidden;
`

const GraphRow = styled.div`
  margin: ${props => props.theme.spacing(1)}px
    ${props => props.theme.spacing(2)}px;
  padding: ${props => props.theme.spacing(1)}px 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  box-shadow: 0 2px 1px -1px ${props => props.theme.colors.primary[20]};
`

const ColumnHeader = styled.div`
  height: 20px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: ${props => props.theme.spacing(2)}px;
  font-family: ${props => props.theme.font.style.bold};
`

const ScrollableContainer = styled.div`
  height: calc(100% - 36px);
  overflow-y: auto;
`
