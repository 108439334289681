import {
  safePolygon,
  useFloating,
  useHover,
  useInteractions,
} from '@floating-ui/react'
import { FC, ReactNode, useState } from 'react'
import styled from 'styled-components'

import { ReactComponent as NestedMenuItemArrowIcon } from 'assets/images/icons/nested-menu-item-arrow.svg'

import { DEFAULT_USE_FLOATING_PROPS } from 'components/tooltip'

import { MenuItem } from './MenuItem'

type NestedMenuItemProps = {
  label: ReactNode
  children: ReactNode
}

export const NestedMenuItem: FC<NestedMenuItemProps> = ({
  label,
  children,
}) => {
  const [isOpen, setIsOpen] = useState(false)

  const { floatingStyles, refs, context } = useFloating({
    ...DEFAULT_USE_FLOATING_PROPS,
    placement: 'right-start',
    open: isOpen,
    onOpenChange: setIsOpen,
    transform: false,
  })
  const { getFloatingProps, getReferenceProps } = useInteractions([
    useHover(context, { handleClose: safePolygon() }),
  ])

  return (
    <>
      <StyledMenuItem ref={refs.setReference} {...getReferenceProps()}>
        {label} <NestedMenuItemArrowIcon />
      </StyledMenuItem>
      {isOpen && (
        <TooltipContainer
          ref={refs.setFloating}
          style={floatingStyles}
          {...getFloatingProps()}
        >
          {children}
        </TooltipContainer>
      )}
    </>
  )
}

const TooltipContainer = styled.div`
  position: relative;
  z-index: 2;
  box-shadow: 2px 2px 10px 2px rgba(0, 0, 0, 0.1);
  border-radius: 4px;
  overflow: hidden;
  display: grid;
  grid-template-rows: 1fr;
`

const StyledMenuItem = styled(MenuItem)`
  & svg {
    margin-left: auto;
  }
`
