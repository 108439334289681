import { skipToken } from '@reduxjs/toolkit/query'
import { useRef, useState } from 'react'

import { useObserve } from './useObserve'

type UseStatefulRequestProps<Payload, Response> = {
  useStartRequestMutation: () => readonly [
    triggerRequest: (payload: Payload) => void,
    requestState: {
      isError: boolean
      isLoading: boolean
      isSuccess: boolean
      data?: { request_id: string; secret: string }
    },
  ]
  useGetResponseQuery: (
    payload: { request_id: string; secret: string } | typeof skipToken,
    options: { pollingInterval: number },
  ) => {
    isError: boolean
    isSuccess: boolean
    currentData?: Response
  }
}

type ResponseHandler<R> = (response: R) => { isFinished: boolean }

export const useStatefulRequest = <Payload, Response>({
  useStartRequestMutation,
  useGetResponseQuery,
}: UseStatefulRequestProps<Payload, Response>): {
  isLoading: boolean
  isError: boolean
  start: (payload: Payload, onResponse: ResponseHandler<Response>) => void
} => {
  const [shouldPoll, setShouldPoll] = useState(false)
  const responseHandlerRef = useRef<ResponseHandler<Response>>()
  const isCanceledRef = useRef(false)

  const [triggerStartRequest, startRequestMutationState] =
    useStartRequestMutation()

  const getResponseQueryState = useGetResponseQuery(
    startRequestMutationState.data
      ? {
          request_id: startRequestMutationState.data.request_id,
          secret: startRequestMutationState.data.secret,
        }
      : skipToken,
    { pollingInterval: shouldPoll ? 2 * 60 * 1000 : 0 },
  )

  const isError = !!(
    startRequestMutationState.isError || getResponseQueryState.isError
  )

  useObserve(startRequestMutationState, request => {
    if (request.isSuccess) {
      setShouldPoll(true)
      isCanceledRef.current = false
    }
  })

  useObserve(getResponseQueryState, query => {
    if (
      query.isSuccess &&
      query.currentData &&
      !isCanceledRef.current &&
      responseHandlerRef.current
    ) {
      if (responseHandlerRef.current(query.currentData).isFinished) {
        setShouldPoll(false)
      }
    }
  })

  useObserve(isError, isError => {
    if (isError) {
      setShouldPoll(false)
    }
  })

  return {
    isLoading: startRequestMutationState.isLoading || shouldPoll,
    isError,
    start: (
      payload: Payload,
      onResponse: (response: Response) => { isFinished: boolean },
    ) => {
      isCanceledRef.current = true
      responseHandlerRef.current = onResponse
      triggerStartRequest(payload)
      setShouldPoll(false)
    },
  }
}
