import { IconButton, Tooltip } from '@material-ui/core'
import {
  FastForward as FastForwardIcon,
  FastRewind as FastRewindIcon,
  Forward as ForwardIcon,
} from '@material-ui/icons'
import { FC } from 'react'
import styled from 'styled-components'

import {
  expandClusters,
  mergeClusters,
  mergeVisibleClustersRecursively,
} from 'pages/analysis/store/analysis.history.slice'
import {
  selectAnalysisAccessMode,
  selectCurrentDepth,
  selectMaxAvailableDepth,
} from 'pages/analysis/store/selectors'

import { useEventCallback } from 'shared/hooks/useEventCallback'
import { useAppDispatch, useAppSelector } from 'shared/store'

type AnalysisScatterPlotDepthSelectorProps = {
  shownActiveLeaves: string[]
  hiddenClusterIds: string[]
}

export const AnalysisScatterPlotDepthSelector: FC<
  AnalysisScatterPlotDepthSelectorProps
> = ({ shownActiveLeaves, hiddenClusterIds }) => {
  const dispatch = useAppDispatch()
  const depth = useAppSelector(selectCurrentDepth)
  const maxDepth = useAppSelector(selectMaxAvailableDepth)
  const analysisAccessMode = useAppSelector(selectAnalysisAccessMode)

  const isDisabled = analysisAccessMode === 'read-only'

  const handleExpandClusters = useEventCallback((depthChange: number) => {
    dispatch(
      expandClusters({
        selectedLeafIds: shownActiveLeaves,
        depthChange,
      }),
    )
  })

  const handleMergeVisibleClustersRecursively = useEventCallback(() => {
    dispatch(
      mergeVisibleClustersRecursively({
        hiddenClusterIds,
      }),
    )
  })

  const handleMergeVisibleClusters = useEventCallback(() => {
    dispatch(
      mergeClusters({
        selectedLeafIds: shownActiveLeaves,
        isExclusive: true,
      }),
    )
  })

  return (
    <Depth>
      <Tooltip title="Merge to common ancestors">
        <IconButton
          onClick={handleMergeVisibleClustersRecursively}
          disabled={depth === 1 || shownActiveLeaves.length === 0 || isDisabled}
        >
          <FastRewindIcon />
        </IconButton>
      </Tooltip>
      <Tooltip title="Merge clusters">
        <IconButton
          onClick={handleMergeVisibleClusters}
          disabled={
            depth - 1 <= 0 || shownActiveLeaves.length === 0 || isDisabled
          }
        >
          <RewindIcon />
        </IconButton>
      </Tooltip>
      <span />
      <DepthIndicator>{depth}</DepthIndicator>
      <span />
      <Tooltip title="Expand clusters">
        <IconButton
          onClick={() => handleExpandClusters(1)}
          disabled={
            depth + 1 > maxDepth || shownActiveLeaves.length === 0 || isDisabled
          }
        >
          <ForwardIcon />
        </IconButton>
      </Tooltip>
      <Tooltip title="Expand by 3 depths">
        <IconButton
          onClick={() => handleExpandClusters(3)}
          disabled={
            depth + 3 > maxDepth || shownActiveLeaves.length === 0 || isDisabled
          }
        >
          <FastForwardIcon />
        </IconButton>
      </Tooltip>
    </Depth>
  )
}

const Depth = styled.div`
  grid-area: depth;
  display: grid;
  grid-template-columns: auto auto 1fr 140px 1fr auto auto;

  button:not([disabled]) {
    svg {
      fill: ${props => props.theme.colors.primaryDark[100]};
    }
  }
`

const DepthIndicator = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  color: ${props => props.theme.colors.primaryDark[100]};
  font-size: 14px;
  font-weight: bold;
`

const RewindIcon = styled(ForwardIcon)`
  rotate: 180deg;
`
