import { DialogActions, DialogContent, DialogTitle } from '@material-ui/core'
import { FC } from 'react'
import styled from 'styled-components'

import { Button } from 'components/Button'

type SimpleConfirmationDialogProps = {
  title: string
  message: string
  onConfirm: () => void
  onClose: () => void
}
export const SimpleConfirmationDialog: FC<SimpleConfirmationDialogProps> = ({
  title,
  message,
  onConfirm,
  onClose,
}) => {
  return (
    <SimpleConfirmationDialogRoot>
      <DialogTitle>{title}</DialogTitle>
      <DialogContent>{message}</DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Cancel</Button>
        <Button
          onClick={() => {
            onClose()
            onConfirm()
          }}
        >
          Confirm
        </Button>
      </DialogActions>
    </SimpleConfirmationDialogRoot>
  )
}
const SimpleConfirmationDialogRoot = styled.div``
