import { Tooltip } from '@material-ui/core'
import { Formik, FormikConfig, FormikErrors } from 'formik'
import { mapValues } from 'lodash'
import { FC, useState } from 'react'
import styled from 'styled-components'
import { array, object, string } from 'yup'

import { Button } from 'components/button/Button'
import { Modal } from 'components/modal/Modal'
import { ModalContainer } from 'components/modal/ModalContainer'

import {
  useCreateExperimentMutation,
  usePreCreateExperimentMutation,
} from 'shared/api/experiments.api'
import { useCheckFilesChannelsMutation } from 'shared/api/pipelines.api'
import {
  BrickAnalysis,
  useLazyGetBrickByIdQuery,
} from 'shared/api/workflows.api'
import { useDialog } from 'shared/contexts/DialogContext'
import { useEventCallback } from 'shared/hooks/useEventCallback'
import { handleError } from 'shared/utils/errorHandler'

import { CreateExperimentWizardSelectCompensatedFilesStep } from './CreateExperimentWizardSelectCompensatedFilesStep'
import { CreateExperimentWizardSelectReferenceStep } from './CreateExperimentWizardSelectReferenceStep'

type CreateExperimentWizardProps = {
  projectId: string
  pipelineId: string
  onClose: () => void
}

export type CreateExperimentFormValues = {
  name?: string
  compensatedFiles: string[]
  referenceAnalyses: Record<string, BrickAnalysis | null>
}

const initialValues: CreateExperimentFormValues = {
  compensatedFiles: [],
  referenceAnalyses: {},
}

const CreateExperimentSchema = object({
  compensatedFiles: array(string()).min(1, 'You must select at least one file'),
  referenceAnalyses: object(),
})

export const CreateExperimentWizard: FC<CreateExperimentWizardProps> = ({
  projectId,
  pipelineId,
  onClose,
}) => {
  const { showConfirmationDialog } = useDialog()

  const [triggerCreateExperimentMutation, createExperimentMutationState] =
    useCreateExperimentMutation()
  const [triggerPreCreateExperimentMutation, preCreateExperimentMutationState] =
    usePreCreateExperimentMutation()
  const [triggerGetBrickByIdQuery, getBrickByIdQueryState] =
    useLazyGetBrickByIdQuery()
  const [triggerCheckFilesChannelsMutation] = useCheckFilesChannelsMutation()

  const isMutationLoading =
    preCreateExperimentMutationState.isLoading ||
    createExperimentMutationState.isLoading

  const [step, setStep] = useState(STEPS[0])

  const handlePreviousStep = useEventCallback(() => {
    setStep(STEP_SELECT_COMPENSATED_FILES)
  })

  const handleSubmit: FormikConfig<CreateExperimentFormValues>['onSubmit'] =
    useEventCallback(async values => {
      try {
        const checkFilesChannelsResult =
          await triggerCheckFilesChannelsMutation({
            compensatedFileIds: values.compensatedFiles,
            pipelineId,
          }).unwrap()

        const proceed = async () => {
          await triggerCreateExperimentMutation({
            template: pipelineId,
            name: values.name,
            compensated_files: values.compensatedFiles,
            references: mapValues(
              preCreateExperimentMutationState.data?.references,
              reference => ({
                ...reference,
                brick_reference_analysis:
                  values.referenceAnalyses[reference.brick_id],
              }),
            ),
          }).unwrap()
          onClose()
        }

        if (checkFilesChannelsResult.all_missing_channels) {
          showConfirmationDialog({
            title: 'Missing channels',
            message:
              `Some of the selected compensated files do not have the following ` +
              `channels from the pipeline: ${checkFilesChannelsResult.all_missing_channels.join(
                ',',
              )}. ` +
              `Do you want to proceed anyway?`,
            onConfirm: proceed,
          })
        } else {
          proceed()
        }
      } catch (error) {
        throw handleError(error)
      }
    })

  return (
    <Modal
      open
      title="Create experiment"
      Container={StyledModalContainer}
      onClose={onClose}
    >
      <Formik<CreateExperimentFormValues>
        initialValues={initialValues}
        onSubmit={handleSubmit}
        validationSchema={CreateExperimentSchema}
      >
        {({ errors, values, handleSubmit, setFieldValue, validateForm }) => {
          const getFirstApplicableError = (
            errors: FormikErrors<CreateExperimentFormValues>,
          ) => {
            if (step === STEP_SELECT_REFERENCE_ANALYSES) {
              if (getBrickByIdQueryState.data) {
                const nonLeafBricks = Object.values(
                  getBrickByIdQueryState.data,
                ).filter(brick => !brick.is_leaf)
                if (
                  nonLeafBricks.some(
                    brick => values.referenceAnalyses[brick.id] === null,
                  )
                ) {
                  return 'You must select a reference analysis for all non-leaf bricks'
                }
              }
            }
            if (step === STEP_SELECT_COMPENSATED_FILES) {
              if (errors.compensatedFiles) {
                return typeof errors.compensatedFiles === 'string'
                  ? errors.compensatedFiles
                  : errors.compensatedFiles.join(', ')
              }
            }
            return ''
          }

          const handleNextStep = async () => {
            await validateForm()

            if (step === STEP_SELECT_REFERENCE_ANALYSES) {
              handleSubmit()
              return
            }

            if (
              preCreateExperimentMutationState.data &&
              getBrickByIdQueryState.data
            ) {
              setStep(STEP_SELECT_REFERENCE_ANALYSES)
              return
            }

            try {
              const results =
                await triggerPreCreateExperimentMutation(pipelineId).unwrap()

              setFieldValue(
                'referenceAnalyses',
                mapValues(
                  results.references,
                  reference => reference.brick_reference_analysis,
                ),
              )

              triggerGetBrickByIdQuery(Object.keys(results.references))

              setStep(STEP_SELECT_REFERENCE_ANALYSES)
            } catch (error) {
              throw handleError(error)
            }
          }

          return (
            <Form onSubmit={handleSubmit}>
              <Fieldset disabled={isMutationLoading}>
                {step === STEP_SELECT_COMPENSATED_FILES ? (
                  <CreateExperimentWizardSelectCompensatedFilesStep
                    projectId={projectId}
                  />
                ) : (
                  <CreateExperimentWizardSelectReferenceStep
                    references={
                      preCreateExperimentMutationState.data?.references
                    }
                  />
                )}
              </Fieldset>
              <Actions>
                <Button
                  colorOverride="greyscale"
                  onClick={
                    step === STEP_SELECT_COMPENSATED_FILES
                      ? onClose
                      : handlePreviousStep
                  }
                >
                  {step === STEP_SELECT_COMPENSATED_FILES ? 'Cancel' : 'Back'}
                </Button>
                <Tooltip title={getFirstApplicableError(errors)}>
                  <div>
                    <Button
                      disabled={
                        !!(
                          getFirstApplicableError(errors) ||
                          preCreateExperimentMutationState.error
                        )
                      }
                      loading={isMutationLoading}
                      onClick={handleNextStep}
                    >
                      {step === STEP_SELECT_COMPENSATED_FILES
                        ? 'Next'
                        : 'Confirm'}
                    </Button>
                  </div>
                </Tooltip>
              </Actions>
            </Form>
          )
        }}
      </Formik>
    </Modal>
  )
}

const STEP_SELECT_COMPENSATED_FILES = 0
const STEP_SELECT_REFERENCE_ANALYSES = 1

const STEPS = [STEP_SELECT_COMPENSATED_FILES, STEP_SELECT_REFERENCE_ANALYSES]

const StyledModalContainer = styled(ModalContainer)`
  min-width: 400px;
  max-width: 90vw;
  max-height: 90vh;
  gap: ${props => props.theme.spacing(4)}px;
  overflow: hidden;
  display: grid;
  grid-template-rows: auto 1fr;
`

const Form = styled.form`
  height: 100%;
  overflow: hidden;
  display: flex;
  flex-direction: column;
`

const Fieldset = styled.fieldset`
  border: none;
  overflow: auto;
`

const Actions = styled.div`
  display: flex;
  justify-content: center;
  gap: 12px;
  margin-top: ${props => props.theme.spacing(4)}px;
`
