import styled from 'styled-components'

import { Button } from './Button'

export const ActionButton = styled(Button)<{ disabled?: boolean }>`
  font-size: ${props => props.theme.font.size.smallest}px;
  height: 32px;
  color: ${props => props.theme.colors.primaryDark[100]};
  background-color: transparent;
  padding: 0 10px;
  &:hover {
    cursor: ${props => (props.disabled ? 'default' : '')};
    background-color: ${props =>
      props.disabled ? 'transparent' : 'rgba(0, 0, 0, 0.04)'};
    color: ${props => props.theme.colors.primaryDark[100]};
  }
  & svg {
    width: 24px;
    height: 24px;
  }
  & .icon__analysis-layout-btn,
  & .icon__analysis-signature-btn {
    width: 18px;
    height: 18px;
  }
  & .MuiButton-startIcon {
    margin-right: 6px;
  }
  & .MuiCircularProgress-root > svg {
    width: 20px;
    height: 20px;
  }
`
