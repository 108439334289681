export const ADD_CHART_PLACEHOLDER_ID = '__add-chart-placeholder'
export const ANALYSIS_HIERARCHY_ID = '__analysis-hierarchy'
export const ANALYSIS_HIERARCHY_SUNBURST_ID = '__analysis-hierarchy-sunburst'
export const ANALYSIS_HIERARCHY_PIE_CHART_ID = '__analysis-hierarchy-pie-chart'
export const ANALYSIS_HIERARCHY_NETWORK_ID = '__analysis-hierarchy-network'
export const ANALYSIS_HEATMAP_ID = '__analysis-heatmap'
export const ANALYSIS_LASSOS_HIERARCHY_GRAPH_ID = '__analysis-lassos-hierarchy'
export const CLUSTER_LIST_ID = '__cluster-list'
export const MULTI_TAB_MENU_ID = '__multi-tab-menu'
export const COLOR_PICKER_SIZE = {
  width: 240,
  height: 250,
}

export const EVENT_PERCENTAGE_LIMIT = 100

export const CUMULATIVE_FLUORESCENCE_CHANNEL =
  'Cumulative-Fluorescence' as const
export const TIME_CHANNEL = 'Time' as const
export const UMAP_X_CHANNEL = 'Umap-x' as const
export const UMAP_Y_CHANNEL = 'Umap-y' as const
export const OTHER_LETTER = 'other' as const
export const OTHER_CHANNEL_TYPE = 'other' as const

export const LOCAL_FORAGE_RAW_LEAF_LABELS_KEY = 'leafLabel'
export const LOCAL_FORAGE_RAW_TRANSFORMED_DATA_KEY = 'transformedData'
export const LOCAL_FORAGE_SCALES_KEY = 'scales'
export const LOCAL_FORAGE_META_ANALYSIS_KEY = 'metaAnalysis'
export const LOCAL_FORAGE_META_ANALYSIS_GLOBAL_VIZ_KEY = 'globalViz'
export const LOCAL_FORAGE_META_ANALYSIS_VOLCANO_PLOT_KEY =
  'metaAnalysisVolcanoPlot'

export const STATISTIC_TYPE_LABELS: Record<
  Analysis.ReportableStatisticType,
  string
> = {
  count: 'Count',
  'absolute-count': 'Absolute Count (Cells/uL)',
  'percentage-of': '% of',
  median: 'Median',
  mean: 'Mean',
  robustsd: 'Robust SD',
  cv: 'CV',
  robustcv: 'Robust CV',
  mode: 'Mode',
  geomean: 'Geometric Mean',
  'cellular-concentration': 'Cellular Concentration (global)',
}

export const TIME = {
  MILLISECOND: 1,
  SECOND: 1000,
  MINUTE: 60 * 1000,
  HOUR: 60 * 60 * 1000,
}
