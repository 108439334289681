export enum OrganizationTypes {
  Academic = 'AC',
  Regular = 'RG',
}

export type Organization = {
  id: string
  type: `${OrganizationTypes}`
  name: string
  contact_first_name?: string
  contact_last_name?: string
  address_1?: string
  address_2?: string
  city?: string
  state?: string
  postal_code?: string
  country: string
  email?: string
  phone_number?: string
}

export type Team = {
  id: string
  name: string
  organization: Organization
}

export interface OrganizationMember {
  id: string
  email: string
  first_name: string
  last_name: string
  date_joined: string
  last_login: string | null
  is_active: boolean
  is_expert: boolean
  is_operator: boolean
  job: string | null
  organization: string
  username: string
  invitation: OrganizationInvitation | null
}

export interface OrganizationInvitation {
  accept_date: string | null
  created_at: string
  email: string
  id: string
  organization: string
  send_date: string | null
  status: 'sending' | 'acceptance pending' | 'accepted' | 'expired' | 'unknown'
  is_active?: boolean
}
