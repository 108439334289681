import { createApi } from '@reduxjs/toolkit/query/react'

import { getInjectedEnvironmentVariable } from 'shared/utils/getInjectedEnvironmentVariable'

import { privateFetchBaseQuery } from './privateFetchBaseQuery'
import { publicFetchBaseQuery } from './publicFetchBaseQuery'

export const PRIVATE_API_TAGS = [
  'Analysis',
  'Basket',
  'Brick',
  'CompensatedFile',
  'Export',
  'File',
  'Invitation',
  'Logbook',
  'Member',
  'MetaAnalysis',
  'Organization',
  'Payment',
  'Project',
  'Signature',
  'Spillover',
  'StatefulRequest',
  'Wallet',
  'Workflow',
] as const

export type PrivateApiTag = (typeof PRIVATE_API_TAGS)[number]

// all other private apis inject their endpoints to this api
// in order to split the code between files
export const privateApi = createApi({
  reducerPath: 'privateApi',
  baseQuery:
    getInjectedEnvironmentVariable('PDF_MODE') === 'true'
      ? publicFetchBaseQuery()
      : privateFetchBaseQuery(),
  tagTypes: PRIVATE_API_TAGS,
  endpoints: () => ({}),
})
