import { Typography } from '@material-ui/core'
import { Autocomplete } from '@material-ui/lab'
import { useFormikContext } from 'formik'
import styled from 'styled-components'

import { StyledInput } from 'components/forms/Input'

import { MetaAnalysisWizardFormValues } from './MetaAnalysisWizard'

export const MetaAnalysisWizardSelectChannelLabelsStep: React.FC<{
  channelNames: Record<string, string[]>
}> = ({ channelNames }) => {
  const {
    setFieldValue,
    values: { channelNames: channelNameValues },
  } = useFormikContext<MetaAnalysisWizardFormValues>()
  return (
    <MetaAnalysisWizardSelectChannelLabelsStepRoot>
      <StepHeader>
        <Typography variant="body1" align="center">
          The following channels have multiple inconsistent labels set in
          selected analyses and/or experiment. Please select a single name for
          each channel to use in meta-analysis
        </Typography>
        <Typography variant="body2" align="center">
          Note: Leave the field blank if you want to use the default channel
          name
        </Typography>
      </StepHeader>
      <ChannelLabelList>
        {Object.keys(channelNames).map(key => (
          <LabelRow>
            <Typography variant="body2">{key}:</Typography>
            <Autocomplete
              key={key}
              options={channelNames[key]}
              freeSolo={true}
              value={channelNameValues[key]}
              onChange={(_event, newValue) => {
                setFieldValue('channelNames', {
                  ...channelNameValues,
                  [key]: newValue,
                })
              }}
              renderInput={params => (
                <div ref={params.InputProps.ref}>
                  <StyledInput error={undefined} {...params.inputProps} />
                </div>
              )}
            />
          </LabelRow>
        ))}
      </ChannelLabelList>
    </MetaAnalysisWizardSelectChannelLabelsStepRoot>
  )
}

const MetaAnalysisWizardSelectChannelLabelsStepRoot = styled.div`
  display: grid;
  grid-template-rows: auto 1fr;
  justify-items: center;
  overflow: hidden;
`

const StepHeader = styled.div`
  padding: ${props => props.theme.spacing(4)}px;

  & > p:first-child {
    margin-bottom: ${props => props.theme.spacing(2)}px;
  }
`

const ChannelLabelList = styled.div`
  width: 75%;
  border-top: 1px solid ${props => props.theme.colors.primaryDark[50]};
  border-bottom: 1px solid ${props => props.theme.colors.primaryDark[50]};
  overflow-y: auto;
`

const LabelRow = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: ${props => props.theme.spacing(1)}px;

  &:not(&:last-child) {
    border-bottom: 1px solid ${props => props.theme.colors.primaryDark[20]};
  }

  & > div {
    width: 50%;
    max-width: 400px;
  }
`
