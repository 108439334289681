import React, { FC, ReactElement, useState } from 'react'
import { TransformComponent, TransformWrapper } from 'react-zoom-pan-pinch'
import styled from 'styled-components'

import { Icon } from 'assets/images/icons/Icon'

import { theme } from 'Theme'

import { PAN_BOTTOM_BAR_HEIGHT } from './constants'

type ChildrenProps = {
  panModeEnabled: boolean
}

type PanProps = Readonly<{
  children: ((props: ChildrenProps) => React.ReactNode) | null
  customControlButton?: ReactElement
  bottomInformation?: string
}>

const INITIAL_SCALE = 1

export const Pan: FC<PanProps> = ({
  children,
  customControlButton,
  bottomInformation,
}) => {
  const [panning, setPanning] = useState(false)
  const [scale, setScale] = useState(INITIAL_SCALE)

  const colors = {
    active: theme.colors.white,
    normal: theme.colors.primaryDark[100],
    disabled: theme.colors.primaryDark[50],
  }

  return (
    <Container>
      <TransformWrapper
        initialScale={INITIAL_SCALE}
        wheel={{ disabled: true }}
        panning={{ disabled: !panning }}
        doubleClick={{ disabled: true }}
        onTransformed={(_, { scale }) => setScale(scale)}
      >
        {({ zoomIn, zoomOut }) => {
          return (
            <>
              <TransformComponent>
                {children &&
                  children({
                    panModeEnabled: panning,
                  })}
              </TransformComponent>
              <BottomBar>
                <Information>{bottomInformation}</Information>
                <Controls>
                  {customControlButton}
                  <ControlButton
                    type="button"
                    onClick={() => setPanning(!panning)}
                    active={panning}
                  >
                    <Icon
                      name="hand"
                      stroke={panning ? colors.active : colors.normal}
                    />
                  </ControlButton>
                  <ControlButton
                    type="button"
                    onClick={() => zoomIn()}
                    disabled={Math.round(scale) === 8}
                  >
                    <Icon
                      name="plus"
                      stroke={
                        Math.round(scale) === 8
                          ? colors.disabled
                          : colors.normal
                      }
                      color={
                        Math.round(scale) === 8
                          ? colors.disabled
                          : colors.normal
                      }
                    />
                  </ControlButton>
                  <ControlButton
                    type="button"
                    onClick={() => zoomOut()}
                    disabled={scale === 1}
                  >
                    <Icon
                      name="less"
                      stroke={scale === 1 ? colors.disabled : colors.normal}
                      color={scale === 1 ? colors.disabled : colors.normal}
                    />
                  </ControlButton>
                </Controls>
              </BottomBar>
            </>
          )
        }}
      </TransformWrapper>
    </Container>
  )
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 100%;
  & .react-transform-component {
    margin: auto;
  }
`

const BottomBar = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  align-self: flex-end;
  height: ${PAN_BOTTOM_BAR_HEIGHT}px;
  width: 100%;
  padding: 0 12px;
`

const Information = styled.div`
  font-weight: bold;
  font-size: ${props => props.theme.font.size.h3}px;
  color: ${props => props.theme.colors.primaryDark[70]};
`

const Controls = styled.div`
  height: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
`

const ControlButton = styled.button<{ active?: boolean }>`
  display: flex;
  justify-content: center;
  align-items: center;
  outline: none;
  border: none;
  width: 32px;
  height: 32px;
  cursor: pointer;
  margin: 0 6px;
  background-color: ${props =>
    props.active
      ? props.theme.colors.primaryDark[100]
      : props.theme.colors.primaryDark[10]};
  & > svg {
    width: 24px;
    height: 24px;
  }
  &:disabled {
    background: ${props => props.theme.colors.primaryDark[20]};
    cursor: not-allowed;
  }
`
