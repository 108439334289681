import { useEffect, useState } from 'react'
import { useHistory } from 'react-router'
import { useEvent, useMount } from 'react-use'
import { v4 as uuid } from 'uuid'

import {
  selectAnalysisClusterTree,
  selectAnalysisLoadingStatus,
  selectAnalysisStoreName,
} from 'pages/analysis/store/selectors'

import {
  initMultiTabSecondaryTabState,
  patchState,
  RootState,
  store,
  useAppDispatch,
  useAppSelector,
} from 'shared/store'
import { selectApplicationInstanceId } from 'shared/store/auth.slice'
import {
  getIsSecondaryTab,
  getMultiTabModeSessionId,
} from 'shared/utils/multi-tab'
import { getIsMultiTabMode } from 'shared/utils/multi-tab'
import { analysisWorker } from 'shared/worker'

import { useEventCallback } from './useEventCallback'

const MULTI_TAB_CHANNEL = 'multi-tab-channel'

const GET_STATE_REQUEST_MESSAGE = 'get-state-request'
const GET_STATE_RESPONSE_MESSAGE = 'get-state-response'
const PATCH_STATE_REQUEST_MESSAGE = 'patch-state-request'
const EXIT_MULTI_TAB_MODE_REQUEST_MESSAGE = 'exit-multi-tab-mode-request'
const CLOSE_TAB_REQUEST_MESSAGE = 'close-tab-request'

type MultiTabMessage =
  | {
      type: typeof GET_STATE_REQUEST_MESSAGE
    }
  | {
      type: typeof GET_STATE_RESPONSE_MESSAGE
      payload: {
        state: RootState
      }
    }
  | {
      type: typeof PATCH_STATE_REQUEST_MESSAGE
      payload: {
        patch: Partial<RootState>
      }
    }
  | {
      type: typeof EXIT_MULTI_TAB_MODE_REQUEST_MESSAGE
    }
  | {
      type: typeof CLOSE_TAB_REQUEST_MESSAGE
    }

type WithMeta<T> = T & {
  meta: {
    multiTabModeSessionId: string
    senderApplicationInstanceId: string
  }
}

export const isSecondaryTabInitializedRef = {
  current: false,
}

export const useMultiTab = (): {
  isSecondaryTabInitialized: boolean
} => {
  const dispatch = useAppDispatch()
  const isAnalysisLoaded =
    useAppSelector(selectAnalysisLoadingStatus) === 'success'
  const applicationInstanceId = useAppSelector(selectApplicationInstanceId)

  const [isSecondaryTabInitialized, setIsSecondaryTabInitialized] =
    useState(false)

  const history = useHistory()
  const isMultiTabMode = getIsMultiTabMode()
  const multiTabModeSessionId = getMultiTabModeSessionId()

  // useRequestSecondaryTabInitState
  const isSecondaryTab = getIsSecondaryTab()
  useEffect(() => {
    if (isSecondaryTab) {
      sendGetStateRequest()
    }
  }, [isSecondaryTab])

  // useBeforeUnload
  useEvent(
    'beforeunload',
    useEventCallback(() => {
      if (isMultiTabMode) {
        exitMultiTabMode(history)
      }
    }),
  )

  // useListen
  const listen = useEventCallback(async (event: MessageEvent) => {
    const message: WithMeta<MultiTabMessage> = event.data

    if (
      message.meta.multiTabModeSessionId !== multiTabModeSessionId ||
      message.meta.senderApplicationInstanceId === applicationInstanceId
    ) {
      return
    }

    switch (message.type) {
      case GET_STATE_REQUEST_MESSAGE: {
        sendGetStateResponse()
        break
      }

      case GET_STATE_RESPONSE_MESSAGE: {
        const state = message.payload.state
        await analysisWorker.loadClusteringFiles(
          selectAnalysisStoreName(state),
          selectAnalysisClusterTree(state),
        )
        dispatch(initMultiTabSecondaryTabState({ state }))
        setIsSecondaryTabInitialized(true)
        isSecondaryTabInitializedRef.current = true
        break
      }

      case PATCH_STATE_REQUEST_MESSAGE: {
        dispatch(patchState({ patch: message.payload.patch }))
        break
      }

      case EXIT_MULTI_TAB_MODE_REQUEST_MESSAGE: {
        exitMultiTabMode(history)
        break
      }

      case CLOSE_TAB_REQUEST_MESSAGE: {
        window.close()
        break
      }
    }
  })
  useEffect(() => {
    if (!isMultiTabMode) {
      return
    }

    const channel = new BroadcastChannel(MULTI_TAB_CHANNEL)
    channel.addEventListener('message', listen)

    return () => channel.close()
  }, [history, isAnalysisLoaded, isMultiTabMode, listen])
  // <\useListen

  // useHandleReloadedTab
  useMount(() => {
    const isReloaded =
      (
        window.performance.getEntriesByType(
          'navigation',
        )[0] as PerformanceNavigationTiming
      ).type === 'reload'

    if (getIsMultiTabMode() && (document.referrer === '' || isReloaded)) {
      exitMultiTabMode(history)
    }
  })

  return {
    isSecondaryTabInitialized,
  }
}

const sendMessage = (message: MultiTabMessage): void => {
  const state = store.getState()
  const applicationInstanceId = selectApplicationInstanceId(state)
  const multiTabModeSessionId = getMultiTabModeSessionId()
  if (!multiTabModeSessionId) {
    throw new Error(
      'multiTabModeSessionId should be defined when multi tab mode is on',
    )
  }
  const messageWithMeta: WithMeta<MultiTabMessage> = {
    ...message,
    meta: {
      multiTabModeSessionId,
      senderApplicationInstanceId: applicationInstanceId,
    },
  }

  const channel = new BroadcastChannel(MULTI_TAB_CHANNEL)
  channel.postMessage(messageWithMeta)
  channel.close()
}

const sendGetStateRequest = (): void => {
  const message: MultiTabMessage = {
    type: GET_STATE_REQUEST_MESSAGE,
  }
  sendMessage(message)
}

const sendGetStateResponse = (): void => {
  const message: MultiTabMessage = {
    type: GET_STATE_RESPONSE_MESSAGE,
    payload: {
      state: store.getState(),
    },
  }
  sendMessage(message)
}

export const sendPatchStateRequest = (patch: Partial<RootState>): void => {
  const message: MultiTabMessage = {
    type: PATCH_STATE_REQUEST_MESSAGE,
    payload: {
      patch,
    },
  }
  sendMessage(message)
}

const sendExitMultiTabModeRequest = (): void => {
  const message: MultiTabMessage = {
    type: EXIT_MULTI_TAB_MODE_REQUEST_MESSAGE,
  }
  sendMessage(message)
}

export const sendCloseTabRequest = (): void => {
  const message: MultiTabMessage = {
    type: CLOSE_TAB_REQUEST_MESSAGE,
  }
  sendMessage(message)
}

export const enterMultiTabMode = (
  history: ReturnType<typeof useHistory>,
): void => {
  const multiTabModeSessionId = uuid()
  const { pathname, search } = history.location
  const searchParams = new URLSearchParams(search)
  searchParams.set('layout', 'primary')
  searchParams.set('multiTabModeSessionId', multiTabModeSessionId)
  history.push(pathname + '?' + searchParams.toString())
  searchParams.set('layout', 'secondary')
  searchParams.set('multiTabModeSessionId', multiTabModeSessionId)
  window.open(
    window.location.origin + pathname + '?' + searchParams.toString(),
    '_blank',
    'noopener',
  )
}

export const exitMultiTabMode = (
  history: ReturnType<typeof useHistory>,
): void => {
  if (getIsSecondaryTab()) {
    sendExitMultiTabModeRequest()
    window.close()
    return
  }

  sendCloseTabRequest()
  const { pathname, search } = history.location
  const params = new URLSearchParams(search)
  params.delete('layout')
  params.delete('multiTabModeSessionId')
  history.push(pathname + '?' + params.toString())
}
