import { PointOptionsObject } from 'highcharts'

import {
  MetaAnalysisBoxPlotType,
  MetaAnalysisFile,
  MetaAnalysisColors,
  MetaAnalysisFrequencyChartType,
  MetaAnalysisGlobalHeatMapChartType,
  MetaAnalysisSpiderwebPlotType,
  MetaAnalysisVolcanoPlotFile,
  MetaAnalysisVolcanoPlotType,
} from 'shared/api/meta-analysis.api'
import { sort } from 'shared/utils/array'

import { AppTheme } from 'Theme'

export const computeBoxPlotSeries = (
  chart: MetaAnalysisBoxPlotType,
  metaAnalysisFile: MetaAnalysisFile,
  channels: string[],
): [Highcharts.SeriesBoxplotOptions, Highcharts.SeriesScatterOptions] => {
  return [
    {
      type: 'boxplot',
      data: chart.selectedFileIds.map(id => {
        const channelIndex = channels.findIndex(
          channel => channel === chart.selectedChannel,
        )
        const clusterDetails =
          metaAnalysisFile.stat_files[id][chart.selectedCluster]
        const min = clusterDetails.min?.[channelIndex] ?? 0
        const q1 = clusterDetails.q1?.[channelIndex] ?? 0
        const median = clusterDetails.median?.[channelIndex] ?? 0
        const q3 = clusterDetails.q3?.[channelIndex] ?? 0
        const max = clusterDetails.max?.[channelIndex] ?? 0
        return [min, q1, median, q3, max]
      }),
    },
    {
      type: 'scatter',
      name: 'Outliers',
      data: chart.selectedFileIds.flatMap((fileId, fileIndex) => {
        const channelIndex = channels.findIndex(
          channel => channel === chart.selectedChannel,
        )
        const clusterDetails =
          metaAnalysisFile.stat_files[fileId][chart.selectedCluster]

        return (
          clusterDetails.outliers
            ?.filter(outlier => {
              const value = outlier[channelIndex]
              return value !== null && value !== undefined
            })
            .map(outlier => {
              return [fileIndex, outlier[channelIndex]]
            }) ?? []
        )
      }),
    },
  ]
}

export const computeFrequencyChartSeries = (
  chart: MetaAnalysisFrequencyChartType,
  metaAnalysisFile: MetaAnalysisFile,
  colors: MetaAnalysisColors,
  channels: string[],
  fileNameById: Record<string, string>,
): Highcharts.SeriesVariwideOptions[] => {
  const totalProportion = chart.selectedFileIds.reduce((prev, cur) => {
    return (
      prev + metaAnalysisFile.stat_files[cur][chart.selectedCluster].proportion
    )
  }, 0)
  const data = chart.selectedFileIds.map(fileId => {
    const file = metaAnalysisFile.stat_files[fileId]
    const cluster = file[chart.selectedCluster]
    const channelIndex = channels.findIndex(
      channel => channel === chart.selectedChannel,
    )
    return {
      category: fileNameById[fileId],
      y: cluster[
        chart.selectedIntensityMode === 'median'
          ? 'median'
          : chart.selectedIntensityMode === 'arithmetic-mean'
          ? 'mean'
          : 'geomean'
      ][channelIndex],
      z: cluster.proportion / totalProportion,
      color: colors.files[fileId],
    } as PointOptionsObject
  })

  return [
    {
      name: chart.selectedCluster,
      data,
      type: 'variwide',
    },
  ]
}

export const computeSpiderwebPlotSeries = (
  chart: MetaAnalysisSpiderwebPlotType,
  colors: MetaAnalysisColors,
  metaAnalysisFile: MetaAnalysisFile,
  fileNameById: Record<string, string>,
  channels: string[],
): {
  series: Highcharts.SeriesLineOptions[]
  xAxisCategories: string[]
} => {
  const sortedChannels = sort(
    chart.selectedChannels,
    'desc',
    channel => channel,
  )

  return {
    series: chart.selectedFileIds.map(fileId => ({
      name: fileNameById[fileId],
      color: colors.files[fileId],
      data: sortedChannels.map(selectedChannel => {
        const channelIndex = channels.findIndex(
          channel => channel === selectedChannel,
        )
        const cluster =
          metaAnalysisFile.stat_files[fileId][chart.selectedCluster]
        return cluster.heatmap[channelIndex]
      }),
      pointPlacement: 'on',
      type: 'line',
    })),
    xAxisCategories: sortedChannels,
  }
}

export const computeGlobalHeatMapSeries = (
  chart: MetaAnalysisGlobalHeatMapChartType,
  metaAnalysisFile: MetaAnalysisFile,
  colors: MetaAnalysisColors,
): Highcharts.SeriesBarOptions[] => {
  return chart.selectedClusters.map(cluster => {
    return {
      type: 'bar',
      name: cluster,
      color: colors.clusters[cluster],
      data: chart.selectedFileIds.map(
        fileId => metaAnalysisFile.stat_files[fileId][cluster].proportion,
      ),
    }
  })
}

export const computeVolcanoPlotSeries = (
  chart: MetaAnalysisVolcanoPlotType,
  volcanoPlotFile: MetaAnalysisVolcanoPlotFile,
  theme: AppTheme,
): Highcharts.SeriesScatterOptions[] => {
  const groupUnion = `(${chart.selectedGroups[0]}|${chart.selectedGroups[1]})`
  const foldchangeArray = Object.keys(volcanoPlotFile).find(key => {
    const re = new RegExp(`${groupUnion}_vs_${groupUnion}_foldchange`, 'g')
    return key.match(re)
  })

  const pvalArray = Object.keys(volcanoPlotFile).find(key => {
    const re = new RegExp(`${groupUnion}_vs_${groupUnion}_pval`, 'g')
    return key.match(re)
  })

  if (!foldchangeArray || !pvalArray) {
    throw new Error(
      'Could not find foldchange or pval data for selected groups',
    )
  }

  const upregulatedClusters = volcanoPlotFile.index.filter(
    (_cluster, index) =>
      (volcanoPlotFile[foldchangeArray][index] as number) > chart.xThreshold &&
      (volcanoPlotFile[pvalArray][index] as number) > chart.yThreshold,
  )

  const downregulatedClusters = volcanoPlotFile.index.filter(
    (_cluster, index) =>
      (volcanoPlotFile[foldchangeArray][index] as number) <
        chart.xThreshold * -1 &&
      (volcanoPlotFile[pvalArray][index] as number) > chart.yThreshold,
  )

  const remainingClusters = volcanoPlotFile.index.filter(
    cluster =>
      !upregulatedClusters.includes(cluster) &&
      !downregulatedClusters.includes(cluster),
  )

  const upregulatedPoints = {
    name: 'Upregulated',
    id: 'upregulated',
    data: upregulatedClusters.map(cluster => {
      const index = volcanoPlotFile.index.indexOf(cluster)
      return {
        x: volcanoPlotFile[foldchangeArray][index] as number,
        y: volcanoPlotFile[pvalArray][index] as number,
        name: cluster,
      }
    }),
    type: 'scatter' as const,
    color: 'red',
  }

  const downregulatedPoints = {
    name: 'Downregulated',
    id: 'downregulated',
    data: downregulatedClusters.map(cluster => {
      const index = volcanoPlotFile.index.indexOf(cluster)
      return {
        x: volcanoPlotFile[foldchangeArray][index] as number,
        y: volcanoPlotFile[pvalArray][index] as number,
        name: cluster,
      }
    }),
    type: 'scatter' as const,
    color: 'blue',
  }

  const remainingPoints = {
    name: 'None',
    id: 'none',
    data: remainingClusters.map(cluster => {
      const index = volcanoPlotFile.index.indexOf(cluster)
      return {
        x: volcanoPlotFile[foldchangeArray][index] as number,
        y: volcanoPlotFile[pvalArray][index] as number,
        name: cluster,
      }
    }),
    type: 'scatter' as const,
    color: theme.colors.greyscale[20],
  }

  return [upregulatedPoints, downregulatedPoints, remainingPoints]
}
