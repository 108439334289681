import styled from 'styled-components'

import { Header } from 'components/Header'

import { SectionHeader } from './SectionHeader'

type HelpProps = {
  projectId: string
}

export const Help: React.FC<HelpProps> = () => {
  return (
    <HelpRoot>
      <Header />
      <Main>
        <SectionHeader title="Help" />
      </Main>
    </HelpRoot>
  )
}

const HelpRoot = styled.div``

const Main = styled.main`
  margin-left: 100px;
`
