import { FC, useState } from 'react'
import styled from 'styled-components'

import { LoadingMask } from 'components/LoadingMask'
import { UserAvatar } from 'components/UserAvatar'
import { UserMenu } from 'components/UserMenu'

import { useGetUserQuery } from 'shared/api/user.api'

export const UserWidget: FC = () => {
  const user = useGetUserQuery()

  const [rootRef, setRootRef] = useState<HTMLDivElement | null>(null)
  const [shouldShowMenu, setShouldShowMenu] = useState(false)

  if (user.data === undefined) {
    return (
      <UserWidgetRoot ref={setRootRef}>
        <LoadingMask />
      </UserWidgetRoot>
    )
  }

  return (
    <>
      <UserWidgetRoot
        ref={setRootRef}
        onClick={() => setShouldShowMenu(currentValue => !currentValue)}
      >
        <Title>
          <UserName>
            {user.data?.first_name} {user.data.last_name}
          </UserName>
          <UserRole>{user.data.job}</UserRole>
        </Title>
        <UserAvatar username={user.data.username} size={38} />
      </UserWidgetRoot>
      <UserMenu
        anchorElement={rootRef}
        isOpen={shouldShowMenu}
        onClose={() => setShouldShowMenu(false)}
      />
    </>
  )
}

const UserWidgetRoot = styled.div`
  background-color: #fff;
  border-radius: 50px;
  display: flex;
  gap: 10px;
  padding: 5px 10px 5px 15px;
  align-items: center;
  width: fit-content;
  cursor: pointer;
`

const Title = styled.div`
  display: flex;
  gap: 5px;
`

const UserName = styled.span`
  color: ${({ theme }) => theme.colors.primaryDark[100]};
  font-family: 'OpenSans-Bold';
`

const UserRole = styled.span`
  color: ${({ theme }) => theme.colors.primaryDark[50]};
  opacity: 0.6;
`
