import Cookies from 'js-cookie'

export const CSRF_TOKEN_REQUEST_HEADER_NAME = 'X-CSRFToken'
export const CSRF_TOKEN_RESPONSE_HEADER_NAME = 'csrftoken'
export const APPLICATION_INSTANCE_ID_HEADER_NAME = 'X-Application-Instance-Id'
export const DONT_SET_HEADER_VALUE = 'do-not-set'

export const getCsrfToken = (): string | undefined => {
  return Cookies.get(CSRF_TOKEN_RESPONSE_HEADER_NAME)
}

export const removeCsrfToken = (): void => {
  Cookies.set(CSRF_TOKEN_RESPONSE_HEADER_NAME, '', { expires: 0 })
}
