import { SeriesScatterOptions } from 'highcharts'

import { ScatterPlotChartScale } from 'pages/analysis/store/selectors'

import { handleError } from 'shared/utils/errorHandler'

import { CUMULATIVE_FLUORESCENCE_CHART_SERIES_COLORS } from 'Theme'

import { AnalysisWorkerStore } from './AnalysisWorkerStore'

type ComputeStandardSeriesResult = {
  series: SeriesScatterOptions[]
  scale: ScatterPlotChartScale
}

export class MetaCleanSeries {
  private store: AnalysisWorkerStore

  constructor(store: AnalysisWorkerStore) {
    this.store = store
  }

  public async computeSeries(): Promise<ComputeStandardSeriesResult> {
    try {
      await this.store.waitForClusteringFiles()

      const { attrib, grp_flow, value } = this.store.getMetaCleanData()

      const series = ['keep', 'drop'].map((attribValue: string) => ({
        type: 'scatter' as const,
        name: attribValue,
        color: CUMULATIVE_FLUORESCENCE_CHART_SERIES_COLORS[attribValue],
        marker: {
          radius: 3,
          symbol: attribValue === 'keep' ? 'circle' : 'triangle',
        },
        data: attrib.reduce(
          (data: number[][], attrib: string, index: number) => {
            if (attrib === attribValue) {
              return [...data, [grp_flow[index], value[index]]]
            } else {
              return data
            }
          },
          [],
        ),
      }))

      return {
        series,
        scale: this.computeScale(series),
      }
    } catch (e) {
      throw handleError(e, `Failed to compute meta clean series`)
    }
  }

  private computeScale = (series: SeriesScatterOptions[] | undefined) => {
    const allPoints = series
      ? [
          ...((series[0] as SeriesScatterOptions).data as Array<
            [number, number]
          >),
          ...((series[1] as SeriesScatterOptions).data as Array<
            [number, number]
          >),
        ]
      : []
    const xValues = allPoints.map(point => point[0])
    const yValues = allPoints.map(point => point[1])

    return {
      xAxis: {
        min: Math.min(...xValues),
        max: Math.max(...xValues),
      },
      yAxis: {
        min: Math.min(...yValues),
        max: Math.max(...yValues),
      },
    }
  }
}
