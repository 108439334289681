import React from 'react'
import styled from 'styled-components'

import { ChannelLabels } from 'shared/models/AnalysisModels'
import { shortenText } from 'shared/utils/text'

const Y_AXIS_WIDTH = 20

type AnalysisPdfChartContainerProps = {
  children: React.ReactNode
  title?: string
} & (
  | {
      xAxis?: undefined
      yAxis?: undefined
      channelDisplayNames?: undefined
    }
  | {
      xAxis: string
      yAxis?: string
      channelDisplayNames: ChannelLabels
    }
)

export const AnalysisPdfChartContainer: React.FC<
  AnalysisPdfChartContainerProps
> = ({ title, xAxis, yAxis, children, channelDisplayNames }) => {
  return (
    <AnalysisPdfChartContainerRoot>
      <Title>{title}</Title>
      <Chart>{children}</Chart>
      {xAxis && <XAxis>{channelDisplayNames[xAxis]}</XAxis>}
      {yAxis && (
        <YAxis>
          <YAxisWrapper>
            {shortenText(channelDisplayNames[yAxis] ?? yAxis, 40)}
          </YAxisWrapper>
        </YAxis>
      )}
    </AnalysisPdfChartContainerRoot>
  )
}

const AnalysisPdfChartContainerRoot = styled.div`
  display: grid;
  grid-template-rows: auto 1fr auto;
  grid-template-columns: auto 1fr auto;
  grid-template-areas:
    'title title title'
    'y-axis chart chart '
    'x-axis x-axis x-axis';
  border: 1px solid ${props => props.theme.colors.primaryDark[20]};
  border-radius: ${props => props.theme.radius[2]}px;
  overflow: hidden;
`

const Title = styled.div`
  grid-area: title;
  place-self: center;
  font-weight: bold;
  margin: 4px 0;
`

const Chart = styled.div`
  grid-area: chart;
  overflow: hidden;
  margin-right: ${Y_AXIS_WIDTH}px;

  img {
    width: 100%;
    height: 100%;
  }
`

const XAxis = styled.div`
  grid-area: x-axis;
  place-self: center;
  margin-bottom: 4px;
`

const YAxis = styled.div`
  width: ${Y_AXIS_WIDTH}px;
  position: relative;
  grid-area: y-axis;
  overflow: hidden;
  margin-left: 4px;
`

const YAxisWrapper = styled.div`
  white-space: nowrap;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%) rotate(-90deg);
`
