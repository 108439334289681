import { useFormikContext } from 'formik'
import styled from 'styled-components'

import { Checkbox } from 'components/Checkbox'
import Input from 'components/forms/Input'
import Select from 'components/forms/Select'

import { MetaAnalysisWizardFormValues } from './MetaAnalysisWizard'

export const MetaAnalysisWizardSelectCreateStep = (): JSX.Element => {
  const { values, errors, touched, handleBlur, handleChange } =
    useFormikContext<MetaAnalysisWizardFormValues>()

  return (
    <MetaAnalysisWizardCreateStepRoot>
      <Input
        name="name"
        label="Name"
        value={values.name}
        error={touched.name ? errors.name : undefined}
        onChange={handleChange}
        onBlur={handleBlur}
      />
      <Input
        name="description"
        label="Description"
        type="text-area"
        value={values.description}
        rows={10}
        error={touched.description ? errors.description : undefined}
        onChange={handleChange}
        onBlur={handleBlur}
      />
      <Select
        name="uMapMethod"
        label="Dimensionality reduction"
        options={[
          { value: 'none', label: 'None' },
          { value: 'tsne', label: 't-SNE' },
          { value: 'umap', label: 'UMAP' },
        ]}
        value={values.uMapMethod}
        onChange={handleChange}
      />
      <Checkbox
        name="alignClusters"
        label="Batch effect removal"
        checked={values.alignClusters}
        onChange={handleChange}
      />
      <Checkbox
        name="searchGroups"
        label="Groups definition"
        checked={values.searchGroups}
        onChange={handleChange}
      />
    </MetaAnalysisWizardCreateStepRoot>
  )
}

const MetaAnalysisWizardCreateStepRoot = styled.div`
  padding: 16px;
`
