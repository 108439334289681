import { debounce } from 'lodash'
import React, { useMemo, useState } from 'react'

import Input, { InputProps } from './Input'

type DebouncedInputProps = Omit<InputProps, 'onChange'> & {
  onChange?: (value: string) => void
  waitMs?: number
}

export const DebouncedInput = ({
  onChange,
  waitMs = 100,
  ...props
}: DebouncedInputProps): JSX.Element => {
  const [value, setValue] = useState(props.value)

  const handleChange = useMemo(
    () => debounce((value: string) => onChange?.(value), waitMs),
    [onChange, waitMs],
  )

  return (
    <Input
      {...props}
      value={value}
      onChange={event => {
        const text = event.target.value
        setValue(text)
        handleChange(text)
      }}
    />
  )
}
