import { RadioGroup } from '@material-ui/core'
import { filesize } from 'filesize'
import { Formik, FormikConfig } from 'formik'
import { mapValues } from 'lodash'
import { FC, useCallback, useEffect, useMemo } from 'react'
import styled from 'styled-components'
import { array, boolean, number, object, string } from 'yup'

import { ValidationLabel } from 'components/ validation-label/ValidationLabel'
import { ChannelsList } from 'components/ChannelsList'
import { Checkbox } from 'components/Checkbox'
import { CircularProgress } from 'components/CircularProgress'
import RadioButton from 'components/RadioButton'
import { Button } from 'components/button/Button'
import { Modal } from 'components/modal/Modal'
import { ModalContainer } from 'components/modal/ModalContainer'
import { Table, TableColumn, TableRow } from 'components/table/Table'
import { TextField } from 'components/text-field/TextField'

import { useGetCompensatedFileByIdQuery } from 'shared/api/files.api'
import { useGetUserQuery } from 'shared/api/user.api'
import {
  BrickSettingsCytometerType,
  BrickSettingsTransformationType,
  PrecisionAndGranularityMode,
  useCreateWorkflowMutation,
  usePreCreateWorkflowMutation,
} from 'shared/api/workflows.api'
import { OTHER_LETTER } from 'shared/constants'
import { useStable } from 'shared/hooks/useStable'
import { calculateClusteringGranularity } from 'shared/utils/clustering.utils'
import { handleError } from 'shared/utils/errorHandler'
import { formatDateTime } from 'shared/utils/utils'

import { ClusteringPrecisionAndGranularityWizardSection } from './ClusteringPrecisionAndGranularityWizardSection'
import { WizardSectionCard } from './WizardSectionCard'
import {
  CLUSTERING_EXCLUDED_CHANNELS,
  DEFAULT_KD,
  DEFAULT_KT,
  MAX_KD,
  MAX_KT,
  MAX_TRANSFORMATION_FACTOR,
  MIN_KD,
  MIN_KT,
  MIN_TRANSFORMATION_FACTOR,
} from './constants'

type CreateWorkflowModalProps = {
  compensatedFileIds: string[]
  projectId: string
  onClose: () => void
}

const DEFAULT_TRANSFORMATION_FACTOR_BY_CYTOMETER = {
  conventional: 150,
  spectral: 50,
  mass: 5,
}

type CreateWorkflowFormValues = {
  alignClusters: boolean
  concatenateFiles: boolean
  cytometer: BrickSettingsCytometerType
  description: string
  doubletExclusion: boolean
  precisionAndGranularityMode: PrecisionAndGranularityMode
  kd: number
  kt: number
  metaClean: boolean
  name: string
  selectedChannels: string[]
  transformationMethod: BrickSettingsTransformationType | 'none'
  transformationFactor: number
}

const CreateWorkflowSchema = object({
  alignClusters: boolean(),
  concatenateFiles: boolean(),
  cytometer: string().required(),
  description: string(),
  doubletExclusion: string(),
  kd: number().min(MIN_KD).max(MAX_KD).required(),
  kt: number().min(MIN_KT).max(MAX_KT).required(),
  metaClean: boolean(),
  name: string().max(80).required(),
  selectedChannels: array(string().required()).min(
    2,
    'You must select at least two channels',
  ),
  transformationMethod: string().required(),
  transformationFactor: number()
    .min(MIN_TRANSFORMATION_FACTOR)
    .max(MAX_TRANSFORMATION_FACTOR)
    .required(),
})

export const CreateWorkflowModal: FC<CreateWorkflowModalProps> = ({
  compensatedFileIds,
  projectId,
  onClose,
}) => {
  const compensatedFileByIdQueryState =
    useGetCompensatedFileByIdQuery(compensatedFileIds)
  const [triggerPreCreateWorkflowMutation, preCreateWorkflowMutationState] =
    usePreCreateWorkflowMutation()

  const [triggerCreateWorkflowMutation, createWorkflowMutationState] =
    useCreateWorkflowMutation()

  const isExpert = !!useGetUserQuery().data?.is_expert

  const stable__compensatedFileIds = useStable(compensatedFileIds)
  useEffect(() => {
    triggerPreCreateWorkflowMutation({
      project: projectId,
      compensated_files: stable__compensatedFileIds,
    })
  }, [
    compensatedFileIds,
    projectId,
    stable__compensatedFileIds,
    triggerPreCreateWorkflowMutation,
  ])

  const compensatedFiles = compensatedFileByIdQueryState.data
    ? Object.values(compensatedFileByIdQueryState.data)
    : undefined

  const initialValues: CreateWorkflowFormValues = useMemo(() => {
    const settings = preCreateWorkflowMutationState.data?.settings
    return {
      alignClusters: !!settings?.align_clusters,
      concatenateFiles: !!settings?.concatenate_files,
      cytometer: 'conventional',
      description: '',
      doubletExclusion: !!settings?.doublet_exclusion,
      precisionAndGranularityMode: 'standard',
      kd: settings?.kd ?? DEFAULT_KD,
      kt:
        (compensatedFiles &&
          calculateClusteringGranularity({
            mode: 'standard',
            compensatedFiles,
            clusteringType: 'primary',
          })) ??
        settings?.kt ??
        DEFAULT_KT,
      metaClean: !!settings?.metaclean,
      name: preCreateWorkflowMutationState.data?.name ?? '',
      selectedChannels: [],
      transformationMethod: settings?.transformation_method ?? 'none',
      transformationFactor:
        settings?.transformation_args.factor ??
        DEFAULT_TRANSFORMATION_FACTOR_BY_CYTOMETER['conventional'],
    }
  }, [
    compensatedFiles,
    preCreateWorkflowMutationState.data?.name,
    preCreateWorkflowMutationState.data?.settings,
  ])

  const handleSubmit: FormikConfig<CreateWorkflowFormValues>['onSubmit'] =
    useCallback(
      async values => {
        if (!preCreateWorkflowMutationState.data) {
          throw handleError(new Error('Default clustering settings not found'))
        }

        try {
          await triggerCreateWorkflowMutation({
            compensated_files:
              preCreateWorkflowMutationState.data.compensated_files,
            description: values.description,
            name: values.name,
            project: projectId,
            settings: {
              add_graphs_for_selected_channels: false,
              align_clusters: values.alignClusters,
              channels: mapValues(
                preCreateWorkflowMutationState.data.settings.channels,
                (value, key) => ({
                  ...value,
                  is_selected: values.selectedChannels.includes(key),
                }),
              ),
              clustering_mode: isExpert ? 'Expert' : 'Normal',
              concatenate_files: values.concatenateFiles,
              cytometer: values.cytometer,
              doublet_exclusion: values.doubletExclusion,
              precision_and_granularity_mode:
                values.precisionAndGranularityMode,
              kd: values.kd,
              kt: values.kt,
              metaclean: values.metaClean,
              normalisation: true,
              transformation_args: { factor: values.transformationFactor },
              transformation_method:
                values.transformationMethod === 'none'
                  ? null
                  : values.transformationMethod,
            },
          }).unwrap()
          onClose()
        } catch (error) {
          handleError(error)
        }
      },
      [
        isExpert,
        onClose,
        preCreateWorkflowMutationState.data,
        projectId,
        triggerCreateWorkflowMutation,
      ],
    )

  const columns: TableColumn[] = [
    {
      key: 'file_name',
      label: 'File name',
      style: { width: '30%' },
    },
    {
      key: 'created_at',
      label: 'Creation date',
      style: { width: '20%' },
    },
    {
      key: 'author_name',
      label: 'Author',
      style: { width: '30%' },
    },
    {
      key: 'fcs_file_size',
      label: 'Size',
      style: { width: '20%' },
    },
  ]

  const rows: TableRow[] = compensatedFileByIdQueryState.data
    ? compensatedFileIds.map(compensatedFileId => {
        const compensatedFile =
          compensatedFileByIdQueryState.data?.[compensatedFileId]
        if (!compensatedFile) {
          throw new Error(`Compensated file ${compensatedFileId} not found`)
        }

        return {
          key: compensatedFileId,
          cells: [
            {
              key: 'file_name',
              content: compensatedFile.name,
            },
            {
              key: 'created_at',
              content: formatDateTime(compensatedFile.created_at),
            },
            {
              key: 'author_name',
              content: compensatedFile.author_name,
            },
            {
              key: 'fcs_file_size',
              content: filesize((compensatedFile.fcs_file_size ?? 0) * 1000),
            },
          ],
        }
      })
    : []

  return (
    <Modal
      open
      title="Set up workflow"
      onClose={onClose}
      Container={StyledModalContainer}
    >
      {preCreateWorkflowMutationState.isLoading ? (
        <CircularProgress />
      ) : (
        <Formik<CreateWorkflowFormValues>
          initialValues={initialValues}
          enableReinitialize
          validationSchema={CreateWorkflowSchema}
          onSubmit={handleSubmit}
        >
          {({
            values,
            touched,
            errors,
            isValid,
            handleChange,
            handleBlur,
            handleSubmit,
            setFieldValue,
          }) => (
            <form onSubmit={handleSubmit}>
              <ScrollWrapper>
                <WizardSectionCard title="Name">
                  {touched.name && errors.name ? (
                    <ValidationLabel>{errors.name}</ValidationLabel>
                  ) : null}
                  <StyledTextField
                    name="name"
                    value={values.name}
                    placeholder="Analysis name"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    autoComplete="off"
                  />
                </WizardSectionCard>

                <WizardSectionCard title="Description">
                  <StyledTextField
                    name="description"
                    value={values.description}
                    placeholder="Description"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    autoComplete="off"
                    multiline={true}
                    minRows={2}
                    maxRows={4}
                  />
                </WizardSectionCard>

                <WizardSectionCard title="Select utilized cytometer type">
                  <RadioGroup
                    row
                    name="cytometer"
                    value={values.cytometer}
                    onChange={event => {
                      setFieldValue(event.target.name, event.target.value)
                      setFieldValue(
                        'transformationFactor',
                        DEFAULT_TRANSFORMATION_FACTOR_BY_CYTOMETER[
                          event.target.value
                        ],
                      )
                    }}
                  >
                    <RadioButton value="conventional" label="Conventional" />
                    <RadioButton value="spectral" label="Spectral" />
                    <RadioButton value="mass" label="Mass" />
                  </RadioGroup>
                </WizardSectionCard>

                <WizardSectionCard title="Metaclean">
                  <Checkbox
                    name="metaClean"
                    checked={values.metaClean}
                    onChange={handleChange}
                    label="Identification and removal of anomalies derived from abrupt changes in flow rate or signal instability"
                  />
                </WizardSectionCard>

                <WizardSectionCard title="Select display transformation">
                  <RadioGroup
                    row
                    name="transformationMethod"
                    value={values.transformationMethod}
                    onChange={event => {
                      setFieldValue(event.target.name, event.target.value)
                      if (event.target.value === 'logicle') {
                        setFieldValue('normalization', false)
                      }
                    }}
                  >
                    <RadioButton value="none" label="None" />
                    <RadioButton value="arcsinh" label="ArcSinH" />
                    <RadioButton value="logicle" label="Logicle" />
                    <RadioButton value="biexp" label="Biexpo" />
                  </RadioGroup>
                </WizardSectionCard>

                <WizardSectionCard title="Select channels for clustering">
                  {errors.selectedChannels && (
                    <ValidationLabel>{errors.selectedChannels}</ValidationLabel>
                  )}
                  <ChannelsList
                    columns={3}
                    channels={Object.entries(
                      preCreateWorkflowMutationState.data?.settings.channels ??
                        {},
                    ).map(([name, details]) => {
                      const defaultLabel = details.PnS ?? undefined

                      return {
                        name,
                        label: defaultLabel,
                        defaultLabel,
                        ...details,
                        letter: details.letter ?? OTHER_LETTER,
                      }
                    })}
                    excludedChannels={CLUSTERING_EXCLUDED_CHANNELS}
                    selectedChannels={values.selectedChannels}
                    selectable
                    onSelectedChannelsChange={channels =>
                      setFieldValue('selectedChannels', channels)
                    }
                  />
                </WizardSectionCard>

                <ClusteringPrecisionAndGranularityWizardSection
                  compensatedFiles={compensatedFiles}
                  isExpert={isExpert}
                  clusteringType="primary"
                />

                <WizardSectionCard title="Selected files">
                  <StyledTable columns={columns} rows={rows} />
                </WizardSectionCard>

                {!isValid && (
                  <ValidationLabel>
                    Correct errors in the form above
                  </ValidationLabel>
                )}
                <Actions>
                  <Button colorOverride="greyscale" onClick={onClose}>
                    Cancel
                  </Button>
                  <Button
                    type="submit"
                    disabled={
                      !isValid || !!preCreateWorkflowMutationState.error
                    }
                    loading={createWorkflowMutationState.isLoading}
                  >
                    Confirm
                  </Button>
                </Actions>
              </ScrollWrapper>
            </form>
          )}
        </Formik>
      )}
    </Modal>
  )
}

const StyledModalContainer = styled(ModalContainer)`
  min-width: 800px;
  min-height: 400px;
  font-size: 13px;
`

const ScrollWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  max-height: calc(100vh - 192px);
  padding: 0 ${props => props.theme.spacing(4)}px;
  overflow-y: auto;
`

const StyledTextField = styled(TextField)`
  width: 50%;

  & > .MuiInputBase-root {
    margin-top: 0;
  }
`

const StyledTable = styled(Table)`
  & td {
    color: ${props => props.theme.colors.primaryDark[50]};
  }
`

const Actions = styled.div`
  display: flex;
  gap: 12px;
  margin-top: ${props => props.theme.spacing(2)}px;
`
