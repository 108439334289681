import { configureStore } from '@reduxjs/toolkit'

import { exportApi } from 'shared/api/export.api'
import { metaAnalysisApi } from 'shared/api/meta-analysis.api'
import { privateApi } from 'shared/api/private.api'
import { reducer } from 'shared/store'
import { globalErrorHandlingMiddleware } from 'shared/store/global-error-handling.middleware'

import { generateEncodedHtml, showLoadingMask } from '..'
import { MetaAnalysisPdf } from './MetaAnalysisPdf'

export const generateMetaAnalysisPdf = async (
  metaAnalysisIds: string[],
): Promise<void> => {
  const removeLoadingMask = showLoadingMask('Preparing Meta-Analysis PDF...')

  const store = configureStore({
    reducer,
    middleware: getDefaultMiddleware =>
      getDefaultMiddleware({
        serializableCheck: false,
        immutableCheck: false,
      }).concat(privateApi.middleware, globalErrorHandlingMiddleware),
  })

  for (const metaAnalysisId of metaAnalysisIds) {
    try {
      await store
        .dispatch(
          metaAnalysisApi.endpoints.getMetaAnalysis.initiate(metaAnalysisId),
        )
        .unwrap()

      await store.dispatch(
        exportApi.endpoints.generateMetaAnalysisPdf.initiate({
          id: metaAnalysisId,
          content: await generateEncodedHtml(
            await MetaAnalysisPdf({ state: store.getState() }),
          ),
        }),
      )
    } catch (error) {
      console.error(error)
    }
  }

  removeLoadingMask()
}
