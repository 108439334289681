import Highcharts from 'highcharts'

import { createAnalysisPieChartBaseOptions } from 'components/graphs/AnalysisPieChartBaseOptions'

import {
  selectActiveLeavesWithoutRoot,
  selectGloballyHiddenClusters,
  selectHighlightedCluster,
} from 'pages/analysis/store/selectors'

import { RootState } from 'shared/store'

type RenderPieChartProps = {
  state: RootState
  width: number
  height: number
}

export const renderPieChart = async ({
  state,
  width,
  height,
}: RenderPieChartProps): Promise<string> => {
  const activeLeavesWithoutRoot = selectActiveLeavesWithoutRoot(state)
  const highlightedCluster = selectHighlightedCluster(state)
  const globallyHiddenClusters = selectGloballyHiddenClusters(state)

  const AnalysisPieChartBaseOptions = createAnalysisPieChartBaseOptions({
    leaves: activeLeavesWithoutRoot,
    globallyHiddenClusters,
    highlightedCluster,
  })

  return new Highcharts.Chart(document.createElement('div'), {
    ...AnalysisPieChartBaseOptions,
    chart: {
      ...AnalysisPieChartBaseOptions.chart,
      width,
      height,
    },
  }).getSVG()
}
