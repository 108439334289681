import { useEffect } from 'react'
import { usePrevious } from 'react-use'

export const useObserve = <T>(
  value: T,
  onChange: (value: T, previous: T | undefined) => void,
  equalityFunction = (a: T | undefined, b: T | undefined) => a === b,
): void => {
  const previousValue = usePrevious(value)

  useEffect(() => {
    if (!equalityFunction(previousValue, value)) {
      onChange(value, previousValue)
    }
  }, [equalityFunction, onChange, previousValue, value])
}
