import { Cluster } from 'pages/analysis/store/selectors'

import { handleError } from 'shared/utils/errorHandler'

import { AnalysisWorkerStore } from './AnalysisWorkerStore'

export type ComputeSeriesProps = {
  globallyHiddenClusterIds: string[]
  selectedChannels: string[]
  activeLeaves: Pick<Cluster, 'id' | 'stats'>[]
}

export class HeatMapSeries {
  private store: AnalysisWorkerStore

  constructor(store: AnalysisWorkerStore) {
    this.store = store
  }

  public async computeSeries({
    globallyHiddenClusterIds,
    activeLeaves,
    selectedChannels,
  }: ComputeSeriesProps): Promise<Highcharts.SeriesHeatmapOptions[]> {
    try {
      await this.store.waitForClusteringFiles()

      // this filter allows us to hide, on the heatmap, the entire row of a cluster
      // when it is globally hidden
      const activeLeavesNotGloballyHidden = activeLeaves.filter(l => {
        return !globallyHiddenClusterIds.includes(l.id)
      })
      return [
        {
          data: activeLeavesNotGloballyHidden.reduce(
            (acc: number[][], val, y) => {
              return selectedChannels
                .map((selectedChannel, x) => [
                  x,
                  y,
                  selectedChannel ? val.stats.heatmap[selectedChannel] : 0,
                ])
                .concat(acc)
            },
            [],
          ),
          type: 'heatmap',
          turboThreshold: 10000,
          borderWidth: 1,
          borderColor: 'white',
        },
      ]
    } catch (e) {
      throw handleError(e, `Failed to compute heatmap series`)
    }
  }
}
