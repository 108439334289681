import { Switch } from '@material-ui/core'
import { FC, useEffect, useState } from 'react'
import styled from 'styled-components'

import { analysisApi } from 'shared/api/analysis.api'
import { metaAnalysisApi } from 'shared/api/meta-analysis.api'
import { useEventCallback } from 'shared/hooks/useEventCallback'
import { useAppDispatch } from 'shared/store'
import {
  getAutoSaveSetting,
  setAutoSaveSetting,
} from 'shared/utils/localstorage'

type AutoSaveButtonProps = {
  type: 'analysis' | 'meta-analysis'
}

export const AutoSaveButton: FC<AutoSaveButtonProps> = ({ type }) => {
  const isAutoSaveOnFromLocalStorage = getAutoSaveSetting()
  const [isAutoSaveOn, setAutoSave] = useState(isAutoSaveOnFromLocalStorage)

  const changeAutoSaveSetting = (isOn: boolean) => {
    setAutoSave(isOn)
    setAutoSaveSetting(isOn)
  }

  const dispatch = useAppDispatch()

  const save = useEventCallback(() => {
    try {
      if (type === 'analysis') {
        dispatch(analysisApi.endpoints.saveAnalysis.initiate())
      } else if (type === 'meta-analysis') {
        dispatch(metaAnalysisApi.endpoints.saveMetaAnalysis.initiate())
      }
    } catch {
      changeAutoSaveSetting(false)
    }
  })

  useEffect(() => {
    if (isAutoSaveOn) {
      const intervalID = setInterval(
        () => {
          save()
        },
        5 * 60 * 1000,
      )

      return () => {
        clearInterval(intervalID)
      }
    }
  }, [isAutoSaveOn, save])

  return (
    <AutoSaveButtonRoot>
      AutoSave
      <Switch
        checked={isAutoSaveOn}
        color="primary"
        onClick={() => changeAutoSaveSetting(!isAutoSaveOn)}
      />
    </AutoSaveButtonRoot>
  )
}

const AutoSaveButtonRoot = styled.div`
  margin-right: 10px;
`
