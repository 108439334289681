import { FC } from 'react'
import styled from 'styled-components'

import { StatusDots } from 'components/StatusDots'

import { BrickAnalysis } from 'shared/api/workflows.api'

type AnalysisTreeNodeTitleProps = {
  analysis: BrickAnalysis
}

export const AnalysisTreeNodeTitle: FC<AnalysisTreeNodeTitleProps> = ({
  analysis,
}) => (
  <AnalysisNodeTitle>
    {analysis.name}
    <StatusDots status={analysis.status} singleLine />
  </AnalysisNodeTitle>
)

const AnalysisNodeTitle = styled.div`
  display: flex;
  align-items: center;
  gap: 16px;
`
