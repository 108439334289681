import { PointerEventObject, SelectEventObject } from 'highcharts'
import HighchartsReact from 'highcharts-react-official'
import { useMemo, useRef } from 'react'

import { zoomChart } from 'pages/analysis/store/analysis.history.slice'
import { selectAnalysisScatterplotSeriesComputationParams } from 'pages/analysis/store/selectors'
import { useDrawGates } from 'pages/analysis/useDrawGates'

import { useEventCallback } from 'shared/hooks/useEventCallback'
import { Graph } from 'shared/models/Graphs'
import { useAppDispatch, useAppSelector } from 'shared/store'

import { AnalysisScatterPlotBaseOptions } from './AnalysisScatterPlotBaseOptions'
import { HighPerformanceScatterPlot } from './HighPerformanceScatterPlot'

type AnalysisScatterPlotWithGatingProps = {
  chart: Graph
  gates: Graph.Gate[]
  gatingMode: Graph.GateTypes
  onAddDraftGate: (
    type: Graph.GateTypes,
    gateValues: Graph.GateValues,
    points?: Graph.Point[],
    pointsString?: string,
    isUpdatePolygonGate?: boolean,
  ) => void
}

export const AnalysisScatterPlotWithGating: React.FC<
  AnalysisScatterPlotWithGatingProps
> = ({ chart, gates, gatingMode, onAddDraftGate }) => {
  const dispatch = useAppDispatch()

  const computationsParams = useAppSelector(
    state => selectAnalysisScatterplotSeriesComputationParams(state)[chart.id],
  )
  if (!computationsParams.chart.scale) {
    throw new Error('Scale is not defined')
  }

  const polygonGatePoints = useRef<Graph.Point[]>([])
  const highchartsRef = useRef<HighchartsReact.RefObject | null>(null)

  const handleSelection = useEventCallback((e: SelectEventObject) => {
    if (gatingMode === 'rectangle') {
      const {
        xAxis: [xAxis],
        yAxis: [yAxis],
      } = e

      onAddDraftGate('rectangle', {
        xMin: xAxis.min,
        xMax: xAxis.max,
        yMin: yAxis.min,
        yMax: yAxis.max,
      })
      return false
    }

    dispatch(
      zoomChart({
        chartId: chart.id,
        zoom: {
          x_min: e.xAxis[0].min,
          x_max: e.xAxis[0].max,
          y_min: e.yAxis[0].min,
          y_max: e.yAxis[0].max,
        },
      }),
    )
    return false
  })

  const handleClick = useEventCallback(function (
    this: Highcharts.Chart,
    event: PointerEventObject,
  ) {
    if (!(gatingMode === 'polygon')) {
      return
    }
    const normalizedEvent = this.pointer.normalize(event)
    const point = {
      x: this.xAxis[0].toValue(normalizedEvent.chartX),
      y: this.yAxis[0].toValue(normalizedEvent.chartY),
    }

    if (polygonGatePoints.current.length === 0) {
      polygonGatePoints.current = [point]
      onAddDraftGate(
        'polygon',
        {
          xMin: point.x,
          xMax: point.x,
          yMin: point.y,
          yMax: point.y,
        },
        [],
        '',
        false,
      )
    } else {
      polygonGatePoints.current.push(point)
      const pointsX = polygonGatePoints.current.map(p => p.x)
      const pointsY = polygonGatePoints.current.map(p => p.y)
      const xMin = Math.min(...pointsX)
      const xMax = Math.max(...pointsX)
      const yMin = Math.min(...pointsY)
      const yMax = Math.max(...pointsY)
      onAddDraftGate(
        'polygon',
        {
          xMin: xMin,
          xMax: xMax,
          yMin: yMin,
          yMax: yMax,
        },
        polygonGatePoints.current,
        polygonGatePoints.current
          .map(
            point =>
              this.xAxis[0].toPixels(point.x, false) +
              ',' +
              this.yAxis[0].toPixels(point.y, false),
          )
          .join(' '),
        true,
      )
    }
  })

  const refreshGates = useDrawGates({
    chartRef: highchartsRef,
    gates,
    zoom: chart.zoom,
  })

  const handleRender = useEventCallback(function (this: Highcharts.Chart) {
    refreshGates()
  })

  const options = useMemo((): Highcharts.Options => {
    return {
      ...AnalysisScatterPlotBaseOptions,
      chart: {
        ...AnalysisScatterPlotBaseOptions.chart,
        events: {
          selection: handleSelection,
          click: handleClick,
          render: handleRender,
        },
      },
    }
  }, [handleClick, handleRender, handleSelection])

  return (
    <HighPerformanceScatterPlot
      ref={highchartsRef}
      chart={{
        ...computationsParams.chart,
        scale: computationsParams.chart.scale,
      }}
      options={options}
    />
  )
}
