import { Formik } from 'formik'
import { produce } from 'immer'
import { mapValues, xor } from 'lodash'
import { FC } from 'react'
import styled from 'styled-components'
import { array, object } from 'yup'

import { selectAnalysisId } from 'pages/analysis/store/selectors'
import { BrickAnalysisSelector } from 'pages/project/BrickAnalysisSelector'
import { WizardStepper } from 'pages/project/WizardStepper'

import { useLazyGetAnalysisByIdQuery } from 'shared/api/analysis.api'
import { useGenerateAnalysisPdfMutation } from 'shared/api/export.api'
import { BrickDetails } from 'shared/api/workflows.api'
import { useAppSelector } from 'shared/store'

import { ValidationLabel } from './ validation-label/ValidationLabel'
import { Checkbox } from './Checkbox'
import { Button } from './button/Button'
import { Modal } from './modal/Modal'
import { ModalContainer } from './modal/ModalContainer'

type PdfExportWizardProps = {
  brickById: Record<string, BrickDetails>
  onClose: () => void
}

type PdfExportWizardFormValues = {
  selectedAnalysisIds: string[]
  stepIndex: number
  selectedGraphicalElementIdsByAnalysisId: { [analysisId: string]: string[] }
}

const PdfExportFormSchema = object({
  selectedAnalysisIds: array().min(1, 'Select at least one analysis'),
})

export const PdfExportWizard: FC<PdfExportWizardProps> = ({
  brickById,
  onClose,
}) => {
  const analysisId = useAppSelector(selectAnalysisId)
  const [triggerGetAnalysisByIdQuery, analysisByIdQueryState] =
    useLazyGetAnalysisByIdQuery()

  const [triggerGenerateAnalysisPdf] = useGenerateAnalysisPdfMutation()

  const handleSubmit = ({
    selectedGraphicalElementIdsByAnalysisId,
  }: PdfExportWizardFormValues) => {
    triggerGenerateAnalysisPdf({
      selectedGraphicalElementIdsByAnalysisId,
    }).then(onClose)
  }

  return (
    <Modal
      open
      title="PDF export"
      Container={StyledModalContainer}
      onClose={onClose}
    >
      <Formik<PdfExportWizardFormValues>
        initialValues={{
          selectedAnalysisIds: analysisId ? [analysisId] : [],
          selectedGraphicalElementIdsByAnalysisId: {},
          stepIndex: 0,
        }}
        validationSchema={PdfExportFormSchema}
        onSubmit={handleSubmit}
      >
        {({ values, errors, isValid, setFieldValue, handleSubmit }) => {
          const error = (() => {
            if (values.stepIndex === 0) {
              return errors.selectedAnalysisIds
            }
          })()

          const handleNextStep = async () => {
            if (!isValid) {
              return
            }
            if (values.stepIndex === 0) {
              triggerGetAnalysisByIdQuery(values.selectedAnalysisIds)
                .unwrap()
                .then(analysisById => {
                  setFieldValue(
                    'selectedGraphicalElementIdsByAnalysisId',
                    mapValues(analysisById, analysis => [
                      ...analysis.graphs.map(graph => graph.id),
                      ...analysis.statistics.map(statistic => statistic.id),
                    ]),
                  )
                  setFieldValue('stepIndex', 1)
                })
            }
            if (values.stepIndex === 1) {
              handleSubmit()
            }
          }

          const analysisById = analysisByIdQueryState.data

          return (
            <>
              <StyledWizardStepper
                steps={['Select analyses', 'Select graphical elements']}
                currentStepIndex={values.stepIndex}
              />
              {error && <ValidationLabel>{error}</ValidationLabel>}
              {values.stepIndex === 0 && (
                <BrickAnalysisSelector
                  selectedAnalysisIds={values.selectedAnalysisIds}
                  brickById={brickById}
                  validationAction={'view-analysis'}
                  onChange={selectedAnalysisIds =>
                    setFieldValue('selectedAnalysisIds', selectedAnalysisIds)
                  }
                />
              )}
              {values.stepIndex === 1 && analysisById && (
                <GraphicalElements>
                  {Object.values(analysisById).map(analysis => {
                    return (
                      <div>
                        <h1>{analysis.name}</h1>
                        <div>
                          {analysis.graphs.map(graph => (
                            <Checkbox
                              key={graph.id}
                              label={graph.name}
                              checked={values.selectedGraphicalElementIdsByAnalysisId[
                                analysis.id
                              ].includes(graph.id)}
                              onChange={() => {
                                setFieldValue(
                                  'selectedGraphicalElementIdsByAnalysisId',
                                  produce(
                                    values.selectedGraphicalElementIdsByAnalysisId,
                                    draft => {
                                      draft[analysis.id] = xor(
                                        draft[analysis.id],
                                        [graph.id],
                                      )
                                    },
                                  ),
                                )
                              }}
                            />
                          ))}
                          {analysis.statistics.map(statistic => (
                            <Checkbox
                              key={statistic.id}
                              label={statistic.name}
                              checked={values.selectedGraphicalElementIdsByAnalysisId[
                                analysis.id
                              ].includes(statistic.id)}
                              onChange={() => {
                                setFieldValue(
                                  'selectedGraphicalElementIdsByAnalysisId',
                                  produce(
                                    values.selectedGraphicalElementIdsByAnalysisId,
                                    draft => {
                                      draft[analysis.id] = xor(
                                        draft[analysis.id],
                                        [statistic.id],
                                      )
                                    },
                                  ),
                                )
                              }}
                            />
                          ))}
                        </div>
                      </div>
                    )
                  })}
                </GraphicalElements>
              )}
              <Actions>
                <Button
                  onClick={handleNextStep}
                  loading={analysisByIdQueryState.isFetching}
                >
                  Next
                </Button>
              </Actions>
            </>
          )
        }}
      </Formik>
    </Modal>
  )
}

const StyledModalContainer = styled(ModalContainer)`
  min-width: 600px;
  max-height: 90vh;
  align-items: stretch;
`

const StyledWizardStepper = styled(WizardStepper)`
  margin-bottom: ${({ theme }) => theme.spacing(3)}px;
`

const Actions = styled.div`
  margin-top: ${({ theme }) => theme.spacing(2)}px;
  align-self: end;
`

const GraphicalElements = styled.div`
  overflow-y: auto;
  padding-left: 12px;
`
