import { skipToken } from '@reduxjs/toolkit/query'
import { FC } from 'react'
import { useParams } from 'react-router'
import { useBeforeUnload, useUnmount } from 'react-use'
import styled from 'styled-components'

import { LoadingMask } from 'components/LoadingMask'

import { analysisApi, useGetAnalysisQuery } from 'shared/api/analysis.api'
import { LogContextProvider } from 'shared/contexts/LogContextProvider'
import { useMultiTab } from 'shared/hooks/useMultiTab'
import { useAppDispatch, useAppSelector } from 'shared/store'
import { getIsSecondaryTab } from 'shared/utils/multi-tab'

import { AnalysisHeader } from './AnalysisHeader'
import { AnalysisLayout } from './AnalysisLayout'
import { AnalysisToolbar } from './AnalysisToolbar'
import { unloadAnalysis } from './store/analysis.slice'
import {
  selectAnalysisIdOrUndefined,
  selectAnalysisLoadingStatus,
  selectIsAnalysisSaved,
} from './store/selectors'

type AnalysisPageParams = {
  experimentId: string
  analysisId: string
}

export const AnalysisPage: FC = () => {
  const { analysisId } = useParams<AnalysisPageParams>()
  const dispatch = useAppDispatch()
  const analysisLoadingStatus = useAppSelector(selectAnalysisLoadingStatus)
  const previousAnalysisId = useAppSelector(selectAnalysisIdOrUndefined)
  const isAnalysisSaved = useAppSelector(selectIsAnalysisSaved)

  const isSecondaryTab = getIsSecondaryTab()

  useBeforeUnload(!isAnalysisSaved)
  useUnmount(() => {
    setTimeout(() => {
      dispatch(unloadAnalysis())
      dispatch(analysisApi.util.invalidateTags(['Analysis']))
    })
  })

  useGetAnalysisQuery(getIsSecondaryTab() ? skipToken : analysisId, {
    refetchOnMountOrArgChange: true,
  })

  const { isSecondaryTabInitialized } = useMultiTab()

  if (
    analysisLoadingStatus !== 'success' ||
    analysisId !== previousAnalysisId ||
    (isSecondaryTab && !isSecondaryTabInitialized)
  ) {
    return <LoadingMask isFirstLoad />
  }

  return (
    <AnalysisPageRoot>
      <LogContextProvider analysisId={analysisId}>
        <AnalysisHeader isSecondaryTab={isSecondaryTab} />
        {isSecondaryTab || <AnalysisToolbar />}
        <AnalysisLayout />
      </LogContextProvider>
    </AnalysisPageRoot>
  )
}

const AnalysisPageRoot = styled.div`
  padding-top: ${props => props.theme.layout.headerHeight}px;
  padding-bottom: ${props => props.theme.layout.graphMargin * 2}px;
  margin: 0 auto;
  width: 100%;
`
