import { computeGatePercentage } from 'pages/analysis/computeGatePercentage'
import {
  ScatterPlotChartScale,
  ScatterPlotScaleType,
} from 'pages/analysis/store/selectors'

import { SeriesEvents } from './SeriesEvents'
import {
  downsampleScatterData,
  groupScatterEventsIntoGrid,
} from './downsampleScatterData'

export type ComputeScatterPlotSeriesIdsByPixelPositionProps = {
  plotSize: {
    width: number
    height: number
  }
  chart: {
    xAxis: string
    yAxis: string
    scale: ScatterPlotChartScale
    scaleType: ScatterPlotScaleType
    activeLeafIds: string[]
    activeClusterIds: string[]
    hiddenClusterIds: string[]
    eventLimit: number
  }
}

type ComputeScatterPlotSeriesIdsByPixelPositionResult = Map<string, string>

type ComputeGatePercentagesProps = {
  chart: {
    xAxis: string
    yAxis: string
    activeLeafIds: string[]
    activeClusterIds: string[]
    hiddenClusterIds: string[]
    eventLimit: number
  }
  gates: Graph.Gate[]
}

export type KDTree = {
  point: {
    position: [number, number]
    seriesId: string
  }
  left: KDTree | undefined
  right: KDTree | undefined
}

export class ScatterPlotSeries {
  private seriesEvents: SeriesEvents

  constructor(seriesEvents: SeriesEvents) {
    this.seriesEvents = seriesEvents
  }

  public async computeScatterPlotSeriesIdsByPixelPosition({
    chart,
    plotSize,
  }: ComputeScatterPlotSeriesIdsByPixelPositionProps): Promise<ComputeScatterPlotSeriesIdsByPixelPositionResult> {
    const activeHiddenClusters = chart.hiddenClusterIds.filter(id =>
      chart.activeClusterIds.includes(id),
    )

    const eventsByActiveLeafId = await this.seriesEvents.computeEventsByLeafId({
      xAxis: chart.xAxis,
      yAxis: chart.yAxis,
      leafIds: chart.activeLeafIds,
      hiddenClustersIds: activeHiddenClusters,
      eventLimit: chart.eventLimit,
    })

    const seriesIdByPixelPosition = downsampleScatterData({
      eventsBySeriesIdByPixelPosition: groupScatterEventsIntoGrid({
        eventsBySeriesId: eventsByActiveLeafId,
        scale: chart.scale,
        scaleType: chart.scaleType,
        plotWidth: plotSize.width,
        plotHeight: plotSize.height,
      }),
      downsampledRatioBySeriesId:
        await this.seriesEvents.computeDownsampledRatioByLeafIds(
          chart.activeLeafIds,
        ),
    })

    return new Map(
      [...seriesIdByPixelPosition.entries()].map(
        ([pixelPosition, mostPrevalentSeriesId]) => [
          pixelPosition,
          mostPrevalentSeriesId,
        ],
      ),
    )
  }

  public computeKDTree(seriesIdByPoint: Map<string, string>): KDTree {
    const buildKDTree = (points: [number, number][], axisIndex = 0) => {
      if (points.length === 0) {
        return undefined
      }

      points.sort(function (a, b): number {
        return a[axisIndex] - b[axisIndex]
      })

      const nextAxisIndex = (axisIndex + 1) % 2
      const middleIndex = Math.floor(points.length / 2)
      const middlePoint = points[middleIndex]
      const middlePointSeriesId = seriesIdByPoint.get(
        `${middlePoint[0]},${middlePoint[1]}`,
      )
      if (!middlePointSeriesId) {
        throw new Error('middlePointSeriesId is undefined')
      }

      return {
        point: {
          position: middlePoint,
          seriesId: middlePointSeriesId,
        },
        left: buildKDTree(points.slice(0, middleIndex), nextAxisIndex),
        right: buildKDTree(points.slice(middleIndex + 1), nextAxisIndex),
      }
    }

    return buildKDTree(
      [...seriesIdByPoint.keys()].map(
        key => key.split(',').map(Number) as [number, number],
      ),
    )
  }

  public async computeGatePercentages({
    chart,
    gates,
  }: ComputeGatePercentagesProps): Promise<Graph.Gate[]> {
    if (gates.length === 0) {
      return []
    }
    const activeHiddenClusters = chart.hiddenClusterIds.filter(id =>
      chart.activeClusterIds.includes(id),
    )
    const eventsByActiveLeafId = await this.seriesEvents.computeEventsByLeafId({
      xAxis: chart.xAxis,
      yAxis: chart.yAxis,
      leafIds: chart.activeLeafIds,
      hiddenClustersIds: activeHiddenClusters,
      eventLimit: chart.eventLimit,
    })

    const events = Object.values(eventsByActiveLeafId)
      .flat()
      .map(event => [event.x, event.y] as [number, number])
    return gates.map(gate => {
      return {
        ...gate,
        percentage: computeGatePercentage(gate, events),
      }
    })
  }
}
