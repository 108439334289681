import styled from 'styled-components'

export const ListMenuItem = styled.div`
  padding: ${props => props.theme.spacing(2)}px;
  font-size: ${props => props.theme.font.size.smallest}px;
  cursor: pointer;

  &:not(:last-child) {
    border-bottom: 1px solid ${props => props.theme.colors.greyscale[10]};
  }

  &:hover {
    background: ${props => props.theme.colors.background};
  }
`
