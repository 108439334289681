import { IconButton } from '@material-ui/core'
import { Delete } from '@material-ui/icons'
import { useField } from 'formik'
import { isEqual } from 'lodash'
import React from 'react'
import styled from 'styled-components'

import { AutocompleteInput } from 'components/AutocompleteInput'
import Select from 'components/forms/Select'
import { TextField } from 'components/text-field/TextField'

import { STATISTIC_TYPE_LABELS } from 'shared/constants'
import { useEventCallback } from 'shared/hooks/useEventCallback'

import {
  ClusterReportableOption,
  ReportableOption,
} from './AnalysisStatisticsWizardReportable'

type AnalysisStatisticsWizardReportableStatisticProps = {
  path: string
  availableOptions: {
    cluster: ClusterReportableOption[]
    lasso: ReportableOption[]
    gate: ReportableOption[]
    channel: ReportableOption[]
  }
  onDelete: () => void
}

export const AnalysisStatisticsWizardReportableStatistic: React.FC<
  AnalysisStatisticsWizardReportableStatisticProps
> = ({ path, availableOptions, onDelete }) => {
  const [typeField, , { setValue: setType }] =
    useField<Analysis.ReportableStatisticType>(`${path}.type`)
  const [, , { setValue: setParameter }] =
    useField<Analysis.ReportableStatisticParameter>(`${path}.parameter`)
  const [parameterTypeField, , { setValue: setParameterType }] =
    useField<Analysis.ReportableStatisticParameterType>(
      `${path}.parameter.type`,
    )
  const [parameterIdField, , { setValue: setParameterId }] = useField<
    string | undefined
  >(`${path}.parameter.id`)
  const [parameterIdsField, , { setValue: setParameterIds }] = useField<
    string[] | undefined
  >(`${path}.parameter.ids`)
  const [parameterTotBeadsCountField] = useField<number | undefined>(
    `${path}.parameter.tot_beads_count`,
  )

  const handleTypeChange = useEventCallback(
    (event: { target: { value: unknown } }) => {
      const type = event.target.value as Analysis.ReportableStatistic['type']
      setType(type)
      if (type === 'percentage-of') {
        setParameterType('cluster')
        setParameterIds(availableOptions.cluster[0].value)
        setParameterId(undefined)
      } else if (type === 'count' || type === 'cellular-concentration') {
        setParameter(undefined)
      } else if (type === 'absolute-count') {
        setParameterType('cluster')
        setParameterIds(availableOptions.cluster[0].value)
      } else {
        setParameterType('channel')
        setParameterId(availableOptions.channel[0].value)
        setParameterIds(undefined)
      }
    },
  )

  const handleParameterTypeChange = useEventCallback(
    (event: { target: { value: unknown } }) => {
      const type = event.target
        .value as Analysis.ReportableStatisticParameterType
      setParameterType(type)
      if (type === 'cluster') {
        setParameterIds(availableOptions.cluster[0].value)
        setParameterId(undefined)
      } else {
        setParameterId(availableOptions[type][0].value)
        setParameterIds(undefined)
      }
    },
  )

  const handleParameterIdChange = useEventCallback(
    (_event, option: ReportableOption) => {
      setParameterId(option.value)
      setParameterIds(undefined)
    },
  )

  const handleParameterIdsChange = useEventCallback(
    (_event, option: ClusterReportableOption) => {
      setParameterIds(option.value)
      setParameterId(undefined)
    },
  )

  return (
    <AnalysisStatisticsWizardReportableStatisticRoot
      $statisticsType={typeField.value}
    >
      <StyledSelect
        name={typeField.name}
        value={typeField.value}
        label="Type"
        options={Object.entries(STATISTIC_TYPE_LABELS).map(
          ([value, label]) => ({
            value,
            label,
          }),
        )}
        shouldReserveSpaceForError={false}
        onChange={handleTypeChange}
        onBlur={typeField.onBlur}
      />
      {(typeField.value === 'percentage-of' ||
        typeField.value === 'absolute-count') && (
        <StyledSelect
          name={parameterTypeField.name}
          value={parameterTypeField.value}
          label="Parameter"
          options={[
            { value: 'cluster', label: 'Cluster' },
            {
              value: 'lasso',
              label: 'Lasso',
              disabled: availableOptions.lasso.length === 0,
            },
            {
              value: 'gate',
              label: 'Gate',
              disabled: availableOptions.gate.length === 0,
            },
          ]}
          shouldReserveSpaceForError={false}
          onChange={handleParameterTypeChange}
          onBlur={parameterTypeField.onBlur}
        />
      )}
      {typeField.value !== 'count' &&
        typeField.value !== 'cellular-concentration' &&
        (parameterTypeField.value === 'cluster' ? (
          <AutocompleteInput
            name={parameterIdsField.name}
            value={availableOptions[parameterTypeField.value].find(
              optionClusterIds =>
                isEqual(optionClusterIds.value, parameterIdsField.value),
            )}
            label="Label"
            options={availableOptions.cluster}
            getOptionLabel={option => option.label}
            disableClearable={true}
            onChange={handleParameterIdsChange}
            onBlur={parameterIdsField.onBlur}
          />
        ) : (
          <AutocompleteInput
            name={parameterIdField.name}
            label={parameterTypeField.value === 'channel' ? 'Channel' : 'ID'}
            options={availableOptions[parameterTypeField.value]}
            getOptionLabel={option => option.label}
            value={availableOptions[parameterTypeField.value].find(
              o => o.value === parameterIdField.value,
            )}
            disableClearable={true}
            onChange={handleParameterIdChange}
            onBlur={parameterIdField.onBlur}
          />
        ))}
      {typeField.value === 'absolute-count' && (
        <TextField
          name={parameterTotBeadsCountField.name}
          label="Total bead count"
          type="number"
          value={parameterTotBeadsCountField.value}
          onChange={parameterTotBeadsCountField.onChange}
          onBlur={parameterTotBeadsCountField.onBlur}
        />
      )}
      <StyledIconButton onClick={onDelete}>
        <Delete />
      </StyledIconButton>
    </AnalysisStatisticsWizardReportableStatisticRoot>
  )
}

const AnalysisStatisticsWizardReportableStatisticRoot = styled.div<{
  $statisticsType: Analysis.ReportableStatisticType
}>`
  border: 1px solid ${props => props.theme.colors.greyscale[10]};
  background: ${props => props.theme.colors.white};
  box-shadow: rgba(0, 0, 0, 0.1) 0 1px 4px;
  border-radius: ${props => props.theme.radius[2]}px;
  padding: 14px 14px 0;
  padding: 0px 6px;
  display: grid;
  gap: 8px;
  grid-template-columns: ${props => {
    if (
      props.$statisticsType === 'count' ||
      props.$statisticsType === 'cellular-concentration'
    ) {
      return 'auto 1fr'
    }
    if (props.$statisticsType === 'percentage-of') {
      return '150px 100px 1fr auto'
    }
    if (props.$statisticsType === 'absolute-count') {
      return '150px 100px 1fr 200px auto'
    }
    return '150px 1fr auto'
  }};
`

const StyledSelect = styled(Select)`
  padding: 0;

  label {
    font-size: 12px;
  }

  .MuiInputBase-root {
    padding: 0 10px;
    font-size: 13px;
    min-height: 26px;
    height: min-content;
  }
`

const StyledIconButton = styled(IconButton)`
  width: 20px;
  height: 20px;
  color: ${props => props.theme.colors.primaryDark['100']};
  align-self: center;
  justify-self: right;

  svg {
    width: 16px;
    height: 16px;
  }
`
