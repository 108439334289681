import { Formik, FormikConfig } from 'formik'
import React, { useCallback, useEffect } from 'react'
import { useParams, useHistory } from 'react-router-dom'
import styled from 'styled-components'
import { object, string } from 'yup'

import { ReactComponent as ArrowRight } from 'assets/images/icons/arrowRight.svg'

import { Button } from 'components/Button'
import { LoggedOutLayout } from 'components/LoggedOutLayout'
import Input from 'components/forms/Input'
import PasswordStrengthMeter from 'components/forms/PasswordStrengthMeter'

import { useChangePasswordMutation } from 'shared/api/auth.api'
import { useServerFormValidation } from 'shared/hooks/useServerFormValidation'
import { handleError } from 'shared/utils/errorHandler'

type NewPasswordFormValues = { password: string; passwordConfirmation: string }

const initialValues: NewPasswordFormValues = {
  password: '',
  passwordConfirmation: '',
}

const NewPasswordFormSchema = object({
  password: string()
    .required()
    .min(8, 'Password must be at least 8 characters')
    .matches(/[a-z]/, 'Password must contain at least one lowercase letter')
    .matches(/[A-Z]/, 'Password must contain at least one uppercase letter')
    .matches(/[0-9]/, 'Password must contain at least one number')
    .matches(
      /[^a-zA-Z0-9]/,
      'Password must contain at least one special character',
    ),
  passwordConfirmation: string()
    .required()
    .test('passwords-match', 'Passwords must match', function (value, context) {
      return value === context.parent.password
    }),
})

export const NewPasswordPage = (): JSX.Element => {
  const history = useHistory()
  const { token } = useParams<{ token: string }>()
  const [changePassword, changePasswordResult] = useChangePasswordMutation()

  const { globalError, getFieldRequestError, setSubmittedValues } =
    useServerFormValidation({
      error: changePasswordResult.error,
      fields: Object.keys(initialValues) as (keyof NewPasswordFormValues)[],
    })

  const handleSubmit: FormikConfig<NewPasswordFormValues>['onSubmit'] =
    useCallback(
      async (values, { setSubmitting, resetForm }) => {
        try {
          await changePassword({ password: values.password, token })
          resetForm()
        } catch (error) {
          handleError(error)
        } finally {
          setSubmittedValues(values)
          setSubmitting(false)
        }
      },
      [changePassword, setSubmittedValues, token],
    )

  useEffect(() => {
    if (changePasswordResult.isSuccess) {
      history.push('/log-in', { isChangePasswordSuccess: true })
    }
  }, [changePasswordResult.isSuccess, history])

  return (
    <LoggedOutLayout>
      <NewPasswordPageRoot>
        <Formik<NewPasswordFormValues>
          initialValues={initialValues}
          validationSchema={NewPasswordFormSchema}
          onSubmit={handleSubmit}
        >
          {({
            values,
            touched,
            errors,
            isSubmitting,
            isValid,
            handleChange,
            handleBlur,
            handleSubmit,
          }) => (
            <form onSubmit={handleSubmit}>
              <Title>Please type your new password</Title>
              <Input
                id="password"
                name="password"
                value={values.password}
                placeholder="New password"
                error={
                  touched.password
                    ? getFieldRequestError('password', values.password) ||
                      errors.password
                    : undefined
                }
                onChange={handleChange}
                onBlur={handleBlur}
                type="password"
              />
              <PasswordStrengthMeter password={values.password} />
              <Input
                id="passwordConfirmation"
                name="passwordConfirmation"
                value={values.passwordConfirmation}
                placeholder="Confirm password"
                error={
                  touched.passwordConfirmation
                    ? getFieldRequestError(
                        'password',
                        values.passwordConfirmation,
                      ) ||
                      globalError ||
                      errors.passwordConfirmation
                    : undefined
                }
                onChange={handleChange}
                onBlur={handleBlur}
                type="password"
              />
              {changePasswordResult.error && (
                <ErrorMessage>{globalError}</ErrorMessage>
              )}
              <Buttons>
                <StyledButton
                  type="submit"
                  variant="contained"
                  color="primary"
                  endIcon={<ArrowRight />}
                  disabled={isSubmitting || !isValid}
                >
                  Submit
                </StyledButton>
                <Button
                  type="button"
                  grey
                  onClick={() => history.push('/log-in')}
                >
                  Back
                </Button>
              </Buttons>
            </form>
          )}
        </Formik>
      </NewPasswordPageRoot>
    </LoggedOutLayout>
  )
}

const NewPasswordPageRoot = styled.div`
  background: ${props => props.theme.colors.white};
  padding: ${props => props.theme.spacing(4, 8)};
  border-radius: ${props => props.theme.radius[2]}px;
  box-shadow: ${props => props.theme.shadow[2]};
  width: 100%;
`

const Title = styled.p`
  margin-bottom: ${props => props.theme.spacing(4)}px;
  text-align: center;
  font-family: ${props => props.theme.font.style.bold};
`

const ErrorMessage = styled.div`
  color: ${props => props.theme.colors.error};
  margin-bottom: ${props => props.theme.spacing(2)}px;
`

const Buttons = styled.div`
  display: flex;
  justify-content: flex-end;
  gap: ${props => props.theme.spacing(2)}px;
`

const StyledButton = styled(Button)`
  margin-left: auto;
  height: 50px;
  width: 140px;
  display: flex;
  padding: 0 25px;
  justify-content: space-between;
`
