import { createContext, useContext } from 'react'

type EnsureConditionIsMetBeforeActionProps = {
  condition: boolean
  title: string
  message: string
  performAction: () => void
  performFix: () => boolean | Promise<boolean>
}

export type DialogContextValue = {
  showDialog: (dialog: DialogFactory) => void
  showConfirmationDialog: (props: {
    title: string
    message: string
    onConfirm: () => void
  }) => void
  ensureConditionIsMetBeforeAction: (
    props: EnsureConditionIsMetBeforeActionProps,
  ) => void
  showOptionDialog: (props: {
    title: string
    message: string
    options: { label: string; onClick: () => void }[]
  }) => void
}

export type DialogFactory = (closeDialog: () => void) => React.ReactNode

export const DialogContext = createContext<DialogContextValue | undefined>(
  undefined,
)

export const useDialog = (): DialogContextValue => {
  const dialogContext = useContext(DialogContext)
  if (dialogContext === undefined) {
    throw new Error('useDialog must be used within a DialogContextProvider')
  }

  return dialogContext
}
