import {
  createAction,
  createSlice,
  PayloadAction,
  Reducer,
  UnknownAction,
} from '@reduxjs/toolkit'
import undoable, { StateWithHistory } from 'redux-undo'

import { Layout, LayoutElement } from 'components/chart-grid'

import { metaAnalysisApi } from 'shared/api/meta-analysis.api'
import { unpackError } from 'shared/api/utils'
import { SliceStatus } from 'shared/store'

import {
  historySlice,
  HistoryState,
  INITIAL_HISTORY_STATE,
} from './meta-analysis.history.slice'

export * from './meta-analysis.history.slice'

export type MetaAnalysisSliceState = {
  status: SliceStatus
  error?: string
  history: StateWithHistory<HistoryState>
  shouldPreventStateUpdate?: boolean
}

const initialState: MetaAnalysisSliceState = {
  status: 'idle',
  history: {
    past: [],
    present: INITIAL_HISTORY_STATE,
    future: [],
  },
}

export const metaAnalysisSlice = createSlice({
  name: 'meta-analysis',
  initialState,
  reducers: {
    updateLayout: (state, { payload: layout }: PayloadAction<Layout>) => {
      if (state.history.present.metaAnalysis) {
        state.history.present.metaAnalysis.layout = layout
      }
    },
    updateSpecificLayout: (
      state,
      {
        payload: { layoutKey, layout },
      }: PayloadAction<{
        layoutKey: keyof Layout
        layout: LayoutElement
      }>,
    ) => {
      if (state.history.present.metaAnalysis) {
        state.history.present.metaAnalysis.layout[layoutKey] = layout
      }
    },
    unloadMetaAnalysis: () => initialState,
    preventStateUpdate: state => {
      state.shouldPreventStateUpdate = true
    },
  },
  extraReducers: builder => {
    builder.addMatcher(
      metaAnalysisApi.endpoints.getMetaAnalysis.matchPending,
      state => {
        if (state.shouldPreventStateUpdate) {
          return
        }
        state.status = 'loading'
        state.error = undefined
      },
    )
    builder.addMatcher(
      metaAnalysisApi.endpoints.getMetaAnalysis.matchFulfilled,
      (state, { payload }) => {
        if (state.shouldPreventStateUpdate) {
          state.shouldPreventStateUpdate = false
        }
        state.status = 'success'
        state.error = undefined
        state.history = {
          past: [],
          present: {
            isMetaAnalysisSaved: true,
            isMetaAnalysisBeingSaved: false,
            metaAnalysis: payload.metaAnalysis,
            metaAnalysisFile: payload.metaAnalysisFile,
            metaAnalysisGlobalVizFile: payload.metaAnalysisGlobalVizFile,
            metaAnalysisVolcanoPlotFile: payload.metaAnalysisVolcanoPlotFile,
            compensatedFileById: payload.compensatedFileById,
            fcsFileById: payload.fcsFileById,
            workflow: payload.workflow,
            project: payload.project,
            logs: [],
          },
          future: [],
        }
      },
    )
    builder.addMatcher(
      metaAnalysisApi.endpoints.getMetaAnalysis.matchRejected,
      (state, { payload }) => {
        if (state.shouldPreventStateUpdate) {
          state.shouldPreventStateUpdate = false
        }
        state.status = 'error'
        state.error = payload
          ? JSON.stringify(unpackError(payload).data)
          : 'unknown error'
        state.history = initialState.history
      },
    ),
      builder.addMatcher(
        metaAnalysisApi.endpoints.saveMetaAnalysis.matchPending,
        state => {
          state.history.present.isMetaAnalysisBeingSaved = true
        },
      ),
      builder.addMatcher(
        metaAnalysisApi.endpoints.saveMetaAnalysis.matchFulfilled,
        (state, { payload }) => {
          state.history.present.isMetaAnalysisSaved = true
          state.history.present.isMetaAnalysisBeingSaved = false
          state.history.present.metaAnalysis = payload
        },
      )
  },
})

export const { updateLayout, updateSpecificLayout, unloadMetaAnalysis } =
  metaAnalysisSlice.actions

export const metaAnalysisSliceReducer: Reducer<
  MetaAnalysisSliceState,
  UnknownAction
> = (state = initialState, action: UnknownAction): MetaAnalysisSliceState => {
  if (action.type.startsWith('meta-analysis/modify/')) {
    return {
      ...state,
      history: historyReducer(state.history, {
        ...action,
        wrappedMetaAnalysisSliceState: {
          metaAnalysisPage: { metaAnalysis: state },
        },
      }),
    }
  }

  return metaAnalysisSlice.reducer(state, action)
}

export const UNDO_ACTION_TYPE = 'meta-analysis/modify/undo'
export const REDO_ACTION_TYPE = 'meta-analysis/modify/redo'

export const metaAnalysisUndo = createAction(UNDO_ACTION_TYPE)
export const metaAnalysisRedo = createAction(REDO_ACTION_TYPE)

const historyReducer = undoable(historySlice.reducer, {
  undoType: UNDO_ACTION_TYPE,
  redoType: REDO_ACTION_TYPE,
})
