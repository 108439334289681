import { createSlice, PayloadAction } from '@reduxjs/toolkit'

import { RootState } from 'shared/store'

interface SelectedGraphicalElementsState {
  selectedGraphicalElements: string[]
  clusterListConnectedChartId: string | undefined
}

const initialState: SelectedGraphicalElementsState = {
  selectedGraphicalElements: [],
  clusterListConnectedChartId: undefined,
}

export const selectedGraphicalElementsSlice = createSlice({
  name: 'selected-graphicalElements',
  initialState,
  reducers: {
    toggleAnalysisGraphicalElementSelection: (
      state,
      action: PayloadAction<string>,
    ) => {
      const id = action.payload

      if (state.selectedGraphicalElements.includes(id)) {
        state.selectedGraphicalElements =
          state.selectedGraphicalElements.filter(elementId => elementId !== id)
      } else {
        state.selectedGraphicalElements = [
          ...state.selectedGraphicalElements,
          id,
        ]
      }
    },
    resetGraphicalElementSelections: state => {
      state.selectedGraphicalElements = []
    },
    removeGraphicalElementFromSelection: (
      state,
      action: PayloadAction<{ id: string }>,
    ) => {
      const { id } = action.payload

      state.selectedGraphicalElements = state.selectedGraphicalElements.filter(
        elementId => elementId !== id,
      )
    },
    setSelectedGraphicalElements: (state, action: PayloadAction<string[]>) => {
      state.selectedGraphicalElements = action.payload
    },
    setClusterListConnectedChartId: (
      state,
      action: PayloadAction<string | undefined>,
    ) => {
      state.clusterListConnectedChartId = action.payload
    },
  },
})

export const {
  toggleAnalysisGraphicalElementSelection,
  resetGraphicalElementSelections,
  removeGraphicalElementFromSelection,
  setSelectedGraphicalElements,
  setClusterListConnectedChartId,
} = selectedGraphicalElementsSlice.actions

export const selectAnalysisSelectedGraphicalElementIds = (
  state: RootState,
): string[] =>
  state.analysisPage.selectedGraphicalElements.selectedGraphicalElements
