import { Tooltip } from '@material-ui/core'
import React, { useState } from 'react'
import styled from 'styled-components'

import { Button } from 'components/Button'
import { Checkbox, CheckboxProps } from 'components/Checkbox'
import Select from 'components/forms/Select'

import { useAppSelector } from 'shared/store'

import {
  selectMetaAnalysisChannels,
  selectMetaAnalysisClusters,
  selectMetaAnalysisFcsFileIds,
  selectMetaAnalysisFcsFileNameById,
} from './store/selectors'

type MetaAnalysisSpiderwebPlotOptionsProps = {
  mode: 'edit' | 'create'
  initialSelectedFileIds: string[]
  initialSelectedCluster: string
  initialSelectedChannels: string[]
  onCancel?: () => void
  onFinish: (fileIds: string[], cluster: string, channels: string[]) => void
  className?: string
}

export const MetaAnalysisSpiderwebPlotOptions = ({
  mode,
  initialSelectedFileIds,
  initialSelectedCluster,
  initialSelectedChannels,
  onCancel,
  onFinish,
  className,
}: MetaAnalysisSpiderwebPlotOptionsProps): JSX.Element => {
  const fileIds = useAppSelector(selectMetaAnalysisFcsFileIds)
  const fileNameById = useAppSelector(selectMetaAnalysisFcsFileNameById)
  const clusters = useAppSelector(selectMetaAnalysisClusters)
  const channels = useAppSelector(selectMetaAnalysisChannels)

  const [selectedFileIds, setSelectedFileIds] = useState(initialSelectedFileIds)
  const [selectedCluster, setSelectedCluster] = useState(initialSelectedCluster)
  const [selectedChannels, setSelectedChannels] = useState(
    initialSelectedChannels,
  )

  const areAllChannelsSelected =
    selectedChannels === channels && channels.length > 0
  const areAllFilesSelected =
    selectedFileIds.length === fileIds.length && fileIds.length > 0

  const handleSelectFile = (selectedFileId: string) => {
    if (selectedFileIds.includes(selectedFileId)) {
      setSelectedFileIds(
        selectedFileIds.filter(fileId => fileId !== selectedFileId),
      )
    } else {
      setSelectedFileIds([...selectedFileIds, selectedFileId])
    }
  }

  const handleSelectChannel = (channel: string) => {
    if (selectedChannels.includes(channel)) {
      setSelectedChannels(selectedChannels.filter(c => c !== channel))
    } else {
      setSelectedChannels([...selectedChannels, channel])
    }
  }

  return (
    <MetaAnalysisSpiderwebPlotOptionsRoot className={className}>
      <Options>
        <Header>Files</Header>
        <AllCheckboxContainer>
          <Checkbox
            label="Select all files"
            checked={areAllFilesSelected}
            onChange={() =>
              areAllFilesSelected
                ? setSelectedFileIds([])
                : setSelectedFileIds(fileIds)
            }
          />
        </AllCheckboxContainer>
        <Files>
          {fileIds.map(fileId => {
            const fileName = fileNameById[fileId]
            return (
              <StyledCheckbox
                key={fileId}
                label={
                  <Tooltip title={fileName}>
                    <Ellipsis>{fileName}</Ellipsis>
                  </Tooltip>
                }
                checked={selectedFileIds.includes(fileId)}
                onChange={() => handleSelectFile(fileId)}
              />
            )
          })}
        </Files>
        <Header>Cluster</Header>
        <Select
          value={selectedCluster}
          onChange={event => setSelectedCluster(event.target.value as string)}
          options={clusters.map(cluster => ({
            value: cluster,
            label: cluster,
          }))}
          shouldReserveSpaceForError={false}
        />
        <Header>Channel</Header>
        <AllCheckboxContainer>
          <Checkbox
            label="Select all channels"
            checked={areAllChannelsSelected}
            onChange={() =>
              areAllChannelsSelected
                ? setSelectedChannels([])
                : setSelectedChannels(channels)
            }
          />
        </AllCheckboxContainer>
        <Checkboxes>
          {channels.map(channel => {
            return (
              <StyledCheckbox
                key={channel}
                label={
                  <Tooltip title={channel}>
                    <Ellipsis>{channel}</Ellipsis>
                  </Tooltip>
                }
                checked={selectedChannels.includes(channel)}
                onChange={() => handleSelectChannel(channel)}
              />
            )
          })}
        </Checkboxes>
      </Options>
      <Buttons>
        <StyledButton
          onClick={() =>
            onFinish(selectedFileIds, selectedCluster, selectedChannels)
          }
        >
          {mode === 'edit' ? 'Apply' : 'Create'}
        </StyledButton>
        <StyledButton onClick={onCancel}>Cancel</StyledButton>
      </Buttons>
    </MetaAnalysisSpiderwebPlotOptionsRoot>
  )
}

const MetaAnalysisSpiderwebPlotOptionsRoot = styled.div`
  background: ${props => props.theme.colors.white};
  width: 100%;
  height: 100%;
  overflow: hidden;
  display: grid;
  grid-template-rows: 1fr auto;
`

const Checkboxes = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 2px;
  padding: 8px;
  padding-bottom: 16px;
`

const Files = styled(Checkboxes)`
  grid-template-columns: repeat(2, 1fr);
`

const StyledCheckbox = styled(({ className, ...props }: CheckboxProps) => (
  <Checkbox containerClassName={className} {...props} />
))`
  width: 100%;
  overflow: hidden;
`

const AllCheckboxContainer = styled.div`
  border-bottom: 1px solid ${props => props.theme.colors.primaryDark[20]};
  padding: ${props => props.theme.spacing(1)}px;
`

const Options = styled.div`
  overflow-y: auto;
  overflow-x: hidden;
  padding: 8px;
`

const Buttons = styled.div`
  border-top: 1px solid ${props => props.theme.colors.primaryDark[20]};
  padding: 8px;
  display: flex;
  flex-direction: row-reverse;
`

const StyledButton = styled(Button)`
  height: 16px;
  font-size: 12px;

  :first-of-type {
    margin-left: 8px;
  }
`

const Header = styled.p`
  font-family: ${props => props.theme.font.style.bold};
`

const Ellipsis = styled.div`
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`
