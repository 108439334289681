import { Backdrop, Modal as MuiModal } from '@material-ui/core'
import { Close } from '@material-ui/icons'
import { ComponentType, FC, ReactNode } from 'react'
import styled from 'styled-components'

import { ButtonWrapper } from 'components/button/ButtonWrapper'

import { theme } from 'Theme'

import { ModalContainer } from './ModalContainer'

type ModalProps = {
  open: boolean
  onClose: () => void
  title?: ReactNode
  className?: string
  keepMounted?: boolean
  disableBackdropClick?: boolean
  disableEscapeKeyDown?: boolean
  children?: ReactNode
  disablePortal?: boolean
  Container?: ComponentType<{ children: ReactNode }>
}

export const Modal: FC<ModalProps> = ({
  open,
  onClose,
  title,
  disableBackdropClick = true,
  disableEscapeKeyDown,
  keepMounted,
  children,
  className,
  disablePortal = true,
  Container = ModalContainer,
}) => {
  return (
    <StyledModal
      disablePortal={disablePortal}
      keepMounted={keepMounted}
      className={className}
      BackdropProps={{
        style: { backgroundColor: theme.colors.modal },
      }}
      open={open}
      onClose={(_event, reason) => {
        if (!(reason === 'backdropClick' && disableBackdropClick)) onClose()
      }}
      disableEscapeKeyDown={disableEscapeKeyDown}
      closeAfterTransition={true}
      BackdropComponent={Backdrop}
    >
      <Container>
        <Header>
          <Title>{title}</Title>
          <CloseButton onClick={onClose}>
            <Close />
          </CloseButton>
        </Header>
        {children}
      </Container>
    </StyledModal>
  )
}

const StyledModal = styled(MuiModal)`
  overflow: scroll;
  display: flex;
  padding: ${props => props.theme.layout.contentPadding}px;
  justify-content: center;
  align-items: start;
  & > *:focus {
    outline: none;
  }
`

const Header = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: start;
`

const Title = styled.div`
  font-family: ${props => props.theme.font.style.bold};
  font-size: ${props => props.theme.font.size.h2}px;
  width: 100%;
  text-align: center;
  margin-bottom: ${props => props.theme.spacing(4)}px;
`

const CloseButton = styled(ButtonWrapper)`
  margin-right: -20px;
  margin-top: -12px;

  & > .MuiSvgIcon-root {
    font-size: 20px;
  }
`
