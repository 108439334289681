import { IconButton, Tooltip } from '@material-ui/core'
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { useHistory, useParams } from 'react-router-dom'
import styled, { useTheme } from 'styled-components'

import { Icon, IconNames } from 'assets/images/icons/Icon'

import { ActionButton } from 'components/ActionButton'
import { Button } from 'components/Button'
import { PdfExportWizard } from 'components/PdfExportWizard'
import { Menu } from 'components/menu/Menu'
import { MenuItem } from 'components/menu/MenuItem'
import { NestedMenuItem } from 'components/menu/NestedMenuItem'

import { FcsFileHeadersModal } from 'pages/analysis/FcsFileHeadersModal'
import { WorkflowBrickSettingsModal } from 'pages/project/WorkflowBrickSettingsModal'

import {
  analysisApi,
  useTransferAnalysisMutation,
} from 'shared/api/analysis.api'
import {
  ExportTypes,
  useGenerateEventsExportMutation,
  useGenerateStatisticsExportMutation,
} from 'shared/api/export.api'
import { useGetUserQuery } from 'shared/api/user.api'
import { useDialog } from 'shared/contexts/DialogContext'
import { useLogActions } from 'shared/contexts/LogContext'
import { useModal } from 'shared/contexts/ModalContext'
import { generateAnalysisGraphicalElementsExport } from 'shared/export/analysis/generateAnalysisGraphicalElementsExport'
import { useAnalysisExitUrl, useSafeNavigate } from 'shared/hooks/router'
import { useEventCallback } from 'shared/hooks/useEventCallback'
import { enterMultiTabMode, exitMultiTabMode } from 'shared/hooks/useMultiTab'
import { useAppDispatch, useAppSelector } from 'shared/store'
import { showNotification } from 'shared/store/notification.slice'
import { getIsMultiTabMode } from 'shared/utils/multi-tab'
import { includeIf } from 'shared/utils/utils'

import { LogbookModal } from './Logbook/LogBookModal'
import { LogbookLoginModal } from './Logbook/LogbookLoginModal'
import { ProtectionModeButton } from './ProtectionModeButton'
import { SaveAnalysisButton } from './SaveAnalysisButton'
import { SignatureModal } from './Signature/SignatureModal'
import { redo, undo } from './store/analysis.slice'
import { selectAnalysisSelectedGraphicalElementIds } from './store/selected-graphical-elements.slice'
import {
  selectAnalysisAccessMode,
  selectAnalysisAuthorName,
  selectAnalysisBrick,
  selectAnalysisFcsFile,
  selectAnalysisLoadingStatus,
  selectAnalysisStatus,
  selectCanRedo,
  selectCanUndo,
  selectIsAnalysisApproved,
  selectIsAnalysisBeingSaved,
  selectIsAnalysisProtected,
  selectIsAnalysisSaved,
} from './store/selectors'
import { useAnalysisStatuses } from './useAnalysisStatuses'

type IconAction = {
  type: 'icon-action'
  iconName: IconNames
  onClick?: React.MouseEventHandler<HTMLButtonElement>
  disabled?: boolean
  tooltipLabel?: string
  colorOverride?: string
}

type ButtonAction = {
  id?: string
  type: 'button-action'
  label: string
  tooltip?: string
  startIconName: IconNames
  onClick?: React.MouseEventHandler<HTMLButtonElement>
  loading?: boolean
  disabled?: boolean
  ref?: React.RefObject<HTMLButtonElement>
  colorOverride?: string
}

type Separator = {
  type: 'separator'
}

type IAction = IconAction | ButtonAction | Separator

type AnalysisPageParams = Readonly<{
  experimentId: string
  analysisId: string
}>

export const AnalysisToolbar = (): JSX.Element => {
  const { ensureConditionIsMetBeforeAction, showConfirmationDialog } =
    useDialog()
  const { showModal } = useModal()
  const safeNavigate = useSafeNavigate()
  const analysisExitUrl = useAnalysisExitUrl()

  const user = useGetUserQuery()
  const [triggerGenerateEventsExport, generateEventsExportResult] =
    useGenerateEventsExportMutation()
  const [triggerTransferAnalysisMutation, transferAnalysisMutation] =
    useTransferAnalysisMutation()
  const [triggerGenerateStatisticsExport, generateStatisticsExportResult] =
    useGenerateStatisticsExportMutation()

  const { validateActionBasedOnAnalysisStatus } = useAnalysisStatuses()
  const history = useHistory()
  const isMultiTabMode = getIsMultiTabMode()
  const { analysisId } = useParams<AnalysisPageParams>()
  const { setCurrentSession, currentSession } = useLogActions()

  const theme = useTheme()

  const dispatch = useAppDispatch()
  const isAnalysisLoading =
    useAppSelector(selectAnalysisLoadingStatus) !== 'success'
  const analysisStatus = useAppSelector(selectAnalysisStatus)
  const isAnalysisSaved = useAppSelector(selectIsAnalysisSaved)
  const fcsFile = useAppSelector(selectAnalysisFcsFile)
  const canUndo = useAppSelector(selectCanUndo)
  const canRedo = useAppSelector(selectCanRedo)
  const selectedGraphicalElements = useAppSelector(
    selectAnalysisSelectedGraphicalElementIds,
  )
  const isAnalysisBeingSaved = useAppSelector(selectIsAnalysisBeingSaved)
  const analysisAuthorName = useAppSelector(selectAnalysisAuthorName)
  const isAnalysisApproved = useAppSelector(selectIsAnalysisApproved)
  const isAnalysisProtected = useAppSelector(selectIsAnalysisProtected)
  const brick = useAppSelector(selectAnalysisBrick)
  const analysisAccessMode = useAppSelector(selectAnalysisAccessMode)

  const exportButtonRef = React.useRef<HTMLButtonElement>(null)
  const [showExportMenu, setShowExportMenu] = useState(false)
  const [showFileInfoModal, setShowFileInfoModal] = useState(false)
  const [showLogbookLoginModal, setShowLogbookLoginModal] = useState(false)
  const [showLogbookModal, setShowLogbookModal] = useState(false)
  const [showSignatureModal, setShowSignatureModal] = useState(false)

  const canToggleProtectionMode = useMemo(() => {
    if (!user.data) {
      return
    }
    const { first_name, last_name, is_operator, is_expert } = user.data
    const userName = first_name + ' ' + last_name
    return userName === analysisAuthorName || is_operator || is_expert
  }, [analysisAuthorName, user.data])

  const isAnalysisReadOnlyAndHasChangesValidation = useMemo(() => {
    return (isAnalysisProtected || isAnalysisApproved) && !isAnalysisSaved
      ? 'You have unsaved changes and the analysis is in read-only mode'
      : undefined
  }, [isAnalysisProtected, isAnalysisApproved, isAnalysisSaved])

  const onLogbookClick = useCallback(
    function onLogbookClick() {
      if (currentSession === undefined) {
        setShowLogbookLoginModal(true)
      } else if (currentSession.analysis === analysisId) {
        setShowLogbookModal(true)
      } else {
        setCurrentSession(undefined)
        setShowLogbookLoginModal(true)
      }
    },
    [analysisId, currentSession, setCurrentSession],
  )

  const performFixSaveAnalysis = useEventCallback(async () => {
    try {
      await dispatch(analysisApi.endpoints.saveAnalysis.initiate()).unwrap()
      return true
    } catch {
      return false
    }
  })

  const handleTransfer = useEventCallback(() => {
    ensureConditionIsMetBeforeAction({
      condition: isAnalysisSaved,
      title: 'Analysis transfer',
      message:
        'The analysis must be saved before performing a transfer. Are you sure you want to continue?',
      performAction: () => {
        const targetAnalysisIds = brick.analyses
          .filter(
            brickAnalysis =>
              brickAnalysis.id !== analysisId &&
              validateActionBasedOnAnalysisStatus(
                brickAnalysis.status,
                'select-analysis-for-transfer-predict',
              ) === undefined,
          )
          .map(brickAnalysis => brickAnalysis.id)

        if (targetAnalysisIds.length === 0) {
          dispatch(
            showNotification({
              type: 'error',
              description: 'No target analyses available for transfer',
            }),
          )
        } else {
          triggerTransferAnalysisMutation({
            sourceAnalysisId: analysisId,
            targetAnalysisIds: brick.analyses
              .filter(brickAnalysis => brickAnalysis.id !== analysisId)
              .map(brickAnalysis => brickAnalysis.id),
          })
            .unwrap()
            .then(() => {
              safeNavigate(analysisExitUrl)
            })
        }
      },
      performFix: performFixSaveAnalysis,
    })
  })

  const handleShowSignatures = useEventCallback(() => {
    if (isAnalysisSaved) {
      setShowSignatureModal(true)
    } else {
      showConfirmationDialog({
        title: 'E-Signature',
        message:
          'If you modify the signatures you will lose the unsaved changes. Do you want to continue?',
        onConfirm: () => {
          setShowSignatureModal(true)
        },
      })
    }
  })

  const handleEnterMultiTabMode = useEventCallback(() => {
    enterMultiTabMode(history)
  })

  const handleExitMultiTabMode = useEventCallback(() => {
    exitMultiTabMode(history)
  })

  const actions: IAction[] = useMemo(() => {
    const isExporting =
      generateEventsExportResult.isLoading ||
      generateStatisticsExportResult.isLoading

    const actions: IAction[] = [
      {
        type: 'icon-action',
        iconName: canUndo ? 'undo' : 'undoInactive',
        onClick: () => canUndo && dispatch(undo()),
        disabled: isAnalysisLoading || !canUndo,
        tooltipLabel: 'Undo\n(CTRL+Z)',
      },
      {
        type: 'icon-action',
        iconName: canRedo ? 'redo' : 'redoInactive',
        onClick: () => canRedo && dispatch(redo()),
        disabled: isAnalysisLoading || !canRedo,
        tooltipLabel: 'Redo\n(CTRL+Y)',
      },
      {
        type: 'icon-action',
        iconName: 'clustering',
        onClick: () => {
          if (fcsFile) {
            setShowFileInfoModal(true)
          }
        },
        tooltipLabel: 'File info',
        disabled: isAnalysisLoading,
        colorOverride: theme.colors.primaryDark[100],
      },
      {
        type: 'separator',
      },
      {
        id: 'analysis-export-btn',
        type: 'button-action',
        label: 'Export',
        startIconName: 'export',
        onClick: () => !isExporting && setShowExportMenu(true),
        loading: isExporting,
        disabled: isAnalysisLoading,
        ref: exportButtonRef,
      },
      {
        type: 'button-action',
        label: 'Logbook',
        startIconName: 'logbook',
        onClick: onLogbookClick,
        disabled: isAnalysisLoading,
      },
      {
        id: 'analysis-signature-btn',
        type: 'button-action',
        label: 'E-Signature',
        startIconName: 'signature',
        onClick: handleShowSignatures,
        disabled:
          isAnalysisLoading ||
          isAnalysisReadOnlyAndHasChangesValidation !== undefined,
        tooltip: isAnalysisReadOnlyAndHasChangesValidation,
      },
      {
        type: 'separator',
      },
      {
        type: 'button-action',
        label: 'Transfer',
        startIconName: 'flow',
        onClick: handleTransfer,
        loading: isAnalysisBeingSaved || transferAnalysisMutation.isLoading,
        disabled:
          isAnalysisLoading ||
          transferAnalysisMutation.isLoading ||
          validateActionBasedOnAnalysisStatus(
            analysisStatus,
            'select-analysis-for-transfer-fit',
          ) !== undefined ||
          isAnalysisReadOnlyAndHasChangesValidation !== undefined ||
          analysisAccessMode !== 'read-and-write',
        tooltip:
          validateActionBasedOnAnalysisStatus(
            analysisStatus,
            'select-analysis-for-transfer-fit',
          ) ?? isAnalysisReadOnlyAndHasChangesValidation,
        colorOverride: theme.colors.primaryDark[100],
      },
      {
        type: 'separator',
      },
      {
        id: 'multi-tab-btn',
        type: 'button-action',
        label: `${!isMultiTabMode ? 'Enter' : 'Exit'} multi-tab mode`,
        startIconName: `${!isMultiTabMode ? 'expand' : 'unexpand'}`,
        onClick: !isMultiTabMode
          ? handleEnterMultiTabMode
          : handleExitMultiTabMode,
      },
    ]

    return actions
  }, [
    generateEventsExportResult.isLoading,
    generateStatisticsExportResult.isLoading,
    canUndo,
    isAnalysisLoading,
    canRedo,
    theme.colors.primaryDark,
    onLogbookClick,
    handleShowSignatures,
    isAnalysisReadOnlyAndHasChangesValidation,
    handleTransfer,
    isAnalysisBeingSaved,
    transferAnalysisMutation.isLoading,
    validateActionBasedOnAnalysisStatus,
    analysisStatus,
    analysisAccessMode,
    isMultiTabMode,
    handleEnterMultiTabMode,
    handleExitMultiTabMode,
    dispatch,
    fcsFile,
  ])

  // to close menus when scrolling
  const handleContextMenuScroll = useCallback(() => {
    setShowExportMenu(false)
  }, [])

  useEffect(() => {
    window.addEventListener('scroll', handleContextMenuScroll)
    return () => {
      window.removeEventListener('scroll', handleContextMenuScroll)
    }
  }, [handleContextMenuScroll])

  const handleLogbookLoginSuccess = useCallback(
    data => {
      if ('session' in data) {
        setCurrentSession(data.session)
        setShowLogbookLoginModal(false)
        setTimeout(() => setShowLogbookModal(true), 50)
      } else {
        dispatch(
          showNotification({
            type: 'error',
            description: data.error,
          }),
        )
      }
    },
    [dispatch, setCurrentSession],
  )

  const handleGeneratePdf = useEventCallback(() => {
    setShowExportMenu(false)
    ensureConditionIsMetBeforeAction({
      condition: isAnalysisSaved,
      title: 'Analysis PDF export',
      message:
        'The analysis must be saved before creating the PDF export. Are you sure you want to continue?',
      performAction: () => {
        showModal(PdfExportWizard, { brickById: { [brick.id]: brick } })
      },
      performFix: performFixSaveAnalysis,
    })
  })

  const handleGenerateSelectedGraphicalElementsExport = useEventCallback(() => {
    if (selectedGraphicalElements.length > 0) {
      setShowExportMenu(false)
      ensureConditionIsMetBeforeAction({
        condition: isAnalysisSaved,
        title: 'Selected graphical elements export',
        message:
          'The analysis must be saved before creating the selected graphical elements export. Are you sure you want to continue?',
        performAction: () =>
          generateAnalysisGraphicalElementsExport(
            analysisId,
            selectedGraphicalElements,
            theme,
          ),
        performFix: performFixSaveAnalysis,
      })
    }
  })

  // effect adding CTRL-Z & CTRL-Y shortcuts for undo-redo
  useEffect(() => {
    function handleKeyPressHandler(event: KeyboardEvent) {
      // CTRL + Z
      if (event.ctrlKey && event.key === 'z' && canUndo) {
        dispatch(undo())
      }

      // CTRL + Y
      if (event.ctrlKey && event.key === 'y' && canRedo) {
        dispatch(redo())
      }
    }

    document.addEventListener('keydown', handleKeyPressHandler)
    return () => {
      document.removeEventListener('keydown', handleKeyPressHandler)
    }
  }, [canUndo, canRedo, dispatch])

  return (
    <ToolbarContainer>
      <ActionsContainer>
        {actions.map((action, idx) => (
          <React.Fragment key={idx}>
            {action.type === 'icon-action' && (
              <IconActionWrapper>
                <IconActionButton
                  size="small"
                  onClick={action.onClick}
                  disabled={action.disabled}
                  className={`icon__${action.iconName}`}
                >
                  <Icon
                    name={action.iconName}
                    {...includeIf(action.colorOverride, {
                      color: action.colorOverride,
                    })}
                  />
                </IconActionButton>
                {action.tooltipLabel && (
                  <IconTooltip>{action.tooltipLabel}</IconTooltip>
                )}
              </IconActionWrapper>
            )}
            {action.type === 'separator' && <ActionSeparator />}
            {action.type === 'button-action' && (
              <Tooltip title={action.tooltip ?? ''}>
                <div>
                  <ActionButton
                    ref={action.ref}
                    id={action.id}
                    loading={action.loading}
                    startIcon={
                      !action.loading && (
                        <Icon
                          name={action.startIconName}
                          className={`icon__${action.id}`}
                          {...includeIf(action.colorOverride, {
                            color: action.colorOverride,
                          })}
                        />
                      )
                    }
                    onClick={action.onClick}
                    size="small"
                    disabled={action.disabled}
                  >
                    {action.label}
                  </ActionButton>
                </div>
              </Tooltip>
            )}
          </React.Fragment>
        ))}
      </ActionsContainer>
      <ButtonsContainer>
        <ChannelsButton
          disabled={isAnalysisLoading}
          onClick={() => {
            showModal(WorkflowBrickSettingsModal, { brickId: brick.id })
          }}
          startIcon={
            <Icon name="channels" stroke={theme.colors.primaryDark[70]} />
          }
        >
          Clustering settings
        </ChannelsButton>
        {canToggleProtectionMode && <ProtectionModeButton />}
        <SaveAnalysisButton canToggleProtectionMode={canToggleProtectionMode} />
      </ButtonsContainer>
      {showFileInfoModal && !!fcsFile && (
        <FcsFileHeadersModal
          open={true}
          onClose={() => setShowFileInfoModal(false)}
        />
      )}
      {showLogbookLoginModal && (
        <LogbookLoginModal
          open={showLogbookLoginModal}
          onLoginCancel={() => setShowLogbookLoginModal(false)}
          onLoginSuccess={data => handleLogbookLoginSuccess(data)}
        />
      )}
      {showLogbookModal && (
        <LogbookModal
          analysisId={analysisId}
          open={showLogbookModal}
          onClose={() => setShowLogbookModal(false)}
        />
      )}
      {showSignatureModal && (
        <SignatureModal hideModal={() => setShowSignatureModal(false)} />
      )}

      <Menu
        open={showExportMenu}
        anchorEl={exportButtonRef.current}
        onClose={() => setShowExportMenu(false)}
      >
        <NestedMenuItem label="Export statistics">
          {[ExportTypes.Csv, ExportTypes.Xlsx].map(type => (
            <StyledMenuItem
              key={`${type}-statistics`}
              onClick={() => {
                triggerGenerateStatisticsExport({
                  analysisId,
                  type,
                })
                setShowExportMenu(false)
              }}
            >
              Export as {type.toUpperCase()}
            </StyledMenuItem>
          ))}
        </NestedMenuItem>
        <NestedMenuItem label="Export event data">
          {[ExportTypes.Csv, ExportTypes.Xlsx].map(type => (
            <StyledMenuItem
              key={`${type}-events`}
              onClick={() => {
                triggerGenerateEventsExport({
                  analysisId,
                  type,
                })
                setShowExportMenu(false)
              }}
            >
              Export as {type === ExportTypes.Xlsx ? 'CSV for Excel' : 'CSV'}
            </StyledMenuItem>
          ))}
        </NestedMenuItem>
        <Tooltip title={isAnalysisReadOnlyAndHasChangesValidation ?? ''}>
          <MenuItem
            key="export-selected-graphical-elements"
            onClick={handleGenerateSelectedGraphicalElementsExport}
            disabled={isAnalysisReadOnlyAndHasChangesValidation !== undefined}
          >
            Export selected elements
          </MenuItem>
        </Tooltip>
        <Tooltip title={isAnalysisReadOnlyAndHasChangesValidation ?? ''}>
          <MenuItem
            key="export-pdf"
            onClick={handleGeneratePdf}
            disabled={isAnalysisReadOnlyAndHasChangesValidation !== undefined}
          >
            Export to PDF
          </MenuItem>
        </Tooltip>
      </Menu>
    </ToolbarContainer>
  )
}

const ToolbarContainer = styled.div`
  width: 100%;
  position: sticky;
  top: ${props => props.theme.layout.headerHeight}px;
  z-index: 2;
  background: ${props => props.theme.colors.white};
  border-bottom: 1px solid ${props => props.theme.colors.primaryDark[20]};
  height: 48px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 20px;
`
const ActionsContainer = styled.div`
  display: flex;
  align-items: center;
  position: relative;
  & > * {
    margin-right: 6px;
  }
`
const ButtonsContainer = styled.div`
  display: flex;
  align-items: center;
  & > * {
    margin-left: 12px;
  }
`
const ActionSeparator = styled.div`
  width: 1px;
  height: 24px;
  background-color: #c4c4c4;
`
const IconActionWrapper = styled.div`
  position: relative;
`
const IconActionButton = styled(IconButton)<{ disabled?: boolean }>`
  &:hover + div {
    opacity: 1;
  }
  &.icon__clustering > span {
    padding: 4px;
  }
  &:hover {
    cursor: ${props => (props.disabled ? 'default' : '')};
    background-color: ${props => (props.disabled ? 'transparent' : '')};
  }
`
const IconTooltip = styled.div`
  position: absolute;
  top: 34px;
  left: 50%;
  transform: translateX(-50%);
  padding: 3px 6px;
  border-radius: ${props => props.theme.radius[1]}px;
  background-color: ${props => props.theme.colors.primaryDark[100]};
  color: ${props => props.theme.colors.white};
  font-size: ${props => props.theme.font.size.smallest}px;
  text-align: center;
  white-space: pre;
  user-select: none;
  opacity: 0;
  transition: opacity 300ms ease;
`

const ChannelsButton = styled(Button)`
  margin-left: auto;
  color: ${props => props.theme.colors.primaryDark[100]};
  font-size: ${props => props.theme.font.size.smallest}px;
  font-family: ${props => props.theme.font.style.bold};
  min-width: 165px;
  height: 32px;
  padding: 0 10px;
  transition: color 0.3s ease;
  background-color: transparent;
  &:hover {
    background-color: rgba(0, 0, 0, 0.04);
    color: ${props => props.theme.colors.primaryDark[100]};
  }
`

const StyledMenuItem = styled(MenuItem)`
  background: ${props => props.theme.colors.white};
`
