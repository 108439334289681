import { IconButton, Typography } from '@material-ui/core'
import styled from 'styled-components'

import { ReactComponent as ArrowLeftIcon } from 'assets/images/icons/analysis/arrowLeft.svg'

import { EditableLabel } from 'components/EditableLabel'

import { useMetaAnalysisExitUrl, useSafeNavigate } from 'shared/hooks/router'
import { useAppDispatch, useAppSelector } from 'shared/store'
import { formatDateTime } from 'shared/utils/utils'

import { renameMetaAnalysis } from './store/meta-analysis.history.slice'
import {
  selectMetaAnalysisCreationDate,
  selectMetaAnalysisName,
} from './store/selectors'

export const MetaAnalysisPageHeader = (): JSX.Element => {
  const dispatch = useAppDispatch()
  const metaAnalysisName = useAppSelector(selectMetaAnalysisName)
  const metaAnalysisCreationDate = useAppSelector(
    selectMetaAnalysisCreationDate,
  )

  const safeNavigate = useSafeNavigate()
  const metaAnalysisExitUrl = useMetaAnalysisExitUrl()

  const handleExitMetaAnalysis = () => {
    safeNavigate(metaAnalysisExitUrl)
  }

  const handleRenameMetaAnalysis = (value: string) => {
    dispatch(renameMetaAnalysis(value))
  }

  return (
    <MetaAnalysisPageHeaderRoot>
      <ExitButton size="medium" onClick={handleExitMetaAnalysis}>
        <ArrowLeftIcon />
      </ExitButton>
      <div>
        <MetaAnalysisName
          value={metaAnalysisName}
          onChange={handleRenameMetaAnalysis}
        />
        <MetaAnalysisNameLabel>Meta-analysis name</MetaAnalysisNameLabel>
      </div>
      <div>
        <Label>Meta-analysis created</Label>
        <MetaAnalysisCreationDate>
          {formatDateTime(metaAnalysisCreationDate)}
        </MetaAnalysisCreationDate>
      </div>
    </MetaAnalysisPageHeaderRoot>
  )
}

const MetaAnalysisPageHeaderRoot = styled.div`
  background-color: ${props => props.theme.colors.white};
  border-bottom: 1px solid ${props => props.theme.colors.primaryDark[20]};
  height: 75px;
  display: flex;
  align-items: center;
`

const ExitButton = styled(IconButton)`
  grid-area: exit-button;
  width: 48px;
  height: 48px;
  margin-left: 12px;
`

const Label = styled(Typography)`
  font-size: 11px;
  color: ${props => props.theme.colors.primaryDark['70']};
`

const MetaAnalysisNameLabel = styled(Label)`
  padding-left: 51px;
`

const MetaAnalysisName = styled(EditableLabel)`
  height: 25px;
  width: 500px;
  font-size: 16px;
  font-family: ${props => props.theme.font.style.bold};

  .label {
    font-size: inherit;
    font-family: inherit;
  }

  .input-root {
    font-size: inherit;
    font-family: inherit;
    color: ${props => props.theme.colors.primaryDark['100']};
  }
`

const MetaAnalysisCreationDate = styled(Typography)`
  font-size: 12px;
  font-family: ${props => props.theme.font.style.bold};
`
