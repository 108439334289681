import { FC } from 'react'
import styled from 'styled-components'

import { ComputeDimensionalityReductionSeriesReturnValue } from 'shared/worker/meta-analysis-worker'

type MetaAnalysisDimensionalityReductionChartTooltipProps = {
  seriesId: string
  metadataBySeriesId:
    | ComputeDimensionalityReductionSeriesReturnValue['metadataBySeriesId']
    | undefined
}

export const MetaAnalysisDimensionalityReductionChartTooltip: FC<
  MetaAnalysisDimensionalityReductionChartTooltipProps
> = ({ seriesId, metadataBySeriesId }) => {
  if (!metadataBySeriesId) {
    return null
  }

  const clusterName = metadataBySeriesId[seriesId]?.clusterName
  const fileName = metadataBySeriesId[seriesId]?.fileName
  const proportion = metadataBySeriesId[seriesId]?.clusterProportion
  const count = metadataBySeriesId[seriesId]?.clusterCount
  const color = metadataBySeriesId[seriesId]?.color

  return (
    <MetaAnalysisDimensionalityReductionChartTooltipRoot>
      {clusterName && (
        <Cluster>
          <ClusterName>{clusterName}</ClusterName>
          <ClusterDot $color={color}>⬤</ClusterDot>
        </Cluster>
      )}
      <FileName>{fileName}</FileName>
      {proportion && count && (
        <ClusterDetails>
          <div>{proportion.toFixed(2)}% of total</div>
          <div>{count} events</div>
          {metadataBySeriesId[seriesId]?.channelIntensity && (
            <div>{metadataBySeriesId[seriesId]?.channelIntensity}</div>
          )}
        </ClusterDetails>
      )}
    </MetaAnalysisDimensionalityReductionChartTooltipRoot>
  )
}

const MetaAnalysisDimensionalityReductionChartTooltipRoot = styled.div`
  padding: 8px;
  font-size: 13px;
  min-width: 120;
  background: ${props => props.theme.colors.white};
`

const Cluster = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`

const ClusterName = styled.span`
  font-family: ${props => props.theme.font.style.bold};
`

const ClusterDot = styled.span<{ $color: string }>`
  color: ${props => props.$color};
  margin-right: 4px;
  font-size: 14px;
`

const FileName = styled.div`
  font-size: 12px;
  color: ${props => props.theme.colors.primaryDark[70]};
`

const ClusterDetails = styled.div`
  font-size: 12px;
  margin-top: 6px;
  color: ${props => props.theme.colors.primaryDark[70]};
`
