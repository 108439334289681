import 'highcharts'
import { FC, useMemo, useState } from 'react'
import styled, { useTheme } from 'styled-components'

import { DefaultErrorBoundary } from 'components/DefaultErrorBoundary'
import { Modal } from 'components/Modal'

import {
  selectAnalysisSelectedGraphicalElementIds,
  toggleAnalysisGraphicalElementSelection,
} from 'pages/analysis/store/selected-graphical-elements.slice'
import {
  selectAnalysisLassos,
  selectClustersByLassoId,
  selectParentLassoIdByLassoId,
} from 'pages/analysis/store/selectors'

import { ANALYSIS_LASSOS_HIERARCHY_GRAPH_ID } from 'shared/constants'
import { useEventCallback } from 'shared/hooks/useEventCallback'
import { useAppDispatch, useAppSelector } from 'shared/store'
import { findDescendantLassos } from 'shared/utils/lasso'

import { createAnalysisLassosHierarchyGraphBaseOptions } from './AnalysisLassosHierarchyGraphBaseOptions'
import { Chart } from './Chart'
import { LayoutItemCard } from './LayoutItemCard'
import { LayoutItemCardHeader } from './LayoutItemCardHeader'

type AnalysisLassosHierarchyGraphProps = {
  selectedLassoId?: string
  selectedLassoLabelColor?: string
  minimalMode?: boolean
  isExpanded?: boolean
  className?: string
  onCloseExpand?: () => void
}

export const AnalysisLassosHierarchyGraph: React.FC<
  AnalysisLassosHierarchyGraphProps
> = ({
  selectedLassoId,
  selectedLassoLabelColor,
  minimalMode,
  isExpanded,
  className,
  onCloseExpand,
}) => {
  const dispatch = useAppDispatch()
  const selectedGraphicalElements = useAppSelector(
    selectAnalysisSelectedGraphicalElementIds,
  )

  const [shouldShowExpandedSelf, setShouldShowExpandedSelf] = useState(false)

  const isSelected = useMemo(
    () =>
      selectedGraphicalElements.includes(ANALYSIS_LASSOS_HIERARCHY_GRAPH_ID),
    [selectedGraphicalElements],
  )

  const handleChartSelection = useEventCallback(() => {
    dispatch(
      toggleAnalysisGraphicalElementSelection(
        ANALYSIS_LASSOS_HIERARCHY_GRAPH_ID,
      ),
    )
  })

  return (
    <StyledLayoutItemCard $isExpanded={isExpanded} className={className}>
      {!minimalMode && (
        <LayoutItemCardHeader
          title="Lasso hierarchy"
          isSelected={isSelected}
          isExpanded={isExpanded}
          onSelect={handleChartSelection}
          onExpand={() => setShouldShowExpandedSelf(true)}
          onCloseExpand={onCloseExpand}
        />
      )}
      <DefaultErrorBoundary>
        <InnerPlot
          selectedLassoId={selectedLassoId}
          selectedLassoLabelColor={selectedLassoLabelColor}
          minimalMode={minimalMode}
        />
      </DefaultErrorBoundary>
      {shouldShowExpandedSelf && (
        <Modal open onClose={() => setShouldShowExpandedSelf(false)}>
          <ExpandedAnalysisLassosHierarchyGraph
            onCloseExpand={() => setShouldShowExpandedSelf(false)}
          />
        </Modal>
      )}
    </StyledLayoutItemCard>
  )
}

type InnerPlotProps = Pick<
  AnalysisLassosHierarchyGraphProps,
  'selectedLassoId' | 'selectedLassoLabelColor' | 'minimalMode'
>

const InnerPlot: FC<InnerPlotProps> = ({
  selectedLassoId,
  selectedLassoLabelColor,
  minimalMode,
}) => {
  const theme = useTheme()
  const lassos = useAppSelector(selectAnalysisLassos)
  const parentLassoIdByLassoId = useAppSelector(selectParentLassoIdByLassoId)
  const clustersByLassoId = useAppSelector(selectClustersByLassoId)

  const lassosToColor = useMemo(() => {
    if (selectedLassoId) {
      return [
        selectedLassoId,
        ...findDescendantLassos([selectedLassoId], parentLassoIdByLassoId),
      ]
    }
  }, [parentLassoIdByLassoId, selectedLassoId])

  const options = useMemo(
    (): Highcharts.Options =>
      createAnalysisLassosHierarchyGraphBaseOptions({
        isMinimalMode: minimalMode,
        lassos,
        parentLassoIdByLassoId,
        clustersByLassoId,
        lassosToColor,
        selectedLassoLabelColor,
        theme,
      }),
    [
      clustersByLassoId,
      lassos,
      lassosToColor,
      minimalMode,
      parentLassoIdByLassoId,
      selectedLassoLabelColor,
      theme,
    ],
  )

  return <Chart options={options} />
}

const StyledLayoutItemCard = styled(LayoutItemCard)`
  grid-template-rows: auto 1fr;
`

const ExpandedAnalysisLassosHierarchyGraph = styled(
  AnalysisLassosHierarchyGraph,
).attrs({ isExpanded: true })`
  width: 90vw;
  height: 90vh;
`
