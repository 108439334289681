import { LicensePackage } from 'shared/models/LicenseModels'
import { PaginatedResults } from 'shared/models/Result'

import { privateApi } from './private.api'

const licensePackageApi = privateApi.injectEndpoints({
  endpoints: builder => ({
    getLicensePackages: builder.query<GetLicensePackagesResult, void>({
      query: () => ({
        url: '/license-packages/',
      }),
    }),
    getLicensePackageDetails: builder.query<LicensePackage, { id: string }>({
      query: ({ id }) => ({
        url: `/license-packages/${id}/`,
      }),
    }),
    purchaseCustom: builder.mutation<PurchaseCustomResult, void>({
      query: () => ({
        url: '/license-packages/purchase_custom/',
        method: 'POST',
      }),
    }),
  }),
})

export const {
  useGetLicensePackagesQuery,
  useGetLicensePackageDetailsQuery,
  usePurchaseCustomMutation,
} = licensePackageApi

type GetLicensePackagesResult = PaginatedResults<LicensePackage[]>

type PurchaseCustomResult = {
  email_support: string
  email_operator: string
}
