import { Check, Error, Info, Warning } from '@material-ui/icons'
import { capitalize } from 'lodash'
import { ReactNode } from 'react'
import styled from 'styled-components'

import {
  MessagesForUser,
  MessagesForUserCategory,
} from 'shared/models/AnalysisModels'
import { formatDateTime } from 'shared/utils/utils'

import { NoDataFound } from './NoDataFound'
import { Modal } from './modal/Modal'
import { ModalContainer } from './modal/ModalContainer'
import { Table, TableColumn, TableRow } from './table/Table'

type MessagesForUserModalProps = {
  title: ReactNode
  messages: MessagesForUser
  onClose: () => void
}

const iconByCategory = {
  warning: <Warning />,
  error: <Error />,
  success: <Check />,
  info: <Info />,
}

export const MessagesForUserModal: React.FC<MessagesForUserModalProps> = ({
  messages,
  title,
  onClose,
}) => {
  const columns: TableColumn[] = [
    {
      key: 'datetime',
      label: 'Date',
    },
    { key: 'type', label: 'Type' },
    {
      key: 'message',
      label: 'Message',
    },
  ]

  const rows: TableRow[] = Object.entries(messages)
    .sort(
      ([datetimeA], [datetimeB]) => +new Date(datetimeB) - +new Date(datetimeA),
    )
    .map(([datetime, { message, category }]) => ({
      key: datetime,
      cells: [
        { key: 'datetime', content: formatDateTime(datetime) },
        {
          key: 'type',
          content: (
            <Category $category={category}>
              {capitalize(category)} {iconByCategory[category]}
            </Category>
          ),
        },
        { key: 'message', content: message },
      ],
    }))

  return (
    <Modal
      open
      title={<Title>{title}</Title>}
      Container={StyledModalContainer}
      onClose={onClose}
    >
      <Messages>
        {rows.length > 0 ? (
          <Table columns={columns} rows={rows} />
        ) : (
          <NoDataFound>No messages</NoDataFound>
        )}
      </Messages>
    </Modal>
  )
}

const StyledModalContainer = styled(ModalContainer)`
  max-height: 90vh;
  min-width: 600px;
`

const Title = styled.div`
  font-size: 18px;
`

const Messages = styled.div`
  display: grid;
  row-gap: 1em;
  overflow-y: auto;
  align-self: stretch;
`

const Category = styled.div<{ $category: MessagesForUserCategory }>`
  display: inline-flex;
  align-items: center;
  gap: 4px;
  color: ${({ theme, $category }) => {
    switch ($category) {
      case 'warning':
        return theme.colors.warning
      case 'error':
        return theme.colors.error
      case 'success':
        return theme.colors.success
      default:
        return theme.colors.primary[70]
    }
  }};

  svg {
    width: 0.5em;
    height: 0.5em;
  }
`
