import { fetchBaseQuery } from '@reduxjs/toolkit/query'

import { extendSession, logout } from 'shared/store/auth.slice'
import { handleError } from 'shared/utils/errorHandler'
import { href } from 'shared/utils/utils'

import { CSRF_TOKEN_REQUEST_HEADER_NAME, getCsrfToken } from './cookies'
import { prepareHeaders } from './utils'

export const privateFetchBaseQuery: typeof fetchBaseQuery = () => {
  return async (args, api, extraOptions) => {
    const baseQuery = fetchBaseQuery({
      baseUrl: href('/'),
      prepareHeaders,
    })
    const csrfToken = getCsrfToken()

    if (!csrfToken) {
      api.dispatch(logout())
      throw handleError(new Error('Not authenticated'))
    }

    if (typeof args === 'string') {
      const result = await baseQuery(
        { url: args, headers: { [CSRF_TOKEN_REQUEST_HEADER_NAME]: csrfToken } },
        api,
        extraOptions,
      )

      if (
        'error' in result &&
        (result.error?.status === 403 || result.error?.status === 401)
      ) {
        api.dispatch(logout())
        throw handleError(new Error('Not authenticated'))
      }

      return result
    }

    const result = await baseQuery(
      {
        ...args,
        headers: {
          [CSRF_TOKEN_REQUEST_HEADER_NAME]: csrfToken,
          ...args.headers,
        },
      },
      api,
      extraOptions,
    )

    api.dispatch(extendSession())

    if (
      'error' in result &&
      (result.error?.status === 403 || result.error?.status === 401)
    ) {
      api.dispatch(logout())
      throw handleError(new Error('Not authenticated'))
    }

    return result
  }
}
