import { CircularProgress, Fade, IconButton } from '@material-ui/core'
import { Pagination } from '@material-ui/lab'
import React, { useState } from 'react'
import styled from 'styled-components'

import { Icon } from 'assets/images/icons/Icon'

import { Button } from 'components/Button'
import { Modal } from 'components/Modal'

import {
  useArchiveEntriesMutation,
  useEndSessionMutation,
  useGetEntriesQuery,
} from 'shared/api/logbook.api'
import { useLogActions } from 'shared/contexts/LogContext'
import { useObserve } from 'shared/hooks/useObserve'
import { useSelect } from 'shared/hooks/useSelect'

import { ExitConfirmationModal } from './ExitConfirmationModal'
import { LogTable } from './LogTable'
import { LogbookExportButton } from './LogbookExportButton'
import { LogbookGlossaryModal } from './LogbookGlossaryModal'

type LogbookModalProps = Readonly<{
  open: boolean
  onClose: () => void
  analysisId: string
}>

export const LogbookModal = ({
  open,
  onClose,
  analysisId,
}: LogbookModalProps): JSX.Element => {
  const { currentSession, setCurrentSession } = useLogActions()
  const [showExitModal, setShowExitModal] = useState(false)
  const [displayGlossary, setDisplayGlossary] = useState(false)

  const [page, setPage] = useState(1)

  const logbookEntriesResult = useGetEntriesQuery({ analysisId, page })
  const [archiveEntries, archiveEntriesResult] = useArchiveEntriesMutation()
  const [endSession, endSessionResult] = useEndSessionMutation()

  useObserve(logbookEntriesResult.data?.page, realCurrentPage => {
    if (realCurrentPage && realCurrentPage !== page) {
      setPage(realCurrentPage)
    }
  })
  const logbookEntries = logbookEntriesResult.currentData?.results

  const { onSelect, onSelectAll, allSelected, isSelected, selectedIds } =
    useSelect(logbookEntries?.map(e => e.id))

  const handleArchiveEntries = () => {
    if (selectedIds.length > 0) {
      archiveEntries({ analysisId, entryIds: selectedIds })
    }
  }

  const handleEndSession = () => {
    if (currentSession !== undefined) {
      setShowExitModal(false)
      onClose()
      endSession({ sessionId: currentSession.id })
      setCurrentSession(undefined)
    }
  }

  return (
    <Modal open={open} onClose={() => setShowExitModal(true)}>
      <ExitConfirmationModal
        open={showExitModal}
        loading={endSessionResult.isLoading}
        onCancel={() => setShowExitModal(false)}
        onConfirm={handleEndSession}
        data-cy="logbook-cancel"
      />
      <LogbookGlossaryModal
        open={displayGlossary}
        onClose={() => setDisplayGlossary(false)}
      />
      <Fade in={open}>
        <Container>
          <TitleContainer>
            <div />
            <Title>Logbook</Title>
            <StyledIconButton
              size="small"
              onClick={() => setShowExitModal(true)}
            >
              <StyledIcon name="close" />
            </StyledIconButton>
          </TitleContainer>
          <LogsContainer>
            {logbookEntries !== undefined ? (
              <>
                <LogTable
                  logs={logbookEntries}
                  onSelect={onSelect}
                  isSelected={isSelected}
                  allSelected={allSelected}
                  onSelectAll={onSelectAll}
                  data-cy="logbook"
                />
              </>
            ) : (
              <LoaderContainer>
                <CircularProgress />
              </LoaderContainer>
            )}
            <StyledPagination
              page={page}
              count={logbookEntriesResult.data?.page_count ?? 1}
              onChange={(_event, page) => setPage(page)}
            />
          </LogsContainer>
          <Footer>
            <Button
              onClick={() => setDisplayGlossary(true)}
              grey
              endIcon={<StyledIcon name="glossary" />}
            >
              Glossary
            </Button>
            <EndActions>
              <ArchiveButton
                grey
                onClick={handleArchiveEntries}
                loading={archiveEntriesResult.isLoading}
                data-cy="glossary-btn"
              >
                Archive
              </ArchiveButton>
              <LogbookExportButton
                analysisId={analysisId}
                selectedEntryIds={selectedIds}
                data-cy="archive-btn"
              />
            </EndActions>
          </Footer>
        </Container>
      </Fade>
    </Modal>
  )
}

const Container = styled.div`
  background-color: ${props => props.theme.colors.background};
  border-radius: ${props => props.theme.radius[2]}px;
  width: 100%;
  height: 100%;
  padding: 50px 32px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`

const TitleContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 45px;
`

const Title = styled.div`
  font-size: ${props => props.theme.font.size.h1}px;
  font-family: ${props => props.theme.font.style.bold};
  text-align: center;
`

const StyledIconButton = styled(IconButton)`
  width: 36p;
  height: 36px;
`

const LogsContainer = styled.div`
  background-color: ${props => props.theme.colors.white};
  border-radius: ${props => props.theme.radius[2]}px;
  border: 1px solid;
  border-color: ${props => props.theme.colors.primaryDark[20]};
  width: 100%;
  height: 100%;
  overflow: hidden;
  margin-bottom: 31px;
  padding: 20px 32px 0;
  display: flex;
  flex-direction: column;
`

const StyledIcon = styled(Icon)`
  color: ${props => props.theme.colors.primaryDark[100]};
`

const Footer = styled.div`
  display: flex;
  justify-content: space-between;
`

const EndActions = styled.div`
  display: flex;
  & > * {
    margin-left: 32px;
  }
`

const ArchiveButton = styled(Button)`
  width: 125px;
`

const LoaderContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
`

const StyledPagination = styled(Pagination)`
  padding-bottom: 20px;
  align-self: center;
`
