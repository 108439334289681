export const AnalysisScatterPlotBaseOptions = {
  chart: {
    type: 'scatter',
  },
  xAxis: {
    title: { text: null },
    endOnTick: false,
    startOnTick: false,
    lineWidth: 1,
  },
  yAxis: {
    title: { text: null },
    endOnTick: false,
    startOnTick: false,
    lineWidth: 1,
  },
  plotOptions: {
    series: {
      animation: false,
      states: {
        inactive: {
          opacity: 1,
        },
      },
    },
    scatter: {
      marker: {
        states: {
          hover: { enabled: false },
        },
      },
    },
  },
  title: { text: undefined },
}
