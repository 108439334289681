import { useGetReCaptchaSettingsQuery } from 'shared/api/utils.api'

export const useReCaptchaRequired = (flag: `${ReCaptchaFlags}`): boolean => {
  const recaptchaSettings = useGetReCaptchaSettingsQuery()

  if (!recaptchaSettings.currentData) {
    return false
  }

  return (
    recaptchaSettings.currentData.recaptcha_enabled &&
    !!recaptchaSettings.currentData[flag]
  )
}

export enum ReCaptchaFlags {
  Login = 'recaptcha_login',
  SignUp = 'recaptcha_signup',
  ForgotPassword = 'recaptcha_forgot_password',
}
