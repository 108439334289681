import React, { FC, useCallback } from 'react'
import styled from 'styled-components'

import { getGateStepValue } from 'shared/utils/graph'

type CoordsFieldsProps = {
  axis: 'x' | 'y'
  min: number
  max: number
  isInputDisabled: boolean // disabling input for polygon gates
  onMinChanged(value: number): void
  onMaxChanged(value: number): void
}

export const CoordsField: FC<CoordsFieldsProps> = ({
  axis,
  min,
  max,
  isInputDisabled,
  onMinChanged,
  onMaxChanged,
}) => {
  const onMinChange = useCallback(
    function onMinChange(event: React.ChangeEvent<HTMLInputElement>) {
      const value = +event.target.value
      onMinChanged(value)
    },
    [onMinChanged],
  )

  const onMaxChange = useCallback(
    function onMaxChange(event: React.ChangeEvent<HTMLInputElement>) {
      const value = +event.target.value
      onMaxChanged(value)
    },
    [onMaxChanged],
  )

  // dynamic step value
  const stepValue = getGateStepValue(Math.min(min, max))

  return (
    <Container>
      <Header>
        <p>{axis.toUpperCase()}</p>
      </Header>
      <Field withBorder>
        <StyledLabel>Min</StyledLabel>
        <StyledInput
          type="number"
          name="min"
          value={min}
          onChange={onMinChange}
          step={stepValue}
          disabled={isInputDisabled}
        />
      </Field>
      <Field>
        <StyledLabel>Max</StyledLabel>
        <StyledInput
          type="number"
          name="max"
          value={max}
          onChange={onMaxChange}
          step={stepValue}
          disabled={isInputDisabled}
        />
      </Field>
    </Container>
  )
}

const Container = styled.div`
  height: 40px;
  display: flex;
  justify-content: flex-start;
  align-content: center;
  border: 1px solid ${props => props.theme.colors.primaryDark[20]};
  border-radius: ${props => props.theme.radius[1]}px;

  &:first-child {
    margin-right: 15px;
  }
`
const Header = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 26px;
  height: 100%;
  background-color: ${props => props.theme.colors.primaryDark[20]};
  border-top-left-radius: 1px;
  border-bottom-left-radius: 1px;

  & > p {
    margin: 0;
    color: ${props => props.theme.colors.primaryDark[70]};
    font-size: ${props => props.theme.font.size.small}px;
    text-align: center;
    font-weight: bold;
  }
`

const Field = styled.div<{ withBorder?: boolean }>`
  height: 100%;
  width: 100px;
  padding: 2px 10px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  border-right: ${props =>
    props.withBorder
      ? `1px solid ${props => props.theme.colors.primaryDark[20]}`
      : ''};
`

const StyledLabel = styled.label`
  font-size: ${props => props.theme.font.size.sub}px;
  color: ${props => props.theme.colors.primaryDark[50]};
`

const StyledInput = styled.input`
  width: 100%;
  font-size: ${props => props.theme.font.size.small}px;
  border: none;

  outline: none;
  color: ${props => props.theme.colors.primaryDark[100]};
`
