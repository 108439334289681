import { Fade, IconButton } from '@material-ui/core'
import React, { FC } from 'react'
import styled from 'styled-components'

import { Icon } from 'assets/images/icons/Icon'

import { Modal } from 'components/Modal'

import {
  glossaryEntries,
  GlossaryKeyword,
  GlossaryReference as _GlossaryReference,
} from './LogbookGlossaryEntries'

type LogbookGlossaryModalProps = Readonly<{
  open: boolean
  onClose: () => void
}>

type GlossaryReferenceProps = Readonly<{
  reference: keyof typeof _GlossaryReference
  entries: GlossaryKeyword
}>

export const LogbookGlossaryModal: FC<LogbookGlossaryModalProps> = ({
  open,
  onClose,
}) => {
  return (
    <Modal open={open} onClose={onClose}>
      <Fade in={open}>
        <Container>
          <TitleContainer>
            <div />
            <Title>Glossary</Title>
            <StyledIconButton size="small" onClick={onClose}>
              <StyledIcon name="close" />
            </StyledIconButton>
          </TitleContainer>
          <GlossaryContent>
            <Header>
              <p>Reference</p>
              <p>Keyword</p>
              <p>Keyword description</p>
            </Header>
            <ScrollContainer>
              {Object.entries(glossaryEntries).map(([reference, entries]) => (
                <GlossaryReference
                  key={reference}
                  reference={reference as keyof typeof _GlossaryReference}
                  entries={entries}
                  data-cy="glossary"
                />
              ))}
            </ScrollContainer>
          </GlossaryContent>
        </Container>
      </Fade>
    </Modal>
  )
}

const GlossaryReference = ({
  reference,
  entries,
}: GlossaryReferenceProps): JSX.Element => {
  return (
    <ReferenceContainer>
      <Reference>{reference}</Reference>
      <KeywordContainer>
        {Object.entries(entries).map(([keyword, description]) => (
          <KeywordLine key={keyword}>
            <p>{keyword}</p>
            <p>{description}</p>
          </KeywordLine>
        ))}
      </KeywordContainer>
    </ReferenceContainer>
  )
}

const Container = styled.div`
  background-color: ${props => props.theme.colors.background};
  width: ${props => props.theme.layout.modalWidth}px;
  height: 80%;
  border-radius: ${props => props.theme.radius[2]}px;
  display: flex;
  flex-direction: column;
  padding: 21px 32px;
`

const TitleContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 22px;
`
const Title = styled.div`
  font-size: ${props => props.theme.font.size.h2}px;
  font-family: ${props => props.theme.font.style.bold};
  text-align: center;
`
const StyledIconButton = styled(IconButton)`
  width: 36px;
  height: 36px;
`
const StyledIcon = styled(Icon)`
  color: ${props => props.theme.colors.primaryDark[100]};
`

const GlossaryContent = styled.div`
  display: flex;
  flex-direction: column;
  overflow: hidden;
`
const ScrollContainer = styled.div`
  overflow: auto;
`

const Header = styled.div`
  display: flex;
  margin-bottom: 12px;
  & > p {
    color: ${props => props.theme.colors.greyscale[50]};
    flex-basis: 42.5%;
    &:nth-child(2) {
      padding-left: 24px;
    }
    &:first-of-type {
      flex-basis: 15%;
    }
  }
`

const ReferenceContainer = styled.div`
  display: flex;
`

const Reference = styled.div`
  flex-basis: 15%;
  font-family: ${props => props.theme.font.style.bold};
  height: 50px;
  padding-top: 8px;
  display: flex;
  align-items: center;
`

const KeywordContainer = styled.div`
  display: flex;
  background-color: ${props => props.theme.colors.white};
  flex-direction: column;
  flex-basis: 85%;
  padding: 8px;
  border: 1px solid ${props => props.theme.colors.primaryDark[20]};
  border-radius: ${props => props.theme.radius[2]}px;
  margin-bottom: 16px;
`

const KeywordLine = styled.div`
  display: flex;
  align-items: center;
  height: 50px;
  background-color: ${props => props.theme.colors.primaryDark[10]};
  margin-bottom: 8px;
  padding: 0 16px;
  :last-of-type {
    margin-bottom: 0;
  }
  & > p {
    flex-basis: 50%;
  }
`
