import { GridItemHTMLElement, GridStack } from 'gridstack'
import 'gridstack/dist/gridstack-extra.min.css'
import 'gridstack/dist/gridstack.min.css'
import 'gridstack/dist/h5/gridstack-dd-native'
import { isEqual } from 'lodash'
import React, { MutableRefObject, ReactElement, useEffect, useRef } from 'react'
import { usePrevious } from 'react-use'
import styled from 'styled-components'

import { useAppDispatch } from 'shared/store'
import { getCurrentLayoutKey } from 'shared/utils/multi-tab'
import { useSize } from 'shared/utils/utils'

import {
  Layout,
  LayoutElement,
  LayoutKey,
  NUMBER_OF_COLUMNS,
  serializeLayout,
} from '.'
import { CustomGridStackEngine } from '../CustomGridStackEngine'

GridStack.registerEngine(CustomGridStackEngine)

type ChartGridProps = {
  children: Array<ReactElement>
  layout: Layout
  onChangeSpecificLayout: (key: LayoutKey, layout: LayoutElement) => void
  onUpdateSpecificLayout: (key: LayoutKey, layout: LayoutElement) => void
}

export const ChartGrid = (props: ChartGridProps): JSX.Element => {
  const [rootRef, { width }] = useSize<HTMLDivElement>()
  const { grid, gridStackElementRef } = useGrid(props)
  const currentLayoutKey = getCurrentLayoutKey()
  useLayoutHistory(grid, props)

  return (
    <GridRoot ref={rootRef}>
      <div ref={gridStackElementRef} className="grid-stack" style={{ width }}>
        {props.children
          .filter(
            child => (child.key as string) in props.layout[currentLayoutKey],
          )
          .map(child => {
            return (
              <div
                key={`grid-stack-item-${child.key}`}
                className="grid-stack-item"
                chart-grid-id={child.key}
                data-cy="fsc-chart"
              >
                <div className="grid-stack-item-content">{child}</div>
              </div>
            )
          })}
      </div>
    </GridRoot>
  )
}

const useGrid = ({
  children,
  layout,
  onUpdateSpecificLayout,
}: ChartGridProps): {
  grid: GridStack | undefined
  gridStackElementRef: React.RefObject<HTMLDivElement>
} => {
  const gridRef: MutableRefObject<GridStack | undefined> = React.useRef()
  const gridStackElementRef = useRef<HTMLDivElement>(null)
  const childrenCount = React.Children.count(children)
  const previousChildrenCount = usePrevious(childrenCount)
  const childrenCountHasChanged = previousChildrenCount !== childrenCount

  useEffect(() => {
    const currentLayoutKey = getCurrentLayoutKey()

    if (!gridRef.current) {
      gridRef.current = setupGrid()
    }
    if (
      !childrenCountHasChanged &&
      isEqual(layout[currentLayoutKey], serializeLayout(gridRef.current))
    ) {
      return
    }
    if (!gridStackElementRef.current) {
      throw new Error('gridStackElementRef is null')
    }

    const children = gridStackElementRef.current.children

    gridRef.current.removeAll(false)
    gridRef.current.batchUpdate()
    for (const child of children) {
      const node = gridRef.current.makeWidget(child as GridItemHTMLElement)
      const id = child.getAttribute('chart-grid-id')

      if (!node.gridstackNode) {
        throw new Error('gridstackNode is null')
      }

      if (id) {
        node.gridstackNode.id = id
      }
    }
    gridRef.current.commit()

    CustomGridStackEngine.customBehaviorEnabled = false
    gridRef.current.load(
      Object.entries(layout[currentLayoutKey]).map(([id, options]) => ({
        id,
        ...options,
      })),
      false,
    )
    CustomGridStackEngine.customBehaviorEnabled = true
    if (childrenCountHasChanged) {
      gridRef.current.compact()
      onUpdateSpecificLayout(currentLayoutKey, serializeLayout(gridRef.current))
    }
  }, [layout, childrenCount, childrenCountHasChanged, onUpdateSpecificLayout])

  return {
    grid: gridRef.current,
    gridStackElementRef,
  }
}

const setupGrid = () =>
  GridStack.init({
    column: NUMBER_OF_COLUMNS,
    cellHeight: 550,
    handleClass: 'chart-grid-drag-handle',
    animate: false,
  })

const useLayoutHistory = (
  grid: GridStack | undefined,
  { layout, onChangeSpecificLayout }: ChartGridProps,
) => {
  const dispatch = useAppDispatch()
  const currentLayoutKey = getCurrentLayoutKey()

  useEffect(() => {
    if (grid) {
      grid.on('resizestop dragstop', () => {
        const nextLayout = serializeLayout(grid)
        if (!isEqual(layout[currentLayoutKey], nextLayout)) {
          onChangeSpecificLayout(currentLayoutKey, nextLayout)
        }
      })
    }
  }, [currentLayoutKey, dispatch, grid, layout, onChangeSpecificLayout])
}

const GridRoot = styled.div`
  overflow: hidden;
  & .grid-stack-item .ui-resizable-handle {
    background: none;
  }
  & .grid-stack-item,
  & .grid-stack-item-content {
    opacity: 1 !important;
    box-shadow: none !important;
    overflow: visible !important;
  }
  & .placeholder-content {
    border-radius: 8px !important;
  }
`
