import { ClickAwayListener, InputBase, Popper } from '@material-ui/core'
import { Autocomplete } from '@material-ui/lab'
import { partition } from 'lodash'
import { useRef, useState } from 'react'
import styled, { css } from 'styled-components'

import { Icon } from 'assets/images/icons/Icon'

import { updateChartType } from 'pages/analysis/store/analysis.history.slice'
import {
  selectAnalysisAccessMode,
  selectAnalysisFcsFile,
  selectChannels,
  selectChannelById,
  selectClusteringChannels,
} from 'pages/analysis/store/selectors'

import { Graph } from 'shared/models/Graphs'
import { useAppDispatch, useAppSelector } from 'shared/store'
import { includeIf, validateAxisScaleType } from 'shared/utils/utils'

type AnalysisChartChannelSelectorProps = {
  axis: 'x_axis' | 'y_axis'
} & (
  | {
      chart: Graph
    }
  | {
      value: string
      onChange: (value: string) => void
    }
)

export const AnalysisChartChannelSelector: React.FC<
  AnalysisChartChannelSelectorProps
> = ({ axis, ...props }) => {
  const dispatch = useAppDispatch()
  const channels = useAppSelector(selectChannels)
  const clusteringChannels = useAppSelector(selectClusteringChannels)
  const channelByName = useAppSelector(selectChannelById)
  const fcsFile = useAppSelector(selectAnalysisFcsFile)
  const analysisAccessMode = useAppSelector(selectAnalysisAccessMode)

  const isDisabled = analysisAccessMode !== 'read-and-write'

  const channelLabelRef = useRef<HTMLDivElement>(null)
  const [isOpen, setIsOpen] = useState(false)

  const HISTOGRAM_CHANNEL = '__histogram__'
  const options = [
    ...includeIf(axis === 'y_axis', [HISTOGRAM_CHANNEL]),
    ...partition(
      channels.map(channel => channel.id),
      name => clusteringChannels.includes(name),
    ).flat(),
  ]

  const handleChannelClick = (updatedValue: {
    x_axis?: string
    y_axis?: string
  }) => {
    if ('onChange' in props) {
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      props.onChange(updatedValue[axis]!)
      return
    }

    if (updatedValue.y_axis === HISTOGRAM_CHANNEL) {
      dispatch(updateChartType({ ...props.chart, chart_type: 'Histogram' }))
      return
    }

    const chart = props.chart
    const updatedChart: Graph = {
      ...chart,
      ...updatedValue,
      gates: [],
      x_axis_scale_type: validateAxisScaleType({
        channel: updatedValue.x_axis,
        channels: fcsFile?.channels,
        scaleType: chart.x_axis_scale_type,
      }),
      y_axis_scale_type: validateAxisScaleType({
        channel: updatedValue.y_axis,
        channels: fcsFile?.channels,
        scaleType: chart.y_axis_scale_type,
      }),
    }
    dispatch(updateChartType(updatedChart))
  }

  const value = 'value' in props ? props.value : props.chart[axis]

  return (
    <AnalysisChartChannelSelectorRoot $axis={axis}>
      <ChannelLabel
        ref={channelLabelRef}
        $isDisabled={isDisabled}
        onClick={
          isDisabled
            ? undefined
            : () => {
                setIsOpen(true)
              }
        }
      >
        {channelByName[value as string]?.__computed__displayName}
        {!isDisabled && <Icon name="smallArrowDown" />}
      </ChannelLabel>

      <StyledPopper open={isOpen} anchorEl={channelLabelRef.current}>
        <ClickAwayListener onClickAway={() => setIsOpen(false)}>
          <StyledAutocomplete
            open
            disablePortal
            options={options}
            filterOptions={(options, state) => {
              return options.filter(
                option =>
                  (option === HISTOGRAM_CHANNEL
                    ? 'histogram'
                    : channelByName[option as string]?.__computed__displayName
                  )
                    ?.toLowerCase()
                    .includes(state.inputValue.toLowerCase()),
              )
            }}
            renderOption={option => (
              <ChannelSelectorOption>
                <span>
                  {option === HISTOGRAM_CHANNEL
                    ? 'Histogram'
                    : channelByName[option as string]?.__computed__displayName}
                </span>
                <span>
                  {clusteringChannels.includes(option) && 'Used for clustering'}
                </span>
              </ChannelSelectorOption>
            )}
            value={value}
            renderInput={params => (
              <StyledInputBase
                autoFocus
                ref={params.InputProps.ref}
                inputProps={params.inputProps}
              />
            )}
            classes={{
              popperDisablePortal: 'disable-portal',
              paper: 'paper',
              option: 'option',
            }}
            onChange={(_event, value) => {
              if (value) {
                handleChannelClick({ [axis]: value as string })
                setIsOpen(false)
              }
            }}
          />
        </ClickAwayListener>
      </StyledPopper>
    </AnalysisChartChannelSelectorRoot>
  )
}

const AnalysisChartChannelSelectorRoot = styled.div<{
  $axis: 'x_axis' | 'y_axis'
}>`
  ${props =>
    props.$axis === 'x_axis' &&
    css`
      grid-area: x-axis;
      place-self: center;
    `}

  ${props =>
    props.$axis === 'y_axis' &&
    css`
      --width: 30px;
      display: flex;
      align-items: center;
      width: var(--width);
      height: 100%;
      grid-area: y-axis;
      & > * {
        flex-shrink: 0;
        transform: translate(calc(-50% + calc(var(--width) / 2))) rotate(-90deg);
      }
    `}
`

const ChannelLabel = styled.div<{ $isDisabled?: boolean }>`
  display: flex;
  align-items: center;
  font-weight: bold;
  ${props =>
    !props.$isDisabled &&
    css`
      cursor: pointer;
    `}
`

const StyledPopper = styled(Popper)`
  border: 1px solid ${props => props.theme.colors.greyscale[20]};
  box-shadow:
    0px 5px 5px -3px rgba(0, 0, 0, 0.2),
    0px 8px 10px 1px rgba(0, 0, 0, 0.14),
    0px 3px 14px 2px rgba(0, 0, 0, 0.12);
  border-radius: 4px;
  background: ${props => props.theme.colors.white};

  .disable-portal {
    position: relative;
  }

  .paper {
    box-shadow: none;
    margin: 0;
  }

  .option {
    color: ${props => props.theme.colors.primaryDark[100]};
    font-size: ${props => props.theme.font.size.small}px;
    background: ${props => props.theme.colors.white};

    :hover {
      background: ${props => props.theme.colors.background};
    }

    &[aria-selected='true'] {
      background: ${props => props.theme.colors.primary[20]};
    }
  }
`

const StyledAutocomplete = styled(Autocomplete<string>)`
  min-width: 300px;
`

const StyledInputBase = styled(InputBase)`
  width: 100%;
  border-bottom: 1px solid ${props => props.theme.colors.greyscale[20]};
  padding: 8px;
  color: ${props => props.theme.colors.primaryDark[100]};
  font-size: ${props => props.theme.font.size.small}px;
  font-weight: bold;
`

const ChannelSelectorOption = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;

  & span:nth-child(2) {
    font-size: ${props => props.theme.font.size.smallest}px;
    color: ${props => props.theme.colors.greyscale[50]};
  }
`
