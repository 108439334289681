import { CircularProgress, Grid } from '@material-ui/core'
import { Pagination } from '@material-ui/lab'
import React from 'react'
import styled from 'styled-components'

import { LoadingMask } from 'components/LoadingMask'
import { TableColumn } from 'components/TableColumn'

import { useScrollTop } from 'shared/hooks/useScrollTop'
import { OrganizationInvitation } from 'shared/models/OrganizationModels'
import { SortingOrder } from 'shared/utils/array'

import { InvitationRow } from './InvitationRow'

const columnHeadersWithSize = [
  { header: 'E-mail', size: 4 },
  { header: 'Created', size: 2 },
  { header: 'Send date', size: 2 },
  { header: 'Status', size: 1 },
  { header: 'Actions', size: 3 },
] as const

export type InvitationHeader = (typeof columnHeadersWithSize)[number]['header']

type InvitationsTableProps = {
  isLoading: boolean
  isFetching: boolean
  invitations: OrganizationInvitation[]
  page: number
  numberOfPages: number
  sortingCategory: InvitationHeader
  sortingOrder: SortingOrder
  onPageChange: (page: number) => void
  onSortingCategoryChange: (sortingCategory: InvitationHeader) => void
  onSortingOrderChange: (sortingOrder: SortingOrder) => void
}

export const InvitationsTable: React.FC<InvitationsTableProps> = ({
  isLoading,
  isFetching,
  invitations,
  page,
  numberOfPages,
  sortingCategory,
  sortingOrder,
  onPageChange,
  onSortingCategoryChange,
  onSortingOrderChange,
}) => {
  const { scrollTop, onScroll } = useScrollTop()

  if (isLoading) {
    return <LoadingMask />
  }

  return (
    <TableWrapper>
      <InvitationsHeader container spacing={0}>
        {columnHeadersWithSize.map(({ header, size }) => (
          <TableColumn<InvitationHeader>
            key={header}
            columnName={header}
            size={size}
            currentSortingCategory={sortingCategory}
            currentSortingOrder={sortingOrder}
            setSortingCategory={onSortingCategoryChange}
            setSortingOrder={onSortingOrderChange}
          />
        ))}
      </InvitationsHeader>
      {invitations.length ? (
        <>
          <TableBodyWrapper>
            <ScrollableContainer scrollTop={scrollTop} onScroll={onScroll}>
              {invitations.map(
                ({ id, email, created_at, send_date, status }) => (
                  <InvitationRow
                    key={id}
                    id={id}
                    email={email}
                    created={created_at}
                    sendDate={send_date || undefined}
                    status={status}
                  />
                ),
              )}
            </ScrollableContainer>
            {isFetching && !isLoading && (
              <RefetchOverlay>
                <CircularProgress />
              </RefetchOverlay>
            )}
          </TableBodyWrapper>
          <PaginationContainer>
            <Pagination
              count={numberOfPages}
              onChange={(_event, page) => onPageChange(page)}
              page={page}
            />
          </PaginationContainer>
        </>
      ) : (
        <EmptyListMessageContainer>
          No invitations found
        </EmptyListMessageContainer>
      )}
    </TableWrapper>
  )
}

const TableWrapper = styled.div`
  width: 100%;
  margin: 20px 0;
`

const InvitationsHeader = styled(Grid)`
  color: ${props => props.theme.colors.greyscale[50]};
  font-family: ${props => props.theme.font.style.bold};
  align-items: center;
  padding: 0 10px 10px;
`

const TableBodyWrapper = styled.div`
  position: relative;
`

const ScrollableContainer = styled.div<{ scrollTop: number }>`
  width: calc(100% + 16px);
  max-height: calc(50vh - 220px);
  overflow-x: hidden;
  overflow-y: scroll;
  box-shadow: ${props =>
    props.scrollTop > 0 ? 'inset 0px 12px 9px -9px rgb(0 0 0 / 15%)' : 'none'};
  transition: box-shadow 0.3s;
`

const RefetchOverlay = styled.div`
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  top: 0;
  width: 100%;
  height: 100%;
  // the 80 adds alpha channel to hex color code, representing 50% opacity
  background: ${props => props.theme.colors.background}80;
`

const PaginationContainer = styled.div`
  display: flex;
  justify-content: center;
  margin-top: ${props => props.theme.spacing(3)}px;
`

const EmptyListMessageContainer = styled.div`
  height: 64px;
  display: flex;
  justify-content: center;
  margin-top: ${props => props.theme.spacing(4)}px;
`
