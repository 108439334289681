import {
  CircularProgress,
  Grid,
  Menu,
  MenuItem,
  Typography,
} from '@material-ui/core'
import {
  CloudDownload as DownloadIcon,
  Delete as DeleteIcon,
} from '@material-ui/icons'
import React, { useState } from 'react'
import styled from 'styled-components'

import { Icon } from 'assets/images/icons/Icon'

import { Button } from 'components/Button'
import { Modal } from 'components/Modal'

import { ExportTask, useDeleteExportTaskMutation } from 'shared/api/export.api'
import { useAppDispatch } from 'shared/store'
import { showNotification } from 'shared/store/notification.slice'
import { downloadFile, formatDateTime, href } from 'shared/utils/utils'

type ExportTaskRowProps = {
  id: string
  exportType: ExportTask['export_class']
  fileName?: string
  format?: ExportTask['format']
  createdAt?: Date
  updatedAt?: Date
  status: ExportTask['status']
  statusText: ExportTask['status_display']
  is_stale?: ExportTask['is_stale']
}

export const ExportTaskRow = ({
  id,
  exportType,
  fileName,
  format,
  createdAt,
  updatedAt,
  status,
  statusText,
  is_stale,
}: ExportTaskRowProps): JSX.Element => {
  const dispatch = useAppDispatch()
  const [deleteExportTask] = useDeleteExportTaskMutation()

  const [actionsMenuAnchor, setActionsMenuAnchor] =
    useState<HTMLElement | null>(null)

  const [shouldShowDeleteModal, setShouldShowDeleteModal] = useState(false)
  const [isFileDownloading, setFileDownloading] = useState(false)

  const handleDownloadFile = async () => {
    setFileDownloading(true)
    downloadFile(href(`/exports/${id}/export_file/`), fileName).then(
      () => {
        dispatch(
          showNotification({
            type: 'success',
            description: 'File downloaded successfully',
          }),
        )
        setFileDownloading(false)
        setActionsMenuAnchor(null)
      },
      () => {
        dispatch(
          showNotification({
            type: 'error',
            description: 'Cannot download file',
          }),
        )
      },
    )
  }

  const handleOpenDeleteModal = () => {
    setShouldShowDeleteModal(true)
    setActionsMenuAnchor(null)
  }

  const handleDeleteExportTask = async () => {
    deleteExportTask({ id }).then(
      () => {
        dispatch(
          showNotification({
            type: 'success',
            description: 'Export task and file deleted succesfully',
          }),
        )
        setShouldShowDeleteModal(false)
      },
      () => {
        dispatch(
          showNotification({
            type: 'error',
            description: 'Could not delete export task',
          }),
        )
      },
    )
  }

  return (
    <RowWrapper container spacing={0} disabled={is_stale}>
      <Grid item xs={2}>
        <EllipsisText title={exportType}>{exportType}</EllipsisText>
      </Grid>
      <Grid item xs={3}>
        <EllipsisText title={fileName || '-'}>{fileName || '-'}</EllipsisText>
      </Grid>
      <Grid item xs={1}>
        <EllipsisText title={format?.toUpperCase() || '-'}>
          {format?.toUpperCase() || '-'}
        </EllipsisText>
      </Grid>
      <Grid item xs={2}>
        <EllipsisText title={createdAt ? formatDateTime(createdAt) : '-'}>
          {createdAt ? formatDateTime(createdAt) : '-'}
        </EllipsisText>
      </Grid>
      <Grid item xs={2}>
        <EllipsisText title={updatedAt ? formatDateTime(updatedAt) : '-'}>
          {updatedAt ? formatDateTime(updatedAt) : '-'}
        </EllipsisText>
      </Grid>
      <Grid item xs={1}>
        <EllipsisText
          $status={
            is_stale
              ? undefined
              : status === 'E'
              ? 'error'
              : status === 'S'
              ? 'success'
              : undefined
          }
          title={statusText}
        >
          {is_stale ? 'Out dated' : statusText}
        </EllipsisText>
      </Grid>
      <Grid item xs={1} title={'Actions'}>
        <ActionsWrapper>
          <div onClick={event => setActionsMenuAnchor(event.currentTarget)}>
            <StyledIcon name="cog" />
          </div>
          <Menu
            open={!!actionsMenuAnchor}
            anchorEl={actionsMenuAnchor}
            onClose={() => setActionsMenuAnchor(null)}
          >
            {fileName && (
              <StyledMenuItem
                onClick={isFileDownloading ? undefined : handleDownloadFile}
                $disabled={isFileDownloading}
              >
                {isFileDownloading ? (
                  <StyledCircularProgress />
                ) : (
                  <DownloadIcon />
                )}
                Download
              </StyledMenuItem>
            )}
            <StyledMenuItem $warning onClick={handleOpenDeleteModal}>
              <DeleteIcon />
              Delete
            </StyledMenuItem>
          </Menu>
        </ActionsWrapper>
      </Grid>
      {shouldShowDeleteModal && (
        <Modal
          open={shouldShowDeleteModal}
          onClose={() => setShouldShowDeleteModal(false)}
        >
          <ModalContainer>
            <Typography variant="body1" align="center">
              Delete selected export task? All generated files will also be
              deleted.
            </Typography>
            <ModalButtonsContainer>
              <Button grey onClick={() => setShouldShowDeleteModal(false)}>
                Cancel
              </Button>
              <Button onClick={handleDeleteExportTask}>Delete</Button>
            </ModalButtonsContainer>
          </ModalContainer>
        </Modal>
      )}
    </RowWrapper>
  )
}

const RowWrapper = styled(Grid)<{ disabled?: boolean }>`
  color: ${props => props.theme.colors.primaryDark[100]};
  align-items: center;
  border-bottom: 1px solid ${props => props.theme.colors.primaryDark[20]};
  padding: 0 10px 10px;
  margin: 10px 0;
  ${props =>
    props.disabled &&
    `opacity: 0.5;
    pointer-events: none;
  `}
`

const EllipsisText = styled.p<{ $status?: 'error' | 'success' }>`
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  padding-right: 16px;
  ${props => props.$status && `color: ${props.theme.colors[props.$status]};`}
`

const ActionsWrapper = styled.div`
  display: flex;
  cursor: pointer;
`

const StyledIcon = styled(Icon)`
  width: 20px;
  height: 20px;
`

const StyledMenuItem = styled(MenuItem)<{
  $warning?: boolean
  $disabled?: boolean
}>`
  font-size: ${props => props.theme.font.size.smallest}px;
  width: 170px;
  height: 52px;
  border-bottom: 1px solid ${props => props.theme.colors.primaryDark[20]};
  color: ${props =>
    props.$warning
      ? props.theme.colors.error
      : props.$disabled
      ? props.theme.colors.primaryDark[50]
      : props.theme.colors.primaryDark[100]};
  ${props => props.$disabled && `pointer-events: none;`}
  > svg {
    margin-right: ${props => props.theme.spacing(1)}px;
  }
`

const ModalContainer = styled.div`
  background: ${props => props.theme.colors.white};
  padding: ${props => props.theme.spacing(4, 8)};
  border-radius: ${props => props.theme.radius[2]}px;
  box-shadow: ${props => props.theme.shadow.tooltip};
  margin: 0 auto;
  width: 450px;
`

const ModalButtonsContainer = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
  gap: ${props => props.theme.spacing(3)}px;
  margin-top: ${props => props.theme.spacing(3)}px;
`

const StyledCircularProgress = styled(CircularProgress)`
  height: 24px !important;
  width: 24px !important;
  margin-right: ${props => props.theme.spacing(1)}px;
  color: ${props => props.theme.colors.primaryDark[50]};
`
