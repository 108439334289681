import { RootState } from 'shared/store'

import { ChannelsSortMode, ClustersSortMode } from '../analysis.history.slice'

export const selectClustersSortMode = (
  state: RootState,
): ClustersSortMode | undefined =>
  state.analysisPage.analysis.history.present.ui.clustersSortMode

export const selectChannelsSortMode = (
  state: RootState,
): ChannelsSortMode | undefined =>
  state.analysisPage.analysis.history.present.ui.channelsSortMode
