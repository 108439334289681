import { ZoomOut } from '@material-ui/icons'
import { SelectEventObject } from 'highcharts'
import { mapValues } from 'lodash'
import { FC, useEffect, useMemo, useRef, useState } from 'react'
import { DistributiveOmit } from 'react-redux'
import { useError, usePrevious } from 'react-use'
import styled from 'styled-components'

import { DefaultErrorBoundary } from 'components/DefaultErrorBoundary'
import { Modal } from 'components/Modal'
import Select from 'components/forms/Select'
import { HighPerformanceScatterPlotBase } from 'components/graphs/HighPerformanceScatterPlotBase'

import { ScatterPlotChartScale } from 'pages/analysis/store/selectors'

import { FcsFile } from 'shared/api/files.api'
import {
  MetaAnalysisFile,
  MetaAnalysisDimensionalityReductionChartType,
  MetaAnalysisGlobalVizFile,
} from 'shared/api/meta-analysis.api'
import { useEventCallback } from 'shared/hooks/useEventCallback'
import { useObserve } from 'shared/hooks/useObserve'
import { useStable } from 'shared/hooks/useStable'
import { useAppDispatch, useAppSelector } from 'shared/store'
import { handleError } from 'shared/utils/errorHandler'
import { downloadText } from 'shared/utils/utils'
import { metaAnalysisWorker } from 'shared/worker'
import { ComputeDimensionalityReductionSeriesReturnValue } from 'shared/worker/meta-analysis-worker'

import { MetaAnalysisChartContainer } from './MetaAnalysisChartContainer'
import { createMetaAnalysisDimensionalityReductionChartBaseOptions } from './MetaAnalysisDimensionalityReductionChartBaseOptions'
import { MetaAnalysisDimensionalityReductionChartOptions } from './MetaAnalysisDimensionalityReductionChartOptions'
import { MetaAnalysisDimensionalityReductionChartTooltip } from './MetaAnalysisDimensionalityReductionChartTooltip'
import { updateMetaAnalysisChart } from './store/meta-analysis.history.slice'
import {
  selectMetaAnalysisChannels,
  selectMetaAnalysisDimensionalityReductionMethod,
  selectMetaAnalysisFcsFiles,
  selectMetaAnalysisFile,
  selectMetaAnalysisGlobalVizFile,
  selectMetaAnalysisName,
} from './store/selectors'
import { useMetaAnalysisCsvMetadata } from './useMetaAnalysisCsvMetadata'

type MetaAnalysisDimensionalityReductionChartProps = {
  chart: MetaAnalysisDimensionalityReductionChartType
  isExpanded?: boolean
  className?: string
  onCloseExpand?: () => void
}

export const MetaAnalysisDimensionalityReductionChart: FC<
  MetaAnalysisDimensionalityReductionChartProps
> = ({ chart, isExpanded, className, onCloseExpand }) => {
  const dispatch = useAppDispatch()
  const metaAnalysisName = useAppSelector(selectMetaAnalysisName)
  const metaAnalysisFile = useAppSelector(selectMetaAnalysisFile)
  const globalVizFile = useAppSelector(selectMetaAnalysisGlobalVizFile)
  const fcsFiles = useAppSelector(selectMetaAnalysisFcsFiles)
  const dimensionalityReductionMethod = useAppSelector(
    selectMetaAnalysisDimensionalityReductionMethod,
  )
  const channels = useAppSelector(selectMetaAnalysisChannels)

  if (dimensionalityReductionMethod === null) {
    throw handleError(
      new Error('Current meta-analysis has no dimensionality reduction'),
    )
  }

  const createCsvMetadata = useMetaAnalysisCsvMetadata()

  const [shouldDisplayChartOptions, setShouldDisplayChartOptions] =
    useState(false)
  const [shouldShowExpandedSelf, setShouldShowExpandedSelf] = useState(false)
  const [zoom, setZoom] = useState<ScatterPlotChartScale>()
  const [areSeriesDefined, setAreSeriesDefined] = useState(false)

  const handleChangeOptions = useEventCallback(() => {
    setShouldDisplayChartOptions(true)
  })

  const handleCancel = useEventCallback(() => {
    setShouldDisplayChartOptions(false)
  })

  const handleApplyOptions = useEventCallback(
    (
      values: Partial<
        DistributiveOmit<
          MetaAnalysisDimensionalityReductionChartType,
          'id' | 'name' | 'type'
        >
      >,
    ) => {
      dispatch(
        updateMetaAnalysisChart({
          id: chart.id,
          ...values,
        }),
      )
      setShouldDisplayChartOptions(false)
    },
  )

  const handleDownload = useEventCallback(async () => {
    if (!globalVizFile) {
      throw new Error('Global viz file is not loaded')
    }

    const csv =
      (await createCsvMetadata()) +
      (await metaAnalysisWorker.computeDimensionalityReductionCsv({
        fcsFiles,
        globalVizFile,
        metaAnalysisFile,
        chart,
      }))
    downloadText(csv, `${metaAnalysisName} - ${chart.name}.csv`)
  })

  return (
    <MetaAnalysisChartContainer
      chart={chart}
      isExpanded={isExpanded}
      className={className}
      actions={
        zoom
          ? [
              {
                tooltip: 'Reset zoom',
                icon: <ZoomOut />,
                onClick: () => setZoom(undefined),
              },
            ]
          : undefined
      }
      onChangeOptions={handleChangeOptions}
      onExpand={() => setShouldShowExpandedSelf(true)}
      onCloseExpand={onCloseExpand}
      onDownload={areSeriesDefined ? handleDownload : undefined}
    >
      {shouldDisplayChartOptions && (
        <StyledMetaAnalysisGlobalHeatMapChartOptions
          mode="edit"
          initialValues={chart}
          onCancel={handleCancel}
          onFinish={handleApplyOptions}
        />
      )}
      <ChartAndLegend>
        <DefaultErrorBoundary>
          <InnerPlot
            chart={chart}
            zoom={zoom}
            onChangeZoom={setZoom}
            onAreSeriesDefinedChange={setAreSeriesDefined}
          />
        </DefaultErrorBoundary>
        {chart.mode === 'cluster/intensity' && (
          <HeatMapLegend>
            1 <HeatmapLegendGradient /> 0
          </HeatMapLegend>
        )}
        {chart.mode === 'cluster/intensity' && (
          <div>
            <Select
              name="colorBy"
              value={chart.colorBy}
              label="Color by"
              options={[
                { value: 'cluster', label: 'Cluster' },
                { value: 'event', label: 'Event' },
              ]}
              onChange={event => {
                handleApplyOptions({
                  colorBy: event.target.value as 'cluster' | 'event',
                })
              }}
            />
            <Select
              name="selectedChannel"
              value={chart.selectedChannel || '__none__'}
              label="Intensity"
              options={[
                { value: '__none__', label: 'None' },
                ...channels.map(channel => ({
                  value: channel,
                  label: channel,
                })),
              ]}
              onChange={event => {
                const value = event.target.value as string
                handleApplyOptions({
                  selectedChannel: value === '__none__' ? undefined : value,
                })
              }}
            />
          </div>
        )}
      </ChartAndLegend>
      {shouldShowExpandedSelf && (
        <Modal open onClose={() => setShouldShowExpandedSelf(false)}>
          <ExpandedMetaAnalysisDimensionalityReductionChart
            chart={chart}
            onCloseExpand={() => setShouldShowExpandedSelf(false)}
          />
        </Modal>
      )}
    </MetaAnalysisChartContainer>
  )
}

type InnerPlotProps = Pick<
  MetaAnalysisDimensionalityReductionChartProps,
  'chart'
> & {
  zoom: ScatterPlotChartScale | undefined
  onChangeZoom: (zoom: ScatterPlotChartScale | undefined) => void
  onAreSeriesDefinedChange: (areSeriesDefined: boolean) => void
}

const InnerPlot: FC<InnerPlotProps> = ({
  chart,
  zoom,
  onChangeZoom,
  onAreSeriesDefinedChange,
}) => {
  const metaAnalysisFile = useAppSelector(selectMetaAnalysisFile)
  const globalVizFile = useAppSelector(selectMetaAnalysisGlobalVizFile)
  const fcsFiles = useAppSelector(selectMetaAnalysisFcsFiles)
  const dimensionalityReductionMethod = useAppSelector(
    selectMetaAnalysisDimensionalityReductionMethod,
  )

  if (dimensionalityReductionMethod === null) {
    throw handleError(
      new Error('Current meta-analysis has no dimensionality reduction'),
    )
  }

  const [highchartsChart, setHighchartsChart] = useState<Highcharts.Chart>()

  const {
    colorBySeriesId,
    metadataBySeriesId,
    series,
    scale,
    isComputingSeriesRef,
  } = useSeries(
    metaAnalysisFile,
    globalVizFile,
    fcsFiles,
    highchartsChart,
    zoom,
    chart,
  )
  useObserve(!!series, onAreSeriesDefinedChange)

  const handleZoom = useEventCallback((e: SelectEventObject) => {
    onChangeZoom({
      xAxis: {
        min: e.xAxis[0].min,
        max: e.xAxis[0].max,
      },
      yAxis: {
        min: e.yAxis[0].min,
        max: e.yAxis[0].max,
      },
    })
    return false
  })

  const options: Highcharts.Options = useMemo(() => {
    const MetaAnalysisDimensionalityReductionChartBaseOptions =
      createMetaAnalysisDimensionalityReductionChartBaseOptions(
        dimensionalityReductionMethod,
      )

    return {
      ...MetaAnalysisDimensionalityReductionChartBaseOptions,
      chart: {
        ...MetaAnalysisDimensionalityReductionChartBaseOptions.chart,
        events: {
          selection: handleZoom,
        },
      },
    }
  }, [dimensionalityReductionMethod, handleZoom])

  return (
    <HighPerformanceScatterPlotBase
      options={options}
      series={series}
      isComputingSeriesRef={isComputingSeriesRef}
      scale={zoom ?? scale}
      colorBySeriesId={colorBySeriesId}
      onHighchartsChartChange={setHighchartsChart}
      Tooltip={MetaAnalysisDimensionalityReductionChartTooltip}
      tooltipProps={{ metadataBySeriesId }}
    />
  )
}

const ChartAndLegend = styled.div`
  display: grid;
  grid-template-rows: 1fr auto;
  grid-template-columns: 1fr auto;
  height: 100%;
`

const StyledMetaAnalysisGlobalHeatMapChartOptions = styled(
  MetaAnalysisDimensionalityReductionChartOptions,
)`
  position: absolute;
  top: 0;
  left: 0;
  z-index: 2;
`

const ExpandedMetaAnalysisDimensionalityReductionChart = styled(
  MetaAnalysisDimensionalityReductionChart,
).attrs({ isExpanded: true })`
  width: 90vw;
  height: 90vh;
`

const HeatMapLegend = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: 12px;
  gap: 7px;
  font-family: ${props => props.theme.font.style.bold};
`

const HeatmapLegendGradient = styled.span`
  display: inline-block;
  height: 200px;
  width: 16px;
  border-radius: 5px;
  background: linear-gradient(
    0turn,
    hsl(240, 100%, 50%),
    hsl(180, 100%, 50%),
    hsl(120, 100%, 50%),
    hsl(60, 100%, 50%),
    hsl(0, 100%, 50%)
  );
`

const useSeries = (
  metaAnalysisFile: MetaAnalysisFile,
  globalVizFile: MetaAnalysisGlobalVizFile | undefined,
  fcsFiles: FcsFile[],
  highchartsChart: Highcharts.Chart | undefined,
  zoom: ScatterPlotChartScale | undefined,
  chart: MetaAnalysisDimensionalityReductionChartType,
) => {
  const throwError = useError()
  const isComputingSeriesRef = useRef(false)
  const [scale, setScale] = useState<ScatterPlotChartScale>()

  const [metadataBySeriesId, setMetadataBySeriesId] =
    useState<
      ComputeDimensionalityReductionSeriesReturnValue['metadataBySeriesId']
    >()
  const [series, setSeries] = useState<{
    seriesIdByPixelPosition: Map<string, string>
    plotWidth: number
    plotHeight: number
  }>()

  const previousZoom = usePrevious(zoom)

  const colorBySeriesId = useMemo(
    () => mapValues(metadataBySeriesId, 'color'),
    [metadataBySeriesId],
  )

  const computationParams = useStable(
    useMemo(() => {
      if (!globalVizFile || previousZoom !== zoom) {
        return undefined
      }

      return {
        plotWidth: highchartsChart?.plotWidth,
        plotHeight: highchartsChart?.plotHeight,
        metaAnalysisFile,
        globalVizFile,
        fcsFiles,
        zoom,
        chart,
      }
    }, [
      chart,
      fcsFiles,
      globalVizFile,
      highchartsChart?.plotHeight,
      highchartsChart?.plotWidth,
      metaAnalysisFile,
      previousZoom,
      zoom,
    ]),
  )

  useEffect(() => {
    let cancelled = false

    if (computationParams) {
      isComputingSeriesRef.current = true
      metaAnalysisWorker
        .computeDimensionalityReductionSeries(computationParams)
        .then(({ series, scale, metadataBySeriesId }) => {
          if (!cancelled) {
            setSeries(series)
            setScale(scale)
            setMetadataBySeriesId(metadataBySeriesId)

            if (series) {
              isComputingSeriesRef.current = false
            }
          }
        })
        .catch(throwError)
    }

    return () => {
      cancelled = true
    }
  }, [computationParams, throwError])

  return {
    series,
    scale,
    metadataBySeriesId,
    colorBySeriesId,
    isComputingSeriesRef,
  }
}
