import { Tooltip } from '@material-ui/core'
import { FC, ReactNode } from 'react'
import styled from 'styled-components'

type WorkflowNodeLabelProps = {
  children: ReactNode
  tooltip?: string
}

export const WorkflowNodeLabel: FC<WorkflowNodeLabelProps> = ({
  children,
  tooltip,
}) => {
  return (
    <Tooltip title={tooltip ?? ''} enterDelay={500}>
      <WorkflowNodeLabelRoot>{children}</WorkflowNodeLabelRoot>
    </Tooltip>
  )
}

const WorkflowNodeLabelRoot = styled.div`
  min-height: 0px;
  min-width: 0px;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  max-height: 100%;
`
