import { createSelector } from '@reduxjs/toolkit'
import localforage from 'localforage'

import { ExperimentDetails } from 'shared/api/experiments.api'
import { CompensatedFile, FcsFile } from 'shared/api/files.api'
import { PipelineDetails } from 'shared/api/pipelines.api'
import { Project } from 'shared/api/projects.api'
import { BrickDetails, WorkflowDetails } from 'shared/api/workflows.api'
import {
  CUMULATIVE_FLUORESCENCE_CHANNEL,
  OTHER_CHANNEL_TYPE,
  OTHER_LETTER,
  TIME_CHANNEL,
  UMAP_X_CHANNEL,
  UMAP_Y_CHANNEL,
} from 'shared/constants'
import { Analysis } from 'shared/models/AnalysisModels'
import { RootState, SliceStatus } from 'shared/store'
import { convertWorkflowModeToWorkflowEntity } from 'shared/utils/api'
import { getChannelDisplayName } from 'shared/utils/channels.utils'
import { groupByUnique } from 'shared/utils/collection.utils'
import { getCurrentLayoutKey } from 'shared/utils/multi-tab'

import {
  AnalysisSliceState,
  SelfOrganizingHeatMapQuery,
} from '../analysis.slice'
import { Channel } from './channels.selectors'

export const selectAnalysisLoadingStatus = (state: {
  analysisPage: { analysis: AnalysisSliceState }
}): SliceStatus => state.analysisPage.analysis.status

export const selectAnalysisOrUndefined = (state: {
  analysisPage: { analysis: AnalysisSliceState }
}): Analysis | undefined => state.analysisPage.analysis.history.present.analysis

export const selectAnalysis = (state: {
  analysisPage: { analysis: AnalysisSliceState }
}): Analysis => {
  const analysis = selectAnalysisOrUndefined(state)
  if (!analysis) {
    throw new Error('analysis is undefined')
  }
  return analysis
}

export const selectAnalysisStatus = createSelector(
  selectAnalysis,
  analysis => analysis.status,
)

export const selectIsAnalysisBeingSaved = (state: {
  analysisPage: { analysis: AnalysisSliceState }
}): boolean => {
  return state.analysisPage.analysis.isAnalysisBeingSaved ?? false
}

export const selectIsAnalysisProtected = (state: RootState): boolean =>
  state.analysisPage.analysis.isAnalysisProtected ?? true

export const selectIsAnalysisApproved = createSelector(
  selectAnalysisStatus,
  status => {
    return status === 'Approved'
  },
)

export const selectAnalysisClusterTree = createSelector(
  selectAnalysis,
  analysis => analysis.cluster_tree,
)

export const selectAnalysisClusterTreeNodeById = createSelector(
  selectAnalysisClusterTree,
  clusterTree => {
    const clusterNodeById: Record<string, Analysis.ClusterNode> = {}
    const traverseClusterTree = (tree: Analysis.ClusterTree) => {
      for (const [id, node] of Object.entries(tree)) {
        clusterNodeById[id] = node
        traverseClusterTree(node.children)
      }
    }
    traverseClusterTree(clusterTree)
    return clusterNodeById
  },
)

export const selectAnalysisId = createSelector(
  selectAnalysis,
  analysis => analysis.id,
)

export const selectAnalysisIdOrUndefined = createSelector(
  selectAnalysisOrUndefined,
  analysis => analysis?.id,
)

export const selectAnalysisName = createSelector(
  selectAnalysis,
  analysis => analysis.name,
)

export const selectAnalysisBrick = (state: {
  analysisPage: { analysis: AnalysisSliceState }
}): BrickDetails => {
  const { brick } = state.analysisPage.analysis
  if (!brick) {
    throw new Error('brick is undefined')
  }
  return brick
}

export const selectAnalysisWorkflow = (state: {
  analysisPage: { analysis: AnalysisSliceState }
}): WorkflowDetails | PipelineDetails | ExperimentDetails => {
  const { workflow } = state.analysisPage.analysis
  if (!workflow) {
    throw new Error('workflow is undefined')
  }
  return workflow
}

export const selectAnalysisFcsFile = (state: {
  analysisPage: { analysis: AnalysisSliceState }
}): FcsFile => {
  const { fcsFile } = state.analysisPage.analysis
  if (!fcsFile) {
    throw new Error('fcsFile is undefined')
  }
  return fcsFile
}

export const selectAnalysisCompensatedFile = (state: {
  analysisPage: { analysis: AnalysisSliceState }
}): CompensatedFile => {
  const { compensatedFile } = state.analysisPage.analysis
  if (!compensatedFile) {
    throw new Error('compensatedFile is undefined')
  }
  return compensatedFile
}

export const selectAnalysisProject = (state: {
  analysisPage: { analysis: AnalysisSliceState }
}): Project => {
  const { project } = state.analysisPage.analysis
  if (!project) {
    throw new Error('project is undefined')
  }
  return project
}

export const selectAnalysisSettings = createSelector(
  selectAnalysisBrick,
  brick => brick.settings,
)

export const selectAnalysisLassos = createSelector(
  selectAnalysis,
  analysis => analysis.lassos,
)

export const selectAnalysisChannelNames = createSelector(
  selectAnalysis,
  analysis => analysis.channel_names,
)

export const selectClusteringChannels = createSelector(
  selectAnalysis,
  analysis => {
    const channels: string[] = []
    for (const channel of Object.values(analysis.channels)) {
      if (channel.is_selected) {
        channels.push(channel.id)
      }
    }
    return channels
  },
)

export const selectAnalysisSortedSelectedChannels = createSelector(
  selectAnalysis,
  analysis => analysis.sorted_selected_channels,
)

export const selectHeatmapColorsFromAnalysis = createSelector(
  selectAnalysis,
  analysis => analysis.heatmap_colors,
)

export const selectAnalysisStoreName = createSelector(
  selectAnalysisId,
  analysisId => {
    return getAnalysisStoreName(analysisId)
  },
)

export const getAnalysisStoreName = (analysisId: string): string => {
  return analysisId.replace(/-/g, '_')
}

export const selectAnalysisStore = createSelector(
  selectAnalysisStoreName,
  storeName => {
    return localforage.createInstance({
      driver: localforage.INDEXEDDB,
      name: 'METAFORA',
      storeName,
    })
  },
)

export const selectHiddenClusterIds = createSelector(
  selectAnalysis,
  analysis => analysis.hidden_cluster_ids,
)

export const selectActiveLeafIds = createSelector(
  selectAnalysis,
  analysis => analysis.active_leaf_ids,
)

export const selectAnalysisGraphs = createSelector(
  selectAnalysis,
  analysis => analysis.graphs,
)

export const selectLayout = createSelector(selectAnalysis, analysis => {
  return analysis.layout
})

export const selectCurrentLayout = createSelector(selectLayout, layout => {
  return layout[getCurrentLayoutKey()]
})

export const selectDefaultDepth = createSelector(
  selectAnalysis,
  (analysis): number => {
    return analysis.default_depth
  },
)

export const selectCurrentDepth = createSelector(
  selectAnalysis,
  (analysis): number => {
    return analysis.depth
  },
)

export const selectScalesFromAnalysis = (state: RootState): Analysis.Scales => {
  const { scales } = state.analysisPage.analysis
  if (!scales) {
    throw new Error('scales are undefined')
  }
  return scales
}

export const selectCanUndo = (state: RootState): boolean =>
  state.analysisPage.analysis.history.past.length > 0

export const selectCanRedo = (state: RootState): boolean =>
  state.analysisPage.analysis.history.future.length > 0

export const selectIsAnalysisSaved = (state: RootState): boolean =>
  state.analysisPage.analysis.history.present.isAnalysisSaved

export const selectAnalysisWorkflowId = createSelector(
  selectAnalysis,
  analysis => analysis.workflow_id,
)

export const selectAnalysisStatistics = createSelector(
  selectAnalysis,
  analysis => analysis.statistics,
)

export const selectAnalysisStatisticsById = createSelector(
  selectAnalysisStatistics,
  statistics => {
    return groupByUnique(statistics, 'id')
  },
)

export const selectNextFreeStatisticsName = createSelector(
  selectAnalysisStatistics,
  statistics => {
    let i = statistics.length + 1
    let name = `Statistics ${i}`
    while (statistics.some(chart => chart.name === name)) {
      i++
      name = `Statistics ${i}`
    }
    return name
  },
)

export const selectAnalysisAuthorName = createSelector(
  selectAnalysis,
  analysis => analysis.author_name,
)

export const selectAnalysisWorkflowEntity = createSelector(
  selectAnalysis,
  analysis => {
    return convertWorkflowModeToWorkflowEntity(analysis.workflow_mode)
  },
)

export type AnalysisAccessMode = 'experiment' | 'read-and-write' | 'read-only'

export const selectAnalysisAccessMode = createSelector(
  selectAnalysisWorkflowEntity,
  (workflowEntity): AnalysisAccessMode => {
    return workflowEntity === 'experiment' ? 'experiment' : 'read-and-write'
  },
)

export const selectSelfOrganizingHeatMapQuery = (
  state: RootState,
): SelfOrganizingHeatMapQuery | undefined => {
  return state.analysisPage.analysis.selfOrganizingHeatMapQuery
}

export const selectChannels = createSelector(
  selectAnalysisFcsFile,
  selectAnalysisChannelNames,
  selectAnalysisSettings,
  (file, channelNames, settings): Channel[] => {
    if (!file) {
      return []
    }

    const channels = Object.entries(file.channels).map(
      ([id, { type, PnS, letter }]) => {
        const label = channelNames?.[id]
        const defaultLabel = PnS ?? undefined

        return {
          id,
          type: ['morpho', 'fluo'].includes(type) ? type : OTHER_CHANNEL_TYPE,
          PnS,
          letter: letter ?? OTHER_LETTER,
          label: channelNames?.[id],
          defaultLabel: PnS ?? undefined,
          __computed__displayName: getChannelDisplayName({
            id: id,
            label,
            defaultLabel,
          }),
        }
      },
    )
    if (settings?.metaclean) {
      const label = 'Cumulative Fluorescence'
      channels.push({
        id: CUMULATIVE_FLUORESCENCE_CHANNEL,
        type: OTHER_CHANNEL_TYPE,
        PnS: undefined,
        letter: OTHER_LETTER,
        label,
        defaultLabel: label,
        __computed__displayName: label,
      })
    }

    return channels
  },
)

export const selectDefaultSelectedChannels = createSelector(
  selectChannels,
  channels => {
    return channels
      .filter(
        channel =>
          !(
            [
              TIME_CHANNEL,
              CUMULATIVE_FLUORESCENCE_CHANNEL,
              UMAP_X_CHANNEL,
              UMAP_Y_CHANNEL,
            ] as string[]
          ).includes(channel.id),
      )
      .map(channel => channel.id)
  },
)
export const selectSelectedChannels = createSelector(
  selectAnalysisSortedSelectedChannels,
  selectDefaultSelectedChannels,
  (sortedSelectedChannels, defaultSelectedChannels) =>
    sortedSelectedChannels ?? defaultSelectedChannels,
)
