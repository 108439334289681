import { publicApi } from './public.api'

export const utilsApi = publicApi.injectEndpoints({
  endpoints: builder => ({
    getCountryCodeList: builder.query<CountryCode[], void>({
      query: () => ({
        url: 'countries/',
      }),
    }),
    getReCaptchaSettings: builder.query<ReCaptchaResult, void>({
      query: () => ({
        url: 'recaptcha/',
      }),
    }),
    getMetrics: builder.query<MetricsResult, void>({
      query: () => ({
        url: 'metrics/',
      }),
    }),
  }),
})

export const {
  useGetCountryCodeListQuery,
  useGetReCaptchaSettingsQuery,
  useGetMetricsQuery,
  useLazyGetMetricsQuery,
} = utilsApi

export interface CountryCode {
  code: string
  name: string
  calling_code: number | null
}

type ReCaptchaResult = {
  recaptcha_enabled: boolean
  recaptcha_login: boolean
  recaptcha_signup: boolean
  recaptcha_forgot_password: boolean
  recaptcha_public_key: string
}

type MetricsResult = {
  metaflow_version: string
}
