import Highcharts from 'highcharts'

import { createMetaAnalysisVolcanoPlotBaseOptions } from 'pages/meta-analysis/MetaAnalysisVolcanoPlotBaseOptions'
import { computeVolcanoPlotSeries } from 'pages/meta-analysis/series'

import {
  MetaAnalysisVolcanoPlotFile,
  MetaAnalysisVolcanoPlotType,
} from 'shared/api/meta-analysis.api'

import { AppTheme } from 'Theme'

type RenderVolcanoPlotProps = {
  chart: MetaAnalysisVolcanoPlotType
  volcanoPlotFile: MetaAnalysisVolcanoPlotFile
  theme: AppTheme
  width: number
  height: number
}

export const renderVolcanoPlot = async ({
  chart,
  volcanoPlotFile,
  theme,
  width,
  height,
}: RenderVolcanoPlotProps): Promise<string> => {
  const MetaAnalysisVolcanoPlotBaseOptions =
    createMetaAnalysisVolcanoPlotBaseOptions(
      theme,
      chart.xThreshold,
      chart.yThreshold,
    )

  return new Highcharts.Chart(document.createElement('div'), {
    ...MetaAnalysisVolcanoPlotBaseOptions,
    chart: {
      ...MetaAnalysisVolcanoPlotBaseOptions.chart,
      width,
      height,
    },
    series: computeVolcanoPlotSeries(chart, volcanoPlotFile, theme),
    title: {
      ...MetaAnalysisVolcanoPlotBaseOptions.title,
      text: chart.name,
    },
  }).getSVG()
}
