import { forwardRef, ReactNode } from 'react'
import styled from 'styled-components'

interface ButtonWrapperProps {
  children: ReactNode
  title?: string
  disabled?: boolean
  className?: string
  onClick: () => void
}

export const ButtonWrapper = forwardRef<HTMLButtonElement, ButtonWrapperProps>(
  ({ children, title, disabled, className, onClick }, ref) => {
    return (
      <Wrapper
        title={title}
        disabled={disabled}
        ref={ref}
        className={className}
        onClick={onClick}
      >
        {children}
      </Wrapper>
    )
  },
)

const Wrapper = styled.button`
  padding: 0;
  line-height: 0;
  vertical-align: middle;
  background: transparent;
  color: inherit;
  border: none;
  cursor: pointer;

  &:disabled {
    cursor: not-allowed;
    color: ${({ theme }) => theme.colors.greyscale[20]};
  }
`
