import { Action, createListenerMiddleware } from '@reduxjs/toolkit'
import { isEqual, pick, uniq } from 'lodash'

import { selectAnalysisLoadingStatus } from 'pages/analysis/store/selectors'

import {
  isSecondaryTabInitializedRef,
  sendPatchStateRequest,
} from 'shared/hooks/useMultiTab'
import { RootState } from 'shared/store'
import { getIsMultiTabMode, getIsSecondaryTab } from 'shared/utils/multi-tab'

export const multiTabMiddleware = createListenerMiddleware()

multiTabMiddleware.startListening({
  predicate: (action: Action) =>
    action.type.includes('modify') ||
    action.type.includes('selected-charts') ||
    action.type === 'analysis/updateLayout' ||
    action.type === 'analysis/updateSpecificLayout',
  effect: (_action, api) => {
    const state = api.getState() as RootState
    const isAnalysisLoaded = selectAnalysisLoadingStatus(state) === 'success'
    const isMultiTabMode = getIsMultiTabMode()
    const isSecondaryTab = getIsSecondaryTab()

    if (
      !isMultiTabMode ||
      (isSecondaryTab && !isSecondaryTabInitializedRef.current) ||
      !isAnalysisLoaded
    ) {
      return
    }

    const createPatch = (
      newState: Record<string, unknown>,
      oldState: Record<string, unknown>,
    ) => {
      const patch = {}
      for (const key of uniq([
        ...Object.keys(newState ?? {}),
        ...Object.keys(oldState ?? {}),
      ])) {
        if (
          typeof newState[key] === 'object' &&
          !Array.isArray(newState[key]) &&
          typeof oldState[key] === 'object' &&
          !Array.isArray(oldState[key])
        ) {
          const nestedPatch = createPatch(
            newState[key] as Record<string, unknown>,
            oldState[key] as Record<string, unknown>,
          )
          if (Object.keys(nestedPatch).length > 0) {
            patch[key] = nestedPatch
          }
        } else if (!isEqual(newState[key], oldState[key])) {
          patch[key] = newState[key]
        }
      }

      return patch
    }

    const patch = createPatch(
      pick(state, 'analysisPage'),
      pick(api.getOriginalState(), 'analysisPage'),
    )
    sendPatchStateRequest(patch)
  },
})
