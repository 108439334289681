import {
  MenuItem,
  Select as MUISelect,
  SelectProps as MUISelectProps,
} from '@material-ui/core'
import React, { ReactNode, useRef } from 'react'
import styled from 'styled-components'

import { ValidationLabel } from 'components/ validation-label/ValidationLabel'

interface SelectProps extends Omit<MUISelectProps, 'error'> {
  id?: string
  name?: string
  value: string | string[]
  options: {
    value: string
    label: React.ReactNode
    disabled?: boolean
    disabledText?: string
  }[]
  disabled?: boolean
  label?: ReactNode
  shouldReserveSpaceForError?: boolean
  error?: string
  className?: string
  onChange?: (e: React.ChangeEvent<{ value: unknown }>) => void
  onBlur?: React.FocusEventHandler<{ value: unknown }>
}

const Select = ({
  name,
  id,
  disabled,
  label,
  value,
  onChange,
  onBlur,
  className,
  shouldReserveSpaceForError = true,
  error,
  options,
  ...props
}: SelectProps): JSX.Element => {
  const ref = useRef<HTMLInputElement>(null)

  return (
    <div className={className}>
      {label && <label htmlFor={id}>{label}</label>}
      <SelectWrapper
        $error={!!error}
        $shouldReserveSpaceForError={shouldReserveSpaceForError}
      >
        <StyledSelect
          variant="outlined"
          id={id}
          ref={ref}
          name={name}
          value={value}
          onChange={onChange}
          onBlur={onBlur}
          disabled={disabled}
          {...props}
        >
          {options.map(
            ({
              value: optionValue,
              label: optionLabel,
              disabled,
              disabledText,
            }) => (
              <StyledMenuItem
                key={JSON.stringify(optionValue)}
                value={optionValue}
                disabled={disabled ?? false}
              >
                <span>
                  {optionLabel}{' '}
                  {disabled && disabledText ? `(${disabledText})` : ''}
                </span>
                <span>
                  {Array.isArray(value) && value.includes(optionValue)
                    ? '✓'
                    : ''}
                </span>
              </StyledMenuItem>
            ),
          )}
        </StyledSelect>
        {error && <StyledValidationLabel>{error}</StyledValidationLabel>}
      </SelectWrapper>
    </div>
  )
}

const StyledSelect = styled(MUISelect)`
  width: 100%;
  padding: 10px 10px;
  min-height: 37px;
  border-radius: ${props => props.theme.radius[1]}px;
  color: ${props => props.theme.colors.primaryDark[100]};
  font-size: ${props => props.theme.typography.body2.fontSize}px;
  resize: none;
  font-family: ${props => props.theme.font.style.regular};
  transition:
    border 0.2s ease,
    outline 0.2s ease;

  & div {
    padding: 0;
  }

  &:focus {
    outline: none;
    border: 1px solid ${props => props.theme.colors.primaryDark[50]};
  }
  &::placeholder {
    color: ${props => props.theme.colors.primaryDark[50]};
  }
`

const SelectWrapper = styled.div<{
  $error: boolean
  $shouldReserveSpaceForError: boolean
}>`
  --line-height: ${props => props.theme.typography.body2.lineHeight};

  line-height: var(--line-height);
  padding-bottom: calc(
    ${props =>
        !props.$error && props.$shouldReserveSpaceForError
          ? props.theme.font.size.smallest
          : 0}px * var(--line-height) + 8px
  );
`

const StyledValidationLabel = styled(ValidationLabel)`
  font-size: ${props => props.theme.font.size.smallest}px;
`

const StyledMenuItem = styled(MenuItem)`
  display: flex;
  justify-content: space-between;
  font-size: ${props => props.theme.typography.body2.fontSize}px;
  color: ${props => props.theme.colors.primaryDark[100]};
`

export default Select
