import { MenuItem, Select } from '@material-ui/core'
import { FC, useCallback, useState } from 'react'
import styled from 'styled-components'
import { v4 as uuidv4 } from 'uuid'

import { Button } from 'components/Button'
import { Checkbox } from 'components/Checkbox'

import {
  CUMULATIVE_FLUORESCENCE_CHANNEL,
  EVENT_PERCENTAGE_LIMIT,
  TIME_CHANNEL,
} from 'shared/constants'
import { useEventCallback } from 'shared/hooks/useEventCallback'
import { Graph, GraphType } from 'shared/models/Graphs'
import { useAppSelector } from 'shared/store'

import {
  selectActiveLeafIds,
  selectChannels,
  selectChannelById,
  selectCurrentDepth,
  selectGloballyHiddenClusters,
} from './store/selectors'

type CreateGraphFormProps = Readonly<{
  onAdd: (graph: Graph) => void
  onCancel: () => void
}>

export const AddGraphForm: FC<CreateGraphFormProps> = ({ onAdd, onCancel }) => {
  const channels = useAppSelector(selectChannels)
  const globalDepth = useAppSelector(selectCurrentDepth)
  const activeLeafIds = useAppSelector(selectActiveLeafIds)
  const hiddenClusterIds = useAppSelector(selectGloballyHiddenClusters)
  const channelById = useAppSelector(selectChannelById)

  const [chart_type, setGraphType] = useState<GraphType>('Dot plot')
  const [x_axis, setXAxis] = useState('default')
  const [y_axis, setYAxis] = useState('default')
  const [xAxisScaleType, setXAxisScaleType] =
    useState<Highcharts.AxisTypeValue>('linear')
  const [yAxisScaleType, setYAxisScaleType] =
    useState<Highcharts.AxisTypeValue>('linear')

  const resetForm = useCallback(() => {
    setGraphType('Dot plot')
    setXAxis('default')
    setYAxis('default')
  }, [])

  const handleAdd = useEventCallback(() => {
    const id = uuidv4()
    const newGraph: Graph = {
      id,
      isUnsaved: true,
      chart_type,
      x_axis:
        y_axis === CUMULATIVE_FLUORESCENCE_CHANNEL ? TIME_CHANNEL : x_axis,
      x_axis_scale_type:
        chart_type === 'Dot plot' && channelById[x_axis].type === 'morpho'
          ? xAxisScaleType
          : 'linear',
      y_axis_scale_type:
        channelById[y_axis].type === 'morpho' ? yAxisScaleType : 'linear',
      depth: globalDepth,
      active_leaf_ids: activeLeafIds,
      hidden_cluster_ids: Array.from(hiddenClusterIds),
      gates: [],
      name: '',
      default_name: '',
      zoom: null,
      event_limit: EVENT_PERCENTAGE_LIMIT,
      lasso_ids: {},
      lassos_hierarchy: [],
      parent_lasso_id: undefined,
      created_at: new Date().toISOString(),
    }

    if (chart_type === 'Dot plot') {
      // y_axis data only present for dot plot, not for histogram
      onAdd({
        ...newGraph,
        y_axis,
      })
    } else {
      onAdd(newGraph)
    }
    resetForm()
  })

  const handleCancel = useEventCallback(() => {
    resetForm()
    onCancel()
  })

  const disableSubmit =
    chart_type === 'Dot plot'
      ? (y_axis !== CUMULATIVE_FLUORESCENCE_CHANNEL && x_axis === 'default') ||
        y_axis === 'default'
      : x_axis === 'default'

  return (
    <FormContainer>
      {channels.length === 0 ? (
        <ErrorMessage>No channels available</ErrorMessage>
      ) : (
        <>
          <SelectContainer>
            <StyledSelect
              value={chart_type}
              placeholder="Graph type"
              variant="outlined"
              onChange={e => setGraphType(e.target.value as GraphType)}
            >
              <StyledMenuItem value="Dot plot">Dot plot</StyledMenuItem>
              <StyledMenuItem value="Histogram">Histogram</StyledMenuItem>
            </StyledSelect>
            <StyledSelect
              value={
                y_axis === CUMULATIVE_FLUORESCENCE_CHANNEL
                  ? TIME_CHANNEL
                  : x_axis
              }
              variant="outlined"
              onChange={e => setXAxis(e.target.value as string)}
              disabled={
                y_axis === CUMULATIVE_FLUORESCENCE_CHANNEL &&
                chart_type !== 'Histogram'
              }
            >
              <PlaceholderMenuItem value="default">X axis</PlaceholderMenuItem>
              {channels
                .filter(
                  channel => channel.id !== CUMULATIVE_FLUORESCENCE_CHANNEL,
                )
                .map(channel => (
                  <StyledMenuItem value={channel.id} key={channel.id}>
                    {channel.__computed__displayName}
                  </StyledMenuItem>
                ))}
            </StyledSelect>
            {chart_type !== 'Histogram' && (
              <StyledSelect
                value={y_axis}
                variant="outlined"
                onChange={e => setYAxis(e.target.value as string)}
              >
                <PlaceholderMenuItem value="default">
                  Y axis
                </PlaceholderMenuItem>
                {channels.map(channel => (
                  <StyledMenuItem value={channel.id} key={channel.id}>
                    {channel.__computed__displayName}
                  </StyledMenuItem>
                ))}
              </StyledSelect>
            )}
          </SelectContainer>
          {chart_type === 'Dot plot' && (
            <AxisSelector>
              {'X axis : Linear'}
              <Checkbox
                checked={
                  xAxisScaleType === 'linear' &&
                  channelById[x_axis]?.type === 'morpho'
                }
                onClick={() => setXAxisScaleType('linear')}
                disabled={channelById[x_axis]?.type !== 'morpho'}
              />
              {' Logarithmic'}
              <Checkbox
                checked={
                  xAxisScaleType === 'logarithmic' ||
                  channelById[x_axis]?.type !== 'morpho'
                }
                onClick={() => setXAxisScaleType('logarithmic')}
                disabled={channelById[x_axis]?.type !== 'morpho'}
              />
            </AxisSelector>
          )}
          <AxisSelector>
            {'Y axis : Linear'}
            <Checkbox
              checked={
                yAxisScaleType === 'linear' &&
                channelById[y_axis]?.type === 'morpho'
              }
              onClick={() => setYAxisScaleType('linear')}
              disabled={channelById[y_axis]?.type !== 'morpho'}
            />
            {' Logarithmic'}
            <Checkbox
              checked={
                yAxisScaleType === 'logarithmic' ||
                channelById[y_axis]?.type !== 'morpho'
              }
              onClick={() => setYAxisScaleType('logarithmic')}
              disabled={channelById[y_axis]?.type !== 'morpho'}
            />
          </AxisSelector>
          <Actions>
            <Button grey onClick={handleCancel}>
              Cancel
            </Button>
            <CreateButton onClick={handleAdd} disabled={disableSubmit}>
              Create
            </CreateButton>
          </Actions>
        </>
      )}
    </FormContainer>
  )
}

const FormContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 30px 60px 30px;
  flex: 1;
  justify-content: space-between;
`
const ErrorMessage = styled.div`
  margin: auto;
`

const SelectContainer = styled.div`
  display: flex;
  flex-direction: column;
`
const StyledSelect = styled(Select)`
  margin: 8px 0;
  height: 45px;
  color: ${props => props.theme.colors.primaryDark[100]};
  font-size: ${props => props.theme.font.size.small}px;
  &:first-child {
    margin-top: 0;
  }
  &:last-child {
    margin-bottom: 16px;
  }
`
const StyledMenuItem = styled(MenuItem)`
  color: ${props => props.theme.colors.primaryDark[100]};
  font-size: ${props => props.theme.font.size.small}px;
`
const PlaceholderMenuItem = styled(MenuItem)`
  opacity: 0;
  visibility: hidden;
  display: none;
`
const Actions = styled.div`
  display: flex;
  justify-content: flex-end;
  & > * {
    height: 45px;
  }
`
const CreateButton = styled(Button)`
  margin-left: 20px;
`
const AxisSelector = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`
