import { useMemo } from 'react'
import styled from 'styled-components'

import { DotLabel } from 'components/DotLabel'

import { LassoTag } from 'pages/analysis/LassoTag'
import { Cluster } from 'pages/analysis/store/selectors'

type LassoTooltipProps = {
  className?: string
  name: string
  clusters: Cluster[]
}

export const LassoTooltip: React.FC<LassoTooltipProps> = ({
  className,
  name,
  clusters,
}) => {
  const numberOfEvents = useMemo(() => {
    if (!clusters) {
      return undefined
    }
    return clusters.reduce((acc, cluster) => acc + cluster.stats.count, 0)
  }, [clusters])

  if (!clusters) {
    return null
  }

  return (
    <LassoTooltipRoot className={className}>
      <LassoTooltipHeader>
        <LassoTooltipLabel>Lasso</LassoTooltipLabel>
        <StyledLassoTag name={name} />
      </LassoTooltipHeader>
      <LassoTooltipNumberOfEvents>
        {numberOfEvents} events
      </LassoTooltipNumberOfEvents>
      <LassoTooltipSelectedClustersLabel>
        Selected clusters ({clusters.length}):
      </LassoTooltipSelectedClustersLabel>
      <LassoTooltipClusters>
        {clusters.map(cluster => (
          <LassoTooltipCluster key={cluster.id}>
            <StyledDotLabel $color={cluster.color} />
            {cluster.label}
          </LassoTooltipCluster>
        ))}
      </LassoTooltipClusters>
    </LassoTooltipRoot>
  )
}

const LassoTooltipRoot = styled.div`
  background: inherit;
  max-height: 80vh;
  overflow: hidden;
  padding: 12px;
`

const LassoTooltipHeader = styled.div`
  display: flex;
  align-items: baseline;
`

const LassoTooltipLabel = styled.div`
  font-family: ${props => props.theme.font.style.bold};
  margin-right: 0.5em;
  font-size: 14px;
`

const LassoTooltipSelectedClustersLabel = styled.div`
  margin-top: 12px;
  margin-bottom: 8px;
`

const LassoTooltipNumberOfEvents = styled.div``

const LassoTooltipClusters = styled.div`
  display: grid;
  grid-template-columns: repeat(3, auto);
  column-gap: 8px;
  row-gap: 4px;
`

const LassoTooltipCluster = styled.div`
  display: flex;
`

const StyledDotLabel = styled(DotLabel)`
  margin-left: 0;
  margin-right: 4px;
`

const StyledLassoTag = styled(LassoTag)`
  font-size: 12px;
`
