import { uniq } from 'lodash'

import { PaginatedResults } from 'shared/models/Result'
import { toQueryString } from 'shared/utils/utils'

import { privateApi } from './private.api'
import { encodeTagParameters } from './utils'

export const filesApi = privateApi.injectEndpoints({
  endpoints: builder => ({
    createFcsFiles: builder.mutation<CreateFilesResult, CreateFilesPayload>({
      query: payload => ({
        url: '/fcs-files/',
        method: 'POST',
        body: payload,
      }),
    }),
    getFcsFiles: builder.query<GetFcsFilesResult, GetFilesPayload>({
      query: payload => ({
        url: `/fcs-files/?${toQueryString({
          project: payload.projectId,
          page: payload.page,
          ordering: payload.ordering,
          ...payload.search,
        })}`,
      }),
      providesTags: [{ type: 'File', id: encodeTagParameters({ id: 'list' }) }],
    }),
    getFcsFileIds: builder.query<GetFcsFileIdsResult, GetFcsFileIdsPayload>({
      query: payload => ({
        url: `/fcs-files/`,
        params: {
          return_all_pk: true,
          ...payload.search,
        },
      }),
      transformResponse: (response: { pk: string }[]) =>
        response.map(item => item.pk),
      providesTags: [{ type: 'File', id: encodeTagParameters({ id: 'list' }) }],
    }),
    getFcsFile: builder.query<FcsFile, string>({
      query: fileId => `/fcs-files/${fileId}/`,
    }),
    getFcsFileById: builder.query<Record<string, FcsFile>, string[]>({
      queryFn: async (fcsFileIds, _queryApi, _extraOptions, baseQuery) => {
        const fcsFileById = {} as Record<string, FcsFile>
        for (const fileId of uniq(fcsFileIds)) {
          const result = await baseQuery(`/fcs-files/${fileId}/`)
          if (result.error) {
            return result
          }
          fcsFileById[fileId] = result.data as FcsFile
        }
        return { data: fcsFileById }
      },
    }),
    getFcsFileAuthors: builder.query<
      GetFileAuthorsResult,
      GetFileAuthorsPayload
    >({
      query: payload =>
        `/fcs-files/authors/?${toQueryString({
          project: payload.projectId,
          page: payload.page,
          ...payload.search,
        })}`,
      providesTags: [{ type: 'File', id: encodeTagParameters({ id: 'list' }) }],
    }),
    getFcsFileBatchNames: builder.query<
      GetFileBatchNamesResult,
      GetFileBatchNamesPayload
    >({
      query: payload =>
        `/fcs-files/batch_names/?${toQueryString({
          project: payload.projectId,
          page: payload.page,
          ...payload.search,
        })}`,
      providesTags: [{ type: 'File', id: encodeTagParameters({ id: 'list' }) }],
    }),
    downloadFcsFile: builder.query<DownloadFileResult, string>({
      query: fileId => `/fcs-files/${fileId}/download/`,
    }),
    deleteFcsFile: builder.mutation<void, string>({
      query: fileId => ({
        url: `/fcs-files/${fileId}/`,
        method: 'DELETE',
      }),
    }),
    updateFcsFile: builder.mutation<unknown, UpdateFcsFilePayload>({
      query: file => ({
        url: `/fcs-files/${file.id}/`,
        method: 'PATCH',
        body: file,
      }),
    }),
    reportFcsFileUploadError: builder.mutation<unknown, string>({
      query: fileId => ({
        url: `/fcs-files/${fileId}/uploading_error/`,
        method: 'POST',
      }),
    }),
    createSpilloverFiles: builder.mutation<
      CreateFilesResult,
      CreateFilesPayload
    >({
      query: payload => ({
        url: '/spillover-files/',
        method: 'POST',
        body: payload,
      }),
    }),
    getSpilloverFiles: builder.query<GetSpilloverFilesResult, GetFilesPayload>({
      query: payload =>
        `/spillover-files/?${toQueryString({
          project: payload.projectId,
          page: payload.page,
          ordering: payload.ordering,
          ...payload.search,
        })}`,
      providesTags: [
        { type: 'Spillover', id: encodeTagParameters({ id: 'list' }) },
      ],
    }),
    getSpilloverFileAuthors: builder.query<
      GetFileAuthorsResult,
      GetFileAuthorsPayload
    >({
      query: payload =>
        `/spillover-files/authors/?${toQueryString({
          project: payload.projectId,
          page: payload.page,
          ...payload.search,
        })}`,
      providesTags: [
        { type: 'Spillover', id: encodeTagParameters({ id: 'list' }) },
      ],
    }),
    getSpilloverFileBatchNames: builder.query<
      GetFileBatchNamesResult,
      GetFileBatchNamesPayload
    >({
      query: payload =>
        `/spillover-files/batch_names/?${toQueryString({
          project: payload.projectId,
          page: payload.page,
          ...payload.search,
        })}`,
      providesTags: [
        { type: 'Spillover', id: encodeTagParameters({ id: 'list' }) },
      ],
    }),
    downloadSpilloverFile: builder.query<DownloadFileResult, string>({
      query: fileId => `/spillover-files/${fileId}/download/`,
    }),
    deleteSpilloverFile: builder.mutation<unknown, string>({
      query: fileId => ({
        url: `/spillover-files/${fileId}/`,
        method: 'DELETE',
      }),
    }),
    updateSpilloverFile: builder.mutation<unknown, UpdateSpilloverFilePayload>({
      query: file => ({
        url: `/spillover-files/${file.id}/`,
        method: 'PATCH',
        body: file,
      }),
    }),
    reportSpilloverFileUploadError: builder.mutation<unknown, string>({
      query: fileId => ({
        url: `/spillover-files/${fileId}/uploading_error/`,
        method: 'POST',
      }),
    }),
    createCompensatedFiles: builder.mutation<
      unknown,
      CreateCompensatedFilesPayload
    >({
      query: payload => ({
        url: `/compensated-files/`,
        method: 'POST',
        body: payload,
      }),
    }),
    getCompensatedFiles: builder.query<
      GetCompensatedFilesResult,
      GetFilesPayload
    >({
      query: payload =>
        `/compensated-files/?${toQueryString({
          project: payload.projectId,
          page: payload.page,
          ordering: payload.ordering,
          ...payload.search,
        })}`,
      providesTags: [
        { type: 'CompensatedFile', id: encodeTagParameters({ id: 'list' }) },
      ],
    }),
    getCompensatedFileIds: builder.query<
      GetCompensatedFilesIdsResult,
      GetCompensatedFilesIdsPayload
    >({
      query: payload => ({
        url: `/compensated-files/`,
        params: {
          return_all_pk: true,
          ...payload.search,
        },
      }),
      transformResponse: (response: { pk: string }[]) =>
        response.map(item => item.pk),
      providesTags: [
        { type: 'CompensatedFile', id: encodeTagParameters({ id: 'list' }) },
      ],
    }),
    getCompensatedFile: builder.query<CompensatedFile, string>({
      query: fileId => `/compensated-files/${fileId}/`,
    }),
    getCompensatedFileById: builder.query<
      Record<string, CompensatedFile>,
      string[]
    >({
      queryFn: async (
        compensatedFileIds,
        _queryApi,
        _extraOptions,
        baseQuery,
      ) => {
        const compensatedFileById = {} as Record<string, CompensatedFile>
        for (const fileId of uniq(compensatedFileIds)) {
          const result = await baseQuery(`/compensated-files/${fileId}/`)
          if (result.error) {
            return result
          }
          compensatedFileById[fileId] = result.data as CompensatedFile
        }
        return { data: compensatedFileById }
      },
    }),
    getCompensatedFileAuthors: builder.query<
      GetFileAuthorsResult,
      GetFileAuthorsPayload
    >({
      query: payload =>
        `/compensated-files/authors/?${toQueryString({
          project: payload.projectId,
          page: payload.page,
          ...payload.search,
        })}`,
      providesTags: [
        { type: 'CompensatedFile', id: encodeTagParameters({ id: 'list' }) },
      ],
    }),
    getCompensatedFileBatchNames: builder.query<
      GetFileBatchNamesResult,
      GetFileBatchNamesPayload
    >({
      query: payload =>
        `/compensated-files/batch_names/?${toQueryString({
          project: payload.projectId,
          page: payload.page,
          ...payload.search,
        })}`,
      providesTags: [
        { type: 'CompensatedFile', id: encodeTagParameters({ id: 'list' }) },
      ],
    }),
    deleteCompensatedFile: builder.mutation<unknown, string>({
      query: fileId => ({
        url: `/compensated-files/${fileId}/`,
        method: 'DELETE',
      }),
    }),
    updateCompensatedFile: builder.mutation<
      unknown,
      UpdateCompensatedFilePayload
    >({
      query: file => ({
        url: `/compensated-files/${file.id}/`,
        method: 'PATCH',
        body: file,
      }),
    }),
  }),
})

export const {
  useGetFcsFileQuery,
  useCreateFcsFilesMutation,
  useGetFcsFilesQuery,
  useGetFcsFileBatchNamesQuery,
  useGetFcsFileAuthorsQuery,
  useGetFcsFileByIdQuery,
  useGetFcsFileIdsQuery,
  useLazyDownloadFcsFileQuery,
  useDeleteFcsFileMutation,
  useUpdateFcsFileMutation,
  useReportFcsFileUploadErrorMutation,
  useCreateSpilloverFilesMutation,
  useGetSpilloverFilesQuery,
  useGetSpilloverFileBatchNamesQuery,
  useGetSpilloverFileAuthorsQuery,
  useLazyDownloadSpilloverFileQuery,
  useDeleteSpilloverFileMutation,
  useUpdateSpilloverFileMutation,
  useReportSpilloverFileUploadErrorMutation,
  useCreateCompensatedFilesMutation,
  useGetCompensatedFileQuery,
  useGetCompensatedFileIdsQuery,
  useGetCompensatedFileByIdQuery,
  useGetCompensatedFilesQuery,
  useGetCompensatedFileBatchNamesQuery,
  useGetCompensatedFileAuthorsQuery,
  useDeleteCompensatedFileMutation,
  useUpdateCompensatedFileMutation,
} = filesApi

type File = {
  author_name: string
  batch_name: string
  error: string
  file_name: string
  id: string
  project: string
  size: number
  status: `${FileStatus}`
  created_at: string
  updated_at: string
}

export type FcsFile = {
  acquisition_date: string
  analyses_count: number
  author_name: string
  batch_name: string
  channels: Record<string, FcsFileChannel>
  created_at: string
  error: null
  file_name: string
  headers: Record<string, string>
  id: string
  project: string
  size: number
  status: `${FileStatus}`
  total_cell_count: number
  updated_at: string
}

export type FcsFileChannel = {
  id: string
  type: string
  letter: string
  PnS?: string
  is_selected: boolean
}

export type SpilloverFile = File

export type CompensatedFile = Omit<
  File,
  'size' | 'project' | 'file_name' | 'status'
> & {
  analyses_count: number
  name: string
  fcs_file: string
  fcs_file_name: string
  fcs_file_size?: number
  fcs_file_total_cell_count: number
  fcs_file_acquisition_date?: string
  spillover?: string
  spillover_origin?: `${SpilloverOrigin}`
  spillover_file_name?: string
  spillover_file_size?: number
  status: `${CompensatedFileStatus}`
}

type CreateFilesPayload = {
  file_name: string
  project: string
  batch_name?: string
}[]

type CreateFilesResult = Record<
  string,
  {
    id: string
    index: number
    path: string
    url: string
  }
>

type GetFilesPayload = {
  projectId: string
  page: number
  ordering?: string
  search: Record<string, string | number | string[] | undefined>
}

type DownloadFileResult = {
  id: string
  path: string
  url: string
}

type GetFileAuthorsPayload = {
  projectId: string
  page: number
  search?: Record<string, string | undefined>
}

type GetFileAuthorsResult = PaginatedResults<
  {
    id: string
    username: string
    first_name?: string
    last_name?: string
    email: string
    job?: string
    is_expert: boolean
  }[]
>

type GetFileBatchNamesPayload = {
  projectId: string
  page: number
  search?: Record<string, string | undefined>
}

type GetFileBatchNamesResult = PaginatedResults<string[]>

type GetFcsFilesResult = PaginatedResults<FcsFile[]>

type UpdateFcsFilePayload = {
  id: string
} & Partial<FcsFile>

type GetSpilloverFilesResult = PaginatedResults<SpilloverFile[]>

type UpdateSpilloverFilePayload = {
  id: string
} & Partial<SpilloverFile>

type CreateCompensatedFilesPayload = {
  fcs_file: string[]
  spillover_origin: `${SpilloverOrigin}` | `${UnmixingMethod}`
  spillover?: string
}

type GetCompensatedFilesResult = PaginatedResults<CompensatedFile[]>

type UpdateCompensatedFilePayload = {
  id: string
} & Partial<CompensatedFile>

export enum FileStatus {
  Uploading = 'Uploading',
  Uploaded = 'Uploaded',
  Checking = 'Checking',
  Ready = 'Ready',
  Error = 'Error',
}

export enum CompensatedFileStatus {
  New = 'New',
  Checking = 'Checking',
  Ready = 'Ready',
  Error = 'Error',
}

export enum SpilloverOrigin {
  None = 'NC',
  Embedded = 'FCS',
  File = 'SPILLOVER',
}

export enum UnmixingMethod {
  Embedded = 'EMBEDDED',
  Unmix = 'UNMIX',
}

type GetFcsFileIdsPayload = {
  search: Record<string, unknown>
}

type GetFcsFileIdsResult = string[]

type GetCompensatedFilesIdsPayload = {
  search: Record<string, unknown>
}

type GetCompensatedFilesIdsResult = string[]
