import { Fade } from '@material-ui/core'
import React, { FC } from 'react'
import styled from 'styled-components'

import { Modal } from 'components/Modal'

import { Button } from './Button'

type ConfirmModalProps = Readonly<{
  open: boolean
  message: string
  KOLabel: string
  OKLabel: string
  onKO: () => void
  onOK: () => void
}>

export const ConfirmModal: FC<ConfirmModalProps> = ({
  open,
  message,
  KOLabel,
  OKLabel,
  onKO,
  onOK,
}) => {
  return (
    <Modal open={open} onClose={onKO}>
      <Fade in={open}>
        <Container>
          <Text>{message}</Text>
          <Actions>
            <Button onClick={onKO} grey>
              {KOLabel}
            </Button>
            <Button onClick={onOK}>{OKLabel}</Button>
          </Actions>
        </Container>
      </Fade>
    </Modal>
  )
}

const Container = styled.div`
  background-color: ${props => props.theme.colors.background};
  border-radius: ${props => props.theme.radius[2]}px;
  width: 380px;
  padding: 30px 40px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`

const Text = styled.div`
  margin: 15px 0 55px;
  font-size: ${props => props.theme.font.size.h3}px;
  color: ${props => props.theme.colors.warning};
  text-align: center;
`
const Actions = styled.div`
  display: flex;
  justify-content: space-around;
`
