import { Typography } from '@material-ui/core'
import { Formik, FormikConfig } from 'formik'
import { useCallback, useRef, useState } from 'react'
import ReCAPTCHA from 'react-google-recaptcha'
import { useHistory } from 'react-router'
import styled from 'styled-components'
import { boolean, object, string } from 'yup'

import { LicenseAgreementConfirmation } from 'components/LicenseAgreementConfirmation'
import { LoggedOutLayout } from 'components/LoggedOutLayout'
import { Section } from 'components/Section'
import { Button } from 'components/button/Button'
import Input from 'components/forms/Input'
import { ReCaptcha } from 'components/forms/ReCaptcha'
import Select from 'components/forms/Select'

import { useSignUpMutation } from 'shared/api/auth.api'
import { CountryCode, useGetCountryCodeListQuery } from 'shared/api/utils.api'
import {
  ReCaptchaFlags,
  useReCaptchaRequired,
} from 'shared/hooks/useReCaptchaRequired'
import { useServerFormValidation } from 'shared/hooks/useServerFormValidation'
import {
  Organization,
  OrganizationTypes,
} from 'shared/models/OrganizationModels'
import { handleError } from 'shared/utils/errorHandler'

import { FreeTrialModal } from './FreeTrialModal'
import { PhoneNumberInput } from './PhoneNumberInput'

type SignUpFormValues = {
  email: string
  password: string
  passwordConfirmation: string
  firstName: string
  lastName: string
  job?: string
  organizationType: Organization['type']
  organizationName: string
  organizationContactFirstName: string
  organizationContactLastName: string
  organizationAddress1: string
  organizationAddress2: string
  organizationCity: string
  organizationState: string
  organizationPostalCode: string
  organizationCountry: string
  organizationEmail: string
  organizationPhoneNumber: string
  isTrialOrganization: boolean
}

const initialValues: SignUpFormValues = {
  email: '',
  password: '',
  passwordConfirmation: '',
  firstName: '',
  lastName: '',
  job: '',
  organizationType: OrganizationTypes.Academic,
  organizationName: '',
  organizationContactFirstName: '',
  organizationContactLastName: '',
  organizationAddress1: '',
  organizationAddress2: '',
  organizationCity: '',
  organizationState: '',
  organizationPostalCode: '',
  organizationCountry: 'FR',
  organizationEmail: '',
  organizationPhoneNumber: '',
  isTrialOrganization: false,
}

const textWithNoSpecialCharacters = /^[\p{L}\p{N} \-/]*$/u

const SignUpFormSchema = object({
  email: string().email().required(),
  password: string()
    .required()
    .min(8, 'Password must be at least 8 characters')
    .matches(/[a-z]/, 'Password must contain at least one lowercase letter')
    .matches(/[A-Z]/, 'Password must contain at least one uppercase letter')
    .matches(/[0-9]/, 'Password must contain at least one number')
    .matches(
      /[^a-zA-Z0-9]/,
      'Password must contain at least one special character',
    ),
  passwordConfirmation: string().test(
    'passwords-match',
    'Passwords must match',
    function (value, context) {
      return value === context.parent.password
    },
  ),
  firstName: string().required(),
  lastName: string().required(),
  job: string(),
  organizationType: string(),
  organizationName: string()
    .matches(
      textWithNoSpecialCharacters,
      'Organization name cannot contain special characters',
    )
    .required(),
  organizationContactFirstName: string()
    .matches(
      textWithNoSpecialCharacters,
      'Contact name cannot contain special characters',
    )
    .required(),
  organizationContactLastName: string()
    .matches(
      textWithNoSpecialCharacters,
      'Contact name cannot contain special characters',
    )
    .required(),
  organizationAddress1: string()
    .matches(
      /^[\p{L}\p{N} \-/,.]*$/u,
      'Address cannot contain special characters',
    )
    .required(),
  organizationAddress2: string().matches(
    /^[\p{L}0-9 \-/,.]*$/u,
    'Address name cannot contain special characters',
  ),
  organizationCity: string()
    .matches(
      textWithNoSpecialCharacters,
      'City cannot contain special characters',
    )
    .required(),
  organizationState: string()
    .matches(
      textWithNoSpecialCharacters,
      'State cannot contain special characters',
    )
    .required(),
  organizationPostalCode: string()
    .matches(
      /^[A-z0-9]*((-|\s)*[A-z0-9])*$/,
      'Postal code cannot contain special characters',
    )
    .required(),
  organizationCountry: string()
    .matches(
      textWithNoSpecialCharacters,
      'Country cannot contain special characters',
    )
    .required(),
  organizationEmail: string().email().required(),
  organizationPhoneNumber: string().required(),
  isTrialOrganization: boolean(),
})

export const SignUpPage = (): JSX.Element => {
  const history = useHistory()

  const [triggerSignUpRequest, signUpRequestResult] = useSignUpMutation()
  const countryCodeList = useGetCountryCodeListQuery()
  const isReCaptchaRequired = useReCaptchaRequired(ReCaptchaFlags.SignUp)
  const [isReCaptchaCompleted, setReCaptchaCompleted] = useState(false)
  const [isLicenseChecked, setLicenseChecked] = useState(false)
  const [shouldShowFreeTrialModal, setShowFreeTrialModal] = useState(false)
  const reCaptchaRef = useRef<ReCAPTCHA>(null)

  const { globalError, getFieldRequestError, setSubmittedValues } =
    useServerFormValidation({
      error: signUpRequestResult.error,
      fields: Object.keys(initialValues) as (keyof SignUpFormValues)[],
    })

  const [selectedAreaCode, setSelectedAreaCode] =
    useState<CountryCode['code']>('FR')
  const [areaCodeValue, setAreaCodeValue] = useState('+33')

  const handleSignUp: FormikConfig<SignUpFormValues>['onSubmit'] = useCallback(
    async (values, { setSubmitting }) => {
      const combinedValues: SignUpFormValues = {
        ...values,
        organizationPhoneNumber: areaCodeValue + values.organizationPhoneNumber,
      }

      try {
        const reCaptchaToken =
          (reCaptchaRef.current && reCaptchaRef.current.getValue()) || ''
        await triggerSignUpRequest({
          reCaptchaToken,
          ...combinedValues,
        }).unwrap()
        history.push('/log-in', { isSignUpSuccess: true })
      } catch (error) {
        handleError(error)
      } finally {
        setSubmittedValues(combinedValues)
        setSubmitting(false)
        reCaptchaRef.current && reCaptchaRef.current.reset()
      }
    },
    [history, areaCodeValue, setSubmittedValues, triggerSignUpRequest],
  )

  const copyContactDetails = ({
    values,
    setFieldValue,
  }: {
    values: SignUpFormValues
    setFieldValue: (field: keyof SignUpFormValues, value: string) => void
  }) => {
    setFieldValue('organizationContactFirstName', values.firstName)
    setFieldValue('organizationContactLastName', values.lastName)
    setFieldValue('organizationEmail', values.email)
  }

  const setAreaCodeToSelectedCountry = useCallback(
    (key: string) => {
      const selectedCountry = countryCodeList.data?.find(
        country => country.code == key,
      )
      if (!selectedCountry) {
        setAreaCodeValue('')
        return
      }

      setSelectedAreaCode(selectedCountry.code)

      setAreaCodeValue(
        selectedCountry.calling_code ? '+' + selectedCountry.calling_code : '',
      )
    },
    [countryCodeList],
  )

  if (!countryCodeList.data) {
    return <div />
  }

  const countryOptions = countryCodeList.data.map(({ code, name }) => ({
    value: code,
    label: name,
  }))

  return (
    <LoggedOutLayout>
      <SignUpPageRoot>
        <Formik<SignUpFormValues>
          initialValues={initialValues}
          validationSchema={SignUpFormSchema}
          onSubmit={handleSignUp}
        >
          {({
            values,
            touched,
            errors,
            isSubmitting,
            isValid,
            handleChange,
            handleBlur,
            handleSubmit,
            setFieldValue,
          }) => (
            <form onSubmit={handleSubmit}>
              <Section title="Personal details">
                <Input
                  id="email"
                  name="email"
                  label="Email*"
                  value={values.email}
                  error={
                    touched.email
                      ? getFieldRequestError('email', values.email) ||
                        errors.email
                      : undefined
                  }
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
                <Input
                  id="password"
                  name="password"
                  type="password"
                  label="Password*"
                  value={values.password}
                  error={
                    touched.password
                      ? getFieldRequestError('password', values.password) ||
                        errors.password
                      : undefined
                  }
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
                <Input
                  id="passwordConfirmation"
                  name="passwordConfirmation"
                  type="password"
                  label="Retype password*"
                  value={values.passwordConfirmation}
                  error={
                    touched.passwordConfirmation
                      ? errors.passwordConfirmation
                      : undefined
                  }
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
                <Input
                  id="firstName"
                  name="firstName"
                  label="First name*"
                  value={values.firstName}
                  error={
                    touched.firstName
                      ? getFieldRequestError('firstName', values.firstName) ||
                        errors.firstName
                      : undefined
                  }
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
                <Input
                  name="lastName"
                  label="Last name*"
                  value={values.lastName}
                  error={
                    touched.lastName
                      ? getFieldRequestError('lastName', values.lastName) ||
                        errors.lastName
                      : undefined
                  }
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
                <Input
                  name="job"
                  label="Job"
                  value={values.job ?? ''}
                  error={
                    touched.job
                      ? getFieldRequestError('job', values.job) || errors.job
                      : undefined
                  }
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
              </Section>
              <ReCaptcha
                onCompleted={() => setReCaptchaCompleted(true)}
                ref={reCaptchaRef}
              >
                <Section
                  title={
                    <OrganizationDetailsSectionTitle>
                      <span>Organization details</span>
                      <Button
                        onClick={() => {
                          copyContactDetails({ values, setFieldValue })
                        }}
                        disabled={
                          !!(
                            errors.firstName ||
                            errors.lastName ||
                            errors.email ||
                            !touched.firstName ||
                            !touched.lastName ||
                            !touched.email
                          )
                        }
                      >
                        Same contact details as above
                      </Button>
                    </OrganizationDetailsSectionTitle>
                  }
                >
                  <Typography variant="caption" align="left">
                    Organization type*
                  </Typography>
                  <OrganizationTypeSelector>
                    {Object.values(OrganizationTypes).map(organizationType => (
                      <OrganizationType
                        key={`organizationType${organizationType}`}
                        $selected={values.organizationType === organizationType}
                        onClick={() =>
                          setFieldValue('organizationType', organizationType)
                        }
                      >
                        <div>
                          <b>
                            {organizationType === OrganizationTypes.Regular
                              ? 'Regular'
                              : 'Academic'}
                          </b>
                        </div>
                      </OrganizationType>
                    ))}
                  </OrganizationTypeSelector>
                  <Input
                    name="organizationName"
                    label="Organization name*"
                    value={values.organizationName}
                    error={
                      touched.organizationName
                        ? getFieldRequestError(
                            'organizationName',
                            values.organizationName,
                          ) || errors.organizationName
                        : undefined
                    }
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                  <Input
                    name="organizationContactFirstName"
                    label="Contact first name*"
                    value={values.organizationContactFirstName}
                    error={
                      touched.organizationContactFirstName
                        ? getFieldRequestError(
                            'organizationContactFirstName',
                            values.organizationContactFirstName,
                          ) || errors.organizationContactFirstName
                        : undefined
                    }
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                  <Input
                    name="organizationContactLastName"
                    label="Contact last name*"
                    value={values.organizationContactLastName}
                    error={
                      touched.organizationContactLastName
                        ? getFieldRequestError(
                            'organizationContactLastName',
                            values.organizationContactLastName,
                          ) || errors.organizationContactLastName
                        : undefined
                    }
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                  <Input
                    name="organizationAddress1"
                    label="Address line 1*"
                    value={values.organizationAddress1}
                    error={
                      touched.organizationAddress1
                        ? getFieldRequestError(
                            'organizationAddress1',
                            values.organizationAddress1,
                          ) || errors.organizationAddress1
                        : undefined
                    }
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                  <Input
                    name="organizationAddress2"
                    label="Address line 2"
                    value={values.organizationAddress2}
                    error={
                      touched.organizationAddress2
                        ? getFieldRequestError(
                            'organizationAddress2',
                            values.organizationAddress2,
                          ) || errors.organizationAddress2
                        : undefined
                    }
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                  <Input
                    name="organizationCity"
                    label="City*"
                    value={values.organizationCity}
                    error={
                      touched.organizationCity
                        ? getFieldRequestError(
                            'organizationCity',
                            values.organizationCity,
                          ) || errors.organizationCity
                        : undefined
                    }
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                  <Input
                    name="organizationState"
                    label="State*"
                    value={values.organizationState}
                    error={
                      touched.organizationState
                        ? getFieldRequestError(
                            'organizationState',
                            values.organizationState,
                          ) || errors.organizationState
                        : undefined
                    }
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                  <Input
                    name="organizationPostalCode"
                    label="Postal code*"
                    value={values.organizationPostalCode}
                    error={
                      touched.organizationPostalCode
                        ? getFieldRequestError(
                            'organizationPostalCode',
                            values.organizationPostalCode,
                          ) || errors.organizationPostalCode
                        : undefined
                    }
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                  <Select
                    id="organizationCountry"
                    name="organizationCountry"
                    label="Country*"
                    value={values.organizationCountry}
                    error={
                      touched.organizationCountry
                        ? getFieldRequestError(
                            'organizationCountry',
                            values.organizationCountry,
                          ) || errors.organizationCountry
                        : undefined
                    }
                    onChange={event => {
                      handleChange(event)
                      setAreaCodeToSelectedCountry(event.target.value as string)
                    }}
                    onBlur={handleBlur}
                    options={countryOptions}
                  />
                  <PhoneNumberInput
                    name="organizationPhoneNumber"
                    label="Contact phone number*"
                    onPhoneNumberChange={(value: string) => {
                      setFieldValue('organizationPhoneNumber', value)
                    }}
                    error={
                      touched.organizationEmail
                        ? getFieldRequestError(
                            'organizationPhoneNumber',
                            values.organizationPhoneNumber,
                          ) || errors.organizationPhoneNumber
                        : undefined
                    }
                    areaCodeValue={areaCodeValue}
                    phoneNumber={values.organizationPhoneNumber}
                    countryCodes={countryCodeList.data || []}
                    areaCode={selectedAreaCode}
                    onAreaCodeChange={setAreaCodeToSelectedCountry}
                    onBlur={handleBlur}
                  />
                  <Input
                    name="organizationEmail"
                    label="Contact email*"
                    value={values.organizationEmail}
                    error={
                      touched.organizationEmail
                        ? getFieldRequestError(
                            'organizationEmail',
                            values.organizationEmail,
                          ) || errors.organizationEmail
                        : undefined
                    }
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                </Section>
                <LicenseAgreementConfirmation
                  isChecked={isLicenseChecked}
                  onChange={() => setLicenseChecked(!isLicenseChecked)}
                />
                {signUpRequestResult.error && (
                  <ErrorMessage>{globalError}</ErrorMessage>
                )}
              </ReCaptcha>
              <Buttons>
                <Button
                  type="button"
                  colorOverride="greyscale"
                  onClick={() => history.push('/log-in')}
                >
                  Back
                </Button>
                <Button
                  type="button"
                  onClick={() => setShowFreeTrialModal(true)}
                  disabled={
                    isSubmitting ||
                    !isValid ||
                    (isReCaptchaRequired && !isReCaptchaCompleted) ||
                    !isLicenseChecked
                  }
                >
                  Submit
                </Button>
              </Buttons>
              {shouldShowFreeTrialModal && (
                <FreeTrialModal
                  onSubmit={handleSubmit}
                  onChange={(isTrial: boolean) =>
                    setFieldValue('isTrialOrganization', isTrial)
                  }
                  onClose={() => setShowFreeTrialModal(false)}
                />
              )}
            </form>
          )}
        </Formik>
      </SignUpPageRoot>
    </LoggedOutLayout>
  )
}

const SignUpPageRoot = styled.div`
  background: ${props => props.theme.colors.white};
  padding: ${props => props.theme.spacing(4, 8)};
  border-radius: ${props => props.theme.radius[2]}px;
  box-shadow: ${props => props.theme.shadow[1]};
  width: 100%;
`

const Buttons = styled.div`
  display: flex;
  justify-content: center;
  gap: ${props => props.theme.spacing(2)}px;
  margin-top: ${props => props.theme.spacing(4)}px;
`

const ErrorMessage = styled.div`
  color: ${props => props.theme.colors.error};
`

const OrganizationDetailsSectionTitle = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;

  button {
    font-size: 12px;
    max-width: 162px;
  }
`

const OrganizationTypeSelector = styled.div`
  display: flex;
  height: 32px;
  justify-content: center;
  margin-bottom: ${props => props.theme.spacing(3)}px;
  border: 2px solid ${props => props.theme.colors.primary[50]};
  border-radius: ${props => props.theme.radius[2]}px;
  overflow: hidden;
`

const OrganizationType = styled.div<{ $selected: boolean }>`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 50%;
  text-align: center;
  color: ${props =>
    props.$selected
      ? props.theme.colors.white
      : props.theme.colors.primaryDark[100]};
  background: ${props =>
    props.$selected ? props.theme.colors.primary[50] : 'transparent'};
  cursor: pointer;
  transition: all ease 100ms;
`
