import { Box, CircularProgress, PopperProps } from '@material-ui/core'
import { ascending, cluster, hierarchy } from 'd3'
import { useMemo, useRef, useState } from 'react'
import { useKeyPress } from 'react-use'
import { Controls, Edge, Node, ReactFlow } from 'reactflow'
import styled, { useTheme } from 'styled-components'

import { ReactComponent as CreatePipelineIcon } from 'assets/images/icons/create-pipeline-icon.svg'
import { ReactComponent as MetaAnalysisIcon } from 'assets/images/icons/meta-analysis-icon.svg'
import { ReactComponent as RetriggerWorkflowIcon } from 'assets/images/icons/retrigger-workflow.svg'
import { ReactComponent as WorkflowSecondaryClusteringIcon } from 'assets/images/icons/workflow-secondary-clustering-icon.svg'
import { ReactComponent as WorkflowSubAnalysisIcon } from 'assets/images/icons/workflow-sub-analysis-icon.svg'

import { ContextMenu } from 'components/ContextMenu'
import { EditableLabel } from 'components/EditableLabel'
import { MessagesForUserModal } from 'components/MessagesForUserModal'
import { OptionsContextMenu } from 'components/OptionsContextMenu'
import { Button } from 'components/button/Button'
import { MenuItem } from 'components/menu/MenuItem'
import { NestedMenuItem } from 'components/menu/NestedMenuItem'

import {
  ExperimentDetails,
  useRetriggerBricksMutation,
} from 'shared/api/experiments.api'
import { PipelineDetails } from 'shared/api/pipelines.api'
import { WorkflowDetails, useLazyGetBrickQuery } from 'shared/api/workflows.api'
import { useModal } from 'shared/contexts/ModalContext'
import { useEventCallback } from 'shared/hooks/useEventCallback'
import { groupByUnique } from 'shared/utils/collection.utils'
import { createVirtualElement } from 'shared/utils/popper'
import { includeIf } from 'shared/utils/utils'
import { findRootBricks } from 'shared/utils/workflow'

import { BrickAnalysisList } from './BrickAnalysisList'
import { CreateBrickWizard } from './CreateBrickWizard'
import { MetaAnalysisWizard } from './MetaAnalysisWizard'
import { RenameBrickModal } from './RenameBrickModal'
import { StatusChip } from './StatusChip'
import { WorkflowBrickNode } from './WorkflowBrickNode'
import { WorkflowBrickSettingsModal } from './WorkflowBrickSettingsModal'
import { WorkflowFilesModal } from './WorkflowFilesModal'
import { WorkflowFilesNode } from './WorkflowFilesNode'
import { WorkflowMetaAnalysisModal } from './WorkflowMetaAnalysisModal'
import { WORKFLOW_NODE_HEIGHT, WORKFLOW_NODE_WIDTH } from './constants'

type WorkflowViewProps = {
  workflow?: WorkflowDetails | PipelineDetails | ExperimentDetails
  shouldShowDebugId?: boolean
  onDeleteBrick?: (brickId: string, isLastRootBrick: boolean) => void
  onUpdate?: ({
    name,
    description,
  }: {
    name?: string
    description?: string
  }) => void
  onCreatePipeline?: () => void
  onClose?: () => void
  onDelete?: () => void
}

export type WorkflowInternalBrick = {
  id: string
  children?: WorkflowInternalBrick[]
}

export const WorkflowView: React.FC<WorkflowViewProps> = ({
  workflow,
  shouldShowDebugId,
  onUpdate,
  onDeleteBrick,
  onCreatePipeline,
  onClose,
  onDelete,
}) => {
  const theme = useTheme()
  const { showModal } = useModal()
  const [isMetaPressed] = useKeyPress('Meta')

  const [triggerRetriggerBricksMutation] = useRetriggerBricksMutation()
  const [triggerGetBrickQuery, getBrickQueryState] = useLazyGetBrickQuery()

  const [shouldShowContextMenu, setShouldShowContextMenu] = useState<
    { brickId: string; anchor: PopperProps['anchorEl'] } | undefined
  >(undefined)
  const workflowRootRef = useRef<HTMLDivElement>(null)

  const rootBricks = useMemo(() => {
    if (!workflow) {
      return []
    }
    return findRootBricks(workflow?.bricks)
  }, [workflow])

  const CONTAINER_PADDING = 48
  const { nodes, edges, height, brickById } = useMemo(() => {
    if (!workflow) {
      return {
        nodes: [],
        edges: [],
        height: 0,
        brickById: {},
      }
    }

    const root = hierarchy<WorkflowInternalBrick>({
      id: 'files',
      children: rootBricks,
    })
    root.sort((a, b) => ascending(a.data.id, b.data.id))
    const HORIZONTAL_NODE_PADDING = 64
    const VERTICAL_NODE_PADDING = 8
    // we need to swap the width and height because the tree is presented horizontally
    const nodeSize = [
      WORKFLOW_NODE_HEIGHT + VERTICAL_NODE_PADDING,
      WORKFLOW_NODE_WIDTH + HORIZONTAL_NODE_PADDING,
    ] as [number, number]
    const tree = cluster<WorkflowInternalBrick>().nodeSize(nodeSize)

    const layoutNodes = tree(root)
      .descendants()
      .map(node => {
        return {
          ...node,
          // we need to swap x and y so the tree is presented horizontally
          x: node.y,
          y: node.x,
        }
      })

    const brickById = groupByUnique(workflow.bricks, 'id')
    const nodes: Node[] = layoutNodes.map(node => {
      const isRoot = node.parent === null

      if (isRoot) {
        return {
          id: 'files',
          position: { x: node.x, y: node.y },
          data: {},
          type: 'files',
        }
      }

      return {
        id: node.data.id,
        position: { x: node.x, y: node.y },
        data: {
          brick: brickById[node.data.id],
          hasChildren: node.children !== undefined,
          workflowRootRef,
        },
        type: 'brick',
      }
    })

    const edges: Edge[] = root.links().map(link => {
      return {
        id: `${link.source.data.id}-${link.target.data.id}`,
        source: link.source.data.id,
        target: link.target.data.id,
      }
    })

    const minY = Math.min(...nodes.map(node => node.position.y))
    const maxY = Math.max(...nodes.map(node => node.position.y))
    const height =
      maxY -
      minY +
      WORKFLOW_NODE_HEIGHT +
      VERTICAL_NODE_PADDING +
      2 * CONTAINER_PADDING

    return {
      nodes,
      edges,
      height,
      brickById,
    }
  }, [rootBricks, workflow])

  const canRetriggerWorkflow =
    workflow?.mode === 'experiment' &&
    (workflow.status === 'Ready' || workflow.status === 'Error') &&
    workflow.bricks.every(
      brick => brick.status === 'Ready' || brick.status === 'Error',
    )

  const handleCreateNewBrick = () => {
    setShouldShowContextMenu(undefined)
    if (!shouldShowContextMenu) {
      throw new Error(
        'Context menu should be open to show create new step modal.',
      )
    }
    showModal(CreateBrickWizard, {
      brick: brickById[shouldShowContextMenu.brickId],
    })
  }

  const handleShowBrickSettings = () => {
    setShouldShowContextMenu(undefined)
    if (!shouldShowContextMenu) {
      throw new Error(
        'Context menu should be open to show step settings modal.',
      )
    }

    showModal(WorkflowBrickSettingsModal, {
      brickId: shouldShowContextMenu.brickId,
    })
  }

  const handleRenameBrick = async (brickId: string) => {
    const brick = await triggerGetBrickQuery(brickId).unwrap()
    setShouldShowContextMenu(undefined)
    showModal(RenameBrickModal, {
      brick,
    })
  }

  const handleShowMetaAnalysisWizard = () => {
    if (!workflow) {
      throw new Error('Workflow not found. Cannot show meta analysis wizard.')
    }
    showModal(MetaAnalysisWizard, {
      workflow,
      brickIds: Object.keys(brickById),
    })
  }

  const handleShowMetaAnalysisModal = () => {
    setShouldShowContextMenu(undefined)
    if (!workflow) {
      throw new Error('Workflow not found. Cannot show meta analysis modal.')
    }
    showModal(WorkflowMetaAnalysisModal, {
      workflow,
      onShowMetaAnalysisWizard: handleShowMetaAnalysisWizard,
    })
  }

  const handleDeleteBrick = () => {
    setShouldShowContextMenu(undefined)
    if (!shouldShowContextMenu) {
      throw new Error('Context menu should be open to delete step.')
    }
    const brick = brickById[shouldShowContextMenu.brickId]
    const isLastRootBrick = !brick.parent && rootBricks.length === 1
    onDeleteBrick?.(shouldShowContextMenu.brickId, isLastRootBrick)
  }

  const handleCreatePipeline = useEventCallback(() => {
    onCreatePipeline?.()
  })

  const handleRetriggerWorkflow = () => {
    if (!workflow) {
      throw new Error('Workflow not found. Cannot retrigger workflow.')
    }
    triggerRetriggerBricksMutation({
      experimentId: workflow.id,
      brickIds: rootBricks.map(brick => brick.id),
    })
  }

  const handleRetriggerBrick = () => {
    setShouldShowContextMenu(undefined)
    if (!shouldShowContextMenu) {
      throw new Error('Context menu should be open to retrigger step.')
    }
    if (!workflow) {
      throw new Error('Workflow not found. Cannot retrigger step.')
    }

    triggerRetriggerBricksMutation({
      experimentId: workflow.id,
      brickIds: [shouldShowContextMenu.brickId],
    })
  }

  const handleShowBrickMessages = () => {
    setShouldShowContextMenu(undefined)
    if (!shouldShowContextMenu) {
      throw new Error('Context menu should be open to show step messages.')
    }
    showModal(MessagesForUserModal, {
      messages: brickById[shouldShowContextMenu.brickId].messages_for_user,
      title: `Messages for ${brickById[shouldShowContextMenu.brickId].name}`,
    })
  }

  const handleShowWorkflowMessages = () => {
    if (!workflow) {
      throw new Error('Workflow not found. Cannot show workflow messages.')
    }
    showModal(MessagesForUserModal, {
      messages: workflow.messages_for_user,
      title: `Messages for ${workflow.name}`,
    })
  }

  const handleCopyBrickIdToClipboard = () => {
    setShouldShowContextMenu(undefined)
    if (!shouldShowContextMenu) {
      throw new Error('Context menu should be open to copy step ID.')
    }
    navigator.clipboard.writeText(
      `workflow-step:${shouldShowContextMenu.brickId}`,
    )
  }

  if (!workflow) {
    return null
  }

  return (
    <WorkflowRoot ref={workflowRootRef}>
      {shouldShowDebugId && <DebugId>{workflow.id}</DebugId>}
      <Header>
        <WorkflowInfo>
          {onUpdate ? (
            <TitleEditableLabel
              value={workflow.name}
              onChange={value => {
                onUpdate({ name: value })
              }}
            />
          ) : (
            <Title>{workflow.name}</Title>
          )}
          {onUpdate ? (
            <DescriptionEditableLabel
              value={workflow.description ?? ''}
              emptyLabel="Add description"
              onChange={value => onUpdate({ description: value })}
            />
          ) : (
            <Description>{workflow.description}</Description>
          )}
        </WorkflowInfo>
        <WorkflowActions>
          <StatusChip status={workflow.status} />
          {workflow.mode === 'experiment' && (
            <StyledButton
              startIcon={<RetriggerWorkflowIcon />}
              colorOverride="white"
              disabled={!canRetriggerWorkflow}
              onClick={handleRetriggerWorkflow}
            >
              Retrigger
            </StyledButton>
          )}
          {(workflow.mode === 'workflow' || workflow.mode === 'experiment') && (
            <MetaAnalysisButton
              startIcon={<MetaAnalysisIcon />}
              colorOverride="white"
              onClick={handleShowMetaAnalysisModal}
            >
              Meta-analysis
            </MetaAnalysisButton>
          )}
          {onCreatePipeline && (
            <StyledButton
              startIcon={<CreatePipelineIcon />}
              colorOverride="white"
              onClick={handleCreatePipeline}
            >
              Save as pipeline
            </StyledButton>
          )}
          <StyledOptionsContextMenu
            options={[
              {
                label: 'Show messages',
                onClick: handleShowWorkflowMessages,
              },
              ...includeIf(onDelete, [{ label: 'Delete', onClick: onDelete }]),
            ]}
          />
          <Legend>
            <LegendLine>
              <WorkflowSecondaryClusteringIcon />
              Secondary clustering
            </LegendLine>
            <LegendLine>
              <WorkflowSubAnalysisIcon />
              Sub analysis
            </LegendLine>
          </Legend>
        </WorkflowActions>
      </Header>
      <div
        style={{ height }}
        onWheelCapture={event => {
          // to allow scrolling the page when the mouse is over the workflow
          if (!isMetaPressed) {
            event.stopPropagation()
          }
        }}
      >
        <ReactFlow
          nodes={nodes}
          edges={edges}
          nodeTypes={nodeTypes}
          edgesUpdatable={false}
          nodesConnectable={false}
          fitView
          defaultEdgeOptions={{
            style: { stroke: theme.colors.primary[70] },
          }}
          maxZoom={1}
          onPaneClick={() => {
            setShouldShowContextMenu(undefined)
          }}
          onMove={() => {
            setShouldShowContextMenu(undefined)
          }}
          onMouseDownCapture={() => {
            // this is a workaround to close the context menu,
            // because react flow is stopping the propagation of the mousedown event
            document.dispatchEvent(new MouseEvent('mousedown'))
          }}
          onNodeClick={(event, node) => {
            if (node.type === 'files') {
              if (!workflow) {
                throw new Error('Workflow not found')
              }
              showModal(WorkflowFilesModal, {
                compensatedFiles: workflow.compensated_files,
              })
            } else {
              setShouldShowContextMenu({
                brickId: node.id as string,
                anchor: createVirtualElement(event.clientX, event.clientY),
              })
            }
          }}
        >
          <Controls showInteractive={false} position="top-right" />
        </ReactFlow>
        {shouldShowContextMenu && (
          <ContextMenu
            open
            anchorEl={shouldShowContextMenu.anchor}
            placement="bottom-start"
            onClose={() => setShouldShowContextMenu(undefined)}
            disablePortal
          >
            {brickById[shouldShowContextMenu.brickId].status !== 'Pending' && (
              <NestedMenuItem label="View analysis layout">
                <BrickAnalysisList
                  brickId={shouldShowContextMenu.brickId}
                  onClose={() => {
                    onClose?.()
                  }}
                  onCloseContextMenu={() => {
                    setShouldShowContextMenu(undefined)
                  }}
                />
              </NestedMenuItem>
            )}
            {brickById[shouldShowContextMenu.brickId].status !== 'Pending' && (
              <>
                {workflow.mode === 'workflow' && (
                  <>
                    <MenuItem onClick={handleCreateNewBrick}>
                      Create new step
                    </MenuItem>
                    {onDeleteBrick && (
                      <MenuItem onClick={handleDeleteBrick} $color="error">
                        Delete
                      </MenuItem>
                    )}
                  </>
                )}
                <MenuItem onClick={handleShowBrickSettings}>
                  View settings
                </MenuItem>
                <MenuItem
                  onClick={() =>
                    handleRenameBrick(shouldShowContextMenu.brickId)
                  }
                >
                  {getBrickQueryState.isLoading ? (
                    <Box display="flex" justifyContent="center" width="100%">
                      <CircularProgress size={16} />
                    </Box>
                  ) : (
                    'Rename step'
                  )}
                </MenuItem>
                {workflow.mode === 'experiment' && (
                  <MenuItem
                    disabled={!canRetriggerWorkflow}
                    onClick={handleRetriggerBrick}
                  >
                    Retrigger
                  </MenuItem>
                )}
              </>
            )}
            <MenuItem onClick={handleShowBrickMessages}>Show messages</MenuItem>
            <MenuItem onClick={handleCopyBrickIdToClipboard}>Copy ID</MenuItem>
          </ContextMenu>
        )}
      </div>
    </WorkflowRoot>
  )
}

const nodeTypes = {
  files: WorkflowFilesNode,
  brick: WorkflowBrickNode,
}

const WorkflowRoot = styled.div`
  background-color: white;
  border-radius: 22px;
  padding-top: 16px;
  display: grid;
  grid-template-columns: 1fr;
  box-shadow: 0px 4px 10px 0px #e8e9f399;
  overflow: hidden;
  align-self: stretch;

  .react-flow__attribution {
    margin-right: 8px;
    margin-bottom: 4px;
  }
`

const Header = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  gap: ${props => props.theme.spacing(2)}px;
  margin-right: ${props => props.theme.spacing(3)}px;
  margin-bottom: ${props => props.theme.spacing(2)}px;
  padding: 0 16px;
`

const WorkflowInfo = styled.div`
  flex-grow: 1;
`

const TitleEditableLabel = styled(EditableLabel)`
  p {
    color: ${props => props.theme.colors.primaryDark['70']};
    font-weight: bold;
  }
  input {
    font-weight: bold;
  }
`

const Title = styled.p`
  color: ${props => props.theme.colors.primaryDark[50]};
  font-family: ${props => props.theme.font.style.bold};
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`

const DescriptionEditableLabel = styled(EditableLabel)`
  .label {
    position: relative;
    height: 58px;
    overflow: auto;
    white-space: normal;

    &::after {
      content: '';
      position: sticky;
      bottom: -2px;
      left: 0;
      width: 100%;
      height: 16px;
      display: block;
      background: linear-gradient(to top, white, transparent);
    }
  }
  p {
    color: ${props => props.theme.colors.primaryDark['50']};
    font-size: ${props => props.theme.font.size.small}px;
  }
  input {
    font-size: ${props => props.theme.font.size.small}px;
  }
  button {
    align-self: start;
  }
`

const Description = styled.p`
  color: ${props => props.theme.colors.primaryDark[50]};
  position: relative;
  height: 58px;
  overflow: auto;

  &::after {
    content: '';
    position: sticky;
    bottom: -2px;
    left: 0;
    width: 100%;
    height: 16px;
    display: block;
    background: linear-gradient(to top, white, transparent);
  }
`

const WorkflowActions = styled.div`
  flex-shrink: 0;
  display: flex;
  gap: ${props => props.theme.spacing(1)}px;
`

const MetaAnalysisButton = styled(Button)`
  height: 36px;
  border-color: ${props => props.theme.colors.success};
  &,
  &:hover {
    color: ${props => props.theme.colors.success};
  }
`

const StyledButton = styled(Button)`
  height: 36px;
  &:not(:disabled) {
    border-color: ${props => props.theme.colors.primaryDark['70']};
  }
  &,
  &:hover {
    color: ${props => props.theme.colors.primaryDark['70']};
  }
`

const Legend = styled.div`
  margin-left: 16px;
`

const LegendLine = styled.div`
  display: flex;
  align-items: center;
  font-size: 11px;
`

const DebugId = styled.div`
  font-size: 10px;
  color: ${({ theme }) => theme.colors.greyscale[30]};
  font-family: ${({ theme }) => theme.font.style.light};
  margin-left: 42px;
`

const StyledOptionsContextMenu = styled(OptionsContextMenu)`
  height: 36px;
  width: 36px;
`
