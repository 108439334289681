import { PaginatedResults } from 'shared/models/Result'

import {
  Organization,
  OrganizationInvitation,
  OrganizationMember,
} from '../models/OrganizationModels'
import { privateApi } from './private.api'
import { publicApi } from './public.api'
import { encodeTagParameters } from './utils'

export const organizationPrivateApi = privateApi.injectEndpoints({
  endpoints: builder => ({
    getOrganization: builder.query<GetOrganizationResult, void>({
      queryFn: async (_arg, _api, _extraOptions, baseQuery) => {
        const result = await baseQuery({ url: '/organizations/' })
        if (result.error) {
          return result
        }
        const organizations = result.data as PaginatedResults<
          GetOrganizationResult[]
        >
        return {
          // We are assuming that there is only one organization for a user
          data: organizations.results[0],
        }
      },
      providesTags: [
        { type: 'Organization', id: encodeTagParameters({ id: 'list' }) },
      ],
    }),
    verifyOrganization: builder.mutation<void, { verification_code: string }>({
      query: verificationData => ({
        url: '/organizations/verify/',
        method: 'PATCH',
        body: verificationData,
      }),
      invalidatesTags: ['Organization'],
    }),
    getOrganizationMembers: builder.query<
      GetOrganizationMembersResult,
      { page: number; ordering: string }
    >({
      query: params => ({
        url: '/members/',
        params,
      }),
      providesTags: [
        { type: 'Member', id: encodeTagParameters({ id: 'list' }) },
      ],
    }),
    getOrganizationMember: builder.query<OrganizationMember, { id: string }>({
      query: ({ id }) => ({
        url: `/members/${id}/`,
      }),
      providesTags: (_result, _error, args) => [
        { type: 'Member', id: encodeTagParameters({ id: args.id }) },
      ],
    }),
    updateMember: builder.mutation<
      void,
      { id: string; job?: string; is_active: boolean; is_expert: boolean }
    >({
      query: ({ id, job, is_active, is_expert }) => ({
        url: `/members/${id}/`,
        method: 'PUT',
        body: { job, is_active, is_expert },
      }),
    }),
    assignOperatorPermission: builder.mutation<void, { id: string }>({
      query: ({ id }) => ({
        url: `/members/${id}/assign_operator_permission/`,
        method: 'POST',
      }),
    }),
    getUsersInvitations: builder.query<
      GetUsersInvitationsResult,
      GetUserInvitationsPayload
    >({
      query: payload => {
        const params = new URLSearchParams({
          page: payload.page.toString(),
          ordering: payload.ordering,
        })
        for (const status of payload.statuses) {
          params.append('statuses', status.replaceAll(' ', '_').toUpperCase())
        }
        return {
          url: '/users/invitation/',
          params,
        }
      },
      providesTags: [
        { type: 'Invitation', id: encodeTagParameters({ id: 'list' }) },
      ],
    }),
    sentInvitation: builder.mutation<void, { email: string }>({
      query: ({ email }) => ({
        url: `/users/invitation/`,
        method: 'POST',
        body: { email },
      }),
    }),
    deleteInvitation: builder.mutation<void, { id: string }>({
      query: ({ id }) => ({
        url: `/users/invitation/${id}/`,
        method: 'DELETE',
      }),
    }),
  }),
})

export const organizationPublicApi = publicApi.injectEndpoints({
  endpoints: builder => ({
    getUserInvitation: builder.query<OrganizationInvitation, { id: string }>({
      query: ({ id }) => ({
        url: `/users/invitation/${id}/`,
      }),
    }),
    acceptInvitation: builder.mutation<
      OrganizationInvitation,
      {
        id: string
        password: string
        firstName: string
        lastName: string
        job?: string
      }
    >({
      query: payload => ({
        url: `/users/invitation/${payload.id}/`,
        method: 'PUT',
        body: {
          password: payload.password,
          first_name: payload.firstName,
          last_name: payload.lastName,
          job: payload.job,
        },
      }),
    }),
  }),
})

export const {
  useGetOrganizationQuery,
  useLazyGetOrganizationQuery,
  useVerifyOrganizationMutation,
  useGetOrganizationMembersQuery,
  useGetOrganizationMemberQuery,
  useUpdateMemberMutation,
  useAssignOperatorPermissionMutation,
  useGetUsersInvitationsQuery,
  useSentInvitationMutation,
  useDeleteInvitationMutation,
} = organizationPrivateApi

type GetOrganizationResult = Organization & {
  verified: boolean
}

type GetUserInvitationsPayload = {
  page: number
  ordering: string
  statuses: OrganizationInvitation['status'][]
}

export const { useGetUserInvitationQuery, useAcceptInvitationMutation } =
  organizationPublicApi

type GetOrganizationMembersResult = PaginatedResults<OrganizationMember[]>
type GetUsersInvitationsResult = PaginatedResults<OrganizationInvitation[]>
