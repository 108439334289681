import { Link, Tooltip } from '@material-ui/core'
import { Info } from '@material-ui/icons'
import { Formik, FormikConfig } from 'formik'
import { useRef, useState } from 'react'
import ReCAPTCHA from 'react-google-recaptcha'
import { useHistory } from 'react-router'
import { Link as RouterLink } from 'react-router-dom'
import styled from 'styled-components'
import { object, string } from 'yup'

import { ReactComponent as ArrowRight } from 'assets/images/icons/arrowRight.svg'

import { Button } from 'components/Button'
import { LoggedOutLayout } from 'components/LoggedOutLayout'
import Input from 'components/forms/Input'
import { ReCaptcha } from 'components/forms/ReCaptcha'

import { useLoginMutation } from 'shared/api/auth.api'
import { useEventCallback } from 'shared/hooks/useEventCallback'
import {
  ReCaptchaFlags,
  useReCaptchaRequired,
} from 'shared/hooks/useReCaptchaRequired'
import { useServerFormValidation } from 'shared/hooks/useServerFormValidation'
import { getURLSearchParam } from 'shared/utils/utils'

type LogInFormValues = {
  email: string
  password: string
}

const initialValues: LogInFormValues = {
  email: '',
  password: '',
}

const LogInFormSchema = object({
  email: string().email().required(),
  password: string().required(),
})

export const LogInPage = (): JSX.Element => {
  const [triggerLoginMutation, loginMutationState] = useLoginMutation()
  const history = useHistory()
  const isReCaptchaRequired = useReCaptchaRequired(ReCaptchaFlags.Login)
  const [isReCaptchaCompleted, setReCaptchaCompleted] = useState(false)
  const reCaptchaRef = useRef<ReCAPTCHA>(null)

  const { getFieldRequestError, setSubmittedValues } = useServerFormValidation({
    error: loginMutationState.error,
    fields: Object.keys(initialValues) as (keyof LogInFormValues)[],
  })

  const handleLogin: FormikConfig<LogInFormValues>['onSubmit'] =
    useEventCallback(async (values, { setSubmitting }) => {
      try {
        const reCaptchaToken =
          (reCaptchaRef.current && reCaptchaRef.current.getValue()) || ''

        const loginResult = await triggerLoginMutation({
          reCaptchaToken,
          ...values,
        }).unwrap()

        const redirectTo =
          loginResult.redirect_to || getURLSearchParam('redirect') || '/'
        history.replace(redirectTo)
      } finally {
        setSubmittedValues(values)
        setSubmitting(false)
        reCaptchaRef.current && reCaptchaRef.current.reset()
      }
    })

  return (
    <LoggedOutLayout>
      <LogInPageRoot>
        <Formik<LogInFormValues>
          initialValues={initialValues}
          validationSchema={LogInFormSchema}
          onSubmit={handleLogin}
        >
          {({
            values,
            touched,
            errors,
            isSubmitting,
            isValid,
            handleChange,
            handleBlur,
            handleSubmit,
          }) => (
            <form onSubmit={handleSubmit}>
              <Title>Please type your email & password to connect</Title>
              <Input
                error={
                  touched.email
                    ? getFieldRequestError('email', values.email) ||
                      errors.email
                    : undefined
                }
                id="email"
                name="email"
                value={values.email}
                placeholder="Email"
                onChange={handleChange}
                onBlur={handleBlur}
                type="email"
              />
              <Input
                error={
                  touched.password
                    ? getFieldRequestError('password', values.password) ||
                      errors.password
                    : undefined
                }
                id="password"
                name="password"
                value={values.password}
                placeholder="Password"
                onChange={handleChange}
                onBlur={handleBlur}
                type="password"
              />
              <ReCaptcha
                onCompleted={() => setReCaptchaCompleted(true)}
                ref={reCaptchaRef}
              >
                <LinksContainer>
                  <Link
                    data-cy="forgot-password-link"
                    component={RouterLink}
                    to="/forgot-password"
                    variant="caption"
                  >
                    I forgot my password
                  </Link>
                  <RegisterLinkContainer>
                    <Link
                      component={RouterLink}
                      to="/sign-up"
                      variant="caption"
                    >
                      Register
                    </Link>
                    <Tooltip
                      title={
                        <span>
                          Create an <b>organization account</b>
                        </span>
                      }
                      arrow
                    >
                      <InfoIcon />
                    </Tooltip>
                  </RegisterLinkContainer>
                </LinksContainer>
              </ReCaptcha>
              <StyledButton
                data-cy="sign-in-btn"
                disableWhileLoading={false}
                type="submit"
                variant="contained"
                color="primary"
                disabled={
                  isSubmitting ||
                  !isValid ||
                  (isReCaptchaRequired && !isReCaptchaCompleted)
                }
                endIcon={<ArrowRight />}
              >
                Log in
              </StyledButton>
            </form>
          )}
        </Formik>
      </LogInPageRoot>
    </LoggedOutLayout>
  )
}

const LogInPageRoot = styled.div`
  background: ${props => props.theme.colors.white};
  padding: ${props => props.theme.spacing(4, 8)};
  border-radius: ${props => props.theme.radius[2]}px;
  box-shadow: ${props => props.theme.shadow[1]};
  width: 100%;
`

const Title = styled.p`
  margin-bottom: ${props => props.theme.spacing(4)}px;
  text-align: center;
  font-family: ${props => props.theme.font.style.bold};
`

const LinksContainer = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: ${props => props.theme.spacing(2)}px;
`

const StyledButton = styled(Button)`
  margin-left: auto;
  height: 50px;
  width: 140px;
  display: flex;
  padding: 0 25px;
  justify-content: space-between;
`

const RegisterLinkContainer = styled.div`
  display: flex;
  align-items: center;
`

const InfoIcon = styled(Info)`
  height: 24px;
  width: 24px;
  margin-left: ${props => props.theme.spacing(0.5)}px;
`
