import { DialogActions, DialogContent, DialogTitle } from '@material-ui/core'
import { FC } from 'react'
import styled from 'styled-components'

import { Button } from 'components/Button'

type SimpleOptionDialogProps = {
  title: string
  message: string
  options: { label: string; onClick: () => void }[]
  onClose: () => void
}

export const SimpleOptionDialog: FC<SimpleOptionDialogProps> = ({
  title,
  message,
  options,
  onClose,
}) => {
  return (
    <SimpleOptionDialogRoot>
      <DialogTitle>{title}</DialogTitle>
      <DialogContent>
        {message}
        <Options>
          {options.map(({ label, onClick }) => (
            <Button
              key={label}
              onClick={() => {
                onClick()
                onClose()
              }}
            >
              {label}
            </Button>
          ))}
        </Options>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Cancel</Button>
      </DialogActions>
    </SimpleOptionDialogRoot>
  )
}

const SimpleOptionDialogRoot = styled.div``

const Options = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`
