import { difference, intersection, union } from 'lodash'
import { useState } from 'react'

type UseTableSelectionProps = {
  allIds: string[]
  currentPageIds: string[]
  initialSelectedIds?: string[]
}

type UseTableSelectionResult = {
  selectedIds: string[]
  areAllSelected: boolean
  handleSelect: (id: string) => void
  handleSelectCurrentPage: () => void
  handleSelectAll: () => void
}

export const useTableSelection = ({
  allIds,
  currentPageIds,
  initialSelectedIds = [],
}: UseTableSelectionProps): UseTableSelectionResult => {
  const [selectedIds, setSelectedIds] = useState<string[]>(initialSelectedIds)

  const areAllSelected =
    allIds.length > 0 &&
    allIds.length === intersection(selectedIds, allIds).length

  const areAllCurrentPageSelected =
    currentPageIds.length > 0 &&
    currentPageIds.length === intersection(selectedIds, currentPageIds).length

  const handleSelect = (element: string): void => {
    if (selectedIds.includes(element)) {
      setSelectedIds(selectedIds.filter(el => el !== element))
    } else {
      setSelectedIds([...selectedIds, element])
    }
  }

  const handleSelectCurrentPage = () => {
    if (areAllCurrentPageSelected) {
      setSelectedIds(difference(selectedIds, currentPageIds))
    } else {
      setSelectedIds(union(selectedIds, currentPageIds))
    }
  }

  const handleSelectAll = () => {
    if (areAllSelected) {
      setSelectedIds(difference(selectedIds, allIds))
    } else {
      setSelectedIds(union(selectedIds, allIds))
    }
  }

  return {
    selectedIds,
    areAllSelected,
    handleSelect,
    handleSelectCurrentPage,
    handleSelectAll,
  }
}
