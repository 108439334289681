import { FC, ReactNode } from 'react'
import { ErrorBoundary } from 'react-error-boundary'

import { ErrorFallback } from './ErrorFallback'

export const DefaultErrorBoundary: FC<{ children: ReactNode }> = ({
  children,
}) => {
  return (
    <ErrorBoundary FallbackComponent={ErrorFallback}>{children}</ErrorBoundary>
  )
}
