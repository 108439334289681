import {
  ContactSupport,
  LocalHospital,
  Memory,
  Person,
} from '@material-ui/icons'

import { Organization } from './OrganizationModels'
import { User } from './User'

export enum LicenseTypes {
  Regular = 'RG',
  Academic = 'AC',
}

enum ResourceTypes {
  Seats = 'ST',
  StorageCapacity = 'CP',
  ClinicalGrade = 'CG',
  Support = 'SP',
}

// License packages
export type LicensePackage = {
  id: string
  name: string
  description: string
  durability: string
  net_price: string
  gross_price: string
  vat: string
  vat_value: string
  currency: string
  currency_display: string
  is_trial: boolean
  license_packages_resource_types: LicensePackageResourceType[]
  license_type: `${LicenseTypes}`
  license_type_display: string
}

export type LicensePackageResourceType = {
  resource_type: ResourceType
  resource_type_name: ResourceLabel
  value: number
}

//Resources
export type ResourceType = `${ResourceTypes}`

export const RESOURCE_ICONS = {
  ST: Person,
  CP: Memory,
  CG: LocalHospital,
  SP: ContactSupport,
} as const

export const RESOURCE_LABELS = {
  ST: 'Seats',
  CP: 'Storage capacity',
  CG: 'Clinical grade',
  SP: 'Support',
} as const

type ResourceLabel = (typeof RESOURCE_LABELS)[ResourceType]

// License wallet
export type LicenseWallet = {
  id: string
  organization: Organization
  total_resources: Record<ResourceType, LicenseWalletResource>
}

export type LicenseWalletResource = {
  name: ResourceLabel
  start_value: number
  current_value: number
}

// Baskets
export type Basket = {
  id: string
  created_at: string
  updated_at: string
  author: string
  net_price: string
  gross_price: string
  vat: string
  vat_value: string
  currency: string
  currency_display: string
  baskets_items: BasketItem[]
  license_agreement: string
  payment_transaction?: string
}

type BasketItem = {
  id: string
  author: string
  basket: string
  created_at: string
  updated_at: string
  currency: string
  license_package: LicensePackage
  net_price: string
  vat: string
  quantity: number
}

// Payments
export type Payment = {
  id: string
  status: string
  status_display: string
  ext_transaction_id: string
  total_price: string
  vat: string
  currency: string
  currency_display: string
  basket: Basket
  author: Partial<User> & { redirect_to?: string }
  license_wallet: LicenseWallet
  created_at: string
  updated_at: string
  expires_at?: string
  comment?: string
  invoice_ready: boolean
}
