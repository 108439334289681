import { LogActions } from 'shared/contexts/LogContext'

export enum GlossaryReference {
  'starburst' = 'Starburst',
  'heatmap' = 'Heatmap',
  'general' = 'General',
  'layout' = 'Layout',
  'graph' = 'Graph',
}

export type GlossaryEntries = {
  [K in GlossaryReference]: GlossaryKeyword
}
export type GlossaryKeyword = { [K in LogActions]?: string }

export const glossaryEntries: GlossaryEntries = {
  [GlossaryReference.starburst]: {
    [LogActions.s_depth_changed]: 'Change of the Starburst depth',
    [LogActions.s_cluster_expanded_merged]:
      'Specific cluster expanded/merged on Starburst',
    [LogActions.s_visible_clusters_expanded]:
      'All visible clusters expanded on Starburst',
    [LogActions.s_visible_clusters_merged]:
      'All visible clusters merged to parents on Starburst',
    [LogActions.s_cluster_renamed]: 'Specific cluster renamed on Starburst',
    [LogActions.s_cluster_highlighted]:
      'Specific cluster highlighted on Starburst',
    [LogActions.s_cluster_color_change]: 'Cluster color changed on Starburst',
    [LogActions.s_globally_shown_and_hidden_clusters_updated]:
      "Shown and hidden clusters changed in Starburst's select displayed clusters menu",
    [LogActions.s_sub_analysis_created_from_shown_clusters]:
      "A sub analysis was created from this analysis' shown clusters",
  },
  [GlossaryReference.heatmap]: {
    [LogActions.h_cluster_renamed]: 'Specific cluster renamed on the Heatmap',
    [LogActions.h_channel_edited]:
      'Specific channel edited (on/off) on the Heatmap',
    [LogActions.h_legend_colour_changed]:
      'The colors of the heatmap legend have been changed',
    [LogActions.h_cluster_colour_changed]:
      'The colour of the cluster has been changed',
    [LogActions.h_cluster_hidden]: 'The cluster has been hidden on the heatmap',
    [LogActions.h_clusters_and_channels_order_updated]:
      "The heatmap's clusters and channels order has been updated",
  },
  [GlossaryReference.general]: {
    [LogActions.log_in_logbook]: 'Log in to the logbook',
    [LogActions.log_out_logbook]: 'Log out of the logbook',
  },
  [GlossaryReference.layout]: {
    [LogActions.l_graph_created]: 'Graph created on the layout',
    [LogActions.l_analysis_saved]: 'Analysis saved',
    [LogActions.l_export_stat]: 'Statistics exported from the analysis layout',
    [LogActions.l_export_event]:
      'Events statistics exported from the analysis layout',
    [LogActions.l_channel_edited]:
      'Specific channel edited (on/off) on the layout',
    [LogActions.l_graph_deleted]: 'Graph removed from the layout',
    [LogActions.l_graph_duplicated]: 'Graph duplicated on the layout',
    // kept for backwards compatibility
    [LogActions.l_rearrange_order]: 'Graphs rearranged on the layout',
    [LogActions.l_layout_changed]: 'Layout changed',
    [LogActions.l_analysis_e_signed]:
      'The analysis has been concluded by e-signature',
    [LogActions.l_analysis_e_signed_cancelled]:
      'The analysis concluded by e-signature has been cancelled',
    [LogActions.l_review_e_signed]:
      'The analysis has been reviewed by e-signature',
    [LogActions.l_approved_e_signed]:
      'The analysis has been approved by e-signature',
    [LogActions.l_cluster_highlighted]: 'Specific cluster highlighted',
    [LogActions.l_cluster_toggle_global_visibility]:
      'Cluster toggle global visibility',
    [LogActions.l_channel_name_changed]: 'Channel name changed',
  },
  [GlossaryReference.graph]: {
    [LogActions.g_cluster_locally_shown]: 'Cluster locally shown on a graph',
    [LogActions.g_cluster_locally_hidden]: 'Cluster locally hidden on a graph',
    [LogActions.g_cluster_color_change]: 'Cluster color changed on graph',
    [LogActions.g_graph_renamed]: 'Graph name changed',
    [LogActions.g_axes_scale_changed]: 'Axes scale changed on the graph',
    [LogActions.g_export_graph]: 'Graph exported',
    [LogActions.g_gate_created]: 'Gate created on a graph',
    [LogActions.g_gate_renamed]: 'Gate renamed on a graph',
    [LogActions.g_gate_duplicated]: 'Gate duplicated on other graph',
    [LogActions.g_gate_toggle_visibility]: 'Gate shown/hidden on graph',
    [LogActions.g_graph_type_changed]: 'The graph type has been changed',
  },
}
