import styled from 'styled-components'

import { MetaflowLogoLink } from 'components/MetaflowLogoLink'
import { UserWidget } from 'components/UserWidget'

import { AutoSaveButton } from 'pages/analysis/AutoSaveAnalysisButton'

import { useIsAnalysisPage, useIsMetaAnalysisPage } from 'shared/hooks/router'
import { getIsSecondaryTab } from 'shared/utils/multi-tab'

type HeaderProps = {
  className?: string
  shouldShowMetaflowLogo?: boolean
}

export const Header: React.FC<HeaderProps> = ({
  className,
  shouldShowMetaflowLogo,
}) => {
  const isSecondaryTab = getIsSecondaryTab()
  const isAnalysisPage = useIsAnalysisPage()
  const isMetaAnalysisPage = useIsMetaAnalysisPage()

  return (
    <HeaderRoot className={className}>
      <RightPart>
        {((isAnalysisPage && !isSecondaryTab) || isMetaAnalysisPage) && (
          <AutoSaveButton
            type={isMetaAnalysisPage ? 'meta-analysis' : 'analysis'}
          />
        )}
        <UserWidget />
      </RightPart>
      {shouldShowMetaflowLogo && <MetaflowLogoLink />}
    </HeaderRoot>
  )
}

const HeaderRoot = styled.div`
  display: flex;
  flex-direction: row-reverse;
  justify-content: space-between;
  align-items: center;
`

const RightPart = styled.div`
  display: flex;
  align-items: center;
`
