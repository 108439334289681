import { RadioGroup } from '@material-ui/core'
import styled from 'styled-components'

import { ChannelsList } from 'components/ChannelsList'
import { Checkbox } from 'components/Checkbox'
import { NoDataFound } from 'components/NoDataFound'
import RadioButton from 'components/RadioButton'
import { TextButton } from 'components/button/TextButton'
import Select from 'components/forms/Select'
import { TextField } from 'components/text-field/TextField'

import { LassoTag } from 'pages/analysis/LassoTag'

import { FcsFileChannel } from 'shared/api/files.api'
import {
  BrickAnalysis,
  BrickSettingsCytometerType,
  BrickSettingsTransformationType,
  PrecisionAndGranularityMode,
} from 'shared/api/workflows.api'
import { OTHER_LETTER } from 'shared/constants'
import { useSafeNavigate } from 'shared/hooks/router'
import { Lasso } from 'shared/models/AnalysisModels'

import { WizardSectionCard } from './WizardSectionCard'
import { CLUSTERING_EXCLUDED_CHANNELS } from './constants'

type WorkflowBrickSettingsProps = {
  name: string
  cytometer: BrickSettingsCytometerType
  isRoot: boolean
  metaClean: boolean
  transformationMethod: BrickSettingsTransformationType | null
  clusterNames: string[] | undefined
  lassos: Record<string, Lasso> | undefined
  isSubAnalysis: boolean
  channels: Record<string, FcsFileChannel> | undefined
  precisionAndGranularityMode: PrecisionAndGranularityMode
  kt: number
  kd: number
  referenceAnalysis?: BrickAnalysis
}

export const WorkflowBrickSettings: React.FC<WorkflowBrickSettingsProps> = ({
  name,
  cytometer,
  isRoot,
  metaClean,
  transformationMethod,
  clusterNames,
  lassos,
  isSubAnalysis,
  channels,
  precisionAndGranularityMode,
  kt,
  kd,
  referenceAnalysis,
}) => {
  const safeNavigate = useSafeNavigate()

  const handleNavigateToReferenceAnalysis = () => {
    safeNavigate(`/analysis/${referenceAnalysis?.id}`)
  }

  return (
    <WorkflowBrickSettingsRoot>
      <WizardSectionCard title="Name">{name}</WizardSectionCard>
      {referenceAnalysis && (
        <WizardSectionCard title="Reference analysis">
          <TextButton onClick={handleNavigateToReferenceAnalysis}>
            {referenceAnalysis.name}
          </TextButton>
        </WizardSectionCard>
      )}
      <WizardSectionCard title="Utilized cytometer type">
        <RadioGroup row name="cytometer" value={cytometer ?? null}>
          <RadioButton value="conventional" label="Conventional" />
          <RadioButton value="spectral" label="Spectral" />
          <RadioButton value="mass" label="Mass" />
        </RadioGroup>
      </WizardSectionCard>
      {isRoot && (
        <WizardSectionCard title="Metaclean">
          <Checkbox
            checked={metaClean}
            label="Identification and removal of anomalies derived from abrupt changes in flow rate or signal instability"
          />
        </WizardSectionCard>
      )}
      {!isSubAnalysis && (
        <WizardSectionCard title="Display transformation">
          <RadioGroup
            row
            name="transformationMethod"
            value={transformationMethod ?? null}
          >
            <RadioButton value="none" label="None" />
            <RadioButton value="arcsinh" label="ArcSinH" />
            <RadioButton value="logicle" label="Logicle" />
            <RadioButton value="biexp" label="Biexpo" />
          </RadioGroup>
        </WizardSectionCard>
      )}
      <WizardSectionCard title="Selected clusters">
        <SelectedClusters>
          {clusterNames && clusterNames.length > 0 ? (
            clusterNames.map(clusterName => (
              <Checkbox key={clusterName} label={clusterName} checked />
            ))
          ) : (
            <NoDataFound>No clusters were selected</NoDataFound>
          )}
        </SelectedClusters>
      </WizardSectionCard>
      <WizardSectionCard title="Selected lassos">
        {lassos && Object.values(lassos).length > 0 ? (
          <SelectedLassos>
            {Object.values(lassos).map(lasso => (
              <StyledLassoTag name={lasso.name} />
            ))}
          </SelectedLassos>
        ) : (
          <NoDataFound>No lassos were selected</NoDataFound>
        )}
      </WizardSectionCard>
      {!isSubAnalysis && (
        <WizardSectionCard title="Selected channels for clustering">
          <ChannelsList
            columns={3}
            channels={Object.entries(channels ?? {}).map(([name, details]) => {
              const defaultLabel = details.PnS ?? undefined

              return {
                name,
                label: defaultLabel,
                defaultLabel,
                ...details,
                letter: details.letter ?? OTHER_LETTER,
              }
            })}
            excludedChannels={CLUSTERING_EXCLUDED_CHANNELS}
            selectedChannels={Object.values(channels ?? {})
              .filter(channel => channel.is_selected)
              .map(channel => channel.id)}
          />
        </WizardSectionCard>
      )}
      {!isSubAnalysis && (
        <WizardSectionCard title="Granularity and precision">
          <Select
            name="precisionAndGranularityMode"
            label="Mode"
            value={precisionAndGranularityMode}
            options={[
              { value: 'standard', label: 'Standard' },
              { value: 'rare-cell', label: 'Rare cell' },
              { value: 'expert', label: 'Expert' },
            ]}
            disabled
          />
          {precisionAndGranularityMode === 'expert' && (
            <>
              <div>
                <TextField
                  type="number"
                  name="kt"
                  label="Granularity"
                  value={kt}
                  disabled
                />
              </div>
              <div>
                <TextField
                  type="number"
                  name="kd"
                  label="Precision"
                  value={kd}
                  disabled
                />
              </div>
            </>
          )}
        </WizardSectionCard>
      )}
    </WorkflowBrickSettingsRoot>
  )
}

const WorkflowBrickSettingsRoot = styled.div`
  overflow-y: auto;
  width: 100%;
`

const SelectedClusters = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 16px;
`

const SelectedLassos = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 12px;
`

const StyledLassoTag = styled(LassoTag)`
  font-size: 12px;
  font-weight: bold;
`
