import { FC } from 'react'
import styled from 'styled-components'

import { CircularProgress } from 'components/CircularProgress'
import { Button } from 'components/button/Button'
import { Modal } from 'components/modal/Modal'
import { ModalContainer } from 'components/modal/ModalContainer'

import { useGetPipelineQuery } from 'shared/api/pipelines.api'

import { WorkflowView } from './WorkflowView'

type PipelinePreviewModalProps = {
  pipelineId: string
  onClose: () => void
}

export const PipelinePreviewModal: FC<PipelinePreviewModalProps> = ({
  pipelineId,
  onClose,
}) => {
  const getPipelineQueryState = useGetPipelineQuery(pipelineId)

  return (
    <Modal
      open
      title={
        <>
          View pipeline <DebugId>{pipelineId}</DebugId>
        </>
      }
      Container={StyledModalContainer}
      onClose={onClose}
    >
      {getPipelineQueryState.isLoading ? (
        <CircularProgress />
      ) : (
        <WorkflowView workflow={getPipelineQueryState.data} onClose={onClose} />
      )}
      <ButtonWrapper>
        <Button colorOverride="greyscale" onClick={onClose}>
          Close
        </Button>
      </ButtonWrapper>
    </Modal>
  )
}

const StyledModalContainer = styled(ModalContainer)`
  width: 60vw;
  min-width: 900px;
  gap: ${props => props.theme.spacing(2)}px;
`

const ButtonWrapper = styled.div`
  display: flex;
  justify-content: center;
  margin-top: ${props => props.theme.spacing(4)}px;
`

const DebugId = styled.div`
  font-size: 10px;
  color: ${({ theme }) => theme.colors.greyscale[30]};
  font-family: ${({ theme }) => theme.font.style.light};
`
