import { MenuItem, Tooltip } from '@material-ui/core'
import { FC } from 'react'
import styled from 'styled-components'

import { MessagesForUserModal } from 'components/MessagesForUserModal'
import { OptionsContextMenu } from 'components/OptionsContextMenu'
import { StatusDots } from 'components/StatusDots'
import { SearchInput } from 'components/input/SearchInput'

import {
  ValidatedAction,
  useAnalysisStatuses,
} from 'pages/analysis/useAnalysisStatuses'

import { BrickAnalysis } from 'shared/api/workflows.api'
import { useModal } from 'shared/contexts/ModalContext'

type SearchableAnalysisListProps = {
  availableAnalyses: BrickAnalysis[]
  validationAction: ValidatedAction
  onSearchValueChange: (value: string) => void
  onAnalysisClick: (analysisId: string) => void
  onCloseContextMenu: () => void
}

export const SearchableAnalysisList: FC<SearchableAnalysisListProps> = ({
  availableAnalyses,
  validationAction,
  onSearchValueChange,
  onAnalysisClick,
  onCloseContextMenu,
}) => {
  const { validateActionBasedOnAnalysisStatus } = useAnalysisStatuses()
  const { showModal } = useModal()

  return (
    <AnalysisListRoot>
      <SearchInput
        placeholder="Search files"
        onChange={value => onSearchValueChange(value)}
      />
      <AnalysisList>
        {availableAnalyses.length > 0 ? (
          availableAnalyses.map(analysis => {
            const isAnalysisValid =
              validateActionBasedOnAnalysisStatus(
                analysis.status,
                validationAction,
              ) === undefined

            return (
              <AnalysisListItemWrapper key={analysis.id}>
                <AnalysisListItem
                  onClick={
                    isAnalysisValid
                      ? () => {
                          onAnalysisClick(analysis.id)
                        }
                      : undefined
                  }
                  disabled={!isAnalysisValid}
                >
                  <Tooltip title={analysis.name}>
                    <AnalysisName>{analysis.name}</AnalysisName>
                  </Tooltip>
                  <StatusDots status={analysis.status} singleLine />
                </AnalysisListItem>
                <StyledOptionsContextMenu
                  options={[
                    {
                      label: 'Show messages',
                      onClick: event => {
                        event.stopPropagation()
                        showModal(MessagesForUserModal, {
                          messages: analysis.messages_for_user,
                          title: `Messages for analysis ${analysis.name}`,
                        })
                        onCloseContextMenu()
                      },
                    },
                    {
                      label: 'Copy ID',
                      onClick: event => {
                        event.stopPropagation()
                        navigator.clipboard.writeText(`analysis:${analysis.id}`)
                        onCloseContextMenu()
                      },
                    },
                  ]}
                />
              </AnalysisListItemWrapper>
            )
          })
        ) : (
          <NoAnalysesFound>No analyses found</NoAnalysesFound>
        )}
      </AnalysisList>
    </AnalysisListRoot>
  )
}

const AnalysisListRoot = styled.div`
  padding: 16px 10px;
  width: 400px;
  background-color: ${props => props.theme.colors.white};
  display: grid;
  grid-template-rows: auto 1fr;
  overflow: hidden;
`

const AnalysisList = styled.div`
  padding: 20px 10px 0;
  overflow-y: auto;
`

const AnalysisListItemWrapper = styled.div`
  display: flex;
  align-items: center;

  &:not(:last-child) {
    border-bottom: 1px solid ${props => props.theme.colors.greyscale[10]};
    padding-bottom: 4px;
  }

  &:not(:first-child) {
    padding-top: 4px;
  }
`

const AnalysisListItem = styled(MenuItem)`
  color: ${props => props.theme.colors.primaryDark[70]};
  font-family: ${props => props.theme.font.style.bold};
  display: flex;
  flex-grow: 1;
  border-bottom: none !important;
  border-radius: 8px;
`

const AnalysisName = styled.div`
  flex-grow: 1;
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: ${props => props.theme.font.size.smallest}px;
`

const NoAnalysesFound = styled.div`
  color: ${props => props.theme.colors.primaryDark[50]};
  text-align: center;
  padding: 10px 0;
  font-size: 13px;
  font-weight: bold;
`

const StyledOptionsContextMenu = styled(OptionsContextMenu)`
  width: 16px;
  height: 16px;
  svg {
    width: 18px;
    height: 18px;
  }
`
