import { round } from 'lodash'
import styled from 'styled-components'

import { DotLabel } from 'components/DotLabel'

type AnalysisMetaCleanChartTooltipProps = {
  name: string
  color: string
  seriesName: string
  x: number
  y: number
}

export const AnalysisMetaCleanChartTooltip: React.FC<
  AnalysisMetaCleanChartTooltipProps
> = ({ name, color, seriesName, x, y }) => {
  return (
    <AnalysisMetaCleanChartTooltipRoot>
      <Header>
        <ClusterLabel>{name}</ClusterLabel>
        {seriesName === 'drop' ? (
          <div
            style={{
              color,
              marginLeft: 'auto',
            }}
          >
            ▲
          </div>
        ) : (
          <StyledDotLabel $color={color} />
        )}
      </Header>
      <div>x: {x}</div>
      <div>y: {round((y ?? 0) - 0.00001, 3)}</div>
    </AnalysisMetaCleanChartTooltipRoot>
  )
}

const AnalysisMetaCleanChartTooltipRoot = styled.div`
  max-height: 80vh;
  overflow: hidden;
  padding: 12px;
`

const Header = styled.div`
  display: flex;
  align-items: center;
  padding-bottom: 3px;
`

const ClusterLabel = styled.div`
  font-family: ${props => props.theme.font.style.bold};
  font-size: 14px;
`

const StyledDotLabel = styled(DotLabel)`
  margin-left: auto;
`
