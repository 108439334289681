import React, { FC } from 'react'
import styled from 'styled-components'

type DataLineProps = Readonly<{
  title?: string
  content?: string
  contentTooltip?: string
  className?: string
  inverted?: boolean
}>

export const DataLine: FC<DataLineProps> = ({
  title,
  content,
  contentTooltip,
  className,
  inverted,
}) => {
  return (
    <Container className={className}>
      <Title
        data-cy="data-line-title"
        inverted={inverted}
        title={inverted ? title : ''}
      >
        {title || '-'}
      </Title>
      <Content inverted={inverted} title={contentTooltip ?? content}>
        {content || '-'}
      </Content>
    </Container>
  )
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  & p {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
`

const Title = styled.p<{ inverted?: boolean }>`
  font-size: ${props =>
    props.inverted
      ? props.theme.font.size.regular
      : props.theme.font.size.smallest}px;
  color: ${props => props.theme.colors.primaryDark[props.inverted ? 100 : 70]};
  font-family: ${props =>
    props.inverted
      ? props.theme.font.style.bold
      : props.theme.font.style.regular};
`

const Content = styled.p<{ inverted?: boolean }>`
  margin-top: 2px;
  font-size: ${props => props.theme.font.size.smallest}px;
  color: ${props => props.theme.colors.primaryDark[props.inverted ? 70 : 100]};
  font-family: ${props =>
    props.inverted
      ? props.theme.font.style.regular
      : props.theme.font.style.bold};
`
