import { Point } from 'geometric'

export const translateToChartPoint = (
  realPoint: Point,
  chart: Highcharts.Chart,
): Point => {
  const x = chart.xAxis?.[0].toPixels(realPoint[0], false)
  const y = chart.yAxis?.[0].toPixels(realPoint[1], false)
  return [x, y]
}
