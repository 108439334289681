import React, { FC } from 'react'

import { Cluster, selectClusterById } from 'pages/analysis/store/selectors'

import { useAppSelector } from 'shared/store'

import { AnalysisScatterPlotTooltip } from './AnalysisScatterPlotTooltip'

type HighPerformanceScatterPlotTooltipProps = {
  seriesId: string
  hoveredLassoName: string | undefined
  hoveredLassoClusters: Cluster[] | undefined
} & React.HTMLAttributes<HTMLDivElement>

export const HighPerformanceScatterPlotTooltip: FC<
  HighPerformanceScatterPlotTooltipProps
> = ({ seriesId, hoveredLassoName, hoveredLassoClusters }) => {
  const clusterById = useAppSelector(selectClusterById)
  const cluster = seriesId && clusterById[seriesId]
  if (!cluster) return null

  return (
    <AnalysisScatterPlotTooltip
      name={cluster.label}
      color={cluster.color}
      parentRate={cluster.parent_rate}
      numberOfEvents={cluster.stats.count}
      hoveredLassoName={hoveredLassoName}
      hoveredLassoClusters={hoveredLassoClusters}
    />
  )
}
