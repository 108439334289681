import { fetchBaseQuery } from '@reduxjs/toolkit/query'

import { href } from 'shared/utils/utils'

import { prepareHeaders } from './utils'

export const publicFetchBaseQuery: typeof fetchBaseQuery = () =>
  fetchBaseQuery({
    baseUrl: href('/'),
    prepareHeaders,
  })
