import { mapValues } from 'lodash'

type DownsampleHistogramDataProps = {
  numberOfBins?: number
  scale: [number, number]
  pointsBySeriesId: Record<string, { id: number; x: number; y: number }[]>
}

export const downsampleHistogramData = ({
  numberOfBins = 2 ** 10,
  scale,
  pointsBySeriesId,
}: DownsampleHistogramDataProps): Record<string, [number, number][]> => {
  const binRange = (scale[1] - scale[0]) / numberOfBins
  return mapValues(pointsBySeriesId, points => {
    const pointCountByBinIndex = new Map<number, number>()
    for (const event of points) {
      const binIndex = Math.min(
        Math.floor((event.x - scale[0]) / binRange),
        numberOfBins - 1,
      )
      const currentPointCount = pointCountByBinIndex.get(binIndex) ?? 0
      pointCountByBinIndex.set(binIndex, currentPointCount + 1)
    }

    return [...pointCountByBinIndex.entries()].map(([binIndex, count]) => {
      return [binIndex * binRange, count] as [number, number]
    })
  })
}
