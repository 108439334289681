import { difference } from 'lodash'
import React, { useCallback, useMemo, useState } from 'react'
import styled from 'styled-components'
import { v4 as uuidv4 } from 'uuid'

import { DefaultErrorBoundary } from 'components/DefaultErrorBoundary'
import { Modal } from 'components/Modal'
import { AnalysisScatterPlotWithGating } from 'components/graphs/AnalysisScatterPlotWithGating'

import { Graph } from 'shared/models/Graphs'
import { useAppDispatch, useAppSelector } from 'shared/store'

import { GateList } from './GateList'
import {
  acceptGate as acceptGateActionCreator,
  removeGate as removeGateActionCreator,
} from './store/analysis.slice'
import { selectAvailableGateLetters } from './store/selectors'
import { useGateWithPercentages } from './useGateWithPercentages'

type AnalysisScatterPlotGatesProps = {
  chart: Graph
  onClose: () => void
}

export const AnalysisScatterPlotGates: React.FC<
  AnalysisScatterPlotGatesProps
> = ({ chart, onClose }) => {
  const dispatch = useAppDispatch()
  const availableGateLetters = useAppSelector(selectAvailableGateLetters)

  const [draftGates, setDraftGates] = useState<Graph.Gate[]>([])
  const [gatesOrder, setGatesOrder] = useState<string[]>(
    chart.gates.map(gate => gate.id),
  )
  const [gatingMode, setGatingMode] = useState<Graph.GateTypes>('rectangle')
  const [tmpGatingMode, setTmpGatingMode] =
    useState<Graph.GateTypes>('rectangle')
  const [showGatingChangeModal, setShowGatingChangeModal] = useState(false)

  const gatesWithPercentages = useGateWithPercentages({
    chartId: chart.id,
    gates: useMemo(
      () => [...draftGates, ...chart.gates],
      [chart.gates, draftGates],
    ),
  })

  const gates = useMemo(() => {
    return gatesOrder.map(gateId => {
      const gate = gatesWithPercentages.find(gate => gate.id === gateId)
      if (!gate) {
        throw new Error(`Gate ${gateId} not found`)
      }
      return gate
    })
  }, [gatesOrder, gatesWithPercentages])

  const addDraftGate = useMemo(
    () =>
      addDraftGateFactory({
        draftGates,
        setDraftGates,
        availableGateLetters: difference(
          availableGateLetters,
          draftGates.map(gate => gate.name).filter(name => name.length === 1),
        ),
        setGatesOrder,
      }),
    [availableGateLetters, draftGates],
  )

  const acceptGate = useCallback(
    (gateId: string, gateName: string) => {
      const acceptedGate = gates.find(gate => gate.id === gateId)
      if (!acceptedGate) {
        throw new Error(`Gate ${gateId} not found`)
      }
      dispatch(
        acceptGateActionCreator({
          chartId: chart.id,
          gate: { ...acceptedGate, name: gateName || acceptedGate.name },
        }),
      )
      setDraftGates(draftGates => draftGates.filter(gate => gate.id !== gateId))
    },
    [chart.id, dispatch, gates],
  )

  const cancelGate = useCallback(
    (canceledGate: Graph.Gate) => {
      setDraftGates(draftGates.filter(gate => gate.id !== canceledGate.id))
      if (!chart.gates.some(gate => gate.id === canceledGate.id)) {
        setGatesOrder(gatesOrder.filter(gateId => gateId !== canceledGate.id))
      }
    },
    [chart.gates, draftGates, gatesOrder],
  )

  const removeGate = useCallback(
    (gate: Graph.Gate) => {
      dispatch(
        removeGateActionCreator({
          chartId: chart.id,
          gateId: gate.id,
        }),
      )
      setGatesOrder(gatesOrder => gatesOrder.filter(id => id !== gate.id))
    },
    [chart.id, dispatch],
  )

  const updateGate = useCallback(
    (gateId: string, values: Graph.GateValues) => {
      if (draftGates.some(gate => gate.id === gateId)) {
        setDraftGates(draftGates => {
          return draftGates.map(gate => {
            if (gate.id === gateId) {
              return { ...gate, tempValues: values }
            }
            return gate
          })
        })
      }

      const gate = chart.gates.find(gate => gate.id === gateId)
      if (!gate) {
        throw new Error(`Gate ${gateId} not found`)
      }
      setDraftGates(draftGates => [
        ...draftGates,
        { ...gate, tempValues: values },
      ])
    },
    [chart.gates, draftGates],
  )

  return (
    <Modal
      open
      onClose={onClose}
      disablePortal={true}
      disableBackdropClick={true}
    >
      <Root>
        <ChartContainer>
          <DefaultErrorBoundary>
            <AnalysisScatterPlotWithGating
              chart={chart}
              gates={gates}
              gatingMode={gatingMode}
              onAddDraftGate={addDraftGate}
            />
          </DefaultErrorBoundary>
        </ChartContainer>
        <GateList
          graph={chart}
          gates={gates}
          gatingMode={gatingMode}
          setGatingMode={setGatingMode}
          tmpGatingMode={tmpGatingMode}
          setTmpGatingMode={setTmpGatingMode}
          showGatingChangeModal={showGatingChangeModal}
          setShowGatingChangeModal={setShowGatingChangeModal}
          onGateUpdated={updateGate}
          onAccept={acceptGate}
          onRemove={removeGate}
          onCancel={cancelGate}
          onCloseClick={onClose}
        />
      </Root>
    </Modal>
  )
}

const addDraftGateFactory =
  ({
    draftGates,
    setDraftGates,
    availableGateLetters,
    setGatesOrder,
  }: {
    draftGates: Graph.Gate[]
    setDraftGates: (gates: Graph.Gate[]) => void
    availableGateLetters: string[]
    setGatesOrder: (value: (previousState: string[]) => string[]) => void
  }) =>
  (
    type: Graph.GateTypes,
    gateValues: Graph.GateValues,
    points: Graph.Point[] = [],
    pointsString = '',
    isUpdatePolygonGate?: boolean,
  ) => {
    const draftGateId = uuidv4()
    const draftGateName = availableGateLetters[0]

    if (type === 'rectangle') {
      const draftGate: Graph.Gate = {
        type: 'rectangle',
        id: draftGateId,
        name: draftGateName,
        xMin: 0,
        xMax: 0,
        yMin: 0,
        yMax: 0,
        tempValues: gateValues,
        defaultName: draftGateName,
      }
      setDraftGates([...draftGates, draftGate])
      setGatesOrder(gatesOrder => [...gatesOrder, draftGateId])
    } else if (type === 'polygon' && !isUpdatePolygonGate) {
      const draftGate: Graph.Gate = {
        type: 'polygon',
        id: draftGateId,
        name: draftGateName,
        xMin: 0,
        xMax: 0,
        yMin: 0,
        yMax: 0,
        tempValues: gateValues,
        points: points ?? [],
        pointsString: pointsString ?? '',
        defaultName: draftGateName,
      }
      setDraftGates([...draftGates, draftGate])
      setGatesOrder(gatesOrder => [...gatesOrder, draftGateId])
    } else if (type === 'polygon' && isUpdatePolygonGate) {
      const draftPolygonGate = draftGates.find(
        gate => gate.type === 'polygon' && gate.tempValues,
      )
      if (draftPolygonGate) {
        setDraftGates(
          draftGates.map(gate => {
            if (gate.id === draftPolygonGate.id) {
              return {
                ...gate,
                tempValues: gateValues,
                points: points ?? [],
                pointsString: pointsString ?? '',
              }
            }

            return gate
          }),
        )
      }
    }
  }

const Root = styled.div`
  width: 90%;
  height: 90%;
  display: grid;
  grid-template-rows: 3fr 1fr;
  grid-template-areas: 'chart' 'gate-list';
  overflow: hidden;
  grid-gap: 12px;
`

const ChartContainer = styled.div`
  background: white;
  border-radius: 6px;
  overflow: hidden;
`
