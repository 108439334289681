import { useState } from 'react'
import { useHistory } from 'react-router-dom'
import styled from 'styled-components'

import { CircularProgress } from 'components/CircularProgress'
import { NoDataFound } from 'components/NoDataFound'
import { OptionsContextMenu } from 'components/OptionsContextMenu'
import { StatusDots } from 'components/StatusDots'
import { Button } from 'components/button/Button'
import { TextButton } from 'components/button/TextButton'
import { Modal } from 'components/modal/Modal'
import { ModalContainer } from 'components/modal/ModalContainer'
import { TableColumn, TableRow } from 'components/table/Table'
import { TableWithPagination } from 'components/table/TableWithPagination'

import { ExperimentDetails } from 'shared/api/experiments.api'
import {
  useGetMetaAnalysesQuery,
  useRetriggerMetaAnalysisMutation,
} from 'shared/api/meta-analysis.api'
import { PipelineDetails } from 'shared/api/pipelines.api'
import { WorkflowDetails } from 'shared/api/workflows.api'
import { useModal } from 'shared/contexts/ModalContext'
import { formatDateTime, includeIf } from 'shared/utils/utils'

import { MetaAnalysisDetailsModal } from './MetaAnalysisDetailsModal'

type WorkflowMetaAnalysisModalProps = {
  workflow: WorkflowDetails | PipelineDetails | ExperimentDetails
  onShowMetaAnalysisWizard: () => void
  onClose: () => void
}

export const WorkflowMetaAnalysisModal: React.FC<
  WorkflowMetaAnalysisModalProps
> = ({ workflow, onShowMetaAnalysisWizard, onClose }) => {
  const { showModal } = useModal()
  const history = useHistory()
  const [page, setPage] = useState(1)
  const metaAnalysesQuery = useGetMetaAnalysesQuery({
    page,
    search: { workflow: workflow.id },
  })
  const [triggerRetriggerMetaAnalysisMutation] =
    useRetriggerMetaAnalysisMutation()

  const columns: TableColumn[] = [
    {
      key: 'name',
      label: 'Name',
      style: { minWidth: 200 },
    },
    {
      key: 'author',
      label: 'Author',
      style: { whiteSpace: 'nowrap' },
    },
    {
      key: 'created_at',
      label: 'Created at',
      style: { width: 100 },
    },
    {
      key: 'updated_at',
      label: 'Updated at',
      style: { width: 100 },
    },
    {
      key: 'status',
      label: 'Status',
      style: {
        width: 80,
      },
    },
    {
      key: 'description',
      label: 'Description',
    },
    {
      key: 'actions',
      label: '',
      canSort: false,
      style: {
        width: 0,
      },
    },
  ]

  const rows: TableRow[] = metaAnalysesQuery.data
    ? metaAnalysesQuery.data.results.map(metaAnalysis => ({
        key: metaAnalysis.id,
        tooltip: metaAnalysis.is_stale
          ? 'Not current version : Some files have been modified and not accounted for in this analysis.'
          : undefined,
        cells: [
          {
            key: 'name',
            content: (
              <TextButton
                disabled={metaAnalysis.status !== 'Done'}
                onClick={() => {
                  onClose()
                  history.push(`/meta-analysis/${metaAnalysis.id}`)
                }}
              >
                <CellText $isStale={metaAnalysis.is_stale}>
                  {metaAnalysis.name}
                </CellText>
              </TextButton>
            ),
          },
          {
            key: 'author',
            content: (
              <CellText $isStale={metaAnalysis.is_stale}>
                {metaAnalysis.author_name}
              </CellText>
            ),
          },
          {
            key: 'created_at',
            content: (
              <CellText $isStale={metaAnalysis.is_stale}>
                {formatDateTime(metaAnalysis.created_at)}
              </CellText>
            ),
          },
          {
            key: 'updated_at',
            content: (
              <CellText $isStale={metaAnalysis.is_stale}>
                {formatDateTime(metaAnalysis.updated_at)}
              </CellText>
            ),
          },
          {
            key: 'status',
            content: (
              <StatusDots
                status={metaAnalysis.status}
                disabled={metaAnalysis.is_stale}
                otherText="Out dated"
              />
            ),
          },
          {
            key: 'description',
            content: metaAnalysis.description ? (
              <Description>
                <CellText $isStale={metaAnalysis.is_stale}>
                  {metaAnalysis.description}
                </CellText>
              </Description>
            ) : null,
          },
          {
            key: 'actions',
            content: (
              <OptionsContextMenu
                options={[
                  {
                    label: 'View settings',
                    onClick: () => {
                      showModal(MetaAnalysisDetailsModal, { metaAnalysis })
                    },
                  },
                  {
                    label: 'Copy ID',
                    onClick: () => {
                      navigator.clipboard.writeText(
                        `meta-analysis:${metaAnalysis.id}`,
                      )
                    },
                  },
                  ...includeIf(metaAnalysis.status === 'Error', [
                    {
                      label: 'Delete',
                      textColor: 'error' as const,
                      onClick: () => {},
                    },
                  ]),
                  ...includeIf(metaAnalysis.is_stale, [
                    {
                      label: 'Retrigger',
                      onClick: () => {
                        triggerRetriggerMetaAnalysisMutation(metaAnalysis.id)
                      },
                    },
                  ]),
                ]}
              />
            ),
          },
        ],
      }))
    : []

  return (
    <Modal
      open
      title={
        <Title>
          <h1>Meta-analysis</h1>
          <h2>{workflow.name}</h2>
        </Title>
      }
      Container={StyledModalContainer}
      onClose={onClose}
    >
      <Title>
        <h1>Meta analysis</h1>
        <h2>{workflow.name}</h2>
      </Title>
      {metaAnalysesQuery.isLoading ? (
        <CircularProgress />
      ) : (
        <>
          {rows.length > 0 ? (
            <TableWithPagination
              columns={columns}
              rows={rows}
              page={page}
              pageCount={metaAnalysesQuery.data?.page_count ?? 1}
              onPageChange={(_event, page) => setPage(page)}
            />
          ) : (
            <NoDataFound>No meta analyses found</NoDataFound>
          )}
        </>
      )}
      <Actions>
        <Button colorOverride="greyscale" onClick={onClose}>
          Close
        </Button>
        <Button
          onClick={() => {
            onClose()
            onShowMetaAnalysisWizard()
          }}
        >
          Create meta analysis
        </Button>
      </Actions>
    </Modal>
  )
}

const StyledModalContainer = styled(ModalContainer)`
  align-items: flex-start;
  width: 80vw;
  max-height: 95vh;
  position: relative;
`

const Title = styled.div`
  h1 {
    font-size: 20px;
    margin: 0;
  }

  h2 {
    font-family: ${props => props.theme.font.style.light};
    font-size: 14px;
    margin-top: 4px;
  }
`

const Actions = styled.div`
  align-self: flex-end;
  display: flex;
  gap: 10px;
`

const Description = styled.div`
  background-color: #f8f8f8;
  border-radius: 11px;
  padding: 4px 10px;
  font-family: ${({ theme }) => theme.font.style.regular};
  color: ${({ theme }) => theme.colors.primaryDark[70]};
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  text-overflow: ellipsis;
  overflow: hidden;
`

const CellText = styled.p<{ $isStale: boolean }>`
  color: ${props =>
    props.$isStale
      ? props.theme.colors.greyscale[50]
      : props.theme.colors.primaryDark[100]};
`
