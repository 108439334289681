import { Formik, FormikConfig } from 'formik'
import { FC, useCallback } from 'react'
import styled from 'styled-components'
import { object, string } from 'yup'

import { Button } from 'components/button/Button'
import Input from 'components/forms/Input'
import { Modal } from 'components/modal/Modal'
import { ModalContainer } from 'components/modal/ModalContainer'

import { useUpdateProjectMutation } from 'shared/api/projects.api'
import { Project } from 'shared/api/projects.api'

type EditProjectModalProps = {
  project: Project | null
  onClose: () => void
}

type EditProjectFormValues = {
  name: string
  description: string
}

const EditProjectFormSchema = object({
  name: string().required().max(80, 'Name cannot be longer than 80 characters'),
  description: string().max(
    80,
    'Description cannot be longer than 80 characters',
  ),
})

export const EditProjectModal: FC<EditProjectModalProps> = ({
  project,
  onClose,
}) => {
  const [updateProject] = useUpdateProjectMutation()

  const initialValues: EditProjectFormValues = {
    name: project?.name ?? '',
    description: project?.description ?? '',
  }

  const handleSubmit: FormikConfig<EditProjectFormValues>['onSubmit'] =
    useCallback(
      (values, { setSubmitting }) => {
        if (project) {
          updateProject({ ...values, id: project.id })
        }
        setSubmitting(false)
        onClose()
      },
      [onClose, project, updateProject],
    )

  return (
    <Modal
      open
      title="Edit project details"
      onClose={onClose}
      Container={StyledModalContainer}
    >
      {project ? (
        <Formik<EditProjectFormValues>
          initialValues={initialValues}
          validationSchema={EditProjectFormSchema}
          onSubmit={handleSubmit}
        >
          {({
            values,
            touched,
            errors,
            isSubmitting,
            isValid,
            handleChange,
            handleBlur,
            handleSubmit,
          }) => (
            <Form onSubmit={handleSubmit} noValidate>
              <div>
                <Input
                  id="name"
                  name="name"
                  value={values.name}
                  placeholder="Project name"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  type="text"
                  autoComplete="off"
                  error={touched.name ? errors.name : undefined}
                />
                <TextArea
                  id="description"
                  name="description"
                  value={values.description}
                  placeholder="Description"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  type="text-area"
                  autoComplete="off"
                  error={touched.description ? errors.description : undefined}
                />
              </div>
              <Buttons>
                <Button colorOverride="greyscale" onClick={onClose}>
                  Cancel
                </Button>
                <Button
                  disabled={isSubmitting || !isValid}
                  type="submit"
                  colorOverride="success"
                >
                  Create
                </Button>
              </Buttons>
            </Form>
          )}
        </Formik>
      ) : (
        <Form>
          Project not found
          <Buttons>
            <Button colorOverride="greyscale" onClick={onClose}>
              Cancel
            </Button>
          </Buttons>
        </Form>
      )}
    </Modal>
  )
}

const StyledModalContainer = styled(ModalContainer)`
  width: 700px;
`

const Buttons = styled.div`
  display: flex;
  justify-content: center;
  gap: ${props => props.theme.spacing(1)}px;
  margin-top: ${props => props.theme.spacing(2)}px;
`

const Form = styled.form`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
  height: 100%;
  margin-top: ${props => props.theme.spacing(4)}px;
`

const TextArea = styled(Input)`
  & textarea {
    min-height: 140px;
  }
`
