import { CircularProgress } from '@material-ui/core'
import { FC, ReactNode } from 'react'
import styled, { css } from 'styled-components'

import { ReactComponent as CheckIcon } from 'assets/images/icons/check-icon.svg'
import { ReactComponent as ErrorIcon } from 'assets/images/icons/workflow-error-status.svg'

import { FileStatus } from 'shared/api/files.api'
import { WorkflowStatus } from 'shared/api/workflows.api'

type StatusChipProps = {
  status: Status
}

type Status = `${WorkflowStatus}` | `${FileStatus}`

const iconByStatus: Record<Status, ReactNode> = {
  [WorkflowStatus.Ready]: <CheckIcon />,
  [WorkflowStatus.CreatingSource]: <CircularProgress size={12} />,
  [WorkflowStatus.CreatingCopy]: <CircularProgress size={12} />,
  [WorkflowStatus.Creating]: <CircularProgress size={12} />,
  [WorkflowStatus.Processing]: <CircularProgress size={12} />,
  [WorkflowStatus.New]: <CircularProgress size={12} />,
  [WorkflowStatus.Error]: <ErrorIcon />,
  [FileStatus.Uploaded]: <CheckIcon />,
  [FileStatus.Uploading]: <CircularProgress size={12} />,
  [FileStatus.Checking]: <CircularProgress size={12} />,
}

export const StatusChip: FC<StatusChipProps> = ({ status }) => {
  const icon = iconByStatus[status]

  return (
    <StatusChipRoot $status={status}>
      {icon}
      <span>{status}</span>
    </StatusChipRoot>
  )
}

const StatusChipRoot = styled.div<{ $status: Status }>`
  border: 1px solid ${({ theme }) => theme.colors.success};
  border-radius: 6px;
  color: ${({ theme }) => theme.colors.success};
  display: flex;
  align-items: center;
  font-size: 13px;
  height: 36px;
  padding: 0 10px;
  gap: 5px;
  font-weight: 600;

  .MuiCircularProgress-root circle {
    stroke: ${({ theme }) => theme.colors.success};
  }

  ${({ $status, theme }) => {
    if ($status === WorkflowStatus.Ready) {
      return css`
        background-color: ${theme.colors.success};
        color: white;
      `
    }
    if ($status === WorkflowStatus.Error) {
      return css`
        background-color: white;
        color: ${theme.colors.error};
        border-color: ${theme.colors.error};
      `
    }
  }};
`
