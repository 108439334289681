import { Typography } from '@material-ui/core'
import { pickBy } from 'lodash'
import { useState } from 'react'
import styled from 'styled-components'

import { LoadingMask } from 'components/LoadingMask'

import {
  useGetExportTaskStatusQuery,
  useGetExportTaskTypesQuery,
  useGetExportTasksQuery,
} from 'shared/api/export.api'
import { useObserve } from 'shared/hooks/useObserve'
import { SortingOrder } from 'shared/utils/array'

import { ExportFilterFormValues, ExportFilters } from './ExportFilters'
import { ExportTaskHeader, ExportTaskTable } from './ExportTaskTable'

const columnSortingFields = [
  { header: 'Export type', field: 'export_class_display' },
  { header: 'File name', field: 'file_name' },
  { header: 'Format', field: 'format' },
  { header: 'Created', field: 'created_at' },
  { header: 'Updated', field: 'updated_at' },
  { header: 'Status', field: 'status_display' },
] as const

export const ExportsPage = (): JSX.Element => {
  const [page, setPage] = useState(1)
  const [sortingCategory, setSortingCategory] =
    useState<ExportTaskHeader>('Updated')
  const [sortingOrder, setSortingOrder] = useState<SortingOrder>('descending')
  const [filters, setFilters] = useState<ExportFilterFormValues | null>(null)

  const exportTaskTypes = useGetExportTaskTypesQuery()
  const exportTaskStatus = useGetExportTaskStatusQuery()

  const exportTasksResult = useGetExportTasksQuery({
    page,
    ordering: computeOrdering(sortingCategory, sortingOrder),
    ...pickBy(filters, value => !['', '-'].includes(value)),
  })

  useObserve(exportTasksResult.data?.page, realCurrentPage => {
    if (realCurrentPage && realCurrentPage !== page) {
      setPage(realCurrentPage)
    }
  })

  if (exportTaskTypes.isFetching || exportTaskStatus.isFetching) {
    return <LoadingMask />
  }

  const exportTaskTypeOptions = [
    { value: '-', label: '-- Select type --' },
    ...(exportTaskTypes.data?.map(({ key, name }) => ({
      value: key,
      label: name,
    })) ?? []),
  ].sort((a, b) => a.value.localeCompare(b.value))

  const exportTaskStatusOptions = [
    { value: '-', label: '-- Select status --' },
    ...(exportTaskStatus.data?.map(({ key, name }) => ({
      value: key,
      label: name,
    })) ?? []),
  ].sort((a, b) => a.value.localeCompare(b.value))

  return (
    <ExportsPageContainer>
      <Header variant="h3" align="center">
        Exports
      </Header>
      <ExportFilters
        onFilterChange={setFilters}
        typeOptions={exportTaskTypeOptions}
        statusOptions={exportTaskStatusOptions}
      />
      <ExportTaskTable
        exportTasks={exportTasksResult.data?.results ?? []}
        page={page}
        numberOfPages={exportTasksResult.data?.page_count ?? 1}
        sortingCategory={sortingCategory}
        sortingOrder={sortingOrder}
        onPageChange={setPage}
        onSortingCategoryChange={setSortingCategory}
        onSortingOrderChange={setSortingOrder}
      />
    </ExportsPageContainer>
  )
}

const computeOrdering = (header: ExportTaskHeader, order: SortingOrder) => {
  const orderColumnIndex = columnSortingFields.findIndex(
    column => column.header === header,
  )
  const orderCategory = columnSortingFields[orderColumnIndex]['field']
  return order === 'descending' ? '-' + orderCategory : orderCategory
}

const ExportsPageContainer = styled.div`
  padding-top: ${props => props.theme.layout.headerHeight + 24}px;
  width: 90%;
  margin: auto;
`
const Header = styled(Typography)`
  margin-bottom: ${props => props.theme.spacing(6)}px;
`
