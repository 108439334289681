import styled from 'styled-components'

import { computeColorFromString } from 'shared/utils/colors'

type LassoTagProps = {
  className?: string
  color?: string
  name: string
}

export const LassoTag = ({
  className,
  name,
  color = computeColorFromString(name),
}: LassoTagProps): JSX.Element => {
  return (
    <LassoTagRoot className={className} $color={color}>
      {name}
    </LassoTagRoot>
  )
}

const LassoTagRoot = styled.div<{ $color: string }>`
  --height: 1.5em;
  --border-radius: calc(var(--height) / 2);
  height: var(--height);
  font-size: 10px;
  border-radius: var(--border-radius);
  background: ${({ $color }) => $color};
  color: white;
  padding: 0 0.5em;
`
