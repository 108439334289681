import styled from 'styled-components'

type AnalysisPdfDepthSliderProps = {
  value: number
  min: number
  max: number
}

export const AnalysisPdfDepthSlider = ({
  value,
  min,
  max,
}: AnalysisPdfDepthSliderProps): JSX.Element => {
  return (
    <AnalysisPdfDepthSliderRoot>
      <MinValue>{min}</MinValue>
      <Track>
        <TrackFilled $value={value / max}>
          <Thumb>{value}</Thumb>
        </TrackFilled>
      </Track>
      <MaxValue>{max}</MaxValue>
    </AnalysisPdfDepthSliderRoot>
  )
}

const AnalysisPdfDepthSliderRoot = styled.div`
  --track-height: 10px;
  --thumb-size: 40px;

  width: 100%;
  display: grid;
  grid-template-columns: auto 1fr auto;
  place-items: center;
  font-size: 16px;
  font-weight: bold;
`

const MinValue = styled.div`
  padding-right: 8px;
`

const MaxValue = styled.div`
  padding-left: 8px;
`

const Track = styled.div`
  width: 100%;
  background-color: ${props => props.theme.colors.primary[10]};
  border-radius: calc(var(--track-height) / 2);
  display: flex;
  height: var(--track-height);
`

const TrackFilled = styled.div<{ $value: number }>`
  border-radius: 10px;
  height: 100%;
  width: ${props => props.$value * 100}%;
  background-color: ${props => props.theme.colors.primaryDark[100]};
  position: relative;
`

const Thumb = styled.div`
  position: absolute;
  right: calc(-1 * var(--thumb-size) / 2);
  top: calc(-1 * (var(--thumb-size) - var(--track-height)) / 2);
  background-color: ${props => props.theme.colors.primaryDark[100]};
  color: white;
  width: var(--thumb-size);
  height: var(--thumb-size);
  border-radius: calc(var(--thumb-size) / 2);
  display: grid;
  place-content: center;
`
