import React, { FC } from 'react'
import styled from 'styled-components'

import { Checkbox } from 'components/Checkbox'
import { TableRow } from 'components/TableRow'

import { LogEntry as _LogEntry } from 'shared/models/Logs'
import { formatDate, formatTime } from 'shared/utils/utils'

type LogEntryProps = Readonly<{
  isSelected: (id: string) => boolean
  onSelect: (id: string) => void
}> &
  _LogEntry

export const LogEntry: FC<LogEntryProps> = ({
  isSelected,
  onSelect,
  id,
  station_ip,
  created_at,
  type,
  user,
  file_path,
}) => {
  const date = formatDate(created_at)
  const time = formatTime(created_at)
  return (
    <Row>
      <Checkbox checked={isSelected(id)} onClick={() => onSelect(id)} />
      <p title={`${user.first_name} ${user.last_name}`}>
        {user.first_name} {user.last_name}
      </p>
      <p title={user.username || '-'}>{user.username || '-'}</p>
      <p title={user.permission_level}>{user.permission_level}</p>
      <p title={station_ip}>{station_ip}</p>
      <p title={date}>{date}</p>
      <p title={time}>{time}</p>
      <p title={type}>{type}</p>
      <p title={file_path}>{file_path}</p>
    </Row>
  )
}

const Row = styled(TableRow)`
  color: ${props => props.theme.colors.primaryDark[100]};
  align-items: center;
  border-radius: ${props => props.theme.radius[2]}px;
  border: 1px solid;
  border-color: ${props => props.theme.colors.primaryDark[20]};
  padding: 15px 20px;
  margin-bottom: 16px;
  & > p {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
`
