import { createListenerMiddleware } from '@reduxjs/toolkit'

import { Graph } from 'shared/models/Graphs'
import { RootState } from 'shared/store'
import { checkIfClusterIsInsideLasso } from 'shared/utils/clusters.utils'

import { postDependentChartsMiddleware } from './analysis.slice'
import {
  selectAnalysisLassos,
  selectAnalysisLoadingStatus,
  selectChartById,
  selectChildChartsById,
  selectClusterById,
  selectRootCharts,
} from './selectors'

export const dependentChartsMiddleware = createListenerMiddleware()

dependentChartsMiddleware.startListening({
  predicate: () => true,
  effect: (_, api) => {
    const state = api.getState() as RootState
    const isAnalysisLoaded = selectAnalysisLoadingStatus(state) === 'success'
    if (!isAnalysisLoaded) {
      return
    }
    const clusterById = selectClusterById(state)
    const lassoById = selectAnalysisLassos(state)
    const rootCharts = selectRootCharts(state)
    const childChartsById = selectChildChartsById(state)

    const previousState = api.getOriginalState() as RootState
    const isPreviousAnalysisLoaded =
      selectAnalysisLoadingStatus(previousState) === 'success'
    if (!isPreviousAnalysisLoaded) {
      return
    }
    const previousChartById = selectChartById(previousState)
    const previousLassoById = selectAnalysisLassos(previousState)

    const modifiedCharts = {} as Record<string, { hiddenClusterIds: string[] }>

    const updateDependentCharts = (parentChart: Graph) => {
      const previousChart = previousChartById[parentChart.id]
      for (const childChart of childChartsById[parentChart.id]) {
        const previousLasso = previousLassoById[childChart.parent_lasso_id!]
        const lasso = lassoById[childChart.parent_lasso_id!]
        if (
          parentChart.active_leaf_ids !== previousChart.active_leaf_ids ||
          parentChart.hidden_cluster_ids !== previousChart.hidden_cluster_ids ||
          lasso !== previousLasso
        ) {
          modifiedCharts[childChart.id] = {
            hiddenClusterIds: childChart.active_leaf_ids.filter(leafId => {
              const currentParentHiddenClusterIds =
                modifiedCharts[parentChart.id]?.hiddenClusterIds ??
                parentChart.hidden_cluster_ids

              return (
                currentParentHiddenClusterIds.includes(leafId) ||
                !checkIfClusterIsInsideLasso(
                  clusterById[leafId],
                  lassoById[childChart.parent_lasso_id!].polygon,
                  parentChart.x_axis,
                  parentChart.y_axis!,
                )
              )
            }),
          }
        }

        updateDependentCharts(childChart)
      }
    }

    for (const rootChart of rootCharts) {
      updateDependentCharts(rootChart)
    }

    if (Object.keys(modifiedCharts).length > 0) {
      api.dispatch(postDependentChartsMiddleware({ modifiedCharts }))
    }
  },
})
