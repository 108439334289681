import { format } from 'date-fns'
import { AxisTypeValue } from 'highcharts'
import { debounce } from 'lodash'
import { useLayoutEffect, useMemo, useState } from 'react'

import { FcsFileChannel } from 'shared/api/files.api'

import { handleError } from './errorHandler'
import { getInjectedEnvironmentVariable } from './getInjectedEnvironmentVariable'

export const href = (url: string): string => {
  if (url.includes('https://') || url.includes('http://')) {
    return url
  } else {
    return getInjectedEnvironmentVariable('REACT_APP_API_BASE_URL') + url
  }
}

export const toQueryString = (
  obj: Record<string, string | number | string[] | number[] | undefined>,
): string => {
  return Object.entries(obj)
    .filter(([, value]) => value !== undefined && value !== '')
    .flatMap(([key, value]) => {
      if (Array.isArray(value)) {
        return value.map(singleValue => [key, singleValue])
      }
      return [[key, value]]
    })
    .map(
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      ([key, val]) => `${encodeURIComponent(key)}=${encodeURIComponent(val!)}`,
    )
    .join('&')
}

export const formatDate = (date?: Date | string): string | undefined => {
  return date !== undefined ? format(new Date(date), 'dd/MM/yyyy') : undefined
}

export const formatTime = (date?: Date): string | undefined => {
  return date !== undefined ? format(new Date(date), 'HH:mm:ss') : undefined
}

export const formatDateTime = (date?: Date | string): string | undefined => {
  return date !== undefined
    ? format(new Date(date), 'dd/MM/yyyy HH:mm:ss')
    : undefined
}

export const formatDateTimeFileName = (
  date?: Date | string,
): string | undefined => {
  return date !== undefined
    ? format(new Date(date), 'yyyyMMdd-HHmmss')
    : undefined
}

export const downloadText = (text: string, filename: string): void => {
  const blob = new Blob([text], { type: 'text/plain' })
  const url = URL.createObjectURL(blob)
  const a = document.createElement('a')
  a.href = url
  a.download = filename
  a.click()
  URL.revokeObjectURL(url)
}

export async function downloadFile(
  url: string,
  filenameOverride?: string,
): Promise<void> {
  const response = await fetch(url)

  if (!response.ok) {
    throw handleError(response, 'Cannot download file')
  }

  const blob = await response.blob()
  const filename =
    filenameOverride ??
    decodeURIComponent(new URL(url).pathname.split('/').pop() ?? '')
  const a = document.createElement('a')
  a.href = URL.createObjectURL(blob)
  a.download = filename
  a.click()
  window.URL.revokeObjectURL(a.href)
}

/**
 * Function computing the width of some text.
 * @param text The text whose width we want to compute.
 * @param font The font of the text.
 * @returns The width of the text.
 */
export function getTextWidth(text: string, font: string): number | null {
  const canvas = document.createElement('canvas')
  const context = canvas.getContext('2d')
  if (context) {
    context.font = font
    const metrics = context?.measureText(text)
    return metrics.width
  }
  return null
}

export function includeIf<T>(condition: unknown, value: T[]): T[]
export function includeIf<T>(condition: unknown, value: T): Partial<T>

export function includeIf<T>(
  condition: unknown,
  value: T | T[],
): Partial<T> | T[] {
  if (Array.isArray(value)) {
    return condition ? value : []
  }
  return condition ? value : {}
}

type ValidateAxisScaleTypeProps = {
  channel: string | undefined
  scaleType: AxisTypeValue
  channels: Record<string, FcsFileChannel> | undefined
}

export function validateAxisScaleType({
  channel,
  scaleType,
  channels,
}: ValidateAxisScaleTypeProps): AxisTypeValue {
  if (!channel || !channels) {
    return scaleType
  }
  return channels[channel].type !== 'morhpo' ? 'linear' : scaleType
}

export type ElementSize = {
  width: number
  height: number
}

export function useSize<E extends Element = Element>(
  options: { debounce: number } = { debounce: 0 },
): [(element: E) => void, ElementSize] {
  const [element, ref] = useState<E | null>(null)
  const [size, setSize] = useState({ width: 0, height: 0 })

  const observer = useMemo(
    () =>
      new ResizeObserver(
        debounce(elements => {
          if (elements[0]) {
            setSize({
              width: elements[0].contentRect.width,
              height: elements[0].contentRect.height,
            })
          }
        }, options.debounce),
      ),
    [options.debounce],
  )

  useLayoutEffect(() => {
    if (!element) {
      return
    }
    setSize({
      width: element.clientWidth,
      height: element.clientHeight,
    })
    observer.observe(element)
    return () => observer.disconnect()
  }, [element, observer])

  return [ref, size]
}

export function getURLSearchParam(param: string): string | null {
  const params = new URLSearchParams(window.location.search)
  return params.get(param)
}
