import { Typography } from '@material-ui/core'
import React from 'react'
import { FallbackProps } from 'react-error-boundary'
import styled from 'styled-components'

import { Icon } from 'assets/images/icons/Icon'

import { Button } from './Button'

export const ErrorFallback = ({
  resetErrorBoundary,
  description,
}: {
  resetErrorBoundary: FallbackProps['resetErrorBoundary']
  description?: string
}): JSX.Element => {
  return (
    <ErrorFallbackRoot>
      <h4>
        An error occurred. Please try again later, or contact the Metafora team
        if the issue persists.
      </h4>
      {description && <Typography variant="body1">{description}</Typography>}
      <StyledButton
        onClick={resetErrorBoundary}
        startIcon={<Icon color="white" name="arrowRotateRight" />}
      >
        Try again
      </StyledButton>
    </ErrorFallbackRoot>
  )
}

const ErrorFallbackRoot = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  width: 100%;
  height: 100%;
`

const StyledButton = styled(Button)`
  padding: 8px;
  font-size: ${props => props.theme.font.size.smallest}px;
  max-height: 32px;
  width: 100px;
`
