import { configureStore } from '@reduxjs/toolkit'
import ReactDOMClient from 'react-dom/client'

import { analysisApi } from 'shared/api/analysis.api'
import { privateApi } from 'shared/api/private.api'
import { signaturesApi } from 'shared/api/signatures.api'
import { reducer } from 'shared/store'
import { globalErrorHandlingMiddleware } from 'shared/store/global-error-handling.middleware'

import { theme as theTheme } from 'Theme'

import { AnalysisPdf } from './AnalysisPdf'

// this function is used through the window object by the node script
const generateAnalysisPdf = async (
  analysisId: string,
  selectedGraphicalElementIds: string[],
  exportHash: string,
  theme = theTheme,
): Promise<unknown> => {
  const store = configureStore({
    reducer,
    middleware: getDefaultMiddleware =>
      getDefaultMiddleware({
        serializableCheck: false,
        immutableCheck: false,
      }).concat(privateApi.middleware, globalErrorHandlingMiddleware),
  })

  try {
    await store
      .dispatch(analysisApi.endpoints.getAnalysis.initiate(analysisId))
      .unwrap()

    await store
      .dispatch(signaturesApi.endpoints.getSignatures.initiate(analysisId))
      .unwrap()

    const node = await AnalysisPdf({
      state: store.getState(),
      selectedGraphicalElementIds,
      theme,
      exportHash,
    })

    window.document.body.innerHTML = '<div id="root"></div>'

    ReactDOMClient.createRoot(
      document.getElementById('root') as HTMLElement,
    ).render(node)
  } catch (error) {
    console.error(error)
    return {
      success: false,
      error,
      __COMMIT_HASH__,
    }
  }

  return {
    success: true,
    __COMMIT_HASH__,
  }
}

const enhancedWindow = window as unknown as {
  __generateAnalysisPdf__: typeof generateAnalysisPdf
}
enhancedWindow.__generateAnalysisPdf__ = generateAnalysisPdf
