import { FC } from 'react'
import styled from 'styled-components'

import { CircularProgress } from 'components/CircularProgress'
import { Button } from 'components/button/Button'
import { Modal } from 'components/modal/Modal'
import { ModalContainer } from 'components/modal/ModalContainer'
import { Table, TableColumn, TableRow } from 'components/table/Table'

import { useGetPipelineQuery } from 'shared/api/pipelines.api'

import { PipelineReferenceModalAnalyses } from './PipelineReferenceModalInnerRow'

type PipelineReferenceModalProps = {
  pipelineId: string
  onClose: () => void
}

export const PipelineReferenceModal: FC<PipelineReferenceModalProps> = ({
  pipelineId,
  onClose,
}) => {
  const getPipelineQueryState = useGetPipelineQuery(pipelineId)

  const columns: TableColumn[] = [
    {
      key: 'reference_analysis',
      label: 'Reference analysis',
      style: { minWidth: 220 },
    },
    {
      key: 'created_at',
      label: 'Creation date',
      style: { width: '25%' },
    },
    {
      key: 'author',
      label: 'Author',
      style: { width: '20%' },
    },
    {
      key: 'status',
      label: 'Status',
    },
  ]

  const rows: TableRow[] =
    getPipelineQueryState.data?.bricks.map(brick => ({
      key: brick.id,
      cells: [
        {
          key: 'reference_analysis',
          content: brick.name,
        },
        ...['created_at', 'author', 'status'].map(key => ({
          key,
          content: '',
        })),
      ],
      children: (
        <PipelineReferenceModalAnalyses brick={brick} columns={columns} />
      ),
    })) ?? []

  return (
    <Modal
      open
      title="Analysis reference"
      Container={StyledModalContainer}
      onClose={onClose}
    >
      {getPipelineQueryState.isLoading ? (
        <CircularProgress />
      ) : (
        <TableWrapper>
          <Table columns={columns} rows={rows} showHeader={false} />
        </TableWrapper>
      )}
      <ButtonWrapper>
        <Button colorOverride="greyscale" onClick={onClose}>
          Close
        </Button>
      </ButtonWrapper>
    </Modal>
  )
}

const StyledModalContainer = styled(ModalContainer)`
  min-width: 800px;
  min-height: 300px;
`

const TableWrapper = styled.div`
  width: 100%;
  max-height: 60vh;
  overflow-y: auto;
`

const ButtonWrapper = styled.div`
  display: flex;
  justify-content: center;
  margin-top: ${props => props.theme.spacing(4)}px;
`
