import { Visibility as VisibilityIcon } from '@material-ui/icons'
import Highcharts from 'highcharts'
import { FC, useCallback, useMemo, useState } from 'react'
import { renderToStaticMarkup } from 'react-dom/server'
import styled, {
  ThemeProvider as StyledComponentsThemeProvider,
  useTheme,
} from 'styled-components'

import { DefaultErrorBoundary } from 'components/DefaultErrorBoundary'
import { Drawer } from 'components/Drawer'
import { Modal } from 'components/Modal'

import {
  ClusterActionsContextMenu,
  ClusterActionsContextMenuActions,
} from 'pages/analysis/ClusterActionsContextMenu'
import { EditDisplayedClusters } from 'pages/analysis/EditDisplayClusters'
import { GraphChangeType } from 'pages/analysis/GraphChangeType'
import {
  selectActiveLeaves,
  selectAnalysisHistogramSeriesComputationParams,
  selectHighlightedCluster,
} from 'pages/analysis/store/selectors'

import { useEventCallback } from 'shared/hooks/useEventCallback'
import { useLoadSeries } from 'shared/hooks/useLoadSeries'
import { Graph } from 'shared/models/Graphs'
import { useAppSelector } from 'shared/store'
import { changeAlpha } from 'shared/utils/colors'
import { analysisWorker } from 'shared/worker'

import { NoClusterInfo } from '../NoClusterInfo'
import { AnalysisChartChannelSelector } from './AnalysisChartChannelSelector'
import { AnalysisChartContainer } from './AnalysisChartContainer'
import { AnalysisHistogramBaseOptions } from './AnalysisHistogramBaseOptions'
import { AnalysisScatterPlotTooltip } from './AnalysisScatterPlotTooltip'
import { Chart } from './Chart'

type AnalysisHistogramProps = {
  chart: Graph
  isExpanded?: boolean
  className?: string
  onCloseExpand?: () => void
}

export const AnalysisHistogram: React.FC<AnalysisHistogramProps> = ({
  chart,
  isExpanded,
  className,
  onCloseExpand,
}) => {
  const [contextMenu, setContextMenu] = useState<{
    clusterId: string
    x: number
    y: number
  }>()
  const [shouldDisplayChartOptions, setShouldDisplayChartOptions] =
    useState(false)
  const [isClustersSelectionDrawerOpen, setIsClustersSelectionDrawerOpen] =
    useState(false)
  const [shouldShowExpandedSelf, setShouldShowExpandedSelf] = useState(false)

  const availableClustersActions =
    useMemo<ClusterActionsContextMenuActions>(() => {
      return {
        changeClusterColor: true,
        changeClusterName: true,
        expandCluster: true,
        expandVisibleClusters: true,
        mergeCluster: true,
        mergeVisibleClusters: true,
        highlightCluster: true,
        shouldChangeDotSize: true,
        toggleClusterVisibilitySelected: true,
      }
    }, [])

  const handleCloseContextMenu = useEventCallback(() => {
    setContextMenu(undefined)
  })

  const handleChangeOptions = useCallback(() => {
    setShouldDisplayChartOptions(true)
  }, [])

  return (
    <AnalysisChartContainer
      chart={chart}
      onChangeOptions={handleChangeOptions}
      menuButtons={[
        {
          tooltip: 'Show cluters selection',
          icon: <VisibilityIcon />,
          onClick: () => setIsClustersSelectionDrawerOpen(true),
        },
      ]}
      onExpand={() => setShouldShowExpandedSelf(true)}
      isExpanded={isExpanded}
      className={className}
      onCloseExpand={onCloseExpand}
    >
      <DefaultErrorBoundary>
        <InnerPlot chart={chart} onContextMenuChange={setContextMenu} />
      </DefaultErrorBoundary>
      {contextMenu && (
        <ClusterActionsContextMenu
          actions={availableClustersActions}
          menuOrigin={contextMenu}
          source="graph"
          graph={chart}
          clusterId={contextMenu.clusterId}
          onClose={handleCloseContextMenu}
        />
      )}
      {shouldDisplayChartOptions && (
        <GraphChangeType
          graph={chart}
          onClose={() => setShouldDisplayChartOptions(false)}
        />
      )}
      <Drawer
        open={isClustersSelectionDrawerOpen}
        onClose={() => setIsClustersSelectionDrawerOpen(false)}
        onBackdropClick={() => setIsClustersSelectionDrawerOpen(false)}
      >
        <EditDisplayedClusters
          onClose={() => setIsClustersSelectionDrawerOpen(false)}
          graphId={chart.id}
        />
      </Drawer>
      {shouldShowExpandedSelf && (
        <Modal open onClose={() => setShouldShowExpandedSelf(false)}>
          <ExpandedAnalysisHistogram
            chart={chart}
            onCloseExpand={() => setShouldShowExpandedSelf(false)}
          />
        </Modal>
      )}
    </AnalysisChartContainer>
  )
}

type InnerPlotProps = Pick<AnalysisHistogramProps, 'chart'> & {
  onContextMenuChange: (value: {
    clusterId: string
    x: number
    y: number
  }) => void
}

const InnerPlot: FC<InnerPlotProps> = ({ chart, onContextMenuChange }) => {
  const theme = useTheme()

  const highlightedCluster = useAppSelector(selectHighlightedCluster)
  const seriesComputationParams = useAppSelector(
    state => selectAnalysisHistogramSeriesComputationParams(state)[chart.id],
  )
  const activeLeaves = useAppSelector(selectActiveLeaves)

  const series = useLoadSeries(
    seriesComputationParams,
    useMemo(
      () =>
        analysisWorker.computeHighPerformanceHistogramSeries.bind(
          analysisWorker,
        ),
      [],
    ),
  )

  const options: Highcharts.Options = useMemo(() => {
    const formatTooltip: Highcharts.TooltipFormatterCallbackFunction =
      function () {
        return renderToStaticMarkup(
          <StyledComponentsThemeProvider theme={theme}>
            <AnalysisScatterPlotTooltip
              name={this.series.options.custom?.displayName || this.series.name}
              color={this.point.color as string}
              parentRate={this.series.options.custom?.parentRate}
              numberOfEvents={this.series.options.custom?.count}
            />
          </StyledComponentsThemeProvider>,
        )
      }

    return {
      ...AnalysisHistogramBaseOptions,
      tooltip: {
        formatter: formatTooltip,
      },
      series: series?.map(series => {
        return {
          ...series,
          index:
            highlightedCluster?.clusterId &&
            series.id === highlightedCluster.clusterId &&
            !highlightedCluster.highlightOnlyOnSunburst
              ? 9999
              : series.index,
          color:
            highlightedCluster?.clusterId &&
            series.id &&
            series.id !== highlightedCluster.clusterId &&
            !highlightedCluster.highlightOnlyOnSunburst &&
            series.color
              ? changeAlpha(series.color as string, 0.6)
              : series.color,
        }
      }),
    }
  }, [
    highlightedCluster?.clusterId,
    highlightedCluster?.highlightOnlyOnSunburst,
    series,
    theme,
  ])

  const handleContextMenu = useEventCallback(
    (
      event: React.MouseEvent<HTMLDivElement, MouseEvent>,
      point: Highcharts.Point | undefined,
    ) => {
      const clusterId = activeLeaves.find(
        leaf => leaf.label === point?.series.name,
      )?.id
      if (!clusterId) return

      onContextMenuChange({
        clusterId,
        x: event.clientX,
        y: event.clientY,
      })
    },
  )

  return (
    <Grid>
      {series?.length === 0 ? (
        <NoClusterInfo />
      ) : (
        <StyledChart
          immutable
          options={options}
          data-cy="histogram"
          onContextMenu={handleContextMenu}
        />
      )}
      <AnalysisChartChannelSelector chart={chart} axis="x_axis" />
    </Grid>
  )
}

const Grid = styled.div`
  width: 100%;
  height: 100%;
  display: grid;
  grid-template-rows: 1fr auto;
  grid-template-columns: 1fr 30px;
  grid-template-areas:
    'chart .'
    'x-axis x-axis';
`

const StyledChart = styled(Chart)`
  grid-area: chart;
`

const ExpandedAnalysisHistogram = styled(AnalysisHistogram).attrs({
  isExpanded: true,
})`
  width: 90vw;
  height: 90vh;
`
