import { User } from 'shared/models/User'

import { privateApi } from './private.api'

export const userApi = privateApi.injectEndpoints({
  endpoints: builder => ({
    getUser: builder.query<User | undefined, void>({
      query: () => ({
        url: '/users/profile/',
      }),
    }),
    updateUserProfile: builder.mutation<User | undefined, UpdateProfilePayload>(
      {
        query: payload => ({
          url: '/users/profile/',
          method: 'PUT',
          body: {
            first_name: payload.firstName,
            last_name: payload.lastName,
            job: payload.job,
          },
        }),
      },
    ),
    updatePassword: builder.mutation<undefined, UpdatePasswordPayload>({
      query: payload => ({
        url: '/users/change-password/',
        method: 'PUT',
        body: {
          old_password: payload.oldPassword,
          new_password: payload.newPassword,
        },
      }),
    }),
  }),
})

export const {
  useGetUserQuery,
  useUpdateUserProfileMutation,
  useUpdatePasswordMutation,
} = userApi

type UpdateProfilePayload = {
  firstName?: string
  lastName?: string
  job?: string
}

type UpdatePasswordPayload = {
  oldPassword: string
  newPassword: string
}
