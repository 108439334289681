export const createMetaAnalysisDimensionalityReductionChartBaseOptions = (
  dimensionalityReductionMethod: string,
  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
) => {
  const dimensionalityReductionMethodLabel =
    dimensionalityReductionMethod === 'umap' ? 'UMAP' : 't-SNE'

  return {
    chart: {
      type: 'scatter',
      zooming: {
        type: 'xy',
      },
    } as Highcharts.ChartOptions,
    xAxis: {
      title: {
        text: `${dimensionalityReductionMethodLabel} X`,
      },
    },
    yAxis: {
      title: {
        text: `${dimensionalityReductionMethodLabel} Y`,
      },
    },
    title: {
      text: undefined,
    },
  }
}
