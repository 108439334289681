import { Formik } from 'formik'
import styled from 'styled-components'
import { array, object } from 'yup'

import { ValidationLabel } from 'components/ validation-label/ValidationLabel'
import { Checkbox } from 'components/Checkbox'
import { Button } from 'components/button/Button'
import { Modal } from 'components/modal/Modal'
import { ModalContainer } from 'components/modal/ModalContainer'

import {
  ExportTypes,
  useGenerateBatchCustomStatisticsExportMutation,
  useGenerateBatchStatisticsExportMutation,
} from 'shared/api/export.api'
import { BrickDetails } from 'shared/api/workflows.api'

import { BrickAnalysisSelector } from './BrickAnalysisSelector'
import { WizardSectionCard } from './WizardSectionCard'

type WorkflowExportModalProps = {
  type: 'custom' | 'global'
  brickById: Record<string, BrickDetails>
  onClose: () => void
}

type WorkflowExportFormValues = {
  selectedAnalysisIds: string[]
  exportType: `${ExportTypes}`
  concatenateStats: boolean
}

const WorkflowExportFormSchema = object({
  selectedAnalysisIds: array().min(1, 'Select at least one analysis'),
})

export const WorkflowExportModal: React.FC<WorkflowExportModalProps> = ({
  type,
  brickById,
  onClose,
}) => {
  const [triggerGenerateBatchCustomStatisticsExportMutation] =
    useGenerateBatchCustomStatisticsExportMutation()
  const [triggerGenerateBatchGlobalExportMutation] =
    useGenerateBatchStatisticsExportMutation()

  const handleCreateExport = async (values: WorkflowExportFormValues) => {
    if (type === 'custom') {
      await triggerGenerateBatchCustomStatisticsExportMutation({
        analyses: values.selectedAnalysisIds,
        type: values.exportType,
        concatenate: values.concatenateStats,
      })
      onClose()
    } else if (type === 'global') {
      await triggerGenerateBatchGlobalExportMutation({
        analyses: values.selectedAnalysisIds,
        type: values.exportType,
      })
      onClose()
    }
  }

  return (
    <Modal
      open
      title={`Export batch ${type} statistics`}
      onClose={onClose}
      Container={StyledModalContainer}
    >
      <Formik<WorkflowExportFormValues>
        initialValues={{
          selectedAnalysisIds: [],
          exportType: ExportTypes.Csv,
          concatenateStats: false,
        }}
        validationSchema={WorkflowExportFormSchema}
        onSubmit={handleCreateExport}
      >
        {({
          values,
          errors,
          setFieldValue,
          isValid,
          isSubmitting,
          validateForm,
          handleSubmit,
        }) => (
          <>
            <StyledWizardSectionCard title="Select analyses">
              {errors.selectedAnalysisIds && (
                <ValidationLabel>{errors.selectedAnalysisIds}</ValidationLabel>
              )}
              <BrickAnalysisSelector
                brickById={brickById}
                selectedAnalysisIds={values.selectedAnalysisIds}
                onChange={ids => setFieldValue('selectedAnalysisIds', ids)}
                validationAction={
                  type === 'global'
                    ? 'select-analysis-for-batch-global-statstics-export'
                    : 'select-analysis-for-batch-custom-statstics-export'
                }
              />
            </StyledWizardSectionCard>

            {type === 'custom' && (
              <StyledCheckboxContainer>
                <Checkbox
                  disabled={values.selectedAnalysisIds.length < 2}
                  checked={values.concatenateStats}
                  onChange={() =>
                    setFieldValue('concatenateStats', !values.concatenateStats)
                  }
                  label="Concatenate statistics into a single file"
                />
              </StyledCheckboxContainer>
            )}

            <Buttons>
              <Button
                onClick={() => {
                  setFieldValue('exportType', ExportTypes.Csv)
                  validateForm().then(errors => {
                    if (!errors.selectedAnalysisIds) {
                      handleSubmit()
                    }
                  })
                }}
                disabled={!isValid}
                loading={isSubmitting}
              >
                Export as CSV
              </Button>
              <Button
                onClick={() => {
                  setFieldValue('exportType', ExportTypes.Xlsx)
                  validateForm().then(errors => {
                    if (!errors.selectedAnalysisIds) {
                      handleSubmit()
                    }
                  })
                }}
                disabled={!isValid}
                loading={isSubmitting}
              >
                Export as CSV for Excel
              </Button>
            </Buttons>
          </>
        )}
      </Formik>
    </Modal>
  )
}

const StyledModalContainer = styled(ModalContainer)`
  min-width: 600px;
  max-height: 90vh;
`

const StyledWizardSectionCard = styled(WizardSectionCard).attrs({
  contentStyle: { overflow: 'auto' },
})`
  display: flex;
  flex-direction: column;
  overflow: hidden;
`

const StyledCheckboxContainer = styled.div`
  align-self: start;
  margin-inline: 1rem;
  margin-block-end: 2rem;
`

const Buttons = styled.div`
  display: flex;
  gap: ${props => props.theme.spacing(1)}px;
  margin-left: auto;
`
