import { Close } from '@material-ui/icons'
import { FC } from 'react'
import styled from 'styled-components'

import { Button } from 'components/button/Button'
import { Modal } from 'components/modal/Modal'
import { ModalContainer } from 'components/modal/ModalContainer'
import { Table, TableColumn, TableRow } from 'components/table/Table'

import { useAppSelector } from 'shared/store'

import {
  selectMetaAnalysisFcsFileNameById,
  selectMetaAnalysisGroupNames,
  selectMetaAnalysisGroups,
} from './store/selectors'

type GroupsPreviewModalProps = {
  onClose: () => void
}

export const GroupsPreviewModal: FC<GroupsPreviewModalProps> = ({
  onClose,
}) => {
  const groups = useAppSelector(selectMetaAnalysisGroups)
  const groupNames = useAppSelector(selectMetaAnalysisGroupNames)
  const fileNameById = useAppSelector(selectMetaAnalysisFcsFileNameById)

  const columns: TableColumn[] = [
    {
      key: 'fileName',
      label: 'File name',
      style: { minWidth: 200 },
    },
    ...groupNames.map(groupName => ({ key: groupName, label: groupName })),
  ]

  const rows: TableRow[] = Object.entries(groups).map(([fileId, group]) => ({
    key: fileId,
    cells: [
      { key: 'fileName', content: fileNameById[fileId] },
      ...groupNames.map(groupName => ({
        key: groupName,
        content: groupName === group ? '✓' : '',
      })),
    ],
  }))

  return (
    <Modal
      open
      title="Review groups"
      onClose={onClose}
      Container={StyledModalContainer}
    >
      <ScrollWrapper>
        <StyledTable columns={columns} rows={rows} stickyHeaderEnabled={true} />
      </ScrollWrapper>
      <Button
        size="small"
        colorOverride="greyscale"
        onClick={onClose}
        endIcon={<Close />}
      >
        Close
      </Button>
    </Modal>
  )
}

const StyledModalContainer = styled(ModalContainer)`
  max-height: 80vh;
  max-width: 60vw;
  gap: ${props => props.theme.spacing(2)}px;
`

const ScrollWrapper = styled.div`
  width: 100%;
  max-height: 100%;
  overflow-y: auto;
  overflow-x: hidden;
`

const StyledTable = styled(Table)`
  border-collapse: collapse;

  & .MuiTableRow-root {
    box-shadow: none;

    & .MuiTableCell-stickyHeader {
      background-color: ${props => props.theme.colors.white};
    }

    & .MuiTableCell-root {
      border-bottom: 1px solid ${props => props.theme.colors.primaryDark[20]};
      border-radius: 0;

      &:not(:first-of-type) {
        text-align: center;
      }
    }
  }
`
