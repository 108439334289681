import { LinearProgress } from '@material-ui/core'
import React from 'react'
import styled from 'styled-components'

type ResourceStateCardProps =
  | {
      current: number
      start: number
    }
  | { label: string }

export const ResourceStateCard = (
  props: ResourceStateCardProps,
): JSX.Element => {
  if ('label' in props) {
    return <ResourceStateCardRoot>{props.label}</ResourceStateCardRoot>
  }
  return (
    <ResourceStateCardRoot>
      <div>
        {props.current} / {props.start}
      </div>
      <StyledLinearProgress
        variant="determinate"
        value={(props.current / props.start) * 100}
        $isExceeded={props.current > props.start}
      />
    </ResourceStateCardRoot>
  )
}

const ResourceStateCardRoot = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 64px;
  padding: 12px;
  font-size: 16px;
  background-color: white;
  border: 1px solid ${props => props.theme.palette.grey[300]};
  border-radius: 4px;
`

const StyledLinearProgress = styled(LinearProgress)<{ $isExceeded: boolean }>`
  background-color: ${props => props.$isExceeded && props.theme.colors.error};
`
