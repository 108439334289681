import { FC } from 'react'
import styled from 'styled-components'

import { Icon } from 'assets/images/icons/Icon'

import { theme } from 'Theme'

interface TableSortButtonProps {
  isActive: boolean
  direction?: 'asc' | 'desc'
  onClick: () => void
}

export const TableSortButton: FC<TableSortButtonProps> = ({
  direction,
  isActive,
  onClick,
}) => {
  const color = theme.colors.primaryDark[isActive ? 50 : 20]

  return (
    <>
      {!isActive ? (
        <StyledIcon onClick={onClick} name="caretUpAndDown" color={color} />
      ) : direction === 'desc' ? (
        <StyledIcon onClick={onClick} name="caretDown" color={color} />
      ) : (
        <StyledIcon onClick={onClick} name="caretUp" color={color} />
      )}
    </>
  )
}

const StyledIcon = styled(Icon)`
  height: 16px;
  width: 16px;
  margin-left: 4px;
  cursor: pointer;
  flex-shrink: 0;
`
