export const MetaAnalysisSpiderwebPlotBaseOptions = {
  chart: {
    type: 'line',
    polar: true,
    animation: false,
  },
  xAxis: {
    type: 'category',
    labels: {
      style: {
        display: 'flex',
        justifyContent: 'center',
      },
    },
    tickmarkPlacement: 'on',
    lineWidth: 0,
  } as Highcharts.XAxisOptions,
  yAxis: {
    gridLineInterpolation: 'polygon',
    lineWidth: 0,
  } as Highcharts.YAxisOptions,
  legend: {
    enabled: true,
    align: 'center',
    verticalAlign: 'bottom',
    layout: 'horizontal',
  } as Highcharts.LegendOptions,
  plotOptions: {
    series: {
      animation: false,
      dataLabels: {
        enabled: false,
      },
    },
  },
  tooltip: {
    enabled: true,
    useHTML: true,
  },
  title: {
    text: '',
  },
}
