import { FormControlLabel } from '@material-ui/core'
import { capitalize } from 'lodash'
import React from 'react'
import styled from 'styled-components'

import { Checkbox } from 'components/Checkbox'

import { TIME_CHANNEL } from 'shared/constants'
import { getChannelDisplayName } from 'shared/utils/channels.utils'

import { ChannelNameInput } from './ChannelNameInput'

type ChannelsLetterListProps = {
  letter: string
  channels: ChannelsLetterListChannel[]
  selectedChannels: string[]
  selectable?: boolean
  onChangeSelectedChannels?: (selectedChannels: string[]) => void
  writable?: boolean
  onChangeChannelLabel?: (channel: string, newName: string) => void
  disabled?: boolean
  columns: number
}

export type ChannelsLetterListChannel = {
  id: string
  label: string | undefined
  defaultLabel?: string
}

export const ChannelsLetterList = React.memo(
  ({
    letter,
    channels,
    selectedChannels,
    selectable,
    writable,
    onChangeSelectedChannels,
    onChangeChannelLabel,
    disabled,
    columns,
  }: ChannelsLetterListProps): JSX.Element => {
    const letterSelectedChannels = channels.filter(channel =>
      selectedChannels.includes(channel.id),
    )

    const handleClickChannel = (channelName: string) => {
      if (selectedChannels.includes(channelName)) {
        onChangeSelectedChannels?.(
          selectedChannels.filter(name => name !== channelName),
        )
      } else {
        onChangeSelectedChannels?.([...selectedChannels, channelName])
      }
    }

    const handleSelectAll = () => {
      if (letterSelectedChannels.length === 0) {
        onChangeSelectedChannels?.([
          ...selectedChannels,
          ...channels.map(channel => channel.id),
        ])
      } else {
        onChangeSelectedChannels?.(
          selectedChannels.filter(
            name => !channels.map(channel => channel.id).includes(name),
          ),
        )
      }
    }

    const getSortedChannels = (
      channels: ChannelsLetterListChannel[],
    ): ChannelsLetterListChannel[] => {
      const sortChannels = (
        a: ChannelsLetterListChannel,
        b: ChannelsLetterListChannel,
      ) => {
        if (a.label && !b.label) {
          return -1
        }

        const aAttribute = a.label || a.defaultLabel || a.id
        const bAttribute = b.label || b.defaultLabel || b.id

        const aMatch = aAttribute.match(/([A-z]*)([0-9]*)/)
        const bMatch = bAttribute.match(/([A-z]*)([0-9]*)/)

        const aLetterGroup = aMatch?.[1]
        const bLetterGroup = bMatch?.[1]
        const aNumberGroup = aMatch?.[2]
        const bNumberGroup = bMatch?.[2]

        if (aLetterGroup === bLetterGroup && aNumberGroup && bNumberGroup) {
          return parseInt(aNumberGroup) - parseInt(bNumberGroup)
        }
        return aAttribute.localeCompare(bAttribute)
      }

      const fsc = channels.filter(c => c.id.toLowerCase().startsWith('fs'))
      const ssc = channels.filter(c => c.id.toLowerCase().startsWith('ss'))
      const viability = channels.filter(c => {
        const processedName = c.id.toLowerCase().replaceAll(' ', '')
        return (
          processedName.startsWith('viability') ||
          processedName.startsWith('livedead')
        )
      })

      const otherChannels = channels.filter(
        channel =>
          ![...fsc, ...ssc, ...viability].some(c => c.id === channel.id),
      )

      return [
        ...fsc.sort(sortChannels),
        ...ssc.sort(sortChannels),
        ...viability.sort(sortChannels),
        ...otherChannels.sort(sortChannels),
      ]
    }

    return (
      <ChannelsLetterListRoot $columns={columns}>
        <Row>
          <Checkbox
            nonSelectable={!selectable}
            checked={letterSelectedChannels.length === channels.length}
            label={<Label>{capitalize(letter)} channels</Label>}
            onClick={handleSelectAll}
            disabled={disabled}
            data-cy="channel-checkbox"
          />
        </Row>
        {getSortedChannels(channels).map(channel => {
          return (
            <Row key={channel.id}>
              <StyledFormControlLabel
                label={
                  writable && !disabled && channel.id !== TIME_CHANNEL ? (
                    <ChannelNameInput
                      channel={channel.id}
                      placeholder={channel.defaultLabel}
                      value={channel.label}
                      onChange={value => {
                        onChangeChannelLabel?.(channel.id, value.trim())
                      }}
                    />
                  ) : (
                    <span>{getChannelDisplayName(channel)}</span>
                  )
                }
                control={
                  <Checkbox
                    nonSelectable={!selectable}
                    checked={selectedChannels.includes(channel.id)}
                    disabled={disabled}
                    onClick={() => handleClickChannel(channel.id)}
                  />
                }
              />
            </Row>
          )
        })}
      </ChannelsLetterListRoot>
    )
  },
)

const ChannelsLetterListRoot = styled.div<{ $columns: number }>`
  display: grid;
  grid-template-columns: repeat(${props => props.$columns}, 1fr);
  grid-row-gap: 8px;
  width: 100%;
  padding-bottom: 12px;
  border-bottom: 1px solid ${props => props.theme.colors.greyscale[10]};

  &:last-child {
    border-bottom: none;
  }

  & > *:first-child {
    grid-column: span ${props => props.$columns};
    position: sticky;
    top: 0;
    z-index: 1;
    padding: 5px 16px;
    background: white;
    border-bottom: 1px solid ${props => props.theme.colors.greyscale[10]};
  }
`

const Row = styled.div`
  display: flex;
  align-items: center;
  padding: 0 20px;
`

const Label = styled.span`
  font-weight: bold;
`

const StyledFormControlLabel = styled(FormControlLabel)`
  width: 100%;
  display: flex;

  .MuiFormControlLabel-label {
    flex-grow: 1;
  }
`
