import {
  Delete as DeleteIcon,
  FileCopy as DuplicateIcon,
  Edit as EditIcon,
  List as ListIcon,
  ZoomOut as ZoomOutIcon,
} from '@material-ui/icons'
import { ReactNode, forwardRef, useImperativeHandle, useState } from 'react'
import styled, { css } from 'styled-components'
import { ValidationError, string } from 'yup'

import { LayoutItemCard } from 'components/graphs/LayoutItemCard'

import {
  duplicateChart,
  removeAnalysisChart,
  renameAnalysisChart,
  resetChartZoom,
} from 'pages/analysis/store/analysis.history.slice'
import {
  selectAnalysisSelectedGraphicalElementIds,
  setClusterListConnectedChartId,
  toggleAnalysisGraphicalElementSelection,
} from 'pages/analysis/store/selected-graphical-elements.slice'
import {
  selectAnalysisAccessMode,
  selectChartNamesSet,
  selectClusterListLinkedChartId,
  selectDescendentChartsById,
} from 'pages/analysis/store/selectors'

import { useDialog } from 'shared/contexts/DialogContext'
import { useEventCallback } from 'shared/hooks/useEventCallback'
import { Graph } from 'shared/models/Graphs'
import { useAppDispatch, useAppSelector } from 'shared/store'
import { includeIf } from 'shared/utils/utils'

import {
  LayoutItemCardHeader,
  LayoutItemCardHeaderAction,
} from './LayoutItemCardHeader'

type AnalysisChartContainerProps = {
  children: ReactNode
  chart: Graph
  headerButtons?: LayoutItemCardHeaderAction[]
  menuButtons?: LayoutItemCardHeaderAction[]
  isExpanded?: boolean
  className?: string
  onChangeOptions?: () => void
  onExpand?: () => void
  onCloseExpand?: () => void
}

export type AnalysisChartContainerInstance = {
  anchorElement: HTMLElement | null
}

export const AnalysisChartContainer = forwardRef<
  AnalysisChartContainerInstance,
  AnalysisChartContainerProps
>(
  (
    {
      children,
      chart,
      headerButtons,
      menuButtons,
      isExpanded,
      className,
      onChangeOptions,
      onExpand,
      onCloseExpand,
    },
    ref,
  ) => {
    const { showConfirmationDialog } = useDialog()
    const dispatch = useAppDispatch()
    const selectedGraphicalElementIds = useAppSelector(
      selectAnalysisSelectedGraphicalElementIds,
    )
    const descendentChartsById = useAppSelector(selectDescendentChartsById)
    const chartNames = useAppSelector(selectChartNamesSet)
    const clusterListLinkedChartId = useAppSelector(
      selectClusterListLinkedChartId,
    )
    const analysisAccessMode = useAppSelector(selectAnalysisAccessMode)

    const [settingsPopperAnchorRef, setSettingsPopperAnchorRef] =
      useState<HTMLElement | null>(null)

    const isLinkedToClusterList = clusterListLinkedChartId === chart.id

    const handleChangeOptions = useEventCallback(() => {
      onChangeOptions?.()
    })

    const handleRename = useEventCallback((value: string) => {
      dispatch(
        renameAnalysisChart({
          id: chart.id,
          name: value,
        }),
      )
    })

    const handleDelete = useEventCallback(() => {
      const descendentCharts = descendentChartsById[chart.id]
      if (descendentCharts.length > 0) {
        showConfirmationDialog({
          title: 'Are you sure you want to remove this chart?',
          message: `This chart is a parent of ${descendentCharts.length} chart${
            descendentCharts.length > 1 ? 's' : ''
          }: ${descendentCharts
            .map(chart => chart.name)
            .join(', ')}. If you proceed, all child charts will be removed.`,
          onConfirm: () => dispatch(removeAnalysisChart(chart.id)),
        })
      } else {
        dispatch(removeAnalysisChart(chart.id))
      }
    })

    const handleDuplicate = useEventCallback(() => {
      dispatch(
        duplicateChart({
          chartId: chart.id,
        }),
      )
    })

    const handleChartSelection = useEventCallback(() => {
      dispatch(toggleAnalysisGraphicalElementSelection(chart.id))
    })

    const handleLinkToClusterList = useEventCallback(() => {
      dispatch(
        setClusterListConnectedChartId(
          isLinkedToClusterList ? undefined : chart.id,
        ),
      )
    })

    const validateChartName = (name: string) => {
      try {
        string()
          .required('Graph name cannot be empty')
          .notOneOf(
            [...chartNames].filter(chartName => chartName !== chart.name),
            'This name is already used by another graph.',
          )
          .validateSync(name.trim())
        return ''
      } catch (error) {
        const validationError = error as ValidationError
        return validationError.errors[0]
      }
    }

    useImperativeHandle(
      ref,
      (): AnalysisChartContainerInstance => {
        return {
          anchorElement: settingsPopperAnchorRef,
        }
      },
      [settingsPopperAnchorRef],
    )

    return (
      <AnalysisChartContainerRoot
        $isSelected={isLinkedToClusterList}
        $isExpanded={isExpanded}
        className={className}
      >
        <StyledLayoutItemCardHeader
          title={chart.name}
          actions={[
            ...includeIf(!!chart.zoom, [
              {
                tooltip: 'Reset zoom',
                icon: <ZoomOutIcon />,
                onClick: () => dispatch(resetChartZoom({ chartId: chart.id })),
              },
            ]),
            ...includeIf(!isExpanded, [
              {
                tooltip: isLinkedToClusterList
                  ? 'Unlink from cluster list'
                  : 'Link to cluster list',
                icon: <ListIcon />,
                onClick: handleLinkToClusterList,
                isActive: isLinkedToClusterList,
              },
            ]),
            ...(headerButtons ?? []),
          ]}
          menuActions={
            isExpanded
              ? undefined
              : [
                  ...includeIf(
                    onChangeOptions && analysisAccessMode === 'read-and-write',
                    [
                      {
                        tooltip: 'Change options',
                        icon: <EditIcon />,
                        onClick: handleChangeOptions,
                      },
                    ],
                  ),
                  ...includeIf(analysisAccessMode === 'read-and-write', [
                    {
                      tooltip: 'Duplicate',
                      icon: <DuplicateIcon />,
                      onClick: handleDuplicate,
                    },
                    {
                      tooltip: 'Delete',
                      icon: <DeleteIcon />,
                      onClick: handleDelete,
                    },
                  ]),
                  ...(menuButtons ?? []),
                ]
          }
          isSelected={selectedGraphicalElementIds.includes(chart.id)}
          menuRef={setSettingsPopperAnchorRef}
          isExpanded={isExpanded}
          onSelect={handleChartSelection}
          onTitleChange={
            analysisAccessMode === 'read-and-write' ? handleRename : undefined
          }
          onTitleValidate={validateChartName}
          onExpand={onExpand}
          onCloseExpand={onCloseExpand}
        />

        <ChartContainer>{children}</ChartContainer>
      </AnalysisChartContainerRoot>
    )
  },
)

const AnalysisChartContainerRoot = styled(LayoutItemCard)`
  position: relative;
  grid-template-rows: auto 1fr;
`

const ChartContainer = styled.div`
  width: 100%;
  height: 100%;
  overflow: hidden;
  display: grid;
`

const StyledLayoutItemCardHeader = styled(LayoutItemCardHeader)`
  ${props =>
    props.isExpanded &&
    css`
      padding: 8px 5px 24px 0;
    `}
`
