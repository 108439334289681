import styled from 'styled-components'

import { CUMULATIVE_FLUORESCENCE_CHART_SERIES_COLORS } from 'Theme'

type AnalysisMetaCleanChartLegendLabelProps = {
  seriesName: string
  metaCleanRatio: number | undefined
}
export const AnalysisMetaCleanChartLegendLabel: React.FC<
  AnalysisMetaCleanChartLegendLabelProps
> = ({
  seriesName,
  metaCleanRatio,
}: AnalysisMetaCleanChartLegendLabelProps) => {
  return (
    <AnalysisMetaCleanChartLegendLabelRoot>
      <Marker $color={CUMULATIVE_FLUORESCENCE_CHART_SERIES_COLORS[seriesName]}>
        {seriesName === 'keep' ? '⬤' : '▲'}
      </Marker>
      {seriesName} {metaCleanRatio}%
    </AnalysisMetaCleanChartLegendLabelRoot>
  )
}

const AnalysisMetaCleanChartLegendLabelRoot = styled.div`
  display: flex;
  align-items: center;
`

const Marker = styled.span<{ $color: string }>`
  color: ${props => props.$color};
  margin-right: 8px;
`
