import { Formik, FormikConfig } from 'formik'
import { FC } from 'react'
import { useHistory } from 'react-router'
import styled from 'styled-components'
import { object, string } from 'yup'

import { Button } from 'components/Button'
import { Modal } from 'components/Modal'
import { Section } from 'components/Section'
import Input from 'components/forms/Input'

import { useVerifyOrganizationMutation } from 'shared/api/organization.api'
import { useEventCallback } from 'shared/hooks/useEventCallback'
import { useAppDispatch } from 'shared/store'
import { logout } from 'shared/store/auth.slice'

type OrganizationVerificationFormValues = {
  code: string
}

const OrganizationVerificationFormSchema = object({
  code: string().required(),
})

export const OrganizationVerificationModal: FC = () => {
  const history = useHistory()

  const [triggerVerifyOrganizationMutation] = useVerifyOrganizationMutation()
  const dispatch = useAppDispatch()

  const handleSubmit: FormikConfig<OrganizationVerificationFormValues>['onSubmit'] =
    useEventCallback(async values => {
      triggerVerifyOrganizationMutation({
        verification_code: values.code,
      })
    })

  const handleLogout = () => {
    history.push('/log-in')
    dispatch(logout())
  }

  return (
    <StyledModal open>
      <Formik<OrganizationVerificationFormValues>
        initialValues={{
          code: '',
        }}
        validationSchema={OrganizationVerificationFormSchema}
        onSubmit={handleSubmit}
      >
        {({
          values,
          touched,
          errors,
          isSubmitting,
          isValid,
          handleChange,
          handleBlur,
          handleSubmit,
        }) => (
          <Form onSubmit={handleSubmit}>
            <Section title="Your organization requires verification">
              <Text>
                Please enter the code that you received in your mailbox after
                signing up to authorize your organization.
              </Text>
              <Input
                value={values.code || ''}
                name="code"
                label="Verification code"
                error={touched.code ? errors.code : undefined}
                onChange={handleChange}
                onBlur={handleBlur}
              />
            </Section>
            <ButtonWrapper>
              <StyledBtn grey onClick={handleLogout}>
                Log out
              </StyledBtn>
              <StyledBtn
                type="submit"
                disabled={!isValid}
                loading={isSubmitting}
                disableWhileLoading
              >
                Send
              </StyledBtn>
            </ButtonWrapper>
          </Form>
        )}
      </Formik>
    </StyledModal>
  )
}

const StyledModal = styled(Modal)`
  background-color: ${props => props.theme.colors.white};
  opacity: 0.85;
`

const Form = styled.form`
  background: ${props => props.theme.colors.white};
  padding: 20px;
  border-radius: ${props => props.theme.radius[2]}px;
  box-shadow: ${props => props.theme.shadow.tooltip};
  max-width: 95%;
  margin: 0 auto;
  width: 450px;
`

const ButtonWrapper = styled.div`
  display: flex;
  justify-content: space-around;
`

const Text = styled.p`
  padding-bottom: 20px;
`

const StyledBtn = styled(Button)`
  margin-top: 32px;
  width: 45%;
`
