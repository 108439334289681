import { useState } from 'react'

interface TableSorting {
  sortingColumn: string
  sortingDirection: 'asc' | 'desc'
  orderingString: string
  setSorting: (column: string) => void
}

export const useTableSorting = (
  defaultSortingColumn: string,
  defaultSortingDirection: 'asc' | 'desc',
): TableSorting => {
  const [sortingColumn, setSortingColumn] =
    useState<string>(defaultSortingColumn)
  const [sortingDirection, setSortingDirection] = useState(
    defaultSortingDirection,
  )

  const setSorting = (column: string) => {
    if (sortingColumn !== column) {
      setSortingColumn(column)
      setSortingDirection('desc')
    } else {
      setSortingDirection(sortingDirection === 'desc' ? 'asc' : 'desc')
    }
  }

  return {
    sortingColumn,
    sortingDirection,
    orderingString: `${sortingDirection === 'desc' ? '-' : ''}${sortingColumn}`,
    setSorting,
  }
}
