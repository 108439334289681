import { ClickAwayListener, Popper, Tooltip } from '@material-ui/core'
import {
  Fullscreen as FullscreenIcon,
  FullscreenExit as FullscreenExitIcon,
} from '@material-ui/icons'
import { ReactNode, useState } from 'react'
import styled, { css } from 'styled-components'

import { Icon } from 'assets/images/icons/Icon'

import { Checkbox } from 'components/Checkbox'
import { EditableLabel } from 'components/EditableLabel'

import { useEventCallback } from 'shared/hooks/useEventCallback'

import { LayoutItemCardHeaderIconButton } from './LayoutItemCardHeaderIconButton'

type LayoutItemCardHeaderProps = {
  title: string
  children?: ReactNode
  className?: string
  disableHandle?: boolean
  isSelected?: boolean
  actions?: LayoutItemCardHeaderAction[]
  menuActions?: LayoutItemCardHeaderAction[]
  menuRef?: React.Ref<HTMLElement | null>
  isExpanded?: boolean
  onSelect?: () => void
  onTitleChange?: (value: string) => void
  onTitleValidate?: (value: string) => string
  onExpand?: () => void
  onCloseExpand?: () => void
}

export type LayoutItemCardHeaderAction = {
  onClick: () => void
  icon: ReactNode
  isActive?: boolean
  tooltip: string
}

export const LayoutItemCardHeader: React.FC<LayoutItemCardHeaderProps> = ({
  title,
  actions,
  menuActions,
  children,
  className,
  disableHandle,
  isSelected,
  menuRef,
  isExpanded,
  onSelect,
  onTitleChange,
  onTitleValidate,
  onExpand,
  onCloseExpand,
}) => {
  const [shouldShowMenu, setShouldShowMenu] = useState(false)

  const [popperAnchor, setPopperAnchor] = useState<HTMLElement | null>(null)

  const handleSetPopperAnchor = useEventCallback((node: HTMLElement | null) => {
    setPopperAnchor(node)
    if (menuRef) {
      if (typeof menuRef === 'function') {
        menuRef(node)
      } else {
        ;(menuRef as React.MutableRefObject<HTMLElement | null>).current = node
      }
    }
  })

  const handleShowMenu = useEventCallback(() => setShouldShowMenu(true))

  const handleCloseMenu = useEventCallback(() => setShouldShowMenu(false))

  return (
    <LayoutItemCardHeaderRoot className={className}>
      {onSelect && !isExpanded && (
        <Checkbox checked={isSelected} onClick={onSelect} />
      )}
      {onTitleChange ? (
        <StyledEditableLabel
          value={title}
          onChange={onTitleChange}
          onValidate={onTitleValidate}
        />
      ) : (
        <Title $isCentered={!onSelect || isExpanded}>{title}</Title>
      )}
      {children}
      <Actions>
        {(actions || menuActions) && (
          <>
            {actions?.map(({ onClick, icon, tooltip, isActive }) => (
              <Tooltip key={tooltip} title={tooltip}>
                <LayoutItemCardHeaderIconButton
                  onClick={onClick}
                  $isActive={isActive}
                >
                  {icon}
                </LayoutItemCardHeaderIconButton>
              </Tooltip>
            ))}
            {menuActions && (
              <Tooltip title="More">
                <LayoutItemCardHeaderIconButton
                  ref={handleSetPopperAnchor}
                  onClick={handleShowMenu}
                >
                  <Icon name="more" />
                </LayoutItemCardHeaderIconButton>
              </Tooltip>
            )}
          </>
        )}
        {!isExpanded && onExpand && (
          <Tooltip title="Expand">
            <LayoutItemCardHeaderIconButton onClick={onExpand}>
              <FullscreenIcon />
            </LayoutItemCardHeaderIconButton>
          </Tooltip>
        )}
        {isExpanded && onCloseExpand && (
          <Tooltip title="Close">
            <LayoutItemCardHeaderIconButton onClick={onCloseExpand}>
              <FullscreenExitIcon />
            </LayoutItemCardHeaderIconButton>
          </Tooltip>
        )}
      </Actions>
      {!disableHandle && !isExpanded && (
        <DragHandle className="chart-grid-drag-handle">
          <Icon name="dragHandle" />
        </DragHandle>
      )}
      <StyledPopper open={shouldShowMenu} anchorEl={popperAnchor}>
        <ClickAwayListener onClickAway={handleCloseMenu}>
          <Menu>
            {menuActions?.map(({ onClick, icon, tooltip }) => (
              <Tooltip key={tooltip} title={tooltip}>
                <LayoutItemCardHeaderIconButton
                  onClick={() => {
                    handleCloseMenu()
                    onClick()
                  }}
                >
                  {icon}
                </LayoutItemCardHeaderIconButton>
              </Tooltip>
            ))}
          </Menu>
        </ClickAwayListener>
      </StyledPopper>
    </LayoutItemCardHeaderRoot>
  )
}

const LayoutItemCardHeaderRoot = styled.div`
  display: flex;
  align-items: center;
`

const Title = styled.p<{ $isCentered: boolean | undefined }>`
  font-family: ${({ theme }) => theme.font.style.bold};
  flex-grow: 1;
  ${({ $isCentered }) =>
    $isCentered
      ? css`
          text-align: center;
        `
      : css`
          margin-left: 48px;
        `}
`

const DragHandle = styled.div`
  cursor: grab;
  display: flex;
`

const StyledEditableLabel = styled(EditableLabel)`
  .label,
  input {
    font-size: 14px;
    font-family: ${props => props.theme.font.style.bold};
    color: ${props => props.theme.colors.primaryDark['100']};
  }
`

const Actions = styled.div`
  display: flex;
  gap: 4px;
`

const StyledPopper = styled(Popper)`
  z-index: 2;
`

const Menu = styled.div`
  border-radius: 4px;
  border: 1px solid ${props => props.theme.colors.primaryDark[20]};
  padding: 8px;
  background-color: white;
  display: grid;
  gap: 12px;
  grid-template-columns: repeat(2, 1fr);
`
