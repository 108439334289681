import { AppTheme } from 'Theme'

export const createMetaAnalysisVolcanoPlotBaseOptions = (
  theme: AppTheme,
  xThreshold: number,
  yThreshold: number,
  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
) => {
  return {
    chart: {
      type: 'scatter',
      zoomType: 'xy',
      animation: false,
    } as Highcharts.ChartOptions,
    xAxis: {
      title: {
        text: 'Log2 Fold Change',
      },
      min: -1,
      max: 1,
      lineWidth: 1,
      tickInterval: 0.25,
      plotLines: [
        {
          value: xThreshold,
          color: theme.colors.greyscale[40],
          width: 1,
        },
        {
          value: xThreshold * -1,
          color: theme.colors.greyscale[40],
          width: 1,
        },
      ],
    },
    yAxis: {
      title: {
        text: 'Log10 Mean of Normalized Count',
      },
      lineWidth: 1,
      plotLines: [
        {
          value: yThreshold,
          color: theme.colors.greyscale[40],
          width: 1,
        },
      ],
    },
    plotOptions: {
      series: {
        animation: false,
        dataLabels: {
          enabled: true,
          allowOverlap: true,
          style: {
            width: 60,
            textOverflow: 'ellipsis',
            fontSize: `${theme.font.size.sub}px`,
          },
          formatter: function (this) {
            return this.series.name !== 'None' ? this.point.name : null
          },
        },
      },
      scatter: {
        marker: {
          radius: 4,
          symbol: 'circle',
        },
        jitter: {
          x: 0.005,
        },
      },
    } as Highcharts.PlotOptions,
    title: {
      text: '',
    },
    legend: {
      enabled: true,
      layout: 'horizontal',
      align: 'center',
    } as Highcharts.LegendOptions,
  }
}
