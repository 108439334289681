import { Typography, IconButton } from '@material-ui/core'
import { useCallback, useMemo, useState } from 'react'
import { useHistory, useParams } from 'react-router-dom'
import styled from 'styled-components'

import { ReactComponent as ArrowLeftIcon } from 'assets/images/icons/analysis/arrowLeft.svg'

import { LoadingMask } from 'components/LoadingMask'

import {
  useAddToBasketMutation,
  useRemoveActiveBasketMutation,
} from 'shared/api/baskets.api'
import { useGetLicensePackagesQuery } from 'shared/api/license-package.api'
import { RESOURCE_LABELS } from 'shared/models/LicenseModels'
import { useAppDispatch } from 'shared/store'
import { showNotification } from 'shared/store/notification.slice'
import { handleError } from 'shared/utils/errorHandler'

import { CustomPackageModal } from './CustomPackageModal'
import { Package } from './Package'
import { PackageDetailsModal } from './PackageDetailsModal'

const ENTERPRISE_PACKAGE_ID = 'enterprise-package'

export const BuyPackagePage = (): JSX.Element => {
  const history = useHistory()
  const dispatch = useAppDispatch()

  const {
    data: licensePackages,
    isLoading,
    error,
  } = useGetLicensePackagesQuery()
  const [addToBasket] = useAddToBasketMutation()
  const [removeActiveBasket] = useRemoveActiveBasketMutation()

  const [selectedPackageId, setSelectedPackageId] = useState<string | null>(
    null,
  )
  const [selectedSeats, setSelectedSeats] = useState<{
    [packageId: string]: number
  }>({})
  const [isCustomPackageModalOpen, setCustomPackageModalOpen] = useState(false)

  const { basketId } = useParams<{ basketId: string }>()

  const sortedNonSeatPackages = useMemo(
    () =>
      licensePackages?.results
        ?.filter(
          packageResult => !packageResult.name.toLowerCase().includes('seats'),
        )
        .sort((a, b) => parseInt(a.net_price) - parseInt(b.net_price)),
    [licensePackages],
  )

  const maxSeats = useMemo(() => {
    const seatPackages = licensePackages?.results.filter(
      packageResult => !sortedNonSeatPackages?.includes(packageResult),
    )

    const seatNumbers =
      seatPackages &&
      seatPackages.map(packageResult => {
        const match = packageResult.name.match(/\d{1,2}/)?.[0]
        return match ? parseInt(match) : 1
      })

    return Math.max(...(seatNumbers ?? [1]))
  }, [licensePackages, sortedNonSeatPackages])

  const handleSeatChange = (mode: 'add' | 'subtract', id: string) => {
    const newSeats = { ...selectedSeats }
    if (!newSeats[id]) {
      newSeats[id] = 1
    }
    if (mode === 'add') {
      if (newSeats[id] === maxSeats) {
        return dispatch(
          showNotification({
            type: 'info',
            description: `For licences with more than ${maxSeats} seats, please contact us for custom Enterprise package`,
          }),
        )
      } else {
        newSeats[id]++
      }
    } else {
      if (newSeats[id] === 1) {
        return
      } else {
        newSeats[id]--
      }
    }
    setSelectedSeats(newSeats)
  }

  const handleSelectPackage = useCallback(
    (id: string) => {
      setSelectedPackageId(id)
      if (!Object.keys(selectedSeats).includes(id)) {
        setSelectedSeats({
          ...selectedSeats,
          [id]: 1,
        })
      }
    },
    [selectedSeats],
  )

  const handleSubmitOrder = useCallback(async () => {
    if (!selectedPackageId) {
      throw handleError(new Error('No package selected'))
    }

    if (!licensePackages) {
      throw handleError(new Error('No packages found'))
    }

    const selectedPackage = licensePackages.results.find(
      licensePackage => licensePackage.id === selectedPackageId,
    )

    if (!selectedPackage) {
      throw handleError(new Error('No package found with selected id'))
    }

    const seatPackageName = `${selectedPackage.name} ${selectedSeats[selectedPackageId]} seats`

    const seatPackageId = licensePackages.results.find(
      packageResult => packageResult.name === seatPackageName,
    )?.id

    if (!seatPackageId) {
      throw handleError(new Error('Could not find seat package'))
    }

    await removeActiveBasket().unwrap()

    const basket = await addToBasket({
      license_package: selectedPackageId,
      quantity: 1,
    }).unwrap()

    await addToBasket({
      id: basket.id as string,
      license_package: seatPackageId,
      quantity: 1,
    }).unwrap()

    history.push({ pathname: `${history.location.pathname}/${basket.id}` })
  }, [
    addToBasket,
    licensePackages,
    history,
    removeActiveBasket,
    selectedPackageId,
    selectedSeats,
  ])

  if (isLoading) {
    return <LoadingMask />
  }

  if (error || !licensePackages) {
    return (
      <LicensePackagePageRoot>
        <LicensePackageHeader>
          <BackButton
            size="medium"
            onClick={() => history.push('/license-wallet')}
          >
            <ArrowLeftIcon />
            <Typography variant="caption">Back to wallet</Typography>
          </BackButton>
          <ErrorMessage>
            <ErrorHeader>Oops, something went wrong, try again.</ErrorHeader>
          </ErrorMessage>
        </LicensePackageHeader>
      </LicensePackagePageRoot>
    )
  }

  return (
    <LicensePackagePageRoot>
      <LicensePackageHeader>
        <BackButton
          size="medium"
          onClick={() => history.push('/license-wallet')}
        >
          <ArrowLeftIcon />
          <Typography variant="caption">Back to wallet</Typography>
        </BackButton>
        <Typography variant="h3" align="center">
          Purchase a license
        </Typography>
      </LicensePackageHeader>
      {!licensePackages.results ||
        (licensePackages.results.length === 0 && (
          <NoPackageInfo variant="body1" align="center">
            No predefined packages found for your organization
          </NoPackageInfo>
        ))}
      <PackagesContainer>
        {sortedNonSeatPackages?.map(
          ({ id, name, license_packages_resource_types }) => {
            const seatPackage = licensePackages.results.find(
              packageResult =>
                packageResult.name ===
                `${name} ${selectedSeats[id] ?? 1} seats`,
            )
            return (
              <Package
                key={id}
                name={name}
                resources={license_packages_resource_types}
                isSelected={selectedPackageId === id}
                canChangeSeats={true}
                seats={selectedSeats[id]}
                buttonText="Order"
                price={seatPackage?.net_price}
                currency={seatPackage?.currency}
                vat={seatPackage?.vat_value}
                onPackageClick={() => handleSelectPackage(id)}
                onButtonClick={handleSubmitOrder}
                onSeatChange={mode => handleSeatChange(mode, id)}
              />
            )
          },
        )}
        <Package
          key="enterprise-license"
          name="Enterprise"
          resources={[
            {
              resource_type: 'ST',
              resource_type_name: RESOURCE_LABELS['ST'],
              value: 'Custom',
            },
            {
              resource_type: 'CP',
              resource_type_name: RESOURCE_LABELS['CP'],
              value: 'Custom',
            },
            {
              resource_type: 'SP',
              resource_type_name: RESOURCE_LABELS['SP'],
              value: 4,
            },
            {
              resource_type: 'CG',
              resource_type_name: RESOURCE_LABELS['CG'],
              value: 2,
            },
          ]}
          buttonText="Contact us"
          onButtonClick={() => setSelectedPackageId(ENTERPRISE_PACKAGE_ID)}
        />
      </PackagesContainer>
      {basketId && (
        <PackageDetailsModal
          basketId={basketId}
          onClose={() => history.push('/buy-package')}
        />
      )}
      {isCustomPackageModalOpen && (
        <CustomPackageModal onClose={() => setCustomPackageModalOpen(false)} />
      )}
    </LicensePackagePageRoot>
  )
}

const LicensePackagePageRoot = styled.div`
  padding-top: calc(${props => props.theme.layout.headerHeight}px + 24px);
  width: 800px;
  margin: 0 auto ${props => props.theme.spacing(4)}px;
`

const LicensePackageHeader = styled.div`
  width: 100%;
  position: relative;
`

const BackButton = styled(IconButton)`
  position: absolute;
  top: -6px;
  left: 0;
  color: ${props => props.theme.colors.primaryDark[100]};

  &:hover {
    background: transparent;
  }

  & > span > svg {
    margin-right: 8px;
  }
`

const PackagesContainer = styled.div`
  display: flex;
  justify-content: center;
  margin: 40px auto 0;
`

const ErrorMessage = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: ${props => props.theme.spacing(6)}px;
`

const ErrorHeader = styled.span`
  font-size: ${props => props.theme.font.size.h2}px;
`

const NoPackageInfo = styled(Typography)`
  margin-top: ${props => props.theme.spacing(2)}px;
  color: ${props => props.theme.colors.error};
`
