import { useHistory } from 'react-router'
import styled from 'styled-components'

import { useGetUserQuery } from 'shared/api/user.api'
import { useAppDispatch } from 'shared/store'
import { logout } from 'shared/store/auth.slice'

import { ContextMenu } from './ContextMenu'
import { ContextMenuItem } from './ContextMenuItem'

type UserMenuProps = {
  anchorElement: HTMLElement | null
  isOpen: boolean
  onClose: () => void
}

export const UserMenu: React.FC<UserMenuProps> = ({
  anchorElement,
  isOpen,
  onClose,
}) => {
  const history = useHistory()
  const dispatch = useAppDispatch()
  const isOperatorUser = useGetUserQuery().data?.is_operator

  const onProfileClick = () => {
    onClose()
    history.push(`/profile`)
  }

  const handleOpenMembersPanel = () => {
    onClose()
    history.push(`/members-panel`)
  }

  const handleOpenLicenseWallet = () => {
    onClose()
    history.push(`/license-wallet`)
  }

  const handleOpenExportsPage = () => {
    onClose()
    history.push('/exports')
  }

  const handleLogOut = () => {
    history.push('/log-in')
    dispatch(logout())
  }

  return (
    <StyledMenu anchorEl={anchorElement} open={isOpen} onClose={onClose}>
      <MenuItem label="Profile" startIcon="user" onClick={onProfileClick} />
      {isOperatorUser && (
        <MenuItem
          label="Members panel"
          startIcon="userGroup"
          onClick={handleOpenMembersPanel}
        />
      )}
      <MenuItem
        label="License wallet"
        startIcon="exchange"
        onClick={handleOpenLicenseWallet}
      />
      <MenuItem
        label="Exports"
        startIcon="export"
        onClick={handleOpenExportsPage}
      />
      <MenuItem
        label="Log out"
        startIcon="logout"
        onClick={handleLogOut}
        warning
      />
    </StyledMenu>
  )
}

const StyledMenu = styled(ContextMenu)`
  width: 170px !important;
  z-index: 2;
`

const MenuItem = styled(ContextMenuItem)<{ warning?: boolean }>`
  width: 170px;
  color: ${props => (props.warning ? props.theme.colors.error : '')};
`
