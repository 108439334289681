import { ComponentType, FC, ReactNode } from 'react'
import { Route, RouteProps } from 'react-router'
import styled from 'styled-components'

import { Header } from 'components/Header'

import { PrivateRouteCheck } from './PrivateRouteCheck'

type PrivateRouteProps = Omit<RouteProps, 'render' | 'component'> & {
  children?: ReactNode
  component?: ComponentType
  withHeader?: boolean
}

export const PrivateRoute: FC<PrivateRouteProps> = ({
  children,
  component: Component,
  withHeader,
  ...props
}) => {
  return (
    <Route {...props}>
      <PrivateRouteCheck>
        {withHeader && <StyledHeader shouldShowMetaflowLogo />}
        {Component ? <Component /> : children}
      </PrivateRouteCheck>
    </Route>
  )
}

const StyledHeader = styled(Header)`
  position: fixed;
  top: 0;
  width: 100vw;
  z-index: 2;
  background-color: white;
  padding: 6px 24px;
`
