import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit'
import { uuid4 } from '@sentry/utils'

import { authApi } from 'shared/api/auth.api'
import { getCsrfToken, removeCsrfToken } from 'shared/api/cookies'
import { TIME } from 'shared/constants'
import { handleError } from 'shared/utils/errorHandler'

import { RootState } from '.'

export type AuthState = {
  isAuthenticated: boolean
  applicationInstanceId: string
  sessionExpiresAt: number | undefined
  userInactiveSince: number | undefined
  sessionExpiryTime: number
}

const initialState: AuthState = {
  isAuthenticated: !!getCsrfToken(),
  applicationInstanceId: uuid4(),
  sessionExpiresAt: undefined,
  userInactiveSince: undefined,
  sessionExpiryTime: TIME.HOUR,
}

export const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    setIsAuthenticated: (state, action: PayloadAction<boolean>) => {
      state.isAuthenticated = action.payload
    },
    setSessionExpiresAt: (state, action: PayloadAction<number | undefined>) => {
      state.sessionExpiresAt = action.payload
    },
    setUserInactiveSince: (
      state,
      action: PayloadAction<number | undefined>,
    ) => {
      state.userInactiveSince = action.payload
    },
    setSessionExpiryTime: (state, action: PayloadAction<number>) => {
      state.sessionExpiryTime = action.payload
    },
  },
  extraReducers: builder => {
    builder.addMatcher(authApi.endpoints.login.matchFulfilled, state => {
      state.userInactiveSince = Date.now()
    })
    builder.addMatcher(
      authApi.endpoints.getSession.matchFulfilled,
      (state, action) => {
        state.sessionExpiryTime =
          action.payload.session_expiry_age * TIME.SECOND
        state.sessionExpiresAt = Date.now() + state.sessionExpiryTime
      },
    )
  },
})

export const { setIsAuthenticated } = authSlice.actions

export const logout = createAsyncThunk(
  'auth/logout',
  async (_, { dispatch }) => {
    try {
      await fetch('/api/v1/logout/')
      removeCsrfToken()
      dispatch(authSlice.actions.setIsAuthenticated(false))
      dispatch(authSlice.actions.setSessionExpiresAt(undefined))
      dispatch(authSlice.actions.setUserInactiveSince(undefined))
    } catch (error) {
      handleError(error)
    }
  },
)

export const extendSession = createAsyncThunk(
  'auth/extendSession',
  (_, { dispatch, getState }) => {
    const state = getState() as RootState
    const sessionExpiryTime = selectSessionExpiryTime(state)
    dispatch(
      authSlice.actions.setSessionExpiresAt(Date.now() + sessionExpiryTime),
    )
  },
)

export const selectIsAuthenticated = (state: RootState): boolean => {
  return state.auth.isAuthenticated
}

export const selectApplicationInstanceId = (state: RootState): string => {
  return state.auth.applicationInstanceId
}

export const selectSessionExpiresAt = (
  state: RootState,
): number | undefined => {
  return state.auth.sessionExpiresAt
}

export const selectUserInactiveSince = (
  state: RootState,
): number | undefined => {
  return state.auth.userInactiveSince
}

export const selectSessionExpiryTime = (state: RootState): number =>
  state.auth.sessionExpiryTime
