import { Fade, useTheme } from '@material-ui/core'
import { FC, useCallback, useMemo, useState } from 'react'
import styled from 'styled-components'

import { Icon } from 'assets/images/icons/Icon'

import { Button } from 'components/Button'

import { useEventCallback } from 'shared/hooks/useEventCallback'
import { useAppDispatch, useAppSelector } from 'shared/store'

import { AppTheme } from 'Theme'

import { ClusterList } from './ClusterList'
import {
  changeClustersVisibility,
  changeClustersVisibilityOnAllCharts,
  renameCluster,
} from './store/analysis.slice'
import {
  selectActiveLeavesWithoutRoot,
  selectAnalysisAccessMode,
  selectAnalysisLassos,
  selectCharts,
  selectClusters,
  selectShownActiveLeafIds,
} from './store/selectors'

type EditDisplayClustersProps = {
  onClose: () => void
  graphId?: string
  lassoIds?: string[]
}

export const EditDisplayedClusters: FC<EditDisplayClustersProps> = ({
  onClose,
  graphId,
  lassoIds,
}) => {
  const theme = useTheme<AppTheme>()

  const dispatch = useAppDispatch()
  const lassos = useAppSelector(selectAnalysisLassos)
  const clusters = useAppSelector(selectClusters)
  const activeLeavesWithoutRoot = useAppSelector(selectActiveLeavesWithoutRoot)
  const charts = useAppSelector(selectCharts)
  const globallyShownActiveLeaves = useAppSelector(selectShownActiveLeafIds)
  const analysisAccessMode = useAppSelector(selectAnalysisAccessMode)

  const chart = charts.find(g => g.id === graphId)

  const initialSelectedClusters = useMemo(
    () =>
      graphId
        ? clusters
            .map(cluster => cluster.id)
            .filter(clusterId => !chart?.hidden_cluster_ids.includes(clusterId))
        : globallyShownActiveLeaves,
    [chart?.hidden_cluster_ids, clusters, globallyShownActiveLeaves, graphId],
  )

  const [selectedClusters, setSelectedClusters] = useState(
    initialSelectedClusters,
  )

  const applyChanges = useCallback(() => {
    const hiddenClusters = activeLeavesWithoutRoot
      .map(leaf => leaf.id)
      .filter(leafId => !selectedClusters.includes(leafId))

    if (!graphId) {
      dispatch(changeClustersVisibilityOnAllCharts({ hiddenClusters }))
    } else {
      dispatch(
        changeClustersVisibility({
          chartId: graphId,
          hiddenClusters,
        }),
      )
    }
  }, [activeLeavesWithoutRoot, dispatch, graphId, selectedClusters])

  const handleRename = useEventCallback((clusterId: string, name: string) => {
    dispatch(renameCluster({ clusterId, name }))
  })

  return (
    <Fade
      in={true}
      unmountOnExit={true}
      appear={true}
      timeout={{
        enter: 300,
        exit: 0,
      }}
    >
      <SelectionContainer>
        <ListsContainer>
          <ListHeader>
            <EmptySelectionDiv />
            <Title>
              {graphId
                ? 'Select clusters displayed on this graph'
                : 'Select displayed clusters'}
            </Title>
            <CloseIcon onClick={onClose}>
              <Icon name="close" color={theme.colors.primaryDark[100]} />
            </CloseIcon>
          </ListHeader>
          <List>
            <ClusterList
              clusters={activeLeavesWithoutRoot}
              lassos={lassos}
              selectedIds={selectedClusters}
              graphLassoIds={lassoIds || []}
              onChange={
                analysisAccessMode === 'read-and-write'
                  ? setSelectedClusters
                  : undefined
              }
              onRename={
                analysisAccessMode !== 'read-only' ? handleRename : undefined
              }
              onClusterHighlighted={onClose}
              columns={2}
              initialShouldShowLassos
            />
          </List>
        </ListsContainer>
        <ApplyChangesButton
          onClick={applyChanges}
          disabled={analysisAccessMode !== 'read-and-write'}
        >
          Apply changes
        </ApplyChangesButton>
      </SelectionContainer>
    </Fade>
  )
}

const EmptySelectionDiv = styled.div`
  width: 30px;
`

const Title = styled.div`
  font-family: ${props => props.theme.font.style.bold};
  font-size: ${props => props.theme.font.size.h2}px;
  color: ${props => props.theme.colors.primaryDark[100]};
`

const SelectionContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 85%;
  margin: auto;
`

const ListsContainer = styled.div`
  background-color: ${props => props.theme.colors.white};
  overflow-y: auto;
  border-radius: ${props => props.theme.radius[2]}px;
  border: 1px solid ${props => props.theme.colors.primaryDark[20]};
  box-shadow: ${props => props.theme.shadow[1]};
  color: ${props => props.theme.colors.primaryDark[100]};
`

const ListHeader = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: space-between;
  padding: 20px;
`

const List = styled.div`
  display: flex;
  flex-wrap: wrap;
  overflow-y: auto;
  padding: 32px;
`

const CloseIcon = styled.div`
  cursor: pointer;
`

const ApplyChangesButton = styled(Button)`
  margin-top: 32px;
`
