import {
  Button,
  Radio,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@material-ui/core'
import { useFormikContext } from 'formik'
import { produce } from 'immer'
import styled from 'styled-components'
import { ValidationError, string } from 'yup'

import { Icon } from 'assets/images/icons/Icon'

import { EditableLabel } from 'components/EditableLabel'

import { MetaAnalysisWizardFormValues } from './MetaAnalysisWizard'

type MetaAnalysisWizardSelectGroupsStepProps = {
  fileNameById: Record<string, string>
}

export const MetaAnalysisWizardSelectGroupsStep = ({
  fileNameById,
}: MetaAnalysisWizardSelectGroupsStepProps): JSX.Element => {
  const {
    values: { groups, fileGroupMapping },
    setFieldValue,
    setValues,
  } = useFormikContext<MetaAnalysisWizardFormValues>()

  const fileIds = Object.keys(fileGroupMapping)

  const handleAddGroup = () => {
    let i = groups.length + 1
    while (groups.includes(`Group ${i}`)) {
      i++
    }
    setFieldValue(
      'groups',
      produce(groups, draft => {
        draft.push(`Group ${i}`)
      }),
    )
  }

  const handleRenameGroup = (oldName: string, newName: string) => {
    setValues(state =>
      produce(state, draft => {
        draft.groups[draft.groups.indexOf(oldName)] = newName
        for (const fileId in draft.fileGroupMapping) {
          if (draft.fileGroupMapping[fileId] === oldName) {
            draft.fileGroupMapping[fileId] = newName
          }
        }
      }),
    )
  }

  const handleDeleteGroup = (group: string) => {
    setValues(state =>
      produce(state, draft => {
        draft.groups = draft.groups.filter(g => g !== group)
        for (const fileId in draft.fileGroupMapping) {
          if (draft.fileGroupMapping[fileId] === group) {
            draft.fileGroupMapping[fileId] = draft.groups[0]
          }
        }
      }),
    )
  }

  const handleChangeGroupMapping = (fileId: string, group: string) => {
    setFieldValue(
      'fileGroupMapping',
      produce(fileGroupMapping, draft => {
        draft[fileId] = group
      }),
    )
  }

  const handleValidateGroup = (group: string, name: string) => {
    try {
      string()
        .required('Group name cannot be empty')
        .notOneOf(
          groups.filter(g => g !== group),
          'Group name already exists',
        )
        .validateSync(name.trim())
      return ''
    } catch (error) {
      const validationError = error as ValidationError
      return validationError.errors[0]
    }
  }

  return (
    <MetaAnalysisWizardSelectGroupsStepRoot>
      <TableContainer component={TableRoot}>
        <Table size="small">
          <TableHead>
            <TableRow>
              <StyledTableCell></StyledTableCell>
              {groups.map(group => (
                <StyledTableCell key={group}>
                  <StyledEditableLabel
                    value={group}
                    onChange={name => handleRenameGroup(group, name.trim())}
                    onValidate={name => handleValidateGroup(group, name)}
                    onDelete={
                      groups.length > 1
                        ? () => handleDeleteGroup(group)
                        : undefined
                    }
                  />
                </StyledTableCell>
              ))}
              <StyledTableCell>
                <StyledTextButton variant="text" onClick={handleAddGroup}>
                  <Icon name="plus" />
                  ADD
                </StyledTextButton>
              </StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {fileIds.map(fileId => (
              <TableRow key={fileId}>
                <FileCell>{fileNameById[fileId]}</FileCell>
                {groups.map(group => (
                  <StyledTableCell key={group}>
                    <RadioContainer>
                      <StyledRadio
                        color="primary"
                        checked={fileGroupMapping[fileId] === group}
                        onClick={() => handleChangeGroupMapping(fileId, group)}
                        size="small"
                      />
                    </RadioContainer>
                  </StyledTableCell>
                ))}
                <StyledTableCell />
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </MetaAnalysisWizardSelectGroupsStepRoot>
  )
}

const MetaAnalysisWizardSelectGroupsStepRoot = styled.div`
  height: 100%;
  overflow: auto;
`

const TableRoot = styled.div`
  height: 100%;
`

const StyledTableCell = styled(TableCell)`
  font-family: ${({ theme }) => theme.font.style.bold};
  font-size: 12px;
  color: ${props => props.theme.colors.primaryDark['70']};
  line-height: unset;
  text-align: center;
`

const FileCell = styled(StyledTableCell)`
  text-align: left;
  min-width: 200px;
`

const StyledTextButton = styled(Button)`
  font-family: ${({ theme }) => theme.font.style.bold};
  font-size: 10px;
  color: ${props => props.theme.colors.primaryDark['70']};
`

const StyledEditableLabel = styled(EditableLabel)`
  width: 140px;

  .label {
    font-size: 12px;
    font-family: ${props => props.theme.font.style.bold};
  }

  input {
    font-size: 12px;
    font-family: ${props => props.theme.font.style.bold};
    color: ${props => props.theme.colors.primaryDark['70']};
  }
`

const RadioContainer = styled.div`
  width: 140px;
`

const StyledRadio = styled(Radio)`
  color: ${props => props.theme.colors.primaryDark['70']} !important;
`
