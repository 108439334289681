import { Fade, useTheme } from '@material-ui/core'
import { xor } from 'lodash'
import React, { FC, useCallback, useState, useEffect } from 'react'
import styled from 'styled-components'

import { Icon } from 'assets/images/icons/Icon'

import { Button } from 'components/Button'
import { ChannelsList, ChannelsListEditedLabels } from 'components/ChannelsList'

import { TIME_CHANNEL, CUMULATIVE_FLUORESCENCE_CHANNEL } from 'shared/constants'
import { useAppDispatch, useAppSelector } from 'shared/store'

import { AppTheme } from 'Theme'

import {
  setSelectedChannels,
  updateChannelLabels,
} from './store/analysis.slice'
import { selectChannels, selectSortedSelectedChannels } from './store/selectors'

type EditDisplayChannelsProps = {
  onClose: () => void
}

export const EditDisplayedChannels: FC<EditDisplayChannelsProps> = ({
  onClose,
}) => {
  // Hooks
  const theme = useTheme<AppTheme>()
  const dispatch = useAppDispatch()
  const channels = useAppSelector(selectChannels)
  const selectedChannels = useAppSelector(selectSortedSelectedChannels)
  const [draftSelectedChannels, setDraftSelectedChannels] =
    useState(selectedChannels)
  const [editedLabels, setEditedLabels] = useState<ChannelsListEditedLabels>({})

  useEffect(() => {
    setDraftSelectedChannels(selectedChannels)
  }, [selectedChannels])

  const applyChanges = useCallback(() => {
    if (xor(selectedChannels, draftSelectedChannels).length > 0) {
      dispatch(setSelectedChannels(draftSelectedChannels))
    }
    if (Object.keys(editedLabels).length > 0) {
      dispatch(updateChannelLabels(editedLabels))
    }
    onClose()
  }, [dispatch, draftSelectedChannels, editedLabels, onClose, selectedChannels])

  return (
    <>
      <Fade
        in={true}
        unmountOnExit={true}
        appear={true}
        timeout={{
          enter: 300,
          exit: 0,
        }}
      >
        <SelectionContainer>
          <ListsContainer>
            <ListHeader>
              <EmptySelectionDiv />
              <Title>Edit channels</Title>
              <CloseIcon onClick={onClose}>
                <Icon name="close" color={theme.colors.primaryDark[100]} />
              </CloseIcon>
            </ListHeader>
            <ChannelsList
              columns={2}
              selectable
              writable
              channels={channels}
              excludedChannels={[TIME_CHANNEL, CUMULATIVE_FLUORESCENCE_CHANNEL]}
              selectedChannels={draftSelectedChannels}
              onSelectedChannelsChange={setDraftSelectedChannels}
              onLabelsChange={setEditedLabels}
            />
          </ListsContainer>
          <SubmitButton onClick={applyChanges}>Apply changes</SubmitButton>
        </SelectionContainer>
      </Fade>
    </>
  )
}

const EmptySelectionDiv = styled.div`
  width: 30px;
`
const Title = styled.div`
  font-family: ${props => props.theme.font.style.bold};
  font-size: ${props => props.theme.font.size.h2}px;
  color: ${props => props.theme.colors.primaryDark[100]};
`
const SelectionContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 85%;
  margin: auto;
`
const SubmitButton = styled(Button)`
  margin-left: auto;
  margin-top: 20px;
`
const ListHeader = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: space-between;
  padding: 20px;
`
const ListsContainer = styled.div`
  background-color: ${props => props.theme.colors.white};
  overflow-y: auto;
  border-radius: ${props => props.theme.radius[2]}px;
  border: 1px solid ${props => props.theme.colors.primaryDark[20]};
  box-shadow: ${props => props.theme.shadow[1]};
  color: ${props => props.theme.colors.primaryDark[100]};
`
const CloseIcon = styled.div`
  cursor: pointer;
`
