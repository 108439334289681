import { CircularProgress as MuiCircularProgress } from '@material-ui/core'
import { FC } from 'react'
import styled from 'styled-components'

export const CircularProgress: FC = () => {
  return (
    <ProgressContainer>
      <MuiCircularProgress />
    </ProgressContainer>
  )
}

const ProgressContainer = styled.div`
  width: 100%;
  min-height: 100px;
  flex-grow: 1;
  display: flex;
  justify-content: center;
  align-items: center;
`
