import Highcharts from 'highcharts'

import { MetaAnalysisGlobalHeatMapChartBaseOptions } from 'pages/meta-analysis/MetaAnalysisGlobalHeatMapChartBaseOptions'
import { computeGlobalHeatMapSeries } from 'pages/meta-analysis/series'

import {
  MetaAnalysisColors,
  MetaAnalysisFile,
  MetaAnalysisGlobalHeatMapChartType,
} from 'shared/api/meta-analysis.api'

type RenderGlobalHeatMapChartProps = {
  chart: MetaAnalysisGlobalHeatMapChartType
  metaAnalysisFile: MetaAnalysisFile
  colors: MetaAnalysisColors
  fileNameById: Record<string, string>
  width: number
  height: number
}

export const renderGlobalHeatMapChart = async ({
  chart,
  metaAnalysisFile,
  colors,
  fileNameById,
  width,
  height,
}: RenderGlobalHeatMapChartProps): Promise<string> => {
  return new Highcharts.Chart(document.createElement('div'), {
    ...MetaAnalysisGlobalHeatMapChartBaseOptions,
    chart: {
      ...MetaAnalysisGlobalHeatMapChartBaseOptions.chart,
      width,
      height,
    },
    xAxis: {
      ...MetaAnalysisGlobalHeatMapChartBaseOptions.xAxis,
      categories: chart.selectedFileIds.map(fileId => fileNameById[fileId]),
    },
    series: computeGlobalHeatMapSeries(chart, metaAnalysisFile, colors),
    title: {
      ...MetaAnalysisGlobalHeatMapChartBaseOptions.title,
      text: chart.name,
    },
  }).getSVG()
}
