import { useFormikContext } from 'formik'
import styled from 'styled-components'

import { ValidationLabel } from 'components/ validation-label/ValidationLabel'
import { TextField } from 'components/text-field/TextField'

import { CreateBrickFormValues } from './CreateBrickWizard'
import { WizardSectionCard } from './WizardSectionCard'

export const CreateBrickWizardBasicSettingsStep: React.FC = () => {
  const { errors, values, handleChange, handleBlur } =
    useFormikContext<CreateBrickFormValues>()
  return (
    <>
      <WizardSectionCard title="Set names">
        {errors.name ? <ValidationLabel>{errors.name}</ValidationLabel> : null}
        <StyledTextField
          name="name"
          value={values.name}
          placeholder="Step name"
          onChange={handleChange}
          onBlur={handleBlur}
          autoComplete="off"
        />
      </WizardSectionCard>
    </>
  )
}

const StyledTextField = styled(TextField)`
  width: 50%;

  & > .MuiInputBase-root {
    margin-top: 0;
  }
`
