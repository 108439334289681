import React, { useMemo, useState } from 'react'
import { RGBColor } from 'react-color'
import styled from 'styled-components'

import { Button } from 'components/Button'
import { ErrorBoxAlert } from 'components/ErrorBoxAlert'
import { SelectColorContextMenu } from 'components/SelectColorContextMenu'

import { useAppDispatch, useAppSelector } from 'shared/store'
import {
  RGBColorToRgbString,
  rgbStringToRGBColor,
  detectColorCollisions,
} from 'shared/utils/colors'

import { changeHeatmapColors } from './store/analysis.slice'
import { selectHeatmapColors } from './store/selectors'

enum SubmitStatus {
  IDLE = 'IDLE',
  COLLISION_ERROR = 'COLLISION_ERROR',
}

type AnalysisHeatmapColorSelectionProps = {
  closeMenu: () => void
}

export const AnalysisHeatmapColorSelection: React.FC<
  AnalysisHeatmapColorSelectionProps
> = props => {
  const { closeMenu } = props

  const dispatch = useAppDispatch()
  const heatmapColors = useAppSelector(selectHeatmapColors)

  const initialMinColor = useMemo(
    () => rgbStringToRGBColor(heatmapColors.min),
    [heatmapColors.min],
  )
  const initialMaxColor = useMemo(
    () => rgbStringToRGBColor(heatmapColors.max),
    [heatmapColors.max],
  )

  const [submitStatus, setSubmitStatus] = useState(SubmitStatus.IDLE)
  const [showColorMenu, setShowColorMenu] = useState(false)
  const [minColor, setMinColor] = useState<RGBColor>(initialMinColor)
  const [maxColor, setMaxColor] = useState<RGBColor>(initialMaxColor)
  const [typeSelected, setTypeSelected] = useState<'min' | 'max' | null>(null)
  const [menuOrigin, setMenuOrigin] = useState<{ x: number; y: number } | null>(
    null,
  )

  const openMenuHandler = (
    e: React.MouseEvent<HTMLDivElement, MouseEvent>,
    type: 'min' | 'max',
  ) => {
    const { clientX, clientY } = e
    setShowColorMenu(true)
    setTypeSelected(type)
    setMenuOrigin({
      x: clientX,
      y: clientY,
    })
  }

  const selectColorHandler = (color: RGBColor | null) => {
    if (!color) {
      return
    }

    if (typeSelected === 'min') {
      setMinColor(color)
    } else if (typeSelected === 'max') {
      setMaxColor(color)
    }
    setShowColorMenu(false)
  }

  const validateNewColors = () => {
    const DELTA = 10 // default value for DELTA in heatmap color legend
    const minRgb = RGBColorToRgbString(minColor)
    const maxRgb = RGBColorToRgbString(maxColor)

    if (detectColorCollisions(minRgb, [maxRgb], DELTA)) {
      setSubmitStatus(SubmitStatus.COLLISION_ERROR)
      return
    }

    dispatch(
      changeHeatmapColors({
        min: minRgb,
        max: maxRgb,
      }),
    )
    closeMenu()
  }

  const currentTypeColor =
    typeSelected === 'min'
      ? minColor
      : typeSelected === 'max'
      ? maxColor
      : undefined
  return (
    <Wrapper>
      <Title>Modify heatmap's color palette</Title>
      <InputsWrapper>
        <ColorWrapper onClick={e => openMenuHandler(e, 'min')}>
          <ColorLabel>Minimum value's color :</ColorLabel>
          <ColorInput>
            <div
              style={{
                backgroundColor: RGBColorToRgbString(minColor),
              }}
            />
          </ColorInput>
        </ColorWrapper>
        <ColorWrapper onClick={e => openMenuHandler(e, 'max')}>
          <ColorLabel>Maximum value's color :</ColorLabel>
          <ColorInput>
            <div
              style={{
                backgroundColor: RGBColorToRgbString(maxColor),
              }}
            />
          </ColorInput>
        </ColorWrapper>
      </InputsWrapper>
      {submitStatus === SubmitStatus.COLLISION_ERROR && (
        <ErrorWrapper>
          <ErrorBoxAlert>
            The two colors selected are too similar and cannot be chosen for the
            heatmap's color palette.
          </ErrorBoxAlert>
        </ErrorWrapper>
      )}
      <ValidationWrapper>
        <Button grey onClick={closeMenu}>
          Cancel
        </Button>
        <Button color="primary" onClick={validateNewColors}>
          Update
        </Button>
      </ValidationWrapper>
      {showColorMenu && menuOrigin && (
        <SelectColorContextMenu
          menuOrigin={menuOrigin}
          onColorValidate={selectColorHandler}
          onCancel={() => setShowColorMenu(false)}
          onClickOutside={() => setShowColorMenu(false)}
          initialColor={currentTypeColor}
        />
      )}
    </Wrapper>
  )
}

const Wrapper = styled.div`
  width: 100%;
  margin: 20px 10px;
  box-sizing: border-box;
`
const Title = styled.div`
  width: 100%;
  text-align: center;
  font-family: ${props => props.theme.font.style.bold};
  font-size: ${props => props.theme.font.size.h3}px;
  color: ${props => props.theme.colors.primaryDark[100]};
  margin-bottom: 32px;
`
const InputsWrapper = styled.div`
  width: 90%;
  margin: auto auto 32px;
`
const ColorWrapper = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 16px;
`
const ColorLabel = styled.div`
  flex: 1;
  max-width: 200px;
  font-size: ${props => props.theme.font.size.regular}px;
  color: ${props => props.theme.colors.primaryDark[100]};
`
const ColorInput = styled.div`
  padding: 4px;
  border-radius: ${props => props.theme.radius[1]}px;
  border: 1px solid #ccc;
  cursor: pointer;
  & > div {
    border-radius: ${props => props.theme.radius[1]}px;
    width: 30px;
    height: 12px;
  }
`
const ErrorWrapper = styled.div`
  margin-bottom: 32px;
`
const ValidationWrapper = styled.div`
  width: 70%;
  margin: auto;
  display: flex;
  align-items: center;
  & > * {
    box-sizing: border-box;
    flex: 1;
    margin: 0 10px;
  }
`
