import {
  FormControlLabel,
  Checkbox as MuiCheckbox,
  CheckboxProps as MuiCheckboxProps,
} from '@material-ui/core'
import React, { FC, MouseEvent, ReactNode } from 'react'
import styled from 'styled-components'

import { Icon } from 'assets/images/icons/Icon'

import { theme } from 'Theme'

export type CheckboxProps = {
  checked: boolean | undefined
  className?: string
  containerClassName?: string
  labelFontSize?: number
  disabled?: boolean
  disabledColor?: string
  onClick?: (e: MouseEvent<HTMLButtonElement>) => void
  onChange?: (
    event: React.ChangeEvent<HTMLInputElement>,
    checked: boolean,
  ) => void
  label?: ReactNode
  name?: string
  nonSelectable?: boolean
  checkboxProps?: Record<string, unknown>
} & MuiCheckboxProps

export const Checkbox: FC<CheckboxProps> = ({
  checked,
  className,
  containerClassName,
  labelFontSize: size,
  disabled = false,
  disabledColor = theme.colors.greyscale[30],
  onClick,
  onChange,
  name,
  label,
  nonSelectable,
  checkboxProps,
}) => {
  const checkbox = (
    <StyledMuiCheckbox
      $nonSelectable={nonSelectable}
      checkedIcon={
        <StyledCheckedIcon
          disabled={disabled}
          $disabledColor={disabledColor}
          name="check"
        />
      }
      icon={<StyledIcon disabled={disabled} $disabledColor={disabledColor} />}
      disabled={disabled}
      disableRipple={true}
      color="primary"
      className={className}
      size="small"
      checked={checked}
      onClick={e => {
        if (!nonSelectable) {
          onClick?.(e)
        }
      }}
      onChange={(e, checked) => {
        if (!nonSelectable) {
          onChange?.(e, checked)
        }
      }}
      name={name}
      {...checkboxProps}
    />
  )

  if (!label) {
    return checkbox
  }

  return (
    <StyledFormControlLabel
      $nonSelectable={nonSelectable}
      className={containerClassName}
      control={checkbox}
      label={label}
      disabled={disabled}
      size={size}
      onClick={event => event.stopPropagation()}
    ></StyledFormControlLabel>
  )
}

type CheckboxStyleProps = {
  disabled: boolean
  disabledColor: string
  size?: number
}

const StyledMuiCheckbox = styled(MuiCheckbox)<{ $nonSelectable }>`
  cursor: ${props =>
    props.disabled || props.$nonSelectable ? 'default' : 'cursor'};
  display: block;
  justify-content: flex-start;
  padding: 10px;
`

const StyledCheckedIcon = styled(Icon)<
  Omit<CheckboxStyleProps, 'disabledColor'> & {
    $disabledColor: string | undefined
  }
>`
  border-radius: 4px;
  width: ${props => props.size ?? '16px'};
  height: ${props => props.size ?? '16px'};
  border: 1px solid;
  border-color: ${props =>
    props.disabled
      ? props.$disabledColor
      : props.theme.colors.primaryDark[100]};
  padding: 3px;
`

const StyledIcon = styled.span<
  Omit<CheckboxStyleProps, 'disabledColor'> & {
    $disabledColor: string | undefined
  }
>`
  border-radius: 4px;
  width: ${props => props.size ?? '16px'};
  height: ${props => props.size ?? '16px'};
  border: 1px solid;
  border-color: ${props =>
    props.disabled
      ? props.$disabledColor
      : props.theme.colors.primaryDark[100]};
  padding: 3px;
`

const StyledFormControlLabel = styled(FormControlLabel)<{
  size: CheckboxStyleProps['size']
  $nonSelectable?: boolean
}>`
  display: flex;
  cursor: ${props =>
    props.disabled || props.$nonSelectable ? 'default' : 'pointer'};
  > label {
    font-size: ${props => props.size ?? '14px'};
  }

  .MuiFormControlLabel-label {
    overflow: hidden;
  }
`
