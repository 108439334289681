import {
  Button as MuiButton,
  ButtonProps as MuiButtonProps,
  CircularProgress,
} from '@material-ui/core'
import { ReactNode, forwardRef } from 'react'
import styled, { css } from 'styled-components'

import { Theme } from 'Theme'

type ButtonProps = {
  loading?: boolean
  children?: ReactNode
  colorOverride?: 'success' | 'error' | 'warning' | 'greyscale' | 'white'
} & MuiButtonProps

export const Button = forwardRef<HTMLButtonElement, ButtonProps>(
  ({ loading, children, colorOverride, ...props }, ref) => {
    return (
      <StyledButton
        ref={ref}
        {...props}
        disableElevation={true}
        disabled={loading || props.disabled}
        $color={colorOverride}
      >
        {loading ? (
          <StyledCircularProgress size={16} $color={colorOverride} />
        ) : (
          children
        )}
      </StyledButton>
    )
  },
)

const StyledButton = styled(MuiButton)<{
  $color: ButtonProps['colorOverride']
}>`
  padding: 0.8em;
  color: ${props => getTextColor(props.$color, props.theme)};
  background-color: ${props => getBackgroundColor(props.$color, props.theme)};
  border-radius: ${props => props.theme.radius[2]}px;
  font-size: 13px;
  line-height: 1.2;
  font-weight: 600;

  &:hover {
    background: ${props =>
      getBackgroundColor(props.$color, props.theme, 'hover')};
    color: ${props => getTextColor(props.$color, props.theme)};

    &.Mui-disabled {
      background: ${props =>
        getBackgroundColor(props.$color, props.theme, 'disabled')};
    }
  }

  &:disabled {
    background: ${props =>
      getBackgroundColor(props.$color, props.theme, 'disabled')};
    color: ${props => getTextColor(props.$color, props.theme, true)};
    & svg > path {
      fill: ${props => getTextColor(props.$color, props.theme, true)};
    }
  }

  & svg > circle {
    fill: none;
  }

  ${props =>
    props.$color === 'white' &&
    css`
      border: 1px solid ${props.theme.colors.greyscale[10]};
      box-shadow: 0px 0.590248167514801px 1.180496335029602px 0px #0000000d;
    `}
`

const StyledCircularProgress = styled(CircularProgress)<{
  $color: ButtonProps['colorOverride']
}>`
  color: ${props =>
    props.$color === 'greyscale'
      ? props.theme.colors.primaryDark[100]
      : props.theme.colors.white};
`

const getBackgroundColor = (
  color: ButtonProps['colorOverride'],
  theme: Theme,
  status?: 'hover' | 'disabled',
) => {
  const colorWithDefault = color ? color : 'primaryDark'
  if (status === 'hover') {
    return colorWithDefault === 'greyscale'
      ? theme.colors[colorWithDefault][10]
      : colorWithDefault === 'primaryDark'
      ? theme.colors[colorWithDefault][70]
      : theme.colors[`${colorWithDefault}Light`]
  }
  if (status === 'disabled') {
    return colorWithDefault === 'greyscale'
      ? theme.colors[colorWithDefault][10]
      : colorWithDefault === 'primaryDark'
      ? theme.colors[colorWithDefault][70]
      : theme.colors[`${colorWithDefault}Lighter`]
  }
  return colorWithDefault === 'greyscale'
    ? theme.colors[colorWithDefault][20]
    : colorWithDefault === 'primaryDark'
    ? theme.colors[colorWithDefault][100]
    : theme.colors[colorWithDefault]
}

const getTextColor = (
  color: ButtonProps['colorOverride'],
  theme: Theme,
  disabled: boolean = false,
) => {
  if (disabled) {
    return color === 'white' ? theme.colors.greyscale[50] : theme.colors.white
  }

  if (color === 'white') {
    return 'black'
  }
  return color === 'greyscale'
    ? theme.colors.primaryDark[100]
    : theme.colors.white
}
