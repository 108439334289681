import { Formik } from 'formik'
import { FC } from 'react'
import { DistributiveOmit } from 'react-redux'
import styled from 'styled-components'

import { Button } from 'components/Button'
import { Checkbox, CheckboxProps } from 'components/Checkbox'
import Select from 'components/forms/Select'

import { MetaAnalysisDimensionalityReductionChartType } from 'shared/api/meta-analysis.api'
import { useAppSelector } from 'shared/store'

import {
  selectMetaAnalysisChannels,
  selectMetaAnalysisClustersByFcsFileId,
  selectMetaAnalysisFcsFileIds,
  selectMetaAnalysisFcsFileNameById,
} from './store/selectors'

type MetaAnalysisDimensionalityReductionChartOptionsProps = {
  mode: 'edit' | 'create'
  initialValues?: Partial<MetaAnalysisDimensionalityReductionOptionsFormValues>
  onCancel?: () => void
  onFinish: (
    values: DistributiveOmit<
      MetaAnalysisDimensionalityReductionChartType,
      'id' | 'type' | 'name'
    >,
  ) => void
  className?: string
}

export type MetaAnalysisDimensionalityReductionOptionsFormValues = {
  mode: 'cluster/intensity' | 'reference-file'
  selectedFileId: string
  selectedClusters: string[]
  selectedReferenceFileId: string
  selectedChannel: string
  colorBy: 'cluster' | 'event'
}

export const MetaAnalysisDimensionalityReductionChartOptions: FC<
  MetaAnalysisDimensionalityReductionChartOptionsProps
> = ({ mode, initialValues = {}, onCancel, onFinish, className }) => {
  const fileIds = useAppSelector(selectMetaAnalysisFcsFileIds)
  const clustersByFcsFileId = useAppSelector(
    selectMetaAnalysisClustersByFcsFileId,
  )
  const fileNameById = useAppSelector(selectMetaAnalysisFcsFileNameById)
  const channels = useAppSelector(selectMetaAnalysisChannels)

  const handleSubmit = (
    values: MetaAnalysisDimensionalityReductionOptionsFormValues,
  ) => {
    switch (values.mode) {
      case 'cluster/intensity':
        onFinish({
          mode: 'cluster/intensity',
          selectedFileId: values.selectedFileId,
          selectedClusters: values.selectedClusters,
          selectedChannel: values.selectedChannel,
          colorBy: values.colorBy,
        })
        break

      case 'reference-file':
        onFinish({
          mode: 'reference-file',
          selectedFileId: values.selectedFileId,
          selectedReferenceFileId: values.selectedReferenceFileId,
        })
        break

      default:
        throw new Error(`Unknown mode: ${values.mode}`)
    }
  }

  return (
    <Formik<MetaAnalysisDimensionalityReductionOptionsFormValues>
      initialValues={{
        selectedFileId: fileIds[0],
        mode: 'cluster/intensity',
        selectedClusters: clustersByFcsFileId[fileIds[0]] ?? [],
        selectedReferenceFileId:
          fileIds.find(
            fileId => fileId !== (initialValues.selectedFileId ?? fileIds[0]),
          ) ?? fileIds[0],
        selectedChannel: channels[0],
        colorBy: 'cluster',
        ...initialValues,
      }}
      onSubmit={handleSubmit}
    >
      {({ handleChange, handleSubmit, values, setFieldValue }) => {
        const availableClusters = clustersByFcsFileId[values.selectedFileId]
        const areAllClustersSelected =
          values.selectedClusters.length === availableClusters.length &&
          availableClusters.length > 0

        return (
          <MetaAnalysisDimensionalityReductionChartOptionsRoot
            className={className}
            onSubmit={handleSubmit}
          >
            <Options>
              <Header>Selected file</Header>
              <Select
                name="selectedFileId"
                value={values.selectedFileId}
                onChange={event => {
                  const newFileId = event.target.value
                  if (newFileId === values.selectedFileId) {
                    return
                  }
                  if (values.mode === 'reference-file') {
                    setFieldValue(
                      'selectedReferenceFileId',
                      fileIds.find(fileId => fileId !== newFileId),
                    )
                  }
                  setFieldValue('selectedFileId', newFileId)
                }}
                options={fileIds.map(fileId => ({
                  value: fileId,
                  label: fileNameById[fileId],
                }))}
                shouldReserveSpaceForError={false}
              />

              <Header>Mode</Header>
              <Select
                name="mode"
                value={values.mode}
                onChange={event => {
                  const newMode = event.target.value
                  if (newMode === values.mode) {
                    return
                  }
                  if (newMode === 'reference-file') {
                    setFieldValue(
                      'selectedReferenceFileId',
                      fileIds.find(fileId => fileId !== values.selectedFileId),
                    )
                  }
                  setFieldValue('mode', newMode)
                }}
                options={[
                  { value: 'cluster/intensity', label: 'Cluster / Intenstity' },
                  {
                    value: 'reference-file',
                    label: 'Reference file',
                    disabled: fileIds.length === 1,
                    disabledText: 'There is only one file in the meta-analysis',
                  },
                ]}
                shouldReserveSpaceForError={false}
              />

              {values.mode === 'cluster/intensity' && (
                <>
                  <Header>Selected clusters</Header>
                  <AllCheckboxContainer>
                    <Checkbox
                      label="Select all clusters"
                      checked={areAllClustersSelected}
                      onChange={() =>
                        areAllClustersSelected
                          ? setFieldValue('selectedClusters', [])
                          : setFieldValue('selectedClusters', availableClusters)
                      }
                    />
                  </AllCheckboxContainer>
                  <Checkboxes>
                    {clustersByFcsFileId[values.selectedFileId].map(cluster => (
                      <StyledCheckbox
                        key={cluster}
                        label={cluster}
                        checked={values.selectedClusters.includes(cluster)}
                        onChange={event => {
                          const newSelectedClusters = event.target.checked
                            ? [...values.selectedClusters, cluster]
                            : values.selectedClusters.filter(
                                selectedCluster => selectedCluster !== cluster,
                              )
                          setFieldValue('selectedClusters', newSelectedClusters)
                        }}
                      />
                    ))}
                  </Checkboxes>
                </>
              )}
              {values.mode === 'reference-file' && (
                <>
                  <Header>Selected reference file</Header>
                  <Select
                    name="selectedReferenceFileId"
                    value={values.selectedReferenceFileId}
                    onChange={handleChange}
                    options={fileIds
                      .filter(fileId => fileId !== values.selectedFileId)
                      .map(fileId => ({
                        value: fileId,
                        label: fileNameById[fileId],
                      }))}
                  />
                </>
              )}
              {values.mode === 'cluster/intensity' && (
                <>
                  <Header>Color by</Header>
                  <Select
                    name="colorBy"
                    value={values.colorBy}
                    onChange={handleChange}
                    options={[
                      { value: 'cluster', label: 'Cluster' },
                      { value: 'event', label: 'Event' },
                    ]}
                  />
                </>
              )}
              {values.mode === 'cluster/intensity' && (
                <>
                  <Header>Selected channel</Header>
                  <Select
                    name="selectedChannel"
                    value={values.selectedChannel}
                    onChange={handleChange}
                    options={channels.map(channel => ({
                      value: channel,
                      label: channel,
                    }))}
                  />
                </>
              )}
            </Options>
            <Buttons>
              <StyledButton type="submit">
                {mode === 'edit' ? 'Apply' : 'Create'}
              </StyledButton>
              <StyledButton onClick={onCancel}>Cancel</StyledButton>
            </Buttons>
          </MetaAnalysisDimensionalityReductionChartOptionsRoot>
        )
      }}
    </Formik>
  )
}

const MetaAnalysisDimensionalityReductionChartOptionsRoot = styled.form`
  background: ${props => props.theme.colors.white};
  width: 100%;
  height: 100%;
  overflow: hidden;
  display: grid;
  grid-template-rows: 1fr auto;
`

const Options = styled.div`
  overflow-y: auto;
  overflow-x: hidden;
  padding: 12px;
`

const Buttons = styled.div`
  border-top: 1px solid ${props => props.theme.colors.primaryDark[20]};
  padding: 8px;
  display: flex;
  flex-direction: row-reverse;
`

const StyledButton = styled(Button)`
  height: 16px;
  font-size: 12px;

  :first-of-type {
    margin-left: 8px;
  }
`

const Header = styled.p`
  font-family: ${props => props.theme.font.style.bold};
`

const Checkboxes = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 2px;
  padding: 8px;
  padding-bottom: 16px;
`

const StyledCheckbox = styled(({ className, ...props }: CheckboxProps) => (
  <Checkbox containerClassName={className} {...props} />
))`
  width: 100%;
  overflow: hidden;
`

const AllCheckboxContainer = styled.div`
  border-bottom: 1px solid ${props => props.theme.colors.primaryDark[20]};
  padding: ${props => props.theme.spacing(1)}px;
`
