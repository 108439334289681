import { ReactNode } from 'react'
import styled from 'styled-components'

import { ReactComponent as Logo } from 'assets/images/logo.svg'

import {
  selectMetaAnalysis,
  selectMetaAnalysisFile,
  selectMetaAnalysisChannels,
  selectMetaAnalysisFcsFileNameById,
  selectMetaAnalysisFcsFiles,
  selectMetaAnalysisGlobalVizFile,
  selectMetaAnalysisDimensionalityReductionMethod,
  selectMetaAnalysisVolcanoPlotFile,
  selectMetaAnalysisColors,
} from 'pages/meta-analysis/store/selectors'

import { RootState } from 'shared/store'
import { formatDateTime } from 'shared/utils/utils'

import { theme } from 'Theme'

import { InlineSvg } from '../InlineSvg'
import { renderMetaAnalysisChart } from './renderMetaAnalysisChart'

type MetaAnalysisPdfProps = {
  state: RootState
}

export const MetaAnalysisPdf = async ({
  state,
}: MetaAnalysisPdfProps): Promise<ReactNode> => {
  const metaAnalysis = selectMetaAnalysis(state)
  const metaAnalysisFile = selectMetaAnalysisFile(state)
  const channels = selectMetaAnalysisChannels(state)
  const fileNameById = selectMetaAnalysisFcsFileNameById(state)
  const fcsFiles = selectMetaAnalysisFcsFiles(state)
  const globalVizFile = selectMetaAnalysisGlobalVizFile(state)
  const dimensionalityReductionMethod =
    selectMetaAnalysisDimensionalityReductionMethod(state)
  const volcanoPlotFile = selectMetaAnalysisVolcanoPlotFile(state)
  const colors = selectMetaAnalysisColors(state)

  const CHART_WIDTH = 350
  const CHART_HEIGHT = 400
  const svgs = await Promise.all(
    metaAnalysis.graphs.map(chart =>
      renderMetaAnalysisChart({
        chart,
        metaAnalysisFile,
        channels,
        fileNameById,
        fcsFiles,
        globalVizFile,
        dimensionalityReductionMethod,
        volcanoPlotFile,
        theme,
        width: CHART_WIDTH,
        height: CHART_HEIGHT,
        colors,
      }),
    ),
  )

  const CHARTS_PER_PAGE = 4
  const numberOfPages = Math.ceil(svgs.length / CHARTS_PER_PAGE)
  const groupedSvgs = Array.from({ length: numberOfPages }, (_, index) =>
    svgs.slice(index * CHARTS_PER_PAGE, (index + 1) * CHARTS_PER_PAGE),
  )

  return (
    <MetaAnalysisPdfRoot>
      {groupedSvgs.map((svgs, index) => (
        <Page>
          <Header>
            <Logo />
            <Infos>
              <Info>
                <InfoValue>{metaAnalysis.name}</InfoValue>
                <InfoLabel>Meta-analysis name</InfoLabel>
              </Info>
              <Info>
                <InfoValue>{formatDateTime(metaAnalysis.created_at)}</InfoValue>
                <InfoLabel>Meta-analysis created</InfoLabel>
              </Info>
            </Infos>
          </Header>
          <ChartGrid>
            {svgs.map((svg, index) => {
              return (
                <ChartBox key={index}>
                  <InlineSvg
                    src={svg}
                    width={CHART_WIDTH}
                    height={CHART_HEIGHT}
                  />
                </ChartBox>
              )
            })}
          </ChartGrid>
          <Footer>
            <div>{formatDateTime(new Date())}</div>
            <div>
              Page {index + 1} of {numberOfPages}
            </div>
          </Footer>
        </Page>
      ))}
    </MetaAnalysisPdfRoot>
  )
}

const MetaAnalysisPdfRoot = styled.div``

const Page = styled.div`
  width: 100vw;
  height: 100vh;
  background: white;
  page-break-after: always;
  display: grid;
  grid-template-rows: auto 1fr auto;
  gap: 16px;
  overflow: hidden;
`

const Header = styled.div`
  display: flex;
  justify-content: space-between;
`

const Infos = styled.div`
  display: flex;
  gap: 8px;
`

const Info = styled.div`
  display: flex;
  flex-direction: column;
`

const InfoLabel = styled.label`
  font-size: 10px;
  color: ${props => props.theme.colors.primaryDark[70]};
`

const InfoValue = styled.span`
  font-family: ${props => props.theme.font.style.bold};
  font-size: 15px;
  font-weight: bold;
`

const ChartGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: repeat(2, 1fr);
  grid-gap: 12px;
`

const ChartBox = styled.div`
  break-inside: avoid;
  display: flex;
  place-items: center;
  border-radius: ${props => props.theme.radius[2]}px;
  border: 1px solid ${props => props.theme.colors.primaryDark[20]};
  background-color: ${props => props.theme.colors.white};
  box-shadow: ${props => props.theme.shadow[1]};
  padding: 12px;
  overflow: hidden;
  width: 100%;
  height: 100%;
`

const Footer = styled.footer`
  display: flex;
  justify-content: space-between;
`
