import { lineLength } from 'geometric'

export const checkIfLinesAreNearColinear = ({
  start,
  middle,
  end,
  threshold = 0.01,
}: {
  start: [number, number]
  middle: [number, number]
  end: [number, number]
  threshold?: number
}): boolean => {
  const angle = Math.acos(
    (Math.pow(lineLength([start, middle]), 2) +
      Math.pow(lineLength([middle, end]), 2) -
      Math.pow(lineLength([start, end]), 2)) /
      (2 * lineLength([start, middle]) * lineLength([middle, end])),
  )
  return Math.abs(angle) < threshold
}
