import { CircularProgress, Typography } from '@material-ui/core'
import React, { useCallback } from 'react'
import { useHistory } from 'react-router-dom'
import styled from 'styled-components'

import { Button } from 'components/Button'
import { Modal } from 'components/Modal'

import { usePurchaseCustomMutation } from 'shared/api/license-package.api'
import { handleError } from 'shared/utils/errorHandler'

type CustomPackageModalProps = {
  onClose: () => void
}

export const CustomPackageModal = ({
  onClose,
}: CustomPackageModalProps): JSX.Element => {
  const history = useHistory()
  const [purchaseCustom, { data, isSuccess, isLoading, error, reset }] =
    usePurchaseCustomMutation()

  const handleSend = useCallback(async () => {
    try {
      await purchaseCustom()
    } catch (error) {
      handleError(error)
    }
  }, [purchaseCustom])

  return (
    <Modal open onClose={onClose}>
      <CustomPackageModalRoot>
        {isLoading ? (
          <ProgressContainer>
            <CircularProgress />
          </ProgressContainer>
        ) : (
          <>
            <Typography variant="h3" align="center">
              Enterprise
            </Typography>
            {error ? (
              <>
                <Typography variant="body1" align="center">
                  Oops, something went wrong when sending the contact request.
                  Try again.
                </Typography>
                <ButtonsContainer>
                  <Button grey onClick={onClose}>
                    Cancel
                  </Button>
                  <Button onClick={reset}>Retry</Button>
                </ButtonsContainer>
              </>
            ) : isSuccess && data ? (
              <>
                <Typography variant="body1" align="center">
                  <b>Success!</b>
                  <br />
                  We have received your contact request and will reply to{' '}
                  <b>{data.email_operator}</b>.<br />
                  You will also receive a confirmation email.
                </Typography>
                <ButtonsContainer>
                  <Button onClick={() => history.push('/license-wallet')}>
                    Return
                  </Button>
                </ButtonsContainer>
              </>
            ) : (
              <>
                <Typography variant="body1" align="center">
                  Click "Send" to let us know you need a custom license tailored
                  to your needs.
                  <br />
                  Our team will contact you with an email to the address used in
                  your Metaflow account.
                </Typography>
                <ButtonsContainer>
                  <Button grey onClick={onClose}>
                    Cancel
                  </Button>
                  <Button color="secondary" onClick={handleSend}>
                    Send
                  </Button>
                </ButtonsContainer>
              </>
            )}
          </>
        )}
      </CustomPackageModalRoot>
    </Modal>
  )
}

const CustomPackageModalRoot = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  width: 600px;
  height: 300px;
  padding: 20px 40px;
  background: ${props => props.theme.colors.white};
  border-radius: 20px;
`

const ButtonsContainer = styled.div`
  display: flex;
  gap: 16px;
`
const ProgressContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
`
