export const AnalysisSunburstBaseOptions: Highcharts.Options = {
  chart: {
    allowMutatingData: false,
  },
  boost: {
    seriesThreshold: 1,
  },
  tooltip: {
    enabled: false,
  },
  drilldown: {
    breadcrumbs: {
      position: {
        align: 'right',
      },
    },
  },
  title: {
    text: undefined,
  },
}
