import { createSlice, PayloadAction } from '@reduxjs/toolkit'

type ProjectPageUiState = {
  fcsFileBeingUploadedIds: string[]
  spilloverFileBeingUploadedIds: string[]
}

const initialState: ProjectPageUiState = {
  fcsFileBeingUploadedIds: [],
  spilloverFileBeingUploadedIds: [],
}

export const projectPageUiSlice = createSlice({
  name: 'project-page-ui',
  initialState,
  reducers: {
    addFcsFileBeingUploaded: (state, { payload }: PayloadAction<string>) => {
      state.fcsFileBeingUploadedIds.push(payload)
    },
    removeFcsFileBeingUploaded: (state, { payload }: PayloadAction<string>) => {
      state.fcsFileBeingUploadedIds = state.fcsFileBeingUploadedIds.filter(
        id => id !== payload,
      )
    },
    addSpilloverFileBeingUploaded: (
      state,
      { payload }: PayloadAction<string>,
    ) => {
      state.spilloverFileBeingUploadedIds.push(payload)
    },
    removeSpilloverFileBeingUploaded: (
      state,
      { payload }: PayloadAction<string>,
    ) => {
      state.spilloverFileBeingUploadedIds =
        state.spilloverFileBeingUploadedIds.filter(id => id !== payload)
    },
  },
})

export const {
  addFcsFileBeingUploaded,
  removeFcsFileBeingUploaded,
  addSpilloverFileBeingUploaded,
  removeSpilloverFileBeingUploaded,
} = projectPageUiSlice.actions
