import { MenuItem as MuiMenuItem } from '@material-ui/core'
import styled from 'styled-components'

export const MenuItem = styled(MuiMenuItem)<{ $color?: string }>`
  min-width: 100px;
  min-height: 36px;
  padding: 4px 12px;
  font-size: ${props => props.theme.font.size.smallest}px;
  color: ${props =>
    props.$color
      ? props.theme.colors[props.$color]
      : props.theme.colors.primaryDark[100]};

  &:not(:last-child) {
    border-bottom: 1px solid ${props => props.theme.colors.greyscale[10]};
  }
`
