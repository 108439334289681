import { FC, ReactNode, useState } from 'react'
import styled from 'styled-components'

import { SearchInput } from 'components/input/SearchInput'

import { TableFilter } from './TableFilter'

export type BaseMultipleSelectFilterProps = {
  title: string
  placeholder: string
  options: { id: string; label: ReactNode }[]
  selectedIds: string[]
  children?: ReactNode
  onSelect: (id: string) => void
  onSearchValueChange: (value: string) => void
}

export const BaseMultipleSelectFilter: FC<BaseMultipleSelectFilterProps> = ({
  title,
  placeholder,
  options,
  selectedIds,
  children,
  onSelect,
  onSearchValueChange,
}) => {
  const [isFilterExpanded, setFilterExpanded] = useState(false)

  return (
    <TableFilter
      title={title}
      isSelected={selectedIds.length > 0}
      isExpanded={isFilterExpanded}
      onButtonClick={() => setFilterExpanded(true)}
      onClickAway={() => setFilterExpanded(false)}
    >
      <SearchInput placeholder={placeholder} onChange={onSearchValueChange} />
      <FilterResults>
        {options.map(option => (
          <FilterResultRow key={option.id} onClick={() => onSelect(option.id)}>
            <span>{option.label}</span>
            <span>{selectedIds.includes(option.id) && '✓'}</span>
          </FilterResultRow>
        ))}
      </FilterResults>
      {children}
    </TableFilter>
  )
}

const FilterResults = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: ${props => props.theme.spacing(2)}px;
  padding: 0 ${props => props.theme.spacing(1)}px;
`

const FilterResultRow = styled.div`
  display: flex;
  justify-content: space-between;
  padding: ${props => props.theme.spacing(1)}px;
  border-bottom: 1px solid ${props => props.theme.colors.greyscale[20]};
  color: ${props => props.theme.colors.primaryDark[70]};
  font-family: ${props => props.theme.font.style.bold};
  cursor: pointer;

  &:hover {
    color: ${props => props.theme.colors.primaryDark[100]};
  }
`
