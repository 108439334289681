import { Tooltip, TooltipProps } from '@material-ui/core'
import { useState } from 'react'
import styled from 'styled-components'

import {
  ClusterTooltipContent,
  ClusterTooltipContentProps,
} from './ClusterTooltipContent'

type ClusterTooltipProps = ClusterTooltipContentProps &
  Pick<TooltipProps, 'children'> & {
    disabled?: boolean
  }

export const ClusterTooltip = ({
  cluster,
  lassos,
  children,
  disabled,
}: ClusterTooltipProps): JSX.Element => {
  const [isOpen, setIsOpen] = useState(false)

  return (
    <StyledTooltip
      title={<ClusterTooltipContent cluster={cluster} lassos={lassos} />}
      enterDelay={500}
      TransitionProps={{ timeout: 0 }}
      open={isOpen && !disabled}
      onClose={() => setIsOpen(false)}
      onOpen={() => setIsOpen(true)}
      placement="bottom"
    >
      {children}
    </StyledTooltip>
  )
}

const StyledTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip
    classes={{
      popper: className,
      tooltip: 'tooltip',
    }}
    {...props}
  />
))`
  padding: 0;

  & .tooltip {
    overflow: hidden;
    background-color: white;
    color: unset;
    padding: 0;

    border: 1px solid ${props => props.theme.colors.primaryDark[20]};
    box-shadow: ${props => props.theme.shadow.tooltip};
    border-radius: ${props => props.theme.radius[2]}px;
  }
`
