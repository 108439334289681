import { FC, useState } from 'react'
import styled from 'styled-components'

import { Button } from 'components/button/Button'

import { useEventCallback } from 'shared/hooks/useEventCallback'

import { TableFilter } from './TableFilter'

type SelectFilterProps = {
  title: string
  options: { id: string; label: string }[]
  selectedId: string | undefined
  onSelect: (id: string | undefined) => void
}

export const SelectFilter: FC<SelectFilterProps> = ({
  title,
  options,
  selectedId,
  onSelect,
}) => {
  const [isFilterExpanded, setFilterExpanded] = useState(false)

  const handleClear = useEventCallback(() => {
    onSelect(undefined)
    setFilterExpanded(false)
  })

  const handleSelectOption = (id: string) => {
    onSelect(id)
    setFilterExpanded(false)
  }

  return (
    <TableFilter
      title={title}
      isSelected={!!selectedId}
      isExpanded={isFilterExpanded}
      onButtonClick={() => setFilterExpanded(true)}
      onClickAway={() => setFilterExpanded(false)}
    >
      <FilterOptions>
        {options.map(option => (
          <FilterOption
            key={option.id}
            onClick={() => handleSelectOption(option.id)}
          >
            <span>{option.label}</span>
            <span>{selectedId === option.id && '✓'}</span>
          </FilterOption>
        ))}
      </FilterOptions>
      <ButtonContainer>
        <Button colorOverride="greyscale" onClick={handleClear}>
          Clear ×
        </Button>
      </ButtonContainer>
    </TableFilter>
  )
}

const FilterOptions = styled.div`
  min-width: 200px;
  display: flex;
  flex-direction: column;
  margin-top: ${props => props.theme.spacing(2)}px;
  padding: 0 ${props => props.theme.spacing(1)}px;
`

const FilterOption = styled.div`
  display: flex;
  justify-content: space-between;
  padding: ${props => props.theme.spacing(1)}px;
  border-bottom: 1px solid ${props => props.theme.colors.greyscale[20]};
  color: ${props => props.theme.colors.primaryDark[70]};
  font-family: ${props => props.theme.font.style.bold};
  cursor: pointer;

  &:hover {
    color: ${props => props.theme.colors.primaryDark[100]};
  }
`

const ButtonContainer = styled.div`
  display: flex;
  justify-content: center;
  margin-top: ${props => props.theme.spacing(2)}px;
`
