import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { random } from 'lodash'

import { RootState } from '.'

type NotificationSliceState = {
  notifications: Notification[]
}

type Notification = {
  id: string
  description: string
  type: NotificationType
  autoHideDuration: number
  extraActions?: FileDownloadNotificationActionType[]
}

export type FileDownloadNotificationActionType = {
  type: 'download-file'
  file_url: string
}

export type NotificationType = 'error' | 'warning' | 'info' | 'success'

const DEFAULT_AUTO_HIDE_DURATION = 5

const initialState: NotificationSliceState = {
  notifications: [],
}

export const notificationSlice = createSlice({
  name: 'notification',
  initialState,
  reducers: {
    showNotification: (
      state,
      action: PayloadAction<{
        type: NotificationType
        description: string
        autoHideDuration?: number
        extraActions?: FileDownloadNotificationActionType[]
      }>,
    ) => {
      state.notifications.push({
        id: `${action.payload.type}-${Date.now()}-${random()}`,
        description: action.payload.description,
        type: action.payload.type,
        autoHideDuration:
          action.payload.autoHideDuration ?? DEFAULT_AUTO_HIDE_DURATION,
        extraActions: action.payload.extraActions,
      })
    },
  },
})

export const { showNotification } = notificationSlice.actions

export const selectNotifications = (
  state: RootState,
): NotificationSliceState['notifications'] => state.notification.notifications
