import { CircularProgress } from '@material-ui/core'
import React, { FC, ReactNode } from 'react'
import styled from 'styled-components'

type LoadingMaskProps = {
  children?: ReactNode
  isFirstLoad?: boolean
}

export const LoadingMask: FC<LoadingMaskProps> = ({
  children,
  isFirstLoad,
}) => {
  return (
    <Container>
      <Background firstLoad={isFirstLoad} />
      <Content>
        <StyledCircularProgress size={isFirstLoad ? 20 : 40} />
        {children}
      </Content>
    </Container>
  )
}

const Container = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
`

const Background = styled.div<{ firstLoad?: boolean }>`
  position: absolute;
  z-index: 1;
  width: 100%;
  height: 100%;
  background: ${props =>
    props.firstLoad ? 'rgba(255, 255, 255, 0.8)' : 'rgba(255, 255, 255, 0.4)'};
  filter: blur(8px);
`
const Content = styled.div`
  position: absolute;
  z-index: 2;
  width: 100%;
  height: 100%;
  background: transparent;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: ${props => props.theme.colors.primaryDark[100]};
`
const StyledCircularProgress = styled(CircularProgress)`
  margin-bottom: 10px;
`
