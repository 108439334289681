import { GridStackEngine, GridStackMoveOpts, GridStackNode } from 'gridstack'

import { ADD_CHART_PLACEHOLDER_ID, MULTI_TAB_MENU_ID } from 'shared/constants'

export class CustomGridStackEngine extends GridStackEngine {
  static customBehaviorEnabled = true

  override compact(): GridStackEngine {
    if (!CustomGridStackEngine.customBehaviorEnabled) {
      return super.compact()
    }

    const addChartPlaceholderNode = this.getAddChartPlaceholderNode()
    const multiTabMenuNode = this.getMultiTabMenuNode()

    multiTabMenuNode && this.removeNode(multiTabMenuNode, false, false)
    addChartPlaceholderNode &&
      this.removeNode(addChartPlaceholderNode, false, false)
    super.compact()
    multiTabMenuNode &&
      this.addNode({ ...multiTabMenuNode, x: undefined, y: undefined }, false)
    addChartPlaceholderNode &&
      this.addNode(
        { ...addChartPlaceholderNode, x: undefined, y: undefined },
        false,
      )

    return this
  }

  override moveNode(node: GridStackNode, options: GridStackMoveOpts): boolean {
    if (!CustomGridStackEngine.customBehaviorEnabled) {
      return super.moveNode(node, options)
    }

    const moved = super.moveNode(node, options)
    if (moved && !options.nested) {
      this.compact()
    }
    return moved
  }

  private getAddChartPlaceholderNode() {
    return this.nodes.find(node => node.id === ADD_CHART_PLACEHOLDER_ID)
  }

  private getMultiTabMenuNode() {
    return this.nodes.find(node => node.id === MULTI_TAB_MENU_ID)
  }
}
