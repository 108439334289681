import { TooltipFormatterCallbackFunction } from 'highcharts'
import {
  forwardRef,
  useImperativeHandle,
  useMemo,
  useRef,
  useState,
} from 'react'
import { renderToStaticMarkup } from 'react-dom/server'
import styled, { useTheme } from 'styled-components'

import { DefaultErrorBoundary } from 'components/DefaultErrorBoundary'
import { Modal } from 'components/Modal'
import { Chart } from 'components/graphs/Chart'

import {
  MetaAnalysisColors,
  MetaAnalysisGlobalHeatMapChartType,
} from 'shared/api/meta-analysis.api'
import { useEventCallback } from 'shared/hooks/useEventCallback'
import { useAppDispatch, useAppSelector } from 'shared/store'
import { downloadText, useSize } from 'shared/utils/utils'

import { MetaAnalysisChartContainer } from './MetaAnalysisChartContainer'
import { MetaAnalysisGlobalHeatMapChartBaseOptions } from './MetaAnalysisGlobalHeatMapChartBaseOptions'
import { MetaAnalysisGlobalHeatMapChartOptions } from './MetaAnalysisGlobalHeatMapChartOptions'
import { computeGlobalHeatMapSeries } from './series'
import { updateMetaAnalysisChart } from './store/meta-analysis.history.slice'
import {
  selectMetaAnalysisFcsFileNameById,
  selectMetaAnalysisFile,
  selectMetaAnalysisName,
} from './store/selectors'
import { useMetaAnalysisCsvMetadata } from './useMetaAnalysisCsvMetadata'
import { useNavigateToFile } from './useNavigateToFile'

type MetaAnalysisGlobalHeatMapChartProps = {
  chart: MetaAnalysisGlobalHeatMapChartType
  colors: MetaAnalysisColors
  isExpanded?: boolean
  className?: string
  onCloseExpand?: () => void
}

export const MetaAnalysisGlobalHeatMapChart = ({
  chart,
  colors,
  isExpanded,
  className,
  onCloseExpand,
}: MetaAnalysisGlobalHeatMapChartProps): JSX.Element => {
  const dispatch = useAppDispatch()

  const [ref, { width, height }] = useSize<HTMLDivElement>({ debounce: 250 })
  const innerPlotRef = useRef<InnerPlotInstance>(null)

  const [shouldDisplayChartOptions, setShouldDisplayChartOptions] =
    useState(false)
  const [shouldShowExpandedSelf, setShouldShowExpandedSelf] = useState(false)

  const handleChangeOptions = useEventCallback(() => {
    setShouldDisplayChartOptions(true)
  })

  const handleCancel = useEventCallback(() => {
    setShouldDisplayChartOptions(false)
  })

  const handleApplyOptions = useEventCallback(
    (fileIds: string[], clusters: string[]) => {
      dispatch(
        updateMetaAnalysisChart({
          id: chart.id,
          selectedFileIds: fileIds,
          selectedClusters: clusters,
        }),
      )
      setShouldDisplayChartOptions(false)
    },
  )

  const handleDownload = useEventCallback(() => {
    innerPlotRef.current?.downloadSeries()
  })

  return (
    <MetaAnalysisChartContainer
      containerRef={ref}
      chart={chart}
      isExpanded={isExpanded}
      className={className}
      onChangeOptions={handleChangeOptions}
      onExpand={() => setShouldShowExpandedSelf(true)}
      onCloseExpand={onCloseExpand}
      onDownload={handleDownload}
    >
      {shouldDisplayChartOptions && (
        <StyledMetaAnalysisGlobalHeatMapChartOptions
          mode="edit"
          initialSelectedFileIds={chart.selectedFileIds}
          initialSelectedClusters={chart.selectedClusters}
          onCancel={handleCancel}
          onFinish={handleApplyOptions}
        />
      )}
      <DefaultErrorBoundary>
        <InnerPlot
          chart={chart}
          colors={colors}
          width={width}
          height={height}
          ref={innerPlotRef}
        />
      </DefaultErrorBoundary>
      {shouldShowExpandedSelf && (
        <Modal open onClose={() => setShouldShowExpandedSelf(false)}>
          <ExpandedMetaAnalysisGlobalHeatMapChart
            chart={chart}
            colors={colors}
            onCloseExpand={() => setShouldShowExpandedSelf(false)}
          />
        </Modal>
      )}
    </MetaAnalysisChartContainer>
  )
}

type InnerPlotProps = Pick<
  MetaAnalysisGlobalHeatMapChartProps,
  'chart' | 'colors'
> & {
  width: number
  height: number
}

type InnerPlotInstance = {
  downloadSeries: () => void
}

const InnerPlot = forwardRef<InnerPlotInstance, InnerPlotProps>(
  ({ chart, colors, width, height }, ref) => {
    const theme = useTheme()
    const navigateToFile = useNavigateToFile()

    const metaAnalysisFile = useAppSelector(selectMetaAnalysisFile)
    const fileNameById = useAppSelector(selectMetaAnalysisFcsFileNameById)
    const metaAnalysisName = useAppSelector(selectMetaAnalysisName)

    const series = useMemo(
      () => computeGlobalHeatMapSeries(chart, metaAnalysisFile, colors),
      [chart, metaAnalysisFile, colors],
    )

    const createCsvMetadata = useMetaAnalysisCsvMetadata()
    const downloadSeries = useEventCallback(async () => {
      const headerRow = 'file,' + chart.selectedClusters.join(',')
      const dataRows = chart.selectedFileIds.map(fileId => {
        const file = metaAnalysisFile.stat_files[fileId]
        return (
          fileNameById[fileId] +
          ',' +
          chart.selectedClusters
            .map(cluster => file[cluster].proportion)
            .join(',')
        )
      })
      const csv =
        (await createCsvMetadata()) + [headerRow, ...dataRows].join('\n')
      downloadText(csv, `${metaAnalysisName} - ${chart.name}.csv`)
    })
    useImperativeHandle(
      ref,
      () => ({
        downloadSeries,
      }),
      [downloadSeries],
    )

    const options: Highcharts.Options = useMemo(() => {
      const formatTooltip: TooltipFormatterCallbackFunction = function (this) {
        return renderToStaticMarkup(
          <div
            style={{
              padding: 8,
              fontSize: 13,
              minWidth: 120,
              background: theme.colors.white,
            }}
          >
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
              }}
            >
              <span style={{ fontFamily: theme.font.style.bold }}>
                {this.series.name}
              </span>
              <span
                style={{
                  color: this.point.color as string,
                  marginRight: 4,
                  fontSize: 14,
                }}
              >
                ⬤
              </span>
            </div>
            <div style={{ fontSize: 12, color: theme.colors.primaryDark[70] }}>
              {this.x}
            </div>
            <div
              style={{
                fontSize: 12,
                marginTop: 6,
                color: theme.colors.primaryDark[70],
              }}
            >
              {this.y?.toFixed(2)}% of total
            </div>
          </div>,
        )
      }

      return {
        ...MetaAnalysisGlobalHeatMapChartBaseOptions,
        chart: {
          ...MetaAnalysisGlobalHeatMapChartBaseOptions.chart,
          width,
          height,
        },
        xAxis: {
          ...MetaAnalysisGlobalHeatMapChartBaseOptions.xAxis,
          categories: chart.selectedFileIds.map(fileId => fileNameById[fileId]),
          labels: {
            ...MetaAnalysisGlobalHeatMapChartBaseOptions.xAxis.labels,
            events: {
              async click(this: { pos: number }) {
                const fcsFileId = chart.selectedFileIds[this.pos]
                navigateToFile(fcsFileId)
              },
            },
          },
        },
        series,
        tooltip: {
          ...MetaAnalysisGlobalHeatMapChartBaseOptions.tooltip,
          formatter: formatTooltip,
        },
      }
    }, [
      chart.selectedFileIds,
      fileNameById,
      height,
      navigateToFile,
      series,
      theme.colors.primaryDark,
      theme.colors.white,
      theme.font.style.bold,
      width,
    ])

    if (!width || !height) {
      return <div>Loading...</div>
    }
    return <Chart options={options} />
  },
)

const StyledMetaAnalysisGlobalHeatMapChartOptions = styled(
  MetaAnalysisGlobalHeatMapChartOptions,
)`
  position: absolute;
  top: 0;
  left: 0;
  z-index: 2;
`

const ExpandedMetaAnalysisGlobalHeatMapChart = styled(
  MetaAnalysisGlobalHeatMapChart,
).attrs({ isExpanded: true })`
  width: 90vw;
  height: 90vh;
`
