import {
  FloatingPortal,
  safePolygon,
  useDismiss,
  useFloating,
  useHover,
  useInteractions,
} from '@floating-ui/react'
import { CircularProgress } from '@material-ui/core'
import { RefObject, useState } from 'react'
import styled, { css } from 'styled-components'

import { ReactComponent as CheckIcon } from 'assets/images/icons/check-icon-inverted.svg'
import { ReactComponent as EyeIcon } from 'assets/images/icons/eye.svg'
import { ReactComponent as ErrorIcon } from 'assets/images/icons/workflow-error-status.svg'
import { ReactComponent as WorkflowSecondaryClusteringIcon } from 'assets/images/icons/workflow-secondary-clustering-icon.svg'
import { ReactComponent as WorkflowSubAnalysisIcon } from 'assets/images/icons/workflow-sub-analysis-icon.svg'

import { DEFAULT_USE_FLOATING_PROPS } from 'components/tooltip'

import { Brick } from 'shared/api/workflows.api'

import { BrickAnalysisList } from './BrickAnalysisList'
import { WorkflowNode } from './WorkflowNode'
import { WorkflowNodeLabel } from './WorkflowNodeLabel'

type WorkflowBrickNode = {
  data: {
    brick: Brick
    hasChildren: boolean
    workflowRootRef: RefObject<HTMLDivElement>
  }
}

const iconByStatus: Record<Brick['status'], React.ReactNode> = {
  Error: <ErrorIcon className="statusIcon" />,
  Pending: <CircularProgress size={12} />,
  Processing: <CircularProgress size={12} />,
  Ready: <CheckIcon className="statusIcon" />,
}

export const WorkflowBrickNode: React.FC<WorkflowBrickNode> = ({ data }) => {
  const [isOpen, setIsOpen] = useState(false)

  const { floatingStyles, refs, context } = useFloating({
    ...DEFAULT_USE_FLOATING_PROPS,
    open: isOpen,
    onOpenChange: setIsOpen,
    transform: false,
  })
  const { getReferenceProps, getFloatingProps } = useInteractions([
    useHover(context, { handleClose: safePolygon() }),
    useDismiss(context, { escapeKey: true }),
  ])

  return (
    <StyledWorkflowNode
      isLeaf={!data.hasChildren}
      isPending={data.brick.status === 'Pending'}
    >
      {data.brick.is_sub_analysis ? (
        <WorkflowSubAnalysisIcon />
      ) : data.brick.parent ? (
        <WorkflowSecondaryClusteringIcon />
      ) : null}
      <WorkflowNodeLabel tooltip={data.brick.name}>
        {data.brick.name}
      </WorkflowNodeLabel>
      {iconByStatus[data.brick.status]}
      <EyeIconContainer ref={refs.setReference} {...getReferenceProps()}>
        <EyeIcon />
      </EyeIconContainer>
      {isOpen && (
        <FloatingPortal root={data.workflowRootRef}>
          <BrickAnalysisListContainer
            ref={refs.setFloating}
            style={floatingStyles}
            onClick={event => event.stopPropagation()}
            {...getFloatingProps()}
          >
            <BrickAnalysisList
              brickId={data.brick.id}
              onClose={() => setIsOpen(false)}
              onCloseContextMenu={() => setIsOpen(false)}
            />
          </BrickAnalysisListContainer>
        </FloatingPortal>
      )}
    </StyledWorkflowNode>
  )
}

const StyledWorkflowNode = styled(WorkflowNode)<{ isPending: boolean }>`
  border: 1px solid ${props => props.theme.colors.primaryDark[20]};
  justify-content: flex-start;
  align-items: center;
  gap: 4px;

  .MuiCircularProgress-root {
    flex-shrink: 0;
    margin-left: auto;
    circle {
      stroke: #53aef6;
    }
  }

  svg {
    flex-shrink: 0;

    &.statusIcon {
      margin-left: auto;
    }
  }

  ${props =>
    props.isPending &&
    css`
      opacity: 0.5;
    `}
`

const BrickAnalysisListContainer = styled.div`
  z-index: 1301;
  box-shadow: 2px 2px 10px 2px rgba(0, 0, 0, 0.1);
  border-radius: 4px;
  overflow: hidden;
  display: grid;
  grid-template-rows: 1fr;
`

const EyeIconContainer = styled.div`
  height: 20px;
`
