import { Polygon, pointInPolygon } from 'geometric'
import { GradientColorObject, PatternObject } from 'highcharts'
import { difference, mapValues, union, uniq } from 'lodash'

import { HighlightedCluster } from 'pages/analysis/store/analysis.slice'
import { Cluster } from 'pages/analysis/store/selectors'

import { Lasso } from 'shared/models/AnalysisModels'

import { theme } from 'Theme'

import { changeAlpha, detectColorCollisions, rgbStringToValues } from './colors'

export const getMaxDepth = (clusters: { depth: number }[]): number => {
  if (!(clusters?.length > 0)) {
    return 0
  }

  return Math.max(...clusters.map(c => c.depth))
}

export function findDescendants(
  clusterId: string,
  clusterById: Record<string, Cluster>,
  includeStartCluster = false,
  breakPredicate?: (cluster: Cluster) => boolean,
): string[] {
  const cluster = clusterById[clusterId]

  if (breakPredicate?.(cluster)) {
    return []
  }

  return [
    ...(includeStartCluster ? [clusterId] : []),
    ...cluster.children.flatMap(id =>
      findDescendants(id, clusterById, true, breakPredicate),
    ),
  ]
}

export const findActiveDescendants = (
  clusterId: string,
  clusterById: Record<string, Cluster>,
): string[] => {
  return findDescendants(
    clusterId,
    clusterById,
    false,
    cluster => !cluster.isActive,
  )
}

export const findAncestors = (
  clusterId: string,
  clusterById: Record<string, { parent: string | undefined }>,
  includeStartCluster = false,
): string[] => {
  const ancestors: string[] = []

  if (includeStartCluster) {
    ancestors.push(clusterId)
  }

  let parent = clusterById[clusterId].parent
  while (parent) {
    ancestors.push(parent)
    parent = clusterById[parent].parent
  }

  return ancestors
}

export const findActiveClusters = (
  leafIds: string[],
  clusterParentIdById: Record<string, string | undefined>,
): string[] => {
  const ancestors = new Set<string>()
  for (const id of leafIds) {
    for (const ancestor of findAncestors(
      id,
      mapValues(clusterParentIdById, parent => ({
        parent,
      })),
      true,
    )) {
      ancestors.add(ancestor)
    }
  }
  return [...ancestors]
}

export const findLeaves = (
  clusterId: string,
  clusterById: Record<string, Cluster>,
  depth: number,
): string[] => {
  const cluster = clusterById[clusterId]
  if (cluster.children.length === 0 || cluster.depth === depth) {
    return [clusterId]
  }

  return uniq(
    cluster.children.flatMap(child => findLeaves(child, clusterById, depth)),
  )
}

export const findClustersToHide = (
  clusterIds: string[],
  clusterById: Record<string, Cluster>,
): string[] => {
  return clusterIds.reduce((acc, clusterId) => {
    return union(acc, findDescendants(clusterId, clusterById, true))
  }, [] as string[])
}

export const findClustersToShow = (
  clusterId: string,
  clusterById: Record<string, Cluster>,
): string[] => {
  return [
    ...findAncestors(clusterId, clusterById),
    clusterId,
    ...findDescendants(clusterId, clusterById),
  ]
}

export const computeHiddenClusters = (
  currentHiddenClusters: string[],
  options: { clustersToShow: string[] } | { clustersToHide: string[] },
): string[] => {
  if ('clustersToShow' in options) {
    return currentHiddenClusters.filter(
      clusterId => !options.clustersToShow.includes(clusterId),
    )
  } else {
    return union(currentHiddenClusters, options.clustersToHide)
  }
}

export const checkIfClusterIsInsideLasso = (
  cluster: Pick<Cluster, 'stats' | 'isHidden'>,
  lassoPolygon: [number, number][],
  xAxis: string,
  yAxis: string,
): boolean => {
  const densityCenter = [
    cluster.stats.median[xAxis],
    cluster.stats.median[yAxis],
  ] as [number, number]

  return !cluster.isHidden && pointInPolygon(densityCenter, lassoPolygon)
}

export const findLowestCommonAncestor = (
  clusterIds: string[],
  clusterById: Record<string, Cluster>,
): Cluster | undefined => {
  const ancestorArrays: string[][] = []
  for (const clusterId of clusterIds) {
    ancestorArrays.push(findAncestors(clusterId, clusterById))
  }
  for (const ancestorId of ancestorArrays[0]) {
    if (
      ancestorArrays.every(ancestorArray => ancestorArray.includes(ancestorId))
    ) {
      return clusterById[ancestorId]
    }
  }
  return undefined
}

export const findLowestCommonAncestorInLasso = (
  clusterIds: string[],
  clusterById: Record<string, Cluster>,
  polygon: Polygon,
  xAxis: string,
  yAxis: string,
): { cluster: Cluster; isInLasso: boolean } | undefined => {
  const ancestor = findLowestCommonAncestor(clusterIds, clusterById)
  if (!ancestor) {
    return undefined
  } else if (checkIfClusterIsInsideLasso(ancestor, polygon, xAxis, yAxis)) {
    return { cluster: ancestor, isInLasso: true }
  } else {
    return { cluster: ancestor, isInLasso: false }
  }
}

export const mergeSelectedClusters = ({
  selectedClusterIds,
  isExclusive,
  clusterById,
  activeLeafIds,
}: {
  selectedClusterIds: string[]
  isExclusive: boolean | undefined
  clusterById: Record<string, Cluster>
  activeLeafIds: string[]
}): string[] => {
  let newActiveLeafIds = activeLeafIds
  const parentIds = uniq(
    selectedClusterIds.map(leafId => clusterById[leafId].parent ?? leafId),
  )

  for (const parentId of parentIds) {
    const childrenIds = clusterById[parentId].children
    const areAllChildrenActive = childrenIds.every(childId =>
      activeLeafIds.includes(childId),
    )
    const areAllChildrenSelected = childrenIds.every(childId =>
      selectedClusterIds.includes(childId),
    )

    if (!areAllChildrenActive || (isExclusive && !areAllChildrenSelected)) {
      continue
    }

    newActiveLeafIds = [parentId, ...difference(newActiveLeafIds, childrenIds)]
  }

  return newActiveLeafIds
}

export function computeClusterColor(
  cluster: Cluster,
  globallyHiddenClusters: Set<string>,
  highlightedCluster: HighlightedCluster | undefined,
): string | GradientColorObject | PatternObject {
  if (globallyHiddenClusters.has(cluster.id) && cluster.isLeaf) {
    return {
      pattern: {
        path: {
          d: 'M 0 0 L 10 10 M 9 -1 L 11 1 M -1 9 L 1 11',
          strokeWidth: 3,
        },
        color: theme.colors.greyscale[30],
        backgroundColor: cluster.color,
        width: 10,
        height: 10,
        aspectRatio: 1,
        image: '',
        opacity: 1,
        patternTransform: '',
      },
    }
  } else if (
    // we lower the opacity if :
    // - there is an ongoing highlighting of a cluster
    // - a cluster is not currently active
    (highlightedCluster !== undefined &&
      highlightedCluster.clusterId !== cluster.id) ||
    !cluster.isLeaf
  ) {
    return changeAlpha(cluster.color, 0.2)
  } else {
    return cluster.color
  }
}

const detectClusterColorCollisions = (
  clusters: Cluster[],
  clusterId: string,
  color: string,
): boolean => {
  const DELTA = 5 // default DELTA value for cluster color change

  const clusterColors = clusters
    .filter(cluster => cluster.id !== clusterId)
    .map(cluster => cluster.color)

  return detectColorCollisions(color, clusterColors, DELTA)
}

export const validateClusterColor = (
  color: string,
  clusters: Cluster[],
  clusterId: string,
): string | undefined => {
  const RGB_THRESHOLD = 180
  const [r, g, b] = rgbStringToValues(color)
  if (r > RGB_THRESHOLD && g > RGB_THRESHOLD && b > RGB_THRESHOLD) {
    return 'The color selected is too bright, please select a darker color.'
  }

  if (detectClusterColorCollisions(clusters, clusterId, color)) {
    return 'A similar color is already selected for one of the clusters, please choose another color.'
  }
}

type UpdateClusterTreeProps = {
  clusterTree: Analysis.ClusterTree
  activeClusterIds: string[]
  clusterById: Record<string, Cluster>
  lassoById: Record<string, Lasso>
}

export const updateClusterTree = ({
  clusterTree,
  activeClusterIds,
  clusterById,
  lassoById,
}: UpdateClusterTreeProps): void => {
  const traverseClusterTree = (tree: Analysis.ClusterTree) => {
    for (const [id, node] of Object.entries(tree)) {
      node.is_hidden = !activeClusterIds.includes(id)
      node.default_label = clusterById[id].defaultLabel
      node.lassos = clusterById[id].lassoIds.map(lassoId => ({
        id: lassoId,
        name: lassoById[lassoId].name,
      }))
      traverseClusterTree(node.children)
    }
  }
  traverseClusterTree(clusterTree)
}
