import styled, { css } from 'styled-components'

export const LayoutItemCard = styled.div<{
  $isSelected?: boolean
  $isExpanded?: boolean
}>`
  display: grid;
  width: 100%;
  height: 100%;
  overflow: hidden;
  border-radius: ${props => props.theme.radius[2]}px;
  border: ${props =>
    props.$isSelected && !props.$isExpanded
      ? `1px solid ${props.theme.colors.primary[100]}`
      : `1px solid ${props.theme.colors.primaryDark[20]}`};
  transition: border 200ms ease;
  background-color: ${props => props.theme.colors.white};
  padding: 12px;

  ${props =>
    !props.$isExpanded &&
    css`
      box-shadow: ${props => props.theme.shadow[1]};
    `}
`
