import {
  getAnalysisStoreName,
  selectActiveClusterIds,
  selectActiveLeaves,
  selectAnalysis,
  selectAnalysisClusterTree,
  selectAnalysisStatistics,
  selectAnalysisStoreName,
  selectCharts,
  selectClusterById,
  selectHeatmapColors,
  selectLayout,
  selectSortedSelectedChannels,
} from 'pages/analysis/store/selectors'

import {
  LOCAL_FORAGE_RAW_LEAF_LABELS_KEY,
  LOCAL_FORAGE_RAW_TRANSFORMED_DATA_KEY,
  LOCAL_FORAGE_SCALES_KEY,
} from 'shared/constants'
import { LogActions } from 'shared/contexts/LogContext'
import { Analysis, AnalysisStatus } from 'shared/models/AnalysisModels'
import type { RootState } from 'shared/store'
import { convertWorkflowModeToWorkflowEntity } from 'shared/utils/api'
import { updateClusterTree } from 'shared/utils/clusters.utils'
import { getStoredScales, loadClusteringFile } from 'shared/utils/storage'
import { analysisWorker } from 'shared/worker'

import {
  APPLICATION_INSTANCE_ID_HEADER_NAME,
  CSRF_TOKEN_REQUEST_HEADER_NAME,
  DONT_SET_HEADER_VALUE,
} from './cookies'
import { ExperimentDetails } from './experiments.api'
import { CompensatedFile, FcsFile } from './files.api'
import { logbookApi } from './logbook.api'
import { PipelineDetails } from './pipelines.api'
import { privateApi } from './private.api'
import { Project } from './projects.api'
import { encodeTagParameters } from './utils'
import { BrickDetails, WorkflowDetails } from './workflows.api'

export const analysisApi = privateApi.injectEndpoints({
  endpoints: builder => ({
    getAnalysis: builder.query<GetAnalysisResult, string>({
      providesTags: (_result, _error, id) => [
        { type: 'Analysis', id: encodeTagParameters({ id }) },
      ],
      queryFn: async (analysisId, _api, _extraOptions, baseQuery) => {
        const storeName = getAnalysisStoreName(analysisId)

        const analysisResult = await baseQuery(`/analysis/${analysisId}/`)
        if (analysisResult.error) {
          return analysisResult
        }
        const analysis = analysisResult.data as Analysis

        const compensatedFileResult = await baseQuery(
          `/compensated-files/${analysis.compensated_file}/`,
        )
        if (compensatedFileResult.error) {
          return compensatedFileResult
        }
        const compensatedFile = compensatedFileResult.data as CompensatedFile

        const fcsFileResult = await baseQuery(
          `/fcs-files/${compensatedFile.fcs_file}/`,
        )
        if (fcsFileResult.error) {
          return fcsFileResult
        }
        const fcsFile = fcsFileResult.data as FcsFile

        const brickResult = await baseQuery(`/bricks/${analysis.brick}/`)
        if (brickResult.error) {
          return brickResult
        }
        const brick = brickResult.data as BrickDetails

        const workflowResult = await baseQuery(
          `/${convertWorkflowModeToWorkflowEntity(analysis.workflow_mode)}s/${
            analysis.workflow_id
          }/`,
        )
        if (workflowResult.error) {
          return workflowResult
        }
        const workflow = workflowResult.data as
          | WorkflowDetails
          | PipelineDetails
          | ExperimentDetails

        const projectResult = await baseQuery(`/projects/${fcsFile.project}/`)
        if (projectResult.error) {
          return projectResult
        }
        const project = projectResult.data as Project

        const analysisFilesUrlsResult = await baseQuery(
          `/analysis/${analysisId}/file_contents_urls/`,
        )
        if (analysisFilesUrlsResult.error) {
          return analysisFilesUrlsResult
        }

        const urls = analysisFilesUrlsResult.data as Analysis.DataSignedUrls

        const leafLabelsDownsampleResult = await loadClusteringFile({
          storeName,
          key: LOCAL_FORAGE_RAW_LEAF_LABELS_KEY,
          url: urls.leaf_labels_downsample,
          baseQuery,
        })
        if (leafLabelsDownsampleResult.error) {
          return leafLabelsDownsampleResult
        }

        const transformedDownsampleResult = await loadClusteringFile({
          storeName,
          key: LOCAL_FORAGE_RAW_TRANSFORMED_DATA_KEY,
          url: urls.transformed_downsample,
          baseQuery,
        })
        if (transformedDownsampleResult.error) {
          return transformedDownsampleResult
        }

        const scalesResult = await loadClusteringFile({
          storeName,
          key: LOCAL_FORAGE_SCALES_KEY,
          url: urls.scale,
          baseQuery,
        })
        if (scalesResult.error) {
          return scalesResult
        }

        analysisWorker.loadClusteringFiles(storeName, analysis.cluster_tree)

        return {
          data: {
            analysis,
            brick,
            workflow,
            compensatedFile,
            fcsFile,
            project,
            scales: JSON.parse(scalesResult.data) as Analysis.Scales,
          },
        }
      },
    }),
    getAnalysisById: builder.query<Record<string, Analysis>, string[]>({
      queryFn: async (analysisIds, _api, _extraOptions, baseQuery) => {
        const analysisById: Record<string, Analysis> = {}
        for (const analysisId of analysisIds) {
          const result = await baseQuery(`/analysis/${analysisId}/`)
          if (result.error) {
            return result
          }
          analysisById[analysisId] = result.data as Analysis
        }
        return {
          data: analysisById,
        }
      },
    }),
    saveAnalysis: builder.mutation<SaveAnalysisResult, void>({
      queryFn: async (
        _args,
        { getState, dispatch },
        _extraOptions,
        baseQuery,
      ) => {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const state = getState() as any
        const analysis = selectAnalysis(state)
        const storeName = selectAnalysisStoreName(state)

        const saveAnalysisResult = await baseQuery({
          url: `/analysis/${analysis.id}/?analysis_done=true`,
          method: 'PUT',
          body: getAnalysisToSave(state),
        })
        if (saveAnalysisResult.error) {
          return saveAnalysisResult
        }
        const savedAnalysis = saveAnalysisResult.data as Analysis

        dispatch(
          logbookApi.endpoints.createEntries.initiate({
            analysisId: analysis.id,
            entries: [
              ...state.analysisPage.analysis.history.present.logs,
              {
                type: LogActions.l_analysis_saved,
                created_at: new Date().toISOString(),
              },
            ],
          }),
        )

        return {
          data: {
            analysis: savedAnalysis,
            scales: await getStoredScales(storeName),
          },
        }
      },
    }),
    startStatisticsComputationRequest: builder.mutation<
      StartStatisticsComputationRequestResult,
      Analysis.Statistics
    >({
      queryFn: async (statistics, { getState }, _extraOptions, baseQuery) => {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const state = getState() as any

        const result = await baseQuery({
          url: `/statistic/calculate_request/`,
          method: 'POST',
          body: {
            analysis: getAnalysisToSave(state),
            statistics,
          },
        })

        if (result.error) {
          return result
        }

        return {
          data: result.data as StartStatisticsComputationRequestResult,
        }
      },
    }),
    getStatisticsComputationResponse: builder.query<
      GetStatisticsComputationResponse,
      GetStatisticsComputationPayload
    >({
      queryFn: async (payload, _api, _extraOptions, baseQuery) => {
        const computatationResponse = await baseQuery(
          `/statistic/calculate_response/?request_id=${payload.request_id}&secret=${payload.secret}`,
        )

        if (computatationResponse.error) {
          return computatationResponse
        }

        const computationResponseData = computatationResponse.data as
          | { response_status: 202 }
          | { response_status: 200; response: string }

        if (computationResponseData.response_status === 202) {
          return { data: { isReady: false } }
        }

        const envelopeResponse = await baseQuery({
          url: computationResponseData.response,
          headers: {
            [CSRF_TOKEN_REQUEST_HEADER_NAME]: undefined,
            [APPLICATION_INSTANCE_ID_HEADER_NAME]: DONT_SET_HEADER_VALUE,
          },
        })

        if (envelopeResponse.error) {
          return envelopeResponse
        }

        const statistics = (
          envelopeResponse.data as { statistics: Analysis.Statistics }
        ).statistics

        return { data: { isReady: true, statistics } }
      },
      providesTags: (_result, _error, { request_id }) => {
        return [
          {
            type: 'StatefulRequest',
            id: encodeTagParameters({ id: request_id }),
          },
        ]
      },
    }),
    toggleProtectionMode: builder.mutation<
      ProtectionModeResponse,
      ProtectionModePayload
    >({
      query: payload => ({
        method: payload.enable ? 'POST' : 'DELETE',
        url: `/analysis/${payload.analysisId}/protection_mode/`,
      }),
    }),
    getAnalysisStatuses: builder.query<GetAnalysisStatusesResponse, void>({
      query: () => '/analysis-statuses/',
    }),
    transferAnalysis: builder.mutation<unknown, TransferAnalysisPayload>({
      query: payload => ({
        url: `/analysis/${payload.sourceAnalysisId}/transfer/`,
        method: 'POST',
        body: { target_analyses: payload.targetAnalysisIds },
      }),
    }),
    checkIfSaveWillCauseWorkflowRetrigger: builder.mutation<
      CheckIfSaveWillCauseWorkflowRetriggerResponse,
      CheckIfSaveWillCauseWorkflowRetriggerPayload
    >({
      query: payload => ({
        url: `/analysis/${payload.analysisId}/check_if_restart_next_steps_are_required/`,
        method: 'POST',
        body: { cluster_tree: payload.clusterTree },
      }),
    }),
    startSelfOrganizingHeatMapRequest: builder.mutation<
      StartSelfOrganizingHeatMapRequestResponse,
      StartSelfOrganizingHeatMapRequestPayload
    >({
      query: payload => ({
        url: `/self-organizing-heat-map/calculate_request/`,
        method: 'POST',
        body: {
          analysis_id: payload.analysisId,
          selected_clusters: payload.selectedClusterIds,
          selected_channels: payload.selectedChannels,
        },
      }),
    }),
    getSelfOrganizingHeatMapResponse: builder.query<
      GetSelfOrganizingHeatMapResponseResponse,
      GetSelfOrganizingHeatMapResponsePayload
    >({
      queryFn: async (payload, _api, _extraOptions, baseQuery) => {
        const computatationResponse = await baseQuery({
          url: `/self-organizing-heat-map/calculate_response/`,
          params: payload,
        })

        if (computatationResponse.error) {
          return computatationResponse
        }

        const computationResponseData = computatationResponse.data as
          | { response_status: 202 }
          | { response_status: 200; response: string }

        if (computationResponseData.response_status === 202) {
          return { data: { isReady: false } }
        }

        const envelopeResponse = await baseQuery({
          url: computationResponseData.response,
          headers: {
            [CSRF_TOKEN_REQUEST_HEADER_NAME]: undefined,
            [APPLICATION_INSTANCE_ID_HEADER_NAME]: DONT_SET_HEADER_VALUE,
          },
        })

        if (envelopeResponse.error) {
          return envelopeResponse
        }

        return {
          data: {
            isReady: true,
            data: envelopeResponse.data as SelfOrganazingHeatMapResult,
          },
        }
      },
      providesTags: (_result, _error, { request_id }) => {
        return [
          {
            type: 'StatefulRequest',
            id: encodeTagParameters({ id: request_id }),
          },
        ]
      },
    }),
  }),
})

const getAnalysisToSave = (state: RootState) => {
  const analysis = selectAnalysis(state)
  const clusterTree = selectAnalysisClusterTree(state)
  const clusterById = selectClusterById(state)
  const layout = selectLayout(state)
  const charts = selectCharts(state)
  const statistics = selectAnalysisStatistics(state)
  const selectedChannels = selectSortedSelectedChannels(state)
  const heatmapColors = selectHeatmapColors(state)
  const activeClusterIds = selectActiveClusterIds(state)
  const activeLeaves = selectActiveLeaves(state)

  updateClusterTree({
    clusterTree,
    activeClusterIds,
    clusterById,
    lassoById: analysis.lassos,
  })

  return {
    ...analysis,
    origin_state_date: analysis.updated_at, // this is to prevent multiple users from overwriting each other's changes
    application_instance_id: state.auth.applicationInstanceId,
    active_leaf_ids: activeLeaves.map(leaf => leaf.id),
    layout,
    graphs: charts.map(({ id, isUnsaved, ...graph }) => ({
      ...graph,
      ...(isUnsaved ? { unsaved_id: id } : { id }),
      // We send the gates without the tempValue, the percentage and the visibility of the gate.
      // This is necessary because the gates are saved in JSON in the DB
      gates: graph.gates.map(
        // eslint-disable-next-line
        ({ tempValues, percentage, hidden, ...propsToSave }) => propsToSave,
      ),
    })),
    statistics: statistics.map(({ id, isUnsaved, ...statistic }) => ({
      ...statistic,
      ...(isUnsaved ? { unsaved_id: id } : { id }),
      recalculate: false,
    })),
    heatmap_colors: heatmapColors,
    sorted_selected_channels: selectedChannels,
  }
}

export const {
  useGetAnalysisQuery,
  useLazyGetAnalysisByIdQuery,
  useSaveAnalysisMutation,
  useStartStatisticsComputationRequestMutation,
  useGetStatisticsComputationResponseQuery,
  useToggleProtectionModeMutation,
  useGetAnalysisStatusesQuery,
  useTransferAnalysisMutation,
  useCheckIfSaveWillCauseWorkflowRetriggerMutation,
  useStartSelfOrganizingHeatMapRequestMutation,
  useGetSelfOrganizingHeatMapResponseQuery,
} = analysisApi

type GetAnalysisResult = {
  analysis: Analysis
  brick: BrickDetails
  workflow: WorkflowDetails | PipelineDetails | ExperimentDetails
  compensatedFile: CompensatedFile
  fcsFile: FcsFile
  project: Project
  scales: Analysis.Scales
}

type SaveAnalysisResult = {
  analysis: Analysis
  scales: Analysis.Scales
}

type ProtectionModePayload = {
  analysisId: string
  enable: boolean
}

type ProtectionModeResponse = {
  analysis_id: string
  protection_mode: boolean
  protection_mode_author?: string
  protection_mode_date?: string
}

export type GetAnalysisStatusesResponse = {
  statuses: Record<AnalysisStatus, string>
  statuses_acceptable_for_transfer_fit: string[]
  statuses_acceptable_for_transfer_predict: string[]
  statuses_acceptable_for_protection_mode: string[]
  statuses_acceptable_for_batch_secondary_clustering: string[]
  statuses_acceptable_for_comparing: string[]
  statuses_acceptable_for_signatures: string[]
  statuses_acceptable_for_view_analysis: string[]
  statuses_acceptable_for_batch_export_statistics: string[]
  statuses_acceptable_for_batch_export_custom_statistics: string[]
  statuses_acceptable_for_sub_analysis: string[]
}

type TransferAnalysisPayload = {
  sourceAnalysisId: string
  targetAnalysisIds: string[]
}

type CheckIfSaveWillCauseWorkflowRetriggerPayload = {
  analysisId: string
  clusterTree: Analysis.ClusterTree
}

type CheckIfSaveWillCauseWorkflowRetriggerResponse = {
  restart_required: boolean
}

type GetStatisticsComputationPayload = {
  request_id: string
  secret: string
}

export type GetStatisticsComputationResponse =
  | { isReady: false }
  | { isReady: true; statistics: Analysis.Statistics }

type StartStatisticsComputationRequestResult = {
  request_id: string
  secret: string
  timeout: number
}

type StartSelfOrganizingHeatMapRequestPayload = {
  analysisId: string
  selectedClusterIds: string[]
  selectedChannels: string[]
}

type StartSelfOrganizingHeatMapRequestResponse = {
  request_id: string
  secret: string
  timeout: number
}

type GetSelfOrganizingHeatMapResponsePayload = {
  request_id: string
  secret: string
}

type GetSelfOrganizingHeatMapResponseResponse =
  | { isReady: false }
  | {
      isReady: true
      data: SelfOrganazingHeatMapResult
    }

export type SelfOrganazingHeatMapResult = {
  hierarchical_clustering_results: {
    ordered_node_ids: number[]
    ordered_columns: string[]
    row_linkage: [number, number, number, number][]
    col_linkage: [number, number, number, number][]
  }
}
