import { CompensatedFile } from 'shared/api/files.api'

type ClusteringPrecisionAndGranularityWizardSectionProps = {
  mode: 'standard' | 'rare-cell'
  compensatedFiles: CompensatedFile[]
  clusteringType: 'primary' | 'secondary'
}

export const calculateClusteringGranularity = ({
  mode,
  compensatedFiles,
  clusteringType,
}: ClusteringPrecisionAndGranularityWizardSectionProps): number => {
  const numberOfEvents = Math.max(
    ...compensatedFiles.map(file => file.fcs_file_total_cell_count),
  )

  if (mode === 'rare-cell' && numberOfEvents < 10 ** 5) {
    return 5
  }

  if (mode === 'standard' && numberOfEvents > 10 ** 6) {
    return 999
  }

  if (
    mode === 'rare-cell' &&
    clusteringType === 'primary' &&
    numberOfEvents >= 10 ** 5
  ) {
    return 999
  }

  return 20
}
