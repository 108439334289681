import Highcharts from 'highcharts'

import { MetaAnalysisBoxPlotBaseOptions } from 'pages/meta-analysis/MetaAnalysisBoxPlotBaseOptions'
import { computeBoxPlotSeries } from 'pages/meta-analysis/series'

import {
  MetaAnalysisBoxPlotType,
  MetaAnalysisFile,
} from 'shared/api/meta-analysis.api'

type RenderBoxPlotChartProps = {
  chart: MetaAnalysisBoxPlotType
  metaAnalysisFile: MetaAnalysisFile
  channels: string[]
  fileNameById: Record<string, string>
  width: number
  height: number
}

export const renderBoxPlotChart = async ({
  chart,
  metaAnalysisFile,
  channels,
  fileNameById,
  width,
  height,
}: RenderBoxPlotChartProps): Promise<string> => {
  return new Highcharts.Chart(document.createElement('div'), {
    ...MetaAnalysisBoxPlotBaseOptions,
    chart: {
      ...MetaAnalysisBoxPlotBaseOptions.chart,
      width,
      height,
    },
    xAxis: {
      categories: chart.selectedFileIds.map(id => fileNameById[id]),
    },
    yAxis: {
      title: {
        text: chart.selectedChannel,
      },
    },
    title: {
      text: chart.selectedCluster,
    },
    series: computeBoxPlotSeries(chart, metaAnalysisFile, channels),
  }).getSVG()
}
