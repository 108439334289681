import { DialogActions, DialogContent, DialogTitle } from '@material-ui/core'
import { Formik } from 'formik'
import React, { useMemo } from 'react'
import styled from 'styled-components'
import { object, string } from 'yup'

import { Button } from 'components/Button'
import Input from 'components/forms/Input'

import { useAppSelector } from 'shared/store'

import { selectAnalysisLassos, selectCharts } from './store/selectors'

type SaveLassoDialogProps = {
  onSave: (lassoName: string) => void
  onCancel: () => void
}

export const SaveLassoDialog = ({
  onSave,
  onCancel,
}: SaveLassoDialogProps): JSX.Element => {
  const charts = useAppSelector(selectCharts)
  const lassos = useAppSelector(selectAnalysisLassos)

  const usedLassoNames = useMemo(() => {
    return charts.flatMap(chart =>
      Object.keys(chart.lasso_ids).map(lassoId => lassos[lassoId].name),
    )
  }, [charts, lassos])

  const SaveLassoDialogFormSchema = useMemo(
    () =>
      object({
        lassoName: string()
          .required('Name is required')
          .notOneOf(usedLassoNames, 'Lasso with this name already exists'),
      }),
    [usedLassoNames],
  )

  return (
    <Formik<SaveLassoDialogFormValue>
      initialValues={{ lassoName: '' }}
      onSubmit={value => {
        onSave(value.lassoName)
      }}
      validationSchema={SaveLassoDialogFormSchema}
    >
      {({
        handleSubmit,
        values,
        handleChange,
        handleBlur,
        errors,
        isValid,
        resetForm,
      }) => (
        <StyledForm onSubmit={handleSubmit}>
          <DialogTitle>Save Lasso</DialogTitle>
          <DialogContent>
            <Input
              name="lassoName"
              label="Name*"
              value={values.lassoName}
              onBlur={handleBlur}
              onChange={handleChange}
              error={errors.lassoName}
              autoFocus
            />
          </DialogContent>
          <DialogActions>
            <Button
              onClick={() => {
                resetForm()
                onCancel()
              }}
            >
              Cancel
            </Button>
            <Button disabled={!isValid} type="submit">
              Save
            </Button>
          </DialogActions>
        </StyledForm>
      )}
    </Formik>
  )
}

type SaveLassoDialogFormValue = {
  lassoName: string
}

const StyledForm = styled.form`
  width: 400px;
`
