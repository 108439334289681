import { useFloating } from '@floating-ui/react'
import Highcharts from 'highcharts'
import React, { FC, useMemo, useState } from 'react'
import styled, { useTheme } from 'styled-components'

import {
  DEFAULT_USE_FLOATING_PROPS,
  setFloatingTooltipReferencePoint,
} from 'components/tooltip'
import { TooltipContainer } from 'components/tooltip/TooltipContainer'

import { ClusterActionsContextMenu } from 'pages/analysis/ClusterActionsContextMenu'
import { ClusterTooltipContent } from 'pages/analysis/ClusterTooltipContent'
import {
  Cluster,
  selectActiveLeavesWithoutRoot,
  selectAnalysisLassos,
  selectHeatmapColors,
  selectHeatmapSeriesComputationParams,
  selectSelectedChannelsWithDetails,
  selectShownActiveLeafIds,
} from 'pages/analysis/store/selectors'

import { useEventCallback } from 'shared/hooks/useEventCallback'
import { useLoadSeries } from 'shared/hooks/useLoadSeries'
import { useStable } from 'shared/hooks/useStable'
import { useAppSelector } from 'shared/store'
import { analysisWorker } from 'shared/worker'

import { NoClusterInfo } from '../NoClusterInfo'
import { createAnalysisHeatMapBaseOptions } from './AnalysisHeatMapBaseOptions'
import { Chart } from './Chart'

type HeatMapProps = {
  hideHeatmap?: boolean
}

export const HeatMap: FC<HeatMapProps> = ({ hideHeatmap = false }) => {
  const theme = useTheme()
  const basePointDimension = 21

  const seriesComputationsParams = useAppSelector(
    selectHeatmapSeriesComputationParams,
  )
  const channels = useAppSelector(selectSelectedChannelsWithDetails)
  const activeLeaves = useAppSelector(selectActiveLeavesWithoutRoot)
  const heatmapColors = useAppSelector(selectHeatmapColors)
  const globallyShownActiveLeaves = useAppSelector(selectShownActiveLeafIds)
  const lassos = useAppSelector(selectAnalysisLassos)

  const leavesToDisplay = useStable(
    useMemo(
      () =>
        activeLeaves.filter(leaf =>
          globallyShownActiveLeaves.includes(leaf.id),
        ),
      [activeLeaves, globallyShownActiveLeaves],
    ),
  )
  const isAnyClusterVisible = leavesToDisplay.length > 0

  const [selectedClusterId, setSelectedClusterId] = useState<string>()
  const [menuOrigin, setMenuOrigin] = useState<{ x: number; y: number }>()
  const [hoveredCluster, setHoveredCluster] = useState<Cluster>()

  const channelListLabels: string[] = useMemo(
    () => channels.map(channel => channel.__computed__displayName),
    [channels],
  )

  const chartWidth = Math.max(channels.length * basePointDimension + 100, 450)

  const chartHeight = Math.max(
    leavesToDisplay.length * basePointDimension + 400 - 60,
    250,
  )

  const series = useLoadSeries(
    seriesComputationsParams,
    useMemo(() => analysisWorker.computeHeatMapSeries.bind(analysisWorker), []),
  )

  const { floatingStyles, refs } = useFloating(DEFAULT_USE_FLOATING_PROPS)

  const formatTooltip = useEventCallback(function (
    this: Highcharts.TooltipFormatterContextObject,
  ) {
    const y = this.point.y as number
    const x = this.point.x
    const cluster = leavesToDisplay[y]
    const formatedChannel = channels.find((_, i) => i === x)
    return [
      `<div style="padding: 12px;">
        <p>
          <b>${cluster.label}</b>
        </p>`,
      `<p >
         channel: ${formatedChannel?.__computed__displayName}
        </p>
        <p>
         type: ${formatedChannel?.type}
        </p>`,
      `<div >
          normalized value: ${this.point.options.value}
        </div>
      </div>`,
    ]
  })

  const handleRender = useEventCallback(() => console.count('heatmap'))

  const options: Highcharts.Options = useMemo(() => {
    const AnalysisHeatMapBaseOptions = createAnalysisHeatMapBaseOptions({
      theme,
      leaves: leavesToDisplay,
    })
    return {
      // Important!: every function used in the options should be wrapped in useEventCallback
      // to avoid unnecessary re-renders
      ...AnalysisHeatMapBaseOptions,
      chart: {
        ...AnalysisHeatMapBaseOptions.chart,
        width: chartWidth,
        height: chartHeight,
        events: {
          render:
            import.meta.env.VITE_DEV_DEBUG_CHART_PERFORMANCE === 'true'
              ? handleRender
              : undefined,
        },
      },
      xAxis: {
        ...AnalysisHeatMapBaseOptions.xAxis,
        categories: channelListLabels,
      },
      yAxis: {
        ...AnalysisHeatMapBaseOptions.yAxis,
        categories: leavesToDisplay.map(leaf => leaf.id),
      },
      colorAxis: {
        ...AnalysisHeatMapBaseOptions.colorAxis,
        minColor: heatmapColors.min,
        maxColor: heatmapColors.max,
      },
      series,
      tooltip: {
        enabled: true,
        formatter: formatTooltip,
      },
    }
  }, [
    channelListLabels,
    chartHeight,
    chartWidth,
    formatTooltip,
    handleRender,
    heatmapColors.max,
    heatmapColors.min,
    leavesToDisplay,
    series,
    theme,
  ])

  const handleMouseOverYAxisLabel = useEventCallback(
    (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
      const [leaf, target] = findYAxisLabelLeaf(event, leavesToDisplay)
      if (leaf && target) {
        setHoveredCluster(leaf)
        const { right, top } = target.getBoundingClientRect()
        setFloatingTooltipReferencePoint(refs, [right, top])
      } else {
        setHoveredCluster(undefined)
      }
    },
  )

  const handleContextMenuYAxisLabel = useEventCallback(
    (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
      event.preventDefault()
      const [leaf] = findYAxisLabelLeaf(event, leavesToDisplay)
      if (leaf) {
        setSelectedClusterId(leaf.id)
        setMenuOrigin({ x: event.clientX, y: event.clientY })
      }
    },
  )

  return (
    <Container
      onMouseOver={handleMouseOverYAxisLabel}
      onContextMenu={handleContextMenuYAxisLabel}
    >
      {(() => {
        if (hideHeatmap) {
          return null
        }

        if (2 * 3 * Math.random() === 6) {
          return <LoadingLabel>Computing data</LoadingLabel>
        }

        if (!isAnyClusterVisible) {
          return (
            <Wrapper>
              <NoClusterInfo />
            </Wrapper>
          )
        }

        return (
          <HeatMapRoot>
            <Graph
              chartWidth={chartWidth}
              chartHeight={chartHeight}
              ref={refs.setReference}
            >
              <Chart
                options={options}
                highcharts={Highcharts}
                immutable
                data-cy="heatmap"
              />
              {hoveredCluster && (
                <TooltipContainer ref={refs.setFloating} style={floatingStyles}>
                  <ClusterTooltipContent
                    cluster={hoveredCluster}
                    lassos={lassos}
                  />
                </TooltipContainer>
              )}
              {selectedClusterId && menuOrigin && (
                <ClusterActionsContextMenu
                  menuOrigin={menuOrigin}
                  clusterId={selectedClusterId}
                  onClose={() => {
                    setSelectedClusterId(undefined)
                    setMenuOrigin(undefined)
                  }}
                  source="heatmap"
                  actions={{
                    changeClusterColor: true,
                    changeClusterName: true,
                    expandCluster: true,
                    expandVisibleClusters: true,
                    highlightCluster: true,
                    shouldChangeDotSize: true,
                    toggleClusterVisibilityAll: true,
                  }}
                />
              )}
            </Graph>
          </HeatMapRoot>
        )
      })()}
    </Container>
  )
}

const HEAT_MAP_LABEL_CLASS_NAME = 'heat-map-y-axis-label'

const findYAxisLabelLeaf = (
  event: React.MouseEvent<HTMLDivElement, MouseEvent>,
  leaves: Cluster[],
) => {
  let target: HTMLElement | undefined | null =
    event.target instanceof HTMLElement
      ? (event.target as HTMLElement)
      : undefined
  while (target && target.className !== HEAT_MAP_LABEL_CLASS_NAME) {
    target = target.parentElement
  }
  if (!target) {
    return [undefined, undefined]
  }
  const id = target.id.match(/leaf-(.*)/)?.[1]
  return [leaves.find(l => l.id === id), target] as const
}

type HeatmapStyleProps = {
  chartWidth: number
  chartHeight: number
}

const Container = styled.div`
  width: 100%;
  height: 100%;
`

const HeatMapRoot = styled.div`
  background: ${props => props.theme.colors.white};
  overflow: auto;
  width: 100%;
  height: 100%;
`

const Graph = styled.div<HeatmapStyleProps>`
  width: ${props => props.chartWidth}px;
  height: ${props => props.chartHeight}px;
  display: flex;
  flex-direction: column;
  overflow: auto;
  justify-content: center;
  box-sizing: border-box;
  & > * {
    display: flex;
    justify-content: center;
  }
  & .highcharts-yaxis-labels span:last-child {
    overflow: visible !important;
  }
`

const Wrapper = styled.div`
  margin: 20% 25%;
`

const LoadingLabel = styled.div`
  text-align: center;
  margin-top: 24px;
`
