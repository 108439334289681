import { Box } from '@material-ui/core'
import React, { useEffect, useRef } from 'react'
import styled from 'styled-components'

import { ValidationLabel } from 'components/ validation-label/ValidationLabel'

import { Theme } from 'Theme'

export interface InputProps {
  name?: string
  disabled?: boolean
  id?: string
  label?: string
  value: string | number
  error?: string
  disableError?: boolean
  placeholder?: string
  type?:
    | 'password'
    | 'email'
    | 'text'
    | 'text-area'
    | 'number'
    | 'datetime-local'
  readOnly?: boolean
  icon?: JSX.Element
  className?: string
  maxLength?: number
  min?: number | string
  max?: number | string
  step?: number | string
  autoComplete?: string
  autoFocus?: boolean
  rows?: number
  onChange?: (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) => void
  onBlur?: (
    e: React.FocusEventHandler<HTMLInputElement | HTMLTextAreaElement>,
  ) => void
  onKeyUp?: (
    e: React.KeyboardEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) => void
}

const Input: React.FunctionComponent<InputProps> = ({
  name,
  id,
  disabled,
  label,
  value,
  maxLength,
  min,
  max,
  step,
  placeholder,
  type = 'text',
  readOnly = false,
  icon,
  onChange,
  onBlur,
  onKeyUp,
  className,
  autoComplete,
  autoFocus,
  error,
  disableError,
  rows,
}) => {
  const inputRef = useRef<HTMLInputElement>(null)
  useEffect(() => {
    if (inputRef && inputRef.current && autoFocus) {
      inputRef.current.focus()
    }
  }, [inputRef, autoFocus, id])

  return (
    <div data-cy={id} className={className}>
      {label && <label htmlFor={id}>{label}</label>}
      <InputWrapper $error={!!error} $disableError={disableError}>
        {type === 'text-area' ? (
          <TextArea
            disabled={disabled}
            maxLength={maxLength}
            placeholder={placeholder}
            id={id}
            name={name}
            onChange={onChange}
            onBlur={
              onBlur as React.FocusEventHandler<HTMLTextAreaElement> | undefined
            }
            onKeyUp={onKeyUp}
            value={value}
            error={error}
            rows={rows}
          />
        ) : (
          <StyledInput
            ref={inputRef}
            autoComplete={autoComplete}
            disabled={disabled}
            type={type}
            readOnly={readOnly}
            id={id}
            maxLength={maxLength}
            min={min}
            max={max}
            step={step}
            name={name}
            placeholder={placeholder}
            onChange={onChange}
            onKeyUp={onKeyUp}
            onBlur={
              onBlur as React.FocusEventHandler<HTMLInputElement> | undefined
            }
            value={value}
            error={error}
          />
        )}
        {icon && (
          <Box position="absolute" top="12px" right="8px">
            {icon}
          </Box>
        )}
        {!disableError && error && (
          <StyledValidationLabel data-cy={id + '-validation-error'}>
            {error}
          </StyledValidationLabel>
        )}
      </InputWrapper>
    </div>
  )
}

const getInputStyles = (props: {
  error: InputProps['error']
  theme: Theme
}) => `
  border: 1px solid ${props.theme.colors.primaryDark[20]};
  width: 100%;
  padding: ${props.theme.spacing(1)}px;
  border-radius: ${props.theme.radius[1]}px;
  color: ${props.theme.colors.primaryDark[100]};
  font-size: ${props.theme.typography.body2.fontSize}px;
  resize: none;
  font-family: ${props.theme.font.style.regular};
  transition: border .2s ease, outline .2s ease;
  &:focus {
    outline: none;
    border: 1px solid ${props.theme.colors.primaryDark[50]};
  }
  &::placeholder {
    color: ${props.theme.colors.primaryDark[50]};
  }
  `

export const StyledInput = styled.input<{ error: InputProps['error'] }>`
  ${props => getInputStyles(props)}
`

const TextArea = styled.textarea<{ error: InputProps['error'] }>`
  ${props => getInputStyles(props)}
`

const InputWrapper = styled.div<{ $error: boolean; $disableError?: boolean }>`
  --line-height: ${props => props.theme.typography.body2.lineHeight};

  line-height: var(--line-height);
  padding-bottom: ${props =>
    props.$disableError
      ? '0'
      : `calc(
    ${
      !props.$error ? props.theme.font.size.smallest : 0
    }px * var(--line-height) + 8px)`};
`

const StyledValidationLabel = styled(ValidationLabel)`
  font-size: ${props => props.theme.font.size.smallest}px;
`

export default Input
