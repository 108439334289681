import { LicenseWallet } from 'shared/models/LicenseModels'
import { PaginatedResults } from 'shared/models/Result'

import { privateApi } from './private.api'

export const licenseWalletApi = privateApi.injectEndpoints({
  endpoints: builder => ({
    getLicenseWallet: builder.query<GetLicenseWalletResult, void>({
      query: () => ({
        url: '/license-wallet/',
      }),
      providesTags: [{ type: 'Wallet' }],
    }),
  }),
})

export const { useGetLicenseWalletQuery } = licenseWalletApi

type GetLicenseWalletResult = PaginatedResults<LicenseWallet[]>
