import { LogSession } from 'shared/contexts/LogContext'
import { OrganizationTypes } from 'shared/models/OrganizationModels'
import { logout, setIsAuthenticated } from 'shared/store/auth.slice'
import { handleError } from 'shared/utils/errorHandler'

import {
  CSRF_TOKEN_REQUEST_HEADER_NAME,
  getCsrfToken,
  removeCsrfToken,
} from './cookies'
import { publicApi } from './public.api'

export const authApi = publicApi.injectEndpoints({
  endpoints: builder => ({
    login: builder.mutation<LoginResult, LoginPayload>({
      queryFn: async (payload, { dispatch }, _extraOptions, baseQuery) => {
        await baseQuery({ url: 'login/' })
        let csrfToken = getCsrfToken()

        const result = await baseQuery({
          url: 'login/',
          method: 'POST',
          body: {
            recaptcha_token: payload.reCaptchaToken,
            user_id: payload.email,
            user_secret: payload.password,
          },
          headers: {
            [CSRF_TOKEN_REQUEST_HEADER_NAME]: csrfToken,
          },
        })

        if (result.error) {
          removeCsrfToken()
          csrfToken = undefined
          return result
        }

        const loginResult = result.data as LoginResult

        if (csrfToken) {
          dispatch(setIsAuthenticated(true))
          dispatch(
            authApi.endpoints.getSession.initiate(undefined, {
              forceRefetch: true,
            }),
          )
        }

        return { data: loginResult }
      },
    }),
    sendForgotPassword: builder.mutation<unknown, ForgotPasswordPayload>({
      query: payload => ({
        url: 'password-reset/',
        method: 'POST',
        body: {
          recaptcha_token: payload.reCaptchaToken,
          email: payload.email,
        },
      }),
    }),
    changePassword: builder.mutation<unknown, ChangePasswordPayload>({
      query: payload => ({
        url: 'password-reset/confirm/',
        method: 'POST',
        body: payload,
      }),
    }),
    loginToLogbook: builder.mutation<
      LoginToLogbookResult,
      LoginToLogbookPayload
    >({
      queryFn: async (payload, api, _extraOptions, baseQuery) => {
        const csrfToken = getCsrfToken()
        if (!csrfToken) {
          throw handleError(new Error('CSRF token is missing'))
        }
        const authentication = await baseQuery({
          url: 'login/',
          method: 'POST',
          body: {
            recaptcha_token: payload.reCaptchaToken,
            user_id: payload.email,
            user_secret: payload.password,
          },
          headers: {
            [CSRF_TOKEN_REQUEST_HEADER_NAME]: csrfToken,
          },
        })

        if (authentication.error) {
          return authentication
        }

        const newCsrfToken = getCsrfToken()
        if (!newCsrfToken) {
          api.dispatch(logout())
          throw handleError(new Error('CSRF token was missing in the response'))
        }

        const logbookSession = await baseQuery({
          url: 'logbook-session/',
          method: 'POST',
          body: { analysis_id: payload.analysisId },
          headers: {
            'Content-Type': 'application/json',
            [CSRF_TOKEN_REQUEST_HEADER_NAME]: newCsrfToken,
          },
        })
        if (logbookSession.error) {
          return {
            data: {
              error: (logbookSession.error.data as { detail: string }).detail,
            },
          }
        }

        return { data: { session: logbookSession.data as LogSession } }
      },
    }),
    signUp: builder.mutation<void, SignUpPayload>({
      query: payload => ({
        url: 'signup/',
        method: 'POST',
        body: {
          recaptcha_token: payload.reCaptchaToken,
          email: payload.email,
          password: payload.password,
          first_name: payload.firstName,
          last_name: payload.lastName,
          job: payload.job,
          organization_name: payload.organizationName,
          organization_contact_first_name: payload.organizationContactFirstName,
          organization_contact_last_name: payload.organizationContactLastName,
          organization_address_1: payload.organizationAddress1,
          organization_address_2: payload.organizationAddress2,
          organization_city: payload.organizationCity,
          organization_state: payload.organizationState,
          organization_postal_code: payload.organizationPostalCode,
          organization_country: payload.organizationCountry,
          organization_email: payload.organizationEmail,
          organization_phone_number: payload.organizationPhoneNumber,
          organization_type: payload.organizationType,
          organization_is_trial: payload.isTrialOrganization,
        },
      }),
    }),
    getSession: builder.query<SessionResult, void>({
      query: () => `session/`,
    }),
  }),
})

export const {
  useLoginMutation,
  useSendForgotPasswordMutation,
  useChangePasswordMutation,
  useLoginToLogbookMutation,
  useSignUpMutation,
  useLazyGetSessionQuery,
} = authApi

type LoginPayload = {
  reCaptchaToken: string
  email: string
  password: string
}

type LoginResult = {
  id: string
  username: string
  first_name: string
  last_name: string
  is_active: boolean
  is_expert: boolean
  is_operator: boolean
  job?: string
  redirect_to?: string
}

export type LoginToLogbookPayload = LoginPayload & {
  analysisId: string
}

type ForgotPasswordPayload = { reCaptchaToken: string; email: string }

type ChangePasswordPayload = { password: string; token: string }

export type LoginToLogbookResult = { session: LogSession } | { error: string }

type SignUpPayload = {
  reCaptchaToken: string
  email: string
  password: string
  firstName: string
  lastName: string
  organizationName: string
  organizationContactFirstName: string
  organizationContactLastName: string
  organizationAddress1: string
  organizationAddress2: string
  organizationCity: string
  organizationState: string
  organizationPostalCode: string
  organizationCountry: string
  organizationEmail: string
  organizationPhoneNumber: string
  organizationType: `${OrganizationTypes}`
  isTrialOrganization: boolean
  job?: string
}

type SessionResult = {
  session_expiry_age: number
}
