import { createSelector } from '@reduxjs/toolkit'

import { computeGatePercentage } from 'pages/analysis/computeGatePercentage'

import { groupByUnique } from 'shared/utils/collection.utils'

import { selectCharts } from './charts.selectors'

export const selectGates = createSelector(selectCharts, charts => {
  return charts.flatMap(chart => chart.gates)
})

export const selectGatesById = createSelector(selectGates, gates => {
  return groupByUnique(gates, gate => gate.id)
})

export const selectGatesWithPercentageByChartId = createSelector(
  selectCharts,
  (charts): { [chartId: string]: Graph.Gate[] } => {
    return Object.fromEntries(
      charts.map(chart => {
        return [
          chart.id,
          chart.gates.map(gate => {
            return {
              ...gate,
              percentage: computeGatePercentage(gate, []),
            }
          }),
        ]
      }),
    )
  },
)
