import { Cluster } from 'pages/analysis/store/selectors'

import { shortenClusterLabel } from 'shared/utils/graph'

import { Theme } from 'Theme'

const HEAT_MAP_LABEL_CLASS_NAME = 'heat-map-y-axis-label'

type CreateAnalysisHeatMapBaseOptionsProps = {
  theme: Theme
  leaves: Cluster[]
}

export const createAnalysisHeatMapBaseOptions = ({
  theme,
  leaves,
}: // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
CreateAnalysisHeatMapBaseOptionsProps) => {
  const formatLabel: Highcharts.AxisLabelsFormatterCallbackFunction =
    currentLeaf => {
      const leaf = leaves.find(l => l.id === currentLeaf.value)
      if (leaf === undefined) return ''
      const shortenedLeafName = shortenClusterLabel(leaf.label)

      return `<span id="leaf-${leaf.id}" class=${HEAT_MAP_LABEL_CLASS_NAME}>
          <span title="${leaf.label}">${shortenedLeafName}</span>
          <span style="color: ${leaf.color};">&nbsp;⬤</span>
        </span>`
    }

  return {
    plotOptions: {
      series: {
        dataLabels: {
          overflow: 'allow',
          crop: false,
        },
      },
    },
    title: {
      text: undefined,
    },
    chart: {
      type: 'heatmap',
      marginLeft: 160,
      marginRight: 70,
    },
    xAxis: {
      title: { text: null },
      opposite: true,
      reversed: false,
      labels: {
        rotation: 270,
      },
    },
    yAxis: {
      title: { text: null },
      reversed: true,
      labels: {
        useHTML: true,
        formatter: formatLabel,
      },
    },
    colorAxis: {
      min: 0,
      max: 1,
      labels: {
        style: {
          color: theme.colors.primaryDark[100],
        },
      },
    },
    legend: {
      enabled: true,
      align: 'left',
      layout: 'horizontal',
      x: 54,
      symbolHeight: 40,
      symbolWidth: 300,
      navigation: { enabled: false },
    },
  } as const
}
