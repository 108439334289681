import { Box, Grid, Menu, MenuItem } from '@material-ui/core'
import React, { useCallback, useState } from 'react'
import { useHistory } from 'react-router'
import { useDeepCompareEffect } from 'react-use'
import styled from 'styled-components'

import { Icon } from 'assets/images/icons/Icon'

import { Button } from 'components/Button'
import { Modal } from 'components/Modal'

import { useAssignOperatorPermissionMutation } from 'shared/api/organization.api'
import { useAppDispatch } from 'shared/store'
import { logout } from 'shared/store/auth.slice'
import { showNotification } from 'shared/store/notification.slice'
import { formatDateTime } from 'shared/utils/utils'

import { EditMemberModal } from './EditMemberModal'

export const MemberRow = ({
  id,
  email,
  firstName,
  lastName,
  inviteDate,
  joinedDate,
  lastLoginDate,
  isOperator,
  isActive,
}: {
  id: string
  email: string
  firstName: string
  lastName: string
  inviteDate: string | null
  joinedDate: string | null
  lastLoginDate: string | null
  isOperator: boolean
  isActive: boolean
}): JSX.Element => {
  const history = useHistory()

  const [assignOperatorRequest, assignOperatorResult] =
    useAssignOperatorPermissionMutation()
  const dispatch = useAppDispatch()

  const [isEditMemberOpen, setIsEditMemberOpen] = useState(false)
  const [isOperatorPermissionWarningOpen, setIsOperatorPermissionWarningOpen] =
    useState(false)

  const [menuAnchorEl, setMenuAnchorEl] = useState<null | HTMLElement>(null)
  const isMenuOpen = Boolean(menuAnchorEl)
  const handleOpenMenu = useCallback(
    (event: React.MouseEvent<HTMLElement>) => {
      setMenuAnchorEl(event.currentTarget)
    },
    [setMenuAnchorEl],
  )
  const handleCloseMenu = useCallback(() => {
    setMenuAnchorEl(null)
  }, [setMenuAnchorEl])

  const handleOpenEditMember = useCallback(() => {
    handleCloseMenu()
    setIsEditMemberOpen(true)
  }, [setIsEditMemberOpen, handleCloseMenu])

  const handleOpenPermissionModal = useCallback(() => {
    handleCloseMenu()
    setIsOperatorPermissionWarningOpen(true)
  }, [setIsOperatorPermissionWarningOpen, handleCloseMenu])

  const handleClosePermissionModal = useCallback(() => {
    setIsOperatorPermissionWarningOpen(false)
  }, [setIsOperatorPermissionWarningOpen])

  const handleConfirmPermissionModal = useCallback(() => {
    assignOperatorRequest({ id })
  }, [assignOperatorRequest, id])

  useDeepCompareEffect(() => {
    if (assignOperatorResult.isSuccess) {
      dispatch(
        showNotification({
          type: 'success',
          description: 'Operator changed successfully',
        }),
      )
      history.push('/log-in')
      dispatch(logout())
    }
  }, [assignOperatorResult, dispatch])

  return (
    <RowWrapper key={id} container spacing={0}>
      <Grid item xs={2}>
        <StyledText title={email}>{email}</StyledText>
      </Grid>
      <Grid item xs={2}>
        <StyledText title={firstName}>{firstName}</StyledText>
      </Grid>
      <Grid item xs={2}>
        <StyledText title={lastName}>{lastName}</StyledText>
      </Grid>
      <Grid item xs={1}>
        <StyledText
          title={inviteDate ? formatDateTime(new Date(inviteDate)) : ''}
        >
          {inviteDate ? formatDateTime(new Date(inviteDate)) : ''}
        </StyledText>
      </Grid>
      <Grid item xs={1}>
        <StyledText
          title={joinedDate ? formatDateTime(new Date(joinedDate)) : ''}
        >
          {joinedDate ? formatDateTime(new Date(joinedDate)) : ''}
        </StyledText>
      </Grid>
      <Grid item xs={1}>
        <StyledText
          title={lastLoginDate ? formatDateTime(new Date(lastLoginDate)) : ''}
        >
          {lastLoginDate ? formatDateTime(new Date(lastLoginDate)) : ''}
        </StyledText>
      </Grid>
      <Grid item xs={1}>
        <div title={isOperator ? 'True' : 'False'}>
          {isOperator ? (
            <StyledIcon name="check" />
          ) : (
            <StyledIcon name="close" />
          )}
        </div>
      </Grid>
      <Grid item xs={1}>
        <div title={isActive ? 'True' : 'False'}>
          {isActive ? <StyledIcon name="check" /> : <StyledIcon name="close" />}
        </div>
      </Grid>
      <Grid item xs={1}>
        <div title="Actions">
          <SettingsWrapper>
            <div onClick={handleOpenMenu}>
              <StyledIcon name="cog" />
            </div>
            <Menu
              open={isMenuOpen}
              anchorEl={menuAnchorEl}
              onClose={handleCloseMenu}
            >
              <StyledMenuItem onClick={handleOpenEditMember}>
                Edit member
              </StyledMenuItem>
              {!isOperator && isActive && (
                <StyledMenuItem $warning onClick={handleOpenPermissionModal}>
                  Make operator
                </StyledMenuItem>
              )}
            </Menu>
          </SettingsWrapper>
        </div>
      </Grid>
      <Modal
        open={isOperatorPermissionWarningOpen}
        onClose={handleClosePermissionModal}
      >
        <ModalPanel textAlign="center">
          <div>
            <Text>
              {`Do you really want to reassign the operator permissions to
                  ${firstName} ${lastName}?`}
            </Text>
            <Text>This will remove your permission and log you out.</Text>
          </div>

          <ButtonWrapper>
            <Button
              onClick={handleClosePermissionModal}
              variant="contained"
              grey
            >
              Cancel
            </Button>
            <Button
              onClick={handleConfirmPermissionModal}
              variant="contained"
              color="primary"
            >
              Confirm
            </Button>
          </ButtonWrapper>
        </ModalPanel>
      </Modal>
      {isEditMemberOpen && (
        <EditMemberModal
          isEditModalOpen={isEditMemberOpen}
          setIsEditModalOpen={setIsEditMemberOpen}
          memberId={id}
        />
      )}
    </RowWrapper>
  )
}

const RowWrapper = styled(Grid)`
  color: ${props => props.theme.colors.primaryDark[100]};
  align-items: center;
  border-bottom: 1px solid ${props => props.theme.colors.primaryDark[20]};
  padding: 0 10px 10px;
  margin: 10px 0;
`

const StyledIcon = styled(Icon)`
  color: ${props => props.theme.colors.primaryDark[100]};
  width: 20px;
  height: 20px;
`

const SettingsWrapper = styled.div`
  cursor: pointer;
`

const StyledMenuItem = styled(MenuItem)<{ $warning?: boolean }>`
  font-size: ${props => props.theme.font.size.smallest}px;
  width: 170px;
  height: 52px;
  border-bottom: 1px solid ${props => props.theme.colors.primaryDark[20]};
  color: ${props =>
    props.$warning
      ? props.theme.colors.error
      : props.theme.colors.primaryDark[100]};
`

const ModalPanel = styled(Box)`
  background: ${props => props.theme.colors.white};
  padding: ${props => props.theme.spacing(4, 8)};
  border-radius: ${props => props.theme.radius[2]}px;
  box-shadow: ${props => props.theme.shadow.tooltip};
  max-width: 95%;
  margin: 0 auto;
  width: 450px;
`

const Text = styled.p`
  font-size: ${props => props.theme.font.size.regular}px;
  padding: 5px 0 10px;
`

const ButtonWrapper = styled.div`
  padding: 16px 0 8px;
  display: flex;
  justify-content: space-around;
`

const StyledText = styled.p`
  overflow: hidden;
  text-overflow: ellipsis;
`
