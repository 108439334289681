import { useState } from 'react'
import { useInterval } from 'react-use'
import styled from 'styled-components'

import { Modal } from './Modal'

type SessionExpiryPromptProps = {
  timeout: number
}
export const SessionExpiryPrompt: React.FC<SessionExpiryPromptProps> = ({
  timeout,
}) => {
  const [timeLeft, setTimeLeft] = useState(timeout)

  useInterval(() => setTimeLeft(timeLeft - 1), timeLeft > 0 ? 1000 : null)

  return (
    <Modal open={true} onClose={() => {}}>
      <Container>
        <Header>Inactivity warning</Header>
        It seems that you are inactive and will be logged out in {timeLeft}{' '}
        seconds. Move your mouse to extend your session.
      </Container>
    </Modal>
  )
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 60vw;
  min-width: 600px;
  max-width: 1000px;
  gap: ${props => props.theme.spacing(3)}px;
  padding: ${props => props.theme.spacing(4)}px;
  text-align: center;
  font-size: ${props => props.theme.font.size.regular}px;
  background: ${props => props.theme.colors.white};
  border-radius: ${props => props.theme.radius[2]}px;
`

const Header = styled.h3`
  margin: 0;
`
