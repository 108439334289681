import { Formik, FormikConfig } from 'formik'
import React, { useCallback } from 'react'
import styled from 'styled-components'
import { object, string } from 'yup'

import { Button } from 'components/Button'
import { Modal } from 'components/Modal'
import { Section } from 'components/Section'
import Input from 'components/forms/Input'

import { useSentInvitationMutation } from 'shared/api/organization.api'
import { handleError } from 'shared/utils/errorHandler'

type InviteFormValues = {
  email: string
}

const InviteFormSchema = object({
  email: string().email().required(),
})

export const InviteMemberModal = ({
  isInvitationModalOpen,
  setIsInvitationModalOpen,
}: {
  isInvitationModalOpen: boolean
  setIsInvitationModalOpen: (value: boolean) => void
}): JSX.Element => {
  const [sendInvitation] = useSentInvitationMutation()

  const handleSubmitEmail: FormikConfig<InviteFormValues>['onSubmit'] =
    useCallback(
      async values => {
        try {
          await sendInvitation(values)
        } catch (error) {
          handleError(error)
        } finally {
          setIsInvitationModalOpen(false)
        }
      },
      [sendInvitation, setIsInvitationModalOpen],
    )

  return (
    <Modal
      open={isInvitationModalOpen}
      onClose={() => setIsInvitationModalOpen(false)}
    >
      <Formik<InviteFormValues>
        initialValues={{ email: '' }}
        validationSchema={InviteFormSchema}
        onSubmit={handleSubmitEmail}
      >
        {({
          values,
          touched,
          errors,
          isSubmitting,
          isValid,
          handleChange,
          handleBlur,
          handleSubmit,
        }) => (
          <Form onSubmit={handleSubmit}>
            <Section title="Invite new member">
              <Input
                value={values.email}
                name="email"
                label="Email*"
                error={touched.email ? errors.email : undefined}
                onChange={handleChange}
                onBlur={handleBlur}
              />
            </Section>
            <ButtonWrapper>
              <StyledBtn onClick={() => setIsInvitationModalOpen(false)} grey>
                Cancel
              </StyledBtn>
              <StyledBtn
                type="submit"
                disabled={!isValid}
                loading={isSubmitting}
                disableWhileLoading
              >
                Send
              </StyledBtn>
            </ButtonWrapper>
          </Form>
        )}
      </Formik>
    </Modal>
  )
}

const Form = styled.form`
  background: ${props => props.theme.colors.white};
  padding: ${props => props.theme.spacing(4, 8)};
  border-radius: ${props => props.theme.radius[2]}px;
  box-shadow: ${props => props.theme.shadow.tooltip};
  max-width: 95%;
  margin: 0 auto;
  width: 450px;
`

const ButtonWrapper = styled.div`
  display: flex;
  justify-content: space-between;
`

const StyledBtn = styled(Button)`
  margin-top: 32px;
  width: 45%;
`
