import { Fade } from '@material-ui/core'
import { isEqual } from 'lodash'
import React, { useCallback, useMemo, useState } from 'react'
import {
  DragDropContext,
  Draggable,
  DraggableProvided,
  Droppable,
  DroppableProvided,
  DropResult,
} from 'react-beautiful-dnd'
import styled from 'styled-components'

import { Icon, IconNames } from 'assets/images/icons/Icon'
import { ReactComponent as Drag } from 'assets/images/icons/drag.svg'

import { Button } from 'components/Button'
import { Checkbox } from 'components/Checkbox'
import Select from 'components/forms/Select'

import { useAppDispatch, useAppSelector } from 'shared/store'

import { sortChannels, sortClusters } from './store/analysis.slice'
import {
  selectActiveLeaves,
  selectChannelsSortMode,
  selectClustersSortMode,
  selectGloballyHiddenClusters,
  selectSelectedChannelsWithDetails,
} from './store/selectors'

type OrganizationHeatmapMenuProps = {
  closeDrawer: () => void
}

const CLUSTER_SORT_OPTIONS = [
  ...createSortByPropertyOptions({
    property: 'name',
    propertyType: 'string',
    label: 'Name',
  }),
  ...createSortByPropertyOptions({
    property: 'events',
    propertyType: 'number',
    label: 'Events',
  }),
  {
    label: 'Normalized Value',
    sortMode: { type: 'by-normalized-value', order: 'asc' },
  },
  {
    label: 'Self organize',
    sortMode: { type: 'self-organize' },
  },
] as const

const CHANNEL_SORT_OPTIONS = [
  {
    label: 'Name',
    sortMode: { type: 'by-label', order: 'asc' },
    iconName: getSortIcon('string', 'asc'),
  },
  {
    label: 'Name',
    sortMode: { type: 'by-label', order: 'desc' },
    iconName: getSortIcon('string', 'desc'),
  },
  {
    label: 'Self organize',
    sortMode: { type: 'self-organize' },
  },
] as const

export const OrganizeHeatmapMenu: React.FC<OrganizationHeatmapMenuProps> = ({
  closeDrawer,
}) => {
  const dispatch = useAppDispatch()
  const selectedChannels = useAppSelector(selectSelectedChannelsWithDetails)
  const activeLeaves = useAppSelector(selectActiveLeaves)
  const clustersSortMode = useAppSelector(selectClustersSortMode)
  const channelsSortMode = useAppSelector(selectChannelsSortMode)
  const globallyHiddenClusters = useAppSelector(selectGloballyHiddenClusters)

  const [manualSorting, setManualSorting] = useState(false)
  const [draftChannelsOrder, setDraftChannelsOrder] = useState([
    ...selectedChannels,
  ])
  const [draftLeavesOrder, setDraftLeavesOrder] = useState([...activeLeaves])
  const [draftClustersSortMode, setClustersSortMode] =
    useState(clustersSortMode)
  const [draftChannelsSortMode, setChannelsSortMode] =
    useState(channelsSortMode)

  const clusterSortModeChanged = useMemo(() => {
    if (manualSorting) {
      return !isEqual(draftLeavesOrder, activeLeaves)
    }
    return !isEqual(draftClustersSortMode, clustersSortMode)
  }, [
    activeLeaves,
    clustersSortMode,
    draftClustersSortMode,
    draftLeavesOrder,
    manualSorting,
  ])

  const channelSortModeChanged = useMemo(() => {
    if (manualSorting) {
      return !isEqual(draftChannelsOrder, selectedChannels)
    }
    return !isEqual(draftChannelsSortMode, channelsSortMode)
  }, [
    channelsSortMode,
    draftChannelsOrder,
    draftChannelsSortMode,
    manualSorting,
    selectedChannels,
  ])

  const onSubmit = useCallback(() => {
    if (manualSorting) {
      dispatch(
        sortClusters({
          type: 'manual',
          order: draftLeavesOrder.map(cluster => cluster.id),
        }),
      )
      dispatch(
        sortChannels({
          type: 'manual',
          order: draftChannelsOrder.map(channel => channel.id),
        }),
      )
    } else {
      dispatch(sortClusters(draftClustersSortMode))
      dispatch(sortChannels(draftChannelsSortMode))
    }

    closeDrawer()
  }, [
    draftChannelsSortMode,
    closeDrawer,
    draftClustersSortMode,
    dispatch,
    draftChannelsOrder,
    draftLeavesOrder,
    manualSorting,
  ])

  const clusterSortButtons = useMemo(() => {
    return CLUSTER_SORT_OPTIONS.map(option => {
      const active =
        option.sortMode.type === 'by-normalized-value'
          ? option.sortMode.type === draftClustersSortMode?.type
          : isEqual(draftClustersSortMode, option.sortMode)

      return {
        type: 'cluster',
        label: option.label,
        iconName: 'iconName' in option && option.iconName,
        active,
        onClick: () => {
          const sortMode =
            option.sortMode.type === 'by-normalized-value'
              ? { ...option.sortMode, channel: draftChannelsOrder[0].id }
              : option.sortMode
          active
            ? setClustersSortMode(undefined)
            : setClustersSortMode(sortMode)
        },
      }
    })
  }, [draftClustersSortMode, draftChannelsOrder])

  const channelSortButtons = useMemo(() => {
    return CHANNEL_SORT_OPTIONS.map(option => {
      const active = isEqual(draftChannelsSortMode, option.sortMode)

      return {
        type: 'channel',
        label: option.label,
        iconName: 'iconName' in option && option.iconName,
        active,
        onClick: () =>
          active
            ? setChannelsSortMode(undefined)
            : setChannelsSortMode(option.sortMode),
      }
    })
  }, [draftChannelsSortMode])

  function allowChangeOrder(currentIndex: number, nextIndex: number) {
    const isCurrentIndexRenamed =
      draftLeavesOrder[currentIndex].label !==
      draftLeavesOrder[currentIndex].defaultLabel
    const isNextIndexRenamed =
      draftLeavesOrder[nextIndex].label !==
      draftLeavesOrder[nextIndex].defaultLabel

    // Find the boundary index where non-renamed items start
    const firstNonRenamedIndex = draftLeavesOrder.findIndex(
      item => item.label === item.defaultLabel,
    )

    // Find the boundary index where renamed items start
    const firstRenamedIndex = draftLeavesOrder.findIndex(
      item => item.label !== item.defaultLabel,
    )

    // If there are no non-renamed items, they all are renamed
    const allRenamed = firstNonRenamedIndex === -1

    // If there are no renamed items, all items are non-renamed
    const allNonRenamed = firstRenamedIndex === -1

    if (allRenamed) {
      // All items are renamed, so any move is allowed
      return true
    }

    if (allNonRenamed) {
      // All items are non-renamed, so any move is allowed
      return true
    }

    if (isCurrentIndexRenamed && isNextIndexRenamed) {
      // Renamed items can only be moved within the renamed section
      if (nextIndex >= firstNonRenamedIndex) {
        return false
      }
    } else if (!isCurrentIndexRenamed && !isNextIndexRenamed) {
      // Non-renamed items can only be moved within the non-renamed section
      if (nextIndex < firstNonRenamedIndex) {
        return false
      }
    } else if (isCurrentIndexRenamed && !isNextIndexRenamed) {
      // Renamed items can only be moved to the end of the non-renamed section
      if (
        nextIndex >= firstNonRenamedIndex &&
        currentIndex < firstNonRenamedIndex
      ) {
        return false
      }
    } else if (!isCurrentIndexRenamed && isNextIndexRenamed) {
      // Non-renamed items can only be moved to the beginning of the renamed section
      if (nextIndex < firstNonRenamedIndex) {
        return false
      }
    }
    return true
  }

  function handleOnDragEnd(result: DropResult, type: string) {
    if (!result.destination) return // dropped outside the list

    const sourceIndex = result.source.index
    const destinationIndex = result.destination.index

    if (type === 'clusters') {
      if (!allowChangeOrder(sourceIndex, destinationIndex)) return
      const newClusters = Array.from(draftLeavesOrder)
      const [removed] = newClusters.splice(sourceIndex, 1)
      newClusters.splice(destinationIndex, 0, removed)

      setDraftLeavesOrder(newClusters)
    } else if (type === 'channels') {
      const newChannels = Array.from(draftChannelsOrder)
      const [removed] = newChannels.splice(sourceIndex, 1)
      newChannels.splice(destinationIndex, 0, removed)

      setDraftChannelsOrder(newChannels)
    }
  }

  return (
    <Fade
      in={true}
      unmountOnExit={true}
      appear={true}
      timeout={{
        enter: 300,
        exit: 0,
      }}
    >
      <Container>
        <InnerContainer>
          <Title>Heatmap Organization</Title>
          <ContentWrapper>
            <ListWrapper>
              <Subtitle>Clusters</Subtitle>
              {!manualSorting && (
                <ButtonsList>
                  {clusterSortButtons.map((button, idx) => (
                    <React.Fragment key={idx}>
                      <StyledButton
                        selected={button.active}
                        key={idx}
                        onClick={button.onClick}
                      >
                        {button.label}
                        {button.iconName && (
                          <CustomSortingModeIcon name={button.iconName} />
                        )}
                      </StyledButton>
                    </React.Fragment>
                  ))}
                  {draftClustersSortMode?.type === 'by-normalized-value' && (
                    <>
                      <StyledSelect
                        id={draftClustersSortMode.channel}
                        name={draftClustersSortMode.channel}
                        value={draftClustersSortMode.channel}
                        onChange={e => {
                          setClustersSortMode({
                            ...draftClustersSortMode,
                            channel: e.target.value as string,
                          })
                        }}
                        options={draftChannelsOrder.map(channel => ({
                          value: channel.id,
                          label: channel.__computed__displayName,
                        }))}
                      />
                      <CheckboxOption>
                        <Checkbox
                          checked={draftClustersSortMode.order === 'asc'}
                          onClick={() =>
                            setClustersSortMode({
                              ...draftClustersSortMode,
                              order: 'asc',
                            })
                          }
                        />
                        <CustomSortingModeIcon name="sortNumericDownLight" />
                      </CheckboxOption>
                      <CheckboxOption>
                        <Checkbox
                          checked={draftClustersSortMode.order === 'desc'}
                          onClick={() =>
                            setClustersSortMode({
                              ...draftClustersSortMode,
                              order: 'desc',
                            })
                          }
                        />
                        <CustomSortingModeIcon name="sortNumericUpLight" />
                      </CheckboxOption>
                    </>
                  )}
                </ButtonsList>
              )}
              {manualSorting && (
                <DragDropContext
                  onDragEnd={(result: DropResult) =>
                    handleOnDragEnd(result, 'clusters')
                  }
                >
                  <Droppable droppableId="clusters">
                    {(provided: DroppableProvided) => (
                      <RowsWrapper
                        {...provided.droppableProps}
                        ref={provided.innerRef}
                      >
                        {draftLeavesOrder.map((cluster, index) => (
                          <Draggable
                            key={cluster.id}
                            draggableId={cluster.id}
                            index={index}
                          >
                            {(provided: DraggableProvided) => (
                              <GraphRow
                                ref={provided.innerRef}
                                {...provided.draggableProps}
                                {...provided.dragHandleProps}
                              >
                                <GraphLabel
                                  hidden={globallyHiddenClusters.has(
                                    cluster.id,
                                  )}
                                  title={cluster.label}
                                >
                                  {globallyHiddenClusters.has(cluster.id)
                                    ? '(hidden) '
                                    : ''}
                                  {cluster.label}
                                </GraphLabel>
                                <GraphArrow>
                                  <Drag />
                                </GraphArrow>
                              </GraphRow>
                            )}
                          </Draggable>
                        ))}
                      </RowsWrapper>
                    )}
                  </Droppable>
                </DragDropContext>
              )}
            </ListWrapper>
            <ListWrapper>
              <Subtitle>Channels</Subtitle>
              {!manualSorting && (
                <ButtonsList>
                  {channelSortButtons.map((button, idx) => (
                    <React.Fragment key={idx}>
                      <StyledButton
                        key={idx}
                        selected={button.active}
                        onClick={button.onClick}
                      >
                        {button.label}
                        {button.iconName && (
                          <CustomSortingModeIcon name={button.iconName} />
                        )}
                      </StyledButton>
                    </React.Fragment>
                  ))}
                </ButtonsList>
              )}
              {manualSorting && (
                <DragDropContext
                  onDragEnd={(result: DropResult) =>
                    handleOnDragEnd(result, 'channels')
                  }
                >
                  <Droppable droppableId="channels">
                    {(provided: DroppableProvided) => (
                      <RowsWrapper
                        {...provided.droppableProps}
                        ref={provided.innerRef}
                      >
                        {draftChannelsOrder.map((channel, index) => (
                          <Draggable
                            key={channel.id}
                            draggableId={channel.id}
                            index={index}
                          >
                            {(provided: DraggableProvided) => (
                              <GraphRow
                                ref={provided.innerRef}
                                {...provided.draggableProps}
                                {...provided.dragHandleProps}
                              >
                                <GraphLabel title={channel.id}>
                                  {channel.__computed__displayName}
                                </GraphLabel>
                                <GraphArrow>
                                  <Drag />
                                </GraphArrow>
                              </GraphRow>
                            )}
                          </Draggable>
                        ))}
                      </RowsWrapper>
                    )}
                  </Droppable>
                </DragDropContext>
              )}
            </ListWrapper>
          </ContentWrapper>
        </InnerContainer>
        <ButtonsRow>
          <SubmitButton grey onClick={() => setManualSorting(!manualSorting)}>
            {manualSorting ? 'Custom Sorting' : 'Manual Sorting'}
          </SubmitButton>
          <SubmitButton
            disabled={!channelSortModeChanged && !clusterSortModeChanged}
            onClick={onSubmit}
          >
            Apply changes
          </SubmitButton>
        </ButtonsRow>
      </Container>
    </Fade>
  )
}

function createSortByPropertyOptions({
  label,
  property,
  propertyType,
}: {
  property: string
  propertyType: 'string' | 'number'
  label: string
}) {
  return (['asc', 'desc'] as const).map(order => ({
    label,
    iconName: getSortIcon(propertyType, order),
    sortMode: { type: 'by-property' as const, property, order },
  }))
}

function getSortIcon(
  type: 'string' | 'number',
  order: 'asc' | 'desc',
): IconNames {
  if (type === 'string' && order === 'asc') {
    return 'sortAlphaDownLight'
  }
  if (type === 'string' && order === 'desc') {
    return 'sortAlphaUpLight'
  }
  if (type === 'number' && order === 'asc') {
    return 'sortNumericDownLight'
  }
  if (type === 'number' && order === 'desc') {
    return 'sortNumericUpLight'
  }

  throw new Error('Invalid sort type or order')
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 90%;
  margin: auto;
`
const ContentWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  column-gap: 20px;
  justify-content: center;
`
const InnerContainer = styled.div`
  background-color: ${props => props.theme.colors.white};
  overflow-y: auto;
  border-radius: ${props => props.theme.radius[2]}px;
  border: 1px solid ${props => props.theme.colors.primaryDark[20]};
  box-shadow: ${props => props.theme.shadow[1]};
  color: ${props => props.theme.colors.primaryDark[100]};
  padding: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
`
const ListWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: 4px 8px;
  z-index: 2;
  align-items: center;
`
const ButtonsList = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`
const StyledButton = styled(Button)<{ selected: boolean }>`
  margin: 5px;
  height: 30px;
  width: 200px;
  font-size: ${props => props.theme.font.size.small}px;

  background-color: ${props =>
    props.selected
      ? props.theme.colors.primaryDark[100]
      : props.theme.colors.greyscale[10]};
  color: ${props =>
    props.selected
      ? props.theme.colors.white
      : props.theme.colors.primaryDark[100]};
`
const ButtonsRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: end;
`
const Subtitle = styled.div`
  font-size: ${props => props.theme.font.size.regular}px;
`
const Title = styled.div`
  font-family: ${props => props.theme.font.style.bold};
  font-size: ${props => props.theme.font.size.h2}px;
  color: ${props => props.theme.colors.primaryDark[100]};
  justify-content: center;
  margin-bottom: 10px;
`
const RowsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  z-index: 2;
`
const GraphRow = styled.div`
  display: flex;
  margin: 4px 0;
  justify-content: space-between;
  align-items: center;
`
const GraphArrow = styled.div`
  margin-right: 4px;
  & > svg {
    margin: 1px;
    width: 12px;
    height: 12px;
    cursor: pointer;
  }
`
const GraphLabel = styled.div<{ hidden?: boolean }>`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 150px;
  font-size: ${props => props.theme.font.size.small}px;
  color: ${props => (props.hidden ? 'grey' : '')};
`
const StyledSelect = styled(Select)`
  height: 30px;
  color: ${props => props.theme.colors.primaryDark[100]};
  font-size: ${props => props.theme.font.size.small}px;
  width: 200px;
  max-height: 200px;
  min-width: 100px;
  margin: 5px 0;
`

const CustomSortingModeIcon = styled(Icon)`
  width: 20px;
  height: 20px;
  margin-left: 5px;
`
const CheckboxOption = styled.div`
  display: flex;
  align-items: center;
  justify-content: start;
  align-self: start;
`
const SubmitButton = styled(Button)`
  margin-left: 20px;
  margin-top: 20px;
`
