import { Typography } from '@material-ui/core'
import { useState } from 'react'
import styled from 'styled-components'

import { useGetOrganizationMembersQuery } from 'shared/api/organization.api'
import { useObserve } from 'shared/hooks/useObserve'
import { SortingOrder } from 'shared/utils/array'

import { MemberHeader, MembersTable } from './MembersTable'

const sortingFields = [
  { header: 'Username', field: 'username' },
  { header: 'First name', field: 'first_name' },
  { header: 'Last name', field: 'last_name' },
  { header: 'Invite date', field: '' },
  { header: 'Join date', field: 'date_joined' },
  { header: 'Last login', field: 'last_login' },
  { header: 'Operator', field: 'is_operator' },
  { header: 'Active', field: 'is_active' },
] as const

export const MembersPageContent = (): JSX.Element => {
  const [page, setPage] = useState(1)
  const [sortingCategory, setSortingCategory] =
    useState<MemberHeader>('Last login')
  const [sortingOrder, setSortingOrder] = useState<SortingOrder>('descending')

  const membersResult = useGetOrganizationMembersQuery({
    page,
    ordering: computeOrdering(sortingCategory, sortingOrder),
  })

  useObserve(membersResult.data?.page, realCurrentPage => {
    if (realCurrentPage && realCurrentPage !== page) {
      setPage(realCurrentPage)
    }
  })

  return (
    <MembersContainer>
      <Typography variant="h4" align="left">
        Organization Members
      </Typography>
      <MembersTable
        isFetching={membersResult.isFetching}
        members={membersResult.data?.results ?? []}
        page={page}
        numberOfPages={membersResult.data?.page_count ?? 1}
        sortingCategory={sortingCategory}
        sortingOrder={sortingOrder}
        onPageChange={setPage}
        onSortingCategoryChange={setSortingCategory}
        onSortingOrderChange={setSortingOrder}
      />
    </MembersContainer>
  )
}

const computeOrdering = (header: MemberHeader, order: SortingOrder) => {
  const orderColumnIndex = sortingFields.findIndex(
    column => column.header === header,
  )
  const orderCategory = sortingFields[orderColumnIndex]['field']
  return order === 'descending' ? '-' + orderCategory : orderCategory
}

const MembersContainer = styled.div`
  margin-top: ${props => props.theme.spacing(2)}px;
`
