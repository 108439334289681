import { Formik, FormikConfig } from 'formik'
import React, { useCallback } from 'react'
import styled from 'styled-components'
import { boolean, object, string } from 'yup'

import { Button } from 'components/Button'
import { Checkbox } from 'components/Checkbox'
import { Modal } from 'components/Modal'
import { Section } from 'components/Section'
import Input from 'components/forms/Input'

import {
  useGetOrganizationMemberQuery,
  useUpdateMemberMutation,
} from 'shared/api/organization.api'
import { handleError } from 'shared/utils/errorHandler'

type MemberEditFormValues = {
  is_active: boolean
  is_expert: boolean
  job: string | undefined
}

const MemberEditSchema = object({
  is_active: boolean().required(),
  is_expert: boolean().required(),
  job: string(),
})

export const EditMemberModal = ({
  isEditModalOpen,
  setIsEditModalOpen,
  memberId,
}: {
  isEditModalOpen: boolean
  setIsEditModalOpen: (value: boolean) => void
  memberId: string
}): JSX.Element => {
  const memberResult = useGetOrganizationMemberQuery({
    id: memberId,
  })
  const [updateMemberRequest] = useUpdateMemberMutation()

  const handleCloseEditModal = useCallback(() => {
    setIsEditModalOpen(false)
  }, [setIsEditModalOpen])

  const handleEditMember = useCallback(
    ({ job, is_active, is_expert }) => {
      updateMemberRequest({ id: memberId, job, is_active, is_expert })
    },
    [updateMemberRequest, memberId],
  )

  const submitMemberChange: FormikConfig<MemberEditFormValues>['onSubmit'] =
    useCallback(
      async values => {
        try {
          await handleEditMember(values)
        } catch (error) {
          handleError(error)
        } finally {
          setIsEditModalOpen(false)
        }
      },
      [handleEditMember, setIsEditModalOpen],
    )

  if (memberResult.isLoading || !memberResult.data) {
    return <div />
  }

  return (
    <Modal open={isEditModalOpen} onClose={() => setIsEditModalOpen(false)}>
      <Formik<MemberEditFormValues>
        initialValues={{
          is_active: memberResult.data.is_active,
          is_expert: memberResult.data.is_expert,
          job: memberResult.data.job || undefined,
        }}
        validationSchema={MemberEditSchema}
        onSubmit={submitMemberChange}
      >
        {({
          values,
          touched,
          errors,
          isSubmitting,
          isValid,
          handleChange,
          handleBlur,
          handleSubmit,
        }) => (
          <Form onSubmit={handleSubmit}>
            <Section title="Edit member">
              <Checkbox
                checked={values.is_active}
                name="is_active"
                label="is active"
                onChange={handleChange}
              />
              <Checkbox
                checked={values.is_expert}
                name="is_expert"
                label="is expert"
                onClick={handleChange}
                onBlur={handleBlur}
              />
              <Input
                value={values.job || ''}
                name="job"
                label="Job"
                error={touched.job ? errors.job : undefined}
                onChange={handleChange}
                onBlur={handleBlur}
              />
            </Section>
            <ButtonWrapper>
              <StyledBtn onClick={handleCloseEditModal} grey>
                Cancel
              </StyledBtn>
              <StyledBtn
                type="submit"
                disabled={!isValid}
                loading={isSubmitting}
                disableWhileLoading
              >
                Send
              </StyledBtn>
            </ButtonWrapper>
          </Form>
        )}
      </Formik>
    </Modal>
  )
}

const Form = styled.form`
  background: ${props => props.theme.colors.white};
  padding: ${props => props.theme.spacing(4, 8)};
  border-radius: ${props => props.theme.radius[2]}px;
  box-shadow: ${props => props.theme.shadow.tooltip};
  max-width: 95%;
  margin: 0 auto;
  width: 450px;
`

const ButtonWrapper = styled.div`
  display: flex;
  justify-content: space-between;
`

const StyledBtn = styled(Button)`
  margin-top: 32px;
  width: 45%;
`
