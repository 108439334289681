import { RootState } from 'shared/store'

export const selectIsAnySpilloverFileBeingUploaded = (
  state: RootState,
): boolean => {
  return state.projectPage.ui.spilloverFileBeingUploadedIds.length > 0
}

export const selectIsAnyFcsFileBeingUploaded = (state: RootState): boolean => {
  return state.projectPage.ui.fcsFileBeingUploadedIds.length > 0
}
