import {
  Line,
  lineIntersectsLine,
  lineLength,
  Point,
  pointInPolygon,
  Polygon,
} from 'geometric'

import { GraphZoom } from 'shared/models/Graphs'
import { checkIfLinesAreNearColinear } from 'shared/utils/geometry'
import { translateToChartPoint } from 'shared/utils/highcharts-utils'

import { UseChartScales } from '../useChartScales'
import { LassoHighcharts } from './LassoHighcharts'
import { LassoToolLasso, LassoToolState } from './LassoTool'

export class LassoValidator {
  private state: LassoToolState
  private lassoHighcharts: LassoHighcharts

  constructor(state: LassoToolState) {
    this.state = state
    this.lassoHighcharts = new LassoHighcharts(state)
  }

  checkIfCanAddPoint(): boolean {
    const { lassos, currentLassoId, mousePoint } = this.state

    return !!(
      currentLassoId &&
      !lassos[currentLassoId].isFinished &&
      mousePoint &&
      this.checkIfCanPlaceLine(lassos[currentLassoId].polygon, mousePoint)
    )
  }

  private checkIfCanPlaceLine(
    polygon: Polygon,
    newPoint: Point,
    checkMinDistance = true,
    lassoType: LassoToolLasso['type'] = 'freeshape',
  ): boolean {
    const chart = this.lassoHighcharts.getChart()

    if (!chart) {
      return false
    }

    const translatedPolygon = polygon.map(point =>
      translateToChartPoint(point, chart),
    )
    newPoint = translateToChartPoint(newPoint, chart)

    if (translatedPolygon.length < 2) {
      return true
    }

    const lastPoint = translatedPolygon[translatedPolygon.length - 1]
    const secondLastPoint = translatedPolygon[translatedPolygon.length - 2]

    if (lassoType === 'rectangle') {
      return !checkMinDistance || lineLength([lastPoint, newPoint]) >= 5
    }

    return (
      (!checkMinDistance || lineLength([lastPoint, newPoint]) >= 5) &&
      !checkIfLinesAreNearColinear({
        start: secondLastPoint,
        middle: lastPoint,
        end: newPoint,
      }) &&
      !this.checkIfPotentialNewLineIntersectsAnyExistingLine(
        translatedPolygon,
        newPoint,
      )
    )
  }

  checkIfLassoCanBeFinished(
    polygon: Polygon,
    chartScales: UseChartScales,
    zoom: GraphZoom,
  ): boolean {
    const chart = this.lassoHighcharts.getChart()
    const { lassos, currentLassoId, mousePoint } = this.state

    return !!(
      currentLassoId &&
      !lassos[currentLassoId].isFinished &&
      !!chart &&
      lassos[currentLassoId].polygon.length >= 2 &&
      !!mousePoint &&
      lineLength([
        translateToChartPoint(lassos[currentLassoId].polygon[0], chart),
        translateToChartPoint(mousePoint, chart),
      ]) < 10 &&
      this.checkIfCanPlaceLine(
        lassos[currentLassoId].polygon.slice(1),
        lassos[currentLassoId].polygon[0],
        false,
      ) &&
      this.checkIfLassoIsInChartArea(polygon, chartScales, zoom)
    )
  }

  checkIfModifiedLassoIsCorrect(
    polygon: Polygon,
    chartScales: UseChartScales,
    zoom: GraphZoom,
  ): boolean {
    const chart = this.lassoHighcharts.getChart()
    const { lassos, currentLassoId, movedPolygonPointIndex, mousePoint } =
      this.state

    if (
      !currentLassoId ||
      !chart ||
      !mousePoint ||
      movedPolygonPointIndex === undefined
    ) {
      return false
    }

    const testPolygon = [
      ...lassos[currentLassoId].polygon.slice(
        movedPolygonPointIndex + 1,
        lassos[currentLassoId].polygon.length,
      ),
      ...lassos[currentLassoId].polygon.slice(
        0,
        Math.max(0, movedPolygonPointIndex),
      ),
    ]

    return (
      this.checkIfCanPlaceLine(
        testPolygon,
        mousePoint,
        true,
        lassos[currentLassoId].type,
      ) &&
      this.checkIfCanPlaceLine(
        testPolygon.reverse(),
        mousePoint,
        true,
        lassos[currentLassoId].type,
      ) &&
      this.checkIfLassoIsInChartArea(polygon, chartScales, zoom)
    )
  }

  checkIfChartDistanceIsLessThan(
    a: Point,
    b: Point,
    distance: number,
  ): boolean {
    const chart = this.lassoHighcharts.getChart()
    return (
      !!chart &&
      lineLength([
        translateToChartPoint(a, chart),
        translateToChartPoint(b, chart),
      ]) < distance
    )
  }

  checkIfLassoIsInChartArea(
    polygon: Polygon,
    chartScales: UseChartScales,
    zoom: GraphZoom,
  ): boolean {
    const { xMin, xMax, yMin, yMax } = chartScales
    if (yMin === undefined || yMax === undefined) {
      return false
    }
    const chartPolygon: Polygon = [
      [zoom?.x_min ?? xMin, zoom?.y_min ?? yMin],
      [zoom?.x_min ?? xMin, zoom?.y_max ?? yMax],
      [zoom?.x_max ?? xMax, zoom?.y_min ?? yMin],
      [zoom?.x_max ?? xMax, zoom?.y_max ?? yMax],
    ]
    return (
      chartPolygon.some(point => pointInPolygon(point, polygon)) ||
      polygon.some(point =>
        this.checkIfPointIsInsideChart(point, chartScales, zoom),
      )
    )
  }

  private checkIfPointIsInsideChart(
    point: Point,
    chartScales: UseChartScales,
    zoom: GraphZoom,
  ) {
    const { xMin, xMax, yMin, yMax } = chartScales
    if (yMin === undefined || yMax === undefined) {
      return false
    }
    return (
      point[0] > (zoom?.x_min ?? xMin) &&
      point[0] < (zoom?.x_max ?? xMax) &&
      point[1] > (zoom?.y_min ?? yMin) &&
      point[1] < (zoom?.y_max ?? yMax)
    )
  }

  private checkIfPotentialNewLineIntersectsAnyExistingLine(
    selectionPoints: Polygon,
    newPoint: Point,
  ) {
    const lastPoint = selectionPoints[selectionPoints.length - 1]
    const potentialNewLine = [lastPoint, newPoint] as Line

    for (let i = 0; i < selectionPoints.length - 2; i++) {
      const existingLine = [selectionPoints[i], selectionPoints[i + 1]] as Line
      if (lineIntersectsLine(potentialNewLine, existingLine)) {
        return true
      }
    }
    return false
  }
}
