import {
  TableCell as MuiTableCell,
  TableHead as MuiTableHead,
  TableRow as MuiTableRow,
} from '@material-ui/core'
import { FC } from 'react'
import styled from 'styled-components'

import { Checkbox } from 'components/Checkbox'

import { TableColumn } from './Table'
import { TableSortButton } from './TableSortButton'

type TableHeadProps = {
  columns: TableColumn[]
  currentSortingColumn?: string
  currentSortingDirection?: 'asc' | 'desc'
  areAllSelected?: boolean
  isSelectionDisabled?: boolean
  hasCollapsibleRows?: boolean
  hasSelectableRows?: boolean
  selectAllProps?: Record<string, unknown>
  onSortingChange?: (column: string) => void
  onToggleSelectAll?: () => void
}

export const TableHead: FC<TableHeadProps> = ({
  columns,
  currentSortingColumn,
  currentSortingDirection,
  areAllSelected,
  isSelectionDisabled,
  hasCollapsibleRows,
  hasSelectableRows,
  selectAllProps,
  onSortingChange,
  onToggleSelectAll,
}) => {
  return (
    <MuiTableHead>
      <MuiTableRow>
        {hasCollapsibleRows || hasSelectableRows ? (
          <StyledTableCell style={{ width: 80 }} />
        ) : null}
        {onToggleSelectAll ? (
          <StyledTableCell>
            {onToggleSelectAll && (
              <Checkbox
                checked={areAllSelected}
                disabled={isSelectionDisabled}
                onChange={onToggleSelectAll}
                checkboxProps={selectAllProps}
              />
            )}
          </StyledTableCell>
        ) : null}
        {columns.map(column => (
          <StyledTableCell key={column.key} style={column.style}>
            <CellContentWrapper>
              <Label>{column.label}</Label>
              {column.canSort && column.label ? (
                <TableSortButton
                  isActive={currentSortingColumn === column.key}
                  direction={currentSortingDirection}
                  onClick={() => onSortingChange?.(column.key as string)}
                />
              ) : null}
            </CellContentWrapper>
          </StyledTableCell>
        ))}
      </MuiTableRow>
    </MuiTableHead>
  )
}

const StyledTableCell = styled(MuiTableCell)`
  color: ${props => props.theme.colors.primaryDark[50]};
  overflow: hidden;
  text-overflow: ellipsis;
  border-bottom: none;
  padding-right: 16px;

  &:first-child {
    padding-left: ${props => props.theme.spacing(4)}px;
  }

  &:last-child {
    padding-right: ${props => props.theme.spacing(4)}px;
  }
`

const Label = styled.div`
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
`

const CellContentWrapper = styled.div`
  display: flex;
  align-items: center;
`
