import { useTheme } from '@material-ui/core'
import React, { FC, useMemo, useState } from 'react'
import styled from 'styled-components'

import { Icon } from 'assets/images/icons/Icon'

import { Button } from 'components/Button'
import { ErrorBoxAlert } from 'components/ErrorBoxAlert'
import { Modal } from 'components/Modal'

import { Graph } from 'shared/models/Graphs'
import { useAppDispatch, useAppSelector } from 'shared/store'

import { AppTheme } from 'Theme'

import { Gate } from './Gate'
import { duplicateGate } from './store/analysis.slice'
import { selectChartScales, selectCharts } from './store/selectors'

type GateListProps = {
  graph: Graph
  gates: Graph.Gate[]
  gatingMode: Graph.GateTypes
  tmpGatingMode: Graph.GateTypes
  showGatingChangeModal: boolean
  setGatingMode(gatingMode: Graph.GateTypes): void
  setTmpGatingMode(tmpGatingMode: Graph.GateTypes): void
  setShowGatingChangeModal(userToggle: boolean): void
  onCloseClick(): void
  onGateUpdated(id: string, value: Graph.GateValues): void
  onAccept(gateId: string, gateName: string): void
  onCancel(gate: Graph.Gate): void
  onRemove(gate: Graph.Gate): void
}

enum DuplicationStatus {
  HIDDEN = 'HIDDEN',
  SHOWN = 'SHOWN',
  OOB_ERROR = 'OOB_ERROR',
  SAME_COORDS_ERROR = 'SAME_COORDS_ERROR',
}

export const GateList: FC<GateListProps> = ({
  graph,
  gates,
  gatingMode,
  showGatingChangeModal,
  tmpGatingMode,
  setGatingMode,
  setShowGatingChangeModal,
  setTmpGatingMode,
  onCloseClick,
  onGateUpdated,
  onAccept,
  onCancel,
  onRemove,
}) => {
  // Hooks
  const theme = useTheme<AppTheme>()

  const dispatch = useAppDispatch()
  const charts = useAppSelector(selectCharts)
  const chartScales = useAppSelector(selectChartScales)

  const [duplicationStatus, setDuplicationStatus] = useState(
    DuplicationStatus.HIDDEN,
  )
  const [gateToDuplicate, setGateToDuplicate] = useState<Graph.Gate | null>(
    null,
  )

  const displayGateDuplicationHandler = (gate: Graph.Gate) => {
    setGateToDuplicate(gate)
    setDuplicationStatus(DuplicationStatus.SHOWN)
  }

  const onGateDuplicationHandler = (
    destinationGraph: Graph,
    gate: Graph.Gate,
  ) => {
    setDuplicationStatus(DuplicationStatus.SHOWN)

    // verifying that the gate doesn't go "out of bounds" on the other graph
    const graphXScale = chartScales[destinationGraph.id]?.xAxis
    const graphYScale = chartScales[destinationGraph.id]?.yAxis

    if (!graphXScale || !graphYScale) {
      return
    }

    const isOutOfBoundsHorizontally =
      gate.xMin < graphXScale.min ||
      gate.xMin > graphXScale.max ||
      gate.xMax < graphXScale.min ||
      gate.xMax > graphXScale.max
    const isOutOfBoundsVertically =
      gate.yMin < graphYScale.min ||
      gate.yMin > graphYScale.max ||
      gate.yMax < graphYScale.min ||
      gate.yMax > graphYScale.max

    if (isOutOfBoundsHorizontally || isOutOfBoundsVertically) {
      setDuplicationStatus(DuplicationStatus.OOB_ERROR)
      return
    }

    // verifying that a gate on the destination graph doesn't have the same coordinates
    const duplicatedValues = {
      xMin: gate.xMin,
      xMax: gate.xMax,
      yMin: gate.yMin,
      yMax: gate.yMax,
    }
    const hasGateWithSameCoordinates = destinationGraph.gates.some(
      g =>
        g.xMin === duplicatedValues.xMin &&
        g.xMax === duplicatedValues.xMax &&
        g.yMin === duplicatedValues.yMin &&
        g.yMax === duplicatedValues.yMax,
    )
    if (hasGateWithSameCoordinates) {
      setDuplicationStatus(DuplicationStatus.SAME_COORDS_ERROR)
      return
    }

    dispatch(duplicateGate({ targetChartId: destinationGraph.id, gate }))
    setDuplicationStatus(DuplicationStatus.HIDDEN)
  }

  const onCloseClickHandler = () => {
    if (duplicationStatus === DuplicationStatus.HIDDEN) {
      onCloseClick()
    } else {
      setDuplicationStatus(DuplicationStatus.HIDDEN)
    }
  }

  const changeGatingMode = (mode: Graph.GateTypes) => {
    setTmpGatingMode(mode)
    const hasTmpGates = graph.gates.some(
      ga => ga.tempValues !== undefined || (ga.xMin === 0 && ga.xMax === 0),
    )
    if (hasTmpGates) {
      setShowGatingChangeModal(true)
    } else {
      setGatingMode(mode)
    }
  }

  const possibleDestinationGraphs = useMemo(() => {
    return charts.filter(g => g.id !== graph.id && g.chart_type === 'Dot plot')
  }, [graph, charts])
  return (
    <Container>
      <Header>
        <HeaderLeft>
          <Icon name="gating" color={theme.colors.primaryDark[100]} />
          <Title>
            {duplicationStatus === DuplicationStatus.HIDDEN
              ? 'Gating List'
              : 'Gate duplication'}
          </Title>
          <HeaderTextSeparator>{'-'}</HeaderTextSeparator>
          <HeaderText>Mode : </HeaderText>
          <StyledButton
            enabled={gatingMode === 'rectangle'}
            onClick={() => changeGatingMode('rectangle')}
          >
            Rectangle
          </StyledButton>
          <StyledButton
            enabled={gatingMode === 'polygon'}
            onClick={() => changeGatingMode('polygon')}
          >
            Polygon
          </StyledButton>
        </HeaderLeft>
        <CloseIconWrapper onClick={onCloseClickHandler}>
          <Icon name="close" stroke={theme.colors.primaryDark[100]} />
        </CloseIconWrapper>
      </Header>
      {showGatingChangeModal && (
        <Modal open onClose={() => setShowGatingChangeModal(false)}>
          <ModalContainer>
            <div>
              Changing the gating mode will delete all unsaved gates, are you
              sure you want to proceed?
            </div>
            <div>
              <ModalButton grey onClick={() => setShowGatingChangeModal(false)}>
                Cancel
              </ModalButton>
              <ModalButton
                onClick={() => {
                  setGatingMode(tmpGatingMode)
                  setShowGatingChangeModal(false)
                }}
              >
                Confirm
              </ModalButton>
            </div>
          </ModalContainer>
        </Modal>
      )}
      {duplicationStatus === DuplicationStatus.HIDDEN ? (
        <Scrollable>
          {gates.map(g => (
            <Gate
              graph={graph}
              key={g.id}
              gate={g}
              onGateUpdated={onGateUpdated}
              onAccept={onAccept}
              onCancel={onCancel}
              onRemove={onRemove}
              canDuplicateGate={possibleDestinationGraphs.length > 0}
              displayGateDuplicationHandler={displayGateDuplicationHandler}
            />
          ))}
        </Scrollable>
      ) : (
        <Scrollable>
          <GraphSelectTitle>Select graph destination</GraphSelectTitle>
          <GraphsListWrapper>
            {possibleDestinationGraphs.map(g => (
              <SelectableGraph
                key={g.id}
                onClick={() => {
                  if (gateToDuplicate) {
                    onGateDuplicationHandler(g, gateToDuplicate)
                  }
                }}
              >
                {g.name}
              </SelectableGraph>
            ))}
          </GraphsListWrapper>
          {duplicationStatus === DuplicationStatus.OOB_ERROR && (
            <DuplicationErrorContainer>
              <ErrorBoxAlert>
                This gate couldn't be duplicated because it would not appear on
                the destination graph.
              </ErrorBoxAlert>
            </DuplicationErrorContainer>
          )}
          {duplicationStatus === DuplicationStatus.SAME_COORDS_ERROR && (
            <DuplicationErrorContainer>
              <ErrorBoxAlert>
                This gate cannot be duplicated to the destination graph because
                there is already a gate on that graph with the same coordinates.
              </ErrorBoxAlert>
            </DuplicationErrorContainer>
          )}
        </Scrollable>
      )}
    </Container>
  )
}

const Container = styled.div`
  grid-area: gate-list;
  display: flex;
  flex-direction: column;
  border: 1px solid ${props => props.theme.colors.primaryDark[20]};
  background-color: ${props => props.theme.colors.white};
  border-radius: ${props => props.theme.radius[2]}px;
  max-height: 80vh;
  width: 100%;
  height: 300px;
`
const Header = styled.div`
  width: 100%;
  height: 50px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid ${props => props.theme.colors.primaryDark[20]};
  padding: 0 25px;
`
const HeaderLeft = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`
const Title = styled.h3`
  font-size: ${props => props.theme.font.size.smallest}px;
  margin-left: 10px;
`
const HeaderTextSeparator = styled.h3`
  font-size: ${props => props.theme.font.size.smallest}px;
  margin-left: 10px;
  margin-right: 10px;
`
const HeaderText = styled.h3`
  font-size: ${props => props.theme.font.size.smallest}px;
  margin-right: 5px;
`
const CloseIconWrapper = styled.div`
  display: flex;
  cursor: pointer;
`
const StyledButton = styled(Button)<{ enabled: boolean }>`
  width: 70px;
  height: 24px;
  cursor: pointer;
  background-color: ${props =>
    props.enabled ? props.theme.colors.primary[100] : 'transparent'};
  color: ${props =>
    props.enabled ? 'white' : props.theme.colors.primary[100]};
  font-size: ${props => props.theme.font.size.smallest}px;
  margin-left: 2px;
  margin-right: 2px;
  &:hover {
    background-color: ${props =>
      props.enabled ? props.theme.colors.primary[100] : 'transparent'};
    color: ${props =>
      props.enabled ? 'white' : props.theme.colors.primary[100]};
  }
`
const Scrollable = styled.div`
  height: 100%;
  flex: 1;
  width: 100%;
  overflow-y: auto;
`
const GraphSelectTitle = styled.p`
  margin: 8px 24px;
  font-size: ${props => props.theme.font.size.h3}px;
  font-weight: bold;
`
const GraphsListWrapper = styled.div`
  display: flex;
  flex-direction: column;
`
const SelectableGraph = styled.span`
  cursor: pointer;
  margin: 8px 24px;
  padding-left: 8px;
  border-left: 2px solid ${props => props.theme.colors.white};
  transition: border 300ms ease;
  &:hover {
    border-left: 2px solid ${props => props.theme.colors.primaryDark[100]};
  }
`
const DuplicationErrorContainer = styled.div`
  margin: 8px 24px;
`
const ModalContainer = styled.div`
  background-color: ${props => props.theme.colors.white};
  border-radius: ${props => props.theme.radius[2]}px;
  width: 30%;
  height: auto;
  min-height: 15%;
  max-height: 30%;
  padding: 28px 24px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  overflow-y: auto;
`
const ModalButton = styled(Button)`
  margin: 10px;
  height: 15px;
`
