import { forwardRef } from 'react'
import styled from 'styled-components'

import { ReactComponent as PlusIcon } from 'assets/images/icons/plus.svg'

type FilterButtonProps = {
  title: string
  isSelected: boolean
  onClick: () => void
}

export const FilterButton = forwardRef<HTMLButtonElement, FilterButtonProps>(
  ({ title, isSelected, onClick }, ref) => {
    return (
      <FilterButtonRoot
        ref={ref}
        onClick={onClick}
        $isSelected={isSelected}
        type="button"
      >
        <span>{title}</span>
        <StyledPlusIcon />
      </FilterButtonRoot>
    )
  },
)

const StyledPlusIcon = styled(PlusIcon)``

const FilterButtonRoot = styled.button<{ $isSelected: boolean }>`
  display: flex;
  align-items: center;
  gap: ${props => props.theme.spacing(1)}px;
  width: auto;
  height: 42px;
  padding: 0 ${props => props.theme.spacing(3)}px;
  background: ${props => props.theme.colors.white};
  border: none;
  border-radius: 21px;
  box-shadow: 0px 4px 10px 0px #e8e9f399;
  font-size: ${props => props.theme.font.size.smallest}px;
  color: ${props =>
    props.theme.colors.primaryDark[props.$isSelected ? 100 : 50]};
  cursor: pointer;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;

  & ${StyledPlusIcon} > path {
    fill: ${props =>
      props.theme.colors.primaryDark[props.$isSelected ? 100 : 50]};
  }

  &:hover {
    background: ${props => props.theme.colors.greyscale[10]};
    color: ${props => props.theme.colors.primaryDark[100]};

    & ${StyledPlusIcon} > path {
      fill: ${props => props.theme.colors.primaryDark[100]};
    }
  }
`
