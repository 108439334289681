import { Fade } from '@material-ui/core'
import React from 'react'
import styled from 'styled-components'

import { Icon } from 'assets/images/icons/Icon'

import { Button } from 'components/Button'
import { Modal } from 'components/Modal'

type ExitConfirmationModalProps = Readonly<{
  open: boolean
  onConfirm: () => void
  onCancel: () => void
  loading: boolean
}>

export const ExitConfirmationModal = ({
  open,
  onConfirm,
  onCancel,
  loading,
}: ExitConfirmationModalProps): JSX.Element => {
  return (
    <Modal open={open} onClose={onCancel}>
      <Fade in={open}>
        <Container>
          <Title>End the logbook session</Title>
          <ConfirmText>
            You are about to end your logbook session. <br />
            Are you sure you want to continue ?
          </ConfirmText>
          <Actions>
            <Button grey onClick={onCancel}>
              Cancel
            </Button>
            <ConfirmButton
              color="primary"
              onClick={onConfirm}
              endIcon={<Icon name="arrowRight" />}
              loading={loading}
              data-cy="confirm-btn"
            >
              Quit
            </ConfirmButton>
          </Actions>
        </Container>
      </Fade>
    </Modal>
  )
}

const Container = styled.div`
  background-color: ${props => props.theme.colors.background};
  border-radius: ${props => props.theme.radius[2]}px;
  display: flex;
  flex-direction: column;
  padding: 32px 64px;
`

const Title = styled.div`
  text-align: center;
  font-size: ${props => props.theme.font.size.h2}px;
  font-family: ${props => props.theme.font.style.bold};
`

const ConfirmText = styled.div`
  font-size: ${props => props.theme.font.size.h3}px;
  text-align: center;
  padding: 32px 0 64px;
`

const ConfirmButton = styled(Button)`
  width: 125px;
  margin-left: 32px;
`

const Actions = styled.div`
  display: flex;
  justify-content: center;
`
