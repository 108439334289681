import { LassoToolState } from './LassoTool'

export class LassoHighcharts {
  private state: LassoToolState

  constructor(state: LassoToolState) {
    this.state = state
  }

  getChart(): Highcharts.Chart | undefined {
    return this.state.scatterPlotRef.current?.chart
  }
}
