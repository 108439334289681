import { FC, ReactNode } from 'react'
import { Redirect, useLocation } from 'react-router'

import { LoadingMask } from 'components/LoadingMask'

import { OrganizationVerificationModal } from 'pages/organization/OrganizationVerificationModal'

import { useGetOrganizationQuery } from 'shared/api/organization.api'
import { useAppSelector } from 'shared/store'
import { selectIsAuthenticated } from 'shared/store/auth.slice'

type PrivateRouteCheckProps = { children: ReactNode }

export const PrivateRouteCheck: FC<PrivateRouteCheckProps> = ({ children }) => {
  const location = useLocation()
  const isAuthenticated = useAppSelector(selectIsAuthenticated)
  const organizationQueryState = useGetOrganizationQuery(undefined, {
    skip: !isAuthenticated,
    refetchOnMountOrArgChange: true,
  })

  if (!isAuthenticated) {
    return <Redirect to={`/log-in?redirect=${location.pathname}`} />
  }

  if (!organizationQueryState.data) {
    return <LoadingMask />
  }

  if (!organizationQueryState.data.verified) {
    return <OrganizationVerificationModal />
  }

  return children
}
