import { Close } from '@material-ui/icons'
import styled from 'styled-components'

import { DotLabel } from 'components/DotLabel'

import { Cluster } from 'pages/analysis/store/selectors'

import { LassoTooltip } from './LassoTooltip'

type AnalysisScatterPlotTooltipProps = {
  name: string
  color: string
  parentRate: number
  numberOfEvents: number
  hoveredLassoName?: string
  hoveredLassoClusters?: Cluster[]
}

export const AnalysisScatterPlotTooltip: React.FC<
  AnalysisScatterPlotTooltipProps
> = ({
  name,
  color,
  parentRate,
  numberOfEvents,
  hoveredLassoName,
  hoveredLassoClusters = [],
}) => {
  return (
    <AnalysisScatterPlotTooltipRoot>
      <Header>
        <ClusterLabel>{name}</ClusterLabel>
        <StyledDotLabel $color={color} />
        <Close />
      </Header>
      <ParentRate>
        {parentRate === 1 ? '100' : parentRate}% of parent
      </ParentRate>
      <NumberOfEvents>{numberOfEvents} events</NumberOfEvents>
      {hoveredLassoName && (
        <StyledLassoTooltip
          name={hoveredLassoName}
          clusters={hoveredLassoClusters}
        />
      )}
    </AnalysisScatterPlotTooltipRoot>
  )
}

const AnalysisScatterPlotTooltipRoot = styled.div`
  max-height: 80vh;
  overflow: hidden;
  padding: 12px;
`

const Header = styled.div`
  display: flex;
  align-items: center;
  padding-bottom: 3px;

  & > .MuiSvgIcon-root {
    font-size: 20px;
    cursor: pointer;
  }
`

const ClusterLabel = styled.div`
  font-family: ${props => props.theme.font.style.bold};
  font-size: 14px;
`

const StyledDotLabel = styled(DotLabel)`
  margin-left: auto;
`

const ParentRate = styled.div`
  display: flex;
  align-items: center;
  padding-bottom: 3px;
`

const NumberOfEvents = styled.div`
  display: flex;
  align-items: center;
`

const StyledLassoTooltip = styled(LassoTooltip)`
  margin-top: 12px;
`
