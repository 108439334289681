import { chunk, groupBy, range, uniqueId } from 'lodash'

import { Channel, Cluster } from 'pages/analysis/store/selectors'

import {
  ANALYSIS_HEATMAP_ID,
  ANALYSIS_HIERARCHY_SUNBURST_ID,
} from 'shared/constants'

export const CLUSTER_LIST_COLUMNS = 5
const HIERARCHY_PAGE_CLUSTER_LIST_ROWS = 8
const ENTIRE_PAGE_CLUSTER_LIST_ROWS = 29
const CHART_GROUP_COLUMNS = 2
const ENTIRE_PAGE_CHART_GROUP_ROWS = 4
const NUMBER_OF_CLUSTER_LIST_ROWS_PER_CHART_GROUP_ROW = 6
const NUMBER_OF_CLUSTERS_PER_2_CHART_GROUP_ROWS = 13
const NUMBER_OF_CLUSTERS_PER_3_CHART_GROUP_ROWS = 23
const ENTIRE_PAGE_STATISTICS_LIST_ROWS = 39

type GroupAnalysisPrintItemsProps = {
  activeLeavesCount: number
  activeClusters: Cluster[]
  sortedChartIds: string[]
  channels: Channel[]
  statisticsItems: Analysis.StatisticsItem[]
}

type GroupAnalysisPrintItemsResult = {
  globalClusters: GroupedClusters
  lastClustersPageChartIds: string[]
  entirePageChartGroups: string[][]
  lastChartsPageChartIds: string[] | undefined
  lastChartsPageHeatmapClustersCount: number
  groupedStatisticsItems: GroupedStatisticsItems
}

type GroupedClusters = {
  firstPageClusters: Cluster[]
  entirePageClusterGroups: Cluster[][]
  lastClustersPageClusters: Cluster[] | undefined
}

type GroupedStatisticsItems = {
  entirePageStatisticsGroups: {
    statisticsItems: Analysis.StatisticsItem[]
  }[]
}

export const groupAnalysisPrintItems = ({
  activeLeavesCount,
  activeClusters,
  sortedChartIds,
  statisticsItems,
}: GroupAnalysisPrintItemsProps): GroupAnalysisPrintItemsResult => {
  const globalClusters = groupClusters(
    activeClusters,
    HIERARCHY_PAGE_CLUSTER_LIST_ROWS,
  )

  const {
    lastClustersPageChartIds,
    entirePageChartGroups,
    lastChartsPageChartIds,
  } = groupCharts(sortedChartIds, globalClusters.lastClustersPageClusters)

  const lastChartsPageChartRows = Math.ceil(
    (lastChartsPageChartIds?.length ?? 0) / 2,
  )
  const lastChartsPageHeatmapClustersCount = Math.min(
    lastChartsPageChartRows === 1
      ? NUMBER_OF_CLUSTERS_PER_3_CHART_GROUP_ROWS
      : lastChartsPageChartRows === 2
      ? NUMBER_OF_CLUSTERS_PER_2_CHART_GROUP_ROWS
      : 0,
    activeLeavesCount,
  )

  const groupedStatisticsItems = groupStatisticsItems(statisticsItems)

  return {
    globalClusters,
    lastClustersPageChartIds,
    entirePageChartGroups,
    lastChartsPageChartIds,
    lastChartsPageHeatmapClustersCount,
    groupedStatisticsItems: groupedStatisticsItems,
  }
}

const groupClusters = (
  activeClusters: Cluster[],
  firstPageClusterListRows: number,
) => {
  const [firstPageClusters, ...remainingClusterGroups] = [
    activeClusters.slice(0, firstPageClusterListRows * CLUSTER_LIST_COLUMNS),
    ...chunk(
      activeClusters.slice(firstPageClusterListRows * CLUSTER_LIST_COLUMNS),
      ENTIRE_PAGE_CLUSTER_LIST_ROWS * CLUSTER_LIST_COLUMNS,
    ),
  ]

  const entirePageClusterGroups = remainingClusterGroups.slice(0, -1)
  const lastClustersPageClusters = remainingClusterGroups.at(-1)

  return {
    firstPageClusters,
    entirePageClusterGroups,
    lastClustersPageClusters,
  }
}

const groupCharts = (
  sortedChartIds: string[],
  lastClustersPageClusters: Cluster[] | undefined,
) => {
  const chartIds = sortedChartIds.filter(
    id => id !== ANALYSIS_HIERARCHY_SUNBURST_ID && id !== ANALYSIS_HEATMAP_ID,
  )

  const lastClustersPageAvailableChartRows = lastClustersPageClusters
    ? Math.max(
        0,
        ENTIRE_PAGE_CHART_GROUP_ROWS -
          Math.ceil(
            Math.ceil(lastClustersPageClusters.length / CLUSTER_LIST_COLUMNS) /
              NUMBER_OF_CLUSTER_LIST_ROWS_PER_CHART_GROUP_ROW,
          ),
      )
    : 0

  const lastClustersPageChartIds = chartIds.slice(
    0,
    lastClustersPageAvailableChartRows * CHART_GROUP_COLUMNS,
  )

  const remainingChartIds = chartIds.slice(lastClustersPageChartIds.length)
  const remainingChartGroups = chunk(
    remainingChartIds,
    CHART_GROUP_COLUMNS * ENTIRE_PAGE_CHART_GROUP_ROWS,
  )
  const entirePageChartGroups = remainingChartGroups.slice(0, -1)
  const lastChartsPageChartIds = remainingChartGroups.at(-1)

  return {
    lastClustersPageChartIds,
    entirePageChartGroups,
    lastChartsPageChartIds,
  }
}

const groupStatisticsItems = (statisticsItems: Analysis.StatisticsItem[]) => {
  const remainingRows = statisticsItems.flatMap(statisticsItem => {
    return statisticsItem.statistics.reportables.flatMap(reportable => {
      const groupedStatistics = groupBy(reportable.statistics, 'type')
      const numberOfRows = Math.max(
        ...Object.values(groupedStatistics).map(group => group.length),
      )
      const reportableUniqueId = uniqueId()
      return range(numberOfRows).map(index => {
        return {
          statisticsItem,
          reportable: {
            ...reportable,
            __uniqueId: reportableUniqueId,
          },
          numberOfRows,
          index,
        }
      })
    })
  })

  type Row = (typeof remainingRows)[0]

  const takeStatisticsItems = () => {
    let availableRowCount = ENTIRE_PAGE_STATISTICS_LIST_ROWS
    const takenRows: Row[] = []
    let lastReportableStatisticsItem: Analysis.StatisticsItem | undefined

    while (remainingRows.length > 0) {
      const consideredRow = remainingRows[0]
      const usedRows =
        1 +
        (consideredRow.statisticsItem !== lastReportableStatisticsItem ? 4 : 0)
      if (usedRows <= availableRowCount) {
        takenRows.push(consideredRow)
        remainingRows.shift()
        availableRowCount -= usedRows
        lastReportableStatisticsItem = consideredRow.statisticsItem
      } else {
        break
      }
    }

    type TakenStatisticsItem = Analysis.StatisticsItem & {
      statistics: {
        reportables: (Analysis.Reportable & {
          __uniqueId: string
          __indices: number[]
        })[]
      }
    }
    const takenStatisticsItems: TakenStatisticsItem[] = []
    while (takenRows.length > 0) {
      const row = takenRows.shift()!
      let statisticsItem = takenStatisticsItems.at(-1)
      if (!statisticsItem || statisticsItem.id !== row.statisticsItem.id) {
        statisticsItem = {
          ...row.statisticsItem,
          statistics: { reportables: [] },
        }
        takenStatisticsItems.push(statisticsItem)
      }

      let reportable =
        statisticsItem.statistics.reportables[
          statisticsItem.statistics.reportables.length - 1
        ]
      if (!reportable || reportable.__uniqueId !== row.reportable.__uniqueId) {
        reportable = { ...row.reportable, __indices: [] }
        statisticsItem.statistics.reportables.push(reportable)
      }

      reportable.__indices.push(row.index)
    }

    return takenStatisticsItems.map(statisticsItem => {
      return {
        ...statisticsItem,
        statistics: {
          reportables: statisticsItem.statistics.reportables.map(
            (
              reportable: TakenStatisticsItem['statistics']['reportables'][number],
            ) => {
              const groupedStatistics = groupBy(reportable.statistics, 'type')
              const min = Math.min(...reportable.__indices)
              const max = Math.max(...reportable.__indices)
              const statistics = Object.values(groupedStatistics).flatMap(
                group => {
                  return group.slice(min, max + 1)
                },
              )
              return {
                ...reportable,
                statistics,
              }
            },
          ),
        },
      }
    })
  }
  const entirePageStatisticsGroups: {
    statisticsItems: Analysis.StatisticsItem[]
  }[] = []
  while (remainingRows.length > 0) {
    entirePageStatisticsGroups.push({ statisticsItems: takeStatisticsItems() })
  }

  return { entirePageStatisticsGroups }
}
