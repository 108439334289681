export type GraphType = 'Dot plot' | 'Histogram'

export type GraphZoom = {
  x_min: number
  x_max: number
  y_min: number
  y_max: number
} | null

export type Graph = {
  id: string
  chart_type: GraphType
  depth: number
  active_leaf_ids: string[]
  hidden_cluster_ids: string[]
  gates: Graph.Gate[]
  name: string
  default_name: string
  x_axis: string
  y_axis?: string
  x_axis_scale_type: Highcharts.AxisTypeValue
  y_axis_scale_type: Highcharts.AxisTypeValue
  duplicated?: boolean
  zoom: GraphZoom
  is_default?: boolean
  event_limit: number
  lasso_ids: {
    [lassoId: string]: boolean
  }
  parent_lasso_id: string | undefined
  lassos_hierarchy: ImmutableLasso[]
  isUnsaved?: boolean
  created_at: string
  display_settings?: GraphDisplaySettings
}

export type ImmutableLasso = {
  polygon: [number, number][]
  x_axis: string
  y_axis: string
}

export type GraphDisplaySettings = {
  dot_sizing_mode?: DotSizingMode
}

export const DOT_SIZING_MODES = ['uniform', 'frequency'] as const

export type DotSizingMode = (typeof DOT_SIZING_MODES)[number]
