import { Grid } from '@material-ui/core'
import { Pagination } from '@material-ui/lab'
import styled from 'styled-components'

import { TableColumn } from 'components/TableColumn'

import { ExportTask } from 'shared/api/export.api'
import { SortingOrder } from 'shared/utils/array'

import { ExportTaskRow } from './ExportTaskRow'

const columnHeadersWithSize = [
  { header: 'Export type', size: 2 },
  { header: 'File name', size: 3 },
  { header: 'Format', size: 1 },
  { header: 'Created', size: 2 },
  { header: 'Updated', size: 2 },
  { header: 'Status', size: 1 },
  { header: 'Actions', size: 1 },
] as const

export type ExportTaskHeader = (typeof columnHeadersWithSize)[number]['header']

type ExportTaskTableProps = {
  exportTasks: ExportTask[]
  page: number
  numberOfPages: number
  sortingCategory: ExportTaskHeader
  sortingOrder: SortingOrder
  onPageChange: (page: number) => void
  onSortingCategoryChange: (sortingCategory: ExportTaskHeader) => void
  onSortingOrderChange: (sortingOrder: SortingOrder) => void
}

export const ExportTaskTable = ({
  exportTasks,
  page,
  numberOfPages,
  sortingCategory,
  sortingOrder,
  onPageChange,
  onSortingCategoryChange,
  onSortingOrderChange,
}: ExportTaskTableProps): JSX.Element => {
  return (
    <TableWrapper>
      <TableHeader container spacing={0}>
        {columnHeadersWithSize.map(({ header, size }) => (
          <TableColumn<ExportTaskHeader>
            key={header}
            columnName={header}
            size={size}
            currentSortingCategory={sortingCategory}
            currentSortingOrder={sortingOrder}
            setSortingCategory={onSortingCategoryChange}
            setSortingOrder={onSortingOrderChange}
          />
        ))}
      </TableHeader>
      {exportTasks.length ? (
        <>
          {exportTasks.map(
            ({
              id,
              export_class_display,
              file_name,
              format,
              created_at,
              updated_at,
              status,
              status_display,
              is_stale,
            }) => (
              <ExportTaskRow
                key={id}
                id={id}
                exportType={export_class_display}
                fileName={file_name}
                format={format}
                createdAt={created_at}
                updatedAt={updated_at}
                status={status}
                statusText={status_display}
                is_stale={is_stale}
              />
            ),
          )}
          <PaginationContainer>
            <Pagination
              count={numberOfPages}
              onChange={(_event, page) => onPageChange(page)}
              page={page}
            />
          </PaginationContainer>
        </>
      ) : (
        <EmptyListMessageContainer>
          No export tasks found
        </EmptyListMessageContainer>
      )}
    </TableWrapper>
  )
}

const TableWrapper = styled.div`
  width: 100%;
  margin: 20px 0;
`

const TableHeader = styled(Grid)`
  color: ${props => props.theme.colors.greyscale[50]};
  font-family: ${props => props.theme.font.style.bold};
  align-items: center;
  padding: 0 10px 10px;
`
const PaginationContainer = styled.div`
  display: flex;
  justify-content: center;
  margin-top: ${props => props.theme.spacing(3)}px;
`

const EmptyListMessageContainer = styled.div`
  display: flex;
  justify-content: center;
  margin-top: ${props => props.theme.spacing(4)}px;
`
