import { RadioGroup, Typography } from '@material-ui/core'
import { useState } from 'react'
import styled from 'styled-components'

import { Button } from 'components/Button'
import { Modal } from 'components/Modal'
import RadioButton from 'components/RadioButton'

type FreeTrialModalProps = {
  onChange: (isTrial: boolean) => void
  onSubmit: () => void
  onClose: () => void
}

export const FreeTrialModal: React.FC<FreeTrialModalProps> = ({
  onChange,
  onSubmit,
  onClose,
}) => {
  const [selectedOption, setSelectedOption] = useState<string | null>(null)

  const handleSubmit = () => {
    onSubmit()
    onClose()
  }

  return (
    <Modal open={true} onClose={onClose}>
      <Container>
        <Typography variant="h4">Select your plan</Typography>
        <Typography variant="body1" align="center">
          Choose whether you'd like to try out or purchase Metaflow
        </Typography>
        <StyledRadioGroup
          value={selectedOption}
          onChange={event => {
            setSelectedOption(event.target.value)
            onChange(event.target.value === 'trial')
          }}
        >
          <RadioButtonWrapper $isSelected={selectedOption === 'trial'}>
            <PlanTitle variant="h5" align="center">
              Try Metaflow
            </PlanTitle>
            <Typography variant="body1" align="center">
              30-day free trial includes limited resources and storage capacity.
              You can purchase a license at any time.
            </Typography>
            <RadioButton label="Start free trial" value="trial" />
          </RadioButtonWrapper>
          <RadioButtonWrapper $isSelected={selectedOption === 'notrial'}>
            <PlanTitle variant="h5" align="center">
              Purchase a license
            </PlanTitle>
            <Typography variant="body1" align="center">
              Choose and purchase a license on your first login and start using
              Metaflow right away.
            </Typography>
            <RadioButton label="Buy license" value="notrial" />
          </RadioButtonWrapper>
        </StyledRadioGroup>
        <Buttons>
          <Button grey onClick={onClose}>
            Back
          </Button>
          <Button disabled={selectedOption === null} onClick={handleSubmit}>
            Submit
          </Button>
        </Buttons>
      </Container>
    </Modal>
  )
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: ${props => props.theme.spacing(4)}px;
  width: 640px;
  padding: ${props => props.theme.spacing(4)}px;
  background: ${props => props.theme.colors.white};
  border-radius: ${props => props.theme.radius[2]}px;
`

const StyledRadioGroup = styled(RadioGroup)`
  &.MuiFormGroup-root {
    flex-direction: row;
    gap: ${props => props.theme.spacing(2)}px;
  }
`

const RadioButtonWrapper = styled.div<{ $isSelected: boolean }>`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  width: 240px;
  height: 300px;
  padding: ${props => props.theme.spacing(2)}px;
  background: ${props =>
    props.$isSelected
      ? props.theme.colors.primaryDark[100]
      : props.theme.colors.white};
  color: ${props =>
    props.$isSelected
      ? props.theme.colors.white
      : props.theme.colors.primaryDark[100]};
  border: 2px solid ${props => props.theme.colors.primaryDark[100]};
  border-radius: ${props => props.theme.radius[2]}px;

  & span {
    font-family: ${props => props.theme.font.style.bold};
  }

  & .MuiSvgIcon-root {
    fill: ${props =>
      props.$isSelected
        ? props.theme.colors.white
        : props.theme.colors.primaryDark[100]};
  }
`

const Buttons = styled.div`
  display: flex;
  gap: ${props => props.theme.spacing(2)}px;
`

const PlanTitle = styled(Typography)`
  font-family: ${props => props.theme.font.style.bold};
`
