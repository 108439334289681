import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { DistributiveOmit } from 'react-redux'
import { v4 as uuidv4 } from 'uuid'

import { Layout, LayoutElement } from 'components/chart-grid'

import { Experiment } from 'shared/api/experiments.api'
import { CompensatedFile, FcsFile } from 'shared/api/files.api'
import {
  MetaAnalysis,
  MetaAnalysisChart,
  MetaAnalysisFile,
  MetaAnalysisGlobalVizFile,
  MetaAnalysisVolcanoPlotFile,
} from 'shared/api/meta-analysis.api'
import { Pipeline } from 'shared/api/pipelines.api'
import { Project } from 'shared/api/projects.api'
import { Workflow } from 'shared/api/workflows.api'
import { LogActions } from 'shared/contexts/LogContext'

import type { MetaAnalysisSliceState } from './meta-analysis.slice'
import { selectNextLayoutPositions } from './selectors'

export type HistoryState = {
  isMetaAnalysisSaved: boolean
  isMetaAnalysisBeingSaved: boolean
  metaAnalysis: MetaAnalysis | undefined
  metaAnalysisFile: MetaAnalysisFile | undefined
  metaAnalysisGlobalVizFile: MetaAnalysisGlobalVizFile | undefined
  metaAnalysisVolcanoPlotFile: MetaAnalysisVolcanoPlotFile | undefined
  compensatedFileById: Record<string, CompensatedFile> | undefined
  fcsFileById: Record<string, FcsFile> | undefined
  workflow: Workflow | Pipeline | Experiment | undefined
  project: Project | undefined
  logs: { type: LogActions; created_at: string }[]
}

type HistoryStateWithDefinedMetaAnalysis = Omit<
  HistoryState,
  'metaAnalysis'
> & {
  metaAnalysis: MetaAnalysis
}

type WrappedMetaAnalysisSliceState = {
  metaAnalysisPage: { metaAnalysis: Required<MetaAnalysisSliceState> }
}

/** A higher order function for the historySlice case reducers.
 * Ensures data integrity. Injects the entire meta-analysis slice state. Updates the logs. */
const _ = <
  C extends (
    state: HistoryStateWithDefinedMetaAnalysis,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    action: any,
    wrappedMetaAnalysisSliceState: WrappedMetaAnalysisSliceState,
  ) => HistoryState | void,
>(
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  log: LogActions | ((action: any) => LogActions | undefined) | undefined,
  caseReducer: C,
) => {
  type A = C extends (
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    state: any,
    action: infer A,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    wrappedMetaAnalysisSliceState: any,
  ) => HistoryState | void
    ? A
    : never

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  return ((state: HistoryState, action: any) => {
    if (state.metaAnalysis === undefined) {
      throw new Error('Cannot perform action on undefined meta-analysis')
    }

    log = typeof log === 'function' ? log(action) : log
    if (log !== undefined) {
      state.logs.push({ type: log, created_at: new Date().toISOString() })
    }

    state.isMetaAnalysisSaved = false

    return caseReducer(
      state as HistoryStateWithDefinedMetaAnalysis,
      action,
      action.wrappedMetaAnalysisSliceState,
    )
  }) as unknown extends A
    ? (state: HistoryState) => HistoryState
    : (state: HistoryState, action: A) => HistoryState
}

export const INITIAL_HISTORY_STATE: HistoryState = {
  isMetaAnalysisSaved: true,
  isMetaAnalysisBeingSaved: false,
  metaAnalysis: undefined,
  metaAnalysisFile: undefined,
  metaAnalysisGlobalVizFile: undefined,
  metaAnalysisVolcanoPlotFile: undefined,
  compensatedFileById: undefined,
  fcsFileById: undefined,
  workflow: undefined,
  project: undefined,
  logs: [],
}

export const historySlice = createSlice({
  name: 'meta-analysis/modify',
  initialState: INITIAL_HISTORY_STATE,
  reducers: {
    renameMetaAnalysis: _(undefined, (state, action: PayloadAction<string>) => {
      state.metaAnalysis.name = action.payload
    }),
    changeSpecificLayout: _(
      LogActions.l_layout_changed,
      (
        { metaAnalysis },
        {
          payload: { layoutKey, layout },
        }: PayloadAction<{
          layoutKey: keyof Layout
          layout: LayoutElement
        }>,
      ) => {
        metaAnalysis.layout[layoutKey] = layout
      },
    ),
    renameMetaAnalysisChart: _(
      undefined,
      (
        { metaAnalysis },
        { payload: { id, name } }: PayloadAction<{ id: string; name: string }>,
      ) => {
        const chart = metaAnalysis.graphs.find(chart => chart.id === id)
        if (chart) {
          chart.name = name
        }
      },
    ),
    addMetaAnalysisChart: _(
      undefined,
      (
        state,
        action: PayloadAction<DistributiveOmit<MetaAnalysisChart, 'id'>>,
        wrappedMetaAnalysisSliceState,
      ) => {
        const nextLayoutPositions = selectNextLayoutPositions(
          wrappedMetaAnalysisSliceState,
        )

        const chart = {
          ...action.payload,
          id: uuidv4(),
        }
        state.metaAnalysis.graphs.push(chart)
        state.metaAnalysis.layout.single[chart.id] = {
          ...nextLayoutPositions.single,
          w: 1,
          h: 1,
        }
      },
    ),
    removeMetaAnalysisChart: _(
      undefined,
      (state, action: PayloadAction<string>) => {
        state.metaAnalysis.graphs = state.metaAnalysis.graphs.filter(
          chart => chart.id !== action.payload,
        )
        delete state.metaAnalysis.layout.single[action.payload]
      },
    ),
    updateMetaAnalysisChart: _(
      undefined,
      (
        { metaAnalysis },
        {
          payload: { id, ...chart },
        }: PayloadAction<{ id: string } & Partial<MetaAnalysisChart>>,
      ) => {
        const chartToUpdate = metaAnalysis.graphs.find(chart => chart.id === id)
        if (chartToUpdate) {
          Object.assign(chartToUpdate, chart)
        }
      },
    ),
  },
})

export const {
  renameMetaAnalysis,
  changeSpecificLayout,
  renameMetaAnalysisChart,
  addMetaAnalysisChart,
  removeMetaAnalysisChart,
  updateMetaAnalysisChart,
} = historySlice.actions
