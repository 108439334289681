import { ChartInfo } from './ChartInfo'

type DownsamplingInfoProps = {
  className?: string
}

export const DownsamplingInfo: React.FC<DownsamplingInfoProps> = ({
  className,
}): JSX.Element => {
  return (
    <ChartInfo
      className={className}
      title="There is no data for this graph"
      description="The chart is empty because the data was downsampled and at least one of the chosen channels was not used for clustering."
    />
  )
}
