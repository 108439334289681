import { TreeDataNode } from 'antd'

import { AnalysisTreeNodeTitle } from 'components/tree/AnalysisTreeNodeTitle'

import { WorkflowInternalBrick } from 'pages/project/WorkflowView'

import { Brick, BrickDetails } from 'shared/api/workflows.api'
import { AnalysisStatus } from 'shared/models/AnalysisModels'

export const findRootBricks = (bricks: Brick[]): WorkflowInternalBrick[] => {
  const internalBrickById: Record<string, WorkflowInternalBrick> = {}
  const rootInternalBricks: WorkflowInternalBrick[] = []
  for (const brick of bricks) {
    internalBrickById[brick.id] = { id: brick.id }
  }
  for (const brick of bricks) {
    if (brick.parent) {
      const parent = internalBrickById[brick.parent]
      if (!parent.children) {
        parent.children = []
      }
      parent.children.push(internalBrickById[brick.id])
    } else {
      rootInternalBricks.push(internalBrickById[brick.id])
    }
  }

  if (!rootInternalBricks) {
    throw new Error('Root brick not found')
  }

  return rootInternalBricks
}

type MapBrickToTreeDataOptions = {
  checkable?: boolean
  selectable?: boolean
  selectableElements?: string[]
}

export const mapBrickToTreeData = (
  bricks: WorkflowInternalBrick[],
  brickById: Record<string, BrickDetails> | undefined,
  options: MapBrickToTreeDataOptions,
  validateActionBasedOnAnalysisStatus?: (
    status: AnalysisStatus,
  ) => string | undefined,
): TreeDataNode[] => {
  if (!brickById) return []

  return bricks.map(brick => {
    return {
      key: brick.id,
      title: brickById[brick.id].name,
      checkable: false,
      selectable: false,
      children: [
        ...(brick.children
          ? mapBrickToTreeData(
              brick.children,
              brickById,
              options,
              validateActionBasedOnAnalysisStatus,
            )
          : []),
        ...brickById[brick.id].analyses.map(analysis => {
          const validationError = validateActionBasedOnAnalysisStatus?.(
            analysis.status,
          )
          return {
            key: analysis.id,
            title: <AnalysisTreeNodeTitle analysis={analysis} />,
            checkable: options.checkable,
            selectable: options.selectable,
            disabled:
              !!validationError ||
              (options.selectable &&
                !options.selectableElements?.includes(analysis.id)),
          }
        }),
      ],
    }
  })
}
