import { useDialog } from 'shared/contexts/DialogContext'
import { useSafeNavigate } from 'shared/hooks/router'
import { useAppSelector } from 'shared/store'

import { selectAnalysesByFcsFileId } from './store/selectors'

export const useNavigateToFile = (): ((fcsFileId: string) => void) => {
  const { showOptionDialog } = useDialog()
  const analysesByFcsFileId = useAppSelector(selectAnalysesByFcsFileId)

  const safeNavigate = useSafeNavigate()

  return async fcsFileId => {
    const analyses = analysesByFcsFileId[fcsFileId]
    if (analyses.length === 1) {
      safeNavigate(`/analysis/${analyses[0].id}`, { shouldOpenInNewTab: true })
    } else {
      showOptionDialog({
        title: 'Select an analysis',
        message: '',
        options: analyses.map(analysis => ({
          label: analysis.name,
          onClick: () => {
            safeNavigate(`/analysis/${analysis.id}`, {
              shouldOpenInNewTab: true,
            })
          },
        })),
      })
    }
  }
}
