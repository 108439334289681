import Highcharts, { TooltipFormatterCallbackFunction } from 'highcharts'
import {
  forwardRef,
  useImperativeHandle,
  useMemo,
  useRef,
  useState,
} from 'react'
import { renderToStaticMarkup } from 'react-dom/server'
import styled, { useTheme } from 'styled-components'

import { DefaultErrorBoundary } from 'components/DefaultErrorBoundary'
import { Modal } from 'components/Modal'
import { Chart } from 'components/graphs/Chart'

import {
  MetaAnalysisColors,
  MetaAnalysisFrequencyChartType,
} from 'shared/api/meta-analysis.api'
import { useEventCallback } from 'shared/hooks/useEventCallback'
import { useAppDispatch, useAppSelector } from 'shared/store'
import { downloadText, useSize } from 'shared/utils/utils'

import { MetaAnalysisChartContainer } from './MetaAnalysisChartContainer'
import { MetaAnalysisFrequencyChartBaseOptions } from './MetaAnalysisFrequencyChartBaseOptions'
import { MetaAnalysisFrequencyChartOptions } from './MetaAnalysisFrequencyChartOptions'
import { computeFrequencyChartSeries } from './series'
import { updateMetaAnalysisChart } from './store/meta-analysis.history.slice'
import {
  selectMetaAnalysisChannels,
  selectMetaAnalysisFcsFileNameById,
  selectMetaAnalysisFile,
  selectMetaAnalysisName,
} from './store/selectors'
import { useMetaAnalysisCsvMetadata } from './useMetaAnalysisCsvMetadata'
import { useNavigateToFile } from './useNavigateToFile'

type MetaAnalysisFrequencyChartProps = {
  chart: MetaAnalysisFrequencyChartType
  colors: MetaAnalysisColors
  isExpanded?: boolean
  className?: string
  onCloseExpand?: () => void
}

export const MetaAnalysisFrequencyChart = ({
  chart,
  colors,
  isExpanded,
  className,
  onCloseExpand,
}: MetaAnalysisFrequencyChartProps): JSX.Element => {
  const dispatch = useAppDispatch()

  const [ref, { width, height }] = useSize<HTMLDivElement>({ debounce: 250 })
  const innerPlotRef = useRef<InnerPlotInstance>(null)

  const [shouldDisplayChartOptions, setShouldDisplayChartOptions] =
    useState(false)
  const [shouldShowExpandedSelf, setShouldShowExpandedSelf] = useState(false)

  const handleChangeOptions = useEventCallback(() => {
    setShouldDisplayChartOptions(true)
  })

  const handleCancel = useEventCallback(() => {
    setShouldDisplayChartOptions(false)
  })

  const handleApplyOptions = useEventCallback(
    (
      fileIds: string[],
      cluster: string,
      channel: string,
      mode: MetaAnalysisFrequencyChartType['selectedIntensityMode'],
    ) => {
      dispatch(
        updateMetaAnalysisChart({
          id: chart.id,
          selectedFileIds: fileIds,
          selectedChannel: channel,
          selectedCluster: cluster,
          selectedIntensityMode: mode,
        }),
      )
      setShouldDisplayChartOptions(false)
    },
  )

  const handleDownload = useEventCallback(() => {
    innerPlotRef.current?.downloadSeries()
  })

  return (
    <MetaAnalysisChartContainer
      containerRef={ref}
      chart={chart}
      isExpanded={isExpanded}
      className={className}
      onChangeOptions={handleChangeOptions}
      onExpand={() => setShouldShowExpandedSelf(true)}
      onCloseExpand={onCloseExpand}
      onDownload={handleDownload}
    >
      <DefaultErrorBoundary>
        <InnerPlot
          chart={chart}
          colors={colors}
          width={width}
          height={height}
          ref={innerPlotRef}
        />
      </DefaultErrorBoundary>
      {shouldDisplayChartOptions && (
        <StyledMetaAnalysisFrequencyChartOptions
          mode="edit"
          initialSelectedFileIds={chart.selectedFileIds}
          initialSelectedCluster={chart.selectedCluster}
          initialSelectedChannel={chart.selectedChannel}
          initialSelectedIntensityMode={chart.selectedIntensityMode}
          onCancel={handleCancel}
          onFinish={handleApplyOptions}
        />
      )}
      {shouldShowExpandedSelf && (
        <Modal open onClose={() => setShouldShowExpandedSelf(false)}>
          <ExpandedMetaAnalysisFrequencyChart
            chart={chart}
            colors={colors}
            onCloseExpand={() => setShouldShowExpandedSelf(false)}
          />
        </Modal>
      )}
    </MetaAnalysisChartContainer>
  )
}

type InnerPlotProps = Pick<
  MetaAnalysisFrequencyChartProps,
  'chart' | 'colors'
> & {
  width: number
  height: number
}

type InnerPlotInstance = {
  downloadSeries: () => void
}

const InnerPlot = forwardRef<InnerPlotInstance, InnerPlotProps>(
  ({ chart, colors, width, height }, ref) => {
    const theme = useTheme()
    const navigateToFile = useNavigateToFile()

    const metaAnalysisName = useAppSelector(selectMetaAnalysisName)
    const metaAnalysisFile = useAppSelector(selectMetaAnalysisFile)
    const fileNameById = useAppSelector(selectMetaAnalysisFcsFileNameById)
    const channels = useAppSelector(selectMetaAnalysisChannels)

    const series = useMemo(
      () =>
        computeFrequencyChartSeries(
          chart,
          metaAnalysisFile,
          colors,
          channels,
          fileNameById,
        ),
      [channels, chart, fileNameById, metaAnalysisFile, colors],
    )

    const createCsvMetadata = useMetaAnalysisCsvMetadata()
    const downloadSeries = useEventCallback(async () => {
      const headerRow = 'file,cluster,channel,mode,intensity,frequency'
      const dataRows = chart.selectedFileIds.map((_, index) => {
        const point = series[0].data![index] as [string, number, number]
        return [
          point[0],
          chart.selectedCluster,
          chart.selectedChannel,
          chart.selectedIntensityMode,
          point[1],
          point[2],
        ].join(',')
      })
      const csv =
        (await createCsvMetadata()) + [headerRow, ...dataRows].join('\n')
      downloadText(csv, `${metaAnalysisName} - ${chart.name}.csv`)
    })
    useImperativeHandle(ref, () => ({ downloadSeries }), [downloadSeries])

    const options: Highcharts.Options = useMemo(() => {
      const formatTooltip: TooltipFormatterCallbackFunction = function (this) {
        return renderToStaticMarkup(
          <div
            style={{
              padding: 8,
              fontSize: 13,
              minWidth: 120,
              background: theme.colors.white,
            }}
          >
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
              }}
            >
              <span style={{ fontFamily: theme.font.style.bold }}>
                {this.series.name}
              </span>
              <span
                style={{
                  color: this.point.color as string,
                  marginRight: 4,
                  fontSize: 14,
                }}
              >
                ⬤
              </span>
            </div>
            <div style={{ fontSize: 12, color: theme.colors.primaryDark[70] }}>
              {this.point.category}
            </div>
            <div
              style={{
                fontSize: 12,
                marginTop: 6,
                color: theme.colors.primaryDark[70],
              }}
            >
              {chart.selectedIntensityMode} for {chart.selectedChannel}:{' '}
              {this.y?.toFixed(2)}
            </div>
            <div
              style={{
                fontSize: 12,
                marginTop: 6,
                color: theme.colors.primaryDark[70],
              }}
            >
              %Frequency: {this.point['z']?.toFixed(2)}
            </div>
          </div>,
        )
      }

      return {
        ...MetaAnalysisFrequencyChartBaseOptions,
        chart: {
          ...MetaAnalysisFrequencyChartBaseOptions.chart,
          width,
          height,
        },
        xAxis: {
          ...MetaAnalysisFrequencyChartBaseOptions.xAxis,
          categories: chart.selectedFileIds.map(fileId => fileNameById[fileId]),
          labels: {
            ...MetaAnalysisFrequencyChartBaseOptions.xAxis.labels,
            events: {
              async click(this: { pos: number }) {
                const fcsFileId = chart.selectedFileIds[this.pos]
                navigateToFile(fcsFileId)
              },
            },
          },
        },
        yAxis: {
          title: {
            text: `${chart.selectedChannel} ${chart.selectedIntensityMode} intensity`,
          },
        },
        series,
        tooltip: {
          ...MetaAnalysisFrequencyChartBaseOptions.tooltip,
          formatter: formatTooltip,
        },
      }
    }, [
      chart.selectedChannel,
      chart.selectedFileIds,
      chart.selectedIntensityMode,
      fileNameById,
      height,
      navigateToFile,
      series,
      theme.colors.primaryDark,
      theme.colors.white,
      theme.font.style.bold,
      width,
    ])
    return <Chart options={options} />
  },
)

const StyledMetaAnalysisFrequencyChartOptions = styled(
  MetaAnalysisFrequencyChartOptions,
)`
  position: absolute;
  top: 0;
  left: 0;
  z-index: 2;
`

const ExpandedMetaAnalysisFrequencyChart = styled(
  MetaAnalysisFrequencyChart,
).attrs({ isExpanded: true })`
  width: 90vw;
  height: 90vh;
`
