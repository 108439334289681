import { FC } from 'react'
import { Link } from 'react-router-dom'
import styled from 'styled-components'

import { ReactComponent as MetaflowLogo } from 'assets/images/logo.svg'

export const MetaflowLogoLink: FC = () => {
  return (
    <MetaflowLogoLinkRoot to="/">
      <MetaflowLogo />
    </MetaflowLogoLinkRoot>
  )
}

const MetaflowLogoLinkRoot = styled(Link)`
  align-self: center;
`
