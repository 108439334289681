import { Fade } from '@material-ui/core'
import { Formik, FormikConfig } from 'formik'
import React, { useCallback, useEffect, useRef, useState } from 'react'
import ReCAPTCHA from 'react-google-recaptcha'
import styled from 'styled-components'
import { object, string } from 'yup'

import { Icon } from 'assets/images/icons/Icon'

import { Button } from 'components/Button'
import { Modal } from 'components/Modal'
import Input from 'components/forms/Input'
import { ReCaptcha } from 'components/forms/ReCaptcha'

import {
  LoginToLogbookResult,
  useLoginToLogbookMutation,
} from 'shared/api/auth.api'
import {
  ReCaptchaFlags,
  useReCaptchaRequired,
} from 'shared/hooks/useReCaptchaRequired'
import { useServerFormValidation } from 'shared/hooks/useServerFormValidation'
import { useAppSelector } from 'shared/store'

import { selectAnalysisId } from '../store/selectors'

type LogbookLoginModalProps = Readonly<{
  open: boolean
  onLoginCancel: () => void
  onLoginSuccess: (data: LoginToLogbookResult) => void
  authError?: string
}>

type LogbookLoginFormValues = {
  email: string
  password: string
}

const LogbookLoginFormSchema = object({
  email: string().email().required(),
  password: string().required(),
})

const initialValues: LogbookLoginFormValues = {
  email: '',
  password: '',
}

export const LogbookLoginModal = ({
  open,
  onLoginCancel,
  onLoginSuccess,
}: LogbookLoginModalProps): JSX.Element => {
  const [loginToLogbook, loginToLogbookResult] = useLoginToLogbookMutation()
  const analysisId = useAppSelector(selectAnalysisId) ?? ''
  const isReCaptchaRequired = useReCaptchaRequired(ReCaptchaFlags.Login)
  const [isReCaptchaCompleted, setReCaptchaCompleted] = useState(false)
  const reCaptchaRef = useRef<ReCAPTCHA>(null)

  const { globalError, getFieldRequestError, setSubmittedValues } =
    useServerFormValidation({
      error: loginToLogbookResult.error,
      fields: Object.keys(initialValues) as (keyof LogbookLoginFormValues)[],
    })

  const handleLogin: FormikConfig<LogbookLoginFormValues>['onSubmit'] =
    useCallback(
      async (values, { setSubmitting }) => {
        const reCaptchaToken =
          (reCaptchaRef.current && reCaptchaRef.current.getValue()) || ''
        loginToLogbook({ reCaptchaToken, analysisId, ...values })
        setSubmittedValues(values)
        setSubmitting(false)
        reCaptchaRef.current && reCaptchaRef.current.reset()
      },
      [analysisId, loginToLogbook, setSubmittedValues],
    )

  const handleCancel = useCallback(() => {
    loginToLogbookResult.reset()
    onLoginCancel()
  }, [loginToLogbookResult, onLoginCancel])

  useEffect(() => {
    if (loginToLogbookResult.isSuccess && loginToLogbookResult.data) {
      onLoginSuccess(loginToLogbookResult.data)
    }
  }, [
    loginToLogbookResult.data,
    loginToLogbookResult.isSuccess,
    onLoginSuccess,
  ])

  return (
    <Modal open={open} onClose={handleCancel}>
      <Formik<LogbookLoginFormValues>
        initialValues={initialValues}
        validationSchema={LogbookLoginFormSchema}
        onSubmit={handleLogin}
      >
        {({
          values,
          touched,
          errors,
          isSubmitting,
          isValid,
          handleChange,
          handleBlur,
          handleSubmit,
        }) => (
          <Fade in={open}>
            <form onSubmit={handleSubmit}>
              <Container>
                <Title>To see the logbook, please identify yourself</Title>
                <ReCaptcha
                  onCompleted={() => setReCaptchaCompleted(true)}
                  ref={reCaptchaRef}
                >
                  <InputGroup>
                    <Input
                      name="email"
                      value={values.email}
                      id="email"
                      type="text"
                      data-cy="email"
                      placeholder="E-mail"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      error={
                        touched.email
                          ? getFieldRequestError('email', values.email) ||
                            errors.email
                          : undefined
                      }
                    />
                    <Input
                      name="password"
                      value={values.password}
                      id="password"
                      type="password"
                      data-cy="password"
                      placeholder="Password"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      error={
                        touched.password
                          ? getFieldRequestError('password', values.password) ||
                            errors.password
                          : undefined
                      }
                    />
                  </InputGroup>
                </ReCaptcha>
                {loginToLogbookResult.error && <Error>{globalError}</Error>}
                <Actions>
                  <Button grey onClick={handleCancel}>
                    Cancel
                  </Button>
                  <StyledButton
                    disabled={
                      isSubmitting ||
                      !isValid ||
                      (isReCaptchaRequired && !isReCaptchaCompleted)
                    }
                    color="primary"
                    type="submit"
                    data-cy="login-btn"
                    endIcon={<Icon name="arrowRight" />}
                  >
                    Continue
                  </StyledButton>
                </Actions>
              </Container>
            </form>
          </Fade>
        )}
      </Formik>
    </Modal>
  )
}

const Container = styled.div`
  background-color: ${props => props.theme.colors.background};
  padding: 32px 70px;
  border-radius: ${props => props.theme.radius[2]}px;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 530px;
`

const Title = styled.div`
  font-family: ${props => props.theme.font.style.bold};
`
const InputGroup = styled.div`
  padding-top: 32px;
  width: 100%;
`

const Error = styled.div`
  padding-bottom: 16px;
  color: ${props => props.theme.colors.error};
  height: 36px;
`

const Actions = styled.div`
  display: flex;
  align-self: flex-end;
  justify-content: flex-end;
  & > * {
    margin-left: 16px;
  }
`
const StyledButton = styled(Button)`
  width: 165px;
  display: flex;
  justify-content: space-between;
`
