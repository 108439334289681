import { useFormikContext } from 'formik'
import { produce } from 'immer'
import { difference, uniq } from 'lodash'
import styled from 'styled-components'

import { ValidationLabel } from 'components/ validation-label/ValidationLabel'

import { BrickDetails } from 'shared/api/workflows.api'

import { BrickAnalysisSelector } from './BrickAnalysisSelector'
import { MetaAnalysisWizardFormValues } from './MetaAnalysisWizard'

type MetaAnalysisWizardSelectAnalysesStepProps = {
  brickById: Record<string, BrickDetails>
  fileIdByAnalysisId: Record<string, string>
}

export const MetaAnalysisWizardSelectAnalysesStep = ({
  brickById,
  fileIdByAnalysisId,
}: MetaAnalysisWizardSelectAnalysesStepProps): JSX.Element => {
  const { errors, values, setValues } =
    useFormikContext<MetaAnalysisWizardFormValues>()

  const handleChange = (selectedAnalysisIds: string[]) => {
    setValues(values =>
      produce(values, draft => {
        draft.selectedAnalysisIds = selectedAnalysisIds as string[]

        const currentFileIds = Object.values(values.selectedAnalysisIds).map(
          id => fileIdByAnalysisId[id],
        )
        const newFileIds = uniq(
          Object.values(draft.selectedAnalysisIds).map(
            id => fileIdByAnalysisId[id],
          ),
        )
        const addedFileIds = difference(newFileIds, currentFileIds)
        const removedFileIds = difference(currentFileIds, newFileIds)

        for (const addedFileId of addedFileIds) {
          draft.fileGroupMapping[addedFileId] = values.groups[0]
        }
        for (const removedFileId of removedFileIds) {
          delete draft.fileGroupMapping[removedFileId]
        }
      }),
    )
  }

  return (
    <MetaAnalysisWizardSelectAnalysesStepRoot>
      {errors.selectedAnalysisIds && (
        <StyledValidationLabel>
          {errors.selectedAnalysisIds}
        </StyledValidationLabel>
      )}
      <BrickAnalysisSelector
        selectedAnalysisIds={values.selectedAnalysisIds}
        brickById={brickById}
        validationAction="select-analysis-for-meta-analysis"
        onChange={handleChange}
      />
    </MetaAnalysisWizardSelectAnalysesStepRoot>
  )
}

const MetaAnalysisWizardSelectAnalysesStepRoot = styled.div``

const StyledValidationLabel = styled(ValidationLabel)`
  margin-bottom: 16px;
`
