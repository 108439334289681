import { RGBColor } from 'react-color'

export function changeAlpha(color: string, alpha: number): string {
  const [r, g, b] = rgbStringToValues(
    color.startsWith('#') ? hexStringToRgbString(color) : color,
  )
  return `rgba(${r}, ${g}, ${b}, ${alpha})`
}

export function rgbStringToValues(s: string): number[] {
  return s.split('(')[1].slice(0, -1).split(',').map(Number)
}

export function rgbStringToRGBColor(s: string): RGBColor {
  const [r, g, b] = rgbStringToValues(s)
  return { r, g, b }
}

export function RGBColorToRgbString(color: RGBColor): string {
  const { r, g, b } = color
  return `rgba(${r}, ${g}, ${b}, 1)`
}

export function hexStringToRgbString(hex: string): string {
  const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex)
  if (result && result.length === 4) {
    const r = parseInt(result[1], 16)
    const g = parseInt(result[2], 16)
    const b = parseInt(result[3], 16)
    return `rgba(${r}, ${g}, ${b}, 1)`
  }
  throw new Error(
    "The value provided doesn't follow the hexadecimal standard format.",
  )
}

/**
 * Function detecting whether the color inputed has a collision with the list of colors provided, depending
 * on a _delta_ range for each R, G and B component of the color.
 * @param color The color to compare to the *colors* parameter. Rgba string format.
 * @param colors A list of rgba string colors.
 * @param delta The range of collision for each color component.
 */
export function detectColorCollisions(
  color: string,
  colors: string[],
  delta: number,
): boolean {
  const [r, g, b] = rgbStringToValues(color)

  if (!r || !g || !b) {
    return true
  }

  // if each of the color values is separated within a DELTA range of one of the cluster color values, then there is a collision
  return colors.some(c => {
    const [c_r, c_g, c_b] = rgbStringToValues(c)
    return (
      Math.abs(c_r - r) <= delta &&
      Math.abs(c_g - g) <= delta &&
      Math.abs(c_b - b) <= delta
    )
  })
}

export const computeColorFromString = (name: string): string => {
  const stringUniqueHash = [...name].reduce((acc, char) => {
    return char.charCodeAt(0) + ((acc << 5) - acc)
  }, 0)
  return hslToHex(stringUniqueHash % 360, 95, 35)
}

function hslToHex(h: number, s: number, l: number): string {
  l /= 100
  const a = (s * Math.min(l, 1 - l)) / 100
  const f = n => {
    const k = (n + h / 30) % 12
    const color = l - a * Math.max(Math.min(k - 3, 9 - k, 1), -1)
    return Math.round(255 * color)
      .toString(16)
      .padStart(2, '0') // convert to Hex and prefix "0" if needed
  }
  return `#${f(0)}${f(8)}${f(4)}`
}

export function colorValueTupleToRgbString(
  colors: [string, string, string] | [string, string, string, string],
): string {
  if (colors.length > 3) {
    return `rgba(${colors.join(',')})`
  }
  return `rgb(${colors.join(',')})`
}
