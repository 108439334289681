import { createSelector } from '@reduxjs/toolkit'
import { pick } from 'lodash'

import { CUMULATIVE_FLUORESCENCE_CHANNEL } from 'shared/constants'

import { selectSortedSelectedChannels } from './channels.selectors'
import { selectChartScales } from './chart-scales.selectors'
import { selectCharts, selectGloballyHiddenClusters } from './charts.selectors'
import {
  selectActiveClusterIds,
  selectActiveClusterIdsByChartId,
  selectActiveClusters,
  selectActiveClustersByChartId,
  selectActiveLeaves,
  selectActiveLeavesByChartId,
  selectHighlightedCluster,
} from './clusters.selectors'

export type ScatterPlotScaleType = {
  xAxis: 'linear' | 'logarithmic'
  yAxis: 'linear' | 'logarithmic'
}

export const selectSunburstSeriesComputationParams = createSelector(
  selectActiveClusters,
  selectGloballyHiddenClusters,
  selectHighlightedCluster,
  (activeClusters, globallyHiddenClusters, highlightedCluster) => {
    return {
      activeClusters,
      globallyHiddenClusters,
      highlightedCluster,
    }
  },
)

export const selectHeatmapSeriesComputationParams = createSelector(
  selectGloballyHiddenClusters,
  selectSortedSelectedChannels,
  selectActiveClusterIds,
  selectActiveLeaves,
  (
    globbalyHiddenClusterIds,
    selectedChannels,
    activeClusters,
    activeLeaves,
  ) => {
    return {
      globallyHiddenClusterIds: [...globbalyHiddenClusterIds],
      selectedChannels,
      activeClusters,
      activeLeaves: activeLeaves.map(leaf => pick(leaf, ['id', 'stats'])),
    }
  },
)

export const selectAnalysisScatterplotSeriesComputationParams = createSelector(
  selectCharts,
  selectActiveClusterIdsByChartId,
  selectChartScales,
  (charts, activeClusterIdsByChartId, chartScales) => {
    return Object.fromEntries(
      charts
        .filter(
          chart =>
            chart.chart_type === 'Dot plot' &&
            chart.y_axis !== CUMULATIVE_FLUORESCENCE_CHANNEL,
        )
        .map(chart => {
          return [
            chart.id,
            {
              chart: {
                xAxis: chart.x_axis,
                yAxis: chart.y_axis as string,
                activeClusterIds: activeClusterIdsByChartId[chart.id],
                activeLeafIds: chart.active_leaf_ids,
                hiddenClusterIds: chart.hidden_cluster_ids,
                eventLimit: chart.event_limit,
                scale: chartScales[chart.id]
                  ? {
                      xAxis: {
                        min:
                          chart.zoom?.x_min ?? chartScales[chart.id]!.xAxis.min,
                        max:
                          chart.zoom?.x_max ?? chartScales[chart.id]!.xAxis.max,
                      },
                      yAxis: {
                        min:
                          chart.zoom?.y_min ??
                          (chartScales[chart.id]!.yAxis as Scale).min,
                        max:
                          chart.zoom?.y_max ??
                          (chartScales[chart.id]!.yAxis as Scale).max,
                      },
                    }
                  : undefined,
                scaleType: {
                  xAxis: chart.x_axis_scale_type as 'linear' | 'logarithmic',
                  yAxis: chart.y_axis_scale_type as 'linear' | 'logarithmic',
                },
              },
            },
          ]
        }),
    )
  },
)

export const selectAnalysisHistogramSeriesComputationParams = createSelector(
  selectCharts,
  selectActiveClustersByChartId,
  selectActiveLeavesByChartId,
  selectChartScales,
  (charts, activeClustersByChartId, activeLeavesByChartId, chartScales) => {
    return Object.fromEntries(
      charts.map(chart => {
        return [
          chart.id,
          {
            chart: {
              id: chart.id,
              chart_type: chart.chart_type,
              x_axis: chart.x_axis,
              y_axis: chart.y_axis as string,
              hidden_cluster_ids: chart.hidden_cluster_ids,
              event_limit: chart.event_limit,
              activeLeaves: activeLeavesByChartId[chart.id].map(leaf => {
                return {
                  id: leaf.id,
                  color: leaf.color,
                  label: leaf.label,
                  parent_rate: leaf.parent_rate,
                  stats: {
                    count: leaf.stats.count,
                  },
                }
              }),
              activeClusters: activeClustersByChartId[chart.id].map(
                cluster => cluster.id,
              ),
              scale: [
                chartScales[chart.id]!.xAxis.min,
                chartScales[chart.id]!.xAxis.max,
              ] as [number, number],
            },
          },
        ]
      }),
    )
  },
)
