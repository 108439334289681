import { Backdrop, Modal as MuiModal } from '@material-ui/core'
import React, { FC, ReactNode } from 'react'
import styled from 'styled-components'

import { theme } from 'Theme'

type ModalProps = Readonly<{
  open: boolean
  onClose?: () => void
  className?: string
  keepMounted?: boolean
  disableBackdropClick?: boolean
  disableEscapeKeyDown?: boolean
  children?: ReactNode
  disablePortal?: boolean
}>

export const Modal: FC<ModalProps> = ({
  open,
  onClose,
  disableBackdropClick,
  disableEscapeKeyDown,
  keepMounted,
  children,
  className,
  disablePortal,
}) => {
  return (
    <StyledModal
      disablePortal={disablePortal}
      keepMounted={keepMounted}
      className={className}
      BackdropProps={{
        style: { backgroundColor: theme.colors.modal },
      }}
      open={open}
      onClose={(_event, reason) => {
        if (!(reason === 'backdropClick' && disableBackdropClick)) {
          onClose?.()
        }
      }}
      disableEscapeKeyDown={disableEscapeKeyDown}
      closeAfterTransition={true}
      BackdropComponent={Backdrop}
    >
      <>{children}</>
    </StyledModal>
  )
}

const StyledModal = styled(MuiModal)`
  display: flex;
  padding: ${props => props.theme.layout.contentPadding}px;
  justify-content: center;
  align-items: center;
  & > *:focus {
    outline: none;
  }
  & > * {
    box-shadow: ${props => props.theme.shadows[10]};
  }
`
