import { Collapse, TableRow as MuiTableRow } from '@material-ui/core'
import { PlayArrow } from '@material-ui/icons'
import { FC, ReactNode, useState } from 'react'
import styled from 'styled-components'

import { useEventCallback } from 'shared/hooks/useEventCallback'

import { TableRowProps } from './TableRow'
import { StyledTableCell as TableCell } from './TableRow'

type CollapsibleTableRowProps = TableRowProps & {
  children: ReactNode
}

export const CollapsibleTableRow: FC<CollapsibleTableRowProps> = ({
  children,
  cells,
  columns,
  id,
  compactMode,
}) => {
  const [isExpanded, setExpanded] = useState(false)

  const handleToggleExpand = useEventCallback(() => setExpanded(!isExpanded))

  return (
    <>
      <StyledTableRow onClick={handleToggleExpand}>
        <StyledTableCell $compactMode={compactMode} style={{ width: 80 }}>
          <StyledPlayArrow $expanded={isExpanded} />
        </StyledTableCell>
        {cells.map((cell, cellIndex) => (
          <StyledTableCell
            style={columns[cellIndex].style}
            $compactMode={compactMode}
            key={id + cell.key}
            title={
              cell.title
                ? cell.title
                : typeof cell.content === 'string'
                ? cell.content
                : typeof cell.content === 'number'
                ? cell.content.toLocaleString()
                : ''
            }
          >
            {cell.content}
          </StyledTableCell>
        ))}
      </StyledTableRow>
      <tr>
        <td colSpan={999}>
          <StyledCollapse in={isExpanded} mountOnEnter>
            {children}
          </StyledCollapse>
        </td>
      </tr>
    </>
  )
}

const StyledCollapse = styled(Collapse)`
  margin-top: -20px;
`

const StyledTableRow = styled(MuiTableRow)`
  z-index: 1;
  position: relative;
  background-color: ${props => props.theme.colors.white};
  box-shadow: 0px 4px 10px 0px #e8e9f399;
  border-radius: ${props => props.theme.radius[2]}px;
  cursor: pointer;
`

const StyledTableCell = styled(TableCell)`
  &:first-of-type {
    line-height: 0;

    & > .MuiSvgIcon-root {
      fill: ${props => props.theme.colors.primaryDark[70]};
    }
  }
`

const StyledPlayArrow = styled(PlayArrow)<{
  $expanded: boolean
}>`
  transform: ${props => (props.$expanded ? 'rotate(90deg)' : 'none')};
  transition: transform 0.3s ease;
`
