import { useDeleteWorkflowMutation } from 'shared/api/workflows.api'
import { useDialog } from 'shared/contexts/DialogContext'

type DeleteWorkflowProps = {
  id: string
  name: string | undefined
  shouldShowConfirmation?: boolean
}

export const useDeleteWorkflow = (): ((props: DeleteWorkflowProps) => void) => {
  const { showConfirmationDialog, showOptionDialog } = useDialog()
  const [triggerDeleteWorkflowMutation] = useDeleteWorkflowMutation()

  return ({
    id: workflowId,
    name,
    shouldShowConfirmation = true,
  }: DeleteWorkflowProps) => {
    const proceed = () => {
      showOptionDialog({
        title: 'Delete workflow',
        message:
          'Do you want to delete the workflow only or the workflow and all its pipelines?',
        options: [
          {
            label: 'Delete workflow only',
            onClick: () => {
              triggerDeleteWorkflowMutation({
                workflowId,
                deletePipelines: false,
              })
            },
          },
          {
            label: 'Delete workflow and pipelines',
            onClick: () => {
              triggerDeleteWorkflowMutation({
                workflowId,
                deletePipelines: true,
              })
            },
          },
        ],
      })
    }

    if (!shouldShowConfirmation) {
      proceed()
    } else {
      showConfirmationDialog({
        title: 'Delete workflow',
        message: `Are you sure you want to delete the workflow "${
          name ?? workflowId
        }"?`,
        onConfirm: proceed,
      })
    }
  }
}
