import styled from 'styled-components'

export const TooltipContainer = styled.div`
  background: rgba(255, 255, 255, 0.85);
  border: 1px solid ${props => props.theme.colors.primaryDark[20]};
  border-radius: ${props => props.theme.radius[2]}px;
  box-shadow: ${props => props.theme.shadow.tooltip};
  overflow: hidden;
  user-select: none;
  z-index: 2;
`
