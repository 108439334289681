import { LassoToolState } from './LassoTool'

export class LassoCursor {
  private state: LassoToolState

  constructor(state: LassoToolState) {
    this.state = state
  }

  updateCursor(): void {
    const {
      isActive,
      scatterPlotRef,
      currentLassoId,
      lassos,
      __computed__hoveredLassoId,
    } = this.state

    const svg = scatterPlotRef.current?.container.current?.querySelector('svg')
    if (!svg) {
      return
    }

    svg.style.cursor = 'default'
    if (!isActive || !currentLassoId) {
      return
    }

    if (
      lassos[currentLassoId].isFinished ||
      (!lassos[currentLassoId].polygon.length && __computed__hoveredLassoId)
    ) {
      this.updateFinishedLassoCursor(svg)
    } else {
      this.updateUnfinishedLassoCursor(svg)
    }
  }

  private updateFinishedLassoCursor(svg: SVGElement) {
    const {
      currentLassoId,
      movedPolygonPointIndex,
      __computed__hoveredPolygonPointIndex,
      __computed__isModifiedLassoCorrect,
      __computed__hoveredLassoId,
      __computed__canDropLasso,
      isMovingLasso,
    } = this.state

    if (movedPolygonPointIndex !== undefined) {
      if (__computed__isModifiedLassoCorrect) {
        svg.style.cursor = 'grab'
      } else {
        svg.style.cursor = 'not-allowed'
      }
    } else if (isMovingLasso && !__computed__canDropLasso) {
      svg.style.cursor = 'not-allowed'
    } else if (currentLassoId === __computed__hoveredLassoId) {
      if (__computed__hoveredPolygonPointIndex !== undefined) {
        svg.style.cursor = 'grab'
      } else {
        svg.style.cursor = 'move'
      }
    }
  }

  private updateUnfinishedLassoCursor(svg: SVGElement) {
    const {
      currentLassoId,
      lassos,
      __computed__hoveredPolygonPoint,
      __computed__canFinishLasso,
      __computed__canAddPoint,
    } = this.state

    if (
      currentLassoId &&
      __computed__hoveredPolygonPoint &&
      lassos[currentLassoId].polygon.length === 0
    ) {
      svg.style.cursor = 'grab'
    }
    if (__computed__canFinishLasso) {
      svg.style.cursor = 'pointer'
    } else if (__computed__canAddPoint) {
      svg.style.cursor = 'crosshair'
    } else {
      svg.style.cursor = 'not-allowed'
    }
  }
}
