import { Location } from 'history'
import React from 'react'
import { Redirect, Route, Switch, useLocation } from 'react-router-dom'

import { LoadingMask } from 'components/LoadingMask'
import { PrivateRoute } from 'components/PrivateRoute'
import { SessionExpiryPrompt } from 'components/SessionExpiryPrompt'

import { AnalysisPage } from 'pages/analysis/AnalysisPage'
import { BuyPackagePage } from 'pages/buy-package/BuyPackagePage'
import { ExportsPage } from 'pages/exports/ExportsPage'
import { HomePage } from 'pages/home/HomePage'
import { LicenseWalletPage } from 'pages/license-wallet/LicenseWalletPage'
import { ForgotPasswordPage } from 'pages/login/ForgotPasswordPage'
import { LogInPage } from 'pages/login/LogInPage'
import { NewPasswordPage } from 'pages/login/NewPasswordPage'
import { MetaAnalysisPage } from 'pages/meta-analysis/MetaAnalysisPage'
import { MembersPanelPage } from 'pages/organization/MembersPanelPage'
import { PaymentsHistory } from 'pages/organization/PaymentsHistory'
import { ProfilePage } from 'pages/profile/ProfilePage'
import { ProjectPage } from 'pages/project/ProjectPage'
import { ProjectsPage } from 'pages/projects/ProjectsPage'
import { SignUpMemberPage } from 'pages/sign-up/SignUpMemberPage'
import { SignUpPage } from 'pages/sign-up/SignUpPage'

import { useGetUserQuery } from 'shared/api/user.api'
import { useEventCallback } from 'shared/hooks/useEventCallback'
import { useObserve } from 'shared/hooks/useObserve'
import { useSessionTimer } from 'shared/hooks/useSessionTimer'
import { useAppDispatch, useAppSelector } from 'shared/store'
import { selectIsAuthenticated } from 'shared/store/auth.slice'
import { showNotification } from 'shared/store/notification.slice'

import { NotFoundPage } from './NotFoundPage'

const Routes: React.FC = () => {
  const isAuthenticated = useAppSelector(selectIsAuthenticated)
  const userQueryState = useGetUserQuery(undefined, { skip: !isAuthenticated })
  const isOperatorUser = userQueryState.data?.is_operator

  const shouldShowLogoutWarning = useSessionTimer()

  useObserveLocation()

  if (userQueryState.isLoading) {
    return <LoadingMask />
  }

  return (
    <>
      <Switch>
        <PrivateRoute path="/home" exact component={HomePage} />
        <PrivateRoute path="/projects" exact component={ProjectsPage} />
        <PrivateRoute
          path="/projects/:projectId/:tab"
          component={ProjectPage}
        />
        <PrivateRoute
          path="/analysis/:analysisId"
          component={AnalysisPage}
          withHeader
        />
        <PrivateRoute
          path="/meta-analysis/:metaAnalysisId"
          component={MetaAnalysisPage}
          withHeader
        />
        <PrivateRoute
          path="/profile"
          exact
          component={ProfilePage}
          withHeader
        />
        <PrivateRoute
          path="/exports"
          exact
          component={ExportsPage}
          withHeader
        />
        <PrivateRoute path="/members-panel" exact withHeader>
          {isOperatorUser ? <MembersPanelPage /> : <Redirect to="/" />}
        </PrivateRoute>
        <PrivateRoute
          path="/license-wallet"
          exact
          component={LicenseWalletPage}
          withHeader
        />
        <PrivateRoute path="/payments-history" exact withHeader>
          {isOperatorUser ? <PaymentsHistory /> : <Redirect to="/" />}
        </PrivateRoute>
        <PrivateRoute path="/buy-package/:basketId?" exact withHeader>
          {isOperatorUser ? <BuyPackagePage /> : <Redirect to="/" />}
        </PrivateRoute>

        <Route exact path="/">
          <Redirect to={isAuthenticated ? '/home' : '/log-in'} />
        </Route>

        <Route path="/forgot-password" component={ForgotPasswordPage} />
        <Route path="/new-password/:token/" component={NewPasswordPage} />
        <Route path="/log-in" component={LogInPage} />
        <Route path="/sign-up" component={SignUpPage} />
        <Route path="/accept-invitation/:token/" component={SignUpMemberPage} />
        <Route path="*" component={NotFoundPage} />
      </Switch>
      {shouldShowLogoutWarning && <SessionExpiryPrompt timeout={59} />}
    </>
  )
}

export default Routes

const useObserveLocation = () => {
  type LocationState =
    | {
        from?: { pathname: string }
        isSignUpSuccess?: boolean
        isChangePasswordSuccess?: boolean
      }
    | undefined

  const dispatch = useAppDispatch()
  const location = useLocation<LocationState>()

  const listener = useEventCallback((location: Location<LocationState>) => {
    if (location.state?.isSignUpSuccess) {
      dispatch(
        showNotification({
          type: 'success',
          description: 'Account was successfully created',
        }),
      )
    }
    if (location.state?.isChangePasswordSuccess) {
      dispatch(
        showNotification({
          type: 'success',
          description: 'Password was changed successfully',
        }),
      )
    }
  })

  useObserve(location, listener)
}
