import { useCallback, useState } from 'react'

import { useEndSessionMutation } from 'shared/api/logbook.api'

import { LogContext, LogSession } from './LogContext'

type LogContextProviderProps = {
  analysisId: string
  children: React.ReactNode
}

export const LogContextProvider = ({
  children,
  analysisId,
}: LogContextProviderProps): JSX.Element => {
  const initialLogSession = useGetLogSession(analysisId)
  const [currentSession, setCurrentSession] = useState<LogSession | undefined>(
    initialLogSession,
  )
  const _setCurrentSession = useCallback((session: LogSession | undefined) => {
    setCurrentSession(session)
    if (session) {
      localStorage.setItem('logSession', JSON.stringify(session))
    } else {
      localStorage.removeItem('logSession')
    }
  }, [])

  return (
    <LogContext.Provider
      value={{
        currentSession,
        setCurrentSession: _setCurrentSession,
      }}
    >
      {children}
    </LogContext.Provider>
  )
}

const useGetLogSession = (analysisId: string) => {
  const [triggerEndSessionMutation] = useEndSessionMutation()
  const session = localStorage.getItem('logSession')
  if (session) {
    const _session = JSON.parse(session) as LogSession
    const startTime = new Date(_session.started_at).getTime()
    const currentTime = new Date().getTime()
    const shouldClearSession =
      currentTime - startTime > 30 * 60 * 1000 ||
      _session.analysis !== analysisId
    if (shouldClearSession) {
      triggerEndSessionMutation({ sessionId: _session.id })
      localStorage.removeItem('logSession')
      return undefined
    } else {
      return _session
    }
  } else {
    return undefined
  }
}
