import {
  PhotoSizeSelectSmall,
  PictureInPicture as PictureInPictureIcon,
  ShowChart as ShowChartIcon,
  Visibility as VisibilityIcon,
} from '@material-ui/icons'
import { SelectEventObject } from 'highcharts'
import HighchartsReact from 'highcharts-react-official'
import { FC, useCallback, useMemo, useRef, useState } from 'react'
import styled from 'styled-components'

import { DefaultErrorBoundary } from 'components/DefaultErrorBoundary'
import { DownsamplingInfo } from 'components/DownsamplingInfo'
import { Drawer } from 'components/Drawer'
import { Modal } from 'components/Modal'

import { AnalysisScatterPlotGates } from 'pages/analysis/AnalysisScatterPlotGates'
import {
  ClusterActionsContextMenu,
  ClusterActionsContextMenuActions,
} from 'pages/analysis/ClusterActionsContextMenu'
import { EditDisplayedClusters } from 'pages/analysis/EditDisplayClusters'
import { EventLimitForm } from 'pages/analysis/EventLimitForm'
import { GraphChangeType } from 'pages/analysis/GraphChangeType'
import { SaveLassoDialog } from 'pages/analysis/SaveLassoDialog'
import { useLassoTool } from 'pages/analysis/lasso'
import {
  changeChartDisplaySettings,
  toggleShowLasso,
  zoomChart,
} from 'pages/analysis/store/analysis.history.slice'
import {
  selectAnalysisScatterplotSeriesComputationParams,
  selectChartDisplaySettingsByChartId,
  selectClusterDotSizes,
  selectHighlightedCluster,
} from 'pages/analysis/store/selectors'
import { useChartScales } from 'pages/analysis/useChartScales'
import { useDrawGates } from 'pages/analysis/useDrawGates'
import { useGateWithPercentages } from 'pages/analysis/useGateWithPercentages'

import { useDialog } from 'shared/contexts/DialogContext'
import { useEventCallback } from 'shared/hooks/useEventCallback'
import { DOT_SIZING_MODES, Graph } from 'shared/models/Graphs'
import { useAppDispatch, useAppSelector } from 'shared/store'

import { ToggleInput } from '../input/ToggleInput'
import { AnalysisChartChannelSelector } from './AnalysisChartChannelSelector'
import {
  AnalysisChartContainer,
  AnalysisChartContainerInstance,
} from './AnalysisChartContainer'
import { AnalysisScatterPlotBaseOptions } from './AnalysisScatterPlotBaseOptions'
import { AnalysisScatterPlotDepthSelector } from './AnalysisScatterPlotDepthSelector'
import { HighPerformanceScatterPlot } from './HighPerformanceScatterPlot'

type AnalysisScatterPlotProps = {
  chart: Graph
  isExpanded?: boolean
  className?: string
  onCloseExpand?: () => void
}

export const AnalysisScatterPlot: React.FC<AnalysisScatterPlotProps> = ({
  chart,
  isExpanded,
  className,
  onCloseExpand = () => {},
}) => {
  const { showDialog } = useDialog()

  const dispatch = useAppDispatch()

  const isScaleDefined = useAppSelector(
    state =>
      !!selectAnalysisScatterplotSeriesComputationParams(state)[chart.id].chart
        .scale,
  )

  const [shouldDisplayChartOptions, setShouldDisplayChartOptions] =
    useState(false)
  const [contextMenu, setContextMenu] = useState<{
    clusterId: string
    x: number
    y: number
  }>()
  const [isClustersSelectionDrawerOpen, setIsClustersSelectionDrawerOpen] =
    useState(false)
  const [isEventLimitFormVisible, setIsEventLimitFormVisible] = useState(false)
  const [areGatesVisible, setAreGatesVisible] = useState(false)
  const [shouldShowExpandedSelf, setShouldShowExpandedSelf] = useState(false)
  const [highchartsContainer, setHighchartsContainer] = useState<
    HTMLDivElement | null | undefined
  >()

  const analysisChartContainerRef = useRef<AnalysisChartContainerInstance>(null)

  const handleFinishLasso = useEventCallback(() => {
    showDialog(closeDialog => (
      <SaveLassoDialog
        onSave={lassoName => {
          lassoTool.saveLasso(lassoName)
          closeDialog()
        }}
        onCancel={() => {
          lassoTool.clearCurrentLasso()
          closeDialog()
        }}
      />
    ))
  })

  const chartScales = useChartScales(chart.id)

  const lassoTool = useLassoTool({
    chartId: chart.id,
    container: highchartsContainer,
    chartScales,
    xAxis: chart.x_axis,
    yAxis: chart.y_axis,
    zoom: chart.zoom,
    onFinishLasso: handleFinishLasso,
  })

  const availableClustersActions =
    useMemo<ClusterActionsContextMenuActions>(() => {
      return {
        // cluster
        changeClusterColor: true,
        changeClusterName: true,
        expandCluster: true,
        expandVisibleClusters: true,
        mergeCluster: true,
        mergeVisibleClusters: true,
        highlightCluster: true,
        shouldChangeDotSize: true,
        toggleClusterVisibilitySelected: true,

        // lasso
        saveLasso: true,
        deleteLasso: true,
        moveLasso: true,
        changeLassoDotSize: true,
        changeLassoCreationMode: true,
        showLassoList: Object.keys(lassoTool.lassos).length > 0,
        hideClusters: true,
        showClustersOnNewChart: true,
        subAnalysisFromSelectedClusters: true,
        expandSelectedClusters: true,
        mergeSelectedClusters: true,
      }
    }, [lassoTool.lassos])

  const handleChangeOptions = useCallback(() => {
    setShouldDisplayChartOptions(true)
  }, [])

  const handleToggleLasso = useEventCallback(() => {
    lassoTool.changeIsActive(!lassoTool.isActive)
  })

  const handleCloseContextMenu = useEventCallback(() => {
    setContextMenu(undefined)
  })

  const handleToggleShowLasso = useEventCallback((lassoId: string) => {
    dispatch(toggleShowLasso({ lassoId, chartId: chart.id }))
    if (lassoTool.currentLasso?.id === lassoId) {
      lassoTool.clearCurrentLasso()
    }
  })

  if (!isScaleDefined) {
    return (
      <AnalysisChartContainer chart={chart} className={className}>
        <Grid>
          <StyledDownsamplingInfo />
          <AnalysisChartChannelSelector chart={chart} axis="x_axis" />
          <AnalysisChartChannelSelector chart={chart} axis="y_axis" />
        </Grid>
      </AnalysisChartContainer>
    )
  }

  return (
    <AnalysisChartContainer
      ref={analysisChartContainerRef}
      chart={chart}
      headerButtons={[
        {
          icon: <PhotoSizeSelectSmall />,
          onClick: handleToggleLasso,
          isActive: lassoTool.isActive,
          tooltip: lassoTool.isActive ? 'Deactivate lasso' : 'Activate lasso',
        },
      ]}
      menuButtons={[
        {
          icon: <VisibilityIcon />,
          onClick: () => setIsClustersSelectionDrawerOpen(true),
          tooltip: 'Show clusters selection',
        },
        {
          icon: <ShowChartIcon />,
          onClick: () => setIsEventLimitFormVisible(true),
          tooltip: 'Change event limit',
        },
        {
          icon: <PictureInPictureIcon />,
          onClick: () => setAreGatesVisible(true),
          tooltip: 'Show gates',
        },
      ]}
      isExpanded={isExpanded}
      className={className}
      onChangeOptions={handleChangeOptions}
      onExpand={() => setShouldShowExpandedSelf(true)}
      onCloseExpand={onCloseExpand}
    >
      <DefaultErrorBoundary>
        <InnerPlot
          chart={chart}
          lassoTool={lassoTool}
          onHighchartsContainerChange={setHighchartsContainer}
          onContextMenuChange={setContextMenu}
        />
      </DefaultErrorBoundary>
      {shouldDisplayChartOptions && (
        <GraphChangeType
          graph={chart}
          onClose={() => setShouldDisplayChartOptions(false)}
        />
      )}
      {contextMenu && (
        <ClusterActionsContextMenu
          actions={availableClustersActions}
          menuOrigin={contextMenu}
          source="graph"
          graph={chart}
          clusterId={contextMenu.clusterId}
          isLassoToolActive={lassoTool.isActive}
          lassoClusterIds={lassoTool.selectedClusters.map(
            cluster => cluster.id,
          )}
          lassoCreationMode={lassoTool.lassoCreationMode}
          selectedLasso={lassoTool.currentLasso}
          onToggleSelectLasso={handleToggleShowLasso}
          onClose={handleCloseContextMenu}
          onRenameLasso={lassoTool.saveLasso}
          onDeleteLasso={lassoTool.deleteLasso}
          onConvertCurrentLassoToFreeshape={
            lassoTool.convertCurrentLassoToFreeshape
          }
          onChangeLassoCreationMode={lassoTool.changeLassoCreationMode}
        />
      )}
      <Drawer
        open={isClustersSelectionDrawerOpen}
        onClose={() => setIsClustersSelectionDrawerOpen(false)}
        onBackdropClick={() => setIsClustersSelectionDrawerOpen(false)}
      >
        <EditDisplayedClusters
          onClose={() => setIsClustersSelectionDrawerOpen(false)}
          graphId={chart.id}
          lassoIds={Object.entries(chart.lasso_ids)
            .filter(([, v]) => !!v)
            .map(([k]) => k)}
        />
      </Drawer>
      {isEventLimitFormVisible && (
        <EventLimitForm
          anchorEl={analysisChartContainerRef.current?.anchorElement ?? null}
          graph={chart}
          onClose={() => setIsEventLimitFormVisible(false)}
        />
      )}
      {areGatesVisible && (
        <AnalysisScatterPlotGates
          chart={chart}
          onClose={() => setAreGatesVisible(false)}
        />
      )}
      {shouldShowExpandedSelf && (
        <Modal open onClose={() => setShouldShowExpandedSelf(false)}>
          <ExpandedAnalysisScatterPlot
            chart={chart}
            onCloseExpand={() => setShouldShowExpandedSelf(false)}
          />
        </Modal>
      )}
    </AnalysisChartContainer>
  )
}

type InnerPlotProps = Pick<AnalysisScatterPlotProps, 'chart'> & {
  lassoTool: ReturnType<typeof useLassoTool>
  onHighchartsContainerChange: (container: HTMLDivElement | null) => void
  onContextMenuChange: (contextMenu: {
    clusterId: string
    x: number
    y: number
  }) => void
}

const InnerPlot: FC<InnerPlotProps> = ({
  chart,
  lassoTool,
  onHighchartsContainerChange,
  onContextMenuChange,
}) => {
  const dispatch = useAppDispatch()
  const computationsParams = useAppSelector(
    state => selectAnalysisScatterplotSeriesComputationParams(state)[chart.id],
  )
  const frequencyDotSizes = useAppSelector(
    state => selectClusterDotSizes(state)[chart.id],
  )
  const uniformDotSizes = Object.fromEntries(
    Object.keys(frequencyDotSizes).map(k => [k, 1]),
  )
  const displaySettings = useAppSelector(
    state => selectChartDisplaySettingsByChartId(state)[chart.id],
  )
  const highlightedCluster = useAppSelector(selectHighlightedCluster)

  const highchartsRef = useRef<HighchartsReact.RefObject | null>(null)

  const shownActiveLeaves = useMemo(
    () =>
      computationsParams.chart.activeLeafIds.filter(
        leafId => !computationsParams.chart.hiddenClusterIds.includes(leafId),
      ),
    [
      computationsParams.chart.activeLeafIds,
      computationsParams.chart.hiddenClusterIds,
    ],
  )

  const highlightedSeriesIds = useMemo(() => {
    if (!highlightedCluster || highlightedCluster.highlightOnlyOnSunburst) {
      return []
    }
    return [highlightedCluster.clusterId]
  }, [highlightedCluster])

  const handleZoom = useEventCallback((e: SelectEventObject) => {
    dispatch(
      zoomChart({
        chartId: chart.id,
        zoom: {
          x_min: e.xAxis[0].min,
          x_max: e.xAxis[0].max,
          y_min: e.yAxis[0].min,
          y_max: e.yAxis[0].max,
        },
      }),
    )
    return false
  })

  const handleContextMenu = useEventCallback(
    (
      event: React.MouseEvent<HTMLDivElement, MouseEvent>,
      clusterId: string | undefined,
    ) => {
      if (!clusterId) return

      onContextMenuChange({
        clusterId,
        x: event.clientX,
        y: event.clientY,
      })
    },
  )

  const toggleDotSizingMode = () => {
    dispatch(
      changeChartDisplaySettings({
        chartId: chart.id,
        settings: {
          ...displaySettings,
          dot_sizing_mode:
            displaySettings.dot_sizing_mode !== 'uniform'
              ? 'uniform'
              : 'frequency',
        },
      }),
    )
  }

  const gatesWithPercentages = useGateWithPercentages({
    chartId: chart.id,
    gates: chart.gates,
  })

  const refreshGates = useDrawGates({
    chartRef: highchartsRef,
    gates: gatesWithPercentages,
    zoom: chart.zoom,
  })

  const handleRender = useEventCallback(async function (
    this: Highcharts.Chart,
  ) {
    refreshGates()
    lassoTool.refresh()
  })

  const options = useMemo((): Highcharts.Options => {
    return {
      ...AnalysisScatterPlotBaseOptions,
      chart: {
        ...AnalysisScatterPlotBaseOptions.chart,
        events: {
          selection: handleZoom,
        },
      },
      xAxis: {
        ...AnalysisScatterPlotBaseOptions.xAxis,
        type: chart.x_axis_scale_type,
      },
      yAxis: {
        ...AnalysisScatterPlotBaseOptions.yAxis,
        type: chart.y_axis_scale_type,
      },
    }
  }, [chart.x_axis_scale_type, chart.y_axis_scale_type, handleZoom])

  const setHighchartsRef = useEventCallback(
    (value: HighchartsReact.RefObject) => {
      lassoTool.setScatterPlotInstance(value)
      highchartsRef.current = value
      onHighchartsContainerChange(value.container.current)
    },
  )

  return (
    <Grid>
      <StyledHighPerformanceScatterPlot
        ref={setHighchartsRef}
        chart={{
          ...computationsParams.chart,
          scale: computationsParams.chart.scale!,
        }}
        isLassoToolActive={lassoTool.isActive}
        hoveredLassoName={lassoTool.hoveredLassoName}
        hoveredLassoClusters={lassoTool.hoveredLassoClusters}
        options={options}
        highlightedSeriesId={highlightedSeriesIds}
        clusterDotSizes={
          displaySettings.dot_sizing_mode === 'uniform'
            ? uniformDotSizes
            : frequencyDotSizes
        }
        shouldHideTooltip={lassoTool.shouldHideTooltip}
        onContextMenu={handleContextMenu}
        onRenderFinished={handleRender}
      />
      <AnalysisChartChannelSelector chart={chart} axis="x_axis" />
      <AnalysisChartChannelSelector chart={chart} axis="y_axis" />
      <AnalysisScatterPlotDepthSelector
        shownActiveLeaves={shownActiveLeaves}
        hiddenClusterIds={computationsParams.chart.hiddenClusterIds}
      />
      <ChartSettings>
        <ToggleInput
          label="Dot Sizing"
          options={DOT_SIZING_MODES}
          value={displaySettings.dot_sizing_mode || 'frequency'}
          onToggle={toggleDotSizingMode}
        />
      </ChartSettings>
    </Grid>
  )
}

const Grid = styled.div`
  width: 100%;
  height: 100%;
  display: grid;
  grid-template-rows: 1fr auto auto auto;
  grid-template-columns: 30px 1fr 30px;
  grid-template-areas:
    'y-axis chart .'
    'x-axis x-axis x-axis'
    'settings settings settings'
    'depth depth depth';
`
const ChartSettings = styled.div`
  grid-area: settings;
  padding-top: 0.25rem;
  text-align: center;
`

const StyledHighPerformanceScatterPlot = styled(HighPerformanceScatterPlot)`
  grid-area: chart;
`

const StyledDownsamplingInfo = styled(DownsamplingInfo)`
  grid-column: 2 / -1;
`

const ExpandedAnalysisScatterPlot = styled(AnalysisScatterPlot).attrs({
  isExpanded: true,
})`
  width: 90vw;
  height: 90vh;
`
