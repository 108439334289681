import { Formik, FormikConfig } from 'formik'
import { useCallback, useRef, useState } from 'react'
import ReCAPTCHA from 'react-google-recaptcha'
import { useHistory } from 'react-router'
import styled from 'styled-components'
import { object, string } from 'yup'

import { ReactComponent as ArrowRight } from 'assets/images/icons/arrowRight.svg'

import { Button } from 'components/Button'
import { LoggedOutLayout } from 'components/LoggedOutLayout'
import Input from 'components/forms/Input'
import { ReCaptcha } from 'components/forms/ReCaptcha'

import { useSendForgotPasswordMutation } from 'shared/api/auth.api'
import {
  ReCaptchaFlags,
  useReCaptchaRequired,
} from 'shared/hooks/useReCaptchaRequired'
import { useServerFormValidation } from 'shared/hooks/useServerFormValidation'
import { useAppDispatch } from 'shared/store'
import { showNotification } from 'shared/store/notification.slice'
import { handleError } from 'shared/utils/errorHandler'

type ForgotPasswordFormValues = { email: string }

const initialValues: ForgotPasswordFormValues = {
  email: '',
}

const ForgotPasswordFormSchema = object({
  email: string().email().required(),
})

export const ForgotPasswordPage = (): JSX.Element => {
  const history = useHistory()
  const dispatch = useAppDispatch()
  const [sendForgotPassword, sendForgotPasswordResult] =
    useSendForgotPasswordMutation()
  const isReCaptchaRequired = useReCaptchaRequired(
    ReCaptchaFlags.ForgotPassword,
  )
  const [isReCaptchaCompleted, setReCaptchaCompleted] = useState(false)
  const reCaptchaRef = useRef<ReCAPTCHA>(null)

  const { globalError, getFieldRequestError, setSubmittedValues } =
    useServerFormValidation({
      error: sendForgotPasswordResult.error,
      fields: Object.keys(initialValues) as (keyof ForgotPasswordFormValues)[],
    })

  const handleSubmit: FormikConfig<ForgotPasswordFormValues>['onSubmit'] =
    useCallback(
      async (values, { setSubmitting, resetForm }) => {
        try {
          const reCaptchaToken =
            (reCaptchaRef.current && reCaptchaRef.current.getValue()) || ''
          await sendForgotPassword({ reCaptchaToken, ...values })
          resetForm()
          dispatch(
            showNotification({
              type: 'success',
              description:
                'We sent you an email with a link to reset your password.',
            }),
          )
          history.push('/log-in')
        } catch (error) {
          handleError(error)
        } finally {
          setSubmittedValues(values)
          setSubmitting(false)
          reCaptchaRef.current && reCaptchaRef.current.reset()
        }
      },
      [dispatch, history, sendForgotPassword, setSubmittedValues],
    )

  return (
    <LoggedOutLayout>
      <ForgotPasswordPageRoot>
        <Formik<ForgotPasswordFormValues>
          initialValues={initialValues}
          validationSchema={ForgotPasswordFormSchema}
          onSubmit={handleSubmit}
        >
          {({
            values,
            touched,
            errors,
            isSubmitting,
            isValid,
            handleChange,
            handleBlur,
            handleSubmit,
          }) => (
            <form onSubmit={handleSubmit}>
              <Title>Please enter your email to change your password</Title>
              <ReCaptcha
                onCompleted={() => setReCaptchaCompleted(true)}
                ref={reCaptchaRef}
              >
                <Input
                  name="email"
                  value={values.email}
                  placeholder="Email"
                  error={
                    touched.email
                      ? getFieldRequestError('email', values.email) ||
                        globalError ||
                        errors.email
                      : undefined
                  }
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
              </ReCaptcha>
              <Buttons>
                <StyledButton
                  type="submit"
                  variant="contained"
                  color="primary"
                  endIcon={<ArrowRight />}
                  disabled={
                    isSubmitting ||
                    !isValid ||
                    (isReCaptchaRequired && !isReCaptchaCompleted)
                  }
                >
                  Send
                </StyledButton>
                <Button
                  type="button"
                  grey
                  onClick={() => history.push('/log-in')}
                >
                  Back
                </Button>
              </Buttons>
            </form>
          )}
        </Formik>
      </ForgotPasswordPageRoot>
    </LoggedOutLayout>
  )
}

const ForgotPasswordPageRoot = styled.div`
  background: ${props => props.theme.colors.white};
  padding: ${props => props.theme.spacing(4, 8)};
  border-radius: ${props => props.theme.radius[2]}px;
  box-shadow: ${props => props.theme.shadow[2]};
  width: 100%;
`

const Title = styled.p`
  margin-bottom: ${props => props.theme.spacing(4)}px;
  text-align: center;
  font-family: ${props => props.theme.font.style.bold};
`

const Buttons = styled.div`
  display: flex;
  justify-content: flex-end;
  gap: ${props => props.theme.spacing(2)}px;
`

const StyledButton = styled(Button)`
  margin-left: auto;
  height: 50px;
  width: 120px;
  display: flex;
  padding: 0 25px;
  justify-content: space-between;
`
