import Highcharts from 'highcharts'

import { createAnalysisNetworkGraphBaseOptions } from 'components/graphs/AnalysisNetworkGraphBaseOptions'

import {
  selectActiveClusters,
  selectGloballyHiddenClusters,
  selectHighlightedCluster,
} from 'pages/analysis/store/selectors'

import { RootState } from 'shared/store'

type RenderNetworkGraphProps = {
  state: RootState
  width: number
  height: number
}

export const renderNetworkGraph = async ({
  state,
  width,
  height,
}: RenderNetworkGraphProps): Promise<string> => {
  const activeClusters = selectActiveClusters(state)
  const highlightedCluster = selectHighlightedCluster(state)
  const globallyHiddenClusters = selectGloballyHiddenClusters(state)

  const AnalysisNetworkGraphBaseOptions = createAnalysisNetworkGraphBaseOptions(
    {
      activeClusters,
      globallyHiddenClusters,
      highlightedCluster,
    },
  )

  return new Highcharts.Chart(document.createElement('div'), {
    ...AnalysisNetworkGraphBaseOptions,
    chart: {
      ...AnalysisNetworkGraphBaseOptions.chart,
      width,
      height,
    },
  }).getSVG()
}
