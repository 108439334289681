import React from 'react'
import styled from 'styled-components'

type SectionHeaderProps = {
  title: string
  children?: React.ReactNode
  className?: string
}

export const SectionHeader: React.FC<SectionHeaderProps> = ({
  title,
  children,
  className,
}) => {
  return (
    <SectionHeaderRoot className={className}>
      <Title>{title}</Title>
      <div>{children}</div>
    </SectionHeaderRoot>
  )
}

const SectionHeaderRoot = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 32px;
`

const Title = styled.h1`
  font-family: OpenSans-Bold;
`
