import { Collapse, Tooltip } from '@material-ui/core'
import { Help as HelpIcon } from '@material-ui/icons'
import React, { ReactNode, useState } from 'react'
import styled from 'styled-components'

import { Icon } from 'assets/images/icons/Icon'

import { theme } from 'Theme'

interface SectionProps {
  title: ReactNode
  contentClassName?: string
  collapsable?: boolean
  children?: ReactNode
  helpText?: string
}

export const Section: React.FunctionComponent<SectionProps> = ({
  title,
  children,
  contentClassName,
  collapsable,
  helpText,
}) => {
  const [open, setOpen] = useState<boolean>(!collapsable)
  const onTitleClick = () => collapsable && setOpen(!open)

  return (
    <SectionContainer>
      <Title onClick={onTitleClick} collapsable={collapsable}>
        {collapsable && (
          <IconArrow
            name="arrowUp"
            $active={open}
            color={theme.colors.primaryDark[70]}
          />
        )}
        {title}
        {helpText && (
          <Tooltip title={helpText}>
            <StyledHelpIcon />
          </Tooltip>
        )}
      </Title>
      <Collapse in={open}>
        <Content className={contentClassName}>{children}</Content>
      </Collapse>
    </SectionContainer>
  )
}

const SectionContainer = styled.div`
  border: 1px solid ${props => props.theme.colors.primary[20]};
  border-radius: ${props => props.theme.radius[2]}px;
  box-shadow: ${props => props.theme.shadow[1]};
  margin-top: 16px;
  overflow: hidden;
`

const Title = styled.div<{
  collapsable?: boolean
}>`
  display: flex;
  align-items: center;
  background-color: ${props => props.theme.colors.primaryDark[10]};
  border-bottom: 1px solid ${props => props.theme.colors.primary[20]};
  padding: 16px 32px;
  cursor: ${props => (props.collapsable ? 'pointer' : 'initial')};
`

const Content = styled.div`
  background-color: ${props => props.theme.colors.white};
  padding: 16px 32px;
`

const IconArrow = styled(Icon)<{ $active?: boolean }>`
  height: 8px;
  margin-right: 8px;
  transform: ${props => (props.$active ? 'rotate(180deg)' : 'rotate(90deg)')};
  transition: all 0.4s ease;
`

const StyledHelpIcon = styled(HelpIcon)`
  margin-left: 8px;
  cursor: pointer;
  width: 20px;
  height: 20px;
`
