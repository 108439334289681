import { FC } from 'react'
import styled from 'styled-components'

import { CircularProgress } from 'components/CircularProgress'
import { StatusDots } from 'components/StatusDots'
import { Table, TableColumn, TableRow } from 'components/table/Table'

import { Brick, useGetBrickQuery } from 'shared/api/workflows.api'
import { formatDate } from 'shared/utils/utils'

type PipelineReferenceModalAnalysesProps = {
  brick: Brick
  columns: TableColumn[]
}

export const PipelineReferenceModalAnalyses: FC<
  PipelineReferenceModalAnalysesProps
> = ({ brick, columns }) => {
  const getBrickQueryState = useGetBrickQuery(brick.id)

  const rows: TableRow[] =
    getBrickQueryState.data?.analyses.map(analysis => ({
      key: brick.id + analysis.id,
      cells: [
        {
          key: 'offset',
          content: '',
        },
        {
          key: 'reference_analysis',
          content: analysis.name,
        },
        {
          key: 'created_at',
          content: formatDate(analysis.created_at),
        },
        {
          key: 'author',
          content: analysis.author_name,
        },
        {
          key: 'status',
          content: <StatusDots status={analysis.status} />,
        },
      ],
    })) ?? []

  return (
    <TableWrapper>
      {getBrickQueryState.isLoading ? (
        <CircularProgress />
      ) : (
        <StyledTable
          columns={[{ key: 'offset', style: { width: 64 } }, ...columns]}
          rows={rows}
        />
      )}
    </TableWrapper>
  )
}

const TableWrapper = styled.div`
  background-color: ${props => props.theme.colors.greyscale[5]};
  border-radius: 0 0
    ${props => `${props.theme.radius[2]}px ${props.theme.radius[2]}px`};
`

const StyledTable = styled(Table)`
  border-spacing: 0 ${props => props.theme.spacing(1)}px;
  border-collapse: separate;
  border-top: 20px solid ${props => props.theme.colors.greyscale[5]};
  padding: ${props => props.theme.spacing(0, 1)};

  & .MuiTableRow-root {
    box-shadow: none;

    &:last-of-type {
      box-shadow: 0px 4px 10px 0px #e8e9f399;

      & .MuiTableCell-body {
        &:first-of-type {
          border-bottom-left-radius: ${props => props.theme.radius[2]}px;
        }
        &:last-of-type {
          border-bottom-right-radius: ${props => props.theme.radius[2]}px;
        }
      }
    }
  }
`
