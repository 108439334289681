import Highcharts from 'highcharts'
import { mapValues } from 'lodash'

import {
  computeSeriesImageDataURL,
  renderSeriesImage,
} from 'components/graphs/high-performance-scatter-plot.utils'

import { createMetaAnalysisDimensionalityReductionChartBaseOptions } from 'pages/meta-analysis/MetaAnalysisDimensionalityReductionChartBaseOptions'

import { FcsFile } from 'shared/api/files.api'
import {
  MetaAnalysisFile,
  MetaAnalysisDimensionalityReductionChartType,
  MetaAnalysisGlobalVizFile,
} from 'shared/api/meta-analysis.api'
import { metaAnalysisWorker } from 'shared/worker'

type RenderDimensionalityReductionChartProps = {
  chart: MetaAnalysisDimensionalityReductionChartType
  metaAnalysisFile: MetaAnalysisFile
  fcsFiles: FcsFile[]
  globalVizFile: MetaAnalysisGlobalVizFile
  dimensionalityReductionMethod: string
  width: number
  height: number
}

export const renderDimensionalityReductionChart = async ({
  chart,
  metaAnalysisFile,
  fcsFiles,
  globalVizFile,
  dimensionalityReductionMethod,
  width,
  height,
}: RenderDimensionalityReductionChartProps): Promise<string> => {
  const { scale } =
    await metaAnalysisWorker.computeDimensionalityReductionSeries({
      metaAnalysisFile,
      globalVizFile,
      fcsFiles,
      plotWidth: undefined,
      plotHeight: undefined,
      zoom: undefined,
      chart,
    })

  const MetaAnalysisDimensionalityReductionChartBaseOptions =
    createMetaAnalysisDimensionalityReductionChartBaseOptions(
      dimensionalityReductionMethod,
    )

  const highchartsChart = new Highcharts.Chart(document.createElement('div'), {
    ...MetaAnalysisDimensionalityReductionChartBaseOptions,
    chart: {
      ...MetaAnalysisDimensionalityReductionChartBaseOptions.chart,
      width,
      height,
    },
    title: {
      ...MetaAnalysisDimensionalityReductionChartBaseOptions.title,
      text: chart.name,
    },
    xAxis: {
      ...MetaAnalysisDimensionalityReductionChartBaseOptions.xAxis,
      ...scale.xAxis,
    },
    yAxis: {
      ...MetaAnalysisDimensionalityReductionChartBaseOptions.yAxis,
      ...scale.yAxis,
    },
  })

  const { series, metadataBySeriesId } =
    await metaAnalysisWorker.computeDimensionalityReductionSeries({
      metaAnalysisFile,
      globalVizFile,
      fcsFiles,
      plotWidth: highchartsChart.plotWidth,
      plotHeight: highchartsChart.plotHeight,
      zoom: undefined,
      chart,
    })

  if (!series) {
    throw new Error('Could not compute series')
  }

  const imageDataUrl = computeSeriesImageDataURL({
    width: series.plotWidth,
    height: series.plotHeight,
    seriesIdByPixelPosition: series.seriesIdByPixelPosition,
    colorBySeriesId: mapValues(metadataBySeriesId, 'color'),
  })

  highchartsChart.options.chart!.events = {
    render: function (this: Highcharts.Chart) {
      renderSeriesImage(this, imageDataUrl)
    },
  }

  return highchartsChart.getSVG()
}
