import { useEffect, useState } from 'react'
import { useError } from 'react-use'

import { useStable } from './useStable'

export const useLoadSeries = <P, R>(
  seriesComputationsParams: P,
  computeSeries: (params: P) => Promise<R>,
): R | undefined => {
  const throwError = useError()
  const stableSeriesComputationsParams = useStable(seriesComputationsParams)

  const [series, setSeries] = useState<R>()

  useEffect(() => {
    let cancelled = false
    computeSeries(stableSeriesComputationsParams)
      .then(series => {
        if (!cancelled) {
          setSeries(series)
        }
      })
      .catch(throwError)

    return () => {
      cancelled = true
    }
  }, [computeSeries, stableSeriesComputationsParams, throwError])

  return series
}
