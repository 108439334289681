import { FC, useState } from 'react'
import { useDebounce } from 'react-use'
import styled from 'styled-components'

import { ReactComponent as SearchIcon } from 'assets/images/icons/search.svg'

import { useEventCallback } from 'shared/hooks/useEventCallback'

type SearchInputProps = {
  placeholder?: string
  debounceTime?: number
  className?: string
  onChange: (value: string) => void
}

export const SearchInput: FC<SearchInputProps> = ({
  placeholder = '',
  debounceTime = 500,
  className,
  onChange,
}) => {
  const [searchValue, setSearchValue] = useState('')
  useDebounce(() => onChange(searchValue), debounceTime, [searchValue])

  const handleChange = useEventCallback((value: string) =>
    setSearchValue(value),
  )

  return (
    <InputWrapper className={className}>
      <SearchIcon />
      <StyledInput
        placeholder={placeholder}
        value={searchValue}
        onChange={event => handleChange(event.target.value)}
      />
    </InputWrapper>
  )
}

const InputWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: ${props => props.theme.spacing(1)}px;
  height: 42px;
  padding: 0 ${props => props.theme.spacing(3)}px;
  background: ${props => props.theme.colors.white};
  border-radius: 21px;
  box-shadow: 0px 4px 10px 0px #e8e9f399;
`

const StyledInput = styled.input`
  border: none;
  outline: none;
  font-size: ${props => props.theme.font.size.small}px;
  color: ${props => props.theme.colors.primaryDark[100]};
  flex-grow: 1;

  &::placeholder {
    color: ${props => props.theme.colors.primaryDark[50]};
  }
`
