import { Box, TextField } from '@material-ui/core'
import { useCallback, useState } from 'react'
import styled from 'styled-components'

import { Checkbox } from 'components/Checkbox'
import { ContextMenu } from 'components/ContextMenu'

import { Graph } from 'shared/models/Graphs'
import { useAppDispatch } from 'shared/store'

import {
  setChartEventLimit,
  setChartEventLimitForAllCharts,
} from './store/analysis.slice'

type EventLimitFormProps = {
  anchorEl: HTMLElement | null
  graph: Graph
  onClose: () => void
}

export const EventLimitForm = ({
  anchorEl,
  graph,
  onClose,
}: EventLimitFormProps): JSX.Element => {
  const dispatch = useAppDispatch()

  const [eventLimit, setEventLimit] = useState(graph.event_limit ?? 100)
  const [
    isChangeAllChartsEventLimitSelected,
    toggleChangeAllChartsEventLimitSelected,
  ] = useState(false)

  const handleEventLimitChange = (limit: number) => {
    const eventPercentage = limit > 100 ? 100 : limit < 0 ? 0 : limit
    setEventLimit(eventPercentage)
  }

  const handleLimitChange = useCallback(
    (eventLimit: number) => {
      if (isChangeAllChartsEventLimitSelected) {
        dispatch(setChartEventLimitForAllCharts({ eventLimit }))
      } else {
        dispatch(
          setChartEventLimit({
            chartId: graph.id,
            eventLimit,
          }),
        )
      }
      return false
    },
    [dispatch, graph, isChangeAllChartsEventLimitSelected],
  )

  return (
    <>
      <ContextMenu
        open
        anchorEl={anchorEl}
        onClose={() => {
          handleLimitChange(eventLimit)
          onClose()
        }}
      >
        <Form component="form">
          <StyledTextField
            id="outlined-number"
            variant="outlined"
            label="Events on display"
            type="number"
            InputProps={{
              inputProps: { min: '0', max: '100', step: '1' },
              endAdornment: '%',
            }}
            value={eventLimit}
            onChange={event =>
              handleEventLimitChange(Number(event.target.value))
            }
            onKeyDown={event => {
              if (event.key === 'Enter') {
                event.preventDefault()
                handleLimitChange(eventLimit)
                onClose()
              }
            }}
          />
          <StyledCheckbox
            checked={isChangeAllChartsEventLimitSelected}
            onChange={() => {
              toggleChangeAllChartsEventLimitSelected(
                !isChangeAllChartsEventLimitSelected,
              )
            }}
            label="Apply number to all graphs"
          />
        </Form>
      </ContextMenu>
    </>
  )
}

const Form = styled(Box)`
  padding: 10px;
  width: 100%;
`
const StyledCheckbox = styled(Checkbox)`
  margin: 0;
`

const StyledTextField = styled(TextField)`
  width: 100%;
`
