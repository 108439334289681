import styled from 'styled-components'

import { Icon } from 'assets/images/icons/Icon'

type AddButtonProps = {
  scale?: number
  disabled?: boolean
  onClick: () => void
}

export const AddButton = ({
  scale,
  disabled,
  onClick,
}: AddButtonProps): JSX.Element => {
  return (
    <AddButtonRoot $scale={scale}>
      <OuterCircle disabled={disabled} onClick={onClick}>
        <InnerIcon disabled={disabled} name="add" />
      </OuterCircle>
    </AddButtonRoot>
  )
}

const AddButtonRoot = styled.div<{ $scale?: number }>`
  margin: auto;
  scale: ${props => props.$scale};
  position: relative;
`

const OuterCircle = styled.div<{ disabled: boolean | undefined }>`
  --width: 95px;
  --height: 95px;
  position: absolute;
  left: calc(50% - var(--width) / 2);
  top: calc(50% - var(--height) / 2);
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  height: var(--height);
  width: var(--width);
  transition: all ease-out 0.2s;
  border-radius: 50%;
  pointer-events: ${props => (props.disabled ? 'none' : '')};
  background-color: ${props =>
    props.disabled
      ? props.theme.colors.greyscale[10]
      : props.theme.colors.successLight};

  &:hover {
    --width: 110px;
    --height: 110px;
    transition: all ease-in 0.2s;
    & svg {
      transition: all ease-out 0.2s;
      box-shadow: 0 0 0 5px
        ${props =>
          props.disabled
            ? props.theme.colors.greyscale[20]
            : props.theme.colors.secondary[100]};
    }
  }
`

const InnerIcon = styled(Icon)<{ disabled: boolean | undefined }>`
  color: ${props =>
    props.disabled
      ? props.theme.colors.greyscale[20]
      : props.theme.colors.secondary[100]};
  fill: ${props =>
    props.disabled
      ? props.theme.colors.greyscale[20]
      : props.theme.colors.secondary[100]};

  border-radius: 50%;
  transition: all ease-in 0.2s;
  box-shadow: 0 0 0 8px
    ${props =>
      props.disabled
        ? props.theme.colors.greyscale[20]
        : props.theme.colors.secondary[100]};

  background-color: ${props =>
    props.disabled
      ? props.theme.colors.greyscale[20]
      : props.theme.colors.secondary[100]};
`
