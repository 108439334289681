import { CreateBrickPayload } from 'shared/api/workflows.api'

import { WorkflowBrickSettings } from './WorkflowBrickSettings'

type CreateBrickWizardSummaryStepProps = {
  finalPayload: CreateBrickPayload
  isSubAnalysis: boolean
}

export const CreateBrickWizardSummaryStep: React.FC<
  CreateBrickWizardSummaryStepProps
> = ({ finalPayload, isSubAnalysis }) => {
  return (
    <WorkflowBrickSettings
      name={finalPayload.name}
      cytometer={finalPayload.settings.cytometer}
      isRoot={false}
      metaClean={false}
      transformationMethod={finalPayload.settings.transformation_method}
      clusterNames={finalPayload.settings.cluster_names}
      lassos={finalPayload.settings.lassos}
      isSubAnalysis={isSubAnalysis}
      channels={finalPayload.settings.channels}
      precisionAndGranularityMode={
        finalPayload.settings.precision_and_granularity_mode
      }
      kt={finalPayload.settings.kt}
      kd={finalPayload.settings.kd}
    />
  )
}
