import { IconButton, Typography } from '@material-ui/core'
import { useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import styled from 'styled-components'

import { Icon } from 'assets/images/icons/Icon'
import { ReactComponent as ArrowLeftIcon } from 'assets/images/icons/analysis/arrowLeft.svg'

import { LoadingMask } from 'components/LoadingMask'
import { Table } from 'components/Table'
import { TableRow } from 'components/TableRow'

import { PackageResourceCard } from 'pages/buy-package/PackageResourceCard'

import { useGetPaymentsQuery } from 'shared/api/payments.api'
import { useAppDispatch } from 'shared/store'
import { showNotification } from 'shared/store/notification.slice'
import { handleError } from 'shared/utils/errorHandler'
import {
  downloadFile,
  formatDate,
  formatTime,
  getURLSearchParam,
  href,
} from 'shared/utils/utils'

export const PaymentsHistory = (): JSX.Element => {
  const paymentStatus = getURLSearchParam('payment-status')

  const paymentsResult = useGetPaymentsQuery()
  const dispatch = useAppDispatch()
  const history = useHistory()

  const payments = paymentsResult.data?.results

  const downloadInvoice = async (paymentId: string) => {
    downloadFile(href(`/payment-transactions/${paymentId}/invoice/`)).then(
      () => {
        dispatch(
          showNotification({
            type: 'success',
            description: 'Invoice downloaded successfully',
          }),
        )
      },
      () => {
        dispatch(
          showNotification({
            type: 'error',
            description: 'Cannot download invoice',
          }),
        )
      },
    )
  }

  useEffect(() => {
    if (paymentStatus === 'failure') {
      handleError(new Error('Stripe payment has failed'))
      dispatch(
        showNotification({
          type: 'error',
          description:
            'Payment has failed. Reach out to us at contact@metaflow.bio',
        }),
      )
    }
  }, [dispatch, paymentStatus])

  if (paymentsResult.isLoading) {
    return <LoadingMask />
  }

  return (
    <PaymentsHistoryContainer>
      <BackButton size="medium" onClick={() => history.push('/license-wallet')}>
        <ArrowLeftIcon />
        <Typography variant="caption">Back to wallet</Typography>
      </BackButton>
      <TableWrapper layout={['40%', '20%', '15%', '15%', '10%']}>
        <TableHeader>
          <p>Payment ID</p>
          <p>Payment Date</p>
          <p>Amount</p>
          <p>Status</p>
          <p>Invoice</p>
        </TableHeader>
        {payments ? (
          payments.map(payment => (
            <PaymentWrapper key={payment.id}>
              <StyledRow>
                <p title={payment.id}>{payment.id}</p>
                <p
                  title={
                    formatDate(new Date(payment.created_at)) +
                    ' ' +
                    formatTime(new Date(payment.created_at))
                  }
                >
                  {formatDate(new Date(payment.created_at)) +
                    ' ' +
                    formatTime(new Date(payment.created_at))}
                </p>
                <p
                  title={`${payment.total_price} ${payment.currency}`}
                >{`${payment.total_price} ${payment.currency}`}</p>
                <p title={payment.status_display}>{payment.status_display}</p>
                <div title="Invoice">
                  <IconWrapper
                    $disabled={!payment.invoice_ready}
                    onClick={() => downloadInvoice(payment.id)}
                  >
                    <Icon name="clustering" />
                  </IconWrapper>
                </div>
              </StyledRow>
              <ResourceRowWrapper>
                {payment.basket.baskets_items
                  .map(item =>
                    item.license_package.license_packages_resource_types.map(
                      resource => (
                        <PackageResourceCard
                          key={resource.resource_type}
                          type={resource.resource_type}
                          value={resource.value}
                        />
                      ),
                    ),
                  )
                  .flat()}
              </ResourceRowWrapper>
            </PaymentWrapper>
          ))
        ) : (
          <div>No payments to show</div>
        )}
      </TableWrapper>
    </PaymentsHistoryContainer>
  )
}

const PaymentsHistoryContainer = styled.div`
  padding-top: ${props => props.theme.layout.headerHeight + 24}px;
  width: 80%;
  margin: auto;
`

const TableWrapper = styled(Table)`
  width: 100%;
  margin: 20px 0;
`

const TableHeader = styled(TableRow)`
  color: ${props => props.theme.colors.greyscale[50]};
  font-family: ${props => props.theme.font.style.bold};
  align-items: center;
  padding: 0 0 20px 0;

  & > p {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  & > p:last-of-type {
    text-align: center;
  }
`

const StyledRow = styled(TableRow)`
  align-items: center;
  padding: 0 0 20px 0;

  & > p {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  & > div {
    display: flex;
    justify-content: center;
  }
`

const IconWrapper = styled.div<{ $disabled?: boolean }>`
  max-width: 20px;
  max-height: 20px;
  flex-basis: 25%;
  margin: auto;
  pointer-events: ${props => (props.$disabled ? 'none' : 'auto')};

  & > svg {
    color: ${props =>
      props.$disabled
        ? props.theme.colors.greyscale[50]
        : props.theme.colors.primaryDark[100]};
  }

  &:hover {
    cursor: ${props => (props.$disabled ? 'not-allowed' : 'pointer')};
  }
`

const ResourceRowWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: ${props => props.theme.spacing(1)}px;
  padding-bottom: 10px;
`

const PaymentWrapper = styled.div`
  margin-bottom: 20px;
  border-bottom: 1px solid ${props => props.theme.colors.primaryDark[20]};
`

const BackButton = styled(IconButton)`
  color: ${props => props.theme.colors.primaryDark[100]};

  &:hover {
    background: transparent;
  }

  & > span > svg {
    margin-right: 8px;
  }
`
