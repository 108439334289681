import Highcharts from 'highcharts'
import HighchartsCustomEvents from 'highcharts-custom-events'
import HighchartsMore from 'highcharts/highcharts-more'
import AccessibilityModule from 'highcharts/modules/accessibility'
import Boost from 'highcharts/modules/boost'
import DrilldownModule from 'highcharts/modules/drilldown'
import Exporting from 'highcharts/modules/exporting'
import HeatMap from 'highcharts/modules/heatmap'
import Histogram from 'highcharts/modules/histogram-bellcurve'
import NetworkGraphModule from 'highcharts/modules/networkgraph'
import PatternFillModule from 'highcharts/modules/pattern-fill'
import SeriesLabelModule from 'highcharts/modules/series-label'
import SunburstModule from 'highcharts/modules/sunburst'
import TreeGraphModule from 'highcharts/modules/treegraph'
import TreeMapModule from 'highcharts/modules/treemap'
import Variwide from 'highcharts/modules/variwide'
import ReactDOMClient from 'react-dom/client'
import ReactGA from 'react-ga'
import 'reactflow/dist/style.css'

import 'shared/export/analysis/generateAnalysisPdf'
import { handleError } from 'shared/utils/errorHandler'
import { getInjectedEnvironmentVariable } from 'shared/utils/getInjectedEnvironmentVariable'

import { App } from './App'
import { initSentry } from './initSentry'

const enhancedWindow = window as unknown as { __COMMIT_HASH__: string }
enhancedWindow.__COMMIT_HASH__ = __COMMIT_HASH__

HeatMap(Highcharts)
Histogram(Highcharts)
DrilldownModule(Highcharts)
SunburstModule(Highcharts)
PatternFillModule(Highcharts)
HighchartsMore(Highcharts)
NetworkGraphModule(Highcharts)
SeriesLabelModule(Highcharts)
AccessibilityModule(Highcharts)
Exporting(Highcharts)
Variwide(Highcharts)
Boost(Highcharts)
TreeGraphModule(Highcharts)
TreeMapModule(Highcharts)
// eslint-disable-next-line @typescript-eslint/no-explicit-any
HighchartsCustomEvents(Highcharts as any)

if (getInjectedEnvironmentVariable('PDF_MODE') !== 'true') {
  const TRACKING_ID = getInjectedEnvironmentVariable(
    'GOOGLE_ANALYTICS_TRACKING_ID',
  )

  if (!TRACKING_ID) {
    handleError('Google Analytics tracking ID does not exist')
  } else {
    ReactGA.initialize(TRACKING_ID)
    ReactGA.pageview(window.location.pathname + window.location.search)
  }

  initSentry()

  ReactDOMClient.createRoot(
    document.getElementById('root') as HTMLElement,
  ).render(<App />)
}
