import {
  cloneElement,
  FC,
  isValidElement,
  useContext,
  Children,
  ReactNode,
  HTMLAttributes,
} from 'react'
import { isPortal } from 'react-is'
import styled from 'styled-components'

import { includeIf } from 'shared/utils/utils'

import { TableContext } from './Table'

type TableRowProps = {
  children?: ReactNode
} & HTMLAttributes<HTMLDivElement>

export const TableRow: FC<TableRowProps> = ({ children, onClick, ...rest }) => {
  const layout = useContext(TableContext)
  return (
    <TableRowContainer
      data-cy="table-row"
      {...includeIf(onClick, { onClick })}
      {...rest}
    >
      {Children.map(children, (child, index) => {
        if (!isValidElement(child) || isPortal(child)) {
          return child
        }
        if (layout === undefined) {
          throw new Error('TableRow must be used within a Table component')
        }

        return cloneElement(child, { style: { flexBasis: layout[index] } })
      })}
    </TableRowContainer>
  )
}

const TableRowContainer = styled.div`
  display: flex;
  & > * {
    padding-right: 10px;
  }
`
