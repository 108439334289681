import { TextField as MuiTextField } from '@material-ui/core'
import styled from 'styled-components'

export const TextField = styled(MuiTextField)`
  .MuiInputLabel-root {
    transition: none;
    transform: none;
    position: static;
    line-height: 16px;
    font-size: 12px;
    color: ${({ theme }) => theme.colors.primaryDark[100]};
  }

  .MuiInputBase-root {
    justify-content: center;
    margin-top: 0;
    font-size: 1em;

    :before,
    :after {
      content: none;
    }
  }

  .MuiInputBase-input {
    border: 0.2px solid #a4a4a4;
    border-radius: ${({ theme }) => theme.radius[2]}px;
    padding: ${({ theme }) => theme.spacing(1)}px;
    color: ${({ theme }) => theme.colors.primaryDark[70]};
    font-size: 1em;
  }
`
