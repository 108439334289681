import {
  FormatterCallbackFunction,
  Point,
  Series,
  PointOptionsObject,
  TooltipFormatterCallbackFunction,
} from 'highcharts'
import {
  forwardRef,
  useImperativeHandle,
  useMemo,
  useRef,
  useState,
} from 'react'
import { renderToStaticMarkup } from 'react-dom/server'
import styled, { useTheme } from 'styled-components'

import { DefaultErrorBoundary } from 'components/DefaultErrorBoundary'
import { Modal } from 'components/Modal'
import { Chart } from 'components/graphs/Chart'

import { MetaAnalysisVolcanoPlotType } from 'shared/api/meta-analysis.api'
import { useEventCallback } from 'shared/hooks/useEventCallback'
import { useAppDispatch, useAppSelector } from 'shared/store'
import { downloadText, useSize } from 'shared/utils/utils'

import { MetaAnalysisChartContainer } from './MetaAnalysisChartContainer'
import { createMetaAnalysisVolcanoPlotBaseOptions } from './MetaAnalysisVolcanoPlotBaseOptions'
import { MetaAnalysisVolcanoPlotOptions } from './MetaAnalysisVolcanoPlotOptions'
import { computeVolcanoPlotSeries } from './series'
import { updateMetaAnalysisChart } from './store/meta-analysis.history.slice'
import {
  selectMetaAnalysisGroupNames,
  selectMetaAnalysisName,
  selectMetaAnalysisVolcanoPlotFile,
} from './store/selectors'
import { useMetaAnalysisCsvMetadata } from './useMetaAnalysisCsvMetadata'

type MetaAnalysisVolcanoPlotProps = {
  chart: MetaAnalysisVolcanoPlotType
  isExpanded?: boolean
  className?: string
  onCloseExpand?: () => void
}

export const MetaAnalysisVolcanoPlot = ({
  chart,
  isExpanded,
  className,
  onCloseExpand,
}: MetaAnalysisVolcanoPlotProps): JSX.Element => {
  const dispatch = useAppDispatch()
  const groupNames = useAppSelector(selectMetaAnalysisGroupNames)

  const [ref, { width, height }] = useSize<HTMLDivElement>({ debounce: 250 })
  const innerPlotRef = useRef<InnerPlotInstance>(null)

  const [shouldDisplayChartOptions, setShouldDisplayChartOptions] =
    useState(false)
  const [shouldShowExpandedSelf, setShouldShowExpandedSelf] = useState(false)

  const handleChangeOptions = useEventCallback(() => {
    setShouldDisplayChartOptions(true)
  })

  const handleCancel = useEventCallback(() => {
    setShouldDisplayChartOptions(false)
  })

  const handleApplyOptions = useEventCallback(
    (groups: [string, string], xThreshold: number, yThreshold: number) => {
      dispatch(
        updateMetaAnalysisChart({
          id: chart.id,
          selectedGroups: groups,
          xThreshold,
          yThreshold,
        }),
      )
      setShouldDisplayChartOptions(false)
    },
  )

  const handleDownload = useEventCallback(() => {
    innerPlotRef.current?.downloadSeries()
  })

  return (
    <MetaAnalysisChartContainer
      containerRef={ref}
      chart={chart}
      isExpanded={isExpanded}
      className={className}
      onChangeOptions={handleChangeOptions}
      onExpand={() => setShouldShowExpandedSelf(true)}
      onCloseExpand={onCloseExpand}
      onDownload={handleDownload}
    >
      <DefaultErrorBoundary>
        <InnerPlot
          chart={chart}
          width={width}
          height={height}
          ref={innerPlotRef}
        />
      </DefaultErrorBoundary>
      {shouldDisplayChartOptions && (
        <StyledMetaAnalysisVolcanoPlotOptions
          mode="edit"
          initialSelectedGroups={
            chart.selectedGroups || [groupNames[0], groupNames[1]]
          }
          initialXThreshold={chart.xThreshold || 0}
          initialYThreshold={chart.yThreshold || 0}
          onCancel={handleCancel}
          onFinish={handleApplyOptions}
        />
      )}
      {shouldShowExpandedSelf && (
        <Modal open onClose={() => setShouldShowExpandedSelf(false)}>
          <ExpandedMetaAnalysisVolcanoPlot
            chart={chart}
            onCloseExpand={() => setShouldShowExpandedSelf(false)}
          />
        </Modal>
      )}
    </MetaAnalysisChartContainer>
  )
}

type InnerPlotProps = Pick<MetaAnalysisVolcanoPlotProps, 'chart'> & {
  width: number
  height: number
}

type InnerPlotInstance = {
  downloadSeries: () => void
}

const InnerPlot = forwardRef<InnerPlotInstance, InnerPlotProps>(
  ({ chart, width, height }, ref) => {
    const theme = useTheme()

    const volcanoPlotFile = useAppSelector(selectMetaAnalysisVolcanoPlotFile)
    const metaAnalysisName = useAppSelector(selectMetaAnalysisName)

    const series = useMemo(
      () => computeVolcanoPlotSeries(chart, volcanoPlotFile, theme),
      [chart, theme, volcanoPlotFile],
    )

    const groupsLabel = useMemo(() => {
      const re = new RegExp(
        `${chart.selectedGroups[0]}_vs_${chart.selectedGroups[1]}_foldchange`,
        'g',
      )
      if (Object.keys(volcanoPlotFile).some(key => key.match(re))) {
        return `${chart.selectedGroups[0]} vs ${chart.selectedGroups[1]}`
      } else {
        return `${chart.selectedGroups[1]} vs ${chart.selectedGroups[0]}`
      }
    }, [chart.selectedGroups, volcanoPlotFile])

    const createCsvMetadata = useMetaAnalysisCsvMetadata()
    const downloadSeries = useEventCallback(async () => {
      const legendRow = `groups,${groupsLabel}`
      const headerRow = 'group,cluster,x,y'
      const dataRows = series.flatMap(group => {
        return (group.data as PointOptionsObject[]).map(
          ({ name, x, y }) => `${group.name},${name},${x},${y}`,
        )
      })
      const csv =
        (await createCsvMetadata()) +
        [legendRow, headerRow, ...dataRows].join('\n')
      downloadText(csv, `${metaAnalysisName} - ${chart.name}.csv`)
    })
    useImperativeHandle(ref, () => ({ downloadSeries }), [downloadSeries])

    const options: Highcharts.Options = useMemo(() => {
      const formatTooltip: TooltipFormatterCallbackFunction = function (this) {
        return renderToStaticMarkup(
          <div
            style={{
              padding: 8,
              fontSize: 13,
              minWidth: 120,
              background: theme.colors.white,
            }}
          >
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
              }}
            >
              <span style={{ fontFamily: theme.font.style.bold }}>
                {this.point.name}
              </span>
              <span
                style={{
                  color: this.point.color as string,
                  marginRight: 4,
                  fontSize: 14,
                }}
              >
                ⬤
              </span>
            </div>
            <div style={{ fontSize: 12, color: theme.colors.primaryDark[70] }}>
              Fold Change: {this.x}
            </div>
            <div
              style={{
                fontSize: 12,
                marginTop: 6,
                color: theme.colors.primaryDark[70],
              }}
            >
              Mean of Normalized Count: {this.y}
            </div>
          </div>,
        )
      }

      const formatLegendLabel: FormatterCallbackFunction<Point | Series> =
        function (this) {
          if (this.name !== 'None') {
            return `${this.name} in ${groupsLabel}`
          } else {
            return this.name
          }
        }

      const MetaAnalysisVolcanoPlotBaseOptions =
        createMetaAnalysisVolcanoPlotBaseOptions(
          theme,
          chart.xThreshold,
          chart.yThreshold,
        )

      return {
        ...MetaAnalysisVolcanoPlotBaseOptions,
        chart: {
          ...MetaAnalysisVolcanoPlotBaseOptions.chart,
          width,
          height,
        },
        series,
        tooltip: {
          enabled: true,
          formatter: formatTooltip,
        },
        legend: {
          enabled: true,
          labelFormatter: formatLegendLabel,
        },
        title: {
          align: 'center',
          verticalAlign: 'top',
          x: 24,
          text: groupsLabel,
          style: {
            fontFamily: theme.font.style.light,
          },
        },
      }
    }, [
      chart.xThreshold,
      chart.yThreshold,
      groupsLabel,
      height,
      series,
      theme,
      width,
    ])

    return <Chart options={options} />
  },
)

const StyledMetaAnalysisVolcanoPlotOptions = styled(
  MetaAnalysisVolcanoPlotOptions,
)`
  position: absolute;
  top: 0;
  left: 0;
  z-index: 2;
`

const ExpandedMetaAnalysisVolcanoPlot = styled(MetaAnalysisVolcanoPlot).attrs({
  isExpanded: true,
})`
  width: 90vw;
  height: 90vh;
`
