import {
  Delete as DeleteIcon,
  CloudDownload as DownloadIcon,
  Edit as EditIcon,
} from '@material-ui/icons'
import React, { ReactNode, useCallback } from 'react'
import styled from 'styled-components'

import { LayoutItemCard } from 'components/graphs/LayoutItemCard'
import {
  LayoutItemCardHeader,
  LayoutItemCardHeaderAction,
} from 'components/graphs/LayoutItemCardHeader'

import { MetaAnalysisChart } from 'shared/api/meta-analysis.api'
import { useAppDispatch } from 'shared/store'
import { includeIf } from 'shared/utils/utils'

import {
  removeMetaAnalysisChart,
  renameMetaAnalysisChart,
} from './store/meta-analysis.history.slice'

type MetaAnalysisChartContainerProps = {
  actions?: LayoutItemCardHeaderAction[]
  menuActions?: LayoutItemCardHeaderAction[]
  children: ReactNode
  containerRef?: React.Ref<HTMLDivElement>
  chart: MetaAnalysisChart
  isExpanded?: boolean
  className?: string
  chartContainerStyle?: React.CSSProperties
  onChangeOptions: () => void
  onExpand?: () => void
  onCloseExpand?: () => void
  onDownload?: () => void
}

export const MetaAnalysisChartContainer = ({
  actions,
  menuActions,
  children,
  containerRef,
  chart,
  isExpanded,
  className,
  chartContainerStyle,
  onChangeOptions,
  onExpand,
  onCloseExpand,
  onDownload,
}: MetaAnalysisChartContainerProps): JSX.Element => {
  const dispatch = useAppDispatch()

  const handleRename = useCallback(
    (value: string) => {
      dispatch(
        renameMetaAnalysisChart({
          id: chart.id,
          name: value,
        }),
      )
    },
    [chart.id, dispatch],
  )

  const handleDelete = useCallback(() => {
    dispatch(removeMetaAnalysisChart(chart.id))
  }, [chart.id, dispatch])

  return (
    <MetaAnalysisChartContainerRoot
      $isExpanded={isExpanded}
      className={className}
    >
      <LayoutItemCardHeader
        title={chart.name}
        actions={actions}
        menuActions={
          isExpanded
            ? undefined
            : [
                ...(menuActions || []),
                {
                  icon: <EditIcon />,
                  tooltip: 'Change chart options',
                  onClick: onChangeOptions,
                },
                {
                  icon: <DeleteIcon />,
                  tooltip: 'Delete chart',
                  onClick: handleDelete,
                },
                ...includeIf(onDownload, [
                  {
                    icon: <DownloadIcon />,
                    tooltip: 'Download data',
                    onClick: onDownload!,
                  },
                ]),
              ]
        }
        isExpanded={isExpanded}
        onTitleChange={handleRename}
        onExpand={onExpand}
        onCloseExpand={onCloseExpand}
      />

      <ChartContainer ref={containerRef} style={chartContainerStyle}>
        {children}
      </ChartContainer>
    </MetaAnalysisChartContainerRoot>
  )
}

const MetaAnalysisChartContainerRoot = styled(LayoutItemCard)`
  position: relative;
  grid-template-rows: auto 1fr;
`

const ChartContainer = styled.div`
  width: 100%;
  height: 100%;
  overflow: hidden;
`
