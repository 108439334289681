import React, { useState } from 'react'

export const useScrollTop = (): {
  onScroll: (event: React.UIEvent<HTMLElement>) => void
  scrollTop: number
} => {
  const [scrollTop, setScrollTop] = useState(0)
  const onScroll = event => setScrollTop(event.target.scrollTop)
  return { scrollTop, onScroll }
}
