import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from '@material-ui/core'
import { groupBy, range } from 'lodash'
import { useMemo } from 'react'
import styled, { css } from 'styled-components'

import { STATISTIC_TYPE_LABELS } from 'shared/constants'
import { ChannelLabels, Lasso } from 'shared/models/AnalysisModels'

import { Cluster } from './store/selectors'

type AnalysisStatisticsTableProps = {
  statisticsItem: Analysis.StatisticsItem
  clusterById: Record<string, Cluster>
  channelDisplayNameById: ChannelLabels
  lassoById: Record<string, Lasso>
  gateById: Record<string, Graph.Gate>
}

export const AnalysisStatisticsTable: React.FC<
  AnalysisStatisticsTableProps
> = ({
  statisticsItem,
  channelDisplayNameById,
  lassoById,
  gateById,
  clusterById,
}) => {
  const availableStatisticTypes = useMemo(() => {
    const set = new Set<Analysis.ReportableStatistic['type']>()
    for (const reportable of statisticsItem.statistics.reportables) {
      for (const statistic of reportable.statistics) {
        set.add(statistic.type)
      }
    }
    return (
      Object.keys(
        STATISTIC_TYPE_LABELS,
      ) as Analysis.ReportableStatistic['type'][]
    ).filter(key => set.has(key))
  }, [statisticsItem.statistics.reportables])

  return (
    <Table>
      <TableHead>
        <TableRow>
          <StyledTableCell $isLastRow>Reportable</StyledTableCell>
          {availableStatisticTypes.map(statistic => (
            <StyledTableCell key={statistic} align="right" $isLastRow>
              {STATISTIC_TYPE_LABELS[statistic]}
            </StyledTableCell>
          ))}
        </TableRow>
      </TableHead>
      <TableBody>
        {statisticsItem.statistics.reportables.flatMap(reportable => {
          const groupedStatistics = groupBy(reportable.statistics, 'type')
          const numberOfRows = Math.max(
            ...Object.values(groupedStatistics).map(group => group.length),
          )

          return range(numberOfRows).map(rowIndex => {
            const id = 'id' in reportable ? reportable.id : reportable.ids[0]
            const key = `${id}-${rowIndex}`

            return (
              <TableRow key={key}>
                {rowIndex === 0 && (
                  <StyledTableCell rowSpan={numberOfRows} $isLastRow>
                    {reportable.label}
                  </StyledTableCell>
                )}

                {availableStatisticTypes.map(statisticType => {
                  const statisticGroup = groupedStatistics[statisticType]
                  const statistic = statisticGroup?.[rowIndex]

                  let parameterLabel = ''
                  if (
                    statistic?.parameter?.type === 'cluster' &&
                    'ids' in statistic.parameter
                  ) {
                    parameterLabel =
                      clusterById[statistic.parameter.ids[0]]?.label
                  } else if (statistic?.parameter?.type === 'lasso') {
                    parameterLabel = lassoById[statistic.parameter.id]?.name
                  } else if (statistic?.parameter?.type === 'gate') {
                    parameterLabel = gateById[statistic.parameter.id]?.name
                  } else if (statistic?.parameter?.type === 'channel') {
                    parameterLabel =
                      channelDisplayNameById[statistic.parameter.id]
                  }

                  if (!statistic) {
                    return (
                      <StyledTableCell
                        key={statisticType}
                        align="right"
                        $isLastRow={rowIndex === numberOfRows - 1}
                      >
                        NA
                      </StyledTableCell>
                    )
                  }

                  if (statistic.value === undefined) {
                    return (
                      <StyledTableCell
                        key={statisticType}
                        align="right"
                        $isLastRow={rowIndex === numberOfRows - 1}
                      >
                        ---
                      </StyledTableCell>
                    )
                  }

                  if (statisticType === 'count') {
                    return (
                      <StyledTableCell
                        key={statisticType}
                        align="right"
                        $isLastRow={rowIndex === numberOfRows - 1}
                      >
                        {statistic.value}
                      </StyledTableCell>
                    )
                  }

                  if (statisticType === 'percentage-of') {
                    return (
                      <StyledTableCell
                        key={statisticType}
                        align="right"
                        $isLastRow={rowIndex === numberOfRows - 1}
                      >
                        {`${statistic?.value?.toFixed(
                          2,
                        )}% of ${parameterLabel}`}
                      </StyledTableCell>
                    )
                  }

                  return (
                    <StyledTableCell
                      key={statisticType}
                      align="right"
                      $isLastRow={rowIndex === numberOfRows - 1}
                    >
                      {statistic?.type === 'count'
                        ? statistic.value
                        : statistic?.value?.toFixed(2) + ' ' + parameterLabel}
                    </StyledTableCell>
                  )
                })}
              </TableRow>
            )
          })
        })}
      </TableBody>
    </Table>
  )
}

const StyledTableCell = styled(TableCell)<{
  $isLastRow?: boolean
}>`
  border: 1px solid ${({ theme }) => theme.colors.greyscale[10]};
  ${({ $isLastRow }) =>
    $isLastRow &&
    css`
      border-bottom: 1px solid ${({ theme }) => theme.colors.greyscale[20]};
    `}
`
