import { Box, Grid } from '@material-ui/core'
import { Link } from 'react-router-dom'
import styled from 'styled-components'

import cell from 'assets/images/cell.png'
import { ReactComponent as Logo } from 'assets/images/logo.svg'

import { useGetMetricsQuery } from 'shared/api/utils.api'

type LoggedOutLayoutProps = {
  children: React.ReactNode
}

export const LoggedOutLayout: React.FC<LoggedOutLayoutProps> = ({
  children,
}) => {
  const metaflowVersion = useGetMetricsQuery().data?.metaflow_version

  return (
    <>
      <CellImageContainer>
        <CellImage src={cell} alt="Cell" />
      </CellImageContainer>
      <ContentContainer>
        <TitleContainer>
          <Link to="/">
            <StyledLogo />
          </Link>
          <IntroText>
            METAflow, a cutting-edge solution: from an automated analysis of
            flow cytometry data to a comprehensive interpretation and high
            quality results.
          </IntroText>
        </TitleContainer>
        {children}
        <SupportText>
          Registration and support: <b>contact@metaflow.bio</b>
        </SupportText>
        <FooterContainer>
          <p>METAflow by METAFORA</p>
          <p>{metaflowVersion}</p>
        </FooterContainer>
      </ContentContainer>
    </>
  )
}

const CellImageContainer = styled(Grid)`
  position: fixed;
  display: flex;
  align-items: center;
  height: 100vh;
  width: 33vw;
  background: ${props => props.theme.colors.cellBackground};
`

const CellImage = styled.img`
  max-width: 100%;
`

const ContentContainer = styled(Box)`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  max-width: 67vw;
  margin-left: 33vw;
  padding: ${props => props.theme.spacing(0, 8)};

  > * {
    max-width: 560px;
  }
`

const TitleContainer = styled(Box)`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: auto;
  margin-bottom: ${props => props.theme.spacing(4)}px;
`

const StyledLogo = styled(Logo)`
  width: 230px;
  height: 60px;
  margin-bottom: ${props => props.theme.spacing(4)}px;
`

const IntroText = styled.p`
  text-align: center;
  color: ${props => props.theme.colors.primaryDark[70]};
`

const SupportText = styled(IntroText)`
  margin-top: ${props => props.theme.spacing(4)}px;
`

const FooterContainer = styled.div`
  margin-top: auto;
  padding-bottom: ${props => props.theme.spacing(4)}px;
  text-align: center;
  font-size: ${props => props.theme.font.size.small}px;
`
