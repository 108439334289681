import { SeriesSunburstOptions } from 'highcharts'

import { HighlightedCluster } from 'pages/analysis/store/analysis.history.slice'
import { Cluster } from 'pages/analysis/store/selectors'

import { computeClusterColor } from 'shared/utils/clusters.utils'

export type ComputeSeriesProps = {
  activeClusters: Cluster[]
  globallyHiddenClusters: Set<string>
  highlightedCluster?: HighlightedCluster
  allowTraversingTree?: boolean
}

export class SunburstSeries {
  public async computeSeries({
    activeClusters,
    globallyHiddenClusters,
    highlightedCluster,
    allowTraversingTree,
  }: ComputeSeriesProps): Promise<SeriesSunburstOptions[]> {
    return [
      {
        animation: false,
        type: 'sunburst',
        name: '',
        data: activeClusters.map(cluster => {
          const color = computeClusterColor(
            cluster,
            globallyHiddenClusters,
            highlightedCluster,
          )
          return {
            ...cluster,
            color,
            value: cluster.stats.count,
            name: cluster.label,
          }
        }),
        allowTraversingTree,
        borderColor: 'white',
        borderWidth: 0.5,
        dataLabels: {
          enabled: false,
        },
        states: {
          hover: {
            enabled: false,
          },
        },
      },
    ]
  }
}
