import { Typography } from '@material-ui/core'
import { Formik, FormikConfig } from 'formik'
import { useCallback, useState } from 'react'
import { useHistory } from 'react-router'
import { useParams } from 'react-router-dom'
import styled from 'styled-components'
import { object, string } from 'yup'

import { ValidationLabel } from 'components/ validation-label/ValidationLabel'
import { Button } from 'components/Button'
import { LicenseAgreementConfirmation } from 'components/LicenseAgreementConfirmation'
import { LoadingMask } from 'components/LoadingMask'
import { LoggedOutLayout } from 'components/LoggedOutLayout'
import { Section } from 'components/Section'
import Input from 'components/forms/Input'

import {
  useAcceptInvitationMutation,
  useGetUserInvitationQuery,
} from 'shared/api/organization.api'
import { useServerFormValidation } from 'shared/hooks/useServerFormValidation'
import { OrganizationInvitation } from 'shared/models/OrganizationModels'
import { useAppDispatch } from 'shared/store'
import { showNotification } from 'shared/store/notification.slice'
import { handleError } from 'shared/utils/errorHandler'

type SignUpFormValues = {
  password: string
  passwordConfirmation: string
  firstName: string
  lastName: string
  job?: string
}

const initialValues: SignUpFormValues = {
  password: '',
  passwordConfirmation: '',
  firstName: '',
  lastName: '',
  job: undefined,
}

const SignUpFormSchema = object({
  password: string()
    .required()
    .min(8, 'Password must be at least 8 characters')
    .matches(/[a-z]/, 'Password must contain at least one lowercase letter')
    .matches(/[A-Z]/, 'Password must contain at least one uppercase letter')
    .matches(/[0-9]/, 'Password must contain at least one number')
    .matches(
      /[^a-zA-Z0-9]/,
      'Password must contain at least one special character',
    ),
  passwordConfirmation: string().test(
    'passwords-match',
    'Passwords must match',
    function (value, context) {
      return value === context.parent.password
    },
  ),
  firstName: string().required(),
  lastName: string().required(),
  job: string().nullable(),
})

export const SignUpMemberPage = (): JSX.Element => {
  const history = useHistory()
  const { token } = useParams<{ token: string }>()
  const dispatch = useAppDispatch()

  const { data, error, isLoading } = useGetUserInvitationQuery({ id: token })
  const [acceptInvitationRequest, acceptInvitationResult] =
    useAcceptInvitationMutation()
  const { globalError, getFieldRequestError, setSubmittedValues } =
    useServerFormValidation({
      error: acceptInvitationResult.error,
      fields: Object.keys(initialValues) as (keyof SignUpFormValues)[],
    })

  const [isLicenseChecked, setLicenseChecked] = useState(false)

  const viableInvitationStatuses: OrganizationInvitation['status'][] = [
    'acceptance pending',
    'expired',
  ]

  const handleSignUp: FormikConfig<SignUpFormValues>['onSubmit'] = useCallback(
    async (values, { setSubmitting }) => {
      try {
        const data = await acceptInvitationRequest({
          ...values,
          id: token,
        }).unwrap()

        if (data.is_active) {
          history.push('/log-in', { isSignUpSuccess: true })
        } else {
          history.push('/404')
          dispatch(
            showNotification({
              type: 'warning',
              description: `Your account was created successfully but it's inactive. Please, contact your organization's operator for more information`,
            }),
          )
        }
      } catch (error) {
        handleError(error)
      } finally {
        setSubmittedValues(values)
        setSubmitting(false)
      }
    },
    [history, acceptInvitationRequest, setSubmittedValues, token, dispatch],
  )

  return (
    <LoggedOutLayout>
      {(() => {
        if (isLoading) {
          return <LoadingMask />
        }

        if (error || !data || !viableInvitationStatuses.includes(data.status)) {
          return <div />
        }

        if (data.status === 'expired') {
          return (
            <SignUpPageRoot>
              <ExpiryMessageContent>
                <Typography variant="h5" align="center" color="error">
                  Invitation expired
                </Typography>
                <Typography variant="body2" align="center">
                  This invitation has expired. Please contact your organization
                  administrator
                </Typography>
                <Button onClick={() => history.push('/log-in')}>
                  Go back to log-in
                </Button>
              </ExpiryMessageContent>
            </SignUpPageRoot>
          )
        }

        return (
          <SignUpPageRoot>
            <Formik<SignUpFormValues>
              initialValues={initialValues}
              validationSchema={SignUpFormSchema}
              onSubmit={handleSignUp}
            >
              {({
                values,
                touched,
                errors,
                isSubmitting,
                isValid,
                handleChange,
                handleBlur,
                handleSubmit,
              }) => (
                <form onSubmit={handleSubmit}>
                  <Section title="Personal details">
                    <Input
                      name="email"
                      type="email"
                      label="Email"
                      value={data.email}
                      disabled
                    />
                    <Input
                      name="password"
                      type="password"
                      label="Password*"
                      value={values.password}
                      error={
                        touched.password
                          ? getFieldRequestError('password', values.password) ||
                            errors.password
                          : undefined
                      }
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                    <Input
                      name="passwordConfirmation"
                      type="password"
                      label="Retype password*"
                      value={values.passwordConfirmation}
                      error={
                        touched.passwordConfirmation
                          ? errors.passwordConfirmation
                          : undefined
                      }
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                    <Input
                      name="firstName"
                      label="First name*"
                      value={values.firstName}
                      error={
                        touched.firstName
                          ? getFieldRequestError(
                              'firstName',
                              values.firstName,
                            ) || errors.firstName
                          : undefined
                      }
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                    <Input
                      name="lastName"
                      label="Last name*"
                      value={values.lastName}
                      error={
                        touched.lastName
                          ? getFieldRequestError('lastName', values.lastName) ||
                            errors.lastName
                          : undefined
                      }
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                    <Input
                      name="job"
                      label="Job"
                      value={values.job ?? ''}
                      error={
                        touched.job
                          ? getFieldRequestError('job', values.job) ||
                            errors.job
                          : undefined
                      }
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                  </Section>
                  <LicenseAgreementConfirmation
                    isChecked={isLicenseChecked}
                    onChange={() => setLicenseChecked(!isLicenseChecked)}
                  />
                  {acceptInvitationResult.error && (
                    <ValidationLabel>{globalError}</ValidationLabel>
                  )}
                  <Buttons>
                    <Button
                      type="submit"
                      disabled={isSubmitting || !isValid || !isLicenseChecked}
                    >
                      Submit
                    </Button>
                    <Button
                      type="button"
                      grey
                      onClick={() => history.push('/log-in')}
                    >
                      Back
                    </Button>
                  </Buttons>
                </form>
              )}
            </Formik>
          </SignUpPageRoot>
        )
      })()}
    </LoggedOutLayout>
  )
}

const SignUpPageRoot = styled.div`
  background: ${props => props.theme.colors.white};
  padding: ${props => props.theme.spacing(4, 8)};
  border-radius: ${props => props.theme.radius[2]}px;
  box-shadow: ${props => props.theme.shadow[1]};
  width: 100%;
`

const Buttons = styled.div`
  display: flex;
  justify-content: center;
  gap: ${props => props.theme.spacing(2)}px;
  margin-top: ${props => props.theme.spacing(4)}px;
`

const ExpiryMessageContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: ${props => props.theme.spacing(2)}px;
`
