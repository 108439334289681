import {
  createAction,
  createSlice,
  isAnyOf,
  PayloadAction,
  Reducer,
  UnknownAction,
} from '@reduxjs/toolkit'
import { FetchBaseQueryError } from '@reduxjs/toolkit/dist/query'
import undoable, { StateWithHistory } from 'redux-undo'

import { Layout, LayoutElement } from 'components/chart-grid'

import {
  analysisApi,
  SelfOrganazingHeatMapResult,
} from 'shared/api/analysis.api'
import { ExperimentDetails } from 'shared/api/experiments.api'
import { CompensatedFile, FcsFile } from 'shared/api/files.api'
import { PipelineDetails } from 'shared/api/pipelines.api'
import { Project } from 'shared/api/projects.api'
import { signaturesApi } from 'shared/api/signatures.api'
import { BrickDetails, WorkflowDetails } from 'shared/api/workflows.api'
import { SliceStatus } from 'shared/store'

import {
  historySlice,
  HistoryState,
  INITIAL_HISTORY_STATE,
} from './analysis.history.slice'

export * from './analysis.history.slice'

export type AnalysisSliceState = {
  status: SliceStatus
  error?: FetchBaseQueryError
  isAnalysisBeingSaved?: boolean
  isAnalysisProtected?: boolean
  brick?: BrickDetails
  workflow?: WorkflowDetails | PipelineDetails | ExperimentDetails
  compensatedFile?: CompensatedFile
  fcsFile?: FcsFile
  project?: Project
  scales?: Analysis.Scales
  history: StateWithHistory<HistoryState>
  shouldPreventStateUpdate?: boolean
  selfOrganizingHeatMapQuery?: SelfOrganizingHeatMapQuery
}

export type SelfOrganizingHeatMapQuery = {
  result: SelfOrganazingHeatMapResult
  parameters: {
    selectedChannels: string[]
    selectedClusterIds: string[]
  }
}

const initialState: AnalysisSliceState = {
  status: 'idle',
  history: {
    past: [],
    present: INITIAL_HISTORY_STATE,
    future: [],
  },
}

export const analysisSlice = createSlice({
  name: 'analysis',
  initialState,
  reducers: {
    updateSpecificLayout: (
      state,
      {
        payload: { layoutKey, layout },
      }: PayloadAction<{
        layoutKey: keyof Layout
        layout: LayoutElement
      }>,
    ) => {
      if (state.history.present.analysis) {
        state.history.present.analysis.layout[layoutKey] = layout
      }
    },
    unloadAnalysis: () => initialState,
    preventStateUpdate: state => {
      state.shouldPreventStateUpdate = true
    },
    postDependentChartsMiddleware: (
      state,
      action: PayloadAction<{
        modifiedCharts: Record<string, { hiddenClusterIds: string[] }>
      }>,
    ) => {
      for (const chartId in action.payload.modifiedCharts) {
        const chart = state.history.present.analysis?.graphs.find(
          graph => graph.id === chartId,
        )
        if (!chart) {
          throw new Error('Chart not found')
        }
        chart.hidden_cluster_ids =
          action.payload.modifiedCharts[chartId].hiddenClusterIds
      }
    },
    selfOrganizingHeatMapDataFetched: (
      state,
      action: PayloadAction<SelfOrganizingHeatMapQuery>,
    ) => {
      state.selfOrganizingHeatMapQuery = action.payload
    },
  },
  extraReducers: builder => {
    builder.addMatcher(
      analysisApi.endpoints.getAnalysis.matchPending,
      state => {
        if (state.shouldPreventStateUpdate) {
          return
        }
        state.status = 'loading'
        state.error = undefined
      },
    )
    builder.addMatcher(
      analysisApi.endpoints.getAnalysis.matchFulfilled,
      (state, { payload }) => {
        if (state.shouldPreventStateUpdate) {
          state.shouldPreventStateUpdate = false
        }
        state.status = 'success'
        state.error = undefined
        state.history = {
          past: [],
          present: {
            isAnalysisSaved: true,
            analysis: payload.analysis,
            ui: { clusterDotSizes: {} },
            logs: [],
          },
          future: [],
        }
        state.brick = payload.brick
        state.workflow = payload.workflow
        state.compensatedFile = payload.compensatedFile
        state.fcsFile = payload.fcsFile
        state.project = payload.project
        state.scales = payload.scales
        state.isAnalysisProtected = payload.analysis.protection_mode
      },
    )
    builder.addMatcher(
      analysisApi.endpoints.getAnalysis.matchRejected,
      (state, { payload }) => {
        if (state.shouldPreventStateUpdate) {
          state.shouldPreventStateUpdate = false
        }
        state.status = 'error'
        state.error = payload
        state.history = initialState.history
        state.scales = undefined
      },
    )
    builder.addMatcher(
      analysisApi.endpoints.saveAnalysis.matchPending,
      state => {
        state.isAnalysisBeingSaved = true
      },
    )
    builder.addMatcher(
      analysisApi.endpoints.saveAnalysis.matchFulfilled,
      (state, { payload }) => {
        state.history.present.isAnalysisSaved = true
        state.history.present.analysis = payload.analysis
        state.scales = payload.scales
        state.isAnalysisBeingSaved = false
      },
    )
    builder.addMatcher(
      analysisApi.endpoints.saveAnalysis.matchRejected,
      state => {
        state.isAnalysisBeingSaved = false
      },
    )
    builder.addMatcher(
      analysisApi.endpoints.toggleProtectionMode.matchFulfilled,
      (state, { payload }) => {
        state.isAnalysisProtected = payload.protection_mode
      },
    )
    builder.addMatcher(
      isAnyOf(
        signaturesApi.endpoints.createSignature.matchFulfilled,
        signaturesApi.endpoints.cancelSignature.matchFulfilled,
      ),
      (state, { payload }) => {
        state.history.present.analysis = payload.analysis
        state.history.present.isAnalysisSaved = true
      },
    )
  },
})

export const {
  updateSpecificLayout,
  unloadAnalysis,
  postDependentChartsMiddleware,
  selfOrganizingHeatMapDataFetched,
} = analysisSlice.actions

export const analysisSliceReducer: Reducer<
  AnalysisSliceState,
  UnknownAction
> = (state = initialState, action: UnknownAction): AnalysisSliceState => {
  if (action.type.startsWith('analysis/modify/')) {
    return {
      ...state,
      history: historyReducer(state.history, {
        ...action,
        wrappedAnalysisSliceState: { analysisPage: { analysis: state } },
      }),
    }
  }

  return analysisSlice.reducer(state, action)
}

export const UNDO_ACTION_TYPE = 'analysis/modify/undo'
export const REDO_ACTION_TYPE = 'analysis/modify/redo'

export const undo = createAction(UNDO_ACTION_TYPE)
export const redo = createAction(REDO_ACTION_TYPE)

const historyReducer = undoable(historySlice.reducer, {
  undoType: UNDO_ACTION_TYPE,
  redoType: REDO_ACTION_TYPE,
})
