import React, { createContext, FC, ReactNode } from 'react'

type TableProps = Readonly<{
  layout: ReadonlyArray<string>
  className?: string
  children: ReactNode
}>

export const TableContext = createContext<TableProps['layout'] | undefined>(
  undefined,
)

export const Table: FC<TableProps> = ({ layout, className, children }) => {
  return (
    <TableContext.Provider value={layout}>
      <div className={className}>{children}</div>
    </TableContext.Provider>
  )
}
