import OpenSansBold from './OpenSans-Bold.woff2'
import OpenSansLight from './OpenSans-Light.woff2'
import OpenSansRegular from './OpenSans-Regular.woff2'

type FontFace = {
  fontFamily: string
  fontStyle: string
  fontWeight: number
  src: string
}

export const fonts = {
  openSansRegular: createFontFace('OpenSans-Regular', OpenSansRegular),
  openSansLight: createFontFace('OpenSans-Light', OpenSansLight),
  openSansBold: createFontFace('OpenSans-Bold', OpenSansBold),
}

function createFontFace(fontName: string, font: string): FontFace {
  return {
    fontFamily: fontName,
    fontStyle: 'normal',
    fontWeight: 400,
    src: `
    local('${fontName}'),
    url(${font}) format('woff2')
  `,
  }
}
