import { CircularProgress } from '@material-ui/core'
import { Security as ShieldIcon } from '@material-ui/icons'
import styled from 'styled-components'

import { Button } from 'components/Button'

import { useToggleProtectionModeMutation } from 'shared/api/analysis.api'
import { useEventCallback } from 'shared/hooks/useEventCallback'
import { useAppSelector } from 'shared/store'

import { selectAnalysisId, selectIsAnalysisProtected } from './store/selectors'

export const ProtectionModeButton = (): JSX.Element => {
  const [triggerToggleProtectionMode, toggleProtectionModeResult] =
    useToggleProtectionModeMutation()

  const analysisId = useAppSelector(selectAnalysisId)
  const isAnalysisProtected = useAppSelector(selectIsAnalysisProtected)

  const handleToggleProtectionMode = useEventCallback(() => {
    triggerToggleProtectionMode({
      analysisId,
      enable: !isAnalysisProtected,
    })
  })
  return (
    <ProtectionModeButtonRoot
      onClick={handleToggleProtectionMode}
      $isActive={isAnalysisProtected}
    >
      {toggleProtectionModeResult.isLoading ? (
        <StyledCircularProgress size={16} />
      ) : (
        <>
          <StyledShieldIcon />{' '}
          <span>
            {isAnalysisProtected
              ? 'Template protected'
              : 'Template unprotected'}
          </span>
        </>
      )}
    </ProtectionModeButtonRoot>
  )
}

const ProtectionModeButtonRoot = styled(Button)<{ $isActive: boolean }>`
  width: 120px;
  height: 24px;
  padding: ${props => props.theme.spacing(2)}px
    ${props => props.theme.spacing(1)}px;
  font-size: ${props => props.theme.font.size.smallest}px;
  line-height: ${props => props.theme.font.size.small}px;
  background: ${props =>
    props.$isActive
      ? props.theme.colors.primary[100]
      : props.theme.colors.primary[50]};
`

const StyledShieldIcon = styled(ShieldIcon)`
  height: 20px;
`

const StyledCircularProgress = styled(CircularProgress)`
  color: ${props => props.theme.colors.white};
`
