import { createSelector } from '@reduxjs/toolkit'
import { groupBy, mapValues, uniq } from 'lodash'

import { Experiment } from 'shared/api/experiments.api'
import { CompensatedFile, FcsFile } from 'shared/api/files.api'
import {
  MetaAnalysis,
  MetaAnalysisFile,
  MetaAnalysisGlobalVizFile,
  MetaAnalysisVolcanoPlotFile,
} from 'shared/api/meta-analysis.api'
import { Pipeline } from 'shared/api/pipelines.api'
import { Project } from 'shared/api/projects.api'
import { Workflow } from 'shared/api/workflows.api'
import { ADD_CHART_PLACEHOLDER_ID } from 'shared/constants'
import { RootState, SliceStatus } from 'shared/store'
import { createDeepEqualSelector } from 'shared/store/createDeepEqualSelector'
import { convertWorkflowModeToWorkflowEntity } from 'shared/utils/api'
import { groupByUnique } from 'shared/utils/collection.utils'

import { MetaAnalysisSliceState } from '../meta-analysis.slice'

export const selectMetaAnalysisOrUndefined = (state: {
  metaAnalysisPage: { metaAnalysis: MetaAnalysisSliceState }
}): MetaAnalysis | undefined =>
  state.metaAnalysisPage.metaAnalysis.history.present.metaAnalysis

export const selectMetaAnalysis = (state: {
  metaAnalysisPage: { metaAnalysis: MetaAnalysisSliceState }
}): MetaAnalysis => {
  const metaAnalysis = selectMetaAnalysisOrUndefined(state)
  if (!metaAnalysis) {
    throw new Error('Meta-analysis is undefined')
  }
  return metaAnalysis
}

export const selectMetaAnalysisCompensatedFileById = (state: {
  metaAnalysisPage: { metaAnalysis: MetaAnalysisSliceState }
}): Record<string, CompensatedFile> => {
  const compensatedFileById =
    state.metaAnalysisPage.metaAnalysis.history.present.compensatedFileById
  if (!compensatedFileById) {
    throw new Error('Compensated file is undefined')
  }
  return compensatedFileById
}

export const selectMetaAnalysisFcsFileById = (state: {
  metaAnalysisPage: { metaAnalysis: MetaAnalysisSliceState }
}): Record<string, FcsFile> => {
  const fcsFileById =
    state.metaAnalysisPage.metaAnalysis.history.present.fcsFileById
  if (!fcsFileById) {
    throw new Error('FCS file is undefined')
  }
  return fcsFileById
}

export const selectMetaAnalysisWorkflow = (state: {
  metaAnalysisPage: { metaAnalysis: MetaAnalysisSliceState }
}): Workflow | Experiment | Pipeline => {
  const workflow = state.metaAnalysisPage.metaAnalysis.history.present.workflow
  if (!workflow) {
    throw new Error('Workflow is undefined')
  }
  return workflow
}

export const selectMetaAnalysisProject = (state: {
  metaAnalysisPage: { metaAnalysis: MetaAnalysisSliceState }
}): Project => {
  const project = state.metaAnalysisPage.metaAnalysis.history.present.project
  if (!project) {
    throw new Error('Project is undefined')
  }
  return project
}

export const selectMetaAnalysisFile = (state: RootState): MetaAnalysisFile => {
  const file =
    state.metaAnalysisPage.metaAnalysis.history.present.metaAnalysisFile
  if (!file) {
    throw new Error('Meta-analysis file is undefined')
  }
  return file
}

export const selectMetaAnalysisGlobalVizFile = (
  state: RootState,
): MetaAnalysisGlobalVizFile | undefined =>
  state.metaAnalysisPage.metaAnalysis.history.present.metaAnalysisGlobalVizFile

export const selectMetaAnalysisVolcanoPlotFile = (
  state: RootState,
): MetaAnalysisVolcanoPlotFile => {
  const file =
    state.metaAnalysisPage.metaAnalysis.history.present
      .metaAnalysisVolcanoPlotFile
  if (!file) {
    throw new Error('Meta-analysis Volcano Plot file is undefined')
  }
  return file
}

export const selectMetaAnalysisId = (state: RootState): string | undefined => {
  return state.metaAnalysisPage.metaAnalysis.history.present.metaAnalysis?.id
}

export const selectMetaAnalysisStoreName = createSelector(
  selectMetaAnalysisId,
  metaAnalysisId => {
    return metaAnalysisId ? getMetaAnalysisStoreName(metaAnalysisId) : undefined
  },
)

export const getMetaAnalysisStoreName = (metaAnalysisId: string): string =>
  metaAnalysisId.replace(/-/g, '_')

export const selectMetaAnalysisLoadingStatus = (
  state: RootState,
): SliceStatus => state.metaAnalysisPage.metaAnalysis.status

export const selectMetaAnalysisCanUndo = (state: RootState): boolean =>
  state.metaAnalysisPage.metaAnalysis.history.past.length > 0

export const selectMetaAnalysisCanRedo = (state: RootState): boolean =>
  state.metaAnalysisPage.metaAnalysis.history.future.length > 0

export const selectIsMetaAnalysisSaved = (state: RootState): boolean =>
  state.metaAnalysisPage.metaAnalysis.history.present.isMetaAnalysisSaved

export const selectIsMetaAnalysisBeingSaved = (state: RootState): boolean =>
  state.metaAnalysisPage.metaAnalysis.history.present.isMetaAnalysisBeingSaved

export const selectIsMetaAnalysisStale = createSelector(
  selectMetaAnalysis,
  metaAnalysis => metaAnalysis.is_stale,
)

export const selectMetaAnalysisName = createSelector(
  selectMetaAnalysis,
  metaAnalysis => metaAnalysis.name,
)

export const selectMetaAnalysisProjectName = createSelector(
  selectMetaAnalysisProject,
  project => project.name,
)

export const selectMetaAnalysisWorkflowName = createSelector(
  selectMetaAnalysisWorkflow,
  workflow => workflow.name,
)

export const selectMetaAnalysisCreationDate = createSelector(
  selectMetaAnalysis,
  metaAnalysis => metaAnalysis.created_at,
)

export const selectMetaAnalysisLayout = createSelector(
  selectMetaAnalysis,
  metaAnalysis => metaAnalysis.layout,
)

export const selectMetaAnalysisColors = createSelector(
  selectMetaAnalysis,
  metaAnalysis => metaAnalysis.colors,
)

export const selectMetaAnalysisCharts = createDeepEqualSelector(
  (state: RootState) => selectMetaAnalysis(state).graphs,
  x => x,
)

export const selectMetaAnalysisFcsFileIds = createSelector(
  selectMetaAnalysisFcsFileById,
  fcsFileById => Object.values(fcsFileById).map(file => file.id),
)

export const selectMetaAnalysisClusters = createSelector(
  selectMetaAnalysisFile,
  metaAnalysisFile =>
    Object.keys(Object.values(metaAnalysisFile.stat_files)[0]),
)

export const selectMetaAnalysisClustersByFcsFileId = createSelector(
  selectMetaAnalysisFile,
  metaAnalysisFile =>
    mapValues(metaAnalysisFile.stat_files, clusters => Object.keys(clusters)),
)

export const selectMetaAnalysisChannels = createSelector(
  selectMetaAnalysisFile,
  metaAnalysisFile => {
    return metaAnalysisFile.channels
  },
)

export const selectNextLayoutPositions = createSelector(
  selectMetaAnalysisLayout,
  layout => {
    const layoutPositions = {
      single: { x: 0, y: 0 },
      primary: { x: 0, y: 0 },
      secondary: { x: 0, y: 0 },
    }
    for (const layoutKey in layoutPositions) {
      if (layout[layoutKey][ADD_CHART_PLACEHOLDER_ID]) {
        layoutPositions[layoutKey] = {
          x: layout[layoutKey][ADD_CHART_PLACEHOLDER_ID].x,
          y: layout[layoutKey][ADD_CHART_PLACEHOLDER_ID].y,
        }
      }
    }
    return layoutPositions
  },
)

export const selectMetaAnalysisGroups = createSelector(
  selectMetaAnalysis,
  metaAnalysis => metaAnalysis.groups,
)

export const selectMetaAnalysisGroupNames = createSelector(
  selectMetaAnalysisGroups,
  groups => uniq(Object.values(groups)),
)

export const selectMetaAnalysisFcsFiles = createSelector(
  selectMetaAnalysisFcsFileById,
  fcsFileById => {
    return Object.values(fcsFileById)
  },
)

export const selectMetaAnalysisFcsFileNameById = createSelector(
  selectMetaAnalysisFcsFiles,
  fcsFiles =>
    mapValues(
      groupByUnique(fcsFiles, file => file.id),
      file => file.file_name,
    ),
)

const selectMetaAnalysisAnalyses = createSelector(
  selectMetaAnalysis,
  metaAnalysis => metaAnalysis.analyses,
)

export const selectAnalysesByFcsFileId = createSelector(
  selectMetaAnalysisAnalyses,
  selectMetaAnalysisCompensatedFileById,
  selectMetaAnalysisFcsFileById,
  (analyses, compensatedFileById) =>
    groupBy(
      analyses,
      analysis => compensatedFileById[analysis.compensated_file].fcs_file,
    ),
)

export const selectMetaAnalysisDimensionalityReductionMethod = createSelector(
  selectMetaAnalysis,
  metaAnalysis => metaAnalysis.compute_global_viz,
)

export const selectMetaAnalysisWorkflowEntity = createSelector(
  selectMetaAnalysis,
  metaAnalysis => {
    return convertWorkflowModeToWorkflowEntity(metaAnalysis.workflow_mode)
  },
)
