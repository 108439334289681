import { CUMULATIVE_FLUORESCENCE_CHANNEL } from 'shared/constants'
import { Graph } from 'shared/models/Graphs'
import { RootState } from 'shared/store'

import { Theme } from 'Theme'

import { ANALYSIS_CHART_HEIGHT, ANALYSIS_CHART_WIDTH } from '..'
import { renderHistogramChart } from './renderHistogramChart'
import { renderMetaCleanChart } from './renderMetaCleanChart'
import { renderScatterplotChart } from './renderScatterplotChart'

type RenderAnalysisChartProps = {
  state: RootState
  chart: Graph
  shouldShowAxisLabels?: boolean
  theme: Theme
}

export const renderAnalysisChart = ({
  state,
  chart,
  shouldShowAxisLabels,
  theme,
}: // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
RenderAnalysisChartProps) => {
  const width = ANALYSIS_CHART_WIDTH
  const height = ANALYSIS_CHART_HEIGHT

  if (chart.chart_type === 'Histogram') {
    return renderHistogramChart({
      state,
      chart,
      width,
      height,
      shouldShowAxisLabels,
    })
  }

  if (chart.chart_type === 'Dot plot') {
    if (chart.y_axis === CUMULATIVE_FLUORESCENCE_CHANNEL) {
      return renderMetaCleanChart({
        state,
        chart,
        width,
        height,
        shouldShowAxisLabels,
      })
    } else {
      return renderScatterplotChart({
        state,
        chart,
        width,
        height,
        shouldShowAxisLabels,
        theme,
      })
    }
  }

  throw new Error(`Unknown chart type: ${chart.chart_type}`)
}
