import { throttle } from 'lodash'
import { useCallback, useMemo, useState } from 'react'
import { useEvent, useTimeoutFn } from 'react-use'

import { useLazyGetSessionQuery } from 'shared/api/auth.api'
import { TIME } from 'shared/constants'
import { useAppDispatch, useAppSelector } from 'shared/store'
import {
  authSlice,
  logout,
  selectIsAuthenticated,
  selectSessionExpiryTime,
  selectUserInactiveSince,
  selectSessionExpiresAt,
} from 'shared/store/auth.slice'

import { useObserve } from './useObserve'

export const useSessionTimer = (): boolean => {
  const dispatch = useAppDispatch()
  const [triggerGetSession] = useLazyGetSessionQuery()
  const [shouldShowLogoutWarning, setShouldShowLogoutWarning] = useState(false)

  const isAuthenticated = useAppSelector(selectIsAuthenticated)
  const sessionExpiresAt = useAppSelector(selectSessionExpiresAt)
  const userInactiveSince = useAppSelector(selectUserInactiveSince)
  const sessionExpiryTime = useAppSelector(selectSessionExpiryTime)

  const expiryTimeMinusOneMinute = useMemo(
    () => sessionExpiryTime - TIME.MINUTE,
    [sessionExpiryTime],
  )

  const handleExtendSession = useCallback(() => {
    if (!isAuthenticated || !sessionExpiresAt || !userInactiveSince) {
      return
    }
    const now = Date.now()
    const userInactiveFor = now - userInactiveSince
    const sessionExpiresIn = sessionExpiresAt - now

    // extend session if it is about to expire but user was active on front-end since last extended
    // values checked against are increased by 1 second to account for timeout reset execution time
    if (
      sessionExpiresIn < TIME.MINUTE + TIME.SECOND &&
      userInactiveFor < expiryTimeMinusOneMinute + TIME.SECOND
    ) {
      triggerGetSession()
    }
  }, [
    isAuthenticated,
    sessionExpiresAt,
    userInactiveSince,
    expiryTimeMinusOneMinute,
    triggerGetSession,
  ])

  const [, cancelExtendSessionTimeout, resetExtendSessionTimeout] =
    useTimeoutFn(handleExtendSession, expiryTimeMinusOneMinute)

  const [, cancelWarningTimeout, resetWarningTimeout] = useTimeoutFn(
    () => setShouldShowLogoutWarning(true),
    expiryTimeMinusOneMinute,
  )

  const [, cancelLogoutTimeout, resetLogoutTimeout] = useTimeoutFn(
    () => dispatch(logout()),
    sessionExpiryTime,
  )

  const handleMouseMove = useMemo(
    () =>
      throttle(
        () => {
          if (!isAuthenticated) {
            return
          }
          dispatch(authSlice.actions.setUserInactiveSince(Date.now()))
          resetWarningTimeout()
          resetLogoutTimeout()
          setShouldShowLogoutWarning(false)
        },
        TIME.SECOND,
        { trailing: false },
      ),
    [dispatch, isAuthenticated, resetLogoutTimeout, resetWarningTimeout],
  )

  useObserve(sessionExpiresAt, () => {
    resetExtendSessionTimeout()
  })

  useEvent('mousemove', handleMouseMove)

  useObserve(isAuthenticated, isAuthenticated => {
    if (shouldShowLogoutWarning) {
      setShouldShowLogoutWarning(false)
    }
    if (isAuthenticated) {
      resetWarningTimeout()
      resetLogoutTimeout()
    } else {
      cancelWarningTimeout()
      cancelLogoutTimeout()
      cancelExtendSessionTimeout()
    }
  })

  return shouldShowLogoutWarning
}
