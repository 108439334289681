import { useMemo } from 'react'
import styled from 'styled-components'

import { useAppSelector } from 'shared/store'
import { findAncestors } from 'shared/utils/clusters.utils'

import { LassoTag } from './LassoTag'
import { Cluster, selectClusterById } from './store/selectors'

export type ClusterTooltipContentProps = {
  cluster: Pick<Cluster, 'id' | 'label' | 'color' | 'lassoIds'> &
    Partial<
      Pick<
        Cluster,
        'isRoot' | 'parent' | 'parent_rate' | 'stats' | 'positionDeterminants'
      >
    >
  lassos: Record<string, { id: string; name: string }>
}

export const ClusterTooltipContent = ({
  cluster,
  lassos,
}: ClusterTooltipContentProps): JSX.Element => {
  const clusterById = useAppSelector(selectClusterById)

  const parentPercent = cluster.isRoot
    ? undefined
    : cluster.parent_rate?.toFixed(2)

  const hierarchy = useMemo(() => {
    if (!cluster.parent) {
      return undefined
    }
    const ancestors = findAncestors(cluster.id, clusterById, true)
      .map(ancestor => clusterById[ancestor].label)
      .reverse()

    const PATH_ITEMS_LIMIT = 20

    if (ancestors.length <= PATH_ITEMS_LIMIT) {
      return ancestors.join(' / ')
    }

    const ancestorsBegin = ancestors.slice(0, PATH_ITEMS_LIMIT / 2)
    const ancestorsEnd = ancestors.slice(
      -(PATH_ITEMS_LIMIT / 2),
      ancestors.length,
    )
    return ancestorsBegin.join(' / ') + ' / ... / ' + ancestorsEnd.join(' / ')
  }, [cluster.id, cluster.parent, clusterById])

  return (
    <Content>
      <Title>
        <Name title={cluster.label}>{cluster.label}</Name>
        {<Color color={cluster.color} />}
      </Title>
      {parentPercent !== undefined && (
        <Info>{`${parentPercent}% of parent`}</Info>
      )}
      {cluster.stats && <Info>{`${cluster.stats.count} events`}</Info>}
      {hierarchy && <Info>{hierarchy}</Info>}
      {cluster.lassoIds.length > 0 && (
        <Lasso>
          <span>Part of lasso:</span>
          <LassoTags>
            {cluster.lassoIds.map(lassoId => (
              <LassoTag key={lassoId} name={lassos[lassoId].name} />
            ))}
          </LassoTags>
        </Lasso>
      )}
      {cluster.positionDeterminants && (
        <Determinants>
          <div>Position determinants:</div>
          <ul>
            <li>F1: {cluster.positionDeterminants[0]}</li>
            <li>F2: {cluster.positionDeterminants[1]}</li>
            <li>F3: {cluster.positionDeterminants[2]}</li>
          </ul>
        </Determinants>
      )}
    </Content>
  )
}

const Content = styled.div`
  background-color: white;
  user-select: none;
  padding: 16px;
  display: flex;
  flex-direction: column;
  width: 200px;
`

const Title = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-family: ${props => props.theme.font.style.bold};
  font-size: ${props => props.theme.font.size.small}px;
  margin-bottom: 5px;
`

const Name = styled.p`
  max-width: 80%;
  word-wrap: break-word;
`

const Color = styled.div<{ color?: string }>`
  border-radius: 50%;
  width: 24px;
  height: 24px;
  background-color: ${props => props.color};
  ${props =>
    props.color === 'white' &&
    `outline: 1px solid ${props.theme.colors.greyscale[20]}`}
`

const Info = styled.div`
  margin-bottom: 7px;
  display: flex;
  align-items: center;
  font-size: ${props => props.theme.font.size.smallest}px;
  color: ${props => props.theme.colors.primaryDark[70]};
  &:last-of-type {
    margin-bottom: 0;
  }
`

const Determinants = styled.div`
  color: ${props => props.theme.colors.primaryDark[70]};
  font-size: ${props => props.theme.font.size.smallest}px;
  & ul {
    padding-left: 16px;
    margin: 0;
  }
`

const Lasso = styled.div`
  color: ${props => props.theme.colors.primaryDark[70]};
  font-size: ${props => props.theme.font.size.smallest}px;
  margin: 10px 0;
`

const LassoTags = styled.div`
  margin-top: 5px;
  display: flex;
  gap: 4px;
  flex-wrap: wrap;
`
