import { useFloating } from '@floating-ui/react'
import { Switch } from '@material-ui/core'
import { List as ListIcon, Replay as ReplayIcon } from '@material-ui/icons'
import _ from 'lodash'
import { FC, useCallback, useMemo, useState } from 'react'
import styled from 'styled-components'

import { Icon, IconNames } from 'assets/images/icons/Icon'

import { DefaultErrorBoundary } from 'components/DefaultErrorBoundary'
import { LoadingMask } from 'components/LoadingMask'
import { Modal } from 'components/Modal'
import { Pan } from 'components/Pan'
import { Slider, SliderChangeValue } from 'components/Slider'
import { PAN_BOTTOM_BAR_HEIGHT } from 'components/constants'
import { LayoutItemCard } from 'components/graphs/LayoutItemCard'
import { LayoutItemCardHeader } from 'components/graphs/LayoutItemCardHeader'
import { NetworkGraph } from 'components/graphs/NetworkGraph'
import { PieChart } from 'components/graphs/PieChart'
import { Sunburst } from 'components/graphs/Sunburst'
import {
  DEFAULT_USE_FLOATING_PROPS,
  setFloatingTooltipReferencePoint,
} from 'components/tooltip'
import { TooltipContainer } from 'components/tooltip/TooltipContainer'

import {
  ANALYSIS_HIERARCHY_NETWORK_ID,
  ANALYSIS_HIERARCHY_PIE_CHART_ID,
  ANALYSIS_HIERARCHY_SUNBURST_ID,
  EVENT_PERCENTAGE_LIMIT,
} from 'shared/constants'
import { useEventCallback } from 'shared/hooks/useEventCallback'
import { Graph } from 'shared/models/Graphs'
import { useAppDispatch, useAppSelector } from 'shared/store'
import { includeIf, useSize } from 'shared/utils/utils'

import { theme } from 'Theme'

import { ClusterTooltipContent } from './ClusterTooltipContent'
import {
  addChart as addChartActionCreator,
  resetAnalysisToAutofocus,
  setDepth,
  toggleClusterActive,
} from './store/analysis.slice'
import {
  removeGraphicalElementFromSelection,
  selectAnalysisSelectedGraphicalElementIds,
  setClusterListConnectedChartId,
  toggleAnalysisGraphicalElementSelection,
} from './store/selected-graphical-elements.slice'
import {
  Cluster,
  selectActiveClusters,
  selectActiveLeavesCount,
  selectAnalysisAccessMode,
  selectAnalysisLassos,
  selectAnalysisLoadingStatus,
  selectClusterListLinkedChartId,
  selectClusters,
  selectCurrentDepth,
  selectGloballyHiddenClusters,
  selectMaxAvailableDepth,
} from './store/selectors'

enum HierarchyCharts {
  Sunburst = 'sunburst',
  PieChart = 'pieChart',
  NetworkChart = 'networkChart',
}

type AnalysisHierarchyProps = {
  isExpanded?: boolean
  className?: string
  onCloseExpand?: () => void
}

export const AnalysisHierarchy: FC<AnalysisHierarchyProps> = ({
  isExpanded,
  className,
  onCloseExpand,
}) => {
  const dispatch = useAppDispatch()
  const analysisLoadingStatus = useAppSelector(selectAnalysisLoadingStatus)
  const depth = useAppSelector(selectCurrentDepth)
  const maxDepth = useAppSelector(selectMaxAvailableDepth)
  const clusters = useAppSelector(selectClusters)
  const activeClusters = useAppSelector(selectActiveClusters)
  const activeLeavesCount = useAppSelector(selectActiveLeavesCount)
  const selectedGraphicalElements = useAppSelector(
    selectAnalysisSelectedGraphicalElementIds,
  )
  const clusterListLinkedChartId = useAppSelector(
    selectClusterListLinkedChartId,
  )
  const globallyHiddenClusters = useAppSelector(selectGloballyHiddenClusters)
  const lassos = useAppSelector(selectAnalysisLassos)
  const analysisAccessMode = useAppSelector(selectAnalysisAccessMode)

  const [hierarchyChartType, setHierarchyChartType] = useState(
    HierarchyCharts.Sunburst,
  )
  const [allowTraversingTree, setAllowTraversingTree] = useState(false)
  const [shouldShowExpandedSelf, setShouldShowExpandedSelf] = useState(false)
  const [hoveredCluster, setHoveredCluster] = useState<Cluster>()

  const [sunburstRef, { width, height }] = useSize<HTMLDivElement>({
    debounce: 250,
  })

  const changeDepth = useMemo(() => {
    return _.debounce(function changeDepth(value: SliderChangeValue) {
      if (typeof value !== 'number') {
        throw new Error('value must be a number')
      }

      dispatch(setDepth({ depth: value }))
    }, 500)
  }, [dispatch])

  const getChartId = (hierarchyChartType: HierarchyCharts): string => {
    switch (hierarchyChartType) {
      case HierarchyCharts.NetworkChart:
        return ANALYSIS_HIERARCHY_NETWORK_ID
      case HierarchyCharts.PieChart:
        return ANALYSIS_HIERARCHY_PIE_CHART_ID
      case HierarchyCharts.Sunburst:
        return ANALYSIS_HIERARCHY_SUNBURST_ID
      default:
        return ANALYSIS_HIERARCHY_SUNBURST_ID
    }
  }

  const changeGraphType = useCallback(
    (chartType: HierarchyCharts) => {
      setHierarchyChartType(chartType)
      for (const type in HierarchyCharts) {
        dispatch(
          removeGraphicalElementFromSelection({
            id: getChartId(HierarchyCharts[type]),
          }),
        )
      }
    },
    [dispatch],
  )

  const canExpandCluster = useCallback(
    (clusterId: string) => {
      const nodeChildren = clusters.filter(d => d.parent === clusterId)
      return nodeChildren.length > 0
    },
    [clusters],
  )

  const onSunburstClusterClickHandler = useCallback(
    (id: string, panModeEnabled: boolean) => {
      if (analysisAccessMode !== 'read-and-write') {
        return
      }

      const cluster = clusters.filter(cluster => cluster.id === id)
      if (cluster[0].positionDeterminants && canExpandCluster(cluster[0].id)) {
        const newGraph: Omit<Graph, 'id'> = {
          chart_type: 'Dot plot',
          x_axis: cluster[0].positionDeterminants[0],
          y_axis: cluster[0].positionDeterminants[1],
          x_axis_scale_type: 'linear',
          y_axis_scale_type: 'linear',
          depth: 0,
          active_leaf_ids: [],
          hidden_cluster_ids: [...globallyHiddenClusters],
          gates: [],
          name: '',
          default_name: '',
          zoom: null,
          event_limit: EVENT_PERCENTAGE_LIMIT,
          lasso_ids: {},
          lassos_hierarchy: [],
          parent_lasso_id: undefined,
          created_at: new Date().toISOString(),
        }
        dispatch(
          addChartActionCreator({
            chart: newGraph,
          }),
        )
      }
      if (!panModeEnabled) {
        dispatch(toggleClusterActive({ clusterId: id }))
      }
    },
    [
      analysisAccessMode,
      canExpandCluster,
      clusters,
      dispatch,
      globallyHiddenClusters,
    ],
  )

  const handleHoveredClusterChange = useEventCallback(
    (cluster: Cluster | undefined, point: [number, number]) => {
      setHoveredCluster(cluster)
      setFloatingTooltipReferencePoint(refs, point)
    },
  )

  const showChart = (panModeEnabled: boolean) => {
    switch (hierarchyChartType) {
      case HierarchyCharts.PieChart:
        return (
          <PieChart
            width={width}
            height={height - PAN_BOTTOM_BAR_HEIGHT}
            onClusterClick={id => {
              if (!panModeEnabled && analysisAccessMode === 'read-and-write') {
                dispatch(toggleClusterActive({ clusterId: id }))
              }
            }}
            onHoveredClusterChange={handleHoveredClusterChange}
          />
        )
      case HierarchyCharts.NetworkChart:
        return (
          <NetworkGraph
            activeClusters={activeClusters}
            width={width}
            height={height - PAN_BOTTOM_BAR_HEIGHT}
          />
        )
      default:
        return (
          <Sunburst
            width={width}
            height={height - PAN_BOTTOM_BAR_HEIGHT}
            allowTraversingTree={allowTraversingTree}
            onClusterClick={id =>
              onSunburstClusterClickHandler(id, panModeEnabled)
            }
            onHoveredClusterChange={handleHoveredClusterChange}
          />
        )
    }
  }

  const showChartIcon = (
    chartType: HierarchyCharts,
    tooltipText: string,
    iconName: IconNames,
  ) => {
    return (
      <ActionsIcon onClick={() => changeGraphType(chartType)}>
        <StyledIcon name={iconName} color={theme.colors.primaryDark[100]} />
        <Tooltip>{tooltipText}</Tooltip>
      </ActionsIcon>
    )
  }

  const { floatingStyles, refs } = useFloating({
    ...DEFAULT_USE_FLOATING_PROPS,
  })

  return (
    <HierarchyContainer
      $isSelected={!clusterListLinkedChartId}
      $isExpanded={isExpanded}
      className={className}
      ref={refs.setReference}
    >
      <LayoutItemCardHeader
        title="Cluster hierarchy"
        isSelected={selectedGraphicalElements.some(
          s => s === getChartId(hierarchyChartType),
        )}
        isExpanded={isExpanded}
        actions={[
          ...includeIf(!isExpanded, [
            {
              tooltip: 'Link to cluster list',
              icon: <ListIcon />,
              onClick: () =>
                dispatch(setClusterListConnectedChartId(undefined)),
              isActive: !clusterListLinkedChartId,
            },
          ]),
          ...includeIf(analysisAccessMode === 'read-and-write', [
            {
              icon: <ReplayIcon />,
              tooltip: 'Restore initial clustering depth',
              onClick: () => dispatch(resetAnalysisToAutofocus()),
            },
          ]),
        ]}
        onSelect={() =>
          dispatch(
            toggleAnalysisGraphicalElementSelection(
              getChartId(hierarchyChartType),
            ),
          )
        }
        onExpand={() => setShouldShowExpandedSelf(true)}
        onCloseExpand={onCloseExpand}
      />

      <ChartTypes>
        {showChartIcon(HierarchyCharts.Sunburst, 'Sunburst', 'sun')}
        {showChartIcon(HierarchyCharts.PieChart, 'Pie chart', 'chartPie')}
        {showChartIcon(HierarchyCharts.NetworkChart, 'Network', 'network')}
      </ChartTypes>

      <DefaultErrorBoundary>
        <Wrapper ref={sunburstRef}>
          {analysisLoadingStatus === 'success' ? (
            <Pan
              bottomInformation={`${activeLeavesCount} Cluster${
                activeLeavesCount > 1 ? 's' : ''
              }`}
              customControlButton={
                hierarchyChartType === HierarchyCharts.Sunburst ? (
                  <Actions>
                    <StyledSwitch
                      checked={allowTraversingTree}
                      color="primary"
                      onClick={() =>
                        setAllowTraversingTree(!allowTraversingTree)
                      }
                    />
                    <Tooltip>
                      {allowTraversingTree
                        ? 'Expand cluster on click'
                        : 'Inspect cluster subtree'}
                    </Tooltip>
                  </Actions>
                ) : undefined
              }
            >
              {({ panModeEnabled }) => (
                <div style={{ display: 'flex' }}>
                  {showChart(panModeEnabled)}
                </div>
              )}
            </Pan>
          ) : (
            <div>
              <LoadingMask isFirstLoad>
                <p>Getting clustering data...</p>
              </LoadingMask>
            </div>
          )}
        </Wrapper>
      </DefaultErrorBoundary>
      <SliderContainer>
        <SliderText>Choose the depth you want to display</SliderText>
        {maxDepth && (
          <Slider
            min={1}
            max={maxDepth}
            value={depth}
            onChange={changeDepth}
            disabled={analysisAccessMode === 'read-only'}
          />
        )}
      </SliderContainer>
      {shouldShowExpandedSelf && (
        <Modal open onClose={() => setShouldShowExpandedSelf(false)}>
          <ExpandedAnalysisHierarchy
            onCloseExpand={() => setShouldShowExpandedSelf(false)}
          />
        </Modal>
      )}
      {hoveredCluster && (
        <TooltipContainer ref={refs.setFloating} style={floatingStyles}>
          <ClusterTooltipContent cluster={hoveredCluster} lassos={lassos} />
        </TooltipContainer>
      )}
    </HierarchyContainer>
  )
}

const HierarchyContainer = styled(LayoutItemCard)`
  grid-template-rows: auto auto 1fr auto;
`

const Wrapper = styled.div`
  display: flex;
  overflow: hidden;
  flex: 1;
  width: 100%;
  border-bottom: 1px solid ${props => props.theme.colors.primaryDark[20]};
`

const SliderContainer = styled.div`
  display: block;
  width: 100%;
  background-color: ${props => props.theme.colors.white};
  padding: 20px 20px 25px;
  border-radius: ${props => props.theme.radius[2]}px;
`

const SliderText = styled.p`
  width: 100%;
  text-align: center;
  font-weight: bold;
  font-size: ${props => props.theme.font.size.small}px;
  margin-bottom: 30px;
`

const StyledIcon = styled(Icon)`
  margin: 2px;
`
const Actions = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
`

const ActionsIcon = styled.div<{ $disabled?: boolean }>`
  cursor: ${({ $disabled }) => ($disabled ? 'not-allowed' : 'auto')};
  color: ${({ $disabled, theme }) =>
    $disabled ? theme.colors.greyscale[70] : theme.colors.primaryDark[100]};
  display: flex;
  align-items: center;
  position: relative;
  &:hover > span {
    display: block;
  }
  &.chart-grid-drag-handle {
    cursor: grab;
  }
`

const Tooltip = styled.span`
  border-radius: ${props => props.theme.radius[1]}px;
  color: ${props => props.theme.colors.white};
  background-color: ${props => props.theme.colors.primaryDark[100]};
  user-select: none;
  padding: 3px 6px;
  display: none;
  position: absolute;
  transform: translateX(-35%) translateY(110%);
  white-space: nowrap;
  z-index: 1;
`

const StyledSwitch = styled(Switch)`
  &:hover + span {
    display: block;
  }
`

const ChartTypes = styled.div`
  display: flex;
  justify-content: center;
  gap: 16px;
`

const ExpandedAnalysisHierarchy = styled(AnalysisHierarchy).attrs({
  isExpanded: true,
})`
  width: 90vw;
  height: 90vh;
`
