import Highcharts, { SeriesHeatmapOptions } from 'highcharts'
import { range } from 'lodash'

import { createAnalysisHeatMapBaseOptions } from 'components/graphs/AnalysisHeatMapBaseOptions'
import { AnalysisSunburstBaseOptions } from 'components/graphs/AnalysisSunburstBaseOptions'

import {
  selectActiveLeaves,
  selectHeatmapColors,
  selectHeatmapSeriesComputationParams,
  selectSortedSelectedChannels,
  selectSelectedChannelsWithDetails,
  selectShownActiveLeafIds,
} from 'pages/analysis/store/selectors'

import { RootState } from 'shared/store'
import { shortenText } from 'shared/utils/text'
import { computeHeatMapSeries } from 'shared/worker/analysis-worker'

import { Theme } from 'Theme'

const HEATMAP_CELL_HEIGHT = 30
const HEATMAP_CLUSTERS_PER_PAGE = 35

const MAX_HEATMAP_CHANNELS_NUMBER = 20
const MAX_HEATMAP_CHANNEL_LABEL_LENGTH = 20

type RenderHeatMapsProps = {
  state: RootState
  theme: Theme
  lastChartsPageHeatmapClustersCount: number
}

type RenderHeatMapsResult = {
  part: number
  svg: string
  width: number
  height: number
}[]

export const renderHeatmaps = async ({
  state,
  theme,
  lastChartsPageHeatmapClustersCount,
}: RenderHeatMapsProps): Promise<RenderHeatMapsResult> => {
  const heatMapSeriesComputationParams =
    selectHeatmapSeriesComputationParams(state)
  const activeLeaves = selectActiveLeaves(state)
  const selectedChannels = selectSortedSelectedChannels(state)

  const series = await computeHeatMapSeries(heatMapSeriesComputationParams)

  const width = computeWidth(selectedChannels.length)

  // TODO: this is a workaround, currently sometimes the heatmap series
  // do not contain data for all clusters; when it's fixed we can
  // just replace (maxY + 1) with the number of active leaves
  const maxY = Math.max(...series[0].data!.map(point => point[1]))
  const numberOfEntirePageHeatmapGroups = Math.ceil(
    (maxY + 1 - lastChartsPageHeatmapClustersCount) / HEATMAP_CLUSTERS_PER_PAGE,
  )

  return Promise.all(
    range(
      0,
      numberOfEntirePageHeatmapGroups +
        (lastChartsPageHeatmapClustersCount > 0 ? 1 : 0),
    ).map(async part => {
      const clusterCount = (() => {
        if (part === 0 && lastChartsPageHeatmapClustersCount > 0) {
          return lastChartsPageHeatmapClustersCount
        }

        if (
          part <
          numberOfEntirePageHeatmapGroups -
            (lastChartsPageHeatmapClustersCount > 0 ? 0 : 1)
        ) {
          return Math.min(HEATMAP_CLUSTERS_PER_PAGE, activeLeaves.length)
        }

        const lastPageClustersCount =
          maxY + 1 - lastChartsPageHeatmapClustersCount

        if (lastPageClustersCount % HEATMAP_CLUSTERS_PER_PAGE === 0) {
          return HEATMAP_CLUSTERS_PER_PAGE
        }

        return lastPageClustersCount % HEATMAP_CLUSTERS_PER_PAGE
      })()

      const height = computeHeight(clusterCount)
      const seriesChunk = [
        {
          ...series[0],
          data: series[0].data!.filter(
            point =>
              point[1] >=
                Math.max(
                  part - (lastChartsPageHeatmapClustersCount > 0 ? 1 : 0),
                  0,
                ) *
                  HEATMAP_CLUSTERS_PER_PAGE +
                  Math.sign(part) * lastChartsPageHeatmapClustersCount &&
              point[1] <
                lastChartsPageHeatmapClustersCount +
                  (lastChartsPageHeatmapClustersCount > 0 ? part : part + 1) *
                    HEATMAP_CLUSTERS_PER_PAGE &&
              point[0] < MAX_HEATMAP_CHANNELS_NUMBER,
          ),
        },
      ]

      const svg = await renderHeatmap({
        theme,
        state,
        width,
        height,
        series: seriesChunk,
        shouldShortenChannelLabels: true,
      })

      return {
        part,
        svg,
        width,
        height,
      }
    }),
  )
}

type RenderHeatMapProps = {
  state: RootState
  theme: Theme
  width?: number
  height?: number
  series?: SeriesHeatmapOptions[]
  shouldShortenChannelLabels?: boolean
}

export const renderHeatmap = async ({
  state,
  theme,
  width,
  height,
  series,
  shouldShortenChannelLabels,
}: RenderHeatMapProps): Promise<string> => {
  const heatMapSeriesComputationParams =
    selectHeatmapSeriesComputationParams(state)
  const activeLeaves = selectActiveLeaves(state)
  const selectedChannelLabels = selectSelectedChannelsWithDetails(state).map(
    channel => channel.__computed__displayName,
  )
  const shownActiveLeafIds = selectShownActiveLeafIds(state)
  const heatmapColors = selectHeatmapColors(state)

  if (!series) {
    series = await computeHeatMapSeries(heatMapSeriesComputationParams)
  }

  const AnalysisHeatMapBaseOptions = createAnalysisHeatMapBaseOptions({
    theme,
    leaves: activeLeaves,
  })
  return new Highcharts.Chart(document.createElement('div'), {
    ...AnalysisHeatMapBaseOptions,
    chart: {
      ...AnalysisSunburstBaseOptions.chart,
      width: width ?? computeWidth(selectedChannelLabels.length),
      height: height ?? computeHeight(shownActiveLeafIds.length),
    },
    xAxis: {
      ...AnalysisHeatMapBaseOptions.xAxis,
      categories: shouldShortenChannelLabels
        ? selectedChannelLabels.map(channel =>
            shortenText(channel, MAX_HEATMAP_CHANNEL_LABEL_LENGTH),
          )
        : selectedChannelLabels,
      labels: {
        ...AnalysisHeatMapBaseOptions.xAxis.labels,
        style: {
          fontFamily: 'Arial',
        },
      },
    },
    yAxis: {
      ...AnalysisHeatMapBaseOptions.yAxis,
      categories: shownActiveLeafIds,
      labels: {
        ...AnalysisHeatMapBaseOptions.yAxis.labels,
        style: {
          fontFamily: 'Arial',
        },
      },
    },
    colorAxis: {
      ...AnalysisHeatMapBaseOptions.colorAxis,
      minColor: heatmapColors.min,
      maxColor: heatmapColors.max,
      labels: {
        ...AnalysisHeatMapBaseOptions.colorAxis.labels,
        style: {
          ...AnalysisHeatMapBaseOptions.colorAxis.labels.style,
          fontFamily: 'Arial',
        },
      },
    },
    series: series,
    legend: {
      ...AnalysisHeatMapBaseOptions.legend,
      itemStyle: {
        fontFamily: 'Arial',
      },
    },
  }).getSVG()
}

const computeWidth = (channelCount: number) => {
  return (
    200 +
    Math.min(channelCount, MAX_HEATMAP_CHANNELS_NUMBER) * HEATMAP_CELL_HEIGHT
  )
}

const computeHeight = (clusterCount: number) => {
  return 300 + clusterCount * HEATMAP_CELL_HEIGHT
}
