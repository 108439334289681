import React from 'react'
import styled from 'styled-components'

import { ReactComponent as Logo } from 'assets/images/logo.svg'

import { AnalysisHeaderDetails } from 'pages/analysis/AnalysisHeaderDetails'

import { CompensatedFile, FcsFile } from 'shared/api/files.api'
import { Project } from 'shared/api/projects.api'
import { Analysis } from 'shared/models/AnalysisModels'
import { Signature } from 'shared/models/Signature'
import { formatDateTime } from 'shared/utils/utils'

import { PrintSignatures } from './AnalysisPdf'

type AnalysisPrintPageProps = {
  children: React.ReactNode
  analysis: Analysis
  compensatedFile: CompensatedFile
  fcsFile: FcsFile
  project: Project
  pageNumber: number
  totalPages: number
  signatures: PrintSignatures
}

export const AnalysisPdfPage = ({
  children,
  analysis,
  compensatedFile,
  fcsFile,
  pageNumber,
  totalPages,
  signatures,
}: AnalysisPrintPageProps): JSX.Element => {
  return (
    <AnalysisPrintPageRoot>
      <Header>
        <Logo />
        <AnalysisHeaderDetails
          analysis={analysis}
          compensatedFile={compensatedFile}
          fcsFile={fcsFile}
          isAnalysisSaved={true}
        />
      </Header>
      {children}
      <Footer>
        <div>{formatDateTime(new Date())}</div>
        <div>
          {}
          {signatures.analysed && (
            <SignatureLabel>
              Analyzed: {getSignedBy(signatures.analysed)}
            </SignatureLabel>
          )}
          {signatures.reviewed && (
            <SignatureLabel>
              Reviewed: {getSignedBy(signatures.reviewed)}
            </SignatureLabel>
          )}
          {signatures.approved && (
            <SignatureLabel>
              Approved: {getSignedBy(signatures.approved)}
            </SignatureLabel>
          )}
        </div>
        <div>
          Page {pageNumber} of {totalPages}
        </div>
      </Footer>
    </AnalysisPrintPageRoot>
  )
}

const getSignedBy = (signature: Signature) => {
  return `${signature.signed_by.first_name} ${signature.signed_by.last_name}`
}

const AnalysisPrintPageRoot = styled.div`
  width: 100vw;
  height: 100vh;
  background: white;
  page-break-after: always;
  display: grid;
  grid-template-rows: 64px 1fr auto;
  overflow: hidden;
`

const Header = styled.div`
  display: flex;
  justify-content: space-between;
  overflow: hidden;
`

const Footer = styled.footer`
  display: flex;
  justify-content: space-between;
  padding-top: 32px;
`

const SignatureLabel = styled.span`
  margin-right: 16px;
`
