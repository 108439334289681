import { omit } from 'lodash'
import { useCallback, useMemo } from 'react'

import { useAsyncMemo } from 'shared/hooks/useAsyncMemo'
import { useStable } from 'shared/hooks/useStable'
import { useAppSelector } from 'shared/store'
import { analysisWorker } from 'shared/worker'

import { selectAnalysisScatterplotSeriesComputationParams } from './store/selectors'

type UseGateWithPercentagesProps = {
  chartId: string
  gates: Graph.Gate[]
}

export const useGateWithPercentages = ({
  chartId,
  gates,
}: UseGateWithPercentagesProps): Graph.Gate[] => {
  const comutationsParams = useAppSelector(
    state => selectAnalysisScatterplotSeriesComputationParams(state)[chartId],
  )

  const chart = useStable(
    useMemo(() => {
      return omit(comutationsParams.chart, ['scale'])
    }, [comutationsParams.chart]),
  )

  const gatesWithPercentages = useAsyncMemo(
    useCallback(() => {
      return analysisWorker.computeScatterPlotGatePercentages({
        chart: chart,
        gates,
      })
    }, [chart, gates]),
  )

  return gates.map(gate => {
    const gateWithPercentage = gatesWithPercentages.value?.find(
      g => g.id === gate.id,
    )
    return {
      ...gate,
      percentage: gateWithPercentage?.percentage ?? 0,
    }
  })
}
