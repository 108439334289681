import { Popper, PopperProps, TextField } from '@material-ui/core'
import { Autocomplete, AutocompleteProps } from '@material-ui/lab'
import { useRef } from 'react'
import styled from 'styled-components'

type AutocompleteInputProps<
  T,
  Multiple extends boolean,
  DisableClearable extends boolean,
  FreeSolo extends boolean,
> = Omit<
  AutocompleteProps<T, Multiple, DisableClearable, FreeSolo>,
  'renderInput'
> & {
  label: string
  name?: string
}

export const AutocompleteInput = <
  T,
  Multiple extends boolean = false,
  DisableClearable extends boolean = true,
  FreeSolo extends boolean = false,
>({
  label,
  name,
  ...props
}: AutocompleteInputProps<
  T,
  Multiple,
  DisableClearable,
  FreeSolo
>): JSX.Element => {
  const inputRef = useRef(null)

  const CustomPopper = function (props: PopperProps) {
    return (
      <StyledPopper
        {...props}
        style={{ width: 250 }}
        anchorEl={inputRef.current}
        placement="bottom-start"
      />
    )
  }
  return (
    <StyledAutocomplete
      {...props}
      renderInput={params => (
        <>
          <StyledLabel>{label}</StyledLabel>
          <TextField
            variant="outlined"
            name={name}
            {...params}
            ref={inputRef}
          />
        </>
      )}
      size="small"
      PopperComponent={CustomPopper}
    />
  )
}

const StyledAutocomplete = styled(Autocomplete)`
  & .MuiAutocomplete-inputRoot.MuiOutlinedInput-root {
    height: 26px;
    padding-top: 0;
    padding-bottom: 0;
    font-size: 13px;
    color: ${props => props.theme.colors.primaryDark[100]};
  }
` as typeof Autocomplete

const StyledLabel = styled.label`
  font-size: 12px;
  color: ${props => props.theme.colors.primaryDark[100]};
`

const StyledPopper = styled(Popper)`
  & * {
    font-size: 13px;
    color: ${props => props.theme.colors.primaryDark[100]};
  }

  & .MuiAutocomplete-noOptions {
    color: ${props => props.theme.colors.greyscale[50]};
  }
`
