import React, { useMemo, useRef, useState } from 'react'
import styled from 'styled-components'

import { Icon } from 'assets/images/icons/Icon'

import { Button } from 'components/Button'
import { Checkbox } from 'components/Checkbox'
import { DotLabel } from 'components/DotLabel'

import { useDialog } from 'shared/contexts/DialogContext'
import { useEventCallback } from 'shared/hooks/useEventCallback'
import { useAppSelector } from 'shared/store'

import { theme } from 'Theme'

import { ClusterTooltip } from './ClusterTooltip'
import { LassoTag } from './LassoTag'
import { RenameClusterDialog } from './RenameClusterDialog'
import { Cluster, selectAnalysisAccessMode } from './store/selectors'

type ClusterListCellProps = {
  cluster: Pick<Cluster, 'id' | 'label' | 'color' | 'lassoIds' | 'defaultLabel'>
  lassos: Record<string, { id: string; name: string }>
  selectedIds: string[]
  shouldShowLassos?: boolean
  isHighlighted?: boolean
  onChange?: (selectedIds: string[]) => void
  onRename?: (clusterId: string, name: string) => void
  onDoubleClickClusterDot?: (clusterId: string) => void
}

export const ClusterListCell = ({
  cluster,
  lassos,
  selectedIds,
  shouldShowLassos,
  isHighlighted,
  onChange,
  onRename,
  onDoubleClickClusterDot,
}: ClusterListCellProps): JSX.Element => {
  const { showDialog } = useDialog()

  const analysisAccessMode = useAppSelector(selectAnalysisAccessMode)

  const [clusterToRename, setClusterToRename] = useState<string | undefined>(
    undefined,
  )
  const changeNameInput = useRef<HTMLInputElement>(null)

  const isSelected = useMemo(
    () => selectedIds.includes(cluster.id),
    [cluster.id, selectedIds],
  )

  const handleRenameCluster = useEventCallback(
    (
      cluster: Pick<Cluster, 'id' | 'label' | 'defaultLabel'>,
      newName: string | undefined,
    ) => {
      onRename?.(cluster.id, newName || cluster.defaultLabel)
    },
  )

  const handleDoubleClickClusterDot = useEventCallback(
    (event: React.MouseEvent<HTMLDivElement>) => {
      event.preventDefault()
      onDoubleClickClusterDot?.(cluster.id)
    },
  )

  return (
    <ClusterTooltip
      cluster={cluster}
      lassos={lassos}
      disabled={clusterToRename !== undefined}
    >
      <ClusterListCellRoot key={cluster.id}>
        <DotLabel
          onDoubleClick={handleDoubleClickClusterDot}
          $color={cluster.color}
          $isHighlighted={isHighlighted}
        />
        <Checkbox
          checked={isSelected}
          onClick={() =>
            isSelected
              ? onChange?.(selectedIds.filter(id => id !== cluster.id))
              : onChange?.([...selectedIds, cluster.id])
          }
        />
        {clusterToRename === cluster.id ? (
          <>
            <StyledInput
              defaultValue={cluster.label}
              ref={changeNameInput}
              onKeyUp={event => {
                if (event.key === 'Enter') {
                  handleRenameCluster(cluster, changeNameInput.current?.value)
                  setClusterToRename(undefined)
                }
              }}
            ></StyledInput>
            <div
              onClick={() => {
                handleRenameCluster(cluster, changeNameInput.current?.value)
                setClusterToRename(undefined)
              }}
            >
              <StyledIcon name="check" color={theme.colors.primaryDark[100]} />
            </div>
            <div
              onClick={() => {
                setClusterToRename(undefined)
              }}
            >
              <StyledIcon name="close" color={theme.colors.primaryDark[100]} />
            </div>
          </>
        ) : (
          <Label
            onDoubleClick={() => {
              if (onRename) {
                if (analysisAccessMode === 'experiment') {
                  showDialog(closeDialog => (
                    <RenameClusterDialog
                      cluster={cluster}
                      onClose={closeDialog}
                    />
                  ))
                } else {
                  setClusterToRename(cluster.id)
                  setTimeout(() => changeNameInput.current?.select())
                }
              }
            }}
          >
            {shouldShowLassos && cluster.lassoIds.length > 0 && (
              <LabelLassos>
                {cluster.lassoIds.map(lassoId => (
                  <LassoTag key={lassoId} name={lassos[lassoId].name} />
                ))}
              </LabelLassos>
            )}
            <p>{cluster.label}</p>
          </Label>
        )}
      </ClusterListCellRoot>
    </ClusterTooltip>
  )
}

const HighlightButton = styled(Button)`
  opacity: 0;
  margin-right: 24px;
  color: ${props => props.theme.colors.primaryDark[100]};
  font-size: ${props => props.theme.font.size.smallest}px;
  font-family: ${props => props.theme.font.style.bold};
  height: 32px;
  padding: 0 10px;
  transition: color 0.3s ease;
  background-color: transparent;
  &:hover {
    background-color: rgba(0, 0, 0, 0.04);
    color: ${props => props.theme.colors.primaryDark[100]};
  }
`

const ClusterListCellRoot = styled.div`
  display: flex;
  align-items: center;

  &:hover ${HighlightButton} {
    opacity: 1;
  }
`

const StyledIcon = styled(Icon)`
  width: 12px;
  height: 12px;
  margin-left: 5px;
`

const StyledInput = styled.input`
  width: 80px;
  font-size: ${props => props.theme.font.size.small}px;
  outline: none;
  color: ${props => props.theme.colors.primaryDark[100]};
`

const Label = styled.div`
  display: flex;
  align-items: center;

  p {
    flex-grow: 1;
  }
`

const LabelLassos = styled.div`
  display: flex;
  gap: 2px;
  max-width: 200px;
  flex-wrap: wrap;
  margin-right: 4px;
`
