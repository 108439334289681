export const findDescendantLassos = (
  lassoIds: string[],
  parentLassoIdByLassoId: Record<string, string | undefined>,
): string[] => {
  const descendantLassoIds = Object.entries(parentLassoIdByLassoId)
    .filter(([, value]) => value && lassoIds.includes(value))
    .map(([key]) => key)

  if (descendantLassoIds.length === 0) {
    return []
  }

  return [
    ...descendantLassoIds,
    ...findDescendantLassos(descendantLassoIds, parentLassoIdByLassoId),
  ]
}
