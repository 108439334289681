import { MessagesForUser } from 'shared/models/AnalysisModels'
import { PaginatedResults } from 'shared/models/Result'
import { includeIf } from 'shared/utils/utils'

import { CompensatedFile } from './files.api'
import { privateApi } from './private.api'
import { encodeTagParameters } from './utils'
import { Brick, WorkflowStatus } from './workflows.api'

export const pipelinesApi = privateApi.injectEndpoints({
  endpoints: builder => ({
    getPipelines: builder.query<GetPipelinesResult, GetPipelinesPayload>({
      query: payload => ({
        url: `/pipelines/`,
        params: {
          project: payload.projectId,
          page: payload.page,
          ...includeIf(payload.workflowId, {
            version_source: payload.workflowId,
          }),
          ...includeIf(payload.searchQuery, { search: payload.searchQuery }),
        },
      }),
      providesTags: [
        { type: 'Workflow', id: encodeTagParameters({ id: 'list' }) },
      ],
    }),
    getPipeline: builder.query<PipelineDetails, string>({
      query: (pipelineId: string) => `/pipelines/${pipelineId}/`,
      providesTags: (_result, _error, pipelineId) => [
        { type: 'Workflow', id: encodeTagParameters({ id: pipelineId }) },
      ],
    }),
    updatePipeline: builder.mutation<unknown, UpdatePipelinePayload>({
      query: ({ pipelineId, ...payload }) => ({
        url: `/pipelines/${pipelineId}/`,
        method: 'PATCH',
        body: payload,
      }),
    }),
    deletePipeline: builder.mutation<unknown, string>({
      query: pipelineId => ({
        url: `/pipelines/${pipelineId}/`,
        method: 'DELETE',
      }),
    }),
    checkFilesChannels: builder.mutation<
      CheckFilesChannelsResult,
      CheckFilesChannelsPayload
    >({
      query: payload => ({
        url: `/pipelines/${payload.pipelineId}/check-files-channels/`,
        method: 'POST',
        body: { files: payload.compensatedFileIds },
      }),
    }),
  }),
})

export const {
  useGetPipelinesQuery,
  useGetPipelineQuery,
  useUpdatePipelineMutation,
  useDeletePipelineMutation,
  useCheckFilesChannelsMutation,
} = pipelinesApi

type GetPipelinesResult = PaginatedResults<Pipeline[]>

export type Pipeline = {
  author_name: string
  created_at: string
  description: null
  id: string
  messages_for_user: MessagesForUser
  mode: 'version'
  name: string
  project: string
  status: PipelineStatus
  updated_at: string
  version: string
  version_source: string
}

export type PipelineStatus = WorkflowStatus

export type PipelineDetails = Pipeline & {
  bricks: Brick[]
  compensated_files: CompensatedFile[]
}

type GetPipelinesPayload = {
  projectId: string
  page: number
  workflowId?: string
  searchQuery?: string
}

type UpdatePipelinePayload = {
  pipelineId: string
  name?: string
  description?: string
}

type CheckFilesChannelsPayload = {
  pipelineId: string
  compensatedFileIds: string[]
}

type CheckFilesChannelsResult = {
  message: string
  all_missing_channels?: string[]
  warnings?: {
    [compensatedFileId: string]: {
      message: string
      missing_channels: string[]
    }
  }
}
