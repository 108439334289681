import { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { useEvent } from 'react-use'
import { useTheme } from 'styled-components'

import { useEventCallback } from 'shared/hooks/useEventCallback'
import { GraphZoom } from 'shared/models/Graphs'
import { useAppDispatch, useAppSelector } from 'shared/store'

import {
  Cluster,
  selectActiveLeavesByChartId,
  selectAnalysisAccessMode,
  selectAnalysisLassos,
  selectChartById,
} from '../store/selectors'
import { UseChartScales } from '../useChartScales'
import { LassoTool, LassoToolLasso, LassoToolState } from './LassoTool'
import { useChartEvent } from './useChartEvent'

type UseLassoToolProps = {
  chartId: string
  chartScales: UseChartScales
  xAxis: string
  yAxis: string | undefined
  zoom: GraphZoom
  container: HTMLDivElement | null | undefined
  onFinishLasso: () => void
}

type UseLassoToolResult = {
  isActive: boolean
  lassoCreationMode: LassoToolState['lassoCreationMode']
  lassos: LassoToolState['lassos']
  currentLasso: LassoToolLasso | undefined
  selectedClusters: Cluster[]
  hoveredLassoName: string | undefined
  hoveredLassoClusters: Cluster[] | undefined
  shouldHideTooltip: boolean
  changeIsActive: LassoTool['changeIsActive']
  setScatterPlotInstance: LassoTool['setScatterPlotInstance']
  saveLasso: (name: string) => void
  deleteLasso: () => void
  clearCurrentLasso: () => void
  convertCurrentLassoToFreeshape: () => void
  changeLassoCreationMode: (mode: LassoToolState['lassoCreationMode']) => void
  refresh: () => void
}

export const useLassoTool = (props: UseLassoToolProps): UseLassoToolResult => {
  const theme = useTheme()

  const dispatch = useAppDispatch()
  const activeLeaves = useAppSelector(
    state => selectActiveLeavesByChartId(state)[props.chartId],
  )
  const chartByChartId = useAppSelector(selectChartById)
  const analysisLassos = useAppSelector(selectAnalysisLassos)
  const analysisAccessMode = useAppSelector(selectAnalysisAccessMode)

  const [, setDummy] = useState({})
  const triggerReactRender = useCallback(() => setDummy({}), [])

  const chart = chartByChartId[props.chartId]

  const shownLassoIds = Object.entries(chart.lasso_ids)
    .filter(([, isShown]) => isShown)
    ?.map(([lassoId]) => lassoId)

  const shownLassos = Object.fromEntries(
    Object.entries(analysisLassos).filter(([lassoId]) =>
      shownLassoIds.includes(lassoId),
    ),
  )

  const lassoToolProps = useMemo(
    () => ({
      ...props,
      activeLeaves,
      analysisAccessMode,
      theme,
      shownLassos,
      triggerReactRender,
      dispatch,
    }),
    [
      activeLeaves,
      analysisAccessMode,
      dispatch,
      props,
      shownLassos,
      theme,
      triggerReactRender,
    ],
  )

  const lassoTool = useRef(new LassoTool(lassoToolProps)).current

  useEffect(() => {
    lassoTool.onReactRender(lassoToolProps)
  }, [lassoTool, lassoToolProps])

  useChartEvent(
    lassoTool.state.scatterPlotRef,
    props.container,
    'mousemove',
    lassoTool.onMouseMove.bind(lassoTool),
    lassoTool.state.isActive,
  )
  useChartEvent(
    lassoTool.state.scatterPlotRef,
    props.container,

    'mousedown',
    lassoTool.onMouseDown.bind(lassoTool),
    lassoTool.state.isActive,
  )
  useChartEvent(
    lassoTool.state.scatterPlotRef,
    props.container,
    'mouseup',
    lassoTool.onMouseUp.bind(lassoTool),
    lassoTool.state.isActive,
  )
  useChartEvent(
    lassoTool.state.scatterPlotRef,
    props.container,
    'dblclick',
    lassoTool.onDoubleClick.bind(lassoTool),
    lassoTool.state.isActive && analysisAccessMode === 'read-and-write',
  )

  useEvent('keydown', lassoTool.onKeydown.bind(lassoTool))

  const setScatterPlotInstance = useMemo(
    () => lassoTool.setScatterPlotInstance.bind(lassoTool),
    [lassoTool],
  )

  const changeIsActive = useMemo(
    () => lassoTool.changeIsActive.bind(lassoTool),
    [lassoTool],
  )

  const saveLasso = useMemo(
    () => lassoTool.saveLasso.bind(lassoTool),
    [lassoTool],
  )

  const deleteLasso = useMemo(
    () => lassoTool.deleteLasso.bind(lassoTool),
    [lassoTool],
  )

  const clearCurrentLasso = useMemo(
    () => lassoTool.clearCurrentLasso.bind(lassoTool),
    [lassoTool],
  )

  const convertCurrentLassoToFreeshape = useMemo(
    () => lassoTool.convertCurrentLassoToFreeshape.bind(lassoTool),
    [lassoTool],
  )

  const changeLassoCreationMode = useMemo(
    () => lassoTool.changeLassoCreationMode.bind(lassoTool),
    [lassoTool],
  )

  const refresh = useEventCallback(() => {
    lassoTool.onReactRender(lassoToolProps)
  })

  const {
    isActive,
    lassoCreationMode,
    currentLassoId,
    lassos,
    movedPolygonPointIndex,
    __computed__hoveredPolygonPointIndex,
    __computed__hoveredLassoId,
    isMovingLasso,
  } = lassoTool.state

  const currentLasso = (currentLassoId && lassos[currentLassoId]) || undefined

  return {
    isActive,
    lassoCreationMode,
    lassos,
    currentLasso,
    selectedClusters: currentLassoId ? lassoTool.getSelectedClusters() : [],
    hoveredLassoName: __computed__hoveredLassoId
      ? analysisLassos[__computed__hoveredLassoId]?.name
      : undefined,
    hoveredLassoClusters: __computed__hoveredLassoId
      ? lassoTool.getHoveredLassoClusters()
      : undefined,
    shouldHideTooltip:
      (isActive &&
        !currentLasso?.isFinished &&
        currentLasso?.polygon.length !== 0) ||
      __computed__hoveredPolygonPointIndex !== undefined ||
      movedPolygonPointIndex !== undefined ||
      isMovingLasso,
    setScatterPlotInstance,
    changeIsActive,
    saveLasso,
    deleteLasso,
    clearCurrentLasso,
    convertCurrentLassoToFreeshape,
    changeLassoCreationMode,
    refresh,
  }
}
