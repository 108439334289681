import { Fade } from '@material-ui/core'
import React from 'react'
import styled from 'styled-components'

import { Modal } from 'components/Modal'

import { SignaturesTable } from './SignaturesTable'

type SignatureModalProps = {
  hideModal: () => void
}

export const SignatureModal: React.FC<SignatureModalProps> = props => {
  const { hideModal } = props

  return (
    <Modal open onClose={hideModal}>
      <Fade in timeout={300}>
        <Container>
          <Title>E-Signature</Title>
          <TableWrapper>
            <SignaturesTable />
          </TableWrapper>
        </Container>
      </Fade>
    </Modal>
  )
}

const Container = styled.div`
  background-color: ${props => props.theme.colors.background};
  border-radius: ${props => props.theme.radius[2]}px;
  width: 70%;
  height: 70%;
  padding: 50px 32px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`

const Title = styled.div`
  text-align: center;
  font-family: ${props => props.theme.font.style.bold};
  font-size: ${props => props.theme.font.size.h1}px;
  margin-bottom: 20px;
`

const TableWrapper = styled.div`
  flex: 1;
  width: 80%;
  margin: auto;
  display: flex;
  overflow-y: auto;
`
