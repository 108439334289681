import { captureException } from '@sentry/browser'

/**
 * For best results use with throw
 * e.g. throw handleError(error)
 */
export function handleError<E>(
  error: E,
  errorMessage?: string,
  additionalInfo?: unknown,
): E {
  console.error(errorMessage ?? '', error, additionalInfo)
  captureException(error)

  return error
}
