import { OTHER_LETTER } from 'shared/constants'

export const getChannelDisplayName = ({
  id,
  label,
  defaultLabel,
}: {
  id: string
  label: string | undefined
  defaultLabel?: string
}): string => {
  if (!label) {
    label = defaultLabel
  }
  return label && label !== id ? `${id} (${label})` : id
}

export function groupChannelsByLetter<T extends { letter?: string | null }>(
  channels: T[],
): { [letter: string]: T[] } {
  const groupedChannels = {}
  for (const channel of channels) {
    const letter = channel.letter ?? OTHER_LETTER
    if (!groupedChannels[letter]) {
      groupedChannels[letter] = []
    }
    groupedChannels[letter].push(channel)
  }
  return groupedChannels
}

export function getSortedChannelLetters<
  T extends { [letter: string]: unknown },
>(groupedChannels: T): string[] {
  return Array.from(Object.keys(groupedChannels)).sort((a, b) =>
    a === OTHER_LETTER ? 1 : b === OTHER_LETTER ? -1 : a.localeCompare(b),
  )
}
