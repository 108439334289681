import Highcharts from 'highcharts'
import HighchartsReact from 'highcharts-react-official'
import { pick } from 'lodash'
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { useError } from 'react-use'

import {
  Cluster,
  ScatterPlotChartScale,
  ScatterPlotScaleType,
  selectClusterColorById,
} from 'pages/analysis/store/selectors'

import { useAsyncMemo } from 'shared/hooks/useAsyncMemo'
import { useStable } from 'shared/hooks/useStable'
import { useAppSelector } from 'shared/store'
import { analysisWorker } from 'shared/worker'

import { HighPerformanceScatterPlotBase } from './HighPerformanceScatterPlotBase'
import { HighPerformanceScatterPlotTooltip } from './HighPerformanceScatterPlotTooltip'

type HighPerformanceScatterPlotProps = {
  chart: {
    xAxis: string
    yAxis: string
    scale: ScatterPlotChartScale
    scaleType: ScatterPlotScaleType
    hiddenClusterIds: string[]
    activeLeafIds: string[]
    activeClusterIds: string[]
    eventLimit: number
  }
  options?: Highcharts.Options
  isLassoToolActive?: boolean
  hoveredLassoName?: string
  hoveredLassoClusters?: Cluster[]
  highlightedSeriesId?: string[]
  clusterDotSizes?: Record<string, number>
  shouldHideTooltip?: boolean
  onContextMenu?: (
    event: React.MouseEvent<HTMLDivElement, MouseEvent>,
    seriesId: string | undefined,
  ) => void
  onRenderFinished?: (this: Highcharts.Chart) => void
}

export const HighPerformanceScatterPlot = React.forwardRef<
  HighchartsReact.RefObject,
  HighPerformanceScatterPlotProps
>(
  (
    {
      chart,
      options,
      isLassoToolActive = false,
      hoveredLassoName,
      hoveredLassoClusters,
      highlightedSeriesId,
      clusterDotSizes,
      shouldHideTooltip = false,
      onContextMenu,
      onRenderFinished,
    },
    ref,
  ): JSX.Element => {
    const clusterColorById = useAppSelector(selectClusterColorById)

    const [highchartsChart, setHighchartsChart] = useState<Highcharts.Chart>()

    const scale = useLogarithmicScaleWorkaround(chart)

    const { series, isComputingSeriesRef } = useSeries(highchartsChart, chart)

    return (
      <HighPerformanceScatterPlotBase
        series={series}
        isComputingSeriesRef={isComputingSeriesRef}
        scale={scale}
        colorBySeriesId={clusterColorById}
        options={options}
        Tooltip={HighPerformanceScatterPlotTooltip}
        tooltipProps={{ hoveredLassoClusters, hoveredLassoName }}
        seriesDotSizes={clusterDotSizes}
        highlightedSeriesId={highlightedSeriesId}
        highchartsRef={ref}
        shouldDisableZoom={isLassoToolActive}
        shouldHideTooltip={shouldHideTooltip}
        onContextMenu={onContextMenu}
        onHighchartsChartChange={setHighchartsChart}
        onRenderFinished={onRenderFinished}
      />
    )
  },
)

const useLogarithmicScaleWorkaround = (
  chart: HighPerformanceScatterPlotProps['chart'],
) => {
  const chartProps = useStable({
    chart: pick(chart, ['xAxis', 'yAxis', 'scale', 'scaleType']),
  })

  return useAsyncMemo(
    useCallback(async () => {
      if (
        chartProps.chart.scaleType.xAxis === 'logarithmic' ||
        chartProps.chart.scaleType.yAxis === 'logarithmic'
      ) {
        return analysisWorker.computeLogarithmicScaleWorkaround(chartProps)
      } else {
        return chartProps.chart.scale
      }
    }, [chartProps]),
  ).currentValue
}

type UseSeriesResult = {
  seriesIdByPixelPosition: Map<string, string>
  plotWidth: number
  plotHeight: number
}

const useSeries = (
  highchartsChart: Highcharts.Chart | undefined,
  chart: HighPerformanceScatterPlotProps['chart'],
) => {
  const throwError = useError()
  const isComputingSeriesRef = useRef(false)
  const [series, setSeries] = useState<UseSeriesResult>()

  const computationParams = useStable(
    useMemo(() => {
      if (!highchartsChart?.plotWidth || !highchartsChart?.plotHeight) {
        return undefined
      }

      return {
        chart: {
          eventLimit: chart.eventLimit,
          xAxis: chart.xAxis,
          yAxis: chart.yAxis,
          hiddenClusterIds: chart.hiddenClusterIds,
          activeLeafIds: chart.activeLeafIds,
          activeClusterIds: chart.activeClusterIds,
          scale: {
            xAxis: {
              min: highchartsChart.xAxis[0].options.min!,
              max: highchartsChart.xAxis[0].options.max!,
            },
            yAxis: {
              min: highchartsChart.yAxis[0].options.min!,
              max: highchartsChart.yAxis[0].options.max!,
            },
          },
          scaleType: {
            xAxis: chart.scaleType.xAxis,
            yAxis: chart.scaleType.yAxis,
          },
        },
        plotSize: {
          width: highchartsChart.plotWidth,
          height: highchartsChart.plotHeight,
        },
      }
    }, [
      chart.activeClusterIds,
      chart.activeLeafIds,
      chart.eventLimit,
      chart.hiddenClusterIds,
      chart.scaleType.xAxis,
      chart.scaleType.yAxis,
      chart.xAxis,
      chart.yAxis,
      highchartsChart?.plotHeight,
      highchartsChart?.plotWidth,
      highchartsChart?.xAxis,
      highchartsChart?.yAxis,
    ]),
  )

  useEffect(() => {
    if (
      !computationParams ||
      computationParams.plotSize.width === 0 ||
      computationParams.plotSize.height === 0
    ) {
      return
    }

    let cancelled = false
    isComputingSeriesRef.current = true

    analysisWorker
      .computeScatterPlotSeriesIdsByPixelPosition(computationParams)
      .then(seriesIdByPixelPosition => {
        if (!cancelled) {
          setSeries({
            seriesIdByPixelPosition,
            plotWidth: computationParams.plotSize.width,
            plotHeight: computationParams.plotSize.height,
          })
          isComputingSeriesRef.current = false
        }
      })
      .catch(throwError)

    return () => {
      cancelled = true
    }
  }, [computationParams, setSeries, throwError])

  return { series, isComputingSeriesRef }
}
