import React, { FC, useCallback, useState } from 'react'
import styled, { useTheme } from 'styled-components'

import { Icon } from 'assets/images/icons/Icon'

import { CoordsField } from 'components/CoordsField'
import Input from 'components/forms/Input'

import { Graph } from 'shared/models/Graphs'
import { useAppDispatch } from 'shared/store'

import { toggleGateVisibility } from './store/analysis.slice'

type GateProps = {
  graph: Graph
  gate: Graph.Gate
  onGateUpdated(id: string, value: Graph.GateValues): void
  onAccept(gateId: string, gateName: string): void
  onCancel(gate: Graph.Gate): void
  onRemove(gate: Graph.Gate): void
  displayGateDuplicationHandler: (gate: Graph.Gate) => void
  canDuplicateGate: boolean
}

export const Gate: FC<GateProps> = ({
  graph,
  gate,
  onGateUpdated,
  onAccept,
  onCancel,
  onRemove,
  displayGateDuplicationHandler,
  canDuplicateGate,
}) => {
  const dispatch = useAppDispatch()

  // Computed
  const [isEditing, setIsEditing] = useState(false)
  const [gateName, setGateName] = useState('')
  const isValidated = gate.tempValues === undefined && !isEditing

  const formValues: Graph.GateValues = gate.tempValues
    ? gate.tempValues
    : {
        xMin: gate.xMin,
        xMax: gate.xMax,
        yMin: gate.yMin,
        yMax: gate.yMax,
      }

  // Hooks
  const theme = useTheme()

  const onModificationValidation = useCallback(
    (gate: Graph.Gate, gateName: string) => {
      onAccept(gate.id, gateName)
      setIsEditing(false)
      setGateName('')
    },
    [onAccept],
  )

  const onModificationCancel = useCallback(
    (gate: Graph.Gate) => {
      onCancel(gate)
      setGateName('')
      setIsEditing(false)
    },
    [onCancel],
  )

  const onToggleGateVisibility = () => {
    dispatch(toggleGateVisibility({ chartId: graph.id, gateId: gate.id }))
  }

  return (
    <GateRoot>
      <GateInfo>
        {isEditing ? (
          <Input
            name="Cluster edit"
            id="clusterEdit"
            type="text"
            value={gateName}
            onChange={e => setGateName(e.target.value)}
            autoFocus={true}
            placeholder={gate.defaultName || gate.name}
          />
        ) : (
          <>
            <GateNameContainer onClick={() => setIsEditing(true)}>
              <GateName title={gate.name}>{`Pop. ${gate.name}`}</GateName>
              <GateEditIcon
                name="edit"
                stroke={theme.colors.primaryDark[100]}
              />
            </GateNameContainer>
            {!isNaN(Number(gate?.percentage)) && (
              <GatePercentage>{gate?.percentage?.toFixed(1)}%</GatePercentage>
            )}
          </>
        )}
      </GateInfo>
      <GateForms>
        <CoordsField
          axis="x"
          min={formValues.xMin}
          max={formValues.xMax}
          isInputDisabled={gate.type === 'polygon'}
          onMinChanged={xMin => onGateUpdated(gate.id, { ...formValues, xMin })}
          onMaxChanged={xMax => onGateUpdated(gate.id, { ...formValues, xMax })}
        />
        <CoordsField
          axis="y"
          min={formValues.yMin}
          max={formValues.yMax}
          isInputDisabled={gate.type === 'polygon'}
          onMinChanged={yMin => onGateUpdated(gate.id, { ...formValues, yMin })}
          onMaxChanged={yMax => onGateUpdated(gate.id, { ...formValues, yMax })}
        />
      </GateForms>
      <GateButtons $isValidated={isValidated}>
        {isValidated ? (
          <>
            {canDuplicateGate && (
              <GateValidationIcon
                onClick={() => displayGateDuplicationHandler(gate)}
              >
                <Icon name="duplicate" stroke={theme.colors.primaryDark[100]} />
              </GateValidationIcon>
            )}
            <GateValidationIcon onClick={onToggleGateVisibility}>
              <Icon
                name={gate.hidden ? 'eyeClosed' : 'eyeOpen'}
                stroke={theme.colors.primaryDark[100]}
              />
            </GateValidationIcon>
            <GateValidationIcon onClick={() => onRemove(gate)}>
              <Icon name="delete" stroke={theme.colors.primaryDark[100]} />
            </GateValidationIcon>
          </>
        ) : (
          <>
            <GateTemporaryIcon
              style={{ backgroundColor: theme.colors.primaryDark[20] }}
              onClick={() => onModificationCancel(gate)}
            >
              <Icon name="close" color={theme.colors.white} stroke="none" />
            </GateTemporaryIcon>
            <GateTemporaryIcon
              style={{ backgroundColor: theme.colors.primaryDark[100] }}
              onClick={() => onModificationValidation(gate, gateName)}
            >
              <Icon name="check" color={theme.colors.white} stroke="none" />
            </GateTemporaryIcon>
          </>
        )}
      </GateButtons>
    </GateRoot>
  )
}

const GateRoot = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  padding: 16px 25px;
  border-bottom: 1px solid ${props => props.theme.colors.primaryDark[20]};
  &:last-child {
    border-bottom: none;
  }
`

const GateInfo = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  width: 120px;
  margin-right: 10px;
`

const GateNameContainer = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
  width: 100%;
`

const GateName = styled.p`
  color: ${props => props.theme.colors.primaryDark[100]};
  font-size: 12px;
  overflow: hidden;
  max-width: 100%;
  text-overflow: ellipsis;
  white-space: nowrap;
`

const GatePercentage = styled.p`
  color: ${props => props.theme.colors.primary[100]};
  font-size: 16px;
  font-weight: bold;
  width: 100%;
`

const GateForms = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  flex: 1;
`

const GateButtons = styled.div<{ $isValidated: boolean }>`
  display: flex;
  flex-direction: row;
  justify-content: ${props =>
    props.$isValidated ? 'flex-end' : 'space-between'};
  align-items: center;
  align-self: center;
  margin-left: 20px;
`

const GateValidationIcon = styled.div`
  display: flex;
  cursor: pointer;
  margin-left: 4px;
`

const GateTemporaryIcon = styled.div`
  display: flex;
  flex-direction: row;
  cursor: pointer;
  margin: 0 2px;
  width: 30px;
  height: 30px;
  border-radius: 15px;
  justify-content: center;
  align-items: center;

  svg {
    width: 16px;
    height: 16px;
  }
`

const GateEditIcon = styled(Icon)`
  min-width: 16px;
  width: 16px;
  height: 16px;
  margin-left: 5px;
`
