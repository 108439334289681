import { Polygon } from 'geometric'

import { translateToChartPoint } from 'shared/utils/highcharts-utils'

import { AppTheme } from 'Theme'

type RenderStaticLassoProps = {
  chart: Highcharts.Chart
  polygon: Polygon
  theme: AppTheme
  isCurrentLasso?: boolean
  svgGroup?: Highcharts.SVGElement
}

export const renderStaticLasso = ({
  chart,
  polygon,
  svgGroup,
  theme,
  isCurrentLasso = false,
}: RenderStaticLassoProps): void => {
  const translatedPolygon = polygon.map(point =>
    translateToChartPoint(point, chart),
  )

  const pointsString = translatedPolygon
    .map(point => `${point[0]},${point[1]}`)
    .join(' ')

  chart.renderer
    .createElement('polygon')
    .attr({
      points: pointsString,
      stroke: isCurrentLasso ? theme.colors.error : theme.colors.primary[100],
      'stroke-width': isCurrentLasso ? 2 : 1,
      fill: theme.colors.greyscale[isCurrentLasso ? 50 : 20],
      'fill-opacity': 0.3,
      'fill-rule': 'evenodd',
    })
    .add(svgGroup)
}
