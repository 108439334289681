import { Link } from 'react-router-dom'
import styled from 'styled-components'

import { Checkbox } from 'components/Checkbox'

type LicenseAgreementConfirmationProps = {
  isChecked: boolean
  licenseLink?: string
  onChange: () => void
}

export const LicenseAgreementConfirmation = ({
  isChecked,
  licenseLink,
  onChange,
}: LicenseAgreementConfirmationProps): JSX.Element => {
  return (
    <Container>
      <Checkbox checked={isChecked} onChange={onChange} />
      <span>
        I have read and signed the{' '}
        <Link
          to={{ pathname: licenseLink ?? 'https://metaflow.bio/licence-sign/' }}
          target="_blank"
        >
          License Agreement
        </Link>
      </span>
    </Container>
  )
}

const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  margin-top: ${props => props.theme.spacing(2)}px;
`
