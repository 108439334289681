import { skipToken } from '@reduxjs/toolkit/query'
import React, { FC, useMemo } from 'react'
import styled from 'styled-components'

import { LoadingMask } from 'components/LoadingMask'

import { useCreateEntriesMutation } from 'shared/api/logbook.api'
import {
  useCancelSignatureMutation,
  useCreateSignatureMutation,
  useGetSignaturesQuery,
} from 'shared/api/signatures.api'
import { useGetUserQuery } from 'shared/api/user.api'
import { LogActions } from 'shared/contexts/LogContext'
import { Signature, SignatureType } from 'shared/models/Signature'
import { useAppSelector } from 'shared/store'
import { handleError } from 'shared/utils/errorHandler'

import { selectAnalysisId } from '../store/selectors'
import { SignatureCancel } from './SignatureCancel'
import { SignatureCreate } from './SignatureCreate'

type SortedSignatures = {
  analysed: Signature | null
  reviewed: Signature | null
  approved: Signature | null
  inactiveAnalysed: Signature[]
}

export const SignaturesTable: FC = () => {
  const analysisId = useAppSelector(selectAnalysisId)

  const { data: user } = useGetUserQuery()
  const signaturesResult = useGetSignaturesQuery(analysisId ?? skipToken)
  const [createSignature, createSignatureResult] = useCreateSignatureMutation()
  const [cancelSignature, cancelSignatureResult] = useCancelSignatureMutation()
  const [createEntries, createEntriesResult] = useCreateEntriesMutation()

  const groupedSignatures = useMemo<SortedSignatures>(
    () => groupSignatures(signaturesResult.data),
    [signaturesResult.data],
  )

  const canCancelAnalysedSignature =
    groupedSignatures.analysed?.signed_by.id === user?.id &&
    groupedSignatures.reviewed === null

  const canCreateReviewOrApprovalSignature =
    user?.id !== groupedSignatures.analysed?.signed_by.id

  const handleCreateSignature = async (type: SignatureType) => {
    try {
      if (!analysisId) {
        throw new Error('Analysis is not defined')
      }

      await createSignature({ analysisId, type }).unwrap()
      await createEntries({
        analysisId,
        entries: [
          {
            type: {
              analysed: LogActions.l_analysis_e_signed,
              reviewed: LogActions.l_review_e_signed,
              approved: LogActions.l_approved_e_signed,
            }[type],
            created_at: new Date().toISOString(),
          },
        ],
      }).unwrap()
    } catch (error) {
      handleError(error)
    }
  }

  const handleCancelSignature = async (signatureId: string) => {
    try {
      if (!analysisId) {
        throw new Error('Analysis is not defined')
      }

      await cancelSignature({ analysisId, signatureId }).unwrap()
      await createEntries({
        analysisId,
        entries: [
          {
            type: LogActions.l_analysis_e_signed_cancelled,
            created_at: new Date().toISOString(),
          },
        ],
      }).unwrap()
    } catch (error) {
      handleError(error)
    }
  }

  const isLoading = [
    signaturesResult,
    createSignatureResult,
    cancelSignatureResult,
    createEntriesResult,
  ].some(result => result.isLoading)

  return (
    <>
      {!isLoading ? (
        <StyledTable>
          <thead>
            <tr>
              <StyledTh>Analysed</StyledTh>
              <StyledTh>Reviewed</StyledTh>
              <StyledTh>Approved</StyledTh>
            </tr>
          </thead>
          <tbody>
            <tr>
              {groupedSignatures.analysed ? (
                <td>
                  <SignatureCancel
                    signature={groupedSignatures.analysed}
                    onClick={handleCancelSignature}
                    disabled={!canCancelAnalysedSignature}
                  />
                </td>
              ) : (
                <td>
                  <SignatureCreate
                    type="analysed"
                    onClick={handleCreateSignature}
                  />
                </td>
              )}
              {groupedSignatures.reviewed ? (
                <ActiveSignature>
                  {groupedSignatures.reviewed.key}
                </ActiveSignature>
              ) : (
                <td>
                  <SignatureCreate
                    type="reviewed"
                    onClick={handleCreateSignature}
                    disabled={
                      groupedSignatures.analysed === null ||
                      !canCreateReviewOrApprovalSignature
                    }
                    disabledTooltipLabel={
                      groupedSignatures.analysed === null
                        ? 'You cannot review an analysis before it has been analysed.'
                        : 'You cannot review this analysis because you already analysed it.'
                    }
                  />
                </td>
              )}
              {groupedSignatures.approved ? (
                <ActiveSignature>
                  {groupedSignatures.approved.key}
                </ActiveSignature>
              ) : (
                <td>
                  <SignatureCreate
                    type="approved"
                    onClick={handleCreateSignature}
                    disabled={
                      groupedSignatures.reviewed === null ||
                      !canCreateReviewOrApprovalSignature
                    }
                    disabledTooltipLabel={
                      groupedSignatures.reviewed === null
                        ? 'You cannot approve an analysis before it has been reviewed.'
                        : 'You cannot approve this analysis because you already analysed it.'
                    }
                  />
                </td>
              )}
            </tr>
            {groupedSignatures.inactiveAnalysed.map(signature => (
              <tr key={signature.id}>
                <InactiveSignature>{signature.key}</InactiveSignature>
                <td />
                <td />
              </tr>
            ))}
          </tbody>
        </StyledTable>
      ) : (
        <LoadingWrapper>
          <LoadingMask />
        </LoadingWrapper>
      )}
    </>
  )
}

const groupSignatures = (signatures: Signature[] | undefined) => {
  if (!signatures) {
    return {
      analysed: null,
      reviewed: null,
      approved: null,
      inactiveAnalysed: [],
    }
  }

  const analysed =
    signatures.find(
      signature =>
        signature.type === 'analysed' && signature.canceled_at === null,
    ) ?? null
  const reviewed =
    signatures.find(signature => signature.type === 'reviewed') ?? null
  const approved =
    signatures.find(signature => signature.type === 'approved') ?? null

  return {
    analysed,
    reviewed,
    approved,
    inactiveAnalysed: signatures.filter(
      signature => signature.canceled_at !== null,
    ),
  }
}

const StyledTable = styled.table`
  table-layout: fixed;
  width: 100%;
  text-align: left;
  height: min-content;
  & td,
  & th {
    width: 33%;
    padding: 8px 12px;
    box-sizing: border-box;
  }
`

const StyledTh = styled.th`
  text-align: center;
  background-color: ${props => props.theme.colors.primaryDark[100]};
  color: ${props => props.theme.colors.white};
  font-size: ${props => props.theme.font.size.h3}px;
`

const ActiveSignature = styled.td`
  font-size: ${props => props.theme.font.size.h3}px;
`

const InactiveSignature = styled.td`
  font-size: ${props => props.theme.font.size.small}px;
  color: ${props => props.theme.colors.greyscale[70]};
`

const LoadingWrapper = styled.div`
  position: relative;
  flex: 1;
  height: 100%;
`
