type AnalysisWorker = typeof import('./analysis-worker')
type MetaAnalysisWorker = typeof import('./meta-analysis-worker')

export const analysisWorker = new ComlinkWorker<AnalysisWorker>(
  new URL('./analysis-worker', import.meta.url),
  {},
)

export const metaAnalysisWorker = new ComlinkWorker<MetaAnalysisWorker>(
  new URL('./meta-analysis-worker', import.meta.url),
  {},
)
