import { createSelector } from '@reduxjs/toolkit'
import { xor } from 'lodash'

import {
  selectActiveLeafIds,
  selectSelectedChannels,
  selectSelfOrganizingHeatMapQuery,
} from './analysis.selectors'
import { selectChannelsSortMode, selectClustersSortMode } from './ui.selectors'

export const selectIsSelfOrganizingHeatMapQueryStale = createSelector(
  selectSelfOrganizingHeatMapQuery,
  selectActiveLeafIds,
  selectSelectedChannels,
  selectClustersSortMode,
  selectChannelsSortMode,
  (
    query,
    selectedClusterIds,
    selectedChannels,
    clustersSortMode,
    channelsSortMode,
  ) => {
    const isSelfOrganazingHeatMapEnabled =
      clustersSortMode?.type === 'self-organize' ||
      channelsSortMode?.type === 'self-organize'

    const haveSelectedClusterIdsChanged =
      !query ||
      xor(selectedClusterIds, query.parameters.selectedClusterIds).length > 0

    const haveSelectedChannelsChanged =
      !query ||
      xor(selectedChannels, query.parameters.selectedChannels).length > 0

    return (
      isSelfOrganazingHeatMapEnabled &&
      (haveSelectedClusterIdsChanged || haveSelectedChannelsChanged)
    )
  },
)
