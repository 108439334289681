import { IconButton, Menu, MenuItem } from '@material-ui/core'
import { MoreVert } from '@material-ui/icons'
import { FC, ReactNode, useRef, useState } from 'react'
import styled from 'styled-components'

import { useEventCallback } from 'shared/hooks/useEventCallback'

interface OptionsContextMenuProps {
  options: {
    label: string
    textColor?: 'error' | 'success'
    disabled?: boolean
    onClick: ((event: React.MouseEvent) => void) | undefined
  }[]
  icon?: ReactNode
  className?: string
}

export const OptionsContextMenu: FC<OptionsContextMenuProps> = ({
  options,
  icon = <MoreVert />,
  className,
}) => {
  const [isOpen, setOpen] = useState(false)
  const iconRef = useRef(null)

  const handleOpen = useEventCallback((event: React.MouseEvent) => {
    event.stopPropagation()
    setOpen(true)
  })

  const handleClose = useEventCallback(() => {
    setOpen(false)
  })

  return (
    <>
      <IconButton
        className={className}
        color="primary"
        onClick={handleOpen}
        title="Options"
        ref={iconRef}
        disabled={options.length === 0}
      >
        {icon}
      </IconButton>

      <StyledMenu
        open={isOpen}
        onClose={handleClose}
        anchorEl={iconRef.current}
        transformOrigin={{
          vertical: -40,
          horizontal: 0,
        }}
        elevation={0}
        disablePortal={true}
      >
        {options.map(option => (
          <StyledMenuItem
            key={option.label}
            onClick={event => {
              option.onClick?.(event)
              handleClose()
            }}
            disabled={option.disabled}
            $color={option.textColor}
          >
            {option.label}
          </StyledMenuItem>
        ))}
      </StyledMenu>
    </>
  )
}

const StyledMenu = styled(Menu)`
  > .MuiPaper-elevation0 {
    box-shadow: 2px 2px 10px 2px rgba(0, 0, 0, 0.1);
  }

  > .MuiPaper-rounded {
    border-radius: ${props => props.theme.radius[2]}px;
  }
`

const StyledMenuItem = styled(MenuItem)<{ $color?: string }>`
  min-width: 100px;
  padding: ${props => props.theme.spacing(2)}px;
  font-size: ${props => props.theme.font.size.smallest}px;
  color: ${props =>
    props.$color
      ? props.theme.colors[props.$color]
      : props.theme.colors.primaryDark[100]};

  &:not(:last-child) {
    border-bottom: 1px solid ${props => props.theme.colors.greyscale[10]};
  }
`
