import { Typography } from '@material-ui/core'
import styled from 'styled-components'

import { CircularProgress } from 'components/CircularProgress'
import { EditableLabel } from 'components/EditableLabel'
import { Header } from 'components/Header'

import {
  useGetProjectQuery,
  useUpdateProjectMutation,
} from 'shared/api/projects.api'
import { formatDateTime } from 'shared/utils/utils'

import { SectionHeader } from './SectionHeader'

type AboutProps = {
  projectId: string
}

export const About: React.FC<AboutProps> = ({ projectId }) => {
  const { data: project } = useGetProjectQuery(projectId)
  const [triggerUpdateProjectMutation] = useUpdateProjectMutation()

  const handleUpdate = ({
    name,
    description,
  }: {
    name?: string
    description?: string
  }) => {
    if (!project) {
      throw new Error('Project not found')
    }
    triggerUpdateProjectMutation({
      id: projectId,
      name: name ?? project.name,
      description: description ?? project.description,
    })
  }

  return (
    <AboutRoot>
      <Header />
      <Main>
        <SectionHeader title="About this project" />
        {project ? (
          <ProjectInfo>
            <EditableLabel
              value={project.name}
              onChange={value => {
                handleUpdate({ name: value })
              }}
            />
            <ProjectAuthor>
              <b>
                {project.author
                  ? `${project.author.first_name} ${project.author.last_name}`
                  : ''}
              </b>
              <ProjectEmail>
                {project.author ? project.author.email : ''}
              </ProjectEmail>
            </ProjectAuthor>
            <StyledEditableLabel
              value={project.description ?? ''}
              onChange={value => {
                handleUpdate({ description: value })
              }}
              emptyLabel="Add description"
            />
            <StyledTypography variant="body2">{`Created: ${formatDateTime(
              project.created_at,
            )}`}</StyledTypography>
            <StyledTypography variant="body2">{`Files: ${project.num_files}`}</StyledTypography>
            <StyledTypography variant="body2">{`Experiments: ${project.num_experiments}`}</StyledTypography>
          </ProjectInfo>
        ) : (
          <CircularProgress />
        )}
      </Main>
    </AboutRoot>
  )
}

const AboutRoot = styled.div`
  margin: 60px 100px;
`

const Main = styled.main``

const ProjectInfo = styled.div`
  display: flex;
  flex-direction: column;
  padding: ${props => props.theme.spacing(4)}px
    ${props => props.theme.spacing(2)}px;
  gap: ${props => props.theme.spacing(2)}px;
  margin-top: ${props => props.theme.spacing(2)}px;
  background: ${props => props.theme.colors.white};
  border-radius: ${props => props.theme.radius[4]}px;
`

export const ProjectAuthor = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: ${props => props.theme.spacing(1)}px 0 0
    ${props => props.theme.spacing(4)}px;
`

export const ProjectEmail = styled.p`
  color: ${props => props.theme.colors.primaryDark[50]};
  font-size: ${props => props.theme.font.size.smallest}px;
  font-family: ${props => props.theme.font.style.regular};
`

const StyledEditableLabel = styled(EditableLabel)`
  .label {
    overflow: auto;
    white-space: normal;
  }
  p {
    color: ${props => props.theme.colors.primaryDark['50']};
    font-size: ${props => props.theme.font.size.small}px;
  }
  input {
    font-size: ${props => props.theme.font.size.small}px;
  }
  button {
    align-self: start;
  }
`

const StyledTypography = styled(Typography)`
  margin-left: ${props => props.theme.spacing(4)}px;
`
