import { FC } from 'react'
import styled from 'styled-components'

import { ComputeScatterPlotSeriesReturnValue } from 'shared/worker/meta-analysis-worker'

type MetaAnalysisScatterPlotTooltipProps = {
  seriesId: string
  metadataBySeriesId:
    | ComputeScatterPlotSeriesReturnValue['metadataBySeriesId']
    | undefined
}

export const MetaAnalysisScatterPlotTooltip: FC<
  MetaAnalysisScatterPlotTooltipProps
> = ({ seriesId, metadataBySeriesId }) => {
  if (!metadataBySeriesId) {
    return null
  }

  const fileName = metadataBySeriesId[seriesId].fileName
  const count = metadataBySeriesId[seriesId].numberOfEvents
  const color = metadataBySeriesId[seriesId].color

  return (
    <MetaAnalysisScatterPlotTooltipRoot>
      <FileLine>
        <FileName>{fileName}</FileName>
        <ColorDot $color={color}>⬤</ColorDot>
      </FileLine>
      <Details>
        <div>{count} events</div>
      </Details>
    </MetaAnalysisScatterPlotTooltipRoot>
  )
}

const MetaAnalysisScatterPlotTooltipRoot = styled.div`
  padding: 8px;
  font-size: 13px;
  min-width: 120;
  background: ${props => props.theme.colors.white};
`

const FileLine = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`

const ColorDot = styled.span<{ $color: string }>`
  color: ${props => props.$color};
  margin-right: 4px;
  font-size: 14px;
`

const FileName = styled.div`
  font-size: 12px;
  color: ${props => props.theme.colors.primaryDark[70]};
`

const Details = styled.div`
  font-size: 12px;
  margin-top: 6px;
  color: ${props => props.theme.colors.primaryDark[70]};
`
