import {
  ServerStyleSheets,
  CssBaseline,
  ThemeProvider as MuiThemeProvider,
} from '@material-ui/core'
import { Base64 } from 'js-base64'
import { ReactNode } from 'react'
import ReactDOMServer from 'react-dom/server'
import {
  ServerStyleSheet,
  ThemeProvider as StyledComponentsThemeProvider,
} from 'styled-components'

import { theme } from 'Theme'

export const ANALYSIS_CHART_WIDTH = 500
export const ANALYSIS_CHART_HEIGHT = 300

export const generateHtml = async (node: ReactNode): Promise<string> => {
  const muiSheet = new ServerStyleSheets()
  const styledComponentsSheet = new ServerStyleSheet()

  const bodyContent = ReactDOMServer.renderToStaticMarkup(
    styledComponentsSheet.collectStyles(
      muiSheet.collect(
        <MuiThemeProvider theme={theme}>
          <StyledComponentsThemeProvider theme={theme}>
            <CssBaseline />
            {node}
          </StyledComponentsThemeProvider>
        </MuiThemeProvider>,
      ),
    ),
  )

  const html = `
        <html>
          <head>
            <style>${muiSheet.toString()}</style>
            ${styledComponentsSheet.getStyleTags()}
          </head>
          <body>
            <div id="root">${bodyContent}</div>
          </body>
        </html>
      `

  styledComponentsSheet.seal()

  return html
}

export const generateEncodedHtml = async (node: ReactNode): Promise<string> => {
  const html = await generateHtml(node)
  return Base64.encode(html)
}

export const showLoadingMask = (message: string): (() => void) => {
  const loadingMaskElement = document.createElement('div')
  loadingMaskElement.style.position = 'fixed'
  loadingMaskElement.style.width = '100vw'
  loadingMaskElement.style.height = '100vh'
  loadingMaskElement.style.left = '0'
  loadingMaskElement.style.top = '0'
  loadingMaskElement.style.pointerEvents = 'none'
  loadingMaskElement.style.zIndex = '10000'
  loadingMaskElement.style.backdropFilter = 'blur(1px)'
  loadingMaskElement.style.display = 'grid'
  loadingMaskElement.style.placeItems = 'center'
  loadingMaskElement.style.fontSize = '16px'
  loadingMaskElement.style.fontWeight = 'bold'
  loadingMaskElement.style.background = 'rgba(255, 255, 255, 0.5)'
  const messageElement = document.createElement('span')
  messageElement.innerText = message
  messageElement.style.textShadow = '1px 0 10px white'
  loadingMaskElement.appendChild(messageElement)
  document.body.appendChild(loadingMaskElement)
  document.body.style.pointerEvents = 'none'

  return () => {
    document.body.removeChild(loadingMaskElement)
    document.body.style.pointerEvents = 'auto'
  }
}
