import { Typography } from '@material-ui/core'
import { filesize } from 'filesize'
import { useState } from 'react'
import styled from 'styled-components'

import { ReactComponent as FilesIcon } from 'assets/images/icons/fcs-file.svg'

import { CircularProgress } from 'components/CircularProgress'
import { Button } from 'components/button/Button'
import { SearchInput } from 'components/input/SearchInput'
import { Modal } from 'components/modal/Modal'
import { ModalContainer } from 'components/modal/ModalContainer'
import { EmptyTableInfo } from 'components/table/EmptyTableInfo'
import { TableColumn, TableRow } from 'components/table/Table'
import { TableWithPagination } from 'components/table/TableWithPagination'
import {
  DateFilter,
  DateFilterValue,
} from 'components/table/filters/DateFilter'
import { MultipleSelectFilterWithPagination } from 'components/table/filters/MultipleSelectFilterWithPagination'
import { NumberRangeFilter } from 'components/table/filters/NumberRangeFilter'
import { SelectFilter } from 'components/table/filters/SelectFilter'
import { useMultipleSelectFilter } from 'components/table/filters/useMultipleSelectFilter'
import { useTableSelection } from 'components/table/useTableSelection'
import { useTableSorting } from 'components/table/useTableSorting'

import {
  useAddFilesToExperimentMutation,
  useCheckFilesChannelsForExperimentMutation,
} from 'shared/api/experiments.api'
import {
  CompensatedFileStatus,
  FileStatus,
  useGetCompensatedFileAuthorsQuery,
  useGetCompensatedFileBatchNamesQuery,
  useGetCompensatedFileIdsQuery,
  useGetCompensatedFilesQuery,
} from 'shared/api/files.api'
import { useDialog } from 'shared/contexts/DialogContext'
import { formatDateTime } from 'shared/utils/utils'

import { StatusChip } from './StatusChip'

type AddFileToExperimentProps = {
  projectId: string
  experimentId: string
  onClose: () => void
}

export const AddFileToExperiment: React.FC<AddFileToExperimentProps> = ({
  projectId,
  experimentId,
  onClose,
}) => {
  const [searchValue, setSearchValue] = useState('')
  const [dateFilterValue, setDateFilterValue] =
    useState<DateFilterValue>(undefined)
  const [sizeFilterValue, setSizeFilterValue] = useState<
    [number | undefined, number | undefined]
  >([undefined, undefined])
  const [selectedStatus, setSelectedStatus] = useState<
    `${CompensatedFileStatus}` | undefined
  >()
  const { showConfirmationDialog } = useDialog()
  const [page, setPage] = useState(1)

  const { sortingColumn, sortingDirection, orderingString, setSorting } =
    useTableSorting('file_name', 'desc')

  const [triggerAddFilesToExperiment] = useAddFilesToExperimentMutation()
  const [
    triggerCheckFilesChannelsForExperiment,
    checkFilesChannelsForExperimentState,
  ] = useCheckFilesChannelsForExperimentMutation()

  const {
    selectedIds: selectedBatchNames,
    page: batchNamesFilterPage,
    filterSearchValue: batchNamesFilterSearchValue,
    handleSelectOption: handleSelectBatchName,
    handlePageChange: handleBatchNamesFilterPageChange,
    handleFilterSearchValueChange: handleBatchNamesFilterSearchValueChange,
  } = useMultipleSelectFilter()

  const {
    selectedIds: selectedAuthorIds,
    page: authorsFilterPage,
    filterSearchValue: authorsFilterSearchValue,
    handleSelectOption: handleSelectAuthor,
    handleFilterSearchValueChange: handleAuthorsFilterSearchValueChange,
    handlePageChange: handleAuthorsFilterPageChange,
  } = useMultipleSelectFilter()

  const compensatedFilesQueryFilters = {
    name: searchValue,
    batch_name: selectedBatchNames.join(','),
    author: selectedAuthorIds.join(','),
    created_at_after: dateFilterValue?.[0]?.toISOString(),
    created_at_before: dateFilterValue?.[1]?.toISOString(),
    size_min: sizeFilterValue[0] ? sizeFilterValue[0] * 1000 : undefined,
    size_max: sizeFilterValue[1] ? sizeFilterValue[1] * 1000 : undefined,
    status: selectedStatus,
  }

  const getCompensatedFilesQueryState = useGetCompensatedFilesQuery({
    projectId,
    page,
    ordering: orderingString,
    search: compensatedFilesQueryFilters,
  })

  const getCompensatedFilesByExperimentIdQueryState =
    useGetCompensatedFilesQuery({
      projectId,
      page,
      ordering: orderingString,
      search: { ...compensatedFilesQueryFilters, experiment: experimentId },
    })

  const compensatedFileIdsQueryState = useGetCompensatedFileIdsQuery({
    search: {
      ...compensatedFilesQueryFilters,
      project: projectId,
      status: CompensatedFileStatus.Ready,
    },
  })

  const getCompensatedFileBatchNamesQueryState =
    useGetCompensatedFileBatchNamesQuery(
      {
        projectId,
        page: batchNamesFilterPage,
        search: {
          lookup_batch_name: batchNamesFilterSearchValue,
        },
      },
      { refetchOnMountOrArgChange: true },
    )

  const getCompensatedFileAuthorsQueryState = useGetCompensatedFileAuthorsQuery(
    {
      projectId,
      page: authorsFilterPage,
      search: {
        lookup_author: authorsFilterSearchValue,
      },
    },
  )

  const {
    selectedIds,
    areAllSelected,
    handleSelect,
    handleSelectAll,
    handleSelectCurrentPage,
  } = useTableSelection({
    allIds: compensatedFileIdsQueryState.data ?? [],
    currentPageIds:
      getCompensatedFilesQueryState.data?.results
        .filter(file => file.status === FileStatus.Ready)
        .map(file => file.id) ?? [],
  })

  const columns: TableColumn[] = [
    {
      key: 'batch_name',
      label: 'Batch Name',
      canSort: true,
      style: { width: 100 },
    },
    {
      key: 'file_name',
      label: 'File name',
      style: { width: '25%' },
      canSort: true,
    },
    {
      key: 'status',
      label: 'Status',
      canSort: false,
    },
    {
      key: 'total_cell_count',
      label: 'Total cell count',
      style: { width: '10%' },
      canSort: true,
    },
    {
      key: 'author',
      label: 'Author',
      style: { width: '20%' },
      canSort: true,
    },
    {
      key: 'size',
      label: 'Size',
      style: { width: '5%' },
      canSort: true,
    },
    {
      key: 'analyses',
      label: 'Analyses',
      style: { width: '10%' },
      canSort: true,
    },
    {
      key: 'sample_acquisition_date',
      label: 'Sample Acquisition',
      style: { width: '20%' },
      canSort: true,
    },
  ]

  const rows: TableRow[] =
    getCompensatedFilesQueryState.data?.results.map(file => ({
      key: file.id,
      shouldDisableCheckbox:
        getCompensatedFilesByExperimentIdQueryState.data?.results.some(
          ({ id }) => id === file.id,
        ),
      cells: [
        {
          key: 'batch_name',
          content: file.batch_name,
        },
        {
          key: 'fileName',
          content: file.name,
        },
        {
          key: 'status',
          title: file.status,
          content: <StatusChip status={file.status} />,
        },
        {
          key: 'cellCount',
          content: 0,
        },
        {
          key: 'author',
          content: file.author_name,
        },
        {
          key: 'size',
          content: filesize((file.fcs_file_size ?? 0) * 1000),
        },
        {
          key: 'analyses',
          content: 0,
        },
        {
          key: 'sampleAcquisition',
          content: formatDateTime(file.fcs_file_acquisition_date),
        },
      ],
    })) ?? []

  const selectedIdsWithCurrentPage = selectedIds.filter(
    id =>
      getCompensatedFilesByExperimentIdQueryState.data?.results.filter(
        file => file.id === id,
      ).length === 0,
  )

  const handleAddFilesToExperiment = async () => {
    const checkFilesChannelsResult =
      await triggerCheckFilesChannelsForExperiment({
        experimentId,
        files: [
          ...selectedIds,
          ...(getCompensatedFilesByExperimentIdQueryState.data?.results.map(
            file => file.id,
          ) ?? []),
        ],
      }).unwrap()

    if (checkFilesChannelsResult.warnings) {
      const channels = checkFilesChannelsResult.all_missing_channels ?? []
      showConfirmationDialog({
        title: 'Are you sure you want to add these files?',
        message: `Some of the selected compensated files do not have the following channels from the experiment: ${channels.join(
          ', ',
        )}. Do you want to proceed anyway?`,
        onConfirm: async () => {
          await triggerAddFilesToExperiment({
            experimentId,
            fileIds: selectedIdsWithCurrentPage,
          })
          onClose()
        },
      })
    } else {
      await triggerAddFilesToExperiment({
        experimentId,
        fileIds: selectedIds,
      })
      onClose()
    }
  }

  return (
    <Modal
      open
      title="Add files to experiment"
      onClose={onClose}
      Container={StyledModalContainer}
    >
      <StepRoot>
        <StepTitle variant="h6" align="center">
          Select compensated files
        </StepTitle>
        <TableFilters>
          <SearchInput placeholder="Search files" onChange={setSearchValue} />
          <MultipleSelectFilterWithPagination
            title="Batch name"
            placeholder="Search batch names"
            options={
              getCompensatedFileBatchNamesQueryState.data?.results.map(
                batchName => ({ id: batchName, label: batchName }),
              ) ?? []
            }
            selectedIds={selectedBatchNames.filter(id => id !== '1')}
            onSelect={handleSelectBatchName}
            page={batchNamesFilterPage}
            pageCount={
              getCompensatedFileBatchNamesQueryState.data?.page_count ?? 1
            }
            onPageChange={handleBatchNamesFilterPageChange}
            onSearchValueChange={handleBatchNamesFilterSearchValueChange}
          />
          <DateFilter value={dateFilterValue} onChange={setDateFilterValue} />
          <MultipleSelectFilterWithPagination
            title="Author"
            placeholder="Search authors"
            options={
              getCompensatedFileAuthorsQueryState.data?.results.map(user => ({
                id: user.id,
                label: user.username,
              })) ?? []
            }
            selectedIds={selectedAuthorIds}
            onSelect={handleSelectAuthor}
            page={authorsFilterPage}
            pageCount={
              getCompensatedFileAuthorsQueryState.data?.page_count ?? 1
            }
            onPageChange={handleAuthorsFilterPageChange}
            onSearchValueChange={handleAuthorsFilterSearchValueChange}
          />
          <NumberRangeFilter
            title="Size"
            value={sizeFilterValue}
            onChange={setSizeFilterValue}
          />
          <SelectFilter
            title="Status"
            options={Object.keys(CompensatedFileStatus).map(key => ({
              id: key,
              label: key,
            }))}
            selectedId={selectedStatus}
            onSelect={id =>
              setSelectedStatus(id as `${CompensatedFileStatus}` | undefined)
            }
          />
        </TableFilters>
        {getCompensatedFilesQueryState.isLoading ? (
          <CircularProgress />
        ) : getCompensatedFilesQueryState.isSuccess ? (
          <StyledTableWithPagination
            rows={rows}
            columns={columns}
            selectedElements={selectedIdsWithCurrentPage}
            areAllSelected={areAllSelected}
            isSelectionDisabled={getCompensatedFilesQueryState.data.results.every(
              file => file.status !== FileStatus.Ready,
            )}
            onToggleSelection={handleSelect}
            onToggleSelectAll={handleSelectAll}
            onToggleSelectAllEntriesFromCurrentPage={handleSelectCurrentPage}
            sortingColumn={sortingColumn}
            sortingDirection={sortingDirection}
            onSortingChange={setSorting}
            page={page}
            pageCount={getCompensatedFilesQueryState.data.page_count}
            onPageChange={(_event, page) => setPage(page)}
          />
        ) : (
          <StyledEmptyTableInfo
            text="No files found"
            Icon={<StyledFilesIcon />}
          />
        )}
      </StepRoot>
      <Actions>
        <Button onClick={onClose} colorOverride="greyscale">
          Close
        </Button>
        <Button
          onClick={handleAddFilesToExperiment}
          loading={checkFilesChannelsForExperimentState.isLoading}
          disabled={!selectedIds.length}
        >
          Upload
        </Button>
      </Actions>
    </Modal>
  )
}

const StyledModalContainer = styled(ModalContainer)`
  min-width: 700px;
  font-size: 13px;
  max-height: 90vh;
`

const StepRoot = styled.div`
  min-width: 600px;
  display: flex;
  flex-direction: column;
  padding: ${props => props.theme.spacing(2)}px;
  background: ${props => props.theme.colors.primary[10]};
  border-radius: ${props => props.theme.radius[4]}px;
`
const StepTitle = styled(Typography)`
  margin-bottom: ${props => props.theme.spacing(2)}px;
`

const TableFilters = styled.div`
  display: flex;
  align-items: center;
  gap: ${props => props.theme.spacing(2)}px;
  margin: ${props => props.theme.spacing(4)}px 0;
`

const StyledEmptyTableInfo = styled(EmptyTableInfo)`
  margin-top: ${props => props.theme.spacing(8)}px;
`

const StyledFilesIcon = styled(FilesIcon)`
  width: 160px;
  height: 160px;
  stroke: ${props => props.theme.colors.greyscale[20]};
  stroke-width: 0.1;
  & > path {
    fill: ${props => props.theme.colors.greyscale[20]};
  }
`
const StyledTableWithPagination = styled(TableWithPagination)`
  display: block;
  overflow-y: auto;
`

const Actions = styled.div`
  margin-top: 24px;
  display: flex;
  gap: 0.5rem;
`
