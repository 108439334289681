import { useMemo } from 'react'

import { useAppSelector } from 'shared/store'

import { ChartScales, selectChartScales } from './store/selectors'

export type UseChartScales = {
  xMax: number
  xMin: number
  yMin?: number
  yMax?: number
}

export const useChartScales = (chartId: string): UseChartScales => {
  const chartScales = useAppSelector(selectChartScales)

  return useMemo(
    () => getChartScales(chartId, chartScales),
    [chartId, chartScales],
  )
}

const getChartScales = (
  chartId: string,
  chartScales: ChartScales,
): UseChartScales => {
  const xMax = chartScales[chartId]?.xAxis.max ?? 0
  const xMin = chartScales[chartId]?.xAxis.min ?? 0
  const yMin = chartScales[chartId]?.yAxis?.min
  const yMax = chartScales[chartId]?.yAxis?.max

  return {
    xMax,
    xMin,
    yMin,
    yMax,
  }
}
