import Highcharts from 'highcharts'

import { AnalysisScatterPlotBaseOptions } from 'components/graphs/AnalysisScatterPlotBaseOptions'
import {
  computeSeriesImageDataURL,
  renderSeriesImage,
} from 'components/graphs/high-performance-scatter-plot.utils'

import { drawGates } from 'pages/analysis/drawGates'
import { renderStaticLasso } from 'pages/analysis/lasso/renderStaticLasso'
import {
  selectAnalysisLassos,
  selectAnalysisScatterplotSeriesComputationParams,
  selectChannelDisplayNames,
  selectClusterColorById,
} from 'pages/analysis/store/selectors'

import { Graph } from 'shared/models/Graphs'
import { RootState } from 'shared/store'
import { analysisWorker } from 'shared/worker'

import { Theme } from 'Theme'

type RenderScatterplotChartProps = {
  state: RootState
  chart: Graph
  width: number
  height: number
  shouldShowAxisLabels?: boolean
  theme: Theme
}

type RenderScatterplotChartResult = {
  chartId: string
  svg: string | undefined
  title: string
  xAxis: string
  yAxis: string
  width: number
  height: number
}

export const renderScatterplotChart = async ({
  state,
  chart,
  width,
  height,
  shouldShowAxisLabels,
  theme,
}: RenderScatterplotChartProps): Promise<RenderScatterplotChartResult> => {
  if (!chart.y_axis) {
    throw new Error('Chart does not have a y axis')
  }
  const lassos = selectAnalysisLassos(state)
  const computationsParams =
    selectAnalysisScatterplotSeriesComputationParams(state)[chart.id]
  if (!computationsParams) {
    throw new Error('Could not find chart params for chartId: ' + chart.id)
  }
  const channelDisplayNames = selectChannelDisplayNames(state)
  const colorByClusterId = selectClusterColorById(state)

  computationsParams.chart.scale =
    await analysisWorker.computeLogarithmicScaleWorkaround(computationsParams)

  if (!computationsParams.chart.scale) {
    return {
      chartId: chart.id,
      svg: undefined,
      title: chart.name,
      xAxis: chart.x_axis,
      yAxis: chart.y_axis,
      width,
      height,
    }
  }

  const renderActiveLassos = (highchartsChart: Highcharts.Chart) => {
    for (const lassoId in chart.lasso_ids) {
      if (chart.lasso_ids[lassoId]) {
        const lasso = lassos[lassoId]
        if (!lasso) {
          throw new Error('Could not find lasso with id: ' + lassoId)
        }
        renderStaticLasso({
          chart: highchartsChart,
          polygon: lasso.polygon,
          theme,
        })
      }
    }
  }

  const highchartsChart = new Highcharts.Chart(document.createElement('div'), {
    ...AnalysisScatterPlotBaseOptions,
    chart: {
      ...AnalysisScatterPlotBaseOptions.chart,
      height,
      width,
    },
    series: [
      {
        type: 'scatter',
        data: [],
      },
    ],
    xAxis: {
      ...AnalysisScatterPlotBaseOptions.xAxis,
      ...computationsParams.chart.scale.xAxis,
      type: chart.x_axis_scale_type,
      title: {
        ...AnalysisScatterPlotBaseOptions.xAxis.title,
        text: shouldShowAxisLabels
          ? channelDisplayNames[chart.x_axis]
          : undefined,
      },
      labels: {
        style: {
          fontFamily: 'Arial',
        },
      },
    },
    yAxis: {
      ...AnalysisScatterPlotBaseOptions.yAxis,
      ...computationsParams.chart.scale.yAxis,
      type: chart.y_axis_scale_type,
      title: {
        ...AnalysisScatterPlotBaseOptions.yAxis.title,
        text: shouldShowAxisLabels
          ? channelDisplayNames[chart.y_axis]
          : undefined,
      },
      labels: {
        style: {
          fontFamily: 'Arial',
        },
      },
    },
  })

  const seriesIdByPixelPosition =
    await analysisWorker.computeScatterPlotSeriesIdsByPixelPosition({
      chart: {
        ...computationsParams.chart,
        scale: computationsParams.chart.scale,
      },
      plotSize: {
        width: highchartsChart.plotWidth,
        height: highchartsChart.plotHeight,
      },
    })

  const imageDataUrl = computeSeriesImageDataURL({
    width: highchartsChart.plotWidth,
    height: highchartsChart.plotHeight,
    seriesIdByPixelPosition,
    colorBySeriesId: colorByClusterId,
  })

  highchartsChart.options.chart!.events = {
    render: function (this: Highcharts.Chart) {
      renderSeriesImage(this, imageDataUrl)

      drawGates(this, chart.gates, chart.zoom)
      renderActiveLassos(this)
    },
  }

  return {
    chartId: chart.id,
    svg: highchartsChart.getSVG(),
    title: chart.name,
    xAxis: chart.x_axis,
    yAxis: chart.y_axis,
    width,
    height,
  }
}
