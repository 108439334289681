import { useState } from 'react'
import styled from 'styled-components'

import { ReactComponent as PipelinesIcon } from 'assets/images/icons/pipelines.svg'

import { CircularProgress } from 'components/CircularProgress'
import { Header } from 'components/Header'
import { EmptyTableInfo } from 'components/table/EmptyTableInfo'
import { TableColumn, TableRow } from 'components/table/Table'
import { TableWithPagination } from 'components/table/TableWithPagination'

import { useGetWorkflowsWithPipelinesQuery } from 'shared/api/workflows.api'
import { formatDate } from 'shared/utils/utils'

import { PipelinesInnerTable } from './PipelinesInnerTable'
import { SectionHeader } from './SectionHeader'

type PipelinesProps = {
  projectId: string
}

export const Pipelines: React.FC<PipelinesProps> = ({ projectId }) => {
  const [page, setPage] = useState(1)

  const getWorkflowsWithPipelinesQueryState = useGetWorkflowsWithPipelinesQuery(
    { projectId, page },
  )

  const columns: TableColumn[] = [
    {
      key: 'name',
      label: 'Name',
      style: { width: 200 },
    },
    {
      key: 'status',
      label: 'Status',
      style: {
        width: 100,
      },
    },
    {
      key: 'actions',
      label: 'Actions',
      style: { width: 300 },
    },
    {
      key: 'created_at',
      label: 'Creation date',
      style: { width: 140 },
    },
    {
      key: 'version',
      label: 'Version',
      style: {
        width: 120,
      },
    },
    {
      key: 'author',
      label: 'Author',
      style: {
        width: 120,
      },
    },
    {
      key: 'description',
      label: 'Description',
      style: { width: 240 },
    },
  ]

  const rows: TableRow[] =
    getWorkflowsWithPipelinesQueryState.data?.results.map(pipeline => ({
      key: pipeline.id,
      cells: [
        {
          key: 'name',
          content: pipeline.name,
        },
        {
          key: 'status',
          content: '',
        },
        {
          key: 'actions',
          content: '',
        },
        {
          key: 'created_at',
          content: formatDate(pipeline.created_at),
        },
        ...['version', 'author', 'description'].map(key => ({
          key,
          content: '',
        })),
      ],
      children: (
        <PipelinesInnerTable
          projectId={projectId}
          workflowId={pipeline.id}
          columns={columns}
        />
      ),
    })) ?? []

  return (
    <PipelinesRoot>
      <Header />
      <Main>
        <SectionHeader title="Pipelines" />
        <TableWrapper>
          {getWorkflowsWithPipelinesQueryState.isLoading ? (
            <CircularProgress />
          ) : getWorkflowsWithPipelinesQueryState.data?.results.length ? (
            <TableWithPagination
              page={page}
              pageCount={getWorkflowsWithPipelinesQueryState.data.page_count}
              onPageChange={(_event, page) => setPage(page)}
              columns={columns}
              rows={rows}
            />
          ) : (
            <StyledEmptyTableInfo
              text="No files found"
              Icon={<StyledPipelinesIcon />}
            />
          )}
        </TableWrapper>
      </Main>
    </PipelinesRoot>
  )
}

const PipelinesRoot = styled.div`
  margin: 60px 100px;
`

const Main = styled.main``

const TableWrapper = styled.div`
  max-width: calc(100vw - 320px - 160px);
  overflow-y: auto;
  padding-bottom: ${props => props.theme.spacing(2)}px;
  & table {
    min-width: max-content;
  }
`

const StyledEmptyTableInfo = styled(EmptyTableInfo)`
  margin-top: ${props => props.theme.spacing(8)}px;
`

const StyledPipelinesIcon = styled(PipelinesIcon)`
  width: 160px;
  height: 160px;
  stroke: ${props => props.theme.colors.greyscale[20]};
  stroke-width: 0.1;
  & path {
    fill: ${props => props.theme.colors.greyscale[20]};
  }
`
