import { Graph } from 'shared/models/Graphs'
import { getTextWidth } from 'shared/utils/utils'

import { theme as _theme } from 'Theme'

export const drawGates = (
  chart: Highcharts.Chart,
  gates: Graph.Gate[],
  zoom: Graph['zoom'],
  theme = _theme,
): (() => void) | undefined => {
  function _getGateValues(gate: Graph.Gate) {
    const isValidated = gate.tempValues === undefined
    const xMin = (isValidated ? gate.xMin : gate.tempValues?.xMin) ?? 0
    const xMax = (isValidated ? gate.xMax : gate.tempValues?.xMax) ?? 0
    const yMin = (isValidated ? gate.yMin : gate.tempValues?.yMin) ?? 0
    const yMax = (isValidated ? gate.yMax : gate.tempValues?.yMax) ?? 0

    return {
      xMin,
      xMax,
      yMin,
      yMax,
    }
  }
  if (!chart.renderer) {
    return
  }

  const mainGroup = chart.renderer.g().add().toFront()
  const textNodes: Highcharts.SVGElement[] = []

  // both yAxis & xAxis contains the width of the graph
  const xAxis = chart.xAxis[0]
  const yAxis = chart.yAxis[0]
  gates
    .filter(g => {
      // we only display gates where :
      // - the gate is not hidden
      // - that the gate's coordinates are within the zoom's coordinates, if the correspond graph is zoomed

      const { xMin, xMax, yMin, yMax } = _getGateValues(g)
      return (
        !g.hidden &&
        !(
          !!zoom &&
          (xMin < zoom.x_min ||
            xMax > zoom.x_max ||
            yMin < zoom.y_min ||
            yMax > zoom.y_max)
        )
      )
    })
    .forEach(gate => {
      const { xMin, xMax, yMin, yMax } = _getGateValues(gate)
      const x = xAxis.toPixels(xMin, false)
      const y = yAxis.toPixels(yMax, false)
      const width = xAxis.toPixels(xMax, false) - xAxis.toPixels(xMin, false)
      const height = yAxis.toPixels(yMin, false) - yAxis.toPixels(yMax, false)
      if (
        width > 0 &&
        height > 0 &&
        !(gate.type === 'polygon' && gate.points.length === 0)
      ) {
        const xMaxPixels = xAxis.toPixels(xMax, false)
        const yMaxPixels = yAxis.toPixels(yMax, true)
        const xAxisMaxPixels = xAxis.toPixels(xAxis.max || 0, false)
        const yAxisMaxPixels = yAxis.toPixels(xAxis.max || 0, true)
        const isGateTouchingRightChartBorder = xMaxPixels === xAxisMaxPixels
        const isGateTouchingTopChartBorder = yMaxPixels - 3 <= yAxisMaxPixels

        let gateLabelWidth = 0
        if (isGateTouchingRightChartBorder) {
          gateLabelWidth =
            getTextWidth(
              gate.name +
                (gate.percentage ? `: ${gate.percentage.toFixed(1)}%` : ''),
              '"Lucida Grande"',
            ) || 0
        }
        const gateGroup = chart.renderer
          .g(gate.name)
          .attr({ zIndex: 999 })
          .add(mainGroup)
        const text = chart.renderer
          .text(
            // prettier-ignore
            `
              <span title="${gate.name}" style="display: flex; color: ${theme.colors.white}; font-weight: bold; font-size: 10px;">
                <span style="color: ${theme.colors.white}; max-width: 40px; white-space: nowrap; text-overflow: ellipsis; overflow: hidden; display: block;">
                  ${gate.name}
                </span>
                <span style="color: ${theme.colors.white}">
                  ${!isNaN(Number(gate.percentage))
                    ? `: ${gate.percentage?.toFixed(1)}%`
                    : ''
                  }
                </span>
              </span>
            `,
            isGateTouchingRightChartBorder
              ? xMaxPixels - (gateLabelWidth > 80 ? 80 : gateLabelWidth)
              : x,
            isGateTouchingTopChartBorder ? y + 8 : y - 8,
            true,
          )
          .add(gateGroup)
        const textBBox = text.getBBox(true)
        const textContainer = chart.renderer
          .rect(
            textBBox.x - 5,
            textBBox.y - 5,
            textBBox.width + 10,
            textBBox.height + 10,
          )
          .attr({
            rx: 2,
            rt: 2,
          })
          .css({
            fill: theme.colors.primary[100],
          })
        if (gate.type === 'rectangle' || !gate.type) {
          const gateRect = chart.renderer
            .rect(x, y, width, height, 0, 0.5)
            .css({
              stroke: theme.colors.primary[100],
              fill: 'none',
            })
          gateRect.add(gateGroup)
        } else if (gate.type === 'polygon') {
          const pointsString = gate.points.reduce((acc, p, index) => {
            if (index === 0) {
              return `${xAxis.toPixels(p.x, false)},${yAxis.toPixels(
                p.y,
                false,
              )}`
            }
            return acc.concat(
              ` ${xAxis.toPixels(p.x, false)},${yAxis.toPixels(p.y, false)}`,
            )
          }, '')
          const gatePolygon = chart.renderer.createElement('polygon').attr({
            points: pointsString,
            stroke: theme.colors.primary[100],
            'stroke-width': 1,
            fill: theme.colors.greyscale[20],
            'fill-opacity': 0.3,
            'fill-rule': 'evenodd',
            zIndex: -1,
          })
          gatePolygon.add(gateGroup)
        }
        textContainer.add(gateGroup)
        textNodes.push(text)
      }
    })

  return () => {
    textNodes.forEach(text => text.destroy())
    mainGroup.destroy()
  }
}
