import { useState } from 'react'

import { useEventCallback } from 'shared/hooks/useEventCallback'

type UseMultipleSelectFilterResult = {
  selectedIds: string[]
  filterSearchValue: string
  page: number
  handleSelectOption: (string: string) => void
  handleFilterSearchValueChange: (value: string) => void
  handlePageChange: (page: number) => void
}

export const useMultipleSelectFilter = (): UseMultipleSelectFilterResult => {
  const [page, setPage] = useState(1)
  const [selectedOptionIds, setSelectedOptionIds] = useState<string[]>([])
  const [filterSearchValue, setFilterSearchValue] = useState('')

  const handleSelectOption = useEventCallback((string: string) => {
    setSelectedOptionIds(
      selectedOptionIds.includes(string)
        ? selectedOptionIds.filter(selectedString => selectedString !== string)
        : [...selectedOptionIds, string],
    )
  })

  return {
    selectedIds: selectedOptionIds,
    page,
    handleSelectOption,
    filterSearchValue,
    handleFilterSearchValueChange: setFilterSearchValue,
    handlePageChange: setPage,
  }
}
