import { Payment } from 'shared/models/LicenseModels'
import { PaginatedResults } from 'shared/models/Result'

import { privateApi } from './private.api'
import { encodeTagParameters } from './utils'

export const paymentsApi = privateApi.injectEndpoints({
  endpoints: builder => ({
    getPayments: builder.query<GetPaymentsResult, void>({
      query: () => ({
        url: '/payment-transactions/',
      }),
      providesTags: [
        { type: 'Payment', id: encodeTagParameters({ id: 'list' }) },
      ],
    }),
  }),
})

export const { useGetPaymentsQuery } = paymentsApi

type GetPaymentsResult = PaginatedResults<Payment[]>
