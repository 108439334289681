import { filesize } from 'filesize'
import { useState } from 'react'
import { useBeforeUnload } from 'react-use'
import styled from 'styled-components'

import { ReactComponent as CompensationMatrixIcon } from 'assets/images/icons/compensation-matrix.svg'
import { ReactComponent as FilesIcon } from 'assets/images/icons/fcs-file.svg'
import { ReactComponent as UploadFileIcon } from 'assets/images/icons/upload-file.svg'

import { CircularProgress } from 'components/CircularProgress'
import { OptionsContextMenu } from 'components/OptionsContextMenu'
import { UserWidget } from 'components/UserWidget'
import { Button } from 'components/button/Button'
import { SearchInput } from 'components/input/SearchInput'
import { EmptyTableInfo } from 'components/table/EmptyTableInfo'
import { TableColumn, TableRow } from 'components/table/Table'
import { TableWithPagination } from 'components/table/TableWithPagination'
import {
  DateFilter,
  DateFilterValue,
} from 'components/table/filters/DateFilter'
import { MultipleSelectFilterWithPagination } from 'components/table/filters/MultipleSelectFilterWithPagination'
import { NumberRangeFilter } from 'components/table/filters/NumberRangeFilter'
import { SelectFilter } from 'components/table/filters/SelectFilter'
import { useMultipleSelectFilter } from 'components/table/filters/useMultipleSelectFilter'
import { useTableSelection } from 'components/table/useTableSelection'
import { useTableSorting } from 'components/table/useTableSorting'
import { UploadBanner } from 'components/upload-banner/UploadBanner'

import {
  FcsFile,
  FileStatus,
  useDeleteFcsFileMutation,
  useGetFcsFileAuthorsQuery,
  useGetFcsFileBatchNamesQuery,
  useGetFcsFileIdsQuery,
  useGetFcsFilesQuery,
  useLazyDownloadFcsFileQuery,
} from 'shared/api/files.api'
import { useModal } from 'shared/contexts/ModalContext'
import { useEventCallback } from 'shared/hooks/useEventCallback'
import { useAppSelector } from 'shared/store'
import { downloadFile, formatDateTime, includeIf } from 'shared/utils/utils'

import { ApplyCompensationMatrixModal } from './ApplyCompensationMatrixModal'
import { FcsFileRenameModal } from './FcsFileRenameModal'
import { FcsFileUploadModal } from './FcsFileUploadModal'
import { SectionHeader } from './SectionHeader'
import { StatusChip } from './StatusChip'
import { selectIsAnyFcsFileBeingUploaded } from './store/ui.selectors'

type RawFcsFilesProps = {
  projectId: string
}

export const RawFcsFiles: React.FC<RawFcsFilesProps> = ({ projectId }) => {
  const isAnyFcsFileBeingUploaded = useAppSelector(
    selectIsAnyFcsFileBeingUploaded,
  )

  const [page, setPage] = useState(1)
  const [searchValue, setSearchValue] = useState<string | undefined>(undefined)
  const [dateFilterValue, setDateFilterValue] =
    useState<DateFilterValue>(undefined)
  const [sizeFilterValue, setSizeFilterValue] = useState<
    [number | undefined, number | undefined]
  >([undefined, undefined])
  const [selectedStatus, setSelectedStatus] = useState<
    `${FileStatus}` | undefined
  >()

  const { showModal } = useModal()

  useBeforeUnload(isAnyFcsFileBeingUploaded)

  const { sortingColumn, sortingDirection, orderingString, setSorting } =
    useTableSorting('created_at', 'desc')

  const {
    selectedIds: selectedBatchNames,
    page: batchNamesFilterPage,
    filterSearchValue: batchNamesFilterSearchValue,
    handleSelectOption: handleSelectBatchName,
    handlePageChange: handleBatchNamesFilterPageChange,
    handleFilterSearchValueChange: handleBatchNamesFilterSearchValueChange,
  } = useMultipleSelectFilter()

  const {
    selectedIds: selectedAuthorIds,
    page: authorsFilterPage,
    filterSearchValue: authorsFilterSearchValue,
    handleSelectOption: handleSelectAuthor,
    handleFilterSearchValueChange: handleAuthorsFilterSearchValueChange,
    handlePageChange: handleAuthorsFilterPageChange,
  } = useMultipleSelectFilter()

  const fcsFilesQueryFilters = {
    file_name: searchValue,
    batch_name: selectedBatchNames.join(','),
    author: selectedAuthorIds.join(','),
    created_at_after: dateFilterValue?.[0]?.toISOString(),
    created_at_before: dateFilterValue?.[1]?.toISOString(),
    size_min: sizeFilterValue[0] ? sizeFilterValue[0] * 1000 : undefined,
    size_max: sizeFilterValue[1] ? sizeFilterValue[1] * 1000 : undefined,
    status: selectedStatus,
  }
  const getFcsFilesQueryState = useGetFcsFilesQuery({
    projectId,
    ordering: orderingString,
    page,
    search: fcsFilesQueryFilters,
  })

  const fcsFileIdsQuery = useGetFcsFileIdsQuery({
    search: {
      ...fcsFilesQueryFilters,
      project: projectId,
      status: FileStatus.Ready,
    },
  })

  const {
    selectedIds: selectedElements,
    areAllSelected,
    handleSelect,
    handleSelectCurrentPage,
    handleSelectAll,
  } = useTableSelection({
    allIds: fcsFileIdsQuery.data ?? [],
    currentPageIds:
      getFcsFilesQueryState.data?.results
        .filter(file => file.status === FileStatus.Ready)
        .map(file => file.id) ?? [],
  })

  const getFcsFileBatchNamesQueryState = useGetFcsFileBatchNamesQuery(
    {
      projectId,
      page: batchNamesFilterPage,
      search: {
        lookup_batch_name: batchNamesFilterSearchValue,
      },
    },
    { refetchOnMountOrArgChange: true },
  )

  const getFcsFileAuthorsQueryState = useGetFcsFileAuthorsQuery({
    projectId,
    page: authorsFilterPage,
    search: {
      lookup_author: authorsFilterSearchValue,
    },
  })

  const [triggerDownloadFcsFileQuery] = useLazyDownloadFcsFileQuery()
  const [triggerDeleteFcsFileMutation] = useDeleteFcsFileMutation()

  const handleShowUploadModal = useEventCallback(() => {
    showModal(FcsFileUploadModal, { projectId })
  })

  const handleShowApplyCompensationMatrixModal = useEventCallback(() => {
    showModal(ApplyCompensationMatrixModal, {
      projectId,
      selectedFcsFileIds: selectedElements,
    })
  })

  const handleRename = (file: FcsFile) => () => {
    showModal(FcsFileRenameModal, { file })
  }

  const handleDownload = (id: string) => () => {
    triggerDownloadFcsFileQuery(id)
      .unwrap()
      .then(file => {
        downloadFile(file.url)
      })
  }

  const handleDelete = (id: string) => () => {
    triggerDeleteFcsFileMutation(id)
  }

  const columns: TableColumn[] = [
    {
      key: 'batch_name',
      label: 'Batch Name',
      canSort: true,
      style: { width: 100 },
    },
    {
      key: 'file_name',
      label: 'File name',
      canSort: true,
    },
    {
      key: 'status',
      label: 'Status',
      canSort: true,
      style: { width: 0 },
    },
    {
      key: 'actions',
      label: '',
      canSort: false,
    },
    {
      key: 'total_cell_count',
      label: 'Total cell count',
      canSort: true,
      style: { width: 92 },
    },
    {
      key: 'author',
      label: 'Author',
      canSort: true,
    },
    {
      key: 'size',
      label: 'Size',
      canSort: true,
      style: { whiteSpace: 'nowrap' },
    },
    {
      key: 'analyses',
      label: 'Analyses',
      canSort: true,
      style: { width: 0 },
    },
    {
      key: 'sample_acquisition_date',
      label: 'Sample Acquisition',
      canSort: true,
      style: { width: 0 },
    },
  ]

  const rows: TableRow[] =
    getFcsFilesQueryState.data?.results.map(file => ({
      key: file.id,
      shouldDisableCheckbox: file.status !== FileStatus.Ready,
      cells: [
        {
          key: 'batch_name',
          content: file.batch_name,
        },
        {
          key: 'fileName',
          content: file.file_name,
        },
        {
          key: 'status',
          title: file.status,
          content: <StatusChip status={file.status} />,
        },
        {
          key: 'actions',
          content: (
            <OptionsContextMenu
              options={[
                {
                  label: 'Rename',
                  onClick: handleRename(file),
                },
                {
                  label: 'Copy ID',
                  onClick: () =>
                    navigator.clipboard.writeText(`fcs-file:${file.id}`),
                },
                ...includeIf(file.status === FileStatus.Error, [
                  {
                    label: 'Delete',
                    textColor: 'error' as const,
                    onClick: handleDelete(file.id),
                  },
                ]),
                ...includeIf(file.status === FileStatus.Ready, [
                  {
                    label: 'Download',
                    onClick: handleDownload(file.id),
                  },
                ]),
              ]}
            />
          ),
        },
        {
          key: 'cellCount',
          content: file.total_cell_count,
        },
        {
          key: 'author',
          content: file.author_name,
        },
        {
          key: 'size',
          content: filesize(file.size * 1000),
        },
        {
          key: 'analyses',
          content: file.analyses_count,
        },
        {
          key: 'sampleAcquisition',
          content: formatDateTime(file.acquisition_date),
        },
      ],
    })) ?? []

  return (
    <RawFcsFilesRoot>
      <Header>
        {isAnyFcsFileBeingUploaded ? <UploadBanner /> : <span />}
        <UserWidget />
      </Header>
      <Main>
        <SectionHeader title="Raw FCS Files">
          <SectionHeaderButtonsContainer>
            <SectionHeaderActionButton
              colorOverride="success"
              onClick={handleShowUploadModal}
            >
              <UploadFileIcon />
              Upload files
            </SectionHeaderActionButton>
            <SectionHeaderActionButton
              disabled={selectedElements.length === 0}
              title={
                selectedElements.length === 0
                  ? 'Please select at least one file'
                  : ''
              }
              onClick={handleShowApplyCompensationMatrixModal}
            >
              <CompensationMatrixIcon />
              Apply compensation or unmix
            </SectionHeaderActionButton>
          </SectionHeaderButtonsContainer>
        </SectionHeader>
        {getFcsFilesQueryState.isLoading ? (
          <CircularProgress />
        ) : (
          <>
            <TableFilters>
              <SearchInput
                placeholder="Search files"
                onChange={setSearchValue}
              />
              <MultipleSelectFilterWithPagination
                title="Batch name"
                placeholder="Search batch names"
                options={
                  getFcsFileBatchNamesQueryState.data?.results.map(
                    batchName => ({ id: batchName, label: batchName }),
                  ) ?? []
                }
                selectedIds={selectedBatchNames}
                onSelect={handleSelectBatchName}
                page={batchNamesFilterPage}
                pageCount={getFcsFileBatchNamesQueryState.data?.page_count ?? 1}
                onPageChange={handleBatchNamesFilterPageChange}
                onSearchValueChange={handleBatchNamesFilterSearchValueChange}
              />
              <DateFilter
                value={dateFilterValue}
                onChange={setDateFilterValue}
              />
              <MultipleSelectFilterWithPagination
                title="Author"
                placeholder="Search authors"
                options={
                  getFcsFileAuthorsQueryState.data?.results.map(user => ({
                    id: user.id,
                    label: user.username,
                  })) ?? []
                }
                selectedIds={selectedAuthorIds}
                onSelect={handleSelectAuthor}
                page={authorsFilterPage}
                pageCount={getFcsFileAuthorsQueryState.data?.page_count ?? 1}
                onPageChange={handleAuthorsFilterPageChange}
                onSearchValueChange={handleAuthorsFilterSearchValueChange}
              />
              <NumberRangeFilter
                title="Size"
                value={sizeFilterValue}
                onChange={setSizeFilterValue}
              />
              <SelectFilter
                title="Status"
                options={Object.keys(FileStatus).map(key => ({
                  id: key,
                  label: key,
                }))}
                selectedId={selectedStatus}
                onSelect={id =>
                  setSelectedStatus(id as `${FileStatus}` | undefined)
                }
              />
            </TableFilters>
            {getFcsFilesQueryState.data?.results.length ? (
              <TableWithPagination
                rows={rows}
                columns={columns}
                sortingColumn={sortingColumn}
                sortingDirection={sortingDirection}
                onSortingChange={setSorting}
                selectedElements={selectedElements}
                areAllSelected={areAllSelected}
                isSelectionDisabled={getFcsFilesQueryState.data?.results.every(
                  file => file.status !== FileStatus.Ready,
                )}
                page={page}
                pageCount={getFcsFilesQueryState.data.page_count}
                onToggleSelection={handleSelect}
                onToggleSelectAll={handleSelectAll}
                onToggleSelectAllEntriesFromCurrentPage={
                  handleSelectCurrentPage
                }
                onPageChange={(_event, page) => setPage(page)}
              />
            ) : (
              <StyledEmptyTableInfo
                text="No files found"
                Icon={<StyledFilesIcon />}
              />
            )}
          </>
        )}
      </Main>
    </RawFcsFilesRoot>
  )
}

const RawFcsFilesRoot = styled.div`
  margin: 60px 100px;
`

const Main = styled.main``

const Header = styled.div`
  display: flex;
  justify-content: space-between;
`

const SectionHeaderButtonsContainer = styled.div`
  display: flex;
  gap: ${props => props.theme.spacing(2)}px;
`

const SectionHeaderActionButton = styled(Button)`
  height: 48px;
  padding: 0 ${props => props.theme.spacing(2)}px;

  & > span > svg {
    margin-right: ${props => props.theme.spacing(1)}px;
  }
`

const StyledFilesIcon = styled(FilesIcon)`
  width: 160px;
  height: 160px;
  stroke: ${props => props.theme.colors.greyscale[20]};
  stroke-width: 0.1;
  & > path {
    fill: ${props => props.theme.colors.greyscale[20]};
  }
`

const TableFilters = styled.div`
  display: flex;
  align-items: center;
  gap: ${props => props.theme.spacing(2)}px;
  margin: ${props => props.theme.spacing(4)}px 0;
`

const StyledEmptyTableInfo = styled(EmptyTableInfo)`
  margin-top: ${props => props.theme.spacing(8)}px;
`
