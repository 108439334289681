import { Typography } from '@material-ui/core'
import styled from 'styled-components'

import { Button } from 'components/Button'
import { Modal } from 'components/Modal'
import { AnalysisLassosHierarchyGraph } from 'components/graphs/AnalysisLassosHierarchyGraph'

import { theme } from 'Theme'

type DeleteLassoDialogProps = {
  selectedLassoId: string
  onConfirm: () => void
  onCancel: () => void
}

export const DeleteLassoDialog: React.FC<DeleteLassoDialogProps> = ({
  selectedLassoId,
  onConfirm,
  onCancel,
}) => {
  return (
    <Modal open={true} onClose={onCancel}>
      <Container>
        <Typography variant="h6">
          Confirm deletion of selected lasso and its descendants
        </Typography>
        <GraphWrapper>
          <AnalysisLassosHierarchyGraph
            selectedLassoLabelColor={theme.colors.error}
            selectedLassoId={selectedLassoId}
            minimalMode={true}
          />
        </GraphWrapper>
        <WarningMessage variant="body2">
          This will delete all lassos marked in red and all graphs created from
          those lassos
        </WarningMessage>
        <ButtonContainer>
          <Button onClick={onConfirm}>Confirm</Button>
          <Button grey onClick={onCancel}>
            Cancel
          </Button>
        </ButtonContainer>
      </Container>
    </Modal>
  )
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
  gap: ${props => props.theme.spacing(2)}px;
  max-width: 600px;
  max-height: 480px;
  padding: ${props => props.theme.spacing(3)}px;
  background: white;
  border-radius: ${props => props.theme.radius[2]}px;
`

const GraphWrapper = styled.div`
  width: 300px;
  height: 300px;
`

const WarningMessage = styled(Typography)`
  color: ${props => props.theme.colors.error};
`

const ButtonContainer = styled.div`
  display: flex;
  justify-content: center;
  gap: ${props => props.theme.spacing(2)}px;
`
