import { createContext, useContext } from 'react'

export type ModalContextValue = {
  showModal: <P extends { onClose: () => void }>(
    component: React.ComponentType<P>,
    props: Omit<P, 'onClose'>,
    options?: {
      shouldCloseOnRouteChange?: boolean
    },
  ) => void
}

export const ModalContext = createContext<ModalContextValue | undefined>(
  undefined,
)

export const useModal = (): ModalContextValue => {
  const modalContext = useContext(ModalContext)
  if (modalContext === undefined) {
    throw new Error('useModal must be used within a ModalContextProvider')
  }

  return modalContext
}
