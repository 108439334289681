import { Tooltip } from '@material-ui/core'
import { useState } from 'react'
import styled from 'styled-components'

import { Button } from 'components/Button'
import { Checkbox, CheckboxProps } from 'components/Checkbox'

import { useAppSelector } from 'shared/store'

import {
  selectMetaAnalysisClusters,
  selectMetaAnalysisFcsFileIds,
  selectMetaAnalysisFcsFileNameById,
} from './store/selectors'

type MetaAnalysisGlobalHeatMapChartOptionsProps = {
  mode: 'edit' | 'create'
  initialSelectedFileIds: string[]
  initialSelectedClusters: string[]
  onCancel?: () => void
  onFinish: (fileIds: string[], clusters: string[]) => void
  className?: string
}

export const MetaAnalysisGlobalHeatMapChartOptions = ({
  mode,
  initialSelectedFileIds,
  initialSelectedClusters,
  onCancel,
  onFinish,
  className,
}: MetaAnalysisGlobalHeatMapChartOptionsProps): JSX.Element => {
  const fileIds = useAppSelector(selectMetaAnalysisFcsFileIds)
  const clusters = useAppSelector(selectMetaAnalysisClusters)
  const fileNameById = useAppSelector(selectMetaAnalysisFcsFileNameById)

  const [selectedFileIds, setSelectedFileIds] = useState(initialSelectedFileIds)
  const [selectedClusters, setSelectedClusters] = useState(
    initialSelectedClusters,
  )

  const areAllFilesSelected =
    selectedFileIds.length === fileIds.length && fileIds.length > 0

  const areAllClustersSelected =
    selectedClusters.length === clusters.length && clusters.length > 0

  const handleSelectFile = (selectedFileId: string) => {
    if (selectedFileIds.includes(selectedFileId)) {
      setSelectedFileIds(
        selectedFileIds.filter(fileId => fileId !== selectedFileId),
      )
    } else {
      setSelectedFileIds([...selectedFileIds, selectedFileId])
    }
  }

  const handleSelectCluster = (cluster: string) => {
    if (selectedClusters.includes(cluster)) {
      setSelectedClusters(selectedClusters.filter(c => c !== cluster))
    } else {
      setSelectedClusters([...selectedClusters, cluster])
    }
  }

  return (
    <MetaAnalysisGlobalHeatMapChartOptionsRoot className={className}>
      <Options>
        <Header>Files</Header>
        <AllCheckboxContainer>
          <Checkbox
            label="Select all files"
            checked={areAllFilesSelected}
            onChange={() =>
              areAllFilesSelected
                ? setSelectedFileIds([])
                : setSelectedFileIds(fileIds)
            }
          />
        </AllCheckboxContainer>
        <Files>
          {fileIds.map(fileId => {
            const fileName = fileNameById[fileId]
            return (
              <StyledCheckbox
                key={fileId}
                label={
                  <Tooltip title={fileName}>
                    <Ellipsis>{fileName}</Ellipsis>
                  </Tooltip>
                }
                checked={selectedFileIds.includes(fileId)}
                onChange={() => handleSelectFile(fileId)}
              />
            )
          })}
        </Files>
        <Header>Clusters</Header>
        <AllCheckboxContainer>
          <Checkbox
            label="Select all clusters"
            checked={areAllClustersSelected}
            onChange={() =>
              areAllClustersSelected
                ? setSelectedClusters([])
                : setSelectedClusters(clusters)
            }
          />
        </AllCheckboxContainer>
        <Checkboxes>
          {clusters.map(cluster => (
            <StyledCheckbox
              key={cluster}
              label={
                <Tooltip title={cluster}>
                  <Ellipsis>{cluster}</Ellipsis>
                </Tooltip>
              }
              checked={selectedClusters.includes(cluster)}
              onChange={() => handleSelectCluster(cluster)}
            />
          ))}
        </Checkboxes>
      </Options>
      <Buttons>
        <StyledButton
          onClick={() => onFinish(selectedFileIds, selectedClusters)}
        >
          {mode === 'edit' ? 'Apply' : 'Create'}
        </StyledButton>
        <StyledButton onClick={onCancel}>Cancel</StyledButton>
      </Buttons>
    </MetaAnalysisGlobalHeatMapChartOptionsRoot>
  )
}

const MetaAnalysisGlobalHeatMapChartOptionsRoot = styled.div`
  background: ${props => props.theme.colors.white};
  width: 100%;
  height: 100%;
  overflow: hidden;
  display: grid;
  grid-template-rows: 1fr auto;
`

const Checkboxes = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 2px;
  padding: 8px;
  padding-bottom: 16px;
`

const Files = styled(Checkboxes)`
  grid-template-columns: repeat(2, 1fr);
`

const StyledCheckbox = styled(({ className, ...props }: CheckboxProps) => (
  <Checkbox containerClassName={className} {...props} />
))`
  width: 100%;
  overflow: hidden;
`

const Options = styled.div`
  overflow-y: auto;
  overflow-x: hidden;
  padding: 12px;
`

const Buttons = styled.div`
  border-top: 1px solid ${props => props.theme.colors.primaryDark[20]};
  padding: 8px;
  display: flex;
  flex-direction: row-reverse;
`

const StyledButton = styled(Button)`
  height: 16px;
  font-size: 12px;

  :first-of-type {
    margin-left: 8px;
  }
`

const Header = styled.p`
  font-family: ${props => props.theme.font.style.bold};
`

const Ellipsis = styled.div`
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`

const AllCheckboxContainer = styled.div`
  border-bottom: 1px solid ${props => props.theme.colors.primaryDark[20]};
  padding: ${props => props.theme.spacing(1)}px;
`
