import { SerializedError } from '@reduxjs/toolkit'
import { FetchBaseQueryError } from '@reduxjs/toolkit/dist/query'
import { snakeCase } from 'lodash'
import { useCallback, useMemo, useState } from 'react'

import { unpackError } from 'shared/api/utils'

export const useServerFormValidation = <T extends Record<string, unknown>>({
  error,
  fields,
}: {
  error: FetchBaseQueryError | SerializedError | undefined
  fields: (keyof T)[]
}): {
  getFieldRequestError: (name: string, value: unknown) => string | undefined
  globalError?: string
  setSubmittedValues: React.Dispatch<React.SetStateAction<T | undefined>>
} => {
  const [submittedValues, setSubmittedValues] = useState<T>()

  const errors = useMemo(():
    | {
        fields?: Record<string, string>
        global?: string
      }
    | undefined => {
    if (!error || !submittedValues) {
      return undefined
    }
    const { data } = unpackError(error)

    if (typeof data === 'string') {
      return { global: data }
    }

    if (data.detail) {
      return { fields: {}, global: JSON.stringify(data.detail) }
    }

    return {
      fields: Object.fromEntries(
        fields.map(field => {
          const errors = data[snakeCase(field as string)]
          return [
            field,
            Array.isArray(errors) ? errors.join('. ') : JSON.stringify(errors),
          ]
        }),
      ),
      global: Array.isArray(data.non_field_errors)
        ? data.non_field_errors?.join('. ')
        : undefined,
    }
  }, [error, fields, submittedValues])

  const getFieldRequestError = useCallback(
    (name: string, value: unknown) => {
      if (value !== submittedValues?.[name]) {
        return undefined
      }

      return errors?.fields?.[name]
    },
    [errors?.fields, submittedValues],
  )

  return {
    globalError: errors?.global,
    getFieldRequestError,
    setSubmittedValues,
  }
}
