import { matchPath, useHistory } from 'react-router'

import {
  selectAnalysisProject,
  selectAnalysisWorkflowEntity,
  selectIsAnalysisSaved,
} from 'pages/analysis/store/selectors'
import {
  selectIsMetaAnalysisSaved,
  selectMetaAnalysisProject,
  selectMetaAnalysisWorkflowEntity,
} from 'pages/meta-analysis/store/selectors'

import { useDialog } from 'shared/contexts/DialogContext'
import { useAppSelector } from 'shared/store'
import { getIsMultiTabMode } from 'shared/utils/multi-tab'

import { useEventCallback } from './useEventCallback'
import { sendCloseTabRequest } from './useMultiTab'

export const useIsAnalysisPage = (): boolean => {
  const history = useHistory()
  return !!matchPath(history.location.pathname, '/analysis/:id')
}

export const useIsMetaAnalysisPage = (): boolean => {
  const history = useHistory()
  return !!matchPath(history.location.pathname, '/meta-analysis/:id')
}

export const useSafeNavigate = (): ((
  url: string,
  options?: { shouldOpenInNewTab: boolean },
) => void) => {
  const isAnalysisSaved = useAppSelector(selectIsAnalysisSaved)
  const isMetaAnalysisSaved = useAppSelector(selectIsMetaAnalysisSaved)
  const isAnalysisPage = useIsAnalysisPage()
  const isMetaAnalysisPage = useIsMetaAnalysisPage()

  const history = useHistory()
  const dialog = useDialog()

  return useEventCallback(
    (url: string, { shouldOpenInNewTab } = { shouldOpenInNewTab: false }) => {
      if (shouldOpenInNewTab) {
        window.open(url)
        return
      }

      const proceed = () => {
        if (getIsMultiTabMode()) {
          sendCloseTabRequest()
        }
        history.push(url)
      }

      if (
        (isAnalysisPage && !isAnalysisSaved) ||
        (isMetaAnalysisPage && !isMetaAnalysisSaved)
      ) {
        dialog.showConfirmationDialog({
          title: 'You have unsaved work, are you sure you want to leave?',
          message: '',
          onConfirm: proceed,
        })
      } else {
        proceed()
      }
    },
  )
}

export const useAnalysisExitUrl = (): string => {
  const project = useAppSelector(selectAnalysisProject)
  const workflowEntity = useAppSelector(selectAnalysisWorkflowEntity)

  return `/projects/${project.id}/${workflowEntity}s`
}

export const useMetaAnalysisExitUrl = (): string => {
  const project = useAppSelector(selectMetaAnalysisProject)
  const workflowEntity = useAppSelector(selectMetaAnalysisWorkflowEntity)

  return `/projects/${project.id}/${workflowEntity}s`
}
