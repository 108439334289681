import React, { FC, ReactNode } from 'react'
import styled from 'styled-components'

export const ErrorBoxAlert: FC<{ children: ReactNode }> = ({ children }) => {
  return <ErrorAlert>{children}</ErrorAlert>
}

const ErrorAlert = styled.div`
  display: flex;
  padding: 10px 16px;
  font-size: ${props => props.theme.font.size.small}px;
  border-radius: ${props => props.theme.radius[1]}px;
  color: ${props => props.theme.colors.error};
  background-color: ${props => props.theme.colors.errorLight};
`
