import { unstable_createMuiStrictModeTheme as createMuiTheme } from '@material-ui/core/styles'

// TODO: Remove unstable createMuiTheme
import { fonts } from 'assets/fonts/fonts'

const appTheme = {
  colors: {
    primary: {
      100: '#476DF6',
      70: '#7E99F9',
      50: '#A3B6FB',
      20: '#DAE2FD',
      10: '#EDF0FE',
    },
    primaryLight: {
      100: '#7ECFFA',
      70: '#A5DDFB',
      50: '#BFE7FC',
      20: '#E5F5FE',
      10: '#F2FAFF',
    },
    primaryDark: {
      100: '#1A2784',
      70: '#5F68A9',
      50: '#8C93C1',
      20: '#D1D4E6',
      10: '#FAFAFB',
    },
    secondary: {
      100: '#67D881',
      70: '#95E4A7',
      50: '#B3EBC0',
      20: '#E1F7E6',
      10: '#F0FBF2',
    },
    success: '#67C57E',
    successLight: '#7AEB97',
    successLighter: '#E1F3E5',
    info: '#53AEF6',
    infoLight: '#DDEFFD',
    warning: '#FFAE00',
    warningLight: '#FFD470',
    warningLighter: '#FDF3DC',
    error: '#EA5439',
    errorLight: '#FA9E8C',
    errorLighter: '#FBDDD7',
    background: '#F8F9FF',
    white: '#FFFFFF',
    greyscale: {
      100: '#131632',
      70: '#5A5C70',
      60: '#717384',
      50: '#898B98',
      30: '#B8B9C2',
      20: '#D0D0D6',
      10: '#E7E8EB',
      5: '#F3F3F5',
    },
    modal: 'rgba(19, 22, 50, 0.4)',
    gold: '#FFD700',
    cellBackground: '#222F40',
  },
  font: {
    style: {
      regular: 'OpenSans-Regular',
      bold: 'OpenSans-Bold',
      light: 'OpenSans-Light',
    },
    size: {
      //TODO: add px?
      h1: 36,
      h2: 20,
      h3: 18,
      regular: 16,
      small: 14,
      smallest: 12,
      sub: 10,
    },
    spacing: {
      figures: 0.2,
    },
  },
  shadow: {
    1: '0px 4px 10px rgba(232, 233, 243, 0.6)',
    2: '0px 10px 20px rgba(232, 233, 243, 0.6)',
    3: '0px 20px 40px rgba(232, 233, 243, 0.8)',
    4: '0px 30px 60px rgba(232, 233, 243, 0.8)',
    tooltip: '0px 2px 4px -2px rgba(0, 0, 0, 0.2)',
  },
  radius: {
    //TODO: add px?
    1: 3,
    2: 8,
    3: 12,
    4: 16,
  },
  layout: {
    //TODO: add px?
    headerHeight: 60,
    contentWidth: 1200,
    contentPadding: 30,
    listHeaderMargins: '16px 0 50px',
    modalWidth: 930,
    graphMargin: 10,
  },
  gradients: {
    header:
      'linear-gradient(180deg, rgba(248,249,255,1) 0%, rgba(248,249,255,1) 90%, rgba(248,249,255,0.5) 100%)',
  },
}

const muiTheme = createMuiTheme({
  typography: {
    fontFamily: 'OpenSans-Regular, Roboto, Arial',
    fontSize: 16,
    body2: {
      fontSize: 14,
    },
    h1: {
      fontSize: '2.2rem',
    },
    h2: {
      fontSize: '2em',
    },
    h3: {
      fontSize: '1.8rem',
    },
    h4: {
      fontSize: '1.4rem',
    },
    h5: {
      fontSize: '1.2rem',
    },
    h6: {
      fontSize: '1rem',
    },
  },
  palette: {
    primary: {
      main: appTheme.colors.primaryDark['100'],
      light: appTheme.colors.primaryLight['100'],
      dark: appTheme.colors.primaryDark['100'],
    },
  },
  overrides: {
    MuiCssBaseline: {
      '@global': {
        body: {
          overflow: 'auto',
          backgroundColor: appTheme.colors.background,
          color: appTheme.colors.primaryDark['100'],
        },
        '@font-face': Object.entries(fonts).map(([, fontFace]) => fontFace),
        p: {
          margin: 0,
        },
      },
    },
    MuiCheckbox: {
      colorPrimary: {
        '&.Mui-checked': {
          '&:hover': { backgroundColor: 'transparent!important' },
        },
      },
      root: {
        color: appTheme.colors.primaryDark['100'],
        '&:hover': {
          backgroundColor: 'transparent',
        },
      },
    },
    MuiFormControlLabel: {
      label: {
        fontSize: 14,
      },
    },
    MuiRadio: {
      colorPrimary: {
        '&.Mui-checked': {
          '&:hover': { backgroundColor: 'transparent!important' },
        },
      },
      root: {
        color: appTheme.colors.primaryDark['100'],
        '&:hover': {
          backgroundColor: 'transparent',
        },
      },
    },
    MuiButton: {
      root: {
        textTransform: 'none',
        position: 'static',
      },
      endIcon: {
        marginLeft: 10,
      },
      startIcon: {
        marginRight: 10,
      },
    },
    MuiList: {
      padding: {
        paddingTop: 5,
        paddingBottom: 5,
      },
    },
    MuiListItem: {
      root: {
        '&.Mui-selected': {
          backgroundColor: appTheme.colors.primary[20],
          '&:hover': {
            backgroundColor: appTheme.colors.primary[20],
          },
        },
      },
      button: {
        '&:hover': {
          backgroundColor: appTheme.colors.background,
        },
      },
    },
    MuiSelect: {
      select: {
        '&:focus': {
          backgroundColor: 'transparent',
        },
      },
    },
    MuiOutlinedInput: {
      root: {
        '&:hover': {
          '& .MuiOutlinedInput-notchedOutline': {
            borderColor: `${appTheme.colors.primaryDark[50]}`,
          },
        },
      },
      notchedOutline: {
        borderColor: appTheme.colors.primaryDark[20],
        borderRadius: appTheme.radius[1],
      },
    },
    MuiSnackbarContent: {
      root: {
        '@media (min-width: 600px)': {
          minWidth: 0,
        },
      },
    },
    MuiMenu: {
      list: {
        padding: 0,
      },
      paper: {
        transition: 'none !important',
      },
    },
    MuiTableCell: {
      root: {
        padding: '4px',
        borderBottom: `1px solid ${appTheme.colors.greyscale[10]}`,
      },
      head: {
        fontFamily: appTheme.font.style.bold,
        color: appTheme.colors.primaryDark[100],
      },
      body: {
        color: appTheme.colors.primaryDark[100],
        fontSize: '13px',
      },
    },
  },
  props: {
    MuiButtonBase: {
      disableRipple: true,
    },
    MuiRadio: {
      disableRipple: true,
      size: 'small',
    },
  },
})

export type AppTheme = typeof appTheme

export const theme = {
  ...muiTheme,
  ...appTheme,
}

export type Theme = typeof theme

export const CUMULATIVE_FLUORESCENCE_CHART_SERIES_COLORS = {
  keep: '#316188',
  drop: '#000000',
}
