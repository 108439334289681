import { Link, Typography } from '@material-ui/core'
import React, { useMemo, useState } from 'react'
import { useHistory } from 'react-router'
import styled from 'styled-components'

import { Button } from 'components/Button'

import { useGetLicenseWalletQuery } from 'shared/api/license-wallet.api'
import { useGetUserQuery } from 'shared/api/user.api'
import { unpackError } from 'shared/api/utils'
import { handleError } from 'shared/utils/errorHandler'

import { ResourceStateCard } from './ResourceStateCard'
import { ResourceTypeCard } from './ResourceTypeCard'

export const LicenseWalletPage = (): JSX.Element => {
  const { error, wallet } = useLicenseWallet()
  const [isErrorExpanded, setIsErrorExpanded] = useState<boolean>(false)
  const history = useHistory()
  const user = useGetUserQuery()
  const isOperator = user.data?.is_operator ?? false

  if (error || !wallet) {
    return (
      <LicenseWalletPageRoot>
        <ErrorMessage>
          <ErrorHeader>
            Oops, something went wrong while loading the wallet, try again!
          </ErrorHeader>
          {error && error.data && (
            <>
              <DetailsLink onClick={() => setIsErrorExpanded(!isErrorExpanded)}>
                Error details {isErrorExpanded ? '▲' : '▼'}
              </DetailsLink>
              {isErrorExpanded && (
                <ErrorDetails>{JSON.stringify(error.data)}</ErrorDetails>
              )}
            </>
          )}
        </ErrorMessage>
      </LicenseWalletPageRoot>
    )
  }

  return (
    <LicenseWalletPageRoot>
      <Typography variant="h3" align="center">
        License wallet
      </Typography>
      <Resources>
        <ResourceTypeCard type="ST" />
        <ResourceStateCard
          current={wallet.total_resources.ST.current_value}
          start={wallet.total_resources.ST.start_value}
        />
        <ResourceTypeCard type="CP" />
        <ResourceStateCard
          current={wallet.total_resources.CP.current_value}
          start={wallet.total_resources.CP.start_value}
        />
        <ResourceTypeCard type="CG" />
        <ResourceStateCard
          label={
            wallet.total_resources.CG.current_value === 1
              ? 'Active'
              : 'Inactive'
          }
        />
        <ResourceTypeCard type="SP" />
        <ResourceStateCard
          label={`Level ${wallet.total_resources.SP.start_value} - Active`}
        />
      </Resources>
      {isOperator ? (
        <ButtonContainer>
          <Button onClick={() => history.push('/payments-history')}>
            Payment history
          </Button>
          <Button
            color="secondary"
            onClick={() => history.push('/buy-package')}
          >
            Purchase a license
          </Button>
        </ButtonContainer>
      ) : null}
    </LicenseWalletPageRoot>
  )
}

const LicenseWalletPageRoot = styled.div`
  padding-top: calc(${props => props.theme.layout.headerHeight}px + 24px);
  width: 600px;
  margin: auto;
`

const useLicenseWallet = () => {
  const licenseWalletResult = useGetLicenseWalletQuery()

  return useMemo(() => {
    const error =
      licenseWalletResult.error && unpackError(licenseWalletResult.error)
    if (error) {
      handleError(error)
    }
    const wallet = licenseWalletResult.data?.results[0]

    const isLoading =
      licenseWalletResult.isFetching || licenseWalletResult.isLoading

    return { error, wallet, isLoading }
  }, [
    licenseWalletResult.data?.results,
    licenseWalletResult.error,
    licenseWalletResult.isFetching,
    licenseWalletResult.isLoading,
  ])
}

const Resources = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: repeat(3, 1fr);
  grid-gap: 12px;
  margin-top: 32px;
`

const ErrorMessage = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`

const ErrorHeader = styled.span`
  font-size: ${props => props.theme.font.size.h2}px;
`

const DetailsLink = styled(Link)`
  cursor: pointer;
  margin: 20px 0;
`

const ErrorDetails = styled.div`
  background-color: white;
  border-radius: 10px;
  padding: 20px 40px;
`

const ButtonContainer = styled.div`
  display: flex;
  justify-content: center;
  gap: 8px;
  margin-top: 20px;
`
