import { useFormikContext } from 'formik'
import { useState } from 'react'
import styled from 'styled-components'

import { ValidationLabel } from 'components/ validation-label/ValidationLabel'
import { Checkbox } from 'components/Checkbox'
import { NoDataFound } from 'components/NoDataFound'
import { SearchInput } from 'components/input/SearchInput'

import { LassoTag } from 'pages/analysis/LassoTag'

import { Lasso } from 'shared/models/AnalysisModels'

import { CreateBrickFormValues } from './CreateBrickWizard'
import { FoldableWizardSection } from './FoldableWizardSection'

type CreateBrickWizardSelectClustersAndLassosStepProps = {
  clusterNames: string[]
  lassos: Lasso[]
}

export const CreateBrickWizardSelectClustersAndLassosStep: React.FC<
  CreateBrickWizardSelectClustersAndLassosStepProps
> = ({ clusterNames, lassos }) => {
  const { values, errors, setFieldValue } =
    useFormikContext<CreateBrickFormValues>()

  const [searchValue, setSearchValue] = useState('')

  const areAllClustersSelected =
    clusterNames.length > 0 &&
    clusterNames.length === values.selectedClusterNames.length

  const areAllLassosSelected =
    lassos.length > 0 && lassos.length === values.selectedLassoIds.length

  const error = errors.selectedClusterNames ?? errors.selectedLassoIds

  const handleToggleAllClusters = () => {
    if (areAllClustersSelected) {
      setFieldValue('selectedClusterNames', [])
    } else {
      setFieldValue('selectedClusterNames', clusterNames)
    }
  }

  const handleToggleCluster = (clusterName: string) => {
    const selectedClusterNames = values.selectedClusterNames
    if (selectedClusterNames.includes(clusterName)) {
      setFieldValue(
        'selectedClusterNames',
        selectedClusterNames.filter(name => name !== clusterName),
      )
    } else {
      setFieldValue('selectedClusterNames', [
        ...selectedClusterNames,
        clusterName,
      ])
    }
  }

  const handleToggleAllLassos = () => {
    if (areAllLassosSelected) {
      setFieldValue('selectedLassoIds', [])
    } else {
      setFieldValue(
        'selectedLassoIds',
        lassos.map(lasso => lasso.id),
      )
    }
  }

  const handleToggleLasso = (lassoId: string) => {
    const selectedLassoIds = values.selectedLassoIds
    if (selectedLassoIds.includes(lassoId)) {
      setFieldValue(
        'selectedLassoIds',
        selectedLassoIds.filter(id => id !== lassoId),
      )
    } else {
      setFieldValue('selectedLassoIds', [...selectedLassoIds, lassoId])
    }
  }

  return (
    <>
      {error && <StyledValidationLabel>{error}</StyledValidationLabel>}
      <CreateBrickWizardSelectClustersAndLassosStepRoot>
        <Toolbar>
          <StyledSearchInput
            placeholder="Search files"
            onChange={setSearchValue}
          />
        </Toolbar>
        <ScrollContainer>
          <FoldableWizardSection
            title={
              <Checkbox
                checked={areAllClustersSelected}
                label="Labeled clusters"
                disabled={clusterNames.length === 0}
                onChange={handleToggleAllClusters}
              />
            }
          >
            {clusterNames.length > 0 ? (
              <CheckboxContainer>
                {clusterNames
                  .filter(clusterName => clusterName.includes(searchValue))
                  .map(clusterName => (
                    <Checkbox
                      key={clusterName}
                      label={<CheckboxLabel>{clusterName}</CheckboxLabel>}
                      checked={values.selectedClusterNames.includes(
                        clusterName,
                      )}
                      onChange={() => handleToggleCluster(clusterName)}
                    />
                  ))}
              </CheckboxContainer>
            ) : (
              <StyledNoDataFound>No clusters available</StyledNoDataFound>
            )}
          </FoldableWizardSection>
          <FoldableWizardSection
            title={
              <Checkbox
                label="Lassos"
                checked={areAllLassosSelected}
                disabled={lassos.length === 0}
                onChange={handleToggleAllLassos}
              />
            }
          >
            {lassos.length > 0 ? (
              <CheckboxContainer>
                {lassos.map(lasso => (
                  <Checkbox
                    key={lasso.id}
                    label={<StyledLassoTag name={lasso.name} />}
                    checked={values.selectedLassoIds.includes(lasso.id)}
                    onChange={() => handleToggleLasso(lasso.id)}
                  />
                ))}
              </CheckboxContainer>
            ) : (
              <StyledNoDataFound>No lassos available</StyledNoDataFound>
            )}
          </FoldableWizardSection>
        </ScrollContainer>
      </CreateBrickWizardSelectClustersAndLassosStepRoot>
    </>
  )
}

const CreateBrickWizardSelectClustersAndLassosStepRoot = styled.div`
  background-color: ${props => props.theme.colors.primary[10]};
  border-radius: 22px;
  padding: 20px 15px;
`

const Toolbar = styled.div`
  display: flex;
  gap: 16px;
  margin-bottom: 20px;
`

const StyledSearchInput = styled(SearchInput)`
  width: 400px;
`

const CheckboxLabel = styled.label`
  font-weight: bold;
  color: ${props => props.theme.colors.primaryDark['70']};
  font-size: 12px;
`

const ScrollContainer = styled.div`
  overflow-y: auto;
  background-color: white;
  border-radius: 22px;
  padding: 16px;
  display: flex;
  flex-direction: column;
  gap: 20px;
`

const CheckboxContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
`

const StyledValidationLabel = styled(ValidationLabel)`
  margin-bottom: 8px;
`

const StyledLassoTag = styled(LassoTag)`
  font-size: 12px;
  font-weight: bold;
`

const StyledNoDataFound = styled(NoDataFound)`
  text-align: center;
  margin: 10px 0;
`
