import { countBy } from 'lodash'

export const computeGatePercentage = (
  gate: Graph.Gate,
  events: [number, number][] | undefined,
): number => {
  if (!events || events.length === 0) {
    return 0
  }

  if (gate.type === 'polygon') {
    return computePolygonGatePercentage(gate, events)
  }
  return computeRectangleGatePercentage(gate, events)
}

const computeRectangleGatePercentage = (
  gate: Graph.Gate,
  points: Array<[number, number]>,
) => {
  const { xMin, xMax, yMin, yMax } = gate.tempValues ? gate.tempValues : gate
  const { inside = 0, outside = 0 } = countBy(points, ([x, y]) => {
    return x >= xMin && x <= xMax && y >= yMin && y <= yMax
      ? 'inside'
      : 'outside'
  })
  return (inside / (inside + outside)) * 100
}

const computePolygonGatePercentage = (
  gate: Graph.PolygonGate,
  points: Array<[number, number]>,
) => {
  const { inside = 0, outside = 0 } = countBy(points, ([x, y]) => {
    // Ray-casting algorithm that is based on:
    // https://wrf.ecse.rpi.edu/Research/Short_Notes/pnpoly.html

    let inside = false
    const points = gate.points
    for (let i = 0, j = points.length - 1; i < points.length; j = i++) {
      const xi = points[i].x
      const yi = points[i].y
      const xj = points[j].x
      const yj = points[j].y

      const intersect =
        // eslint-disable-next-line
        yi > y !== yj > y && x < ((xj - xi) * (y - yi)) / (yj - yi) + xi

      if (intersect) {
        inside = !inside
      }
    }

    return inside ? 'inside' : 'outside'
  })

  return (inside / (inside + outside)) * 100
}
