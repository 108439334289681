import { TooltipFormatterCallbackFunction } from 'highcharts'
import {
  forwardRef,
  useImperativeHandle,
  useMemo,
  useRef,
  useState,
} from 'react'
import { renderToStaticMarkup } from 'react-dom/server'
import styled, { useTheme } from 'styled-components'

import { DefaultErrorBoundary } from 'components/DefaultErrorBoundary'
import { Modal } from 'components/Modal'
import { Chart } from 'components/graphs/Chart'

import { MetaAnalysisBoxPlotType } from 'shared/api/meta-analysis.api'
import { useEventCallback } from 'shared/hooks/useEventCallback'
import { useAppDispatch, useAppSelector } from 'shared/store'
import { downloadText, useSize } from 'shared/utils/utils'

import { MetaAnalysisBoxPlotBaseOptions } from './MetaAnalysisBoxPlotBaseOptions'
import { MetaAnalysisBoxPlotOptions } from './MetaAnalysisBoxPlotOptions'
import { MetaAnalysisChartContainer } from './MetaAnalysisChartContainer'
import { computeBoxPlotSeries } from './series'
import { updateMetaAnalysisChart } from './store/meta-analysis.history.slice'
import {
  selectMetaAnalysisChannels,
  selectMetaAnalysisFcsFileNameById,
  selectMetaAnalysisFile,
  selectMetaAnalysisName,
} from './store/selectors'
import { useMetaAnalysisCsvMetadata } from './useMetaAnalysisCsvMetadata'
import { useNavigateToFile } from './useNavigateToFile'

type MetaAnalysisBoxPlotChartProps = {
  chart: MetaAnalysisBoxPlotType
  isExpanded?: boolean
  className?: string
  onCloseExpand?: () => void
}

export const MetaAnalysisBoxPlot = ({
  chart,
  isExpanded,
  className,
  onCloseExpand,
}: MetaAnalysisBoxPlotChartProps): JSX.Element => {
  const dispatch = useAppDispatch()

  const [shouldDisplayChartOptions, setShouldDisplayChartOptions] =
    useState(false)
  const [shouldShowExpandedSelf, setShouldShowExpandedSelf] = useState(false)

  const [ref, { width, height }] = useSize<HTMLDivElement>({ debounce: 250 })
  const innerPlotRef = useRef<InnerPlotInstance>(null)

  const handleChangeOptions = useEventCallback(() => {
    setShouldDisplayChartOptions(true)
  })

  const handleCancel = useEventCallback(() => {
    setShouldDisplayChartOptions(false)
  })

  const handleApplyOptions = useEventCallback(
    (fileIds: string[], cluster: string, channel: string) => {
      dispatch(
        updateMetaAnalysisChart({
          id: chart.id,
          selectedFileIds: fileIds,
          selectedCluster: cluster,
          selectedChannel: channel,
        }),
      )
      setShouldDisplayChartOptions(false)
    },
  )

  const handleDownload = useEventCallback(() => {
    innerPlotRef.current?.downloadSeries()
  })

  return (
    <MetaAnalysisChartContainer
      containerRef={ref}
      chart={chart}
      className={className}
      isExpanded={isExpanded}
      onChangeOptions={handleChangeOptions}
      onExpand={() => setShouldShowExpandedSelf(true)}
      onCloseExpand={onCloseExpand}
      onDownload={handleDownload}
    >
      {shouldDisplayChartOptions && (
        <StyledMetaAnalysisBoxPlotOptions
          mode="edit"
          initialSelectedFileIds={chart.selectedFileIds}
          initialSelectedChannel={chart.selectedChannel}
          initialSelectedCluster={chart.selectedCluster}
          onCancel={handleCancel}
          onFinish={handleApplyOptions}
        />
      )}
      <DefaultErrorBoundary>
        <InnerPlot
          chart={chart}
          width={width}
          height={height}
          ref={innerPlotRef}
        />
      </DefaultErrorBoundary>
      {shouldShowExpandedSelf && (
        <Modal open onClose={() => setShouldShowExpandedSelf(false)}>
          <ExpandedMetaAnalysisBoxPlot
            chart={chart}
            onCloseExpand={() => setShouldShowExpandedSelf(false)}
          />
        </Modal>
      )}
    </MetaAnalysisChartContainer>
  )
}

type InnerPlotProps = Pick<MetaAnalysisBoxPlotChartProps, 'chart'> & {
  width: number
  height: number
}

type InnerPlotInstance = {
  downloadSeries: () => void
}

const InnerPlot = forwardRef<InnerPlotInstance, InnerPlotProps>(
  ({ chart, width, height }, ref) => {
    const theme = useTheme()
    const navigateToFile = useNavigateToFile()

    const metaAnalysisFile = useAppSelector(selectMetaAnalysisFile)
    const channels = useAppSelector(selectMetaAnalysisChannels)
    const fcsFileNameById = useAppSelector(selectMetaAnalysisFcsFileNameById)
    const metaAnalysisName = useAppSelector(selectMetaAnalysisName)

    const series = useMemo(
      () => computeBoxPlotSeries(chart, metaAnalysisFile, channels),
      [chart, metaAnalysisFile, channels],
    )

    const createCsvMetadata = useMetaAnalysisCsvMetadata()

    const downloadSeries = useEventCallback(async () => {
      const headerRow = 'file,cluster,channel,min,q1,median,q3,max'
      const dataRows = chart.selectedFileIds.map((id, index) => {
        return (
          `${fcsFileNameById[id]},${chart.selectedCluster},${chart.selectedChannel},` +
          (series[0].data![index] as number[]).join(',')
        )
      })
      const csv =
        (await createCsvMetadata()) + [headerRow, ...dataRows].join('\n')
      downloadText(csv, `${metaAnalysisName} - ${chart.name}.csv`)
    })
    useImperativeHandle(
      ref,
      () => ({
        downloadSeries,
      }),
      [downloadSeries],
    )

    const options: Highcharts.Options = useMemo(() => {
      const formatTooltip: TooltipFormatterCallbackFunction = function (this) {
        const isOutlier = this.point.series.userOptions.type === 'scatter'
        return renderToStaticMarkup(
          <div
            style={{
              padding: 8,
              fontSize: 13,
              minWidth: 120,
              background: theme.colors.white,
            }}
          >
            <div
              style={{
                fontFamily: theme.font.style.bold,
              }}
            >
              {this.x}
            </div>
            <div
              style={{
                fontSize: 12,
                marginTop: 6,
                color: theme.colors.primaryDark[70],
              }}
            >
              {isOutlier ? (
                this.point.y?.toFixed(2)
              ) : (
                <>
                  <div>Maximum: {this.point.options.high?.toFixed(2)}</div>
                  <div>Upper quartile: {this.point.options.q3?.toFixed(2)}</div>
                  <div>Median: {this.point.options.median?.toFixed(2)}</div>
                  <div>Lower quartile: {this.point.options.q1?.toFixed(2)}</div>
                  <div>Minimum: {this.point.options.low?.toFixed(2)}</div>
                </>
              )}
            </div>
          </div>,
        )
      }

      return {
        ...MetaAnalysisBoxPlotBaseOptions,
        chart: {
          ...MetaAnalysisBoxPlotBaseOptions.chart,
          width,
          height,
        },
        tooltip: {
          enabled: true,
          useHTML: true,
          formatter: formatTooltip,
        },
        xAxis: {
          categories: chart.selectedFileIds.map(id => fcsFileNameById[id]),
          labels: {
            events: {
              async click(this: { pos: number }) {
                const fcsFileId = chart.selectedFileIds[this.pos]
                navigateToFile(fcsFileId)
              },
            },
          } as Highcharts.XAxisLabelsOptions,
        },
        yAxis: {
          title: {
            text: chart.selectedChannel,
          },
        },
        series,
      }
    }, [
      chart.selectedChannel,
      chart.selectedFileIds,
      fcsFileNameById,
      height,
      navigateToFile,
      series,
      theme.colors.primaryDark,
      theme.colors.white,
      theme.font.style.bold,
      width,
    ])

    if (!width || !height) {
      return <div>Loading...</div>
    }

    return <Chart options={options} width={width} height={height} />
  },
)

const StyledMetaAnalysisBoxPlotOptions = styled(MetaAnalysisBoxPlotOptions)`
  position: absolute;
  top: 0;
  left: 0;
  z-index: 2;
`

const ExpandedMetaAnalysisBoxPlot = styled(MetaAnalysisBoxPlot).attrs({
  isExpanded: true,
})`
  width: 90vw;
  height: 90vh;
`
