import { changeAlpha } from 'shared/utils/colors'

type ComputeSeriesImageDataURLProps = {
  width: number
  height: number
  seriesIdByPixelPosition: Map<string, string>
  colorBySeriesId: Record<string, string>
  highlightedSeriesIds?: string[]
  seriesDotSizes?: Record<string, number>
}

export const computeSeriesImageDataURL = ({
  width,
  height,
  seriesIdByPixelPosition,
  colorBySeriesId,
  highlightedSeriesIds,
  seriesDotSizes,
}: ComputeSeriesImageDataURLProps): string => {
  const canvas = document.createElement('canvas')
  canvas.width = width
  canvas.height = height
  const ctx = canvas.getContext('2d')
  if (!ctx) {
    throw new Error('Could not get canvas context')
  }
  for (const [pixelPosition, clusterId] of seriesIdByPixelPosition) {
    const color = colorBySeriesId[clusterId]
    ctx.fillStyle =
      highlightedSeriesIds && highlightedSeriesIds.length > 0
        ? highlightedSeriesIds.includes(clusterId)
          ? color
          : changeAlpha(color, 0.2)
        : color
    const [x, y] = pixelPosition.split(',').map(Number)
    const size = seriesDotSizes ? seriesDotSizes[clusterId] ?? 1 : 1
    ctx.fillRect(x, y, size, size)
  }

  return canvas.toDataURL()
}

export const renderSeriesImage = (
  highchartsChart: Highcharts.Chart,
  seriesImageDataUrl: string,
): Highcharts.SVGElement => {
  return highchartsChart.renderer
    .image(
      seriesImageDataUrl,
      highchartsChart.plotLeft,
      highchartsChart.plotTop,
      highchartsChart.plotWidth,
      highchartsChart.plotHeight,
    )
    .add()
    .toFront()
}
