import { RadioGroup } from '@material-ui/core'
import { useFormikContext } from 'formik'
import styled from 'styled-components'

import { ChannelsList } from 'components/ChannelsList'
import RadioButton from 'components/RadioButton'

import { CompensatedFile, FcsFileChannel } from 'shared/api/files.api'
import { PrecisionAndGranularityMode } from 'shared/api/workflows.api'
import { OTHER_LETTER } from 'shared/constants'

import { ClusteringPrecisionAndGranularityWizardSection } from './ClusteringPrecisionAndGranularityWizardSection'
import { CreateBrickFormValues } from './CreateBrickWizard'
import { WizardSectionCard } from './WizardSectionCard'
import { CLUSTERING_EXCLUDED_CHANNELS } from './constants'

type CreateBrickWizardSelectChannelsStepProps = {
  channels: Record<string, FcsFileChannel>
  isExpert: boolean
  compensatedFiles: CompensatedFile[]
  parentPrecisionAndGranularityMode: PrecisionAndGranularityMode
}

export const CreateBrickWizardSelectChannelsStep: React.FC<
  CreateBrickWizardSelectChannelsStepProps
> = ({
  channels,
  isExpert,
  compensatedFiles,
  parentPrecisionAndGranularityMode,
}) => {
  const { values, errors, setFieldValue, handleChange } =
    useFormikContext<CreateBrickFormValues>()

  return (
    <>
      <CreationModeRadioGroup
        row
        name="brickType"
        value={values.brickType}
        onChange={handleChange}
      >
        <RadioButton value="clustering" label="Clustering" />
        <RadioButton value="sub-analysis" label="Sub analysis" />
      </CreationModeRadioGroup>
      {values.brickType === 'clustering' && (
        <>
          <Error>{errors.selectedChannelIds}</Error>
          <WizardSectionCard title="Select channels for clustering">
            <ChannelsList
              columns={3}
              channels={Object.values(channels ?? {}).map(channel => {
                const defaultLabel = channel.PnS ?? undefined
                return {
                  label: defaultLabel,
                  defaultLabel,
                  ...channel,
                  letter: channel.letter ?? OTHER_LETTER,
                }
              })}
              selectedChannels={values.selectedChannelIds}
              excludedChannels={CLUSTERING_EXCLUDED_CHANNELS}
              selectable
              onSelectedChannelsChange={channels =>
                setFieldValue('selectedChannelIds', channels)
              }
            />
          </WizardSectionCard>

          <WizardSectionCard title="Select display transformation">
            <RadioGroup
              row
              name="transformationMethod"
              value={values.transformationMethod}
              onChange={event => {
                setFieldValue(event.target.name, event.target.value)
                if (event.target.value === 'logicle') {
                  setFieldValue('normalization', false)
                }
              }}
            >
              <RadioButton value="none" label="None" />
              <RadioButton value="arcsinh" label="ArcSinH" />
              <RadioButton value="logicle" label="Logicle" />
              <RadioButton value="biexp" label="Biexpo" />
            </RadioGroup>
          </WizardSectionCard>

          <ClusteringPrecisionAndGranularityWizardSection
            compensatedFiles={compensatedFiles}
            isExpert={isExpert}
            clusteringType="secondary"
            parentPrecisionAndGranularityMode={
              parentPrecisionAndGranularityMode
            }
          />
        </>
      )}
    </>
  )
}

const Error = styled.div`
  color: ${({ theme }) => theme.colors.error};
  padding-bottom: 14px;
`

const CreationModeRadioGroup = styled(RadioGroup)`
  margin-bottom: 24px;
`
