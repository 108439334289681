import styled from 'styled-components'

import { ReactComponent as EyeIcon } from 'assets/images/icons/eye.svg'

import { WorkflowNode } from './WorkflowNode'

export const WorkflowFilesNode: React.FC = () => {
  return (
    <StyledWorkflowNode isRoot>
      Files
      <EyeIcon />
    </StyledWorkflowNode>
  )
}

const StyledWorkflowNode = styled(WorkflowNode)`
  background-color: ${props => props.theme.colors.success};
  color: white;
`
