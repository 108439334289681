import React, { FC, useRef, useEffect, useCallback } from 'react'
import ReactSlider from 'react-slider'
import ResizeObserver from 'resize-observer-polyfill'
import styled from 'styled-components'

export type SliderChangeValue = number | number[] | undefined | null

type SliderProps = {
  min: number
  max: number
  value: number
  onChange(value: SliderChangeValue): void
  className?: string
  disabled?: boolean
}

export const Slider: FC<SliderProps> = ({
  min,
  max,
  value,
  onChange,
  className,
  disabled,
}) => {
  // Hooks
  // eslint-disable-next-line
  const sliderRef = useRef<any>()
  const containerRef = useRef<HTMLDivElement>(null)
  const [depthInput, setDepthInput] = React.useState(value)

  useEffect(() => {
    if (depthInput !== value) {
      onChange(depthInput)
    }
    // eslint-disable-next-line
  }, [depthInput])

  useEffect(() => {
    setDepthInput(value)
  }, [value])

  // mandatory useEffect needed due to dynamic resize created by sunburst expansion
  // https://zillow.github.io/react-slider/#!/ReactSlider/21
  useEffect(() => {
    const containerRefCurrent = containerRef.current
    const resizeObserver = new ResizeObserver(() => {
      sliderRef.current.resize()
    })
    if (containerRefCurrent) {
      resizeObserver.observe(containerRefCurrent)
    }

    return () => {
      if (containerRefCurrent) {
        resizeObserver.unobserve(containerRefCurrent)
      }
    }
  }, [containerRef, sliderRef])

  const renderTrack = useCallback(
    (props, state) => (
      <StyledTrack {...props} $disabled={disabled} index={state.index} />
    ),
    [disabled],
  )

  const renderThumb = useCallback(
    (props, state) => (
      <StyledThumb {...props} $disabled={disabled}>
        {state.valueNow}
      </StyledThumb>
    ),
    [disabled],
  )

  return (
    <SliderWrapper className={className} ref={containerRef}>
      <Info>{min}</Info>
      <StyledSlider
        ref={sliderRef}
        renderTrack={renderTrack}
        renderThumb={renderThumb}
        min={min}
        max={max}
        value={depthInput}
        onAfterChange={setDepthInput}
        disabled={disabled}
      />
      <Info>{max}</Info>
    </SliderWrapper>
  )
}

const StyledSlider = styled(ReactSlider)`
  width: 100%;
  height: 10px;
  margin: 0 10px;
  & > div:first-child {
    background-color: ${props =>
      props.disabled
        ? props.theme.colors.greyscale[50]
        : props.theme.colors.primaryDark[100]};
  }
` as React.ComponentType as new () => ReactSlider<number>

const StyledTrack = styled.div<{ $disabled: boolean }>`
  height: 10px;
  border-radius: 10px;
  background-color: ${props =>
    props.$disabled
      ? props.theme.colors.greyscale[10]
      : props.theme.colors.primary[10]};
  cursor: ${props => (props.$disabled ? 'default' : 'pointer')};
`

const StyledThumb = styled.div<{ $disabled: boolean }>`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 40px;
  width: 40px;
  border-radius: 20px;
  background-color: ${props =>
    props.$disabled
      ? props.theme.colors.greyscale[50]
      : props.theme.colors.primaryDark[100]};
  outline: none;
  transform: translateY(-15px);
  color: ${props => props.theme.colors.white};
  font-weight: bold;
  font-size: 16px;
  cursor: ${props => (props.$disabled ? 'default' : 'pointer')};
  z-index: unset !important;
`

const SliderWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
`

const Info = styled.span`
  font-weight: bold;
  color: ${props => props.theme.colors.primaryDark[100]};
  font-size: 16px;
`
