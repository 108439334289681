import { ReactNode, useState } from 'react'
import styled, { css } from 'styled-components'

import { ReactComponent as ArrowIcon } from 'assets/images/icons/section-expand-indicator.svg'

type FoldableWizardSectionProps = {
  title: ReactNode
  children: ReactNode
}

export const FoldableWizardSection: React.FC<FoldableWizardSectionProps> = ({
  title,
  children,
}) => {
  const [isExpanded, setIsExpanded] = useState(true)

  return (
    <FoldableWizardSectionRoot>
      <SectionHeader
        onClick={() => setIsExpanded(currentValue => !currentValue)}
      >
        {title}
        <StyledArrowIcon $isExpanded={isExpanded} />
      </SectionHeader>
      {isExpanded && <SectionContent>{children}</SectionContent>}
    </FoldableWizardSectionRoot>
  )
}

const FoldableWizardSectionRoot = styled.div`
  box-shadow: 0px 2px 10px 0px #e8e9f399;
  border-radius: 9px;
`

const SectionHeader = styled.div`
  background-color: #f5f5f5;
  border-radius: 9px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 13px 20px;
  cursor: pointer;
`

const StyledArrowIcon = styled(ArrowIcon)<{ $isExpanded: boolean }>`
  ${props =>
    props.$isExpanded &&
    css`
      transform: rotate(180deg);
    `}
`

const SectionContent = styled.div`
  padding: 16px;
  overflow-x: auto;
`
