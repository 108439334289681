import { useState, useEffect, useRef } from 'react'
import { usePrevious } from 'react-use'

type UseAsyncMemoResult<T> = {
  value: T | undefined
  currentValue: T | undefined
  isLoading: boolean
  isError: boolean
}

export const useAsyncMemo = <T>(
  producer: () => Promise<T>,
): UseAsyncMemoResult<T> => {
  const [value, setValue] = useState<T | undefined>(undefined)
  const [isLoading, setIsLoading] = useState(true)
  const [isError, setIsError] = useState(false)
  const currentValueRef = useRef<T | undefined>(undefined)
  const previousProducer = usePrevious(producer)
  if (previousProducer !== producer) {
    currentValueRef.current = undefined
  }

  useEffect(() => {
    let cancelled = false
    setIsLoading(true)
    setIsError(false)
    producer()
      .then(value => {
        if (!cancelled) {
          currentValueRef.current = value
          setValue(value)
          setIsLoading(false)
        }
      })
      .catch(() => {
        setIsError(true)
      })
    return () => {
      cancelled = true
    }
  }, [producer])

  return {
    value,
    currentValue: currentValueRef.current,
    isLoading,
    isError,
  }
}
