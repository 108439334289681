import styled from 'styled-components'

import { InvitationsPageContent } from './InvitationsPageContent'
import { MembersPageContent } from './MembersPageContent'

export const MembersPanelPage = (): JSX.Element => {
  return (
    <MembersPanelContainer>
      <InvitationsPageContent />
      <MembersPageContent />
    </MembersPanelContainer>
  )
}

const MembersPanelContainer = styled.div`
  padding-top: calc(${props => props.theme.layout.headerHeight + 24}px);
  width: 90%;
  margin: auto;
`
