import { renderToStaticMarkup } from 'react-dom/server'
import { ThemeProvider as StyledComponentsThemeProvider } from 'styled-components'

import { Cluster } from 'pages/analysis/store/selectors'

import { computeColorFromString } from 'shared/utils/colors'

import { Theme } from 'Theme'

import { LassoTooltip } from './LassoTooltip'

type CreateAnalysisLassosHierarchyGraphBaseOptionsProps = {
  isMinimalMode?: boolean
  lassos: Record<string, { name: string }>
  parentLassoIdByLassoId: Record<string, string | undefined>
  clustersByLassoId: Record<string, Cluster[]>
  lassosToColor?: string[]
  selectedLassoLabelColor?: string
  theme: Theme
}

export const createAnalysisLassosHierarchyGraphBaseOptions = ({
  isMinimalMode,
  lassos,
  parentLassoIdByLassoId,
  clustersByLassoId,
  lassosToColor,
  selectedLassoLabelColor,
  theme,
}: // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
CreateAnalysisLassosHierarchyGraphBaseOptionsProps) => {
  const formatTooltip: Highcharts.TooltipFormatterCallbackFunction =
    function () {
      const clusters = clustersByLassoId[this.point.id]
      if (!clusters) {
        return false
      }

      return renderToStaticMarkup(
        <StyledComponentsThemeProvider theme={theme}>
          <LassoTooltip name={this.point.name} clusters={clusters} />
        </StyledComponentsThemeProvider>,
      )
    }

  return {
    chart: {
      type: 'treegraph' as const,
      height: isMinimalMode ? 274 : undefined,
    },
    tooltip: {
      useHTML: true,
      formatter: formatTooltip,
    },
    plotOptions: {
      series: {
        stickyTracking: false,
        animation: false,
        clip: false,
        marker: {
          symbol: 'circle',
          radius: 6,
          fillColor: '#ffffff',
          lineWidth: 3,
        },
        dataLabels: {
          align: 'left' as const,
          x: 12,
          style: {
            textOutline: '3px #ffffff',
          },
        },
      },
    },
    series: [
      {
        type: 'treegraph' as const,
        data: [
          {
            id: '--total-population',
            name: 'total',
            marker: {
              lineColor: selectedLassoLabelColor
                ? theme.colors.greyscale[50]
                : computeColorFromString('total'),
            },
          },
          ...Object.entries(parentLassoIdByLassoId).map(([id, parentId]) => {
            return {
              id,
              parent: parentId ?? '--total-population',
              name: lassos[id].name,
              marker: {
                lineColor: selectedLassoLabelColor
                  ? lassosToColor?.includes(id)
                    ? selectedLassoLabelColor
                    : theme.colors.greyscale[50]
                  : computeColorFromString(lassos[id].name),
              },
            }
          }),
        ],
      },
    ],
    title: {
      text: undefined,
    },
  }
}
