import Highcharts from 'highcharts'

import { MetaAnalysisFrequencyChartBaseOptions } from 'pages/meta-analysis/MetaAnalysisFrequencyChartBaseOptions'
import { computeFrequencyChartSeries } from 'pages/meta-analysis/series'

import {
  MetaAnalysisColors,
  MetaAnalysisFile,
  MetaAnalysisFrequencyChartType,
} from 'shared/api/meta-analysis.api'

type RenderFrequencyChartProps = {
  chart: MetaAnalysisFrequencyChartType
  metaAnalysisFile: MetaAnalysisFile
  colors: MetaAnalysisColors
  channels: string[]
  fileNameById: Record<string, string>
  width: number
  height: number
}

export const renderFrequencyChart = async ({
  chart,
  metaAnalysisFile,
  colors,
  channels,
  fileNameById,
  width,
  height,
}: RenderFrequencyChartProps): Promise<string> => {
  return new Highcharts.Chart(document.createElement('div'), {
    ...MetaAnalysisFrequencyChartBaseOptions,
    chart: {
      ...MetaAnalysisFrequencyChartBaseOptions.chart,
      width,
      height,
    },
    xAxis: {
      ...MetaAnalysisFrequencyChartBaseOptions.xAxis,
      categories: chart.selectedFileIds.map(fileId => fileNameById[fileId]),
    },
    yAxis: {
      title: {
        text: `${chart.selectedChannel} ${chart.selectedIntensityMode} intensity`,
      },
    },
    series: computeFrequencyChartSeries(
      chart,
      metaAnalysisFile,
      colors,
      channels,
      fileNameById,
    ),
    title: {
      text: chart.name,
    },
  }).getSVG()
}
