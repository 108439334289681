import { Slide, Tooltip } from '@material-ui/core'
import { FC, useState } from 'react'
import styled from 'styled-components'

import { Icon } from 'assets/images/icons/Icon'

import { Button } from 'components/Button'

import {
  useCheckIfSaveWillCauseWorkflowRetriggerMutation,
  useSaveAnalysisMutation,
} from 'shared/api/analysis.api'
import { useDuplicateBrickMutation } from 'shared/api/workflows.api'
import { useDialog } from 'shared/contexts/DialogContext'
import { useSafeNavigate } from 'shared/hooks/router'
import { useEventCallback } from 'shared/hooks/useEventCallback'
import { useAppSelector } from 'shared/store'
import { updateClusterTree } from 'shared/utils/clusters.utils'

import {
  selectActiveClusterIds,
  selectAnalysisAccessMode,
  selectAnalysisBrick,
  selectAnalysisClusterTree,
  selectAnalysisId,
  selectAnalysisLassos,
  selectClusterById,
  selectIsAnalysisApproved,
  selectIsAnalysisBeingSaved,
  selectIsAnalysisProtected,
} from './store/selectors'

type SaveAnalysisButtonProps = {
  canToggleProtectionMode?: boolean
  className?: string
}

export const SaveAnalysisButton: FC<SaveAnalysisButtonProps> = ({
  canToggleProtectionMode,
  className,
}) => {
  const { showConfirmationDialog } = useDialog()
  const safeNavigate = useSafeNavigate()

  const [triggerSaveAnalysis] = useSaveAnalysisMutation()
  const [triggerDuplicateBrick, duplicateBrickMutation] =
    useDuplicateBrickMutation()

  const analysisId = useAppSelector(selectAnalysisId)
  const clusterTree = useAppSelector(selectAnalysisClusterTree)
  const brick = useAppSelector(selectAnalysisBrick)
  const isAnalysisBeingSaved =
    useAppSelector(selectIsAnalysisBeingSaved) ||
    duplicateBrickMutation.isLoading
  const isAnalysisProtected = useAppSelector(selectIsAnalysisProtected)
  const isAnalysisApproved = useAppSelector(selectIsAnalysisApproved)
  const analysisAccessMode = useAppSelector(selectAnalysisAccessMode)
  const activeClusterIds = useAppSelector(selectActiveClusterIds)
  const clusterById = useAppSelector(selectClusterById)
  const lassoById = useAppSelector(selectAnalysisLassos)

  const [triggerCheckIfSaveWillCauseWorkflowRetriggerMutation] =
    useCheckIfSaveWillCauseWorkflowRetriggerMutation()

  const [shouldDisplaySuccess, setShouldDisplaySuccess] = useState(false)

  const handleSave = useEventCallback(async () => {
    const justSave = () => {
      triggerSaveAnalysis()
        .unwrap()
        .then(() => {
          setShouldDisplaySuccess(true)
          setTimeout(() => setShouldDisplaySuccess(false), 3000)
        })
    }

    if (analysisAccessMode === 'experiment') {
      updateClusterTree({
        clusterTree,
        activeClusterIds,
        clusterById,
        lassoById,
      })
      triggerCheckIfSaveWillCauseWorkflowRetriggerMutation({
        analysisId,
        clusterTree,
      })
        .unwrap()
        .then(({ restart_required }) => {
          if (restart_required) {
            showConfirmationDialog({
              title: 'Save Analysis',
              message:
                'The changes you made will result in a different cluster selection in the subsuquent steps and they will have to be re-computed. Are you sure you want to proceed?',
              onConfirm: justSave,
            })
          } else {
            justSave()
          }
        })
    } else if (brick.is_leaf) {
      justSave()
    } else {
      showConfirmationDialog({
        title: 'Save Analysis',
        message:
          'This analysis cannot be overwritten, because the step it belongs to, has children. ' +
          'A copy of the step will be created. Are you sure you want to proceed?',
        onConfirm: () => {
          triggerDuplicateBrick(brick.id)
            .unwrap()
            .then(result => {
              setShouldDisplaySuccess(true)
              setTimeout(() => setShouldDisplaySuccess(false), 3000)
              const newDuplicatedAnalysisId =
                result.copy_report.analyses_result.find(
                  r => r.old_analysis === analysisId,
                )?.new_analysis
              if (!newDuplicatedAnalysisId) {
                throw new Error('Duplicated analysis id not found')
              }
              safeNavigate(`/analysis/${newDuplicatedAnalysisId}`)
            })
        },
      })
    }
  })

  const tooltipText = isAnalysisProtected
    ? canToggleProtectionMode
      ? 'This analysis is in protection mode. Please disable it before saving changes'
      : "This analysis is in protection mode. You don't have permission to save it"
    : isAnalysisApproved
    ? 'This analysis is approved and you cannot modify it'
    : ''

  return (
    <Tooltip title={tooltipText}>
      <div>
        <StyledButton
          disabled={isAnalysisProtected}
          className={className}
          onClick={handleSave}
          loading={isAnalysisBeingSaved}
          $hasSuccessStyle={shouldDisplaySuccess}
          disableWhileLoading={false}
          startIcon={
            !isAnalysisBeingSaved &&
            !shouldDisplaySuccess && <Icon name="save" />
          }
        >
          <Slide
            in={shouldDisplaySuccess}
            direction="up"
            exit={false}
            mountOnEnter={true}
            unmountOnExit={true}
          >
            <SaveConfirmContent>
              <CheckIcon name="check" color="white" />
              <p>Analysis Saved</p>
            </SaveConfirmContent>
          </Slide>
          <Slide
            in={!shouldDisplaySuccess}
            direction="up"
            exit={false}
            appear={false}
            mountOnEnter={true}
            unmountOnExit={true}
          >
            <div>Save this analysis</div>
          </Slide>
        </StyledButton>
      </div>
    </Tooltip>
  )
}

const StyledButton = styled(Button)<{
  $hasSuccessStyle: boolean
}>`
  color: ${props => props.theme.colors.white};
  font-size: ${props => props.theme.font.size.smallest}px;
  font-family: ${props => props.theme.font.style.bold};
  min-width: 165px;
  height: 32px;
  overflow: hidden;
  padding: 0 10px;
  background: ${props => props.theme.colors.warning};
  transition: opacity 300ms ease;
  &:hover {
    background: ${props => props.theme.colors.warning};
    opacity: 0.7;
  }
  ${props =>
    props.$hasSuccessStyle
      ? `pointer-events: none;
      background: ${props.theme.colors.success};`
      : ''}
`

const SaveConfirmContent = styled.div`
  display: flex;
  align-items: center;
`

const CheckIcon = styled(Icon)`
  margin-right: 10px;
  width: 20px;
  height: 20px;
`
