import React from 'react'
import styled from 'styled-components'

import { Button } from 'components/Button'

import { Signature } from 'shared/models/Signature'

type SignatureCancelProps = {
  signature: Signature
  disabled?: boolean
  onClick: (signatureId: string) => void
}

export const SignatureCancel = ({
  signature,
  disabled,
  onClick,
}: SignatureCancelProps): JSX.Element => {
  return (
    <Wrapper>
      <SignatureKey>{signature.key}</SignatureKey>
      {!disabled && (
        <div>
          <StyledButton grey onClick={() => onClick(signature.id)}>
            Cancel
          </StyledButton>
        </div>
      )}
    </Wrapper>
  )
}

const Wrapper = styled.div`
  & > * {
    margin: 4px 0;
  }
`

const SignatureKey = styled.div`
  font-size: ${props => props.theme.font.size.h3}px;
`

const StyledButton = styled(Button)`
  height: 32px;
`
