import { Basket } from 'shared/models/LicenseModels'
import { Results } from 'shared/models/Result'

import { privateApi } from './private.api'
import { encodeTagParameters } from './utils'

const basketsApi = privateApi.injectEndpoints({
  endpoints: builder => ({
    getBaskets: builder.query<BasketResults, void>({
      query: () => '/baskets/',
      providesTags: [
        { type: 'Basket', id: encodeTagParameters({ id: 'list' }) },
      ],
    }),
    getBasket: builder.query<Basket, { id: string }>({
      query: payload => `/baskets/${payload.id}/`,
      providesTags: (_result, _error, args) => [
        { type: 'Basket', id: encodeTagParameters({ id: args.id }) },
      ],
    }),
    addToBasket: builder.mutation<
      Basket,
      { id?: string; license_package: string; quantity: number }
    >({
      query: payload => ({
        url: '/baskets/add/',
        method: 'POST',
        body: payload,
      }),
    }),
    purchaseBasket: builder.mutation<{ url: string }, { id: string }>({
      query: payload => ({
        url: `/baskets/${payload.id}/purchase/`,
        method: 'POST',
      }),
    }),
    removeActiveBasket: builder.mutation<void, void>({
      query: () => ({
        url: '/baskets/remove_active_basket/',
        method: 'DELETE',
      }),
    }),
  }),
})

type BasketResults = Results<Basket>

export const {
  useGetBasketQuery,
  useAddToBasketMutation,
  usePurchaseBasketMutation,
  useRemoveActiveBasketMutation,
} = basketsApi
