import { HighlightedCluster } from 'pages/analysis/store/analysis.history.slice'
import { Cluster } from 'pages/analysis/store/selectors'

import { computeClusterColor } from 'shared/utils/clusters.utils'

type CreateAnalysisPieChartBaseOptionsProps = {
  leaves: Cluster[]
  globallyHiddenClusters: Set<string>
  highlightedCluster: HighlightedCluster | undefined
  shouldAnimate?: boolean
}

export const createAnalysisPieChartBaseOptions = ({
  leaves,
  globallyHiddenClusters,
  highlightedCluster,
  shouldAnimate,
}: // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
CreateAnalysisPieChartBaseOptionsProps) => {
  const getClusterColor = (cluster: Cluster) => {
    return computeClusterColor(
      cluster,
      globallyHiddenClusters,
      highlightedCluster,
    )
  }

  return {
    title: {
      text: undefined,
    },
    chart: {
      type: 'pie' as const,
    },
    tooltip: {
      enabled: false,
    },
    plotOptions: {
      pie: {
        shadow: false,
        cursor: 'pointer',
        dataLabels: {
          connectorShape: 'crookedLine',
          crookDistance: '90%',
          alignTo: 'plotEdges',
        },
      },
      series: {
        dataLabels: {
          enabled: true,
          allowOverlap: true,
          crop: false,
          overflow: 'allow' as const,
          padding: 0,
          style: {
            fontSize: '10px',
            visibility: 'inherit',
            display: 'block',
          },
        },
      },
    },
    series: [
      {
        animation: shouldAnimate,
        type: 'pie' as const,
        data: leaves.map(cluster => {
          return {
            ...cluster,
            name: cluster.label,
            color: getClusterColor(cluster),
            y: Math.pow(cluster.stats.count, 0.25),
          }
        }),
        size: '75%',
        innerSize: '45%',
        borderColor: 'white',
        borderWidth: 0.5,
      },
    ],
  }
}
