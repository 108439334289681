import { FormControlLabel, Switch } from '@material-ui/core'
import { uniq } from 'lodash'
import React, { ReactNode, useCallback, useMemo, useState } from 'react'
import styled from 'styled-components'

import { Icon } from 'assets/images/icons/Icon'

import { Checkbox } from 'components/Checkbox'
import { DebouncedInput } from 'components/forms/DebouncedInput'
import Select from 'components/forms/Select'

import { ClusterListCell } from './ClusterListCell'
import { LassoTag } from './LassoTag'
import { Cluster } from './store/selectors'

type ClusterListProps = {
  className?: string
  clusters: Pick<
    Cluster,
    'id' | 'label' | 'color' | 'lassoIds' | 'defaultLabel'
  >[]
  lassos: Record<string, { id: string; name: string }>
  selectedIds: string[]
  onChange?: (selectedIds: string[]) => void
  onRename?: (clusterId: string, name: string) => void
  hideHighlightButton?: boolean
  onClusterHighlighted?: () => void
  columns: number
  title?: ReactNode
  onDoubleClickClusterDot?: (clusterId: string) => void
  selectedDotClusterIds?: string[]
  graphLassoIds?: string[]
  initialShouldShowLassos?: boolean
}

export const ClusterList = (props: ClusterListProps): JSX.Element => {
  const {
    className,
    clusters,
    selectedIds,
    onChange,
    onRename,
    columns,
    lassos,
    graphLassoIds,
    initialShouldShowLassos = false,
  } = props
  const [selectedLassoIds, setSelectedLassoIds] = useState<string[]>(
    graphLassoIds ?? [],
  )
  const [search, setSearch] = useState('')
  const [shouldShowLassos, setShouldShowLassos] = useState(
    initialShouldShowLassos,
  )
  const allSelected = selectedIds.length === clusters.length

  const isAnyLassoSelected = selectedLassoIds.length > 0

  const { foundClusters = [], remainingClusters = [] } = useMemo(() => {
    let selected = clusters

    if (isAnyLassoSelected) {
      selected = selected.filter(cluster =>
        selectedLassoIds.some(lassoId => cluster.lassoIds.includes(lassoId)),
      )
    }
    if (search) {
      selected = selected.filter(cluster =>
        cluster.label.toLowerCase().includes(search.trim().toLowerCase()),
      )
    }

    return {
      foundClusters: selected,
      remainingClusters: clusters.filter(
        cluster => !selected.includes(cluster),
      ),
    }
  }, [clusters, search, selectedLassoIds, isAnyLassoSelected])

  const toggleFoundClusters = useMemo(
    () =>
      createToggleClusterGroup({
        clusters: foundClusters,
        selectedIds,
        onChange,
      }),
    [foundClusters, onChange, selectedIds],
  )

  const areLassosAvailable = Object.values(lassos).length > 0

  const toggleRemainingClusters = useMemo(
    () =>
      createToggleClusterGroup({
        clusters: remainingClusters,
        selectedIds,
        onChange,
      }),
    [onChange, remainingClusters, selectedIds],
  )

  const handleSelectLassos = useCallback(
    lassos => {
      setSelectedLassoIds(lassos)
    },
    [setSelectedLassoIds],
  )

  return (
    <ClustersListRoot className={className}>
      {props.title}
      <Header className="cluster-list-header">
        <Checkbox
          checked={allSelected}
          label={<SelectAllLabel>Select all</SelectAllLabel>}
          onClick={() =>
            allSelected
              ? onChange?.([])
              : onChange?.(clusters.map(cluster => cluster.id))
          }
        />
        <Filters>
          {areLassosAvailable && (
            <StyledSelect
              id="Lassos"
              name="Lassos"
              value={selectedLassoIds}
              multiple
              IconComponent={() => <SelectIcon name="smallArrowDown" />}
              options={Object.values(lassos).map(lasso => ({
                value: lasso.id,
                label: lasso.name,
              }))}
              renderValue={selectedIds => (
                <LassoTags>
                  {(selectedIds as string[]).map(selectedId => (
                    <LassoTag key={selectedId} name={lassos[selectedId].name} />
                  ))}
                </LassoTags>
              )}
              onChange={event => {
                handleSelectLassos(event.target.value)
              }}
            />
          )}
          <DebouncedInput
            placeholder="Search"
            value={search}
            onChange={setSearch}
            disableError={true}
          />
          {areLassosAvailable && (
            <LassoSwitchControlLabel
              control={
                <Switch
                  color="primary"
                  checked={shouldShowLassos}
                  onChange={event => setShouldShowLassos(event.target.checked)}
                />
              }
              label="Lassos"
            />
          )}
        </Filters>
      </Header>
      {(search || isAnyLassoSelected) && foundClusters.length > 0 && (
        <StyledCheckbox
          label={
            <Label>
              Found {foundClusters.length} cluster
              {foundClusters.length > 1 && 's'}
            </Label>
          }
          checked={foundClusters.every(foundCluster =>
            selectedIds.includes(foundCluster.id),
          )}
          onChange={toggleFoundClusters}
        />
      )}
      {(search || isAnyLassoSelected) && foundClusters.length === 0 && (
        <NoClustersFoundLabel>No clusters found</NoClustersFoundLabel>
      )}
      {foundClusters.length > 0 && (
        <Clusters $columns={columns}>
          {foundClusters.map(cluster => (
            <ClusterListCell
              key={cluster.id}
              cluster={cluster}
              lassos={lassos}
              selectedIds={selectedIds}
              onChange={onChange}
              onRename={onRename}
              shouldShowLassos={shouldShowLassos}
              onDoubleClickClusterDot={props.onDoubleClickClusterDot}
              isHighlighted={props.selectedDotClusterIds?.includes(cluster.id)}
            />
          ))}
        </Clusters>
      )}
      {(search || isAnyLassoSelected) && remainingClusters.length > 0 && (
        <>
          <HorizontalSeparator />
          <StyledCheckbox
            label={<Label>Remaining clusters</Label>}
            checked={remainingClusters.every(remainingCluster =>
              selectedIds.includes(remainingCluster.id),
            )}
            onChange={toggleRemainingClusters}
          />
        </>
      )}
      <Clusters $columns={columns}>
        {remainingClusters.map(cluster => (
          <ClusterListCell
            key={cluster.id}
            cluster={cluster}
            lassos={lassos}
            selectedIds={selectedIds}
            onChange={onChange}
            onRename={onRename}
            shouldShowLassos={shouldShowLassos}
          />
        ))}
      </Clusters>
    </ClustersListRoot>
  )
}

const createToggleClusterGroup =
  ({
    clusters,
    selectedIds,
    onChange,
  }: {
    clusters: Pick<Cluster, 'id'>[]
    selectedIds: string[]
    onChange: ((selectedClusterIds: string[]) => void) | undefined
  }) =>
  (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.checked) {
      onChange?.(uniq([...selectedIds, ...clusters.map(cluster => cluster.id)]))
    } else {
      onChange?.(
        selectedIds.filter(
          selectedId => !clusters.some(cluster => cluster.id === selectedId),
        ),
      )
    }
  }

const ClustersListRoot = styled.div`
  width: 100%;
  overflow-y: auto;
`

const Header = styled.div`
  background: white;
  z-index: 1;
  border-bottom: 1px solid ${props => props.theme.colors.greyscale[10]};
  padding: 5px 16px;
  position: sticky;
  top: 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: fit-content;
`

const Clusters = styled.div<{ $columns: number }>`
  display: grid;
  grid-template-columns: repeat(${props => props.$columns}, 1fr);
  grid-row-gap: 12px;
  padding: 10px 32px 16px;

  ${Header} {
    grid-column: span ${props => props.$columns};
  }
`

const HorizontalSeparator = styled.div`
  border-bottom: 1px solid ${props => props.theme.colors.greyscale[10]};
`

const SelectAllLabel = styled.span`
  font-weight: bold;
`

const StyledCheckbox = styled(Checkbox)`
  margin-left: 16px;
`

const Label = styled.p`
  font-weight: bold;
  opacity: 0.7;
`

const NoClustersFoundLabel = styled(Label)`
  padding: 12px 16px;
`

const StyledSelect = styled(Select)`
  height: auto;
  max-height: 60px;
  color: ${props => props.theme.colors.primaryDark[100]};
  font-size: ${props => props.theme.font.size.small}px;
  width: 200px;
  padding: 0 4px;
  overflow: auto;
  min-width: 100px;

  & > div {
    padding: 0;
  }

  .MuiInputBase-root {
    padding: 0;
  }
`

const SelectIcon = styled(Icon)`
  position: absolute;
  right: 0;
  pointer-events: none;
`

const LassoTags = styled.div`
  padding: 4px 8px !important;
  display: flex;
  flex-wrap: wrap;
  max-height: fit-content !important;

  & > div {
    margin: 2px;
    padding: 0 4px;
    line-height: 1.5;
  }
`

const Filters = styled.div`
  display: flex;
  align-items: center;
  height: 100%;
`

const LassoSwitchControlLabel = styled(FormControlLabel)`
  margin-left: 4px;
`
