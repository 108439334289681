import styled from 'styled-components'

type ChartInfoProps = {
  className?: string
  title: string
  description: string
}

export const ChartInfo: React.FC<ChartInfoProps> = ({
  className,
  title,
  description,
}): JSX.Element => {
  return (
    <ChartInfoRoot className={className}>
      <h4>{title}</h4>
      <p>{description}</p>
    </ChartInfoRoot>
  )
}

const ChartInfoRoot = styled.div`
  place-self: center;
  text-align: center;
  max-width: 300px;
  color: ${props => props.theme.colors.primaryDark[70]};
`
