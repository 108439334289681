import { Formik, FormikConfig } from 'formik'
import { FC } from 'react'
import styled from 'styled-components'
import { object, string } from 'yup'

import { Button } from 'components/button/Button'
import { Modal } from 'components/modal/Modal'
import { ModalContainer } from 'components/modal/ModalContainer'
import { TextField } from 'components/text-field/TextField'

import { Pipeline, useUpdatePipelineMutation } from 'shared/api/pipelines.api'
import { useEventCallback } from 'shared/hooks/useEventCallback'

type PipelineSettingsModalProps = {
  pipeline: Pipeline
  onClose: () => void
}

type PipelineSettingsFormValues = {
  name: string
  description: string
}

const PipelineSettingsSchema = object({
  name: string()
    .required('You must specify a name')
    .max(128, 'Name cannot be longer than 128 characters'),
  description: string(),
})

export const PipelineSettingsModal: FC<PipelineSettingsModalProps> = ({
  pipeline,
  onClose,
}) => {
  const [triggerUpdatePipelineMutation, updatePipelineMutationState] =
    useUpdatePipelineMutation()

  const initialValues: PipelineSettingsFormValues = {
    name: pipeline.name,
    description: pipeline.description ?? '',
  }

  const handleSubmit: FormikConfig<PipelineSettingsFormValues>['onSubmit'] =
    useEventCallback(async values => {
      await triggerUpdatePipelineMutation({
        pipelineId: pipeline.id,
        ...values,
      }).unwrap()
      onClose()
    })

  return (
    <Modal
      open
      title="Update pipeline"
      onClose={onClose}
      Container={StyledModalContainer}
    >
      <Formik<PipelineSettingsFormValues>
        initialValues={initialValues}
        onSubmit={handleSubmit}
        validationSchema={PipelineSettingsSchema}
      >
        {({
          values,
          errors,
          isValid,
          handleChange,
          handleBlur,
          handleSubmit,
        }) => (
          <Form onSubmit={handleSubmit}>
            <StyledTextField
              id="name"
              name="name"
              value={values.name}
              placeholder="Project name"
              onChange={handleChange}
              onBlur={handleBlur}
              autoComplete="off"
              error={!!errors.name}
              helperText={errors.name}
            />
            <StyledTextField
              id="description"
              name="description"
              value={values.description}
              placeholder="Description"
              onChange={handleChange}
              onBlur={handleBlur}
              multiline={true}
              minRows={4}
              maxRows={6}
              autoComplete="off"
            />
            <Actions>
              <Button colorOverride="greyscale" onClick={onClose}>
                Cancel
              </Button>
              <Button
                type="submit"
                disabled={!isValid}
                loading={updatePipelineMutationState.isLoading}
              >
                Confirm
              </Button>
            </Actions>
          </Form>
        )}
      </Formik>
    </Modal>
  )
}

const StyledModalContainer = styled(ModalContainer)`
  width: 700px;
  font-size: 13px;
  gap: ${props => props.theme.spacing(2)}px;
`

const Form = styled.form`
  width: 50%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
`

const StyledTextField = styled(TextField)`
  width: 100%;
`

const Actions = styled.div`
  display: flex;
  gap: 12px;
  margin-top: 50px;
`
