import { combineReducers, configureStore, createAction } from '@reduxjs/toolkit'
import { createReduxEnhancer as createSentryReduxEnhancer } from '@sentry/react'
import { produce, setAutoFreeze } from 'immer'
import {
  TypedUseSelectorHook,
  useDispatch as originalUseDispatch,
  useSelector as originalUseSelector,
} from 'react-redux'

import { analysisPageReducer } from 'pages/analysis/store'
import { dependentChartsMiddleware } from 'pages/analysis/store/dependent-charts.middleware'
import { multiTabMiddleware } from 'pages/analysis/store/multi-tab.middleware'
import { metaAnalysisPageReducer } from 'pages/meta-analysis/store'
import { projectPageReducer } from 'pages/project/store'

import { metaAnalysisApi } from 'shared/api/meta-analysis.api'
import { privateApi } from 'shared/api/private.api'
import { publicApi } from 'shared/api/public.api'

import { authSlice } from './auth.slice'
import { globalErrorHandlingMiddleware } from './global-error-handling.middleware'
import { notificationSlice } from './notification.slice'

setAutoFreeze(false)

const combinedReducers = combineReducers({
  // apis
  [privateApi.reducerPath]: privateApi.reducer,
  [publicApi.reducerPath]: publicApi.reducer,
  // slices
  auth: authSlice.reducer,
  projectPage: projectPageReducer,
  analysisPage: analysisPageReducer,
  metaAnalysisPage: metaAnalysisPageReducer,
  notification: notificationSlice.reducer,
})

export const reducer: typeof combinedReducers = (state, action) => {
  if (initMultiTabSecondaryTabState.match(action)) {
    const applicationInstanceId = state?.auth?.applicationInstanceId
    if (!applicationInstanceId) {
      throw new Error('applicationInstanceId is not set')
    }
    return produce(action.payload.state, draft => {
      draft.auth.applicationInstanceId = applicationInstanceId
    })
  }

  if (patchState.match(action)) {
    return produce(state, draft => {
      const applyPatch = (stateSlice, patchSlice) => {
        for (const key of Object.keys(patchSlice)) {
          if (
            typeof patchSlice[key] === 'object' &&
            !Array.isArray(patchSlice[key]) &&
            typeof stateSlice[key] === 'object' &&
            !Array.isArray(stateSlice[key])
          ) {
            applyPatch(stateSlice[key], patchSlice[key])
          } else if (patchSlice[key] === undefined) {
            delete stateSlice[key]
          } else {
            stateSlice[key] = patchSlice[key]
          }
        }
      }

      applyPatch(draft, action.payload.patch)
    }) as RootState
  }

  return combinedReducers(state, action)
}

export const initMultiTabSecondaryTabState = createAction<{ state: RootState }>(
  'MULTI_TAB_INIT_STATE_ACTION',
)

export const patchState = createAction<{ patch: Partial<RootState> }>(
  'MULTI_TAB_PATCH_STATE_ACTION',
)

const sentryReduxEnhancer = createSentryReduxEnhancer()

export const store = configureStore({
  reducer,
  middleware: getDefaultMiddleware =>
    getDefaultMiddleware({
      serializableCheck: false,
      immutableCheck: false,
    }).concat(
      privateApi.middleware,
      publicApi.middleware,
      multiTabMiddleware.middleware,
      dependentChartsMiddleware.middleware,
      globalErrorHandlingMiddleware,
    ),
  enhancers: getDefaultEnhancers =>
    getDefaultEnhancers().concat([sentryReduxEnhancer]),
  devTools: {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    actionSanitizer(action) {
      if (metaAnalysisApi.endpoints.getMetaAnalysis.matchFulfilled(action)) {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        return produce(action, (draft: any) => {
          draft.payload.metaAnalysisFile = '<data>'
          draft.payload.metaAnalysisGlobalVizFile = '<data>'
          draft.payload.metaAnalysisVolcanoPlotFile = '<data>'
        })
      } else {
        return action
      }
    },
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    stateSanitizer: (state: any) => {
      return {
        ...state,
        analysisPage: {
          ...state.analysisPage,
          analysis: {
            ...state.analysisPage.analysis,
            history: {
              ...state.analysisPage.analysis.history,
              past: state.analysisPage.analysis.history.past.map(
                historyEntry => ({
                  ...historyEntry,
                  analysis: {
                    ...historyEntry.analysis,
                    cluster_tree: '<data>',
                  },
                }),
              ),
              present: {
                ...state.analysisPage.analysis.history.present,
                analysis: {
                  ...state.analysisPage.analysis.history.present.analysis,
                  cluster_tree: '<data>',
                },
              },
              future: state.analysisPage.analysis.history.future.map(
                historyEntry => ({
                  ...historyEntry,
                  analysis: {
                    ...historyEntry.analysis,
                    cluster_tree: '<data>',
                  },
                }),
              ),
            },
          },
        },
        metaAnalysisPage: {
          ...state.metaAnalysisPage,
          metaAnalysis: {
            ...state.metaAnalysisPage.metaAnalysis,
            history: {
              ...state.metaAnalysisPage.metaAnalysis.history,
              past: state.metaAnalysisPage.metaAnalysis.history.past.map(
                historyEntry => ({
                  ...historyEntry,
                  metaAnalysisFile: '<data>',
                  metaAnalysisGlobalVizFile: '<data>',
                }),
              ),
              present: {
                ...state.metaAnalysisPage.metaAnalysis.history.present,
                metaAnalysisFile: '<data>',
                metaAnalysisGlobalVizFile: '<data>',
              },
              future: state.metaAnalysisPage.metaAnalysis.history.future.map(
                historyEntry => ({
                  ...historyEntry,
                  metaAnalysisFile: '<data>',
                  metaAnalysisGlobalVizFile: '<data>',
                }),
              ),
            },
          },
        },
      }
    },
  },
})

export type RootState = ReturnType<typeof store.getState>
export type AppDispatch = typeof store.dispatch
export type SliceStatus = 'idle' | 'loading' | 'success' | 'error'

export const useAppDispatch = () => originalUseDispatch<AppDispatch>() // eslint-disable-line
export const useAppSelector: TypedUseSelectorHook<RootState> =
  originalUseSelector
