import { Menu as MuiMenu } from '@material-ui/core'
import styled from 'styled-components'

export const Menu = styled(MuiMenu)`
  > .MuiPaper-elevation0 {
    box-shadow: 2px 2px 10px 2px rgba(0, 0, 0, 0.1);
  }

  > .MuiPaper-rounded {
    border-radius: ${props => props.theme.radius[2]}px;
  }
`
