import { MessagesForUser } from 'shared/models/AnalysisModels'
import { PaginatedResults } from 'shared/models/Result'

import { CompensatedFile } from './files.api'
import { privateApi } from './private.api'
import { encodeTagParameters } from './utils'
import { Brick, BrickAnalysis, WorkflowStatus } from './workflows.api'

const experimentsApi = privateApi.injectEndpoints({
  endpoints: builder => ({
    preCreateExperiment: builder.mutation<PreCreateExperimentResult, string>({
      query: templateId => ({
        url: `/experiments/pre_create/`,
        method: 'POST',
        body: {
          template: templateId,
        },
      }),
    }),
    createExperiment: builder.mutation<unknown, CreateExperimentPayload>({
      query: payload => ({
        url: `/experiments/`,
        method: 'POST',
        body: payload,
      }),
    }),
    getExperiments: builder.query<GetExperimentsResult, GetExperimentsPayload>({
      query: payload => ({
        url: `/experiments/`,
        params: {
          project: payload.projectId,
          page: payload.page,
          ordering: payload.ordering,
          ...payload.search,
        },
      }),
      providesTags: [
        { type: 'Workflow', id: encodeTagParameters({ id: 'list' }) },
      ],
    }),
    getExperiment: builder.query<ExperimentDetails, string>({
      query: id => `/experiments/${id}/`,
      providesTags: (_result, _error, id) => [
        { type: 'Workflow', id: encodeTagParameters({ id }) },
      ],
    }),
    updateExperiment: builder.mutation<unknown, UpdateExperimentPayload>({
      query: ({ id, ...payload }) => ({
        url: `/experiments/${id}/`,
        method: 'PATCH',
        body: payload,
      }),
    }),
    retriggerBricks: builder.mutation<unknown, RetriggerBricksPayload>({
      query: ({ experimentId, brickIds }) => ({
        url: `/experiments/${experimentId}/restart_bricks/`,
        method: 'POST',
        body: { bricks: brickIds },
      }),
    }),
    deleteExperiment: builder.mutation<unknown, string>({
      query: id => ({
        url: `/experiments/${id}/`,
        method: 'DELETE',
      }),
    }),
    addFilesToExperiment: builder.mutation<
      unknown,
      AddFilesToExperimentPayload
    >({
      query: ({ experimentId, fileIds }) => ({
        url: `/experiments/${experimentId}/add-compensated-files/`,
        method: 'POST',
        body: { files: fileIds },
      }),
    }),
    deleteFilesFromExperiment: builder.mutation<
      unknown,
      DeleteFilesFromExperimentPayload
    >({
      query: ({ experimentId, fileIds }) => ({
        url: `/experiments/${experimentId}/remove-compensated-files/`,
        method: 'POST',
        body: { files: fileIds },
      }),
    }),
    checkFilesChannelsForExperiment: builder.mutation<
      CheckFilesChannelsForExperimentResult,
      CheckFilesChannelsForExperimentPayload
    >({
      query: ({ experimentId, files }) => ({
        url: `/experiments/${experimentId}/check-files-channels/`,
        method: 'POST',
        body: { files },
      }),
    }),
  }),
})

export const {
  usePreCreateExperimentMutation,
  useCreateExperimentMutation,
  useGetExperimentsQuery,
  useGetExperimentQuery,
  useLazyGetExperimentQuery,
  useUpdateExperimentMutation,
  useRetriggerBricksMutation,
  useDeleteExperimentMutation,
  useAddFilesToExperimentMutation,
  useDeleteFilesFromExperimentMutation,
  useCheckFilesChannelsForExperimentMutation,
} = experimentsApi

export type BrickReferenceOptions = {
  brick_id: string
  brick_name: string
  brick_reference_analysis: BrickAnalysis | null
  brick_references_analyses: BrickAnalysis[]
}

type PreCreateExperimentResult = {
  references: Record<string, BrickReferenceOptions>
}

type CreateExperimentPayload = {
  template: string
  name: string | undefined
  compensated_files: string[]
  references: Record<string, BrickReferenceOptions>
}

type GetExperimentsPayload = {
  projectId: string
  page: number
  ordering?: string
  search?: Record<string, string | undefined>
}

type GetExperimentsResult = PaginatedResults<Experiment[]>

export type Experiment = {
  id: string
  author_name: string
  created_at: string
  description: string | null
  experiment_source: string
  experiment_source_name: string
  origin_pipeline_version: string
  origin_pipeline_name: string
  messages_for_user: MessagesForUser
  mode: 'experiment'
  name: string
  progress: `${number}/${number}`
  project: string
  status: ExperimentStatus
  updated_at: string
}

export type ExperimentDetails = Experiment & {
  bricks: Brick[]
  compensated_files: CompensatedFile[]
}

export type ExperimentStatus = WorkflowStatus

type UpdateExperimentPayload = {
  id: string
  name?: string
  description?: string
}

type RetriggerBricksPayload = {
  experimentId: string
  brickIds: string[]
}

export type AddFilesToExperimentPayload = {
  experimentId: string
  fileIds: string[]
}

export type DeleteFilesFromExperimentPayload = {
  experimentId: string
  fileIds: string[]
}

type CheckFilesChannelsForExperimentPayload = {
  experimentId: string
  files: string[]
}

type CheckFilesChannelsForExperimentResult = {
  message: string
  all_missing_channels?: string[]
  warnings?: {
    [compensatedFileId: string]: {
      message: string
      missing_channels: string[]
    }
  }
}
