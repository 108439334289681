import Highcharts from 'highcharts'

import { createAnalysisLassosHierarchyGraphBaseOptions } from 'components/graphs/AnalysisLassosHierarchyGraphBaseOptions'

import {
  selectAnalysisLassos,
  selectClustersByLassoId,
  selectParentLassoIdByLassoId,
} from 'pages/analysis/store/selectors'

import { RootState } from 'shared/store'

import { Theme } from 'Theme'

import { ANALYSIS_CHART_HEIGHT, ANALYSIS_CHART_WIDTH } from '..'

type RenderLassosHierarchyGraphProps = {
  state: RootState
  theme: Theme
}

type RenderLassosHierarchyGraphResult = {
  svg: string
  width: number
  height: number
}

export const renderLassosHierarchyGraph = async ({
  state,
  theme,
}: RenderLassosHierarchyGraphProps): Promise<RenderLassosHierarchyGraphResult> => {
  const lassos = selectAnalysisLassos(state)
  const parentLassoIdByLassoId = selectParentLassoIdByLassoId(state)
  const clustersByLassoId = selectClustersByLassoId(state)

  const AnalysisLassosHierarchyGraphBaseOptions =
    createAnalysisLassosHierarchyGraphBaseOptions({
      lassos,
      parentLassoIdByLassoId,
      clustersByLassoId,
      theme,
    })

  const width = ANALYSIS_CHART_WIDTH
  const height = ANALYSIS_CHART_HEIGHT

  return {
    svg: new Highcharts.Chart(document.createElement('div'), {
      ...AnalysisLassosHierarchyGraphBaseOptions,
      chart: {
        ...AnalysisLassosHierarchyGraphBaseOptions.chart,
        width: ANALYSIS_CHART_WIDTH,
        height: ANALYSIS_CHART_HEIGHT,
      },
      plotOptions: {
        ...AnalysisLassosHierarchyGraphBaseOptions.plotOptions,
        series: {
          ...AnalysisLassosHierarchyGraphBaseOptions.plotOptions.series,
          dataLabels: {
            ...AnalysisLassosHierarchyGraphBaseOptions.plotOptions.series
              .dataLabels,
            style: {
              ...AnalysisLassosHierarchyGraphBaseOptions.plotOptions.series
                .dataLabels.style,
              fontFamily: 'Arial',
            },
          },
        },
      },
    }).getSVG(),
    width,
    height,
  }
}
