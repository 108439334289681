import {
  Drawer as MuiDrawer,
  DrawerProps as MuiDrawerProps,
} from '@material-ui/core'
import React, { FC, ReactNode } from 'react'
import styled from 'styled-components'

import { theme } from 'Theme'

type DrawerProps = {
  open: boolean
  onClose: () => void
  onBackdropClick: () => void
  anchor?: MuiDrawerProps['anchor']
  className?: string
  children?: ReactNode
}

export const Drawer: FC<DrawerProps> = props => {
  const {
    open,
    onClose,
    onBackdropClick,
    anchor = 'left',
    className,
    children,
  } = props
  return (
    <MuiDrawer
      keepMounted={false}
      BackdropProps={{
        style: { backgroundColor: theme.colors.modal },
      }}
      variant="temporary"
      anchor={anchor}
      open={open}
      onClose={(event, reason) =>
        reason === 'backdropClick' ? onBackdropClick() : onClose()
      }
      transitionDuration={300}
    >
      <StyledDrawer className={className}>{children}</StyledDrawer>
    </MuiDrawer>
  )
}

const StyledDrawer = styled.div`
  width: 800px;
  overflow: auto;
  height: 100%;
  display: flex;
  justify-content: center;
  padding: 50px 30px;
  background-color: ${props => props.theme.colors.background};
`
