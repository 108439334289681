import { skipToken } from '@reduxjs/toolkit/query'
import { useParams } from 'react-router'
import { useBeforeUnload, useUnmount } from 'react-use'
import styled from 'styled-components'

import { LoadingMask } from 'components/LoadingMask'

import {
  metaAnalysisApi,
  useGetMetaAnalysisQuery,
} from 'shared/api/meta-analysis.api'
import { useAppDispatch, useAppSelector } from 'shared/store'

import { MetaAnalysisLayout } from './MetaAnalysisLayout'
import { MetaAnalysisPageHeader } from './MetaAnalysisPageHeader'
import { MetaAnalysisPageToolbar } from './MetaAnalysisPageToolbar'
import { unloadMetaAnalysis } from './store/meta-analysis.slice'
import {
  selectIsMetaAnalysisSaved,
  selectMetaAnalysisLoadingStatus,
} from './store/selectors'

export const MetaAnalysisPage = (): JSX.Element => {
  const { metaAnalysisId } = useParams<{
    metaAnalysisId: string | undefined
  }>()
  useGetMetaAnalysisQuery(metaAnalysisId ?? skipToken)

  const dispatch = useAppDispatch()
  const status = useAppSelector(selectMetaAnalysisLoadingStatus)
  const isMetaAnalysisSaved = useAppSelector(selectIsMetaAnalysisSaved)
  useBeforeUnload(!isMetaAnalysisSaved)

  useUnmount(() => {
    setTimeout(() => {
      dispatch(unloadMetaAnalysis())
      dispatch(metaAnalysisApi.util.invalidateTags(['MetaAnalysis']))
    })
  })

  return (
    <MetaAnalysisPageRoot>
      {status !== 'success' && <LoadingMask isFirstLoad={true} />}
      {status === 'success' && (
        <>
          <MetaAnalysisPageHeader />
          <MetaAnalysisPageToolbar />
          <MetaAnalysisLayout />
        </>
      )}
    </MetaAnalysisPageRoot>
  )
}

const MetaAnalysisPageRoot = styled.div`
  padding-top: ${props => props.theme.layout.headerHeight}px;
  padding-bottom: ${props => props.theme.layout.graphMargin * 2}px;
  margin: 0 auto;
  width: 100%;
`
