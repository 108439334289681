import styled from 'styled-components'

type PdfCheckboxProps = {
  className?: string
  checked: boolean
}

export const PdfCheckbox: React.FC<PdfCheckboxProps> = ({
  className,
  checked,
}) => {
  return (
    <PdfCheckboxRoot
      className={className}
      width="8"
      height="6"
      viewBox="0 0 8 6"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      {checked && (
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M7.86983 0.130175C8.04339 0.303741 8.04339 0.585148 7.86983 0.758714L3.31613 5.31241C3.23278 5.39576 3.11974 5.44258 3.00186 5.44258C2.88399 5.44258 2.77094 5.39576 2.68759 5.31241L0.130175 2.75499C-0.0433916 2.58142 -0.0433916 2.30002 0.130175 2.12645C0.303741 1.95288 0.585148 1.95288 0.758714 2.12645L3.00186 4.3696L7.24129 0.130175C7.41485 -0.0433916 7.69626 -0.0433916 7.86983 0.130175Z"
          fill="#1A2784"
        ></path>
      )}
    </PdfCheckboxRoot>
  )
}

const PdfCheckboxRoot = styled.svg`
  border-radius: 4px;
  width: 16px;
  height: 16px;
  border: 1px solid;
  border-color: #1a2784;
  padding: 3px;
`
