import { FC, useState } from 'react'
import styled from 'styled-components'

import { Button } from 'components/button/Button'
import { TextField } from 'components/text-field/TextField'

import { TableFilter } from './TableFilter'

type NumberRangeFilterProps = {
  title: string
  value: [number | undefined, number | undefined]
  onChange: (value: [number | undefined, number | undefined]) => void
}

export const NumberRangeFilter: FC<NumberRangeFilterProps> = ({
  title,
  value,
  onChange,
}) => {
  const [isFilterExpanded, setFilterExpanded] = useState(false)
  const [from, setFrom] = useState(value?.[0])
  const [to, setTo] = useState(value?.[1])

  const handleSet =
    (field: 'from' | 'to') =>
    (event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
      const value = event.target.value === '' ? undefined : +event.target.value
      if (value && isNaN(value)) {
        return
      }
      if (field === 'from') {
        setFrom(value)
      }
      if (field === 'to') {
        setTo(value)
      }
    }

  const handleCancel = () => {
    setFrom(value?.[0])
    setTo(value?.[1])
    setFilterExpanded(false)
  }

  const handleApply = () => {
    onChange([from, to])
    setFilterExpanded(false)
  }

  return (
    <TableFilter
      title={title}
      isSelected={value.some(v => v !== undefined)}
      isExpanded={isFilterExpanded}
      onButtonClick={() => setFilterExpanded(true)}
      onClickAway={handleCancel}
    >
      <NumberRangeFilterContainer>
        <TextField
          label="From"
          type="number"
          value={from}
          onChange={handleSet('from')}
        />
        <TextField
          label="To"
          type="number"
          value={to}
          onChange={handleSet('to')}
        />
        <Buttons>
          <Button colorOverride="white" onClick={handleCancel}>
            Cancel
          </Button>
          <Button onClick={handleApply}>Apply</Button>
        </Buttons>
      </NumberRangeFilterContainer>
    </TableFilter>
  )
}

const NumberRangeFilterContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${props => props.theme.spacing(2)}px;
  color: ${props => props.theme.colors.primaryDark[50]};
`

const Buttons = styled.div`
  display: flex;
  gap: ${props => props.theme.spacing(1)}px;
  align-self: flex-end;
  button {
    font-size: 10px;
  }
`
